import { InputHidden } from "@heart/components";
import PropTypes from "prop-types";
import { useRef, useEffect, useState, Fragment } from "react";

import AfcarsRequired from "@components/ccwis_demo/AfcarsRequired";
import NestedMultiFormInput from "@components/inputs/nested_multi/NestedMultiFormInput";

const EthnicityWrapper = ({
  selectedEthnicities,
  inputName,
  isRequired = false,
  isAfcarsRequired = false,
}) => {
  const isFirstRun = useRef(true);
  const [ethnicities, setEthnicities] = useState(selectedEthnicities);

  useEffect(() => {
    // Tracking the first run here, if other effects are added in the future
    // isFirstRun.current should be set to false in the last useEffect method.
    // The reason we want to skip the first render is if the ethnicity already
    // has ethnicities selected in addition to 'prefer_not_to_answer' (Decline to state).
    // We do not want to show the wrong state to the user.
    if (
      !isFirstRun.current &&
      Object.keys(ethnicities).length > 1 &&
      Object.keys(ethnicities).includes("prefer_not_to_answer")
    ) {
      setEthnicities({ prefer_not_to_answer: null });
    }
    isFirstRun.current = false;
  }, [ethnicities]);

  return (
    <div>
      <NestedMultiFormInput
        options={window.Binti.ETHNICITY_OPTIONS}
        label={
          <Fragment>
            {I18n.t("views.common.ethnicity")}
            <If condition={isRequired}>*</If>
            <If condition={isAfcarsRequired}>
              {" "}
              <AfcarsRequired />
            </If>
          </Fragment>
        }
        selectedOptions={ethnicities}
        onSelectedOptionsChange={newEthnicities => {
          setEthnicities(newEthnicities);
        }}
      />
      <InputHidden name={inputName} value={JSON.stringify(ethnicities)} />
    </div>
  );
};

EthnicityWrapper.propTypes = {
  selectedEthnicities: PropTypes.object.isRequired,
  inputName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  /** Whether these inputs are required for AFCARS completeness */
  isAfcarsRequired: PropTypes.bool,
};

export default EthnicityWrapper;
