import { useCallback, useEffect, useMemo, useState } from "react";

const useFilter = ({ filters }) => {
  const requiredVariables = useMemo(
    () => filters.filter(({ required }) => required),
    [filters]
  );
  const isVariableSet = useCallback(
    ({ variable, value }) => {
      const filter = filters.find(({ field }) => field === variable.field);

      if (filter?.isSet) {
        return filter.isSet(value);
      }

      return value !== undefined;
    },
    [filters]
  );

  const determineDefaultVals = filts =>
    filts.reduce((defaultVals, filter) => {
      // check for the key `defaultValue` in the filter as opposed to
      // checking for filter.defaultValue in case the defaultValue is falsy
      if ("defaultValue" in filter) {
        return { ...defaultVals, [filter.field]: filter.defaultValue };
      }
      return defaultVals;
    }, {});

  const [defaultValues, setDefaultValues] = useState(
    determineDefaultVals(filters)
  );
  useEffect(() => {
    setDefaultValues(determineDefaultVals(filters));
  }, [filters]);

  return {
    requiredVariables,
    isVariableSet,
    defaultValues,
  };
};

export default useFilter;
