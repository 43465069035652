import { Flex, Text } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import ProgressArc from "@components/ProgressArc";
import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "requirements.requirement_table.progress_cell"
);

/** Cell contents displaying the status of an entity. Valid statuses are
 * a percentage complete, overridden, waiting for a signature, and completed.
 */
const ProgressCell = ({ fulfillment }) => {
  const { isFulfilled, isOverridden, progressPercentage, waitingForSignature } =
    fulfillment;
  return (
    <Fragment>
      <If condition={isOverridden}>
        <Text textColor="emphasis-800">{t("waived")}</Text>
      </If>
      <If condition={!isOverridden}>
        <Flex column>
          <Flex align="center">
            <ProgressArc
              progress={progressPercentage}
              progressIncomplete={!isFulfilled}
            />
            <Text>
              {isFulfilled ? t("completed") : `${progressPercentage}%`}
            </Text>
          </Flex>
          <If condition={waitingForSignature}>
            <Text textStyle="supporting-50">{t("waiting_for_signature")}</Text>
          </If>
        </Flex>
      </If>
    </Fragment>
  );
};
ProgressCell.propTypes = {
  fulfillment: PropTypes.shape({
    isFulfilled: PropTypes.bool.isRequired,
    isOverridden: PropTypes.bool.isRequired,
    progressPercentage: PropTypes.number.isRequired,
    waitingForSignature: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ProgressCell;
