import { Button, Icons } from "@heart/components";
import { Panel, useReactFlow } from "@xyflow/react";
import { toPng } from "html-to-image";
import PropTypes from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("heart.components.node_diagram");

const downloadImage = ({ dataUrl, fileName }) => {
  const a = document.createElement("a");

  a.setAttribute("download", `${fileName}.png`);
  a.setAttribute("href", dataUrl);
  a.click();
};

/** A button to download an image of the data displayed in a NodeDiagram */
const DownloadButton = ({ fileName }) => {
  const { getNodes, getNodesBounds } = useReactFlow();

  if (!fileName) return null;

  const onClick = () => {
    const nodesBounds = getNodesBounds(getNodes());
    toPng(document.querySelector(".react-flow__viewport"), {
      backgroundColor: "#ffffff",
      width: nodesBounds.width + 100,
      height: nodesBounds.height + 100,
      style: {
        width: nodesBounds.width,
        height: nodesBounds.height,
        /** We remove any transformation of the node diagram's viewport
         * done via zoom and pan tools so the full diagram is captured
         * in the downloaded image
         */
        transform: "none",
      },
    }).then(dataUrl => downloadImage({ dataUrl, fileName }));
  };

  return (
    <Panel position="top-right">
      <Button
        variant="secondary"
        onClick={onClick}
        description={t("download")}
        icon={Icons.FileDownload}
      />
    </Panel>
  );
};
DownloadButton.propTypes = {
  fileName: PropTypes.string,
};

export default DownloadButton;
