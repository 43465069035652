import { Flex } from "@heart/components";
import PropTypes from "prop-types";

/** Basic implementation of a row of inputs */
const InputsRow = ({ children }) => (
  <Flex row gap="200" wrap>
    {children}
  </Flex>
);

InputsRow.propTypes = {
  children: PropTypes.node.isRequired,
};
export default InputsRow;
