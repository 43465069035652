/**
 * Conversion utilities to allow us to use boolean `true`/`false`
 * values in our `props` / `onChange` interfaces but still render
 * "true"/"false" strings in our HTML to accommodate form POSTs.
 */

export const htmlTrueFalseToJavascript = value => {
  if (value === "true") return true;
  if (value === "false") return false;
  return value;
};

export const javascriptTrueFalseToHtml = value => {
  if (value === true) return "true";
  if (value === false) return "false";
  return value;
};
