// 1.1 is a very gentle base. At 30 max retries, the sum of all the
// retries is about 180 seconds/3 minutes.
const DEFAULTS = { initialDelay: 1000, base: 1.1, maxRetries: 30 };

class Poller {
  /*
    poll is a function taking a single argument which is a callback to call
    when the poll function knows we need to poll again.

    e.g.

    function poll(needToPollAgain) {
      someAsyncCall({
        success() {
          "it's all good"
        },

        failure() {
          needToPollAgain();
        }
      })
    }
   */
  constructor(poll, options = DEFAULTS) {
    this.poll = poll;
    this.retries = 0;
    this.initialDelay = options.initialDelay || DEFAULTS.initialDelay;
    this.base = options.base || DEFAULTS.base;
    this.maxRetries = options.maxRetries || DEFAULTS.maxRetries;
  }

  start = () => {
    this.pollNow();

    // make this chainable
    return this;
  };

  stop = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  };

  retry = () => {
    if (this.retries > this.maxRetries) {
      return;
    }

    this.retries += 1;
    this.timeoutId = setTimeout(this.pollNow, this.calculateTimeout());
  };

  pollNow = () => {
    this.timeoutId = null;
    this.poll(this.retry);
  };

  calculateTimeout = () => {
    const expFactor = this.base ** this.retries;
    return (Math.random() + 1) * this.initialDelay * expFactor;
  };
}

Poller.startPolling = (poll, options) => new Poller(poll, options).start();

export default Poller;
