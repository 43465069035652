import { Layout } from "@heart/components";

import { translationWithRoot } from "@components/T";

import ApiTokens from "./ApiTokens";

const { t: tHeaderLinks } = translationWithRoot("admin.header_links", {
  escapeJavascriptRoot: true,
});

const ApiTokensPage = () => (
  <Layout
    pageTitle={tHeaderLinks("api_tokens")}
    main={{ content: <ApiTokens showAddTokenButton={false} /> }}
  />
);

export default ApiTokensPage;
