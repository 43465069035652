const determineUserAgencyProfile = ({
  agencyId,
  person: { userAgencyProfiles = [] },
}) =>
  userAgencyProfiles.filter(
    ({ agencyId: profileAgencyId }) =>
      parseInt(profileAgencyId, 10) === agencyId
  )[0] || { phoneNumbers: [] };

export default determineUserAgencyProfile;
