import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

import { B64PARAMS } from "@root/constants";

import { decode, ENCODED_EMPTY_OBJECT } from "../base64Helpers";
import { isBrowser, on, off } from "./utils";

/** This hook was copied over from [react-use](https://github.com/streamich/react-use)
 * as the library is no longer being maintained. We're moving to `react-hookz` as the
 * supported alternative, but location related hooks will not be migrated over so we're
 * pulling the hooks we need directly into our codebase as an alternative
 *
 * We've modified it to account for our use of base64 encoded search params
 *
 * Their [license](https://github.com/streamich/react-use/blob/325f5bd69904346788ea981ec18bfc7397c611df/LICENSE#L1-L24),
 * allows us to copy, modify, publish, use, compile, sell, or distribute this
 * software, either in source code form or as a compiled binary, for any purpose,
 * commercial or non-commercial, and by any means.
 */

const getBase64Value = (search, param) => {
  const params = new URLSearchParams(search).get(B64PARAMS);
  return JSON.parse(decode(isEmpty(params) ? ENCODED_EMPTY_OBJECT : params))[
    param
  ];
};

const useBase64SearchParam = param => {
  const { location } = window;
  const [value, setValue] = useState(() =>
    getBase64Value(location.search, param)
  );

  useEffect(() => {
    const onChange = () => {
      setValue(getBase64Value(location.search, param));
    };

    on(window, "popstate", onChange);
    on(window, "pushstate", onChange);
    on(window, "replacestate", onChange);

    return () => {
      off(window, "popstate", onChange);
      off(window, "pushstate", onChange);
      off(window, "replacestate", onChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value;
};

const useBase64SearchParamServer = () => null;

export default isBrowser ? useBase64SearchParam : useBase64SearchParamServer;
