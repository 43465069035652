/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// new way to do what @babel/polyfill did before
import "core-js/stable";
import Modal from "react-modal";
import "regenerator-runtime/runtime";
import "whatwg-fetch";

import I18n from "../I18n";
import "../honeybadger";
import "../legacy";
import requireContext from "../requireContext.js";

window.I18n = I18n;

if (document.getElementById("wrapper")) {
  Modal.setAppElement("#wrapper");
}

const ReactRailsUJS = require("react_ujs");

ReactRailsUJS.useContext(requireContext);
