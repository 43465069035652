import { useMutation } from "@apollo/client";
import { Modal, Link } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import T from "@components/T";

import DeleteRequirement from "@graphql/mutations/DeleteRequirement.graphql";

const DeleteRequirementLink = ({ requirement }) => {
  const [deleteRequirement, { loading: deleting }] =
    useMutation(DeleteRequirement);
  const [confirmationHidden, setConfirmationHidden] = useState(true);
  const setConfirmationHiddenToTrue = () => setConfirmationHidden(true);

  const onDelete = () => setConfirmationHidden(false);

  return (
    <Fragment>
      <Modal
        submitDangerButton
        title={I18n.t("javascript.components.common.alert")}
        submitText={<T t="requirements.delete_requirement.yes_delete" />}
        submittingText={<T t="requirements.delete_requirement.deleting" />}
        submitting={deleting}
        onSubmit={() => {
          deleteRequirement({
            variables: { requirementId: requirement.id },
          }).then(setConfirmationHiddenToTrue);
        }}
        onCancel={setConfirmationHiddenToTrue}
        hidden={confirmationHidden}
      >
        <p>
          <T t="requirements.delete_requirement.are_you_sure_you_want_to_delete" />
        </p>
        <p>
          <strong>{requirement.title}</strong>
        </p>
      </Modal>
      <Link data-testid="delete-requirement" onClick={onDelete}>
        delete
      </Link>
    </Fragment>
  );
};

DeleteRequirementLink.propTypes = {
  requirement: PropTypes.object.isRequired,
};

export default DeleteRequirementLink;
