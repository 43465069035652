import { Icons, Text } from "@heart/components";
import PropTypes from "prop-types";

const ColorizedExclamationTriangle = ({ color }) => (
  <Text textColor={color} as="span">
    <Icons.ExclamationTriangle />
  </Text>
);

ColorizedExclamationTriangle.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ColorizedExclamationTriangle;
