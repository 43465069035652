import { SurfaceFieldset } from "@heart/components";
import PropTypes from "prop-types";

import NestedEnumFormInput from "@components/inputs/nested_enum/NestedEnumFormInput";

const NestedEnumFormInputWrapper = props => {
  const { title } = props;
  return (
    <SurfaceFieldset title={title}>
      <NestedEnumFormInput {...props} />
    </SurfaceFieldset>
  );
};

NestedEnumFormInputWrapper.propTypes = {
  /** Title of the this section of the form */
  title: PropTypes.string,
};

export default NestedEnumFormInputWrapper;
