import { Upload, UploadButton } from "@heart/components";
import PropTypes from "prop-types";
import { Component } from "react";

import { DocumentsClientContext } from "../../clients/DocumentsClient";

class DocumentUploadOther extends Component {
  onDrop = client => async files => {
    const { applicationId, category } = this.props;

    if (files.length === 0) {
      // files were rejected
      return;
    }

    if (files.some(file => file.size > Binti.MAX_FILE_SIZE)) {
      window.alert(Binti.MAX_FILE_SIZE_ERROR_MESSAGE); // eslint-disable-line
    } else {
      await client.uploadOtherDocuments(applicationId, category, files);
    }
  };

  render() {
    return (
      <DocumentsClientContext.Consumer>
        {client => (
          <div className="button-right">
            <Upload iconOnly onUpload={this.onDrop(client)}>
              <UploadButton onUpload={this.onDrop(client)} />
            </Upload>
          </div>
        )}
      </DocumentsClientContext.Consumer>
    );
  }
}

DocumentUploadOther.propTypes = {
  applicationId: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
};

export default DocumentUploadOther;
