import { Flex } from "@heart/components";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import { LUXON_DATEPICKER_FORMAT } from "@lib/dates";

import styles from "../DocumentCell.module.scss";

const { t } = translationWithRoot(
  "requirements.requirement_table.document_cell"
);

const CaregiverFacingOverride = ({ lastItem = false, overrideRecord }) => {
  const { creatorName, grantDate } = overrideRecord;

  return (
    <Flex column>
      <Flex justify="space-between">
        {t("override.waived_by", {
          date: DateTime.fromISO(grantDate).toFormat(LUXON_DATEPICKER_FORMAT),
          creatorName,
        })}
      </Flex>
      <If condition={!lastItem}>
        <hr className={styles.dividingLine} />
      </If>
    </Flex>
  );
};
CaregiverFacingOverride.propTypes = {
  /** id of the entity that this override is
   * associated with
   */
  holderToken: PropTypes.string.isRequired,
  /** The fulfillment that this override is
   * associated with
   */
  fulfillment: PropTypes.shape({
    overrideRecords: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        creatorName: PropTypes.string.isRequired,
        grantDate: PropTypes.string.isRequired,
      })
    ),
  }),
  /** Boolean indicating whether this override is the
   * last one in a list of overrides. Functionally,
   * controls whether a dividing line shows up at the bottom
   * of the attachment information
   */
  lastItem: PropTypes.bool,
  /** The override being displayed */
  overrideRecord: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creatorName: PropTypes.string.isRequired,
    grantDate: PropTypes.string.isRequired,
  }),
};

export default CaregiverFacingOverride;
