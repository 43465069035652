import { Button } from "@heart/components";
import { sample } from "lodash";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import { LUXON_DATEPICKER_FORMAT } from "@lib/dates";
import preventDefault from "@lib/preventDefault";

const randomDateBefore = days =>
  DateTime.local().minus({ days: Math.random() * days });

const populateChildData = faker =>
  preventDefault(() => {
    $("#child_first_name").val(faker.person.firstName());
    $("#child_last_name").val(faker.person.lastName());
    $("#child_gender").val(sample(["Female", "Male", "Trans*"]));

    const dob = randomDateBefore(18 * 365);
    // react-select doesn't recognize us setting the date in either the
    // actual input or its text input facade, so just set it in both for now.
    $("input[name='child[date_of_birth]']")
      .val(dob.toISODate())
      .parent()
      .find("input[type=text]")
      .val(dob.toFormat(LUXON_DATEPICKER_FORMAT));
  });

const PopulateChildFakeData = () => {
  const [faker, setFaker] = useState();

  // faker is fairly large and we only need it for binti admin, so load
  // it separately
  useEffect(() => {
    import("@faker-js/faker").then(({ faker: fakerInstance }) => {
      setFaker(fakerInstance);
    });
  }, []);

  if (faker) {
    return (
      <Button onClick={populateChildData(faker)}>Populate w/ Fake Data</Button>
    );
  }

  return false;
};

export default PopulateChildFakeData;
