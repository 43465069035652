import { InputText, InputFilterable } from "@heart/components";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";

/** When choosing a respite provider, this component contains shared fields
 * between relative families / private agencies / caregivers not in Binti.
 *
 *   Children > Add Respite > Select a "Respite Provider Name" option
 */
class FamilyInformationFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedState: {
        label: this.stateLabelFinder(this.props.address?.addressState),
        value: this.props.address?.addressState,
      },
    };
  }

  stateLabelFinder = stateAcronym => {
    const stateOptions = this.props.usStateOptions || {};
    const stateKeys = Object.keys(stateOptions || {});
    let stateLabel = "";
    stateKeys.forEach(value => {
      if (stateOptions[value] === stateAcronym) {
        stateLabel = value;
      }
    });
    return stateLabel;
  };

  stateChanged = selectedState => {
    this.setState({
      selectedState: selectedState,
    });
  };

  // TODO: Change to placement_period[phone_number when phone_number int column is deprecated https://binti.atlassian.net/browse/ENG-3513
  render() {
    const stateOptions = this.props.usStateOptions;
    const stateKeys = Object.keys(stateOptions || {});
    return (
      <Fragment>
        <InputText
          label="Family Name"
          type="text"
          name="placement_period[family_name]"
          id="placement_period[family_name]"
          required={true}
          value={this.props.familyName || ""}
        />
        <InputText
          label="Phone Number"
          type="tel"
          name="placement_period[phone_number_str]"
          id="placement_period[phone_number_str]"
          required
          value={this.props.phoneNumber || ""}
        />
        <InputText
          label="Street Address"
          type="text"
          name="placement_period[address_street]"
          id="placement_period[address_street]"
          required
          value={get(this.props, "address.addressStreet", "")}
        />
        <InputText
          label="City"
          type="text"
          name="placement_period[address_city]"
          id="placement_period[address_city]"
          required
          value={get(this.props, "address.addressCity", "")}
        />
        <InputFilterable
          label="State"
          required
          name="placement_period[address_state]"
          id="select_state"
          isDisabled={false}
          onChange={state => this.stateChanged(state)}
          disabled={false}
          options={stateKeys.map(label => ({
            label: label,
            value: stateOptions[label],
          }))}
          value={this.state.selectedState}
        />
        <InputText
          label="Zip Code"
          type="text"
          name="placement_period[address_zipcode]"
          id="placement_period[address_zipcode]"
          value={get(this.props, "address.addressZipcode", "")}
          required
        />
      </Fragment>
    );
  }
}

FamilyInformationFields.propTypes = {
  /** Name of the family */
  familyName: PropTypes.string,
  /** Phone number of the family */
  phoneNumber: PropTypes.string,
  /** Address of the family */
  address: PropTypes.shape({
    addressStreet: PropTypes.string,
    addressCity: PropTypes.string,
    addressState: PropTypes.string,
    addressZipcode: PropTypes.string,
  }),
  /** US States options for user to choose */
  usStateOptions: PropTypes.object,
};

export default FamilyInformationFields;
