import { Text } from "@heart/components";
import { DateTime } from "luxon";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "prevention.agency_service_records.table_component"
);
const { t: datetimeT } = translationWithRoot("datetime.distance_in_words", {
  escapeJavascriptRoot: true,
});

const { t: billingStructureT } = translationWithRoot(
  "activerecord.enums.agency_service_rate",
  {
    escapeJavascriptRoot: true,
  }
);

/** Visible for testing purposes */
export const formatRecipients = recipients =>
  recipients
    .map(recipient => recipient.fullName)
    // we do not want to have a semi-colon at the end of the last recipient
    // the following join/split is a hack to add semi-colons between recipients
    // and not at the end of the last recipient
    .join(";\n")
    .split("\n")
    .map((fullName, idx) => (
      <Fragment key={`x${idx}`}>
        <Text>{fullName}</Text>
        <br />
      </Fragment>
    ));

/** Visible for testing purposes */
export const formatTime = record => {
  const startTime = DateTime.fromISO(record.startTime).toLocaleString(
    DateTime.TIME_SIMPLE
  );
  const endTime = DateTime.fromISO(record.endTime).toLocaleString(
    DateTime.TIME_SIMPLE
  );

  return (
    <Text>
      {record.date}
      <br />
      {`${startTime} - ${endTime}`}
    </Text>
  );
};

/** Visible for testing purposes */
export const formatUnitsBilled = record => {
  if (record.unitsUtilized) {
    if (record.unitsUtilized > 1) {
      return <Text>{record.unitsUtilized} Sessions</Text>;
    }

    return <Text>{record.unitsUtilized} Session</Text>;
  }

  if (record.mileHundredths) {
    const miles = record.mileHundredths / 100;

    if (miles > 1) {
      return <Text>{miles} Miles</Text>;
    }
    return <Text>{miles} Mile</Text>;
  }

  if (record.minutesBilled) {
    if (record.minutesBilled >= 60) {
      let hours = datetimeT("x_hours.one");
      if (Math.floor(record.minutesBilled / 60) > 1) {
        hours = datetimeT("x_hours.other", {
          count: Math.floor(record.minutesBilled / 60),
        });
      }

      let minute = datetimeT("x_minutes.one");
      if (record.minutesBilled % 60 > 1) {
        minute = datetimeT("x_minutes.other", {
          count: record.minutesBilled % 60,
        });
      } else if (record.minutesBilled % 60 === 0) {
        minute = undefined;
      }

      return (
        <Text>
          {hours} <br /> {minute}
        </Text>
      );
    }

    return (
      <Text>{datetimeT("x_minutes", { count: record.minutesBilled })}</Text>
    );
  }

  return (
    <Text>{billingStructureT(record.agencyServiceRate.billingStructure)}</Text>
  );
};

const columns = [
  {
    cell: record => {
      const rate = record.agencyServiceRate;
      return (
        <Fragment>
          <Text textStyle={"body-200"}>{rate.agencyService.name}</Text>
          <br />
          <Text textStyle={"body-100"}>{rate.code}</Text>
        </Fragment>
      );
    },
    columnName: { name: t("columns.service_rate_code") },
    id: "service_rate_code",
  },
  {
    cell: formatTime,
    columnName: { name: t("columns.date_time_of_service") },
    id: "date_time_of_service",
  },
  {
    cell: record => record.methodOfEngagement || record.methodOfEngagementOther,
    columnName: { name: t("columns.method_of_engagement") },
    id: "method_of_engagement",
  },
  {
    cell: "notes",
    columnName: { name: t("columns.notes") },
    id: "notes",
  },
  {
    cell: record => formatRecipients(record.recipients),
    columnName: { name: t("columns.people_involved") },
    id: "people_involved",
  },
  {
    cell: formatUnitsBilled,
    columnName: { name: t("columns.units_billed") },
    id: "unit_billed",
  },
  {
    cell: () => <Text>TODO</Text>, // TODO ENG-20095
    columnName: { name: t("columns.recorded_by"), justify: "end" },
    id: "recorded_by",
  },
];

export default columns;
