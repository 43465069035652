import { Button } from "@heart/components";
import Honeybadger from "@honeybadger-io/js";
import HelloSign from "hellosign-embedded";
import PropTypes from "prop-types";

const HELLOSIGN_MOBILE_BREAKPOINT = 768;

const t = (key, values) =>
  I18n.t(
    `javascript.components.hellosign.hellosign_signature_button.${key}`,
    values
  );

const newHelloSignClient = () => {
  const hsLocale =
    I18n.locale === "es" ? HelloSign.locales.ES_MX : HelloSign.locales.EN_US;

  return new HelloSign({
    clientId: Binti.HelloSign.clientId,
    debug: Binti.HelloSign.isTestMode,
    locale: hsLocale,
    skipDomainVerification: Binti.HelloSign.isTestMode,
    allowCancel: true,
  });
};

const HellosignSignatureButton = ({ role, blob, disabled }) => {
  const {
    "complete?": complete,
    "blocked?": blocked,
    name,
    signatureId,
  } = blob;

  Honeybadger.setContext({ sign_for: name, signatureId });

  let buttonLabel;
  if (role === "other_adult_in_home") {
    if (complete) {
      buttonLabel = t("already_signed");
    } else {
      buttonLabel = t("sign");
    }
  } else if (complete) {
    buttonLabel = t("name_already_signed", { name });
  } else if (blocked) {
    buttonLabel = t("cannot_sign_yet", { name });
  } else {
    buttonLabel = t("sign_for_name", { name });
  }

  const onOpen = () => {
    let canceled = false;
    let signed = false;
    const client = newHelloSignClient();

    // Move the Zendesk widget to the bottom left (bl) hand side so it
    // doesn't cover the HelloSign signature insert button on mobile.
    if (window.innerWidth < HELLOSIGN_MOBILE_BREAKPOINT) {
      window.$zopim?.livechat?.window?.setPosition("bl");
    }

    client.on(HelloSign.events.CANCEL, () => {
      canceled = true;
    });

    client.on(HelloSign.events.SIGN, () => {
      signed = true;
    });

    client.on(HelloSign.events.CLOSE, () => {
      // Move the Zendesk widget back to the bottom right (br).
      // (not strictly necessary since we reload, but whatever.)
      window.$zopim?.livechat?.window?.setPosition("br");

      if (canceled) {
        if (blob.reference) {
          window.location = "/";
        } else {
          window.location.reload();
        }
      } else if (signed) {
        if (blob.reference) {
          window.location = blob.nextUrl;
        } else {
          window.location.reload();
        }
      }
    });

    client.on(HelloSign.events.ERROR, eventData => {
      if (eventData.description === "Error: Unauthorized") {
        if (blob.reference) {
          window.location = blob.nextUrl;
        } else {
          window.location.reload();
        }
      } else {
        Honeybadger.notify(eventData.description, { tags: "hellosign" });
        // eslint-disable-next-line no-alert
        alert(t("temporary_difficulties"));
        throw HelloSign.EVENT_ERROR;
      }
    });

    client.open(blob.data.signUrl);
  };

  const buttonDisabled = complete || blocked;
  return (
    <Button disabled={buttonDisabled || disabled} onClick={onOpen}>
      {buttonLabel}{" "}
    </Button>
  );
};

HellosignSignatureButton.propTypes = {
  role: PropTypes.string.isRequired,
  blob: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default HellosignSignatureButton;
