import { SurfaceFieldset } from "@heart/components";
import styles from "@heart/components/inputs/common/RadioAndCheckboxCommonStyles.module.scss";

import T from "@components/T";

const NumberOfApplicants = () => (
  <SurfaceFieldset title={<T t="number_of_applicants.are_you_single_or_two" />}>
    <div className="radio">
      <label
        htmlFor="application_single_parent_true"
        className={styles.radioCheckContainer}
      >
        <input
          required="required"
          type="radio"
          value="true"
          name="application[single_parent]"
          id="application_single_parent_true"
        />
        <T t={"number_of_applicants.single_applicant"} />
      </label>
    </div>
    <div className="radio">
      <label
        htmlFor="application_single_parent_false"
        className={styles.radioCheckContainer}
      >
        <input
          required="required"
          type="radio"
          value="false"
          name="application[single_parent]"
          id="application_single_parent_false"
        />
        <T t={"number_of_applicants.two_applicants"} />
      </label>
    </div>
  </SurfaceFieldset>
);

export default NumberOfApplicants;
