/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

import PlacementProviderForApplication from "@graphql/queries/PlacementProviderForApplication.graphql";

const ApplicationRefetchQuery = gql`
  query Application($id: ID!) {
    application(id: $id) {
      id
      type
      otherAdultsInHomeKnown
      otherAdultsInHomeCount
      statusDate
      status
      createdAt
      applicationOtherAdults {
        humanPlacementProviderRoleId
      }
    }
  }
`;

const getPlacementProviderChangeRefetchQueries = ({
  placementProviderId,
  applicationId,
}) => [
  {
    query: PlacementProviderForApplication,
    variables: { placementProviderId, applicationId },
  },
  {
    query: ApplicationRefetchQuery,
    variables: { id: applicationId },
  },
];

export { getPlacementProviderChangeRefetchQueries };
