import { isEmpty } from "lodash";

export const sortByDocument = (
  { allAttachmentsForRecord: attachmentsA, isOverridden: isOverriddenA },
  { allAttachmentsForRecord: attachmentsB, isOverridden: isOverriddenB }
) => {
  /** Sorted based on the name of the first attachment if one exists, then by
   * which requirements are overridden, and then by those without any attachments
   * or overrides
   */
  if (!isEmpty(attachmentsA) && isEmpty(attachmentsB)) return 1;
  else if (!isEmpty(attachmentsB) && isEmpty(attachmentsA)) return -1;
  else if (!isEmpty(attachmentsA) && !isEmpty(attachmentsB))
    return attachmentsA[0].name.toLowerCase() <
      attachmentsB[0].name.toLowerCase()
      ? 1
      : -1;
  else if (isOverriddenA) return 1;
  else if (isOverriddenB) return -1;
  return 1;
};

export const sortByProgress = (
  {
    progressPercentage: progressA,
    isFulfilled: isFulfilledA,
    isOverridden: isOverriddenA,
  },
  {
    progressPercentage: progressB,
    isFulfilled: isFulfilledB,
    isOverridden: isOverriddenB,
  }
) => {
  if (isFulfilledA && !isOverriddenA) return 1;
  else if (isFulfilledB && !isOverriddenB) return -1;
  else if (isOverriddenA) return 1;
  else if (isOverriddenB) return -1;
  return progressA > progressB ? 1 : -1;
};
