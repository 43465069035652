import { gql, useQuery } from "@apollo/client";
import { Flex, Link, LoadingOverlay } from "@heart/components";
import { Table, Td, Th, Thead, Tr } from "@heart/components/table/Table";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

const { t } = translationWithRoot("placement_search");

const responseViewQuery = gql`
  query responseViewQuery($placementSearchId: ID!) {
    placementSearch(id: $placementSearchId) {
      id
      response
      custodialAgency {
        id
        name
      }
      assignedFromSearch {
        primaryWorkerAssigned {
          id
          name
          email
          phoneNumber
        }
      }
    }
  }
`;

const ResponseView = ({ placementSearchId }) => {
  const { data, loading: dataLoading } = useQuery(responseViewQuery, {
    variables: { placementSearchId },
  });

  const placementSearch = data?.placementSearch || {};

  return (
    <LoadingOverlay active={dataLoading}>
      <Flex column>
        <Table>
          <Thead>
            <Tr>
              <Th>Label</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tr>
            <Td>{t("response.label")}</Td>
            <Td>
              {placementSearch.response
                ? t(`responses.${placementSearch.response}`)
                : null}
            </Td>
          </Tr>
        </Table>
        <Flex column>
          {`${t("response.more_info_line_1")} ${
            placementSearch?.custodialAgency?.name
          } ${t("response.more_info_line_2")}`}
          <div>
            {placementSearch?.assignedFromSearch?.primaryWorkerAssigned?.name}
          </div>
          <Link
            href={`mailto:${placementSearch?.assignedFromSearch?.primaryWorkerAssigned?.email}`}
          >
            {placementSearch?.assignedFromSearch?.primaryWorkerAssigned?.email}
          </Link>
          <div>
            {
              placementSearch?.assignedFromSearch?.primaryWorkerAssigned
                ?.phoneNumber
            }
          </div>
        </Flex>
      </Flex>
    </LoadingOverlay>
  );
};

ResponseView.propTypes = {
  placementSearchId: BintiPropTypes.ID,
};

export default ResponseView;
