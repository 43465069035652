import classNames from "classnames";
import PropTypes from "prop-types";

import completedCheckmark from "@sprockets/images/stage_icons/icon-completed-checkmark.svg";

import styles from "./ProgressArc.module.scss";
import ProgressIcon from "./ProgressIcon";

/**
 * An icon that represents the progress of a task, either partial
 * or complete. With a completed task, a checkmark is displayed instead.
 * With a partial task, a blue arc is displayed that represents the
 * percentage of completion in a pie chart style (via the ProgressIcon
 * component).
 *
 * A secondary progress can be displayed as well, which is represented
 * by a second, reddish arc in the icon. If you specify this secondary
 * progress, you must also specify a secondaryProgressTitle to describe
 * what the secondary progress represents and that title must include
 * the number value of the secondary progress.
 */
const ProgressArc = ({
  progress,
  progressIncomplete,
  tableIcon,
  secondaryProgress,
  secondaryProgressTitle,
}) => {
  let progressNumber = progress;
  let secondaryProgressNumber = secondaryProgress;

  if (typeof progress === "string") {
    progressNumber = parseInt(progress, 10);
  }

  if (typeof secondaryProgress === "string") {
    secondaryProgressNumber = parseInt(progress, 10);
  }

  return (
    <div className={tableIcon ? "table-icon" : styles.container}>
      {progressNumber === 100 && !progressIncomplete ? (
        <img
          className={classNames("svg-checkmark", styles.icon)}
          src={completedCheckmark}
          alt="Completed checkmark"
          data-testid="progress-arc-complete"
        />
      ) : (
        <ProgressIcon
          className={styles.icon}
          progress={progressNumber}
          secondaryProgress={secondaryProgressNumber}
          secondaryProgressTitle={secondaryProgressTitle}
        />
      )}
    </div>
  );
};

ProgressArc.propTypes = {
  /** The percent completeness of the task this icon represents */
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  /** Indicates wether task is considered complete. There are times when progress is 100%
   *  but the task isn't "complete". In those cases we display a full blue circle.
   */
  progressIncomplete: PropTypes.bool,
  /** Applies the styles needed for displaying this icon in a table. Makes the icon
   *  smaller and centers it.
   */
  tableIcon: PropTypes.bool,
  /**
   * an optional "sub" completeness of a task. Should be less than progress,
   */
  secondaryProgress: PropTypes.number,
  /**
   * An additional progress title to add describing what the secondary progress
   * represents.
   */
  secondaryProgressTitle: PropTypes.string,
};

export default ProgressArc;
