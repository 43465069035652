import {
  Surface,
  GraphQLDataTable,
  Pill,
  Flex,
  Icons,
} from "@heart/components";
import { useState } from "react";

import Events from "@graphql/queries/integrations/Events.graphql";

import EventEditModal from "./EventEditModal";

/**
 * A Table to display integration events.
 */
const EventsTable = () => {
  const [showEditModal, setShowEditModal] = useState(null);
  const [eventIdToEdit, setEventIdToEdit] = useState(null);

  return (
    <Surface hideTitle title="Integration Events">
      <GraphQLDataTable
        query={Events}
        queryTitle="events"
        filters={[]}
        defaultSort={{ sortBy: "slug", sortDirection: "ASC" }}
        columns={[
          {
            columnName: { name: "Event Slug" },
            id: "slug",
            sortBy: "slug",
            cell: "slug",
          },
          {
            columnName: { name: "Status" },
            id: "status",
            sortBy: "status",
            cell: ({ status }) => (
              <Pill
                variant={status === "enabled" ? "success" : "warning"}
                text={status}
              />
            ),
          },
          {
            columnName: { name: "Subscriber Count" },
            id: "subscriberCount",
            sortBy: "subscriberCount",
            cell: "subscriberCount",
          },
          {
            columnName: { name: "Actions" },
            id: "actions",
            cell: ({ id }) => (
              <Flex row>
                <Icons.Pencil
                  description="Edit"
                  onClick={() => {
                    setEventIdToEdit(id);
                    setShowEditModal(true);
                  }}
                />
              </Flex>
            ),
          },
        ]}
      />
      <If condition={showEditModal}>
        <EventEditModal
          eventIdToEdit={eventIdToEdit}
          resetStateCallback={() => {
            setEventIdToEdit(null);
            setShowEditModal(false);
          }}
        />
      </If>
    </Surface>
  );
};
EventsTable.propTypes = {};

export default EventsTable;
