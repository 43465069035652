import { Text } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import {
  Caption,
  Thead,
  Th,
  Table,
  Tr,
  Td,
  Tbody,
} from "@components/reusable_ui/Table";

const { T } = translationWithRoot("availability_request.recipients_table");

const RecipientsTable = ({
  agencyPlacements,
  showMobilePhoneNumber,
  showEmail,
}) => {
  const formMobilePhoneNumberCell = agencyPlacement => {
    if (!agencyPlacement.mobilePhoneNumber) {
      return (
        <Text textColor="colors-danger-600">
          <T
            t="missing_mobile_number_html"
            agency_placement_id={agencyPlacement.id}
          />
        </Text>
      );
    }

    if (agencyPlacement.optedOut) {
      return (
        <Text as="div" textColor="colors-danger-600">
          <T t="opted_out" />
        </Text>
      );
    }

    return agencyPlacement.mobilePhoneNumber;
  };

  return (
    <Table>
      <Caption>
        <T t="recipients" />
      </Caption>
      <Thead>
        <Tr>
          <Th>
            <T t="name" />
          </Th>
          <If condition={showEmail}>
            <Th>
              <T t="email" />
            </Th>
          </If>
          <If condition={showMobilePhoneNumber}>
            <Th>
              <T t="mobile_phone_number" />
            </Th>
          </If>
        </Tr>
      </Thead>
      <Tbody>
        {agencyPlacements.map(agencyPlacement => (
          <Tr key={agencyPlacement.id}>
            <Td>{agencyPlacement.name}</Td>
            <If condition={showEmail}>
              <Td>{agencyPlacement.email}</Td>
            </If>
            <If condition={showMobilePhoneNumber}>
              <Td>{formMobilePhoneNumberCell(agencyPlacement)}</Td>
            </If>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

RecipientsTable.propTypes = {
  agencyPlacements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
      mobilePhoneNumber: PropTypes.string,
      optedOut: PropTypes.bool,
    })
  ).isRequired,
  showMobilePhoneNumber: PropTypes.bool.isRequired,
  showEmail: PropTypes.bool.isRequired,
};

export default RecipientsTable;
