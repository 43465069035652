import { useQuery, gql, useMutation } from "@apollo/client";
import {
  Actions,
  Button,
  Flex,
  Icons,
  LoadingOverlay,
  Surface,
  SurfaceForm,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import { Fragment, useState } from "react";

import RequirementTemplateFragment from "@graphql/fragments/RequirementTemplate.graphql";
import ScheduleFragment from "@graphql/fragments/Schedule.graphql";
import ScheduleQuery from "@graphql/queries/Schedule.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import BasicInfoForm from "./BasicInfoForm";
import RequirementTemplateCreate from "./RequirementTemplateCreate";
import RequirementTemplateEdit from "./RequirementTemplateEdit";
import ScheduleTypeEdit from "./ScheduleTypeEdit";

const emptyFormState = {};

/**
 * Schedule component fetches and displays schedule details.
 *
 * @param {string} id - The ID of the schedule.
 * @param {string} agencyId - The ID of the schedule's agency.
 */
const ScheduleEdit = ({ id, agencyId }) => {
  const { data, loading: queryLoading } = useQuery(ScheduleQuery, {
    variables: { id },
  });

  const [updateSchedule, { loading: mutationLoading }] = useMutation(gql`
    ${RequirementTemplateFragment}
    ${ScheduleFragment}
    mutation UpdateSchedule($input: UpdateScheduleInput!) {
      updateSchedule(input: $input) {
        schedule {
          ...Schedule
        }
      }
    }
  `);

  const { formState, setFormAttribute } = useBintiForm(
    data?.schedule || emptyFormState
  );

  const { requirementTemplates } = data?.schedule || {};

  const [showCreateForm, setShowCreateForm] = useState(false);

  const onSubmit = () =>
    updateSchedule({
      variables: { input: { ..._.omit(formState, "requirementTemplates") } },
    });

  return (
    <LoadingOverlay active={queryLoading}>
      <SurfaceForm
        title="Schedule"
        actions={<Actions isSubmitting={mutationLoading} />}
        onSubmit={preventDefault(onSubmit)}
      >
        <BasicInfoForm
          formState={formState}
          setFormAttribute={setFormAttribute}
        />
        <ScheduleTypeEdit
          agencyId={agencyId}
          formState={formState}
          setFormAttribute={setFormAttribute}
        />
      </SurfaceForm>
      <Surface title="Scheduled Requirement Templates">
        {requirementTemplates?.map(requirementTemplate => (
          <RequirementTemplateEdit
            key={requirementTemplate.id}
            id={requirementTemplate.id}
            scheduleId={id}
            agencyId={agencyId}
            templateType={requirementTemplate.type}
          />
        ))}
        {showCreateForm ? (
          <RequirementTemplateCreate
            agencyId={agencyId}
            scheduleId={id}
            onCancel={() => setShowCreateForm(false)}
          />
        ) : null}
        <If condition={!showCreateForm}>
          <Flex justify="end">
            <Button variant="secondary" onClick={() => setShowCreateForm(true)}>
              <Icons.Plus /> Add Requirement Template
            </Button>
          </Flex>
        </If>
      </Surface>
    </LoadingOverlay>
  );
};

ScheduleEdit.propTypes = {
  id: BintiPropTypes.ID,
  agencyId: BintiPropTypes.ID,
};

export default ScheduleEdit;
