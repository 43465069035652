import { Surface, Icons } from "@heart/components";
import I18n from "i18n-js";
import React, { useState } from "react";

import BintiPropTypes from "@lib/BintiPropTypes";

import EditHearingDetails from "./EditHearingDetails";
import ViewHearingDetails from "./ViewHearingDetails";

const HearingDetails = ({ appealId }) => {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <EditHearingDetails
        appealId={appealId}
        onSubmit={() => setEditing(false)}
        onCancel={() => setEditing(false)}
      />
    );
  }

  return (
    <Surface
      title={I18n.t("javascript.components.appeals.hearing_details")}
      secondary={
        <Icons.Pencil
          description={I18n.t(
            "javascript.components.appeals.hearing_details_edit"
          )}
          onClick={() => setEditing(true)}
        />
      }
    >
      <ViewHearingDetails appealId={appealId} />
    </Surface>
  );
};

HearingDetails.propTypes = {
  appealId: BintiPropTypes.ID,
};

export default HearingDetails;
