import { Flex, Icons, InputText } from "@heart/components";
import I18n from "i18n-js";

import { ssoSearchTermVar } from "@components/shared/BintiApolloProvider";

import styles from "./SsoSearch.module.scss";

const SsoSearch = () => (
  <Flex align="center" className={styles.container}>
    <Icons.Search />
    <div className={styles.input}>
      <InputText
        label={I18n.t(
          "javascript.components.permissions.login.sso_search_label"
        )}
        type="search"
        onChange={ssoSearchTermVar}
      />
    </div>
  </Flex>
);

export default SsoSearch;
