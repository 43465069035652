/**
 *  Webpack compiles everything in `app/javascript/packs` as separate entry points.
 *  Usually Webpack only traverses these entrypoints and scoops up any dependencies
 *  it needs as a tree with `packs` files as the root.
 *
 *  In our situation, though, the `ReactRailsUJS` package crawls the entirety of
 *  whatever `require.context` you give it - in order for Rails to confidently be
 *  able to refer to component names via strings (`react_component("SomeComponent")`)
 *  and know that they're in the bundle, we presume.
 *
 *  This is convenient, but it means that we need to exclude specfiles and stories
 *  explicitly (instead of simply not `import`ing them from application code),
 *  which we do here with this regex.  If you need to debug this regex, here's a handy
 *  tool: https://regex101.com/r/4JqTLS/1
 *
 * You can also do `console.error(requireContext.keys())` in this file and check
 * your browser logs to see everything that's matched.
 *
 * require.context() docs:
 * https://webpack.js.org/guides/dependency-management/
 */
const requireContext = require.context(
  "components",
  true,
  /^\.\/(?!.*\.(spec|stories)).*$/
);

export default requireContext;
