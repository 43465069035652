import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { isNumber } from "lodash";
import PropTypes from "prop-types";

const ApplicationDashboardScopeCountsQuery = gql`
  query ApplicationDashboardScopeCounts($qParams: JSON) {
    applicationDashboardScopeCounts(qParams: $qParams) {
      id
      resourceName
      scopeName
      scopeCount
    }
  }
`;

const ApplicationDashboardScopeCount = ({ scopeName, qParams }) => {
  // note that this queries for all the scope simultaneously and that each
  // instance of this component will try to make this call, but apollo will
  // de-duplicate them and re-render them all when the first (and only) call
  // comes back.
  const { data, loading } = useQuery(ApplicationDashboardScopeCountsQuery, {
    variables: { qParams },
  });

  if (loading) return "(...)";

  const scopeCount = data.applicationDashboardScopeCounts.find(
    activeAdminScopeCount => activeAdminScopeCount.scopeName === scopeName
  )?.scopeCount;

  if (isNumber(scopeCount)) return `(${scopeCount})`;

  return false;
};

ApplicationDashboardScopeCount.propTypes = {
  scopeName: PropTypes.string.isRequired,
  qParams: PropTypes.object.isRequired,
};

export default ApplicationDashboardScopeCount;
