import { useMutation } from "@apollo/client";
import {
  Button,
  InputDate,
  InputFilterable,
  InputText,
  Modal,
} from "@heart/components";
import { entries, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useState, Fragment } from "react";

import T from "@components/T";
import NestedMultiFormInput, {
  findOption,
} from "@components/inputs/nested_multi/NestedMultiFormInput";
import Label from "@components/reusable_ui/forms/Label";

import CreateChildInHome from "@graphql/mutations/CreateChildInHome.graphql";
import RemoveChildInHome from "@graphql/mutations/RemoveChildInHome.graphql";
import UpdateChildInHome from "@graphql/mutations/UpdateChildInHome.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import { generateIds } from "@lib/generateId";
import { selectEnumProps } from "@lib/graphqlHelpers";

import styles from "./ChildrenInHome.module.scss";

const Editable = ({
  onCreate,
  onUpdate,
  onCancel,
  applicationId,
  applicationChildInHome,
  RelationshipToApplicantValues,
  ethnicityOptions,
  GenderValues,
  loading,
}) => {
  const [labelIds] = useState(
    generateIds(
      "firstName",
      "middleName",
      "lastName",
      "relationshipToApplicant",
      "relationshipToApplicantDetails",
      "dateOfBirth",
      "ethnicities",
      "gender"
    )
  );
  const [firstName, setFirstName] = useState(
    applicationChildInHome?.userAgencyProfile?.firstName || ""
  );
  const [middleName, setMiddleName] = useState(
    applicationChildInHome?.userAgencyProfile?.middleName || ""
  );
  const [lastName, setLastName] = useState(
    applicationChildInHome?.userAgencyProfile?.lastName || ""
  );
  const [relationshipToApplicant, setRelationshipToApplicant] = useState(
    applicationChildInHome?.relationshipToApplicant
  );
  const [relationshipToApplicantDetails, setRelationshipToApplicantDetails] =
    useState(applicationChildInHome?.relationshipToApplicantDetails || "");
  const [dateOfBirth, setDateOfBirth] = useState(
    applicationChildInHome?.userAgencyProfile?.dateOfBirth
  );
  const [ethnicities, setEthnicities] = useState(
    applicationChildInHome?.userAgencyProfile?.ethnicities || {}
  );
  const [ethnicitiesValid, setEthnicitiesValid] = useState(true);
  const [gender, setGender] = useState(
    applicationChildInHome?.userAgencyProfile?.gender
  );

  let isSubmittable = !loading;
  isSubmittable =
    isSubmittable &&
    ethnicitiesValid &&
    ![firstName, lastName, relationshipToApplicant, dateOfBirth].some(isEmpty);

  if (relationshipToApplicant === "other") {
    isSubmittable = isSubmittable && !isEmpty(relationshipToApplicantDetails);
  }

  return (
    <table
      className={styles.childInHomeTable}
      data-testid="child-in-home-edit-table"
    >
      <tbody>
        <tr>
          <th colSpan={2} className={styles.actionHeader}>
            {!applicationChildInHome &&
              I18n.t(
                "javascript.components.children_in_home.child_in_home_input.add_child_header"
              )}
            {applicationChildInHome &&
              I18n.t(
                "javascript.components.children_in_home.child_in_home_input.edit_child_header"
              )}
          </th>
        </tr>
        <tr>
          <th>
            <Label required htmlFor={labelIds.firstName}>
              {I18n.t("views.common.first_name")}
            </Label>
          </th>
          <td>
            <input
              id={labelIds.firstName}
              type="text"
              value={firstName}
              onChange={({ target }) => {
                setFirstName(target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <th>
            <Label htmlFor={labelIds.middleName}>
              {I18n.t("views.common.middle_name")}
            </Label>
          </th>
          <td>
            <input
              id={labelIds.middleName}
              type="text"
              value={middleName}
              onChange={({ target }) => {
                setMiddleName(target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <th>
            <Label required htmlFor={labelIds.lastName}>
              {I18n.t("views.common.last_name")}
            </Label>
          </th>
          <td>
            <input
              id={labelIds.lastName}
              type="text"
              value={lastName}
              onChange={({ target }) => {
                setLastName(target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <th>
            <Label required htmlFor={labelIds.relationshipToApplicant}>
              <T t="children_in_home.common.relationship_to_applicant" />
            </Label>
          </th>
          <td>
            <div className="contains-inputs">
              <InputFilterable
                labeledExternally
                id={labelIds.relationshipToApplicant}
                onChange={({ value }) => {
                  setRelationshipToApplicant(value);
                }}
                {...selectEnumProps({
                  value: relationshipToApplicant,
                  enumType: RelationshipToApplicantValues,
                  i18nPrefix:
                    "application_child_in_home.relationships_to_applicant",
                })}
              />
              {relationshipToApplicant === "other" && (
                <InputText
                  label={
                    <T t="children_in_home.common.relationship_to_applicant_details" />
                  }
                  required
                  type="text"
                  id={labelIds.relationshipToApplicantDetails}
                  value={relationshipToApplicantDetails}
                  onChange={setRelationshipToApplicantDetails}
                />
              )}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <Label required htmlFor={labelIds.dateOfBirth}>
              {I18n.t("views.common.date_of_birth")}
            </Label>
          </th>
          <td>
            <InputDate
              id={labelIds.dateOfBirth}
              labeledExternally
              value={dateOfBirth}
              onChange={setDateOfBirth}
            />
          </td>
        </tr>
        <tr>
          <th>
            <Label id={labelIds.ethnicities}>
              {I18n.t("views.common.ethnicity")}
            </Label>
          </th>
          <td>
            <NestedMultiFormInput
              labeledExternally
              id={labelIds.ethnicities}
              options={ethnicityOptions}
              selectedOptions={ethnicities}
              onSelectedOptionsChange={(newEthnicities, { valid }) => {
                setEthnicities(newEthnicities);
                setEthnicitiesValid(valid);
              }}
            />
          </td>
        </tr>
        <tr>
          <th>
            <Label htmlFor={labelIds.gender}>
              {I18n.t("views.common.gender")}
            </Label>
          </th>
          <td>
            <InputFilterable
              labeledExternally
              id={labelIds.gender}
              onChange={option => {
                if (option) {
                  setGender(option.value);
                } else {
                  setGender(null);
                }
              }}
              isClearable
              {...selectEnumProps({
                value: gender,
                enumType: GenderValues,
                i18nPrefix: "common.gender",
              })}
            />
          </td>
        </tr>
        <tr className={styles.actionRow}>
          <td colSpan={2}>
            {!applicationChildInHome && (
              <Button
                disabled={!isSubmittable}
                onClick={() => {
                  onCreate({
                    applicationId,
                    firstName,
                    middleName,
                    lastName,
                    relationshipToApplicant,
                    relationshipToApplicantDetails,
                    dateOfBirth,
                    gender,
                    ethnicities,
                  });
                }}
              >
                <T t="children_in_home.child_in_home_input.add_child_to_home" />
              </Button>
            )}
            <Button variant="secondary" onClick={onCancel}>
              {I18n.t("views.common.cancel")}
            </Button>
            {applicationChildInHome && (
              <Button
                disabled={!isSubmittable}
                onClick={() => {
                  onUpdate({
                    applicationChildInHomeId: applicationChildInHome.id,
                    firstName,
                    middleName,
                    lastName,
                    relationshipToApplicant,
                    relationshipToApplicantDetails,
                    dateOfBirth,
                    gender,
                    ethnicities,
                  });
                }}
              >
                <T t="children_in_home.child_in_home_input.update_child" />
              </Button>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

Editable.propTypes = {
  applicationId: BintiPropTypes.ID,
  applicationChildInHome: PropTypes.object,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  RelationshipToApplicantValues: PropTypes.object.isRequired,
  ethnicityOptions: PropTypes.array.isRequired,
  GenderValues: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

const formatEthnicities = (ethnicities, ethnicityOptions) => {
  if (!ethnicities) return false;

  return entries(ethnicities)
    .map(([path, details]) => {
      const option = findOption(path, ethnicityOptions);

      if (details) {
        return `${option.label} (${details})`;
      }

      return option.label;
    })
    .sort()
    .join(", ");
};

const Display = ({
  applicationChildInHome,
  ethnicityOptions,
  onEdit,
  onRemove,
  readOnly,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onCancel = () => setShowDeleteModal(false);

  return (
    <Fragment>
      <table
        className={styles.childInHomeTable}
        data-testid="child-in-home-display-table"
      >
        <tbody>
          <tr>
            <th>{I18n.t("views.common.name")}</th>
            <td>
              {applicationChildInHome.userAgencyProfile.firstName}{" "}
              {applicationChildInHome.userAgencyProfile.middleName}{" "}
              {applicationChildInHome.userAgencyProfile.lastName}
            </td>
          </tr>
          <tr>
            <th>
              <T t="children_in_home.common.relationship_to_applicant" />
            </th>
            <td>
              {I18n.t(
                `activerecord.enums.application_child_in_home.relationships_to_applicant.${applicationChildInHome.relationshipToApplicant}`
              )}
              {applicationChildInHome.relationshipToApplicant === "other" && (
                <span>
                  {" "}
                  ({applicationChildInHome.relationshipToApplicantDetails})
                </span>
              )}
            </td>
          </tr>
          <tr>
            <th>{I18n.t("views.common.date_of_birth")}</th>
            <td>
              {I18n.l(applicationChildInHome.userAgencyProfile.dateOfBirth)}
            </td>
          </tr>
          <tr>
            <th>{I18n.t("views.common.ethnicity")}</th>
            <td>
              {formatEthnicities(
                applicationChildInHome?.userAgencyProfile?.ethnicities,
                ethnicityOptions
              )}
            </td>
          </tr>
          <tr>
            <th>{I18n.t("views.common.gender")}</th>
            <td>
              <Choose>
                <When
                  condition={applicationChildInHome.userAgencyProfile.gender}
                >
                  {I18n.t(
                    `activerecord.enums.common.gender.${applicationChildInHome.userAgencyProfile.gender}`
                  )}
                </When>
              </Choose>
            </td>
          </tr>
          <If condition={!readOnly}>
            <tr className={styles.actionRow}>
              <td colSpan={2}>
                <Button onClick={onEdit}>
                  <T t="children_in_home.child_in_home_input.edit_child" />
                </Button>
                <Button
                  variant="danger"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <T t="children_in_home.child_in_home_input.remove_child" />
                </Button>
              </td>
            </tr>
          </If>
        </tbody>
      </table>
      <If condition={showDeleteModal}>
        <Modal
          hidden={!showDeleteModal}
          title={I18n.t(
            "javascript.components.children_in_home.delete_confirm_title"
          )}
          onCancel={onCancel}
          onSubmit={() => onRemove({ id: applicationChildInHome.id })}
        >
          {I18n.t("javascript.components.children_in_home.delete_confirm_body")}
        </Modal>
      </If>
    </Fragment>
  );
};

Display.propTypes = {
  applicationChildInHome: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  RelationshipToApplicantValues: PropTypes.object.isRequired,
  ethnicityOptions: PropTypes.array.isRequired,
  GenderValues: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

const ChildInHomeInput = ({
  applicationId,
  applicationChildInHome,
  RelationshipToApplicantValues,
  ethnicityOptions,
  GenderValues,
  onCreateDone,
  readOnly,
}) => {
  const [isEditable, setIsEditable] = useState(!applicationChildInHome);
  const [createChildInHome, { loading: createLoading }] =
    useMutation(CreateChildInHome);
  const [updateChildInHome, { loading: updateLoading }] =
    useMutation(UpdateChildInHome);
  const [removeChildInHome, { loading: removeLoading }] =
    useMutation(RemoveChildInHome);

  const loading = createLoading || updateLoading || removeLoading;

  if (isEditable) {
    const onCreate = variables => {
      createChildInHome({ variables }).then(() => {
        onCreateDone();
      });
    };

    const onUpdate = variables => {
      updateChildInHome({ variables }).then(() => {
        setIsEditable(false);
      });
    };

    const onCancel = () => {
      if (onCreateDone) {
        onCreateDone();
      } else {
        setIsEditable(false);
      }
    };

    return (
      <Editable
        {...{
          applicationId,
          applicationChildInHome,
          RelationshipToApplicantValues,
          ethnicityOptions,
          GenderValues,
          onUpdate,
          onCreate,
          onCancel,
          loading,
        }}
      />
    );
  }

  const onRemove = variables => {
    removeChildInHome({ variables });
  };

  return (
    <Display
      applicationChildInHome={applicationChildInHome}
      onEdit={() => setIsEditable(true)}
      {...{
        applicationChildInHome,
        RelationshipToApplicantValues,
        ethnicityOptions,
        GenderValues,
        onRemove,
        readOnly,
      }}
    />
  );
};

ChildInHomeInput.propTypes = {
  applicationId: BintiPropTypes.ID.isRequired,
  applicationChildInHome: PropTypes.object,
  RelationshipToApplicantValues: PropTypes.object.isRequired,
  ethnicityOptions: PropTypes.array.isRequired,
  GenderValues: PropTypes.object.isRequired,
  onCreateDone: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default ChildInHomeInput;
