import { Flex, FlexItem, InputFilterable } from "@heart/components";

import FormSelect from "./FormSelect";

const requirePresenceOptions = [
  { value: "true", label: "Treat as error, don't replace form" },
  { value: "false", label: "Add anyway" },
];

const ReplaceForm = () => (
  <Flex row>
    <FlexItem expand="sm">
      <FormSelect name="to_remove_slug" label="Form to remove" />
    </FlexItem>

    <FlexItem expand="sm">
      <FormSelect name="to_add_slug" label="Form to add" />
    </FlexItem>

    <FlexItem expand="sm">
      <InputFilterable
        label="When the form is not in the application's template?"
        name="require_presence_in_application_template"
        defaultValue={requirePresenceOptions[0]}
        options={requirePresenceOptions}
      />
    </FlexItem>
  </Flex>
);

export default ReplaceForm;
