import { DateTime } from "luxon";
import PropTypes from "prop-types";

// TODO localize me https://binti.atlassian.net/browse/ENG-18003
const Timestamp = ({ datetimestamp, label }) =>
  !!datetimestamp && (
    <span>
      {" "}
      ({label}{" "}
      {DateTime.fromISO(datetimestamp).toLocaleString(DateTime.DATE_MED)}){" "}
    </span>
  );

Timestamp.propTypes = {
  /* timestamp is in ISO format */
  datetimestamp: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
};

export default Timestamp;
