import PropTypes from "prop-types";

const DownloadBlankFormLink = ({ doc }) => (
  <a
    href={`/applications/${doc.applicationId}/documents/${doc.id}/blank_form`}
    target={`blank_form-${doc.id}`}
  >
    Download Blank Form
  </a>
);

DownloadBlankFormLink.propTypes = {
  doc: PropTypes.object.isRequired,
};

export default DownloadBlankFormLink;
