import { InputCheckbox } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { OVERRIDE_REASON_KEYS } from "@lib/overridableHelpers";

import styles from "./OverridableInput.module.scss";
import OverridableReasonSpecInput from "./OverridableReasonSpecInput";

/**
 * Component to allow setting the allowable overrides for a particular role.
 *    Upload Types or Forms > New > Override Options
 */
const OverridableRoleSpecInput = ({ role, spec = {}, updateRoleSpec }) => {
  const [showReasons, setShowReasons] = useState(!isEmpty(spec));

  //
  // We can open or close the list of reasons for this role based on two
  // criteria:
  //
  // 1. There is an actual specification in place for this role and we need
  //    to show it. This specification may change because of interactions
  //    the user takes within this list of reasons or it may change because
  //    someone clicked the "Reset to defaults" button at the top level.
  // 2. The user has clicked on the checkbox for this role so they can add
  //    reasons, but as of yet there are no actually reasons specified.
  //
  // The showReasons state lets us handle case number 2 and this useEffect
  // block keeps that state in sync with the props if they change without
  // interacting in this list because of, say, clicking the "Reset to defaults"
  // button.
  //

  useEffect(() => {
    setShowReasons(!isEmpty(spec));
  }, [isEmpty(spec)]);

  const toggleShowReasons = () => {
    if (showReasons) {
      updateRoleSpec(undefined);
    }
    setShowReasons(!showReasons);
  };

  const updateReasonSpec = reason => reasonSpec => {
    updateRoleSpec(Object.assign({}, spec, { [reason]: reasonSpec }));
  };

  // note that we don't give a name to the checkbox below because we don't
  // want to submit it.
  return (
    <div className={styles.roleBlock} data-testid="roleblock">
      <InputCheckbox
        value={showReasons}
        onChange={toggleShowReasons}
        label={role}
      />
      {showReasons &&
        OVERRIDE_REASON_KEYS.map(reason => (
          <OverridableReasonSpecInput
            key={reason}
            reason={reason}
            spec={spec[reason]}
            updateReasonSpec={updateReasonSpec(reason)}
          />
        ))}
    </div>
  );
};

OverridableRoleSpecInput.propTypes = {
  role: PropTypes.string.isRequired,
  updateRoleSpec: PropTypes.func.isRequired,
  spec: PropTypes.object,
};

export default OverridableRoleSpecInput;
