import {
  DetailsTable,
  Flex,
  Button,
  DisplaySSN,
  TableCellList,
  LoadingOverlay,
} from "@heart/components";
import { isEmpty, isNull } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

import { dateTimeFromAnyFormat } from "@lib/dates";

import ConfirmationModal from "./ConfirmationModal";

const { t } = translationWithRoot(
  "family_finding.potential_kin_search.personal_information"
);

const { t: commonT } = translationWithRoot(
  "family_finding.potential_kin_search.common"
);

const PersonalInformation = ({
  searchData,
  createClearPersonReport,
  reportData,
  comprehensiveReportPending,
  comprehensiveReportLoaded,
  mutationLoading,
}) => {
  const [hideConfirmationModal, setHideConfirmationModal] = useState(true);

  return (
    <Flex gap="300" column>
      <DetailsTable
        details={[
          { label: t("first_name"), value: searchData.firstName },
          {
            label: t("middle_name"),
            value: searchData.middleName,
          },
          {
            label: t("last_name"),
            value: searchData.lastName,
          },
          {
            label: t("suffix"),
            value: searchData.suffix,
          },
          {
            label: t("aliases"),
            value: <TableCellList gap="200" items={searchData.aliases || []} />,
          },
          {
            label: t("date_of_birth"),
            value: searchData.dateOfBirth
              ? dateTimeFromAnyFormat(searchData.dateOfBirth, {
                  zone: "UTC",
                  setZone: true,
                }).toLocaleString()
              : null,
          },
          {
            label: t("date_of_death"),
            value: searchData.dateOfDeath
              ? dateTimeFromAnyFormat(searchData.dateOfDeath, {
                  zone: "UTC",
                  setZone: true,
                }).toLocaleString()
              : null,
          },
          {
            label: t("social_security_number"),
            value: <DisplaySSN ssn={searchData.ssn} />,
          },
          {
            label: t("addresses"),
            value: (
              <TableCellList
                gap="200"
                items={searchData.addresses || []}
                transformItem={({ addressLine1, city, state, zip }) => {
                  const prelude = [addressLine1, city, state]
                    .filter(part => !isEmpty(part))
                    .join(", ");
                  return isEmpty(zip) ? prelude : `${prelude} ${zip}`;
                }}
              />
            ),
          },
          {
            label: t("phone_numbers"),
            value: (
              <TableCellList
                gap="200"
                items={searchData.phoneNumbers || []}
                transformItem={({ phoneNumber, phoneNumberType }) =>
                  phoneNumberType
                    ? `${phoneNumber} (${phoneNumberType})`
                    : phoneNumber
                }
              />
            ),
          },
          {
            label: t("email_addresses"),
            value: (
              <TableCellList
                gap="200"
                items={searchData.emailAddresses || []}
              />
            ),
          },
        ]}
      />
      <LoadingOverlay
        active={!comprehensiveReportPending && !comprehensiveReportLoaded}
      >
        <DetailsTable
          caption={t("comprehensive_report_info")}
          subcaption={
            comprehensiveReportLoaded
              ? ""
              : t("comprehensive_report_info_caption")
          }
          details={[
            {
              label: t("social_media_links"),
              value: (
                <Fragment>
                  <If condition={reportData?.socialMediaLinks}>
                    <TableCellList
                      gap="200"
                      items={reportData?.socialMediaLinks || []}
                    />
                  </If>
                  <If
                    condition={
                      !comprehensiveReportLoaded &&
                      isNull(reportData?.socialMediaLinks)
                    }
                  >
                    {t("no_data")}
                  </If>
                </Fragment>
              ),
            },
            {
              label: t("risk_flags"),
              value: (
                <Fragment>
                  <If condition={reportData?.riskFlags}>
                    <TableCellList
                      gap="200"
                      items={reportData?.riskFlags || []}
                    />
                  </If>
                  <If
                    condition={
                      !comprehensiveReportLoaded &&
                      isNull(reportData?.riskFlags)
                    }
                  >
                    {t("no_data")}
                  </If>
                </Fragment>
              ),
            },
          ]}
          actions={
            <Fragment>
              <ConfirmationModal
                submitting={mutationLoading}
                onSubmit={async () => {
                  await createClearPersonReport();
                  setHideConfirmationModal(true);
                }}
                onCancel={() => setHideConfirmationModal(true)}
                hidden={hideConfirmationModal}
              />
              <If condition={comprehensiveReportPending}>
                <Button
                  submitting={mutationLoading}
                  onClick={() => setHideConfirmationModal(false)}
                >
                  {commonT("run_comprehensive_search")}
                </Button>
              </If>
            </Fragment>
          }
        />
      </LoadingOverlay>
    </Flex>
  );
};

PersonalInformation.propTypes = {
  // Data returned from the initial Person Search query
  searchData: PropTypes.object.isRequired,
  // Function to run CreateClearPersonReport mutation
  createClearPersonReport: PropTypes.func.isRequired,
  // Data returned from the comprehensive Person Report query
  reportData: PropTypes.object.isRequired,
  // True if the ClearPersonReport (aka Comprehensive Search) record has not been created in the DB
  comprehensiveReportPending: PropTypes.bool.isRequired,
  // True if CreateClearPersonReport is currently loading
  mutationLoading: PropTypes.bool,
  // True if Clear has generated the report and we've finished polling for the data
  comprehensiveReportLoaded: PropTypes.bool,
};

export default PersonalInformation;
