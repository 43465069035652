import { useEffect } from "react";

import toast from "./toast";

const ToastComponent = ({ type, title, message }) => {
  useEffect(() => {
    toast[type]({ title, message });
  }, [type, title, message]);
  return null;
};

export default ToastComponent;
