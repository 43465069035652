import { Button, Flex, Icons, Modal, UploadButton } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";
import OverrideModal from "@components/requirements/OverrideModal";
import SigningButtons from "@components/requirements/SigningButtons";

import { policy } from "@lib/graphqlHelpers";
import { isOverridable } from "@lib/overridableHelpers";

import createOrEditOverrideRecord from "./createOrEditOverrideRecord";
import uploadRequirementFiles from "./uploadRequirementFiles";

const { t } = translationWithRoot(
  "requirements.requirement_table.actions_cell"
);

const ActionsButtons = ({
  holderToken,
  fulfillment,
  attachFormInstanceFiles,
  createUploadedRecord,
  createFormOverride,
  createUploadTypeOverride,
}) => {
  const {
    isFulfilled,
    isOverridden,
    formInstance,
    requirement,
    waitingForSignature,
  } = fulfillment;
  const [showOverrideModal, setShowOverrideModal] = useState(false);

  const FillEditButton = () => {
    const [hideEditModal, setHideEditModal] = useState(true);
    return (
      <If condition={formInstance}>
        <Button
          icon={Icons.Edit}
          disabled={isEmpty(formInstance.editUrl)}
          onClick={() => {
            if (isFulfilled) setHideEditModal(false);
            else window.location.href = formInstance.editUrl;
          }}
        >
          {t("fill_edit")}
        </Button>

        {/* Edit Completed Form Confirmation Modal */}
        <Modal
          title={t("are_you_sure_you_want_to_edit")}
          hidden={hideEditModal}
          onCancel={() => setHideEditModal(true)}
          onSubmit={() => {
            window.location.href = formInstance.editUrl;
          }}
          submitText={t("yes_edit")}
          submitDangerButton
        >
          {t("already_complete", { name: fulfillment.requirement.title })}
        </Modal>
      </If>
    );
  };

  let cellContents = null;
  if (isOverridden) {
    cellContents = null;
  } else if (waitingForSignature) {
    cellContents = (
      <Fragment>
        <FillEditButton />
        <SigningButtons {...{ formInstance }} />
      </Fragment>
    );
  } else {
    const uploadFiles = uploadRequirementFiles({
      holderToken,
      attachFormInstanceFiles,
      createUploadedRecord,
    })({
      requirement,
      formInstance,
    });

    cellContents = (
      <Fragment>
        <If
          condition={
            !isFulfilled &&
            isOverridable({ requirement, providerRole: "caseworker" }) &&
            policy(requirement).mayCreateOverrideRecord()
          }
        >
          <Button variant="tertiary" onClick={() => setShowOverrideModal(true)}>
            {t("waive")}
          </Button>
        </If>
        <UploadButton onUpload={uploadFiles} />
        <FillEditButton />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <OverrideModal
        fulfillment={fulfillment}
        isModalOpen={showOverrideModal}
        onSubmit={createOrEditOverrideRecord({
          holderToken,
          fulfillment,
          createFormOverride,
          createUploadTypeOverride,
          setShowOverrideModal,
        })}
        onCloseModal={() => setShowOverrideModal(false)}
      />
      <Flex justify="end">{cellContents}</Flex>
    </Fragment>
  );
};
ActionsButtons.propTypes = {
  holderToken: PropTypes.string.isRequired,
  fulfillment: PropTypes.shape({
    isFulfilled: PropTypes.bool.isRequired,
    isOverridden: PropTypes.bool.isRequired,
    formInstance: PropTypes.shape({
      id: PropTypes.string.isRequired,
      editUrl: PropTypes.string,
    }),
    requirement: PropTypes.shape({
      id: PropTypes.number.isRequired,
      form: PropTypes.shape({ slug: PropTypes.string }),
      title: PropTypes.string.isRequired,
      uploadType: PropTypes.shape({ slug: PropTypes.string }),
    }).isRequired,
    waitingForSignature: PropTypes.bool.isRequired,
  }),
  /** GraphQL Mutations */
  attachFormInstanceFiles: PropTypes.func.isRequired,
  createUploadedRecord: PropTypes.func.isRequired,
  createFormOverride: PropTypes.func.isRequired,
  createUploadTypeOverride: PropTypes.func.isRequired,
};

export default ActionsButtons;
