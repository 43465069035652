import { useMutation } from "@apollo/client";
import { Link } from "@heart/components";
import completedIcon from "green_check.png";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState } from "react";

import ProgressArc from "@components/ProgressArc";
import PersonCard from "@components/people/PersonCard";

import SendApplicationReferenceInviteEmail from "@graphql/mutations/SendApplicationReferenceInviteEmail.graphql";

import constructName from "../constructName";
import findEmailAndPhoneNumber from "../findEmailAndPhoneNumber";
import isPlaceholderEmail from "../isPlaceholderEmail";
import styles from "./Reference.module.scss";

const Reference = ({
  agencyId,
  reference,
  removeReference,
  setEditingForReference,
  displayNotice,
  readOnly,
}) => {
  const [resending, setResending] = useState(false);
  const [removing, setRemoving] = useState(false);

  const [sendApplicationReferenceInviteEmail] = useMutation(
    SendApplicationReferenceInviteEmail
  );

  const resendEmail = () => {
    if (resending) return;
    setResending(true);

    const variables = {
      applicationReferenceId: reference.id,
    };
    sendApplicationReferenceInviteEmail({ variables });

    displayNotice(
      I18n.t(
        "views.reference_requests.create.reference_or_other_adult_with_email",
        { email: reference.email }
      )
    );
    setResending(false);
  };

  const icons = reference.complete
    ? [
        <img
          key="completed"
          src={completedIcon}
          alt={I18n.t(
            "views.reference_notifications_mailer.remind_reference_request_notification.complete_checkmark"
          )}
        />,
      ]
    : [
        <ProgressArc
          key="progressarc"
          progress={reference.complete ? 100 : 0}
          progressIncomplete={true}
        />,
      ];

  const iconActions = [];
  if (!(reference.complete || readOnly)) {
    if (reference.editable) {
      iconActions.push({
        ariaLabel: `${I18n.t("admin.common.edit")} ${constructName(reference)}`,
        onClick: () => setEditingForReference(true),
        icon: "Pencil",
        "data-testid": "edit-reference",
      });
    }
    iconActions.push({
      ariaLabel: `${I18n.t("admin.common.delete")} ${constructName(reference)}`,
      onClick: () => {
        setRemoving(true);
        if (
          // eslint-disable-next-line no-alert
          confirm(
            I18n.t(
              "views.applications.delete_application_reference.delete_confirmation"
            )
          )
        ) {
          removeReference(reference);
        } else {
          setRemoving(false);
        }
      },
      icon: removing ? "Spinner" : "Trash",
    });
  }

  const { email, phoneNumber } = findEmailAndPhoneNumber({
    agencyId,
    person: reference,
  });

  let genericMessage = null;
  if (reference.complete) {
    genericMessage = I18n.t(
      "views.applications.delete_application_reference.reference_locked"
    );
  } else if (!reference.editable) {
    genericMessage = I18n.t(
      "views.applications.application_reference.cannot_edit"
    );
  } else if (reference.hardBounce) {
    genericMessage = I18n.t(
      "javascript.components.people.application_references_form.reference.hard_bounce",
      { email: reference.email }
    );
  }

  return (
    <PersonCard
      icons={icons}
      iconActions={iconActions}
      person={{ ...reference, email, phoneNumber }}
      actions={
        <If
          condition={
            !reference.complete &&
            !isPlaceholderEmail({
              email: reference.email,
            })
          }
        >
          <span className={styles.actionsSection}>
            <Link onClick={resendEmail}>
              {I18n.t(
                `views.applications.common.${
                  resending ? "sending" : "send_reminder"
                }`
              )}
            </Link>
          </span>
        </If>
      }
      hasDateOfBirthInput={false}
      genericMessage={genericMessage}
    />
  );
};

Reference.propTypes = {
  agencyId: PropTypes.number.isRequired,
  reference: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    complete: PropTypes.bool,
    editable: PropTypes.bool.isRequired,
    hardBounce: PropTypes.bool,
  }),
  removeReference: PropTypes.func.isRequired,
  setEditingForReference: PropTypes.func.isRequired,
  displayNotice: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default Reference;
