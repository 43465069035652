import PropTypes from "prop-types";
import { Fragment } from "react";

import CopyToClipboard from "./CopyToClipboard";
import styles from "./CopyToClipboardTextWithIcon.module.scss";

/**
 * Given the prop `text`, this component displays that text and a little
 * copy button next to it. When you click that button, it copies the text
 * to the clipboard.
 */
const CopyIcon = ({ onClick }) => (
  <button
    className={`fa far fa-copy ${styles.copyToClipboard}`}
    title="Copy to clipboard"
    onClick={onClick}
  />
);

CopyIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const CopyToClipboardTextWithIcon = ({ text }) => (
  <Fragment>
    {text} <CopyToClipboard text={text} button={CopyIcon} />
  </Fragment>
);

CopyToClipboardTextWithIcon.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CopyToClipboardTextWithIcon;
