import { InputDropdown } from "@heart/components";
import PropTypes from "prop-types";

const ReasonRequested = ({ reasonOptions, respiteReasonRequested }) => (
  <InputDropdown
    required
    name="placement_period[respite_reason_requested]"
    label="Respite Reason Requested"
    values={reasonOptions}
    value={respiteReasonRequested}
  />
);

ReasonRequested.propTypes = {
  respiteReasonRequested: PropTypes.string,
  reasonOptions: PropTypes.array,
};

export default ReasonRequested;
