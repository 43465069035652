import { Flex, Icons } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import Csrf from "@components/Csrf";

import styles from "./Header.module.scss";

const SearchBar = ({ onClose }) => {
  const inputRef = useRef(null);

  useEffect(() => inputRef.current.focus(), [inputRef]);

  return (
    <Flex grow className={styles.searchBarContainer}>
      <form
        className={styles.searchBar}
        method="POST"
        action="/admin/search_name"
      >
        <Csrf />
        <label className={styles.hidden} htmlFor="header-search-bar">
          {I18n.t("admin.common.search_names_header")}
        </label>
        <input
          id="header-search-bar"
          data-testid="header-search-bar"
          ref={inputRef}
          type="text"
          name="name"
          placeholder={I18n.t("admin.common.search_names_header")}
        />
      </form>
      <Icons.Times
        className={styles.headerButton}
        onClick={onClose}
        description={I18n.t("views.common.close")}
      />
    </Flex>
  );
};

SearchBar.propTypes = {
  onClose: PropTypes.func,
};

export default SearchBar;
