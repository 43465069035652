$(function placementCallingLogToggle() {
  const $availability = $(".js-availability");
  const $reasonRefusedDisplay = $(".js-why-refused-display");
  const $reasonRefusedSelect = $(".js-why-refused-select");
  const $otherExplanationDisplay = $(".js-other-explanation");
  const $otherExplanationTextField = $(".js-why-refused-textfield");

  function reasonRefusedToggle() {
    if ($availability.val() === "no") {
      $reasonRefusedDisplay.show();
    } else {
      $reasonRefusedDisplay.hide();
      $otherExplanationDisplay.hide();
      $reasonRefusedSelect.val([]);
      $otherExplanationTextField.val("");
    }
  }

  function otherExplanationToggle() {
    if ($reasonRefusedSelect.val() === "other") {
      $otherExplanationDisplay.show();
    } else {
      $otherExplanationDisplay.hide();
      $otherExplanationTextField.val("");
    }
  }
  $availability.on("change", reasonRefusedToggle);
  $reasonRefusedSelect.on("change", otherExplanationToggle);
});
