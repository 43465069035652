import PropTypes from "prop-types";

import EndUserUploadTypeTable from "./EndUserUploadTypeTable";

const OtherAdultUploadTypeTable = ({
  applicationId,
  applicationStageId,
  userAgencyProfileId,
}) => (
  <EndUserUploadTypeTable
    sections={[
      {
        i18nRoleKey: "other_adult_in_home",
        isHeaderHidden: true,
        userAgencyProfileIds: [userAgencyProfileId],
      },
    ]}
    {...{ applicationId, applicationStageId }}
  />
);

OtherAdultUploadTypeTable.propTypes = {
  applicationId: PropTypes.number.isRequired,
  userAgencyProfileId: PropTypes.number.isRequired,
  applicationStageId: PropTypes.number,
};

export default OtherAdultUploadTypeTable;
