import PropTypes from "prop-types";
import React, { useState } from "react";

import { translationWithRoot } from "@components/T";

import { BasicInputLayout } from "./common";

const { t } = translationWithRoot("heart.components.inputs.input_money");

/**
 * InputMoney is a custom input component designed to handle and format monetary values.
 * It displays a currency symbol and automatically formats the input value to show
 * the correct decimal places and commas as thousand separators.
 *
 * Props:
 * - currencySymbol: The symbol to display as the currency (e.g., "$").
 * - label: A label for the input field.
 * - locale: The locale code to format the currency according to different regional conventions.
 * - value: The initial value of the input field (in the smallest unit of currency, e.g., cents for USD).
 * - onChange: Callback function to pass the updated value (in the smallest unit of currency) to the parent component.
 *
 * Example usage:
 * <InputMoney
 *   currencySymbol="€"
 *   label={<span>Amount</span>}
 *   locale="de-DE"
 *   value={5000} // Value in cents (€50.00)
 *   onChange={(newValue) => console.log(newValue)} // Handle updated value
 * />
 */
const InputMoney = ({
  currencySymbol = "$",
  label,
  locale = "en-US",
  value = 0,
  onChange,
  ...props
}) => {
  /**
   * Formats the given numeric value into a string with correct decimal placement and
   * thousands separators based on the specified locale.
   *
   * @param {number|string} val - The value to be formatted. It should be a number or a string that can be converted to a number.
   * @returns {string} - The formatted string with two decimal places and appropriate thousands separators.
   */
  const formatValue = val => {
    if (!val) return "0.00";
    return new Intl.NumberFormat(locale, {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(val) / 100);
  };

  // Set the initial value of the input field based on the formatted value.
  const [inputValue, setInputValue] = useState(formatValue(value));

  /**
   * Handles the change event for the input field, updating the displayed value
   * and triggering the onChange callback with the updated numeric value.
   *
   * @param {object} e - The change event triggered by the input field.
   */
  const handleInputChange = e => {
    // Extract the numeric value from the input, removing any non-numeric characters.
    const rawValue = e.target.value.replace(/\D/g, "");
    const formattedValue = formatValue(rawValue);
    setInputValue(formattedValue);
    onChange((parseFloat(rawValue) / 100).toFixed(2));
  };

  // Create a second element with the currency information based on the currencySymbol.
  const currencyInfo =
    currencySymbol === "$"
      ? ` (${t("in_usd")})`
      : ` (${t("in")} ${currencySymbol})`;

  // Combine the original label and the currency information into an array for rendering.
  const combinedLabel = label
    ? [
        <span key="label">{label}</span>,
        <span key="currency-info">{currencyInfo}</span>,
      ]
    : null;

  const renderInputComponent = inputProps => (
    <input
      {...inputProps}
      type="text"
      value={`${currencySymbol}${inputValue}`}
      onChange={handleInputChange}
    />
  );

  return (
    <BasicInputLayout
      inputComponent={renderInputComponent}
      label={combinedLabel}
      {...props}
    />
  );
};

InputMoney.propTypes = {
  /** The symbol to display as the currency (e.g., "$"). */
  currencySymbol: PropTypes.string,
  /** A label for the input field. If provided, the currency information (e.g., "(In USD)") is appended. */
  label: PropTypes.node,
  /** The locale code to format the currency according to different regional conventions (e.g., "en-US"). */
  locale: PropTypes.string,
  /** The initial value of the input field (in the smallest unit of currency, e.g., cents for USD). */
  value: PropTypes.string,
  /** Callback function to pass the updated value (in the smallest unit of currency) to the parent component. */
  onChange: PropTypes.func.isRequired,
};

export default InputMoney;
