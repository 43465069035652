import { useReactiveVar } from "@apollo/client";

import { bannerVar } from "@lib/useBanner";

import BannerContainer from "./BannerContainer";

const LocalBannerSubscriber = () => {
  const { visible, type, message } = useReactiveVar(bannerVar);

  if (visible) {
    return <BannerContainer type={type} message={message} />;
  }
  return null;
};

export default LocalBannerSubscriber;
