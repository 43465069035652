import { InputTextarea } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import styles from "./AvailabilityRequest.module.scss";

const prefixT = (key, args) =>
  I18n.t(`javascript.components.availability_request.sms_draft.${key}`, args);

const SMSDraft = ({ SMSText, setSMSText }) => {
  const charMax = 320;
  const [charCount, setCharCount] = useState(SMSText.length);

  const errorMessage = prefixT("character_count", {
    count: charMax - charCount,
  });

  return (
    <div>
      <div className={styles.inputContainer}>
        <InputTextarea
          label={prefixT("label")}
          value={SMSText}
          rows={5}
          maxCharacters={charMax}
          className={styles.nonResizableTextareaInput}
          onChange={value => {
            setCharCount(value.length);
            setSMSText(value);
          }}
          error={
            charCount > 160
              ? `${errorMessage}\n${prefixT("length_warning")}`
              : errorMessage
          }
        />
      </div>
    </div>
  );
};

SMSDraft.propTypes = {
  SMSText: PropTypes.string.isRequired,
  setSMSText: PropTypes.func.isRequired,
};

export default SMSDraft;
