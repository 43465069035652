import PropTypes from "prop-types";

import styles from "./ProfileSection.module.scss";

const ParagraphField = ({ items }) => {
  if (items && items.length > 0) {
    return (
      <div className={styles.fieldText}>
        {items.map((item, idx) => item && <p key={idx}>{item}</p>)}
      </div>
    );
  }
  // Blank spacer
  return <div className={styles.fieldText}></div>;
};

ParagraphField.propTypes = {
  /** Array of strings to display as series of paragraphs */
  items: PropTypes.arrayOf(PropTypes.string),
};
export default ParagraphField;
