import classnames from "classnames";
import { isFunction } from "lodash";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

import { FILE_INPUT_ACCEPT_VALUE } from "@root/constants";

import styles from "./useUpload.module.scss";

/**
 * Callback to upload files
 *
 * @callback uploadFilesCallback
 * @param {File[]} files
 */

/**
 * @typedef {Object} UploadState
 * @property {function} aroundLoader wraps another function such that
 *   `isLoading` becomes true when the function is first called, then becomes
 *   false when it finishes. If the function returns a Promise, `isLoading`
 *   will become false when the promise concludes.
 * @property {function} getInputProps function that returns props to be
 *   added to an <input> element in the root element. There MUST be such an
 *   <input> element rendered in one of the table cells.
 * @property {function} getRootProps function that returns props to be
 *   added to a root element.
 * @property {boolean} isLoading boolean that indicates if actively loading
 *   something. This value will be true when either `uploadFiles` is active or
 *   in the time between when a function wrapped with `aroundLoader` begins and
 *   ends.
 * @property {boolean} isDragActive boolean that indicates if a drag is active
 *   over the root element.
 * @property {function} openFileDialog function to open file dialog explicitly
 *   on user input, e.g. when clicking a button.
 */

/**
 * Hook to enable drag and drop on root element with our Binti styling.
 *
 * Example usage:
 *
 * ```js
 * const { getRootProps, getInputProps, aroundLoader, isLoading } =
 *   useUpload(uploadSomehow);
 *
 * <div {...getRootProps()}>
 *     <UploadButton passedInputProps={getInputProps} />
 * </div>
 * ```
 *
 * @param {uploadFilesCallback} uploadFiles
 * @returns {UploadState}
 *
 * @see useUploadRow
 * */
export default uploadFiles => {
  const [isLoading, setIsLoading] = useState(false);

  const aroundLoader =
    fn =>
    (...args) => {
      setIsLoading(true);
      const ret = fn(...args);

      if (isFunction(ret.finally)) {
        ret.finally(() => setIsLoading(false));
        return;
      }

      setIsLoading(false);
    };

  const wrappedUploadFiles = files => {
    aroundLoader(() => uploadFiles(files))();
  };

  const onDrop = files => {
    if (files.some(file => file.size > Binti.MAX_FILE_SIZE)) {
      window.alert(Binti.MAX_FILE_SIZE_ERROR_MESSAGE); // eslint-disable-line
    } else {
      wrappedUploadFiles(files);
    }
  };

  const {
    getRootProps: dropzoneGetRootProps,
    getInputProps,
    isDragActive,
    open,
  } = useDropzone({
    onDrop,
    accept: FILE_INPUT_ACCEPT_VALUE,
    noClick: true,
    disabled: isLoading,
  });

  const { className, ...rootProps } = dropzoneGetRootProps();

  const getRootProps = () => ({
    ...rootProps,
    className: classnames(className, {
      [styles.backgroundLoading]: isLoading,
    }),
  });

  return {
    aroundLoader,
    getInputProps,
    getRootProps,
    isLoading,
    openFileDialog: open,
    isDragActive,
  };
};
