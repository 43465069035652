import PropTypes from "prop-types";

import { inputCommonPropTypes } from "./common";

/**
 * A hidden input!  Only useful for legacy forms, the
 * `name=value` pair will be sent along with the POST request
 * to the server.
 */
const InputHidden = ({ name, value, disabled, "data-testid": dataTestId }) => (
  <input
    type="hidden"
    name={name}
    value={value}
    disabled={disabled}
    data-testid={dataTestId}
  />
);

InputHidden.propTypes = {
  name: inputCommonPropTypes.name,
  /** The value to send to the server for this hidden input. */
  value: PropTypes.string,
  /** If you don't want to send the value along with your form POST, set this to `true`.
   * NOTE: delete this note/prop if you try this and can confirm/deny that this works. */
  disabled: inputCommonPropTypes.disabled,
  /** test id */
  "data-testid": PropTypes.string,
};
export default InputHidden;
