import { InputPhone, SurfaceFieldset } from "@heart/components";

import T from "@components/T";

const InputPhoneWrapper = props => (
  <SurfaceFieldset title={<T t="input_phone_wrapper.phone_number" />}>
    <InputPhone {...props} />
  </SurfaceFieldset>
);

export default InputPhoneWrapper;
