import { gql, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  InputFilterable,
  LoadingOverlay,
  Surface,
  Text,
} from "@heart/components";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

const emptyArray = [];

const { t } = translationWithRoot("placement_search.agencies_assigned");

const AddAgenciesForm = ({
  agencyId,
  assignedSearches,
  onSubmit,
  onCancel,
}) => {
  const { data, loading: dataLoading } = useQuery(
    gql`
      query AddAgenciesModal($custodialAgencyId: ID!) {
        agenciesAssignableForPlacementPeriod(
          custodialAgencyId: $custodialAgencyId
        ) {
          id
          name
        }
      }
    `,
    { variables: { custodialAgencyId: agencyId } }
  );

  const [agenciesToAdd, setAgenciesToAdd] = useState(null);

  const agenciesAvailable =
    data?.agenciesAssignableForPlacementPeriod || emptyArray;

  const assignedAgencyIds =
    assignedSearches?.map(s => s.agency.id) || emptyArray;

  const availableAgencies = useMemo(
    () => agenciesAvailable.filter(({ id }) => !assignedAgencyIds.includes(id)),
    [assignedAgencyIds, agenciesAvailable]
  );

  const availableAgencyOptions = availableAgencies.map(({ id, name }) => ({
    label: name,
    value: id.toString(),
  }));

  return (
    <LoadingOverlay active={dataLoading}>
      <Surface title={t("title")} hideTitle data-testid="add-agencies-form">
        <Choose>
          <When condition={!!availableAgencies.length}>
            <InputFilterable
              isMulti
              label={t("available")}
              onChange={setAgenciesToAdd}
              options={availableAgencyOptions}
              value={agenciesToAdd}
            />
            <Flex justify="end">
              <Button
                variant="tertiary"
                onClick={() => {
                  setAgenciesToAdd(availableAgencyOptions);
                }}
              >
                {t("select_all")}
              </Button>
              <Button variant="tertiary" onClick={onCancel}>
                {t("cancel")}
              </Button>
              <Button
                onClick={() =>
                  onSubmit(
                    agenciesToAdd.map(a => ({ id: a.value, name: a.label }))
                  )
                }
              >
                {t("assign")}
              </Button>
            </Flex>
          </When>
          <Otherwise>
            <Text>{t("no_agencies_available")}</Text>
          </Otherwise>
        </Choose>
      </Surface>
    </LoadingOverlay>
  );
};

AddAgenciesForm.propTypes = {
  agencyId: BintiPropTypes.ID,
  assignedSearches: PropTypes.array,
  placementSearchId: BintiPropTypes.ID,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default AddAgenciesForm;
