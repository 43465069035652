import {
  InputCheckbox,
  InputDropdown,
  InputPhone,
  InputText,
  InputTextarea,
  MultiInputTemplate,
  SurfaceFormGroup,
  Text,
} from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import { formatDateTimeAsShortDate } from "@lib/dates";

import { agencyHumanErrors } from "./AgencyHumanFormComputations";

const { t: emailT } = translationWithRoot("agency_human.form.email_address");
const { t: phoneT } = translationWithRoot("agency_human.form.phone_number");

const ContactInformationSection = ({
  formState: { phoneNumbers, emailAddresses },
  setFormAttribute,
}) => {
  const primaryPhoneError = agencyHumanErrors.primaryPhoneNumbersError({
    phoneNumbers,
  });
  const primaryEmailError = agencyHumanErrors.primaryEmailAddressesError({
    emailAddresses,
  });

  return (
    <Fragment>
      <SurfaceFormGroup title={phoneT("phone_numbers")}>
        <MultiInputTemplate
          title={phoneT("phone_number")}
          hideTitle={true}
          onChange={setFormAttribute("phoneNumbers")}
          startingValues={phoneNumbers}
          onDelete={setFormAttribute("phoneNumberIdsToDelete")}
          defaultValues={{
            phoneNumber: "",
            primary: false,
            inactive: false,
          }}
          firstSectionDefaultValues={{
            phoneNumber: "",
            primary: true,
            inactive: false,
          }}
          sectionInputs={({ getMultiInputProps }) => (
            <Fragment>
              <InputCheckbox
                {...getMultiInputProps({ id: "primary" })}
                label={phoneT("primary")}
              />
              <InputDropdown
                {...getMultiInputProps({ id: "category" })}
                label={phoneT("phone_number_type")}
                values={[
                  { label: phoneT("categories.home"), value: "home" },
                  { label: phoneT("categories.mobile"), value: "mobile" },
                  { label: phoneT("categories.work"), value: "work" },
                ]}
              />
              <InputPhone
                {...getMultiInputProps({ id: "phoneNumber" })}
                required
                label={phoneT("phone_number")}
                defaultCountry="US"
                international
              />
              <InputCheckbox
                {...getMultiInputProps({ id: "inactive" })}
                label={phoneT("inactive")}
              />
              <InputTextarea
                {...getMultiInputProps({ id: "notes" })}
                label={phoneT("notes")}
              />
            </Fragment>
          )}
          addSectionText={phoneT("add_phone_number")}
          removeSectionText={phoneT("remove_phone_number")}
          sectionValidation={entity =>
            agencyHumanErrors.primaryAndInactiveError({
              entity,
              translationPrefix: "phone_number",
            })
          }
          data-testid={"phoneNumbersSection"}
        />
        <If condition={!isEmpty(primaryPhoneError)}>
          <Text textColor="danger-600" role="alert">
            {primaryPhoneError}
          </Text>
        </If>
      </SurfaceFormGroup>
      <SurfaceFormGroup title={emailT("email_addresses")}>
        <MultiInputTemplate
          title={emailT("email_address")}
          hideTitle={true}
          onChange={setFormAttribute("emailAddresses")}
          onDelete={setFormAttribute("emailAddressIdsToDelete")}
          defaultValues={{ emailAddress: "", primary: false, inactive: false }}
          firstSectionDefaultValues={{
            emailAddress: "",
            primary: true,
            inactive: false,
          }}
          startingValues={emailAddresses}
          sectionInputs={({ getMultiInputProps }) => {
            const { value: inactiveUpdatedAt } = getMultiInputProps({
              id: "inactiveUpdatedAt",
            });
            const { value: inactive } = getMultiInputProps({
              id: "inactive",
            });

            return (
              <Fragment>
                <InputCheckbox
                  {...getMultiInputProps({ id: "primary" })}
                  label={emailT("primary")}
                />
                <InputText
                  {...getMultiInputProps({ id: "emailAddress" })}
                  required
                  type="email"
                  label={emailT("email_address")}
                />
                <InputCheckbox
                  {...getMultiInputProps({ id: "inactive" })}
                  label={emailT("inactive")}
                  description={
                    inactiveUpdatedAt && inactive
                      ? emailT("inactive_description", {
                          inactive_updated_at:
                            formatDateTimeAsShortDate(inactiveUpdatedAt),
                        })
                      : ""
                  }
                />
              </Fragment>
            );
          }}
          addSectionText={emailT("add_email_address")}
          removeSectionText={emailT("remove_email_address")}
          sectionValidation={entity =>
            agencyHumanErrors.primaryAndInactiveError({
              entity,
              translationPrefix: "email_address",
            })
          }
          data-testid={"emailsSection"}
        />
        <If condition={!isEmpty(primaryEmailError)}>
          <Text textColor="danger-600" role="alert">
            {primaryEmailError}
          </Text>
        </If>
      </SurfaceFormGroup>
    </Fragment>
  );
};
ContactInformationSection.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
};

export default ContactInformationSection;
