import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Text,
  Button,
  Flex,
  Modal,
  Icons,
  LoadingOverlay,
} from "@heart/components";
import flattenErrorMessages from "@utils/flattenErrorMessages";
import I18n from "i18n-js";
import { omit } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState, useEffect } from "react";

import T from "@components/T";
import CharacteristicsOpenToTable from "@components/placement_preferences/CharacteristicsOpenToTable";
import ChildYouthPreferences from "@components/placement_preferences/ChildYouthPreferences";

import UpdatePlacementProvider from "@graphql/mutations/UpdatePlacementProvider.graphql";

import useBanner from "@lib/useBanner";

import { BEHAVIOR_MEDICAL_OPTIONS } from "@root/constants";

export const PlacementProviderQuery = gql`
  query PlacementProviderQuery($placementProviderId: ID!) {
    placementProvider(placementProviderId: $placementProviderId) {
      id
      childDemographicsOpenTo {
        gendersOpenTo
        ethnicitiesOpenTo
        numSiblingsOpenTo
        sexAssignedAtBirthOpenTo
        ageLowerOpenTo
        ageHigherOpenTo
        openToAllGenders
      }
      childMedicalBehavioralCharacteristicsOpenTo
      placementInterests {
        slug
      }
    }
  }
`;

export const YouthPreferences = ({ placementProviderId }) => {
  const { clearBanner, errorBanner } = useBanner();
  const [isUpdating, setIsUpdating] = useState(false);
  // query for grabbing Placement Provider data
  const {
    loading: isLoadingPlacementProvider,
    error: placementProviderQueryError,
    data: placementProviderData,
  } = useQuery(PlacementProviderQuery, {
    variables: { placementProviderId },
    onError: err => {
      errorBanner(flattenErrorMessages(err));
    },
  });

  // mutation for updating Placement Provider data
  const [updatePlacementProvider, { loading: updatingPlacementProviderData }] =
    useMutation(UpdatePlacementProvider, {
      onCompleted: () => {
        window.location = "/";
      },
      onError: err => {
        setIsUpdating(false);
        errorBanner(flattenErrorMessages(err));
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      },
    });

  const [characteristicsOpenTo, setCharacteristicsOpenTo] = useState();
  const [demographicsOpenTo, setDemographicsOpenTo] = useState({});
  const [modalHidden, setModalHidden] = useState(true);
  const [modalSubmitting, setModalSubmitting] = useState(false);
  const [placementInterests, setPlacementInterests] = useState([]);

  const onModalSubmit = () => {
    setModalSubmitting(true);
    window.location = "/";
  };

  const onUpdate = () => {
    setIsUpdating(true);
    updatePreferences();
  };

  // we need this useEffect here because passing the query data into useState() was resulting in
  // characteristicsOpenTo being initialized to an empty object, because it takes some time for the query to return data
  useEffect(() => {
    if (!isLoadingPlacementProvider && !placementProviderQueryError) {
      setCharacteristicsOpenTo(
        placementProviderData.placementProvider
          .childMedicalBehavioralCharacteristicsOpenTo
      );
      // this omit is necessary now that childDemographicsOpenTo has an explicit type,
      // because "__typename" is passed along in the data response from the query
      setDemographicsOpenTo(
        omit(
          placementProviderData.placementProvider.childDemographicsOpenTo,
          "__typename"
        )
      );
      if (placementProviderData.placementProvider.placementInterests) {
        setPlacementInterests(
          placementProviderData.placementProvider.placementInterests.map(
            interest => interest.slug
          )
        );
      }
    }
  }, [
    isLoadingPlacementProvider,
    placementProviderQueryError,
    placementProviderData,
  ]);

  useEffect(() => {
    if (
      demographicsOpenTo.ageHigherOpenTo &&
      demographicsOpenTo.ageLowerOpenTo &&
      Number(demographicsOpenTo.ageHigherOpenTo) <
        Number(demographicsOpenTo.ageLowerOpenTo)
    ) {
      errorBanner(I18n.t("activerecord.errors.messages.ages_in_correct_order"));
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      clearBanner();
    }
  }, [clearBanner, demographicsOpenTo, errorBanner]);

  // update the characteristicsOpenTo object with the new value for `slug` (e.g., lgbtq: false)
  const onCharacteristicChanged = (slug, value) =>
    setCharacteristicsOpenTo({
      ...characteristicsOpenTo,
      [slug]: value,
    });

  // merge the demographicsOpenTo object with the new object
  // (e.g., { ethnicitiesOpenTo: ["Black or African American"]} )
  const onDemographicsOpenToChanged = newObject =>
    setDemographicsOpenTo({
      ...demographicsOpenTo,
      ...newObject,
    });

  const updatePreferences = () => {
    updatePlacementProvider({
      variables: {
        placementProviderId,
        childDemographicsOpenTo: demographicsOpenTo,
        childMedicalBehavioralCharacteristicsOpenTo: characteristicsOpenTo,
        placementInterests: placementInterests,
      },
    });
  };

  return (
    <Fragment>
      <If condition={!placementProviderQueryError}>
        <LoadingOverlay
          active={isLoadingPlacementProvider || updatingPlacementProviderData}
        >
          <Flex column>
            <Text as="h1">
              <T t="youth_preferences.youth_preferences" />
            </Text>
            <T t="youth_preferences.description" />
            <ChildYouthPreferences
              title={I18n.t(
                "javascript.components.youth_preferences.child_youth_preferences_title"
              )}
              subtitle={I18n.t(
                "javascript.components.youth_preferences.section_subtitle"
              )}
              demographicsOpenTo={demographicsOpenTo || {}}
              onDemographicsOpenToChanged={onDemographicsOpenToChanged}
              approvedAgeLower={0}
              approvedAgeHigher={21}
              onPlacementInterestsChanged={interests =>
                setPlacementInterests(interests)
              }
              placementInterests={placementInterests}
            />
            <CharacteristicsOpenToTable
              title={I18n.t(
                "javascript.components.youth_preferences.characteristics_title"
              )}
              subtitle={I18n.t(
                "javascript.components.youth_preferences.section_subtitle"
              )}
              characteristicsOptions={BEHAVIOR_MEDICAL_OPTIONS}
              characteristicsOpenTo={characteristicsOpenTo || {}}
              onCharacteristicChanged={onCharacteristicChanged}
            />
            <Flex justify="end" row>
              <Button
                onClick={() => {
                  setModalHidden(false);
                }}
                type="button"
                variant="secondary"
                disabled={isUpdating}
              >
                {I18n.t("views.common.cancel")}
              </Button>
              <Button
                onClick={onUpdate}
                type="submit"
                variant="primary"
                disabled={isUpdating}
                icon={isUpdating ? Icons.Spinner : null}
              >
                {I18n.t("javascript.components.youth_preferences.update_prefs")}
              </Button>
            </Flex>
          </Flex>
          <Modal
            title={
              <T t="placement_preferences.child_youth_preferences.cancel_confirmation" />
            }
            hidden={modalHidden}
            submitDisabled={modalSubmitting}
            onSubmit={onModalSubmit}
            onCancel={() => {
              setModalHidden(true);
            }}
            cancelText={<T t="common.no" />}
            submitText={<T t="common.yes" />}
          >
            <T t="placement_preferences.child_youth_preferences.unsaved_changes_warning" />
          </Modal>
        </LoadingOverlay>
      </If>
    </Fragment>
  );
};

YouthPreferences.propTypes = {
  placementProviderId: PropTypes.number.isRequired,
};

export default YouthPreferences;
