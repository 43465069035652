import { InputFilterable, InputTextarea, InputHidden } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

const FORM_NAME_PREFIX = "good_cause[good_cause_barriers_attributes][]";
const BARRIER_TYPE_I18N_PREFIX =
  "activerecord.attributes.good_cause_barrier/barrier_types.";

/**
 * Individual GoodCauseBarrier component for the GoodCauseBarriers HasMany
 * component.
 */
const GoodCauseBarrier = ({ isPersisted, item, barrierTypes, updateItem }) => {
  const onValueChange = attribute => value => {
    updateItem({ ...item, [attribute]: value });
  };

  const barrierTypeOptions = barrierTypes.map(type => ({
    value: type,
    label: I18n.t(`${BARRIER_TYPE_I18N_PREFIX}${type}`),
  }));

  return (
    <Fragment>
      {isPersisted && (
        <InputHidden value={item.id} name={`${FORM_NAME_PREFIX}[id]`} />
      )}
      <div data-testid="good-cause-barrier-reason">
        <InputFilterable
          label="Reason"
          name={`${FORM_NAME_PREFIX}[barrier_type]`}
          value={barrierTypeOptions.find(b => b.value === item.barrierType)}
          onChange={({ value }) => onValueChange("barrierType")(value)}
          options={barrierTypeOptions}
          required
        />
      </div>
      <InputTextarea
        label="Explanation"
        name={`${FORM_NAME_PREFIX}[explanation]`}
        onChange={onValueChange("explanation")}
        value={item.explanation}
        required
      />
      {item.markedForDestruction && (
        <InputHidden value={1} name={`${FORM_NAME_PREFIX}[_destroy]`} />
      )}
    </Fragment>
  );
};

GoodCauseBarrier.propTypes = {
  isPersisted: PropTypes.bool,
  item: PropTypes.object.isRequired,
  barrierTypes: PropTypes.array.isRequired,
  updateItem: PropTypes.func,
};

export default GoodCauseBarrier;
