import { Flex, Pill, Link } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment } from "react";

const AppealStatus = ({ appealFinalized, appealInProgress, appealPath }) => (
  <Fragment>
    <Link href={appealPath} style={{ textDecoration: "none" }}>
      <If condition={appealInProgress}>
        <Flex align="center">
          <Pill
            variant={"alert"}
            text={I18n.t("views.admin.appeal_in_progress")}
          />
        </Flex>
      </If>
      <If condition={appealFinalized && !appealInProgress}>
        <Flex align="center">
          <Pill
            variant={"warning"}
            text={I18n.t("views.admin.appeal_finalized")}
          />
        </Flex>
      </If>
    </Link>
  </Fragment>
);

AppealStatus.propTypes = {
  appealFinalized: PropTypes.bool,
  appealInProgress: PropTypes.bool,
  appealPath: PropTypes.string,
};

export default AppealStatus;
