import { gql, useQuery } from "@apollo/client";
import { InputDropdown, MultiInputTemplate, Text } from "@heart/components";
import InputAddress from "@heart/components/inputs/InputAddress";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import {
  ADDRESS_TYPE_PHYSICAL_HOME,
  ADDRESS_TYPE_MAILING,
  HOUSING_STATUS_UNHOUSED,
} from "@root/constants";

import { agencyHumanErrors } from "./AgencyHumanFormComputations";

const { t: housingStatusEnumT } = translationWithRoot(
  "activerecord.enums.agency_human.housing_status",
  {
    escapeJavascriptRoot: true,
  }
);
const { t } = translationWithRoot("agency_human.form.addresses");

export const HousingStatusesQuery = gql`
  query {
    HousingStatus: __type(name: "HousingStatus") {
      enumValues {
        name
      }
    }
  }
`;

/**
 * AddressesSectionUnWrapped
 *
 * Linter asked me to add this during minor edit... :D ?
 */
export const AddressesSectionUnWrapped = ({ formState, setFormAttribute }) => {
  const primaryAddressesError = agencyHumanErrors.primaryAddressesError({
    addresses: formState.addresses,
  });
  const { data: housingStatusEnum } = useQuery(HousingStatusesQuery);
  const getHousingStatusOptions = () =>
    housingStatusEnum
      ? housingStatusEnum.HousingStatus.enumValues.map(({ name: value }) => ({
          label: housingStatusEnumT(value),
          value: value,
        }))
      : [];
  const agencyHumanIsUnhoused =
    formState.housingStatus === HOUSING_STATUS_UNHOUSED;

  return (
    <Fragment>
      <InputDropdown
        label={I18n.t("activerecord.attributes.agency_human.housing_status")}
        values={getHousingStatusOptions()}
        value={formState.housingStatus}
        onChange={setFormAttribute("housingStatus")}
        hideBlank
      />
      <If condition={!agencyHumanIsUnhoused || formState.addresses.length > 0}>
        <MultiInputTemplate
          title={t("address")}
          onChange={setFormAttribute("addresses")}
          onDelete={setFormAttribute("addressIdsToDelete")}
          startingValues={formState.addresses}
          firstSectionDefaultValues={{
            address: {
              type: [ADDRESS_TYPE_PHYSICAL_HOME, ADDRESS_TYPE_MAILING],
              addressLine1: "",
              countryCode: "US",
              postalCode: "",
              primary: true,
              inactive: false,
            },
          }}
          defaultValues={{
            address: {
              type: [],
              addressLine1: "",
              addressLine2: "",
              city: "",
              primarySubdivision: "",
              countryCode: "US",
              postalCode: "",
              primary: false,
              inactive: false,
            },
          }}
          sectionInputs={({ getMultiInputProps }) => (
            <InputAddress
              {...getMultiInputProps({ id: "address" })}
              useAutocomplete
            />
          )}
          addSectionText={t("add_address")}
          removeSectionText={t("remove_address")}
        />
      </If>
      <If condition={!isEmpty(primaryAddressesError)}>
        <Text textColor="danger-600" role="alert">
          {primaryAddressesError}
        </Text>
      </If>
    </Fragment>
  );
};

AddressesSectionUnWrapped.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
};

export default AddressesSectionUnWrapped;
