import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Flex,
  InputDropdown,
  InputFilterable,
  SurfaceForm,
  InputText,
  Actions,
  LoadingOverlay,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  providerRoleRequirementMatrixDashboardPath,
  showProviderRoleRequirementMatrixPath,
} from "routes";

import UpdateProviderRoleRequirementMatrix from "@graphql/mutations/UpdateProviderRoleRequirementMatrix.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const EditProviderRoleRequirementMatrix = ({
  matrixId,
  agencies,
  licenseTypeOptions,
  subroleOptions,
}) => {
  const [editedMatrixData, setEditedMatrixData] = useState({
    initialized: false,
  });

  const [agency, setAgency] = useState("");

  const getRelevantStateData = obj => ({
    matrixId: matrixId,
    name: obj.name,
    slug: obj.slug,
    agencyId: obj.agencyId,
    licenseType: obj.licenseType,
    subrole: obj.subrole,
    uploadTypeSlugs: obj.uploadTypeSlugs,
    providerRole: "staff_member",
  });

  const { loading: matrixLoading } = useQuery(
    gql`
      query ProviderRoleRequirementMatrix($id: ID!) {
        providerRoleRequirementMatrix(id: $id) {
          id
          name
          slug
          licenseType
          agency {
            name
            slug
            id
          }
          providerRole
          subrole
          requirementTemplates {
            uploadType {
              slug
            }
          }
        }
      }
    `,
    {
      onCompleted: data => {
        if (editedMatrixData.initialized === false) {
          setEditedMatrixData({
            ...getRelevantStateData({
              ...data.providerRoleRequirementMatrix,
              agencyId: data.providerRoleRequirementMatrix.agency.id,
              uploadTypeSlugs:
                data.providerRoleRequirementMatrix.requirementTemplates.map(
                  t => t.uploadType.slug
                ),
            }),
            agencyLabel: data.providerRoleRequirementMatrix.agency.slug,
            initialized: true,
          });
          setAgency({
            label: data.providerRoleRequirementMatrix.agency.slug,
            value: data.providerRoleRequirementMatrix.agency.id,
          });
        }
      },
      variables: { id: matrixId },
    }
  );

  const { data: uploadTypeData, loading: uploadTypeLoading } = useQuery(
    gql`
      query UploadTypes($agencyId: ID) {
        uploadTypes(agencyId: $agencyId) {
          id
          slug
        }
      }
    `,
    { variables: agency !== "" ? { agencyId: parseInt(agency.value, 10) } : {} }
  );
  const selectedAgency = editedMatrixData.agencyId
    ? {
        label: editedMatrixData.agencyLabel,
        value: editedMatrixData.agencyId,
      }
    : { label: "", value: "" };
  const [update] = useMutation(UpdateProviderRoleRequirementMatrix);
  const updateProviderRoleMatrix = () => {
    const fixedState = getRelevantStateData(editedMatrixData);
    update({
      variables: { input: fixedState },
      onCompleted: () => {
        window.location.assign(
          showProviderRoleRequirementMatrixPath({ id: matrixId })
        );
      },
    });
  };

  return (
    <LoadingOverlay active={matrixLoading}>
      <If condition={matrixLoading}>
        <span>loading...</span>
      </If>
      <If condition={!matrixLoading}>
        <SurfaceForm
          title="View and Edit Staff Requirements Matrix"
          onSubmit={preventDefault(updateProviderRoleMatrix)}
          actions={
            <Actions
              cancelText="Back to Dashboard"
              cancelHref={providerRoleRequirementMatrixDashboardPath()}
              secondaryText="Back to readonly view"
              secondaryHref={showProviderRoleRequirementMatrixPath({
                id: matrixId,
              })}
            />
          }
        >
          <Flex column>
            <InputText
              label="Name of this requirement matrix"
              required
              onChange={value => {
                setEditedMatrixData({ ...editedMatrixData, name: value });
              }}
              value={editedMatrixData.name}
            />
            <InputText
              label="slug of this requirement matrix"
              required
              onChange={value => {
                setEditedMatrixData({ ...editedMatrixData, slug: value });
              }}
              value={editedMatrixData.slug}
            />
            <InputFilterable
              label="Agency"
              required
              values={agencies.map(([id, slug]) => ({
                label: slug,
                value: id.toString(),
              }))}
              onChange={value => {
                setEditedMatrixData({
                  ...editedMatrixData,
                  agencyId: value.value,
                  agencyLabel: value.label,
                });
                setAgency(value);
              }}
              value={selectedAgency}
            />

            <InputDropdown
              label="License type"
              required
              value={editedMatrixData.licenseType}
              values={licenseTypeOptions}
              onChange={value => {
                setEditedMatrixData({
                  ...editedMatrixData,
                  licenseType: value,
                });
              }}
            />
            <InputDropdown
              label="Staff requirements are always for staff roles. Optionally select a subrole of staff these requirements are applicable to."
              values={subroleOptions}
              value={editedMatrixData.subrole}
              onChange={value => {
                setEditedMatrixData({
                  ...editedMatrixData,
                  subrole: value,
                });
              }}
            />
            <InputFilterable
              label="Select upload types"
              error={
                !editedMatrixData.uploadTypeSlugs ||
                editedMatrixData.uploadTypeSlugs.length === 0
                  ? "Please select one or more upload type slugs"
                  : null
              }
              required
              isMulti
              value={
                editedMatrixData.uploadTypeSlugs?.map(slug => ({
                  label: slug,
                  value: slug,
                })) || []
              }
              values={
                uploadTypeLoading || agency === ""
                  ? []
                  : uploadTypeData.uploadTypes.map(type => ({
                      label: type.slug,
                      value: type.slug,
                    }))
              }
              onChange={value => {
                setEditedMatrixData({
                  ...editedMatrixData,
                  uploadTypeSlugs: value.map(s => s.value),
                });
              }}
            />
          </Flex>
        </SurfaceForm>
      </If>
    </LoadingOverlay>
  );
};

EditProviderRoleRequirementMatrix.propTypes = {
  matrixId: BintiPropTypes.ID,
  agencies: PropTypes.arrayOf(PropTypes.array),
  licenseTypeOptions: PropTypes.array,
  subroleOptions: PropTypes.array,
};
export default EditProviderRoleRequirementMatrix;
