import { gql, useQuery } from "@apollo/client";
import { Flex, FlexItem, InputDropdown, Surface } from "@heart/components";
import LogoSpinner from "@heart/components/loading_overlay/LogoSpinner";
import { range } from "lodash";
import PropTypes from "prop-types";

import UpdateAvailabilityLink from "@components/update_availability_link/UpdateAvailabilityLink";

import ChildrenInHome from "./ChildrenInHome";

export const ChildrenInHomeQuery = gql`
  query ChildrenInHomeQuery($agencyPlacementId: ID!) {
    agencyPlacement(agencyPlacementId: $agencyPlacementId) {
      currentChildrenInHome {
        id
        fullName
        dependentName
        permissions
      }
      respiteChildrenInHome {
        id
        fullName
        dependentName
        permissions
      }
    }
  }
`;

const OldAvailability = ({
  agencyPlacementId,
  capacity = null,
  availability,
  onAvailabilityChanged,
}) => {
  const { loading, data } = useQuery(ChildrenInHomeQuery, {
    variables: { agencyPlacementId },
  });

  const { currentChildrenInHome = [], respiteChildrenInHome = [] } =
    data?.agencyPlacement || {};
  const maxCapacity = capacity || 8;

  return (
    <Surface title="Availability">
      <Flex justify="space-between" row mobileColumn>
        <FlexItem expand="md">
          <Flex column>
            <If condition={loading}>
              <div style={{ width: "50px", height: "50px" }}>
                <LogoSpinner />
              </div>
            </If>
            <If condition={!loading}>
              <ChildrenInHome
                placedChildren={currentChildrenInHome}
                respiteChildren={respiteChildrenInHome}
              />
            </If>
          </Flex>
        </FlexItem>
        <FlexItem expand="md">
          <InputDropdown
            id="availability-select"
            label={
              <Flex justify="space-between" align="center" row>
                <span>Availability</span>
                <UpdateAvailabilityLink
                  url={`/admin/placements/${agencyPlacementId}/update_availability_updated_at`}
                />
              </Flex>
            }
            value={availability?.toString() || undefined}
            values={range(0, maxCapacity + 1).map(v => [`${v} beds`, `${v}`])}
            onChange={onAvailabilityChanged}
            description={`Total capacity is ${capacity || "unknown"}.`}
          />
        </FlexItem>
      </Flex>
    </Surface>
  );
};

OldAvailability.propTypes = {
  agencyPlacementId: PropTypes.number.isRequired,
  capacity: PropTypes.number,
  availability: PropTypes.number,
  onAvailabilityChanged: PropTypes.func.isRequired,
};

export default OldAvailability;
