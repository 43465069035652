import { useMutation, useReactiveVar } from "@apollo/client";
import { Modal, toast } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import CreateApiToken from "@graphql/mutations/CreateApiToken.graphql";
import ApiTokensQuery from "@graphql/queries/ApiTokens.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import ApiTokensEdit from "./ApiTokensEdit";

const ApiTokenCreateModal = ({ workerId, resetStateCallback }) => {
  const queryVariables = useReactiveVar(queryVariablesVar);

  const [tokenName, setTokenName] = useState(false);
  const [createApiToken, { loading }] = useMutation(CreateApiToken, {
    onCompleted: () => {
      toast.success({
        title: "Success!",
        message: "Created API token",
      });
      resetStateCallback();
    },
    refetchQueries: [{ query: ApiTokensQuery, variables: queryVariables }],
  });

  return (
    <Modal
      asForm
      title="Create New API Token"
      submitting={loading}
      submitText="Create"
      submittingText="Creating..."
      onCancel={() => {
        resetStateCallback();
      }}
      onSubmit={async () => {
        await createApiToken({
          variables: { pointOfContactId: workerId, tokenName: tokenName },
        });
      }}
      hidden={false}
    >
      <ApiTokensEdit setTokenName={setTokenName} />
    </Modal>
  );
};

ApiTokenCreateModal.propTypes = {
  workerId: BintiPropTypes.ID,
  resetStateCallback: PropTypes.func,
};
export default ApiTokenCreateModal;
