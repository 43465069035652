import { GraphQLDataTable } from "@heart/components";

import columns from "@components/prevention/agency_service_records/agency_service_records_table/columns";

import AgencyServiceRecords from "@graphql/queries/prevention/AgencyServiceRecords.graphql";

const { default: BintiPropTypes } = require("@lib/BintiPropTypes");

/**
 * Displays a table of agency service records for a given agency service referral.
 *
 * @param agencyServiceReferralId
 * @returns {JSX.Element}
 * @constructor
 */
const AgencyServiceRecordsTable = ({ agencyServiceReferralId }) => (
  <GraphQLDataTable
    columns={columns}
    query={AgencyServiceRecords}
    queryTitle={"preventionAgencyServiceRecords"}
    defaultFilters={{ agencyServiceReferralId }}
    filtersToPreserveOnClear={["agencyServiceReferralId"]}
    filters={[]}
  />
);

AgencyServiceRecordsTable.propTypes = {
  agencyServiceReferralId: BintiPropTypes.ID.isRequired,
};

export default AgencyServiceRecordsTable;
