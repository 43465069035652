import { Flex } from "@heart/components";
import { snakeCase } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import Timestamp from "@components/shared/Timestamp";

import { LUXON_DATEPICKER_FORMAT } from "@lib/dates";
import { OTHER_KEY, overrideOptionTranslation } from "@lib/overridableHelpers";

import OverrideRecordEditDelete from "./OverrideRecordEditDelete";

// TODO(ENG-12169): Flock/merge with Override component on DocumentCell
const OverrideRecordDisplay = ({
  holderToken,
  fulfillment,
  overrideRecord,
  replaceOverrideRecord,
}) => {
  const { requirement } = fulfillment;
  const { creatorName, creatorRole } = overrideRecord;

  const reasonTranslation = I18n.t(
    `activerecord.enums.override_record.reasons.${snakeCase(
      overrideRecord.reason
    )}`
  );

  let reasonTitle = reasonTranslation;

  if (overrideRecord.optionKey !== OTHER_KEY) {
    const optionTranslation = overrideOptionTranslation({
      requirement,
      overrideRecord,
      providerRole: creatorRole,
    });

    reasonTitle += ":";
    reasonTitle += optionTranslation;
  }

  return (
    <Flex row align="start">
      <div>
        {`Waived ${DateTime.fromISO(overrideRecord.grantDate).toFormat(
          LUXON_DATEPICKER_FORMAT
        )} by ${creatorName}`}
        <br />
        {reasonTitle} - {overrideRecord.details}
        <br />
        <If condition={overrideRecord.deletedAt}>
          <Timestamp label="Deleted" datetimestamp={overrideRecord.deletedAt} />
        </If>
      </div>

      <If condition={!overrideRecord.deletedAt}>
        <OverrideRecordEditDelete
          {...{
            holderToken,
            fulfillment,
            replaceOverrideRecord,
            overrideRecord,
          }}
        />
      </If>
    </Flex>
  );
};

OverrideRecordDisplay.propTypes = {
  holderToken: PropTypes.string.isRequired,
  fulfillment: PropTypes.object.isRequired,
  overrideRecord: PropTypes.object.isRequired,
  replaceOverrideRecord: PropTypes.func.isRequired,
};

export default OverrideRecordDisplay;
