import PropTypes from "prop-types";
import { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

/* eslint-disable react/display-name, react/prop-types */
const COLUMNS = Object.freeze([
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "slug",
    Header: "Slug",
    accessor: "slug",
  },
  {
    id: "stages",
    Header: "Stages",
    accessor: "stages",
  },
  {
    id: "roles",
    Header: "Roles",
    Cell: ({ original: { rolesSpec } }) => JSON.stringify(rolesSpec),
  },
]);
/* eslint-enable react/display-name, react/prop-types */

class RequirementTemplateTable extends Component {
  render() {
    const { title, data } = this.props;

    return (
      <div>
        <h2>{title}</h2>
        <ReactTable
          minRows={0}
          showPagination={false}
          columns={COLUMNS}
          data={data}
        />
      </div>
    );
  }
}

RequirementTemplateTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default RequirementTemplateTable;
