import { Button } from "@heart/components";
import { random, sample } from "lodash";
import { useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";

import preventDefault from "@lib/preventDefault";

const populateAll = ({ faker }) =>
  preventDefault(() => {
    const randomName = () => {
      const hasMiddle = random(10) < 4;
      const hasSuffix = random(10) < 2;

      const names = {
        first: faker.person.firstName(),
        last: faker.person.lastName(),
      };

      if (hasMiddle) {
        names.middle = faker.person.firstName();
      }

      if (hasSuffix) {
        names.suffix = faker.person.suffix();
      }

      return names;
    };

    const usPhoneNumber = () => {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const phone = `+1 ${faker.phone.number("(!##) !##-####")}`;
        if (isValidPhoneNumber(phone)) return phone;
      }
    };

    const findByDOMSelector = selector => document.querySelector(selector);
    const inputPhoneNumber = elem => {
      if (!elem) return;

      // eslint-disable-next-line no-param-reassign
      elem.value = usPhoneNumber({ faker });
      elem.dispatchEvent(new Event("change"));
    };

    const randomEmail = role =>
      `emil+random-${role}-${faker.datatype.uuid()}@binti.com`;

    const populateApplicants = () => {
      const applicantCount = random(1, 2);
      const applicantName = randomName();

      $("#intake_form_first_name").val(applicantName.first);
      $("#intake_form_middle_name").val(applicantName.middle);
      $("#intake_form_last_name").val(applicantName.last);
      $("#intake_form_suffix").val(applicantName.suffix);

      $("#intake_form_mail").val(randomEmail("applicant"));

      inputPhoneNumber(
        findByDOMSelector("input[name='intake_form[phone_number]']")
      );

      if (applicantCount === 1) {
        $("#intake_form_number_of_applicants_one").click();
      } else {
        $("#intake_form_number_of_applicants_two").click();

        const coApplicantName = randomName();
        $("#intake_form_co_applicant_first_name").val(coApplicantName.first);
        $("#intake_form_co_applicant_middle_name").val(coApplicantName.middle);
        $("#intake_form_co_applicant_last_name").val(coApplicantName.last);
        $("#intake_form_co_applicant_suffix").val(coApplicantName.suffix);

        $("#intake_form_co_applicant_email").val(randomEmail("co-applicant"));
        inputPhoneNumber(
          findByDOMSelector(
            "input[name='intake_form[co_applicant_profile][phone_number]']"
          )
        );
      }
    };

    const populateReferringWorkers = () => {
      const csw = sample([
        "Chesterton Seinfeld Wong",
        "Charity Sophia Whitfield",
        "Chi Su Warfield",
        "Captain Sarah Weaton",
      ]);

      const scsw = sample([
        "Sandra Coffield St Wheelhouse",
        "Saria Contessa Scoffield Wu",
        "Samwell Crawley Serendib Whittlington",
        "Sumit Capstone Svi Wintor",
      ]);

      $("#intake_form_referring_caseworker").select2("trigger", "select", {
        data: { id: csw, text: csw },
      });

      $("#intake_form_referring_supervisor_caseworker").select2(
        "trigger",
        "select",
        {
          data: { id: scsw, text: scsw },
        }
      );
    };

    const populateApplicationStatus = () => {
      $("#intake_form_status").val("applying");
      $("#intake_form_family_type").val("community");
    };

    const populateAddress = () => {
      $("#intake_form_address_street").val(faker.location.streetAddress());
      $("#intake_form_address_city").val(faker.location.city());
      $("#intake_form_address_state")
        .val(faker.location.stateAbbr())
        .trigger("change");
      $("#intake_form_address_zipcode").val(faker.location.zipCode());
    };

    populateApplicants();
    populateReferringWorkers();
    populateApplicationStatus();
    populateAddress();
  });

const PopulateIntakeFakeData = () => {
  const [faker, setFaker] = useState();

  // faker is fairly large and we only need it for binti admin, so load
  // it separately
  useEffect(() => {
    import("@faker-js/faker").then(({ faker: fakerLib }) => {
      setFaker(fakerLib);
    });
  }, []);

  if (faker) {
    return (
      <Button onClick={populateAll({ faker })}>Populate w/ Fake Data</Button>
    );
  }

  return false;
};

export default PopulateIntakeFakeData;
