/* eslint-disable no-shadow */
import { useQuery } from "@apollo/client";
import { Icons, InputFilterable, LiveRegion } from "@heart/components";

import BackgroundCheckTypesQuery from "@graphql/queries/BackgroundCheckTypes.graphql";

import ErrorBanner from "./banner/ErrorBanner";

const BackgroundCheckTypeDropdown = ({ value, onChange, ...inputProps }) => {
  const {
    data: { backgroundCheckTypes } = {},
    loading,
    error,
  } = useQuery(BackgroundCheckTypesQuery);

  const backgroundCheckTypeValues = backgroundCheckTypes?.nodes.map(
    ({ id, name }) => ({
      label: name,
      value: id,
    })
  );

  let content;
  if (loading) {
    content = <Icons.Spinner />;
  } else if (error) {
    content = <ErrorBanner message={error.message} />;
  } else {
    content = (
      <InputFilterable
        onChange={value => onChange(value.value)}
        {...inputProps}
        values={backgroundCheckTypeValues}
        value={backgroundCheckTypeValues.find(type => type.value === value)}
      />
    );
  }

  return <LiveRegion>{content}</LiveRegion>;
};

// This is otherwise a standard dropdown but the values are
// hydrated from the backend.
const { values, ...propTypes } = InputFilterable.propTypes;
BackgroundCheckTypeDropdown.propTypes = propTypes;

export default BackgroundCheckTypeDropdown;
