import PropTypes from "prop-types";
import React from "react";

import styles from "./HalfWidthOnDesktop.module.scss";

const HalfWidthOnDesktop = ({ children }) => (
  <div className={styles.halfWidthOnDesktop}>{children}</div>
);

HalfWidthOnDesktop.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HalfWidthOnDesktop;
