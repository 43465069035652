import { snakeCase } from "lodash";

import { typeEq } from "@lib/graphqlHelpers";

const createOrEditOverrideRecord = ({
  fulfillment: { requirement },
  holderToken,
  overrideRecordIdToReplace,
  createFormOverride,
  createUploadTypeOverride,
  setShowOverrideModal,
}) => {
  let variables = {
    holderToken,
    overrideRecordIdToReplace,
  };

  return async ({ reason, details, optionKey, grantDate }) => {
    variables = {
      ...variables,
      reason: snakeCase(reason),
      details,
      optionKey,
      grantDate,
    };

    if (typeEq("ChildFormRequirement", requirement)) {
      variables.formSlug = requirement.form.slug;
      variables.overriddenFormRequirementId = requirement.id;
      await createFormOverride({ variables });
    } else if (typeEq("ScheduledUploadTypeRequirement", requirement)) {
      variables.uploadTypeSlug = requirement.uploadType.slug;
      variables.overriddenUploadTypeRequirementId = requirement.id;
      await createUploadTypeOverride({ variables });
    }
    setShowOverrideModal(false);
  };
};

export default createOrEditOverrideRecord;
