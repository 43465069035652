import { useQuery, useMutation } from "@apollo/client";
import { Actions, InputDropdown, SurfaceForm } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import BulkAssignAgencyRoles from "@graphql/mutations/BulkAssignAgencyRoles.graphql";
import Roles from "@graphql/queries/Roles.graphql";

import preventDefault from "@lib/preventDefault";

const RoleSetter = ({ agencyWorkerIds }) => {
  const { data, loading } = useQuery(Roles);
  const [newRoleId, setNewRoleId] = useState(null);
  const [assignRoles] = useMutation(BulkAssignAgencyRoles);
  const onSubmit = preventDefault(() => {
    assignRoles({ variables: { agencyWorkerIds, roleId: newRoleId } });
  });

  if (loading) return null;

  const values = [...data.customRoles, ...data.defaultRoles].map(role => [
    role.name,
    role.id,
  ]);

  return (
    <SurfaceForm onSubmit={onSubmit} title="Roles" actions={<Actions />}>
      <InputDropdown
        label="New role"
        onChange={roleId => setNewRoleId(roleId)}
        values={values}
      />
    </SurfaceForm>
  );
};

RoleSetter.propTypes = {
  agencyWorkerIds: PropTypes.array.isRequired,
};

export default RoleSetter;
