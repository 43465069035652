import { InputFilterable } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";
import { relationshipErrors } from "@components/agency_humans/AgencyHumanFormComputations";

import { mapConstantSetToValues } from "@lib/constantsConversion";

import {
  RELATIVE,
  EMOTIONAL_RELATIONSHIP_STATUSES,
  LINEAGE_TYPES,
  PARENTAL_LINES,
} from "@root/constants";

const { t } = translationWithRoot("agency_human.form.relationship_to_child");

const BaseRelationshipToChildSection = ({
  relationship: {
    relationshipCategory = {},
    parentalLine = [],
    lineageType,
    emotionalRelationshipStatuses = [],
  },
  setRelationshipAttribute,
}) => {
  const isRelative = relationshipCategory?.value === RELATIVE;

  return (
    <Fragment>
      <If condition={isRelative}>
        <InputFilterable
          label={t("parental_lineage")}
          isMulti
          error={relationshipErrors.getParentalLineError({ parentalLine })}
          onChange={setRelationshipAttribute("parentalLine")}
          onBlur={e =>
            e.target.setCustomValidity(
              relationshipErrors.getParentalLineError({ parentalLine })
            )
          }
          value={parentalLine}
          values={mapConstantSetToValues({
            constant: PARENTAL_LINES,
            translationKey: "relationships.parental_lines",
          })}
        />
        <InputFilterable
          label={t("lineage_type")}
          onChange={setRelationshipAttribute("lineageType")}
          value={lineageType}
          values={mapConstantSetToValues({
            constant: LINEAGE_TYPES,
            translationKey: "relationships.lineage_type",
          })}
        />
      </If>
      <InputFilterable
        isMulti
        label={t("emotional_relationship_status")}
        onChange={setRelationshipAttribute("emotionalRelationshipStatuses")}
        value={emotionalRelationshipStatuses}
        values={mapConstantSetToValues({
          constant: EMOTIONAL_RELATIONSHIP_STATUSES,
          translationKey: "relationships.emotional_status",
        })}
      />
    </Fragment>
  );
};
BaseRelationshipToChildSection.propTypes = {
  setRelationshipAttribute: PropTypes.func.isRequired,
  relationship: PropTypes.shape({
    relationshipCategory: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    parentalLine: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
    ),
    lineageType: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    emotionalRelationshipStatuses: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
    ),
  }),
};

export default BaseRelationshipToChildSection;
