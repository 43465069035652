import { InputFilterableAsync } from "@heart/components";
import { sortBy } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import styles from "./KeyboardNav.module.scss";
import { fuzzyMatch } from "./fts_fuzzy_match";

const Is = ["i", "I"];
const ESC = "Escape";

/** Quick-access navigator to all named Binti pages!
 *   On any page > CTRL+I
 */
const KeyboardNav = ({ navigationItems }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("keydown", e => {
      if (e.ctrlKey && Is.includes(e.key)) {
        setOpen(true);
      } else if (e.key === ESC) {
        setOpen(false);
      }
    });
  });

  const navigate = ({ value }) => {
    window.location = value;
  };

  if (!open) return false;

  const loadOptions = input => {
    const matches = navigationItems
      .map(item => {
        const { label } = item;
        const [matched, score] = fuzzyMatch(input, label);

        return { item, matched, score };
      })
      .filter(({ matched }) => matched);

    return Promise.resolve(
      sortBy(matches, ({ score }) => -score).map(({ item }) => item)
    );
  };

  /* eslint-disable jsx-a11y/no-autofocus */
  // we only autoFocus if this select appears and it's then the primary focus
  // of the page, which seems to be the legit use per this article that's
  // linked from the eslint rule page
  //
  // https://brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/
  return (
    <div className={styles.wrapper}>
      <InputFilterableAsync
        autoFocus
        label="Quickly jump to a Binti page"
        hideLabel
        onChange={navigate}
        loadOptions={loadOptions}
      />
    </div>
  );
  /* eslint-enable jsx-a11y/no-autofocus */
};

KeyboardNav.propTypes = {
  navigationItems: PropTypes.array.isRequired,
};

export default KeyboardNav;
