import { gql, useQuery } from "@apollo/client";
import { Icons, InputDropdown } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { useEffect } from "react";

import DefaultRolesQuery from "@graphql/queries/DefaultRoles";

const CustomRolesQuery = gql`
  query CustomRoles($agencyId: ID) {
    customRoles(agencyId: $agencyId) {
      id
      name
    }
  }
`;

const RoleDropdown = ({
  value,
  agencyId,
  targetAgencyWorkerId,
  onChange,
  excludedRoleId,
  ...inputProps
}) => {
  const {
    data: { defaultRoles } = { defaultRoles: [] },
    loading: loadingDefaultRoles,
  } = useQuery(DefaultRolesQuery, {
    variables: { agencyId, agencyWorkerId: targetAgencyWorkerId },
    skip: !agencyId,
  });

  const {
    data: { customRoles = [] } = {},
    loading: loadingCustomRoles,
    refetch: refetchCustomRoles,
  } = useQuery(CustomRolesQuery, {
    variables: { agencyId },
    skip: !agencyId,
  });

  // Filter out `excludedRoleId` from custom and template role lists
  const [availableCustomRoles, availableDefaultRoles] = [
    customRoles,
    defaultRoles,
  ].map(roles => roles.filter(role => role.id !== excludedRoleId));

  const formatOption = role => ({ label: role.name, value: role.id });

  const dropdownValues = (() => {
    // If there are no custom roles, return an array of template role options
    if (customRoles.length === 0) {
      return availableDefaultRoles.map(formatOption);
      // If no template roles, return an array of custom role options
    } else if (defaultRoles.length === 0) {
      return availableCustomRoles.map(formatOption);
      // If both, group the custom roles and template roles separately
    }
    return [
      {
        label: I18n.t("views.roles.custom_roles"),
        options: availableCustomRoles.map(formatOption),
      },
      {
        label: I18n.t("views.roles.default_roles"),
        options: availableDefaultRoles.map(formatOption),
      },
    ];
  })();

  useEffect(() => {
    if (!agencyId) {
      return;
    }

    refetchCustomRoles({ agencyId });
  }, [refetchCustomRoles, agencyId]);

  useEffect(() => {
    const valueIsInOptions = [...availableCustomRoles, ...availableDefaultRoles]
      .map(r => r.id)
      .includes(Number(value));

    if (value && !valueIsInOptions) {
      onChange(null);
    }
  }, [availableCustomRoles, availableDefaultRoles, onChange, value]);

  if (loadingDefaultRoles || loadingCustomRoles) {
    return <Icons.Spinner />;
  }

  return (
    <InputDropdown
      {...inputProps}
      values={dropdownValues}
      value={String(value)}
      onChange={onChange}
      hideBlank
    />
  );
};

// We want all the propTypes from InputDropdown except `values`
const inputDropdownPropTypes = Object.assign({}, InputDropdown.propTypes);
delete inputDropdownPropTypes.values;

RoleDropdown.propTypes = {
  ...inputDropdownPropTypes,
  agencyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  targetAgencyWorkerId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  excludedRoleId: PropTypes.string,
};

export default RoleDropdown;
