import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { Line } from "react-chartjs-2";

import DeprecatedArrayDataTable from "./DeprecatedArrayDataTable";

const LINE_CHART_COLORS = [
  "#378722",
  "#600080",
  "#EBA911",
  "#1F73E5",
  "#E95F45",
  "#B13AD8",
  "#073773",
  "#88d367",
  "#12bba9",
  "#f3b4d3",
];

const BarriersToLicensingTable = ({ title, columns, data }) => {
  const columnsWithFilterBy = columns;
  const [colorMap, setColorMap] = useState({});
  const getColor = useCallback(
    key => {
      if (!colorMap[key]) {
        colorMap[key] =
          LINE_CHART_COLORS[
            Object.keys(colorMap).length % LINE_CHART_COLORS.length
          ];
        setColorMap(colorMap);
      }
      return colorMap[key];
    },
    [colorMap]
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
  );

  const [dataChartData, setDataChartData] = useState({ datasets: [] });

  const dataUpdate = useCallback(
    filteredData => {
      setDataChartData({
        datasets: filteredData.map(({ row }) => ({
          label: row.label,
          data: [
            { x: row.start_day, y: row.label },
            { x: row.end_day, y: row.label },
          ],
          fill: false,
          borderWidth: 15,
          pointRadius: 2,
          borderColor: getColor(row.partner_agency + row.time_period),
          backgroundColor: getColor(row.partner_agency + row.time_period),
        })),
      });
    },
    [getColor]
  );

  return (
    <div>
      <DeprecatedArrayDataTable
        title={title}
        columns={columnsWithFilterBy}
        data={data}
        filteredDataCallback={dataUpdate}
      />

      <div
        className="gantt-chart-container space-below-4"
        // If the number of pixels per line gets too small,
        // then graphs with only 2 data lines might not display both lines.
        style={{ height: `${dataChartData.datasets.length * 50}px` }}
      >
        <Line
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: { display: false },
            },
            callbacks: {
              label: (tooltipItem, tooltipData) =>
                tooltipData.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ].label,
            },
            scales: {
              x: {
                type: "linear",
                position: "bottom",
                ticks: {
                  beginAtzero: true,
                },
              },
              y: {
                type: "category",
                offset: true,
                position: "left",
              },
            },
          }}
          data={dataChartData}
        />
      </div>
    </div>
  );
};

BarriersToLicensingTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
};
export default BarriersToLicensingTable;
