import PropTypes from "prop-types";

import BintiSignTableWaiter from "@components/bintisign/entrypoint/BintiSignTableWaiter";
import HellosignSignatureWrapper from "@components/hellosign/HellosignSignatureWrapper";

/**
 *
 * SigningButtons shows either:
 * 1. Nothing, if the form instance isn't at all signable
 * 2. Hellosign signature wrapper if the form is Hellosignable
 * 3. BintiSignTableWaiter if the form is BintiSignable
 *
 * Only intended for use in the caseworker requirements page
 *
 * */
const SigningButtons = ({ formInstance, fulfillment }) => {
  if (!formInstance) return false;

  const { signingEvent, helloSignSignatureState } = formInstance;

  if (signingEvent) {
    return <BintiSignTableWaiter signingEventId={signingEvent.id} />;
  }

  if (helloSignSignatureState && fulfillment?.progressPercentage === 100) {
    return (
      <HellosignSignatureWrapper
        initialSignatureState={helloSignSignatureState.initialSignatureState}
        pollUrl={helloSignSignatureState.pollUrl}
        // this role is not the aria role
        // eslint-disable-next-line jsx-a11y/aria-role
        role="caseworker"
      />
    );
  }

  return false;
};

SigningButtons.propTypes = {
  formInstance: PropTypes.object,
  fulfillment: PropTypes.object,
};

export default SigningButtons;
