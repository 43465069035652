import { Icons, Surface } from "@heart/components";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

import PlacementSearchDetailsEdit from "./PlacementSearchDetailsEdit";
import PlacementSearchDetailsView from "./PlacementSearchDetailsView";

const { t } = translationWithRoot("placement_search.details");

const PlacementSearchDetails = ({ id, childId }) => {
  const [editing, setEditing] = useState(false);

  return (
    <Surface
      title={t("title")}
      secondary={
        editing ? (
          <Icons.Times
            description={"close"}
            onClick={() => setEditing(false)}
          />
        ) : (
          <Icons.Pencil description={"edit"} onClick={() => setEditing(true)} />
        )
      }
      data-testid="placement-search-details-container"
    >
      {editing ? (
        <PlacementSearchDetailsEdit
          id={id}
          childId={childId}
          onCompleted={() => setEditing(false)}
        />
      ) : (
        <PlacementSearchDetailsView id={id} />
      )}
    </Surface>
  );
};

PlacementSearchDetails.propTypes = {
  id: BintiPropTypes.ID.isRequired,
  childId: BintiPropTypes.ID,
};

export default PlacementSearchDetails;
