import { useQuery } from "@apollo/client";
import { Button, HeartTable, Icons, Link } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState } from "react";

import CustomRoles from "@graphql/queries/CustomRoles";

import T from "../T";
import BulkReassignModal from "./BulkReassignModal/index";
import useDeleteRole from "./useDeleteRole";

const { Table, Thead, Tbody, Tr, Th, Td } = HeartTable;
const CustomRolesTable = ({ viewOnly, agencyId }) => {
  const {
    data: { customRoles } = { customRoles: [] },
    error: errorLoadingRoles,
    loading: loadingRoles,
  } = useQuery(CustomRoles, {
    variables: { agencyId: agencyId },
    skip: !agencyId,
  });

  const [deleteRole, { loading: deleteLoading }] = useDeleteRole(agencyId);
  const [lastDeleteCandidate, setDeleteCandidate] = useState();
  const [showModal, setShowModal] = useState(false);

  const onDeleteRole = role => {
    if (role.usersInRole.length > 0) {
      setShowModal(true);
      setDeleteCandidate(role);
    } else {
      deleteRole({ variables: { roleId: role.id } });
    }
  };

  if (loadingRoles) return <p>{I18n.t("common.loading")}</p>;
  if (errorLoadingRoles) return <T t="permissions.error_loading_roles" />;
  if (customRoles.length === 0) return <T t="permissions.no_roles_found" />;

  return (
    <React.Fragment>
      <If condition={showModal}>
        <BulkReassignModal
          fromRole={lastDeleteCandidate}
          agencyId={agencyId}
          onClose={() => {
            setShowModal(false);
            setDeleteCandidate(null);
          }}
        />
      </If>
      <Table>
        <Thead>
          <Tr>
            <Th>
              <T t="permissions.role_name" />
            </Th>
            <Th>
              <T t="permissions.role_created_header" />
            </Th>
            <Th>
              <T t="permissions.role_parent_header" />
            </Th>
            <Th colSpan="2"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {customRoles.map(role => (
            <Tr key={role.id}>
              <Td>{role.name}</Td>
              <Td>{new Date(role.createdAt).toLocaleDateString("en-US")}</Td>
              <Td>{role.originatedFrom.name}</Td>
              <Td>
                <Link href={role.path}>
                  <T
                    t={
                      viewOnly
                        ? "permissions.show_role"
                        : "permissions.edit_role"
                    }
                  />
                </Link>
              </Td>
              <If condition={!viewOnly}>
                <Td>
                  <Button
                    data-testid="delete-button"
                    description={I18n.t(
                      "javascript.components.permissions.delete_role_button"
                    )}
                    icon={deleteLoading ? Icons.Spinner : Icons.Trash}
                    onClick={() => onDeleteRole(role)}
                  />
                </Td>
              </If>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </React.Fragment>
  );
};

CustomRolesTable.propTypes = {
  viewOnly: PropTypes.bool,
  onDeleted: PropTypes.func,
  agencyId: PropTypes.number,
};

export default CustomRolesTable;
