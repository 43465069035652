import PropTypes from "prop-types";

import DocumentToggleButton from "./DocumentToggleButton";

const isMarked = doc => doc.notApplicable;

const MarkNAButton = ({ doc, backgroundLoading }) => (
  <DocumentToggleButton
    doc={doc}
    backgroundLoading={backgroundLoading}
    isMarkable={doc.mayBeMarkedNotApplicable}
    isMarked={isMarked}
    mark="markNotApplicable"
    unmark="markApplicable"
    label={doc.notApplicable ? "Unmark N/A" : "Not Applicable"}
  />
);

MarkNAButton.propTypes = {
  doc: PropTypes.object.isRequired,
  backgroundLoading: PropTypes.bool,
};

export default MarkNAButton;
