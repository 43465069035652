import { isEmpty } from "lodash";

/**
 * Remove an other adult from a household. Thin wrapper for
 * removeHumanPlacementProviderRole GraphQL mutation.
 */
export default async ({
  messageDisplay: { displayError },
  applicationId,
  placementProviderId,
  userAgencyProfileId,
  removeHumanPlacementProviderRole,
  leftAt,
  isErroneous,
  setRemoving,
  adultHasLeft,
  adultId,
}) => {
  const variables = {
    applicationId: applicationId,
    userAgencyProfileId: userAgencyProfileId,
    placementProviderId: placementProviderId,
    role: "otherAdultInHome",
    leftAt: leftAt,
    isErroneous: isErroneous,
    adultHasLeft: adultHasLeft,
    id: adultId,
  };

  const {
    data: {
      removeHumanPlacementProviderRole: { errors },
    },
  } = await removeHumanPlacementProviderRole({
    variables,
  });
  if (!isEmpty(errors)) {
    displayError(errors.map(({ message }) => message).join(", "));
  } else {
    setRemoving(false);
  }
};
