import { gql, useQuery } from "@apollo/client";
import { InputDropdown, LoadingOverlay } from "@heart/components";
import I18n from "i18n-js";
import { PropTypes } from "prop-types";

const ResponseDropdown = ({ value, onChange }) => {
  const { data: formData, loading } = useQuery(gql`
    query ResponseDropdownQuery($childId: ID) {
      placementSearchFormData(childId: $childId) {
        responseOptions {
          label
          value
        }
      }
    }
  `);

  return (
    <LoadingOverlay active={loading}>
      <InputDropdown
        data-testid="response-dropdown"
        value={value}
        hideBlank
        blankOptionText={I18n.t(
          "javascript.components.placement_search.responses.awaiting_response"
        )}
        values={formData?.placementSearchFormData?.responseOptions || []}
        onChange={newResponse => onChange(newResponse)}
      />
    </LoadingOverlay>
  );
};

ResponseDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ResponseDropdown;
