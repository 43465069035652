import { PageContainer } from "@heart/components";
import { useState } from "react";

import EnteringEmails from "./EnteringEmails";
import PreviewingAgencyWorkers from "./PreviewingAgencyWorkers";

/** For bulk-assigning agency worker roles all at once.
 *    Agency Details > Bulk Assign Roles
 */
const BulkEditAgencyWorkers = () => {
  const [emails, setEmails] = useState(null);

  if (!emails) {
    return (
      <PageContainer>
        <EnteringEmails onFinalized={setEmails} />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PreviewingAgencyWorkers emails={emails} />
    </PageContainer>
  );
};

export default BulkEditAgencyWorkers;
