import { useLazyQuery } from "@apollo/client";
import { useDebouncedCallback } from "@react-hookz/web";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import { InputFilterableAsync } from "./InputFilterable";
import { inputCommonPropTypes } from "./common";

const { t } = translationWithRoot("common");

/** An asynchronous autocomplete input component where options are populated
 * using the provided GraphQL query. When the user types at least 3 characters,
 * the query will be kicked off and results will be processed using the
 * valuesFromResponse function provided
 *
 * Queries will be debounced by half a second after when the user stops typing
 * into the dropdown.
 *
 * The story for this component only supports returning results from the
 * typed query "Chris"
 *
 *
 * ### Cypress
 *
 * You can use our `cy.reactSelectOption` helper, you just need to pass in a `query` param, which is the GraphQL query you want to wait for
 *
 * (e.g., `cy.reactSelectOption({ option: placementName, label: "Placement", query: "AgencyPlacementNameAutocomplete"})`)
 */
export const InputAutocompleteGraphQL = ({
  query,
  queryVariables,
  valuesFromResponse,
  cacheOptions = true,
  error,
  ...props
}) => {
  const [inputQuery, setInputQuery] = useState("");
  const [errors, setErrors] = useState(error);
  const [loadOptions] = useLazyQuery(query, {
    variables: { inputQuery, ...(queryVariables || {}) },
  });

  return (
    <InputFilterableAsync
      {...props}
      cacheOptions={cacheOptions}
      error={errors}
      loadOptions={useDebouncedCallback(
        async (prefix, callback) => {
          if (prefix.length < 2) {
            callback([]);
          } else {
            setInputQuery(prefix);
            const { data, error: queryError } = await loadOptions();
            setErrors([errors, queryError].filter(Boolean).join(", "));
            callback(valuesFromResponse(data));
          }
        },
        [],
        500
      )}
      noOptionsMessage={() =>
        inputQuery.length < 2 ? t("enter_three_characters") : t("no_options")
      }
    />
  );
};
InputAutocompleteGraphQL.propTypes = {
  ...inputCommonPropTypes,
  /** a gql query, which should take in an `inputQuery` argument to
   * determine options for the dropdown
   */
  query: PropTypes.object.isRequired,
  /** Optional variables object passed along when calling the query */
  queryVariables: PropTypes.object,
  /** a function that will be called with the query results, which
   * should return the options in an array of `{label, value}` objects
   */
  valuesFromResponse: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  /** This will be invoked with a `{label, value}` pair or an array of them,
   * if `isMulti` is set to `true`.
   */
  onChange: PropTypes.func,
  /** A singular value, or an array of them if `isMulti` is true */
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  ]),
  /** A singular value, or an array of them if `isMulti` is true.
   * Use this prop if you're not attaching an `onChange` handler.
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  ]),
  /** Allow the user to clear their selection once they select it. */
  isClearable: PropTypes.bool,
  /** Allow multiple selection. */
  isMulti: PropTypes.bool,
  /** Only load results the first time; use a cache thereafter */
  cacheOptions: PropTypes.bool,
  /** options => options */
  filterOptions: PropTypes.func,
};

export default InputAutocompleteGraphQL;
