import { Button } from "@heart/components";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import T from "@components/T";

import generateId from "@lib/generateId";

import styles from "./SignatureInput.module.scss";
import TextSignature from "./TextSignature";

const SignatureTextInput = ({
  width,
  height,
  updateSignatureUri,
  canCancel,
  cancel,
  name: propsName = "",
}) => {
  const inputRef = useRef();
  const [canSave, setCanSave] = useState(false);
  const [name, setName] = useState(propsName);
  const [signatureUri, setSignatureUri] = useState("");
  const [nameInputId] = useState(generateId());

  useEffect(() => {
    inputRef.current.focus();
  });

  const onSave = () => {
    updateSignatureUri(signatureUri);
  };

  const onChange = ({ target }) => {
    setName(target.value);
  };

  const onImageUpdate = newSignatureUri => {
    setSignatureUri(newSignatureUri);
    setCanSave(true);
  };

  return (
    <div
      className={styles.signatureInput}
      style={{ maxWidth: width, minHeight: height }}
    >
      <TextSignature {...{ name, width, height, onImageUpdate }} />
      <label htmlFor={nameInputId}>{I18n.t("views.common.name")}</label>
      <input id={nameInputId} onChange={onChange} value={name} ref={inputRef} />
      <div>
        <Button variant="secondary" disabled={!canSave} onClick={onSave}>
          <T t="bintisign.signature_input.common.save_my_signature" />
        </Button>
        {canCancel && (
          <Button variant="secondary" onClick={() => cancel()}>
            <T t="bintisign.signature_input.common.cancel" />
          </Button>
        )}
      </div>
    </div>
  );
};

SignatureTextInput.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  updateSignatureUri: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  canCancel: PropTypes.bool.isRequired,
  name: PropTypes.string,
};

export default SignatureTextInput;
