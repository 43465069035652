import { postcodeValidator } from "postcode-validator";

/** Checks if a postal code value is a "valid" postal code. A postal code is "valid"
 *  when it match the regular expressions for that country. If no country
 *  is specified, it will assume US. */
const isValidPostalCode = (postalCode, country = "US") =>
  postcodeValidator(postalCode, country);

export { isValidPostalCode };

/** Checks if the postcodeValidator recognizes postal from this country. */
export { postcodeValidatorExistsForCountry } from "postcode-validator";
