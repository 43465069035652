import I18n from "i18n-js";
import { isEmpty, orderBy } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import ShowHide from "@components/ShowHide";
import AttachmentControl from "@components/requirements/AttachmentControl";

import { policy } from "@lib/graphqlHelpers";

import styles from "./ApplicationRequirementTable.module.scss";

const FormInstanceAttachmentControl = ({
  formInstance,
  attachment,
  deleteAttachment,
}) => {
  const mayDownloadAttachment = policy(formInstance).mayDownloadAttachment();
  const mayDestroyAttachment =
    policy(formInstance).mayDestroyAttachment() ||
    policy(attachment).mayDestroy();

  return (
    <AttachmentControl
      {...{
        attachment,
        deleteAttachment,
        mayDestroyAttachment,
        mayDownloadAttachment,
      }}
    />
  );
};

FormInstanceAttachmentControl.propTypes = {
  attachment: PropTypes.object.isRequired,
  formInstance: PropTypes.object.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
};

const createdFromSigningService = ({ createdFrom }) =>
  ["bintisign", "hellosign"].includes(createdFrom);

const FormInstanceWithHiddenOtherAttachments = ({
  formInstance,
  deleteAttachment,
}) => {
  const sortedAttachments = orderBy(
    formInstance.attachments,
    "createdAt",
    "desc"
  );
  let shownAttachments = [];
  let hiddenAttachments = [];

  if (
    !createdFromSigningService(sortedAttachments[0]) ||
    (createdFromSigningService(sortedAttachments[0]) &&
      formInstance.state === "allSigned")
  ) {
    shownAttachments = sortedAttachments.slice(0, 1);
    hiddenAttachments = sortedAttachments.slice(1);
  } else {
    // if the latest attachment is from a signing service
    // and it's not all signed, hide all attachments
    hiddenAttachments = sortedAttachments;
  }

  return (
    <Fragment>
      <If condition={!isEmpty(shownAttachments)}>
        {shownAttachments.map(attachment => (
          <li key={attachment.id} className={styles.recordRow}>
            <FormInstanceAttachmentControl
              {...{
                formInstance,
                attachment,
                deleteAttachment,
              }}
            />
          </li>
        ))}
      </If>
      <If condition={!isEmpty(hiddenAttachments)}>
        <ShowHide
          initiallyOpen={false}
          renderHeader={() =>
            I18n.t(
              "javascript.components.application_requirements_shared.form_instance_with_hidden_older_attachments.older_documents"
            )
          }
          renderBody={() =>
            hiddenAttachments.map(attachment => (
              <FormInstanceAttachmentControl
                key={attachment.id}
                {...{
                  formInstance,
                  attachment,
                  deleteAttachment,
                }}
              />
            ))
          }
        />
      </If>
    </Fragment>
  );
};

FormInstanceWithHiddenOtherAttachments.propTypes = {
  formInstance: PropTypes.object.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
};

export default FormInstanceWithHiddenOtherAttachments;
