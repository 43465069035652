import { useIsMounted, useRerender, useUpdateEffect } from "@react-hookz/web";
import { useMemo, useRef } from "react";

/** This hook was copied over from [react-use](https://github.com/streamich/react-use)
 * as the library is no longer being maintained. We're moving to `react-hookz` as the
 * supported alternative, but location related hooks will not be migrated over so we're
 * pulling the hooks we need directly into our codebase as an alternative
 *
 * Their [license](https://github.com/streamich/react-use/blob/325f5bd69904346788ea981ec18bfc7397c611df/LICENSE#L1-L24),
 * allows us to copy, modify, publish, use, compile, sell, or distribute this
 * software, either in source code form or as a compiled binary, for any purpose,
 * commercial or non-commercial, and by any means.
 *
 *
 * This one should eventually be migrated over, at which point we can remove our
 * version and use the `react-hookz` version
 */
const useStateList = stateSet => {
  const isMounted = useIsMounted();
  const update = useRerender();
  const index = useRef(0);

  // If new state list is shorter that before - switch to the last element
  useUpdateEffect(() => {
    if (stateSet.length <= index.current) {
      index.current = stateSet.length - 1;
      update();
    }
  }, [stateSet.length]);

  const actions = useMemo(
    () => ({
      next: () => actions.setStateAt(index.current + 1),
      prev: () => actions.setStateAt(index.current - 1),
      setStateAt: newIndex => {
        // do nothing on unmounted component
        if (!isMounted()) return;

        // do nothing on empty states list
        if (!stateSet.length) return;

        // in case new index is equal current - do nothing
        if (newIndex === index.current) return;

        // it gives the ability to travel through the left and right borders.
        // 4ex: if list contains 5 elements, attempt to set index 9 will bring use to 5th element
        // in case of negative index it will start counting from the right, so -17 will bring us to 4th element
        index.current =
          newIndex >= 0
            ? newIndex % stateSet.length
            : stateSet.length + (newIndex % stateSet.length);
        update();
      },
      setState: state => {
        // do nothing on unmounted component
        if (!isMounted()) return;

        const newIndex = stateSet.length ? stateSet.indexOf(state) : -1;

        if (newIndex === -1) {
          throw new Error(
            `State '${state}' is not a valid state (does not exist in state list)`
          );
        }

        index.current = newIndex;
        update();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateSet]
  );

  return {
    state: stateSet[index.current],
    currentIndex: index.current,
    ...actions,
  };
};

export default useStateList;
