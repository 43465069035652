import classnames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import styles from "./HelloSign.module.scss";
import HellosignSignatureLauncher from "./HellosignSignatureLauncher";

const HellosignSignatureWrapper = props => {
  const { role, initialSignatureState } = props;
  const isCaseworker = role === "caseworker";

  if (initialSignatureState["earlierSignatureRequired?"]) {
    if (isCaseworker) {
      return (
        <div
          className={classnames(
            styles.signatureWrapper,
            styles.caseworkerWaitingForSignature
          )}
        >
          {I18n.t("common.waiting_for_applicants_to_sign")}
        </div>
      );
    }

    return (
      <div
        className={classnames(
          styles.signatureWrapper,
          styles.caseworkerWaitingForSignature
        )}
      >
        {I18n.t("common.waiting_for_caseworker_to_sign")}
      </div>
    );
  }

  if (initialSignatureState["needSignatures?"]) {
    return <HellosignSignatureLauncher {...props} />;
  }

  if (initialSignatureState["laterSignatureRequired?"]) {
    if (isCaseworker) {
      if (!isEmpty(initialSignatureState.signerBlobs)) {
        return (
          <div
            className={classnames(
              styles.signatureWrapper,
              styles.caseworkerWaitingForSignature
            )}
          >
            {I18n.t("common.waiting_for_applicants_to_sign")}
          </div>
        );
      }

      return false;
    }

    return (
      <div className={styles.signatureWrapper}>
        {I18n.t("common.waiting_for_caseworker_to_sign")}
      </div>
    );
  }

  if (
    !isCaseworker &&
    initialSignatureState["supervisorSignatureRequiredNext?"]
  ) {
    return (
      <div className={styles.signatureWrapper}>
        {I18n.t("common.waiting_for_supervisor_to_sign")}
      </div>
    );
  }

  return false;
};

HellosignSignatureWrapper.propTypes = {
  role: PropTypes.string.isRequired,
  pollUrl: PropTypes.string.isRequired,
  initialSignatureState: PropTypes.shape({
    signerBlobs: PropTypes.array,
    "pending?": PropTypes.bool,
    "needSignatures?": PropTypes.bool,
    "laterSignatureRequired?": PropTypes.bool,
    "earlierSignatureRequired?": PropTypes.bool,
    "supervisorSignatureRequiredNext?": PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default HellosignSignatureWrapper;
