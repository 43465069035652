const isLongerThan = length => password => password.length >= length;
const hasNumber = password => /[0-9]/.test(password);
const hasSpecialChar = password => /[^A-Za-z0-9]/.test(password);
const hasNoRepeatingChars = password =>
  [...password].every(
    (char, idx) => !(char === password[idx - 1] && char === password[idx + 1])
  );
const hasNoSequentialChars = password =>
  [...password].every((char, idx) => {
    const previousChar = String.fromCharCode(char.charCodeAt(0) - 1);
    const nextChar = String.fromCharCode(char.charCodeAt(0) + 1);
    const isAscending =
      password[idx - 1] === previousChar && password[idx + 1] === nextChar;
    const isDescending =
      password[idx - 1] === nextChar && password[idx + 1] === previousChar;
    return !(isAscending || isDescending);
  });
const hasNoSequentialOrRepeating = password =>
  hasNoRepeatingChars(password) && hasNoSequentialChars(password);

const doesNotContainBinti = password => !/[Bb]inti/.test(password);
const doesNotContainUsername = username => password =>
  !password.includes(username);

// The value next to each rule is the rule's weight.
// Failing the rule is a deduction from a high-score of 100.
//
// The string is an I18n key that contains text describing how to improve
// the password for a given rule.
export const produceRules = options => [
  [isLongerThan(8), 60, "longer_than_eight"],
  [hasNumber, 10, "has_number"],
  [hasSpecialChar, 10, "has_special_char"],
  [hasNoSequentialOrRepeating, 20, "has_no_sequential_or_repeating"],
  [doesNotContainBinti, 20, "does_not_contain_binti"],
  ...(options.username
    ? [doesNotContainUsername(options.username), 1, "does_not_contain_username"]
    : []),
];

export const computePasswordScore = rules => password => {
  const deductions = rules.reduce(
    (ded, [rule, weight]) => (rule(password) ? ded : ded + weight),
    0
  );
  return 100 - deductions;
};

export const allSuggestionKeys = rules => rules.map(r => r[2]);

export const getSuggestionKeys = rules => password =>
  rules.reduce((keys, rule) => {
    const [ruleFn, key] = [rule[0], rule[2]];
    if (!ruleFn(password)) {
      keys.push(key);
    }
    return keys;
  }, []);
