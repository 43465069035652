import { useMutation } from "@apollo/client";
import {
  Surface,
  SurfaceForm,
  Icons,
  DetailsTable,
  InputDate,
  InputTextarea,
  Actions,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { useState } from "react";

import CreateHearingSesssion from "@graphql/mutations/CreateHearingSession";
import UpdateHearingSession from "@graphql/mutations/UpdateHearingSession";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const HearingSession = ({
  existingHearingSession,
  sessionNumber,
  addingNewSession,
  setAddingNewSession,
  appealId,
  refetchQueries,
}) => {
  const constructedHearingSessionObject = () => {
    const {
      id = null,
      hearingNotes,
      hearingSessionDate,
    } = existingHearingSession || {};

    return {
      id,
      hearingNotes,
      hearingSessionDate,
    };
  };

  const [editing, setEditing] = useState(false);
  const [hearingSessionDate, setHearingSessionDate] = useState(
    constructedHearingSessionObject(existingHearingSession).hearingSessionDate
  );
  const [hearingNotes, setHearingNotes] = useState(
    constructedHearingSessionObject(existingHearingSession).hearingNotes
  );

  const [updateHearingSesssion] = useMutation(UpdateHearingSession, {
    refetchQueries,
  });
  const [createHearingSession] = useMutation(CreateHearingSesssion, {
    refetchQueries,
  });

  const handleHearingSesssion = preventDefault(() => {
    if (addingNewSession) {
      createHearingSession({
        variables: {
          appealId,
          hearingSessionDate,
          hearingNotes,
        },
        onCompleted: () => {
          setEditing(false);
          setAddingNewSession(false);
        },
      });
    } else {
      updateHearingSesssion({
        variables: {
          hearingSessionId: constructedHearingSessionObject(
            existingHearingSession
          ).id,
          hearingSessionDate,
          hearingNotes,
        },
        onCompleted: () => {
          setEditing(false);
        },
      });
    }
  });

  if (editing)
    return (
      <SurfaceForm
        title={I18n.t("javascript.components.appeals.hearing_session_number", {
          session: sessionNumber,
        })}
        actions={
          <Actions
            cancelAction={() => {
              setHearingNotes(
                constructedHearingSessionObject(existingHearingSession)
                  .hearingNotes
              );
              setHearingSessionDate(
                constructedHearingSessionObject(existingHearingSession)
                  .hearingSessionDate
              );
              setEditing(false);
            }}
          />
        }
        onSubmit={handleHearingSesssion}
      >
        <InputDate
          label={I18n.t("javascript.components.appeals.hearing_session_date")}
          name="hearingSessionDate"
          value={hearingSessionDate}
          onChange={setHearingSessionDate}
          required
        />
        <InputTextarea
          label={I18n.t("javascript.components.appeals.hearing_session_notes")}
          name="hearingNotes"
          value={hearingNotes}
          onChange={setHearingNotes}
        />
      </SurfaceForm>
    );
  return (
    <Surface
      title={I18n.t("javascript.components.appeals.hearing_date", {
        session: sessionNumber,
      })}
      secondary={
        <Icons.Pencil
          description={I18n.t(
            "javascript.components.appeals.hearing_session_details_edit",
            {
              session: sessionNumber,
            }
          )}
          onClick={() => setEditing(true)}
        />
      }
    >
      <DetailsTable
        details={[
          {
            label: I18n.t(
              "javascript.components.appeals.hearing_session_notes"
            ),
            value: hearingNotes,
          },
          {
            label: I18n.t("javascript.components.appeals.hearing_session_date"),
            value: hearingSessionDate,
          },
        ]}
      />
    </Surface>
  );
};

HearingSession.propTypes = {
  existingHearingSession: PropTypes.shape({
    id: BintiPropTypes.ID,
    hearingNotes: PropTypes.text,
    hearingSessionDate: PropTypes.date,
  }),
  sessionNumber: PropTypes.number,
  addingNewSession: PropTypes.bool,
  setAddingNewSession: PropTypes.func,
  appealId: BintiPropTypes.ID,
  refetchQueries: PropTypes.array,
};

export default HearingSession;
