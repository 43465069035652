import { InputDropdown } from "@heart/components";
import PropTypes from "prop-types";

/*
 * A dropdown for displaying i18n-translated enum values.  See `@root/constants` for
 * an example of how to pull values from Rails and get parameters for this component.
 */
const EnumDropdown = ({ i18nModel, i18nAttribute, values, ...props }) => {
  const valuesForDropdown = values.map(value => [
    I18n.t(`activerecord.enums.${i18nModel}.${i18nAttribute}.${value}`),
    value,
  ]);

  return <InputDropdown {...props} values={valuesForDropdown} />;
};

EnumDropdown.propTypes = {
  ...InputDropdown.propTypes,
  i18nModel: PropTypes.string.isRequired,
  i18nAttribute: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EnumDropdown;
