import { useMutation } from "@apollo/client";

import DeleteRole from "@graphql/mutations/DeleteRole";
import CustomRoles from "@graphql/queries/CustomRoles";

export default (agencyId, options) =>
  useMutation(DeleteRole, {
    ...options,
    update: (cache, { data }) =>
      cache.updateQuery({ query: CustomRoles, variables: { agencyId } }, d => ({
        customRoles: d.customRoles.filter(
          r => r.id.toString() !== data.deleteRole.roleId
        ),
      })),
  });
