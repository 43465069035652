import { InputHidden } from "@heart/components";
import PropTypes from "prop-types";
import { useState, Fragment } from "react";

import NestedEnum from "./NestedEnum";

/**
 * NestedEnumFormInput accepts a set of arbitrarily nested options
 * and populates two inputs, specified by `optionInputName` and
 * `detailsInputName`, with a single selected option from that set
 * and possibly details if allowed/required by the `selected` option.
 *
 * Implementation: `NestedEnumFormInput` is a state-maintaining component
 * that manages state for the nested option tree whose root is `NestedEnum`.
 * It renders the inputs as hidden text inputs based on that state.
 *
 * Currently, this component is used for "How did you hear about us?"
 * and for the Confidential License reasons component.
 */
const NestedEnumFormInput = ({
  optionInputName,
  isRequired,
  detailsInputName,
  options,
  label,
  placeholderLabel,
  initialSelectedOptionPath = "",
  initialSelectedOptionDetails = "",
}) => {
  const [selectedOptionPath, setSelectedOptionPath] = useState(
    initialSelectedOptionPath
  );

  const [selectedOptionDetails, setSelectedOptionDetails] = useState(
    initialSelectedOptionDetails
  );

  const onOptionSelected = (path, details = "") => {
    setSelectedOptionPath(path);
    setSelectedOptionDetails(details);
  };

  return (
    <Fragment>
      <NestedEnum
        {...{
          label,
          isRequired,
          placeholderLabel,
          options,
          onOptionSelected,
          initialSelectedOptionPath,
          initialSelectedOptionDetails,
        }}
      />
      <InputHidden name={optionInputName} value={selectedOptionPath} />
      <InputHidden name={detailsInputName} value={selectedOptionDetails} />
    </Fragment>
  );
};

NestedEnumFormInput.propTypes = {
  optionInputName: PropTypes.string.isRequired,
  detailsInputName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  placeholderLabel: PropTypes.string.isRequired,
  initialSelectedOptionPath: PropTypes.string,
  initialSelectedOptionDetails: PropTypes.string,
};

export default NestedEnumFormInput;
