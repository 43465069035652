import { useMutation } from "@apollo/client";
import { Modal } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React from "react";

import DeleteAppeal from "@graphql/mutations/DeleteAppeal.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const DeleteAppealModal = ({ appealId, applicationId, onCancel }) => {
  const [deleteAppeal] = useMutation(DeleteAppeal, {
    onCompleted: () => {
      window.location = `/admin/applications/${applicationId}/change_status`;
    },
  });

  return (
    <Modal
      title={I18n.t("javascript.components.appeals.delete_appeal_title")}
      onCancel={onCancel}
      onSubmit={() => deleteAppeal({ variables: { appealId } })}
    >
      {I18n.t("javascript.components.appeals.delete_appeal_text")}
    </Modal>
  );
};

DeleteAppealModal.propTypes = {
  appealId: BintiPropTypes.ID,
  applicationId: BintiPropTypes.ID,
  onCancel: PropTypes.func,
};

export default DeleteAppealModal;
