import {
  Flex,
  FlexItem,
  HeartTable,
  InputFilterableGraphQL,
  Link,
  LiveRegion,
  Text,
} from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";
import {
  constructAddressLabel,
  displayAddress,
  displayEmails,
  displayPhoneNumbers,
} from "@components/agency_humans/displayAgencyHumanInfo";

import AgencyHumanRelationships from "@graphql/queries/AgencyHumanRelationships.graphql";

import { ADDRESS_TYPE_MAILING } from "@root/constants";

import styles from "./AgencyHumansInputWithContactInfo.module.scss";
import { getOptionsFromRelationshipsData } from "./ContactLogForm";

const { t: contactLogsT } = translationWithRoot("family_finding.contact_logs");
const { t: contactCardTableT } = translationWithRoot(
  "family_finding.contact_logs.contact_log_form.contact_card_table"
);
const { Table, Caption, Thead, Tbody, Tr, Th, Td } = HeartTable;

const AgencyHumansInputWithContactInfo = ({
  agencyHumans,
  setAgencyHumans,
  childAgencyHuman,
  disabled,
}) => (
  <Fragment>
    <InputFilterableGraphQL
      isMulti
      label={contactLogsT("contact_log_form.binti_contacted")}
      value={agencyHumans}
      query={AgencyHumanRelationships}
      queryVariables={{ agencyHumanId: childAgencyHuman.id }}
      transformQueryData={getOptionsFromRelationshipsData(childAgencyHuman)}
      onChange={setAgencyHumans}
      isClearable={false}
      disabled={disabled}
    />
    <LiveRegion>
      <If condition={!isEmpty(agencyHumans)}>
        <Table>
          <Caption>{contactCardTableT("contact_info")}</Caption>
          <Thead>
            <Tr>
              <Th>{contactCardTableT("name")}</Th>
              <Th>{contactCardTableT("address")}</Th>
              <Th>{contactCardTableT("phone")}</Th>
              <Th>{contactCardTableT("email")}</Th>
              <Th>
                <div className={styles.leftAlign}>
                  {contactCardTableT("social_media")}
                </div>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {agencyHumans.map(
              ({
                value,
                label,
                childPhoneNumber,
                addresses = [],
                phoneNumbers = [],
                emailAddresses = [],
                socialMediaLinks = [],
                linkToView,
              }) => (
                <Tr key={value}>
                  <Td>
                    <Link href={linkToView} target="_blank">
                      {label}
                    </Link>
                  </Td>
                  <Td>
                    <Flex column as="ul" className={styles.list}>
                      {addresses
                        .filter(
                          ({ inactive, primary, type }) =>
                            !inactive &&
                            (primary || type.includes(ADDRESS_TYPE_MAILING))
                        )
                        .map(addr => (
                          <Fragment key={addr.id}>
                            <Text textStyle="emphasis-100">
                              {constructAddressLabel(addr)}
                            </Text>
                            {displayAddress(addr)}
                          </Fragment>
                        ))}
                    </Flex>
                  </Td>
                  <Td>
                    {displayPhoneNumbers({
                      childPhoneNumber,
                      phoneNumbers: phoneNumbers.filter(
                        ({ primary }) => primary
                      ),
                    })}
                  </Td>
                  <Td>
                    {displayEmails({
                      emailAddresses: emailAddresses.filter(
                        ({ primary }) => primary
                      ),
                    })}
                  </Td>
                  <Td>
                    <Flex as="ul" column className={styles.list} align="start">
                      {socialMediaLinks.map(link => (
                        <FlexItem as="li" key={link}>
                          {link}
                        </FlexItem>
                      ))}
                    </Flex>
                  </Td>
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      </If>
    </LiveRegion>
  </Fragment>
);
AgencyHumansInputWithContactInfo.propTypes = {
  /** Details of the agency humans selected */
  agencyHumans: PropTypes.array.isRequired,
  /** The core child the contact log is associated with */
  childAgencyHuman: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  }).isRequired,
  /** Form state setting function */
  setAgencyHumans: PropTypes.func.isRequired,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
};

export default AgencyHumansInputWithContactInfo;
