import { Button, Link } from "@heart/components";
import classnames from "classnames";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import Dropzone from "react-dropzone";
import { pdfjs } from "react-pdf/dist/entry.webpack";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";

import preventDefault from "@lib/preventDefault";

import UploadTranslationsClient from "../../clients/UploadTranslationsClient";
import styles from "./HelloSignPDFReplacer.module.scss";

const DragHandle = SortableHandle(() => (
  <div className={classnames(styles.dragHandle, "fa fa-bars")} />
));

const SortableItem = SortableElement(
  ({ fileId, file, pageCount, removeFile }) => (
    <li className={styles.fileItem}>
      <DragHandle />
      <div className={styles.fileName}>{file.name}</div>
      <div className={styles.pageCount}>{pageCount} page(s)</div>
      <Link
        onClick={preventDefault(() => {
          removeFile(fileId);
        })}
      >
        <i className="fa fa-trash" />
      </Link>
    </li>
  )
);

const SortableList = SortableContainer(({ items, removeFile }) => (
  <ul className={styles.fileList}>
    {items.map((value, index) => (
      <SortableItem
        key={`item-${index}`}
        {...{ index, removeFile }}
        {...value}
      />
    ))}
  </ul>
));

const getDroppedFilePageCounts = droppedFiles =>
  Promise.all(
    droppedFiles.map(
      file =>
        new Promise((resolve, reject) => {
          // this component is only ever used by Binti admins
          const reader = new FileReader();
          reader.onload = () => {
            pdfjs
              .getDocument(reader.result)
              .promise.then(doc => {
                resolve({
                  file,
                  fileId: uniqueId(),
                  pageCount: doc.numPages,
                });
              })
              .catch(reject);
          };
          reader.readAsDataURL(file);
        })
    )
  );

class HelloSignPDFReplacer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
    };

    const {
      uploadPath,
      sourceHellosignTemplateId,
      targetLocale,
      field,
      formSlug,
    } = props;

    this.client = new UploadTranslationsClient(
      {
        uploadPath,
        sourceHellosignTemplateId,
        targetLocale,
        field,
        formSlug,
      },
      this.templateCreated
    );
  }

  templateCreated = templateId => {
    this.setState({
      templateId,
    });
  };

  onDrop = droppedFiles => {
    getDroppedFilePageCounts(droppedFiles).then(newFiles => {
      const { files } = this.state;
      this.setState({ files: files.concat(newFiles) });
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { files } = this.state;
    this.setState({
      files: arrayMove(files, oldIndex, newIndex),
    });
  };

  upload = preventDefault(() => {
    const { files } = this.state;
    this.client.uploadTranslations(files.map(f => f.file));
  });

  removeFile = toRemove => {
    const { files } = this.state;

    this.setState({
      files: files.filter(({ fileId }) => fileId !== toRemove),
    });
  };

  render() {
    const { pagesRequired, hellosignTemplatePrefix, formPath } = this.props;
    const { files, templateId } = this.state;
    const totalPageCount = files.reduce(
      (acc, { pageCount }) => acc + pageCount,
      0
    );

    return (
      <div className={styles.wrapper}>
        {!templateId && (
          <div>
            <div className={styles.pageCount}>
              Total pages required: {pagesRequired}
            </div>
            <div className={styles.pageCount}>
              Current page count: {totalPageCount}
            </div>
            <Dropzone onDrop={this.onDrop} accept={"application/pdf"}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  className={classnames(styles.dropzone, {
                    [styles.dropzoneActive]: isDragActive,
                  })}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  Click or drag to upload
                </div>
              )}
            </Dropzone>
            {files.length > 0 && <p>Drag to reorder</p>}
            <SortableList
              items={files}
              onSortEnd={this.onSortEnd}
              removeFile={this.removeFile}
              useDragHandle={true}
            />
            <Button
              disabled={totalPageCount !== pagesRequired}
              onClick={this.upload}
            >
              Upload to Hellosign
            </Button>
          </div>
        )}
        {templateId && (
          <div>
            <h2>
              The template was created!
              <i className="fa fa-star" />
            </h2>
            <ul className={styles.templateCreatedActions}>
              <li>
                <b>Template ID: </b> {templateId}
              </li>
              <li>
                <a
                  href={`${hellosignTemplatePrefix}${templateId}`}
                  target={templateId}
                >
                  Edit in Hellosign <i className="fa fa-external-link" />
                </a>
              </li>
              {formPath && (
                <li>
                  <a href={formPath}>Back to the form</a>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

HelloSignPDFReplacer.propTypes = {
  sourceHellosignTemplateId: PropTypes.string.isRequired,
  pagesRequired: PropTypes.number.isRequired,
  uploadPath: PropTypes.string.isRequired,
  hellosignTemplatePrefix: PropTypes.string.isRequired,
  formPath: PropTypes.string,
  formSlug: PropTypes.string,
  targetLocale: PropTypes.string,
  field: PropTypes.string,
};

export default HelloSignPDFReplacer;
