import PropTypes from "prop-types";
import { useRef } from "react";

import preventDefault from "@lib/preventDefault";

/**
 * Flexible "copy to clipboard" component - this component just displays
 * a user-provided button (the button prop uses the "Render Props"[1] pattern).
 *
 * When you click that button, it copies the text you've given to the `text`
 * prop to the clipboard.
 */
const CopyToClipboard = ({ text, button }) => {
  const ref = useRef();

  const copyToClipboard = preventDefault(() => {
    if (!ref.current) return;

    const textarea = document.createElement("textarea");
    textarea.value = text;
    ref.current.appendChild(textarea);
    textarea.focus();
    textarea.setSelectionRange(0, text.length);
    document.execCommand("copy");
    ref.current.removeChild(textarea);
  });

  const Button = button;
  return (
    <span ref={ref}>
      <Button onClick={copyToClipboard} />
    </span>
  );
};

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  button: PropTypes.func.isRequired,
};

export default CopyToClipboard;
