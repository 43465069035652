import PropTypes from "prop-types";

import styles from "./ApplicationTemplateDesigner.module.scss";

const NEEDED_FOR = {
  child: "CH",
  applicant: "A",
  co_applicant: "CO",
  application: "CW",
  other_adult_in_home: "OA",
  child_in_home: "CH",
  reference_user: "REF",
  staff_member: "SM",
};

const NeededFor = ({ recordType }) => {
  if (!recordType.neededFor) return false;
  const neededFors = recordType.neededFor;

  return (
    <div className={styles.formNeededForBadges}>
      <p>Needed for:</p>
      {neededFors.map(neededFor => (
        <div
          key={neededFor}
          className={styles.formNeededForBadge}
          alt={`Needed for ${neededFor}`}
          title={`Needed for ${neededFor}`}
        >
          {NEEDED_FOR[neededFor]}
        </div>
      ))}
    </div>
  );
};

NeededFor.propTypes = {
  recordType: PropTypes.object.isRequired,
};

export default NeededFor;
