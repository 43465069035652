import PropTypes from "prop-types";

import CreateApplicationFormRequirementTemplate from "@graphql/mutations/CreateApplicationFormRequirementTemplate.graphql";
import RemoveApplicationFormRequirementTemplate from "@graphql/mutations/RemoveApplicationFormRequirementTemplate.graphql";

import AllRequirementsOfAType from "./AllRequirementsOfAType";
import Form from "./Form";
import FormAdder from "./FormAdder";

const AllForms = ({ applicationTemplate, stageRole, defaultIsCollapsed }) => (
  <AllRequirementsOfAType
    type="form"
    createMutation={CreateApplicationFormRequirementTemplate}
    removeMutation={RemoveApplicationFormRequirementTemplate}
    AdderComponent={FormAdder}
    DisplayComponent={Form}
    {...{ applicationTemplate, stageRole, defaultIsCollapsed }}
  />
);

AllForms.propTypes = {
  /** An ApplicationTemplate GraphQL object */
  applicationTemplate: PropTypes.object.isRequired,
  /**
   * Role of stages currently visible. Used to determine if the objects
   * of `type` are already assigned in the current view.
   */
  stageRole: PropTypes.string.isRequired,
  /**
   * Should the component start out collapsed? The user will have the ability
   * to show/hide.
   */
  defaultIsCollapsed: PropTypes.bool,
};

export default AllForms;
