import {
  Button,
  Icons,
  Flex,
  Link,
  FlexItem,
  InputText,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { useState } from "react";

import Csrf from "@components/Csrf";
import GoogleSSO from "@components/GoogleSSO";
import { translationWithRoot } from "@components/T";

import styles from "./EmailLogin.module.scss";

const { T } = translationWithRoot("permissions.login");

const EmailLogin = ({ displayGoogleSso, googleSsoProps }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Flex justify="space-between" column align="center" gap="300">
      <form action="/users/login" method="post">
        <Flex column align="center" gap="300">
          <div className={styles.field}>
            <InputText
              required
              label={<T t="email_address" />}
              name="user[email]"
              type="email"
              id="user_email"
            />
            <Flex align="end">
              <FlexItem>
                <InputText
                  type={showPassword ? "text" : "password"}
                  required
                  className={styles.passwordInput}
                  label={<T t="password" />}
                  name="user[password]"
                  id="user_password"
                />
              </FlexItem>
              <FlexItem expand="none" className={styles.showHideIcon}>
                <If condition={!showPassword}>
                  <Icons.Eye
                    onClick={() => {
                      setShowPassword(true);
                    }}
                    description={I18n.t(
                      "javascript.components.permissions.login.show_password"
                    )}
                  />
                </If>
                <If condition={showPassword}>
                  <Icons.EyeSlash
                    onClick={() => {
                      setShowPassword(false);
                    }}
                    description={I18n.t(
                      "javascript.components.permissions.login.hide_password"
                    )}
                  />
                </If>
              </FlexItem>
            </Flex>
          </div>
          <Csrf />
          <Link href="/users/password/new">
            {I18n.t("devise.shared.links.forgot_your_password")}
          </Link>
          <Button type="submit" variant="primary">
            {I18n.t("views.devise.common.sign_in")}
          </Button>
        </Flex>
      </form>
      <If condition={displayGoogleSso}>
        <GoogleSSO {...googleSsoProps} />
      </If>
      <Flex>
        <Link href="https://binti.com/terms/">
          {I18n.t("views.shared.footer.terms_of_use")}
        </Link>{" "}
        |{" "}
        <Link href="https://binti.com/privacy/">
          {I18n.t("views.shared.footer.privacy_policy")}
        </Link>
      </Flex>
    </Flex>
  );
};

EmailLogin.propTypes = {
  displayGoogleSso: PropTypes.bool,
  googleSsoProps: PropTypes.shape({
    googleOauth2ClientId: PropTypes.string,
    redirectPath: PropTypes.string,
    callbackPath: PropTypes.string,
  }),
};

export default EmailLogin;
