import PropTypes from "prop-types";

import T from "@components/T";

import styles from "../../BintiSign.module.scss";
import rolesT from "../../rolesT";
import ScaledSignatureBlock from "./ScaledSignatureBlock";

/**
 * Renders a signature block that is not for this user (at least not in their
 * current role). May be signed or unsigned, but this user cannot interact
 * with it at the moment.
 */
const SomeoneElsesSignatureBlock = ({ isSigned, location, scale }) => {
  const blockContent = isSigned ? (
    ""
  ) : (
    <T t="bintisign.common.role_signature" role={rolesT(location.signerRole)} />
  );

  return (
    <ScaledSignatureBlock
      containerClassName={styles.signatureBlockInactive}
      {...{ location, scale, blockContent }}
    />
  );
};

SomeoneElsesSignatureBlock.propTypes = {
  isSigned: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  scale: PropTypes.number.isRequired,
};

export default SomeoneElsesSignatureBlock;
