import { isNil } from "lodash";
import PropTypes from "prop-types";
import React from "react";

/** This component is the Binti alternative to `dangerouslySetInnerHTML`.
 *
 * Sometimes we have a string containing HTML and we need to render it as DOM.
 * By default, React's safety mechanism kicks in and protects us from XSS security
 * vulnerabilities.  Without using this component, you'll see `<` `>` angle brackets
 * in your code.
 *
 * When we're rendering HTML strings and we bypass the safety mechanism using this
 * component, you'll need to pass in a prop `trustedSource` documenting why you trust
 * this HTML string and where it came from.
 *
 * As a general rule, please do not make reusable components that accept dangerous HTML
 * and expect the users of those components to always pass safe props.  The responsibility
 * of marking HTML should be on the code *passing the data around*, so use this component
 * when passing data into a `prop`.
 *
 * You might fail a `PropTypes: expected String, got Object` warning when you do this.
 * In this case, you might need to change that component's API so that it accepts
 * `PropTypes.node` values.
 */
const ContainsTrustedHTML = ({ html, as, trustedSource, ...props }) => {
  if (isNil(html)) return null;

  // If you pass in something that's already a React element, we assume it's probably
  // because you've already wrapped a string in a `ContainsTrustedHTML` and return that
  // element.  This allows this function to be idempotent and gets around issues similar to
  // "double-escaping", letting you use `ContainsTrustedHTML` without worrying about whether
  // or not it will be wrapped again.
  if (React.isValidElement(html)) return html;

  const Component = as || "span";
  return <Component dangerouslySetInnerHTML={{ __html: html }} {...props} />;
};

export default ContainsTrustedHTML;

ContainsTrustedHTML.propTypes = {
  /** What kind of DOM element to use.  Defaults to `<span>` */
  as: PropTypes.string,
  /** Trusted HTML content - you must be sure this contains no script injection! */
  html: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Please document the reason you trust this HTML or name its source.
   *  Unused by the component itself, but required by `propTypes` as all
   *  trusted HTML should document why we trust it.
   */
  trustedSource: PropTypes.string.isRequired,
};
