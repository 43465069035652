/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

//######################
// Hack to move sidebar to main section for index
//######################

$(function () {
  $("#main_content").append($(".js-index-panel"));
  $(".js-index-panel").removeClass("sidebar_section panel");
  return $(".js-index-panel .panel_contents").removeClass("panel_contents");
});

$(function () {
  $("#active_admin_content").prepend($(".js-top-index-panel"));
  $(".js-top-index-panel").removeClass("sidebar_section panel");
  return $(".js-top-index-panel .panel_contents").removeClass("panel_contents");
});
