import { InputText, MultiInputTemplate } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("agency_human.form.social_media");

const SocialMediaSection = ({ formState, setFormAttribute }) => (
  <MultiInputTemplate
    title={t("social_media")}
    hideTitle={true}
    onChange={setFormAttribute("socialMediaLinks")}
    startingValues={formState.socialMediaLinks}
    sectionInputs={({ getMultiInputProps }) => (
      <InputText
        label={t("social_profile_link")}
        {...getMultiInputProps({ id: "socialMediaLink" })}
      />
    )}
    defaultValues={{ socialMediaLink: "" }}
    addSectionText={t("add_link")}
    removeSectionText={t("remove_link")}
    data-testid={"socialMediaSection"}
  />
);
SocialMediaSection.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
};

export default SocialMediaSection;
