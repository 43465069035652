import { gql, NetworkStatus, useQuery } from "@apollo/client";
import { Button, Flex, Icons, Notice, Text } from "@heart/components";
import I18n from "i18n-js";
import { isEmpty, omit, snakeCase, startCase } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

const { t, T } = translationWithRoot(
  "placement_preferences.preferences_conflict_notice"
);

const { t: childCharacteristicsT } = translationWithRoot(
  "activerecord.enums.common.child_characteristics",
  { escapeJavascriptRoot: true }
);

const { t: placementInterestsT } = translationWithRoot(
  "activerecord.models.placement_interest",
  { escapeJavascriptRoot: true }
);

const { t: adminPlacementsT } = translationWithRoot("admin.placement", {
  escapeJavascriptRoot: true,
});

export const PreferencesConflictQuery = gql`
  query PreferencesConflictQuery($agencyPlacementId: ID!) {
    preferencesConflicts(agencyPlacementId: $agencyPlacementId) {
      characteristicsOpenToConflicts
      demographicsOpenToConflicts {
        gendersOpenTo
        openToAllGenders
        ethnicitiesOpenTo
        numSiblingsOpenTo
        sexAssignedAtBirthOpenTo
        ageLowerOpenTo
        ageHigherOpenTo
      }
      placementInterestsConflicts {
        slug
      }
      hasConflicts
    }
  }
`;

const ConflictItem = ({ conflictName, newValue }) => (
  <Flex row>
    <Text textColor="danger-500">
      <Icons.ExclamationTriangle />
    </Text>
    <p>
      <b>{conflictName}</b> {t("updated_by_family")}: <em>{newValue}</em>
    </p>
  </Flex>
);

ConflictItem.propTypes = {
  conflictName: PropTypes.string.isRequired,
  newValue: PropTypes.string.isRequired,
};

const translateArray = (arr, key, suffix = null) => {
  const fullSuffix = suffix ? `.${suffix}` : "";
  return arr
    .map(val => I18n.t(`${key}.${snakeCase(val)}${fullSuffix}`))
    .join(", ");
};

const DemographicsConflicts = ({ demographicsOpenToConflicts }) => {
  if (Object.values(demographicsOpenToConflicts).every(c => c === null)) {
    return null;
  }

  const {
    gendersOpenTo,
    openToAllGenders,
    ethnicitiesOpenTo,
    numSiblingsOpenTo,
    sexAssignedAtBirthOpenTo,
    ageLowerOpenTo,
    ageHigherOpenTo,
  } = demographicsOpenToConflicts;

  return (
    <Fragment>
      <If condition={openToAllGenders}>
        <ConflictItem
          conflictName={startCase(adminPlacementsT("genders_open_to"))}
          newValue={t("open_to_all_genders")}
        />
      </If>
      <If condition={!openToAllGenders && gendersOpenTo}>
        <ConflictItem
          conflictName={startCase(adminPlacementsT("genders_open_to"))}
          newValue={translateArray(
            gendersOpenTo,
            "activerecord.enums.common.gender"
          )}
        />
      </If>
      <If condition={ethnicitiesOpenTo}>
        <ConflictItem
          conflictName={startCase(adminPlacementsT("ethnicities_open_to"))}
          // TODO(ENG-11325): do translations
          newValue={ethnicitiesOpenTo.join(", ")}
        />
      </If>
      <If condition={sexAssignedAtBirthOpenTo}>
        <ConflictItem
          conflictName={startCase(
            adminPlacementsT("sex_assigned_at_birth_open_to")
          )}
          // TOOD(ENG-11366): do translations
          newValue={sexAssignedAtBirthOpenTo.join(", ")}
        />
      </If>
      <If condition={numSiblingsOpenTo}>
        <ConflictItem
          conflictName={startCase(adminPlacementsT("num_siblings_open_to"))}
          // TODO(ENG-11340): do translations
          newValue={numSiblingsOpenTo.join(", ")}
        />
      </If>
      <If condition={ageLowerOpenTo}>
        <ConflictItem
          conflictName={startCase(adminPlacementsT("age_lower_open_to"))}
          newValue={ageLowerOpenTo}
        />
      </If>
      <If condition={ageHigherOpenTo}>
        <ConflictItem
          conflictName={startCase(adminPlacementsT("age_higher_open_to"))}
          newValue={ageHigherOpenTo}
        />
      </If>
    </Fragment>
  );
};

DemographicsConflicts.propTypes = {
  demographicsOpenToConflicts: PropTypes.object,
};

const CharacteristicsConflicts = ({ characteristicsOpenToConflicts }) => {
  if (!characteristicsOpenToConflicts) {
    return null;
  }

  const newOpenTo = (characteristic, openTo) => {
    const translatedChar = childCharacteristicsT(characteristic);
    return `${openTo ? t("open_to") : t("not_open_to")} ${translatedChar}`;
  };

  return (
    <Fragment>
      {Object.entries(characteristicsOpenToConflicts).map(
        ([characteristic, openTo]) => (
          <ConflictItem
            key={characteristic}
            conflictName={t("characteristics_medical_behaviors")}
            newValue={newOpenTo(characteristic, openTo)}
          />
        )
      )}
    </Fragment>
  );
};

CharacteristicsConflicts.propTypes = {
  characteristicsOpenToConflicts: PropTypes.object,
};

const PlacementInterestsConflicts = ({ placementInterestsConflicts }) => {
  if (isEmpty(placementInterestsConflicts)) {
    return null;
  }

  const interests = placementInterestsConflicts
    .map(c => placementInterestsT(c.slug))
    .join(", ");

  return (
    <ConflictItem
      conflictName={placementInterestsT("other")}
      newValue={`${t("interested_in")} ${interests}`}
    />
  );
};

PlacementInterestsConflicts.propTypes = {
  placementInterestsConflicts: PropTypes.array,
};

export const PreferencesConflictNoticeImpl = ({
  agencyPlacementId,
  onApprove,
  submitting,
}) => {
  const { loading, data, refetch, networkStatus } = useQuery(
    PreferencesConflictQuery,
    {
      variables: { agencyPlacementId },
      notifyOnNetworkStatusChange: true,
    }
  );

  const refetching = networkStatus === NetworkStatus.refetch;

  const noConflicts = !data || !data.preferencesConflicts.hasConflicts;

  // refetching causes data to be momentarily empty, and while refetching we still
  // want to show the notice, so only return null here if we aren't refetching
  if (!refetching && (loading || noConflicts)) {
    return null;
  }

  const {
    preferencesConflicts: {
      characteristicsOpenToConflicts,
      demographicsOpenToConflicts,
      placementInterestsConflicts,
    },
  } = data;

  const onClick = () => {
    onApprove().then(() => {
      refetch();
    });
  };

  return (
    <Notice title={t("notice")}>
      <T
        t="unapproved_preferences_html"
        buttonText={t("approve_preference_requests")}
      />
      <DemographicsConflicts
        demographicsOpenToConflicts={omit(
          demographicsOpenToConflicts,
          "__typename"
        )}
      />
      <CharacteristicsConflicts
        characteristicsOpenToConflicts={characteristicsOpenToConflicts}
      />
      <PlacementInterestsConflicts
        placementInterestsConflicts={placementInterestsConflicts}
      />
      <div>
        <Button onClick={onClick} submitting={submitting || refetching}>
          <T t="approve_preference_requests" />
        </Button>
      </div>
    </Notice>
  );
};

PreferencesConflictNoticeImpl.propTypes = {
  agencyPlacementId: PropTypes.number.isRequired,
  onApprove: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default PreferencesConflictNoticeImpl;
