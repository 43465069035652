import { Icons, Flex, Text } from "@heart/components";
import PropTypes from "prop-types";

const ToastMessage = ({ title, message, toastProps: { type } }) => (
  <Flex column align="start">
    <Choose>
      <When condition={type === "info"}>
        <Icons.InfoCircle />
      </When>
      <When condition={type === "success"}>
        <Icons.CheckCircle />
      </When>
      <When condition={type === "warning"}>
        <Icons.StickyNote />
      </When>
      <When condition={type === "error"}>
        <Icons.ExclamationTriangle />
      </When>
    </Choose>
    <Text textStyle="emphasis-200">{title}</Text>
    <Text textStyle="supporting-100">{message}</Text>
  </Flex>
);
ToastMessage.propTypes = {
  /** The Toast's title */
  title: PropTypes.string.isRequired,
  /** The Toast's message content */
  message: PropTypes.node.isRequired,
  /** Additional toast props from `react-toastify` - see:
   * https://fkhadra.github.io/react-toastify/render-what-you-want */
  toastProps: PropTypes.shape({
    type: PropTypes.oneOf(["info", "success", "warning", "error"]),
  }),
};

export default ToastMessage;
