import camelize from "camelize";
import React from "react";

import { ajaxDefaults } from "./common";

const basePath = doc =>
  `/applications/${doc.applicationId}/documents/${doc.id}`;

export default class DocumentsClient {
  constructor({ updatingDocument, updatedDocument, deletedDocument }) {
    this.updatingDocumentCb = updatingDocument;
    this.updatedDocumentCb = updatedDocument;
    this.deletedDocumentCb = deletedDocument;
  }

  handleUpdatedDocuments = (updatedDocuments, originalDoc) => {
    const camelized = camelize(updatedDocuments);

    camelized.requested.forEach(updatedDocument => {
      this.updatedDocumentCb(updatedDocument, originalDoc && originalDoc.id);
    });

    camelized.related.forEach(updatedDocument => {
      this.updatedDocumentCb(updatedDocument);
    });
  };

  onFail = doc => () => this.updatedDocumentCb(doc);

  mark = (doc, operation) => {
    this.updatingDocumentCb(doc);
    const url = `${basePath(doc)}/mark_${operation}`;

    $.ajax(
      Object.assign({}, ajaxDefaults(this.onFail(doc)), {
        url,
        method: "patch",
        success: updatedDocuments => {
          console.log("success!", operation, updatedDocuments); // eslint-disable-line
          // XXX truly shameful DOC_MODEL
          if (doc.forceReload) {
            window.location.reload();
          } else {
            this.handleUpdatedDocuments(updatedDocuments, doc);
          }
        },
      })
    );
  };

  markNotApplicable = doc => this.mark(doc, "not_applicable");
  markApplicable = doc => this.mark(doc, "applicable");
  markComplete = doc => this.mark(doc, "complete");
  markIncomplete = doc => this.mark(doc, "incomplete");

  deleteAttachment = (doc, attachmentId) => {
    this.updatingDocumentCb(doc);
    const url = `${basePath(doc)}/attachments/${attachmentId}`;

    $.ajax(
      Object.assign({}, ajaxDefaults(this.onFail(doc)), {
        url,
        method: "delete",
        success: updatedDocuments => {
          // XXX truly shameful DOC_MODEL
          if (doc.forceReload) {
            window.location.reload();
          } else if (updatedDocuments.requested.length > 0) {
            // document was updated, just attachment was deleted
            this.handleUpdatedDocuments(updatedDocuments);
          } else {
            // the document itself is deleted
            this.deletedDocumentCb(doc);
          }
        },
      })
    );
  };

  uploadAttachments = async (doc, files) => {
    this.updatingDocumentCb(doc);
    const url = `${basePath(doc)}/attachments`;

    const data = new FormData();
    files.forEach(file => data.append("files[]", file));

    await $.ajax(
      Object.assign({}, ajaxDefaults(this.onFail(doc)), {
        url,
        cache: false,
        contentType: false,
        processData: false,
        data,
        method: "post",
        success: updatedDocuments => {
          console.log("attachments uploaded successfully", updatedDocuments); // eslint-disable-line
          this.handleUpdatedDocuments(updatedDocuments, doc);
        },
      })
    );
  };

  uploadOtherDocuments = async (applicationId, category, files) => {
    this.updatingDocumentCb(OtherDocument);
    const url = `/applications/${applicationId}/documents`;

    const data = new FormData();
    files.forEach(file => data.append("files[]", file));
    data.append("category", category);

    await $.ajax(
      Object.assign({}, ajaxDefaults(this.onFail(OtherDocument)), {
        url,
        cache: false,
        contentType: false,
        processData: false,
        data,
        method: "post",
        success: updatedDocuments => {
          console.log("uploaded other doc!", updatedDocuments); // eslint-disable-line
          this.handleUpdatedDocuments(updatedDocuments);
          this.updatedDocumentCb(OtherDocument);
        },
      })
    );
  };

  setDateSigned = (doc, date) => {
    this.updatingDocumentCb(doc);

    const url = `${basePath(doc)}/date_signed`;

    $.ajax(
      Object.assign({}, ajaxDefaults(this.onFail(doc)), {
        url,
        data: JSON.stringify({ date }),
        method: "patch",
        success: updatedDocuments => {
          console.log("stored date signed", updatedDocuments); // eslint-disable-line
          this.handleUpdatedDocuments(updatedDocuments);
        },
      })
    );
  };

  createPartial = doc => {
    this.updatingDocumentCb(doc);

    const url = `${basePath(doc)}/partial`;

    $.ajax(
      Object.assign({}, ajaxDefaults(this.onFail(doc)), {
        url,
        method: "post",
        success: updatedDocuments => {
          console.log("partial in progress", updatedDocuments); // eslint-disable-line
          this.handleUpdatedDocuments(updatedDocuments);
        },
      })
    );
  };

  resetSignatureData = doc => {
    this.updatingDocumentCb(doc);

    const url = `${basePath(doc)}/signature_data`;

    $.ajax(
      Object.assign({}, ajaxDefaults(this.onFail(doc)), {
        url,
        method: "post",
        success: updatedDocuments => {
          console.log("reset signature data", updatedDocuments); // eslint-disable-line
          this.handleUpdatedDocuments(updatedDocuments);
        },
      })
    );
  };

  getDocument = (doc, fetchCompleteCb) => {
    const url = basePath(doc);

    $.ajax(
      Object.assign({}, ajaxDefaults(this.onFail(doc)), {
        url,
        method: "get",
        success: updatedDocuments => {
          // In case someone wants to know that the fetch itself is over,
          // but maybe doesn't care about the document specifically.
          if (fetchCompleteCb) {
            fetchCompleteCb();
          }

          this.handleUpdatedDocuments(updatedDocuments);
        },
      })
    );
  };
}

export const DocumentsClientContext = React.createContext();
export const OtherDocument = Object.freeze({ id: "other" });
