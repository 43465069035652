import { useQuery, useMutation } from "@apollo/client";
import { ContentTabs, Flex, Surface, ArrayDataTable } from "@heart/components";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { translationWithRoot } from "@components/T";

import CreateChildUploadedRecord from "@graphql/mutations/CreateChildUploadedRecord.graphql";
import DeleteUploadedRecordAttachment from "@graphql/mutations/DeleteUploadedRecordAttachment.graphql";
import SetUploadedRecordCompletedDate from "@graphql/mutations/SetUploadedRecordCompletedDate.graphql";
import ChildRequirementReconciliation from "@graphql/queries/ChildRequirementReconciliation.graphql";

import { sortDatesDesc } from "@lib/dates";

import DueDateCell from "../DueDateCell";
import ProgressCell from "../ProgressCell";
import { sortByDocument, sortByProgress } from "../sortRequirements";
import uploadRequirementFiles from "../uploadRequirementFiles";
import CaregiverActionsButtons from "./CaregiverActionsButtons";
import CaregiverDocumentCell from "./CaregiverDocumentCell";

const { t } = translationWithRoot("requirements.requirement_table");

const CaregiverChildRequirementsTableWrapper = ({ childId }) => {
  const refetchQueries = [
    { query: ChildRequirementReconciliation, variables: { childId } },
  ];
  const { data, loading } = useQuery(ChildRequirementReconciliation, {
    variables: { childId },
  });
  const [createChildUploadedRecord] = useMutation(CreateChildUploadedRecord, {
    refetchQueries,
  });
  const [deleteChildUploadedRecordAttachment] = useMutation(
    DeleteUploadedRecordAttachment,
    {
      refetchQueries,
    }
  );
  const [setUploadedRecordCompletedDate] = useMutation(
    SetUploadedRecordCompletedDate
  );

  const tableData = {
    all: [],
    overdue: [],
    next30Days: [],
    moreThan30Days: [],
    waived: [],
    completed: [],
  };
  const graphQLData = useMemo(
    () =>
      get(data, "childRequirementReconciliation.requirementFulfillments") || [],
    [data]
  );

  graphQLData.forEach(record => {
    const { isFulfilled, isOverridden, isActionCritical, isActionNeededSoon } =
      record;

    tableData.all.push(record);
    if (isOverridden) tableData.waived.push(record);
    else if (isFulfilled) tableData.completed.push(record);
    else if (isActionCritical) tableData.overdue.push(record);
    else if (isActionNeededSoon) tableData.next30Days.push(record);
    else tableData.moreThan30Days.push(record);
  });

  tableData.all = sortDatesDesc(tableData.all, "dateActionNeededBy");
  tableData.completed = sortDatesDesc(
    tableData.completed,
    "dateActionNeededBy"
  );
  tableData.waived = sortDatesDesc(tableData.waived, "dateActionNeededBy");
  tableData.overdue = sortDatesDesc(tableData.overdue, "dateActionNeededBy");
  tableData.next30Days = sortDatesDesc(
    tableData.next30Days,
    "dateActionNeededBy"
  );
  tableData.moreThan30Days = sortDatesDesc(
    tableData.moreThan30Days,
    "dateActionNeededBy"
  );

  return (
    <Flex>
      <CaregiverChildRequirementsTable
        loading={loading}
        /** We could pull this directly off the graphql data, but we then
         * lose the ability to assert that holderToken is required in all
         * our subsequent components as it will be undefined until data
         * loads.
         */
        holderToken={`Child-${childId}`}
        tableData={tableData}
        /** GraphQL Mutations */
        createChildUploadedRecord={createChildUploadedRecord}
        deleteChildUploadedRecordAttachment={
          deleteChildUploadedRecordAttachment
        }
        setUploadedRecordCompletedDate={setUploadedRecordCompletedDate}
      />
    </Flex>
  );
};
CaregiverChildRequirementsTableWrapper.propTypes = {
  childId: PropTypes.number.isRequired,
};

export const CaregiverChildRequirementsTable = ({
  loading,
  holderToken,
  tableData,
  /** GraphQL Mutations */
  createChildUploadedRecord,
  deleteChildUploadedRecordAttachment,
  setUploadedRecordCompletedDate,
}) => {
  const Table = ({ tableDataKey }) => (
    <Flex column>
      <ArrayDataTable
        loading={loading}
        onUpload={uploadRequirementFiles({
          holderToken,
          createUploadedRecord: createChildUploadedRecord,
        })}
        disableUploadForRow={fulfillment => fulfillment.isOverridden}
        disableUploadColumnIdCellIndexes={{
          columnId: "actions",
          indexes: [1],
        }}
        columns={useMemo(
          () => [
            {
              columnName: { name: t("cols.title") },
              id: "title",
              iconOnlyUpload: true,
              cell: "requirement.title",
              columnSort: "requirement.title",
              filter: {
                type: "search",
                filterBy: "requirement.title",
                label: t("filters.search"),
                topLevel: true,
              },
            },
            {
              columnName: { name: t("cols.progress") },
              id: "progress",
              iconOnlyUpload: true,
              cell: fulfillment => <ProgressCell fulfillment={fulfillment} />,
              columnSort: sortByProgress,
            },
            {
              columnName: {
                name: t("cols.due_date"),
                justify: "center",
              },
              id: "due_date",
              iconOnlyUpload: true,
              cell: fulfillment => <DueDateCell fulfillment={fulfillment} />,
              filter: {
                type: "custom_dates",
                filterBy: "dateActionNeededBy",
                label: t("filters.due_date"),
              },
              columnSort: "dateActionNeededBy",
            },
            {
              columnName: { name: t("cols.document") },
              id: "document",
              iconOnlyUpload: true,
              cell: fulfillment => (
                <CaregiverDocumentCell
                  holderToken={holderToken}
                  fulfillment={fulfillment}
                  /** GraphQL Mutations */
                  deleteUploadedRecordAttachment={
                    deleteChildUploadedRecordAttachment
                  }
                  setUploadedRecordCompletedDate={
                    setUploadedRecordCompletedDate
                  }
                />
              ),
              columnSort: sortByDocument,
            },
            {
              columnName: {
                name: t("cols.actions"),
                justify: "end",
              },
              id: "actions",
              cell: [
                fulfillment => (
                  <CaregiverActionsButtons
                    holderToken={holderToken}
                    fulfillment={fulfillment}
                    /** GraphQL Mutations */
                    createUploadedRecord={createChildUploadedRecord}
                  />
                ),
              ],
            },
          ],
          []
        )}
        data={tableData[tableDataKey]}
      />
    </Flex>
  );
  Table.propTypes = { tableDataKey: PropTypes.string.isRequired };

  return (
    <Surface hideTitle title="Requirements">
      <ContentTabs
        tabs={[
          {
            title: t("tabs.all"),
            contents: Table({ tableDataKey: "all" }),
            count: tableData.all.length,
          },
          {
            title: t("tabs.overdue"),
            contents: Table({ tableDataKey: "overdue" }),
            count: tableData.overdue.length,
          },
          {
            title: t("tabs.due_next_30_days"),
            contents: Table({ tableDataKey: "next30Days" }),
            count: tableData.next30Days.length,
          },
          {
            title: t("tabs.due_over_30_days"),
            contents: Table({ tableDataKey: "moreThan30Days" }),
            count: tableData.moreThan30Days.length,
          },
          {
            title: t("tabs.waived"),
            contents: Table({ tableDataKey: "waived" }),
            count: tableData.waived.length,
          },
          {
            title: t("tabs.completed"),
            contents: Table({ tableDataKey: "completed" }),
            count: tableData.completed.length,
          },
        ]}
      />
    </Surface>
  );
};
CaregiverChildRequirementsTable.propTypes = {
  loading: PropTypes.bool,
  holderToken: PropTypes.string.isRequired,
  tableData: PropTypes.shape({
    all: PropTypes.array.isRequired,
    overdue: PropTypes.array.isRequired,
    next30Days: PropTypes.array.isRequired,
    moreThan30Days: PropTypes.array.isRequired,
    waived: PropTypes.array.isRequired,
    completed: PropTypes.array.isRequired,
  }),
  /** GraphQL Mutations */
  createChildUploadedRecord: PropTypes.func.isRequired,
  deleteChildUploadedRecordAttachment: PropTypes.func.isRequired,
  setUploadedRecordCompletedDate: PropTypes.func.isRequired,
};

export default CaregiverChildRequirementsTableWrapper;
