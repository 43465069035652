import { identity } from "lodash";
import PropTypes from "prop-types";

import SigningEvent from "@graphql/queries/SigningEvent.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import { usePollingQuery } from "@lib/graphqlHelpers";

import BintiSignSignerSelector from "./BintiSignSignerSelector";

const isEventReady = data =>
  data?.signingEvent?.state !== "generating_locations";

/**
 * Polls and waits for a signing event to be ready.
 */
const BintiSignWaiter = ({
  contentWhileLoading,
  signingEventId,
  contentWhenReady = identity,
  redirectOnCloseUrl,
  signerButtonText,
}) => {
  const { loading, error, data } = usePollingQuery(SigningEvent, isEventReady, {
    variables: { id: signingEventId },
  });

  if (loading) {
    return contentWhileLoading();
  }

  if (error) {
    return I18n.t("assets.javascripts.common.trouble_with_document_prep");
  }

  const { signingEvent } = data;

  return contentWhenReady(
    <BintiSignSignerSelector
      {...{ signingEvent, redirectOnCloseUrl, signerButtonText }}
    />
  );
};

BintiSignWaiter.propTypes = {
  // these props come from the backend
  signingEventId: BintiPropTypes.ID.isRequired,
  redirectOnCloseUrl: PropTypes.string,
  // these props are configured by other wrapper components
  contentWhileLoading: PropTypes.func.isRequired,
  contentWhenReady: PropTypes.func,
  signerButtonText: PropTypes.object,
};

const { contentWhileLoading, contentWhenReady, ...passthroughProps } =
  BintiSignWaiter.propTypes;

// use this in your wrapper classes
BintiSignWaiter.passthroughProps = passthroughProps;

export default BintiSignWaiter;
