import { gql, useMutation, useQuery } from "@apollo/client";
import { Actions, LoadingOverlay, SurfaceForm, toast } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import I18n from "i18n-js";
import { useMemo, useState } from "react";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import PlacementPeriodForm from "./PlacementPeriodForm";

const { t } = translationWithRoot("placement_period");

const initialFormState = { startDate: new Date() };

const placementPeriodQuery = gql`
  query CreatePlacementPeriod($childId: ID!) {
    child(id: $childId) {
      id
      firstName
      lastName
    }
  }
`;

const createPlacementPeriodMutation = gql`
  mutation CreatePlacementPeriod($input: CreatePlacementPeriodInput!) {
    createPlacementPeriod(input: $input) {
      placementPeriod {
        id
      }
      errors
      redirectTo
    }
  }
`;

/**
 * Form for creating a new placement period
 */
const CreatePlacementPeriod = ({ childId }) => {
  const [errors, setErrors] = useState([]);
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const agencyPlacementId = queryParams.get("agency_placement_id");
  if (initialFormState.careProvider == null && agencyPlacementId != null) {
    initialFormState.placementType = "went_with_relatives";
    initialFormState.careProvider = `AgencyPlacement:${agencyPlacementId}`;
  }

  const { formState, setFormAttribute } = useBintiForm(initialFormState);
  const { data, loading: dataLoading } = useQuery(placementPeriodQuery, {
    variables: {
      childId,
    },
  });

  const [createPlacementPeriod, { loading: mutationLoading }] = useMutation(
    createPlacementPeriodMutation,
    {
      onCompleted: ({ createPlacementPeriod: response }) => {
        if (response.errors.length === 0) {
          toast.onNextPageLoad.success(
            I18n.t(
              "views.admin.placements.placement_period.created_successfully"
            )
          );
          window.location = response.redirectTo;
        }

        setErrors(response.errors);
      },
    }
  );
  const referrerUrl = document.referrer ? new URL(document.referrer) : null;

  const referrer = referrerUrl
    ? `${referrerUrl.pathname}${referrerUrl.search}`
    : "";

  return (
    <LoadingOverlay active={dataLoading || mutationLoading}>
      <SurfaceForm
        title={`${t("placement_for")} ${data?.child.firstName} ${
          data?.child.lastName
        }`}
        actions={<Actions />}
        onSubmit={preventDefault(() =>
          createPlacementPeriod({
            variables: {
              input: {
                ...formState,
                childId,
                referrer,
              },
            },
          })
        )}
      >
        <PlacementPeriodForm
          childId={childId}
          formState={formState}
          setFormAttribute={setFormAttribute}
          errors={errors}
        />
      </SurfaceForm>
    </LoadingOverlay>
  );
};

CreatePlacementPeriod.propTypes = {
  childId: BintiPropTypes.ID,
};

export default CreatePlacementPeriod;
