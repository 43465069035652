import {
  Surface,
  GraphQLDataTable,
  Pill,
  Flex,
  Icons,
} from "@heart/components";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import Workflows from "@graphql/queries/integrations/Workflows.graphql";

import WorkflowEditModal from "./WorkflowEditModal";

const { t } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});

/**
 * A Table to display integration workflows.
 */
const WorkflowsTable = () => {
  const [showEditModal, setShowEditModal] = useState(null);
  const [workflowIdToEdit, setWorkflowIdToEdit] = useState(null);

  return (
    <Surface hideTitle title="Integration Workflows">
      <GraphQLDataTable
        query={Workflows}
        queryTitle="workflows"
        filters={[]}
        defaultSort={{ sortBy: "slug", sortDirection: "ASC" }}
        columns={[
          {
            columnName: { name: "Workflow Service Class" },
            id: "serviceClass",
            sortBy: "serviceClass",
            cell: "serviceClass",
          },
          {
            columnName: { name: "Status" },
            id: "status",
            sortBy: "status",
            cell: ({ status }) => (
              <Pill
                variant={status === "enabled" ? "success" : "warning"}
                text={t(`labels.${status}`)}
              />
            ),
          },
          {
            columnName: { name: "Subscriber Count" },
            id: "subscriberCount",
            sortBy: "subscriberCount",
            cell: "subscriberCount",
          },
          {
            columnName: { name: "Actions" },
            id: "actions",
            cell: ({ id }) => (
              <Flex row>
                <Icons.Pencil
                  description="Edit"
                  onClick={() => {
                    setWorkflowIdToEdit(id);
                    setShowEditModal(true);
                  }}
                />
              </Flex>
            ),
          },
        ]}
      />
      <If condition={showEditModal}>
        <WorkflowEditModal
          workflowIdToEdit={workflowIdToEdit}
          resetStateCallback={() => {
            setWorkflowIdToEdit(null);
            setShowEditModal(false);
          }}
        />
      </If>
    </Surface>
  );
};
WorkflowsTable.propTypes = {};

export default WorkflowsTable;
