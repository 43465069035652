import { InputText } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import Address from "@components/applicant_sign_up/Address";

const EditOrganization = ({
  agencyPlacementProviderProfile,
  address,
  // licenseManagers,
  // licenseInformation,
  // applications,
  usStateOptions,
}) => {
  const apppNamePrefix =
    "application[applicant_agency_profile_attributes]" +
    "[agency_placement_attributes][agency_placement_provider_profile_attributes]";
  return (
    <Fragment>
      <InputText
        label="Provider name"
        name={`${apppNamePrefix}[provider_name]`}
        value={agencyPlacementProviderProfile.providerName}
      />
      <InputText
        label="Provider external identifier"
        name={`${apppNamePrefix}[provider_external_identifier]`}
        value={agencyPlacementProviderProfile.providerExternalId}
      />
      <InputText
        label="Provider FEIN"
        name={`${apppNamePrefix}[provider_fein]`}
        value={agencyPlacementProviderProfile.providerFein}
      />
      <Address
        title={I18n.t("javascript.components.address.address")}
        address={{
          addressStreet: address.street,
          addressCity: address.city,
          addressState: address.state,
          addressZipcode: address.zipcode,
        }}
        usStateOptions={usStateOptions}
      />
    </Fragment>
  );
};

EditOrganization.propTypes = {
  agencyPlacementProviderProfile: PropTypes.shape({
    providerName: PropTypes.string,
    providerExternalId: PropTypes.string,
    providerFein: PropTypes.string,
  }).isRequired,
  address: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    county: PropTypes.string,
    mailing_address_same_as_physical: PropTypes.bool.isRequired,
    mailing_street: PropTypes.string,
    mailing_city: PropTypes.string,
    mailing_state: PropTypes.string,
    mailing_zipcode: PropTypes.string,
  }).isRequired,
  licenseManagers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      role: PropTypes.string,
      email: PropTypes.string,
      phone_number: PropTypes.string,
    }).isRequired
  ),
  licenseInformation: PropTypes.shape({
    capacity: PropTypes.integer,
    approved_for: PropTypes.string,
    specialization: PropTypes.string,
  }).isRequired,
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      application_template: PropTypes.string,
      documents: PropTypes.string,
    }).isRequired
  ),
  /** US States options for user to choose */
  usStateOptions: PropTypes.array,
};

export default EditOrganization;
