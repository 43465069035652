import { Flex, InputCheckbox, Link } from "@heart/components";
import classNames from "classnames";
import { isEmpty, isNil, without } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";

import { translationWithRoot } from "@components/T";

import {
  inputCommonPropTypes,
  InputGroupLayout,
  inputGroupCommonPropTypes,
} from "./common";
import styles from "./common/RadioAndCheckboxCommonStyles.module.scss";

const { t } = translationWithRoot(
  "heart.components.inputs.input_checkbox_group"
);

const optionValue = val => val.value || val;
const optionLabel = val => val.label || val;
const rawValueArray = val => Array.from(val ? val.map(optionValue) : []);
const determineAllValues = values =>
  values.reduce(
    (selected, val) => [
      ...selected,
      /** For nested options, the top level label isn't a selectable option - this
       * logic omits it from the list of all values
       */
      ...(val.options ? determineAllValues(val.options) : [optionValue(val)]),
    ],
    []
  );

/**
 * A collection of checkbox inputs handled in aggregate.  When included in a `<form>` POST,
 * these are represented as a list of values in the HTTP params.
 *
 * **Note**: Using the `required` prop will *not* prevent form submissions.
 *
 * Relevant Cypress commands:
 *   * `cy.findRadioOrCheckboxInput(groupName, label).check()`
 */
const InputCheckboxGroup = props => {
  const { onChange, onBlur, value, values, selectAll = {} } = props;
  const selectAllClassName = classNames({
    [styles.indentAllInputs]: selectAll.type === "checkbox",
  });
  const selectAllLabel = selectAll.label || t("select_all");

  const [allSelected, setAllSelected] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState(rawValueArray(value));

  const allValues = determineAllValues(values);
  const allValuesChecked = checkboxValues.length === allValues.length;
  useEffect(() => {
    if (allValuesChecked) setAllSelected(true);
    else setAllSelected(false);
  }, [allValuesChecked]);
  useEffect(() => setCheckboxValues(rawValueArray(value)), [value]);
  const handleChange = e => {
    const newValues = e.target.checked
      ? [...checkboxValues, e.target.value]
      : without(checkboxValues, e.target.value);
    setCheckboxValues(newValues);
    if (onChange) onChange(newValues);
  };

  const generateOption = ({ val, disabled, name, nested }) => (
    <li key={optionValue(val)}>
      <label
        className={classNames(styles.radioCheckContainer, selectAllClassName, {
          [styles.indent]: nested,
        })}
      >
        <input
          type="checkbox"
          onChange={handleChange}
          onBlur={onBlur}
          checked={checkboxValues.includes(optionValue(val))}
          name={name ? `${name}[]` : undefined}
          value={optionValue(val)}
          disabled={disabled || val.disabled}
        />
        {optionLabel(val)}
      </label>
    </li>
  );

  const SelectAllControl = () => {
    if (selectAll.type === "checkbox") {
      return (
        <InputCheckbox
          label={selectAllLabel}
          value={allSelected}
          onChange={checked => {
            setCheckboxValues(checked ? allValues : []);
            setAllSelected(checked);
            if (onChange) onChange(checked ? allValues : []);
          }}
        />
      );
    }
    if (selectAll.type === "links") {
      return (
        <Flex row>
          <Link
            onClick={() => {
              setCheckboxValues(allValues);
              if (onChange) onChange(allValues);
            }}
            disabled={allValuesChecked}
          >
            {selectAllLabel}
          </Link>
          <Link
            onClick={() => {
              setCheckboxValues([]);
              if (onChange) onChange([]);
            }}
            disabled={isEmpty(checkboxValues)}
          >
            {t("clear")}
          </Link>
        </Flex>
      );
    }
    return null;
  };

  if (isEmpty(values) && selectAll.type === "checkbox")
    return <InputCheckbox label={selectAllLabel} disabled />;

  return (
    <InputGroupLayout
      spacing="compact"
      {...props}
      inputsComponent={({ disabled, name }) => (
        <Fragment>
          {/** required, data-testid, and ID are applicable for a single input;
           * since this component is a collection of inputs, we discard them */}
          <SelectAllControl />
          {values.map((val, ind) => (
            <Fragment key={`${optionValue(val)}-${ind}`}>
              {isNil(val.options) ? (
                generateOption({ val, disabled, name })
              ) : (
                <div className={classNames(selectAllClassName)}>
                  {optionLabel(val)}
                </div>
              )}
              <If condition={!isNil(val.options)}>
                {val.options.map(nestedVal =>
                  generateOption({
                    val: nestedVal,
                    disabled,
                    name,
                    nested: true,
                  })
                )}
              </If>
            </Fragment>
          ))}
        </Fragment>
      )}
    />
  );
};

InputCheckboxGroup.propTypes = {
  ...inputCommonPropTypes,
  ...inputGroupCommonPropTypes,
  /** The initial value (uncontrolled) of this input, or the current value (controlled).
   *
   * TODO see if string and shape types work
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
      ),
    ]),
  ]),
  /** Available options to generate checkboxes */
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
      })
    ),
  ]).isRequired,
  /** `onChange` is invoked with an array of the values selected (just the value) */
  onChange: PropTypes.func,
  /** This HTML `name` is the prefix for `[]` so the values are submitted as a list
   * in HTML form POST submissions. */
  name: PropTypes.string,
  /** When type = "links", adds Select All/Clear options as links to the top of the checkbox group.
   *
   * When type = "checkbox", adds a checkbox with the provided label to the top of the checkbox group.
   *
   * Note: this should NOT be used with a disabled checkbox option
   */
  selectAll: PropTypes.shape({
    type: PropTypes.oneOf(["links", "checkbox"]),
    label: PropTypes.string,
  }),
};
export default InputCheckboxGroup;
