import { Button, Icons } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

import AddAgenciesForm from "./AddAgenciesForm";

const { t } = translationWithRoot("placement_search.agencies_assigned");

const AddAgencies = ({ agencyId, assignedSearches, onSubmit }) => {
  const [formHidden, setFormHidden] = useState(true);

  return (
    <Fragment>
      <If condition={formHidden}>
        {/* to make button not span whole length */}
        <div>
          <Button variant="secondary" onClick={() => setFormHidden(false)}>
            <Icons.Plus />
            {t("add_agency")}
          </Button>
        </div>
      </If>
      <If condition={!formHidden}>
        <AddAgenciesForm
          agencyId={agencyId}
          assignedSearches={assignedSearches}
          onSubmit={newAgencies => {
            onSubmit(newAgencies);
            setFormHidden(true);
          }}
          onCancel={() => setFormHidden(true)}
        />
      </If>
    </Fragment>
  );
};

AddAgencies.propTypes = {
  agencyId: BintiPropTypes.ID,
  assignedSearches: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default AddAgencies;
