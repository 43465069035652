import classnames from "classnames";
import PropTypes from "prop-types";

import T from "@components/T";
import { Table, Tbody, Th, Thead, Tr } from "@components/reusable_ui/Table";

import { typeEq } from "@lib/graphqlHelpers";

import styles from "../requirements/Requirement.module.scss";
import ApplicationFormRequirementRow from "./ApplicationFormTypeRequirementRow";
import ApplicationUploadTypeRequirementRow from "./ApplicationUploadTypeRequirementRow";

const ApplicationRequirementTable = ({
  application,
  id,
  requirementFulfillments,
  title,
}) => (
  <div
    className={classnames(
      styles.breakoutTable,
      "test-application-requirement-table"
    )}
  >
    <div
      className={styles.tableTitle}
      data-testid="requirements-table-title"
      id={id}
    >
      {title}
    </div>
    <Table>
      <Thead>
        <Tr>
          <Th>
            <T t="requirements.requirement_table.columns.title" />
          </Th>
          <Th>Status</Th>
          <Th>Document</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {requirementFulfillments.map(fulfillment => {
          if (typeEq("ApplicationFormRequirement", fulfillment.requirement)) {
            return (
              <ApplicationFormRequirementRow
                key={fulfillment.id}
                {...{ fulfillment, application }}
              />
            );
          }

          if (typeEq("UploadTypeRequirement", fulfillment.requirement)) {
            return (
              <ApplicationUploadTypeRequirementRow
                key={fulfillment.id}
                {...{ fulfillment, application }}
              />
            );
          }

          return false;
        })}
      </Tbody>
    </Table>
  </div>
);

ApplicationRequirementTable.propTypes = {
  application: PropTypes.object.isRequired,
  id: PropTypes.string,
  requirementFulfillments: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ApplicationRequirementTable;
