import ResolutionOnly from "@heart/components/layout/ResolutionOnly";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { Flex, Button, Surface } from "@components/HeartRails";
import { translationWithRoot } from "@components/T";

import TableFilter from "../common/filter/TableFilter";

const { t } = translationWithRoot("heart.components.filter");

/** The filters sidebar for our SearchResultsTable
 *
 * Note: This sidebar does not use our <Sidebar/> component because it's not
 * really a navigation component. Please do not replicate this pattern without
 * clearing your use case with #guild-frontend!
 */
const SearchResultsTableSidebar = ({
  filters,
  applyFilters,
  appliedFilterValueFor,
  clearFilters,
}) => {
  const [filterValues, setFilterValues] = useState(
    filters.reduce((vals, { field, type }) => {
      if (type === "custom_dates")
        return {
          ...vals,
          [`${field}Gteq`]: appliedFilterValueFor(`${field}Gteq`),
          [`${field}Lteq`]: appliedFilterValueFor(`${field}Lteq`),
        };
      return {
        ...vals,
        [field]: appliedFilterValueFor(field),
      };
    }, {})
  );
  const setFilterValuesAttr = vals => {
    setFilterValues({ ...filterValues, ...vals });
  };

  const contents = (
    <Flex gap="300" column>
      <Flex>
        <Button onClick={() => applyFilters(filterValues)}>
          {t("apply_filters")}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setFilterValues({});
            clearFilters();
          }}
        >
          {t("clear_filters")}
        </Button>
      </Flex>
      {filters.map(filter => (
        <TableFilter
          key={filter.field}
          filter={filter}
          filterValues={filterValues}
          setFilterValuesAttr={setFilterValuesAttr}
        />
      ))}
    </Flex>
  );

  return (
    <Fragment>
      <ResolutionOnly small over>
        <Surface title="Filters" hideTitle>
          {contents}
        </Surface>
      </ResolutionOnly>
      <ResolutionOnly small under>
        {/* Our sidebar already includes a surface when on small screens */}
        {contents}
      </ResolutionOnly>
    </Fragment>
  );
};
SearchResultsTableSidebar.propTypes = {
  /** For date based filters, the query variable `${field}Gteq` and `${field}Lteq`
   * will be used */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      customDatesLabels: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
      }),
      type: PropTypes.oneOf([
        "autocomplete_select",
        "custom_dates",
        "date",
        "dropdown",
        "search",
        "select",
      ]).isRequired,
      field: PropTypes.string.isRequired,
      /** used for select and autocomplete_select filters to allow
       * multiple selections */
      isMulti: PropTypes.bool,
      /** For type `autocomplete_select`, a gql query, which should take
       * in an `inputQuery` argument to determine options for the dropdown
       */
      query: PropTypes.object,
      /** For type `autocomplete_select`, a function that will be called with
       * the query results, which should return the options in an array of
       * `{label, value}` objects
       */
      valuesFromResponse: PropTypes.func,
      /** the query will not be executed unless all filters with
       * required === true are set */
      required: PropTypes.bool,
      /** function to evaluate if the variable is considered set. if
       * unspecified, any non-undefined value is considered set.
       */
      isSet: PropTypes.func,
      /** if true, shows a link to "select all" options for a "select"
       * component where isMulti is true.
       */
      showSelectAll: PropTypes.bool,
    })
  ),
  applyFilters: PropTypes.func.isRequired,
  appliedFilterValueFor: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};

export default SearchResultsTableSidebar;
