import { Flex } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import Attachment from "../Attachment";
import styles from "../DocumentCell.module.scss";
import CaregiverFacingOverride from "./CaregiverFacingOverride";

const { t } = translationWithRoot("caregiver_portal");

const CaregiverDocumentCell = ({
  holderToken,
  fulfillment,
  /** GraphQL Mutations */
  deleteUploadedRecordAttachment,
  setUploadedRecordCompletedDate,
}) => {
  const {
    allAttachmentsForRecord,
    overrideRecords,
    requirement,
    isFulfilled,
    isOverridden,
  } = fulfillment;
  const fulfilledByCaseworker =
    isFulfilled && allAttachmentsForRecord.length === 0 && !isOverridden;

  return (
    <Flex column className={styles.documentCell}>
      <If condition={fulfilledByCaseworker}>
        {t("requirements.fulfilled_by_caseworker")}
      </If>

      {allAttachmentsForRecord.map((attachment, index) => (
        <Attachment
          key={index}
          lastItem={index >= allAttachmentsForRecord.length - 1}
          holderToken={holderToken}
          attachment={attachment}
          requirement={requirement}
          /** GraphQL Mutations */
          deleteUploadedRecordAttachment={deleteUploadedRecordAttachment}
          setUploadedRecordCompletedDate={setUploadedRecordCompletedDate}
        />
      ))}

      {/* Display all override records */}
      {overrideRecords.map((override, index) => (
        <CaregiverFacingOverride
          key={index}
          holderToken={holderToken}
          fulfillment={fulfillment}
          lastItem={index >= overrideRecords.length - 1}
          overrideRecord={override}
        />
      ))}
    </Flex>
  );
};

CaregiverDocumentCell.propTypes = {
  holderToken: PropTypes.string.isRequired,
  fulfillment: PropTypes.shape({
    allAttachmentsForRecord: PropTypes.arrayOf(
      PropTypes.shape({
        downloadUrl: PropTypes.string,
        name: PropTypes.string.isRequired,
        recordCompletedDate: PropTypes.string,
        id: PropTypes.string.isRequired,
        recordId: PropTypes.number.isRequired,
      })
    ),
    overrideRecords: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        creatorName: PropTypes.string.isRequired,
        details: PropTypes.string.isRequired,
        grantDate: PropTypes.string.isRequired,
        reason: PropTypes.string.isRequired,
      })
    ),
    requirement: PropTypes.shape({
      id: PropTypes.number.isRequired,
      form: PropTypes.shape({ slug: PropTypes.string }),
      title: PropTypes.string.isRequired,
      uploadType: PropTypes.shape({ slug: PropTypes.string }),
    }).isRequired,
    isFulfilled: PropTypes.bool.isRequired,
    isOverridden: PropTypes.bool.isRequired,
  }),
  /** GraphQL Mutations */
  deleteUploadedRecordAttachment: PropTypes.func.isRequired,
  setUploadedRecordCompletedDate: PropTypes.func.isRequired,
};

export default CaregiverDocumentCell;
