import { useQuery } from "@apollo/client";
import { DetailsTable } from "@heart/components";
import I18n from "i18n-js";
import React from "react";

import Spinner from "@components/Spinner";

import AppealQuery from "@graphql/queries/Appeal.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const getHearingDisplayDetails = hearing => {
  const hearingDisplayDetails = [
    {
      label: I18n.t("javascript.components.appeals.pre_hearing_date"),
      value: hearing.preHearingDate,
    },
    {
      label: I18n.t("javascript.components.appeals.pre_hearing_notes"),
      value: hearing.preHearingNotes,
    },
  ];

  hearing.hearingSessions?.forEach((session, index) => {
    hearingDisplayDetails.push({
      label: I18n.t("javascript.components.appeals.hearing_date", {
        session: index + 1,
      }),
      value: session.hearingSessionDate,
    });
    hearingDisplayDetails.push({
      label: I18n.t("javascript.components.appeals.hearing_notes", {
        session: index + 1,
      }),
      value: session.hearingNotes,
    });
  });

  return hearingDisplayDetails;
};

const HearingDetails = ({ appealId }) => {
  const { data, loading } = useQuery(AppealQuery, {
    variables: { appealId },
  });

  if (loading) {
    return <Spinner />;
  }

  return <DetailsTable details={getHearingDisplayDetails(data.appeal)} />;
};

HearingDetails.propTypes = {
  appealId: BintiPropTypes.ID,
};

export default HearingDetails;
