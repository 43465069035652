import PropTypes from "prop-types";

import ProgressArc from "../ProgressArc";
import styles from "./Requirement.module.scss";

const RequirementStatus = ({ isFulfilled, progressPercentage }) => (
  <div className={styles.statusCell}>
    <ProgressArc
      progress={progressPercentage}
      progressIncomplete={!isFulfilled}
    />
  </div>
);

RequirementStatus.propTypes = {
  progressPercentage: PropTypes.number,
  isFulfilled: PropTypes.bool,
};

export default RequirementStatus;
