import { InputCheckbox, InputFilterable } from "@heart/components";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useState } from "react";

import T from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";
import {
  selectedDetails,
  overridableOptions,
  NOT_APPLICABLE,
} from "@lib/overridableHelpers";

import styles from "./EndUserUploadTypeTable.module.scss";

const SELECT_PLACEHOLDER = I18n.t(
  "javascript.components.end_user.end_user_upload_type_override.select_a_reason"
);

/**
 * Dropdown selector for end-user selectable overrides. Shown to applicants and
 * other adults in the applicant-side on the supporting docs upload page.
 *
 * This input only shows "not applicable" reasons to end users because that's
 * all they're allowed to enter.
 *   Application Template Details > Assign Template > Application Forms >
 *     Choose a Supporting Document that allows "not applicable" like "auto registration"
 *     See checkbox below upload widget
 */
const EndUserUploadTypeOverride = ({
  applicationId,
  createUploadTypeOverride,
  deleteOverrideRecord,
  record,
  requirement,
  providerRole,
  readOnly,
}) => {
  const [showDetailsOptions, setShowDetailsOptions] = useState(!!record);

  const onChange = ({ value }) => {
    const {
      uploadType: { slug },
    } = requirement;

    if (value !== SELECT_PLACEHOLDER) {
      createUploadTypeOverride({
        variables: {
          holderToken: `Application-${applicationId}`,
          uploadTypeSlug: slug,
          reason: "notApplicable",
          optionKey: value,
          grantDate: DateTime.local().toISO(),
          userAgencyProfileId: requirement.userAgencyProfile.id,
          replaceOverrideRecordId: record && record.id,
        },
      });
    }
  };

  const onOverriddenChange = checked => {
    if (record && !checked) {
      deleteOverrideRecord({
        variables: {
          holderToken: `Application-${applicationId}`,
          overrideRecordId: record.id,
        },
      }).then(() => {
        setShowDetailsOptions(false);
      });
    } else {
      setShowDetailsOptions(true);
    }
  };

  const value =
    record &&
    selectedDetails({
      overrideRecord: record,
      requirement,
      providerRole,
      reason: NOT_APPLICABLE,
    });
  const options = overridableOptions({
    requirement,
    providerRole,
    reason: NOT_APPLICABLE, // only not applicable is valid for end users
  });

  return (
    <div className={styles.overrideRecords}>
      <InputCheckbox
        label={<T t="end_user.end_user_upload_type_override.dont_have_this" />}
        value={showDetailsOptions}
        onChange={onOverriddenChange}
        disabled={readOnly}
      />
      {showDetailsOptions && !readOnly && (
        <InputFilterable
          label={
            <T t="end_user.end_user_upload_type_override.please_let_us_know" />
          }
          options={options}
          value={value}
          onChange={onChange}
          placeholder={SELECT_PLACEHOLDER}
        />
      )}
    </div>
  );
};

EndUserUploadTypeOverride.propTypes = {
  applicationId: BintiPropTypes.ID.isRequired,
  record: PropTypes.object,
  requirement: PropTypes.object.isRequired,
  providerRole: PropTypes.string.isRequired,
  createUploadTypeOverride: PropTypes.func.isRequired,
  deleteOverrideRecord: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default EndUserUploadTypeOverride;
