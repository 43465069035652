import { Button, Flex, Surface } from "@heart/components";
import { startCase } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import useCollapse from "react-collapsed";

import PermissionsPropTypes from "./PermissionsPropTypes";
import ResourceAction from "./ResourceAction";
import { viewOnlyUserVar } from "./permissionsReactiveVars";

const ResourceActionGroup = ({ resource, actions, queryVariables }) => {
  const [viewOnlyMode, setViewOnlyMode] = useState(true);
  const [collapseButtonText, setCollapseButtonText] = useState(
    I18n.t("javascript.components.permissions.hide")
  );
  const toggleEditButtonText = viewOnlyMode
    ? I18n.t("javascript.components.permissions.edit_button")
    : I18n.t("javascript.components.permissions.stop_editing_button");

  const toggleEdit = () => {
    setViewOnlyMode(!viewOnlyMode);
  };
  const { getCollapseProps, getToggleProps } = useCollapse({
    defaultExpanded: true,
    onCollapseStart: () => {
      setCollapseButtonText(I18n.t("javascript.components.permissions.show"));
    },
    onExpandStart: () => {
      setCollapseButtonText(I18n.t("javascript.components.permissions.hide"));
    },
  });

  return (
    <Surface
      secondary={
        <React.Fragment>
          <If condition={!viewOnlyUserVar()}>
            <Button variant="secondary" onClick={toggleEdit}>
              {toggleEditButtonText}
            </Button>
          </If>
          <Button variant="tertiary" {...getToggleProps()}>
            {collapseButtonText}
          </Button>
        </React.Fragment>
      }
      title={I18n.t("javascript.components.permissions.resource_group_title", {
        resource: startCase(resource),
      })}
    >
      <Flex column gap="300" {...getCollapseProps()}>
        {actions.map(action => (
          <ResourceAction
            key={`${resource}-${action}`}
            viewOnlyMode={viewOnlyMode}
            queryVariables={{
              ...queryVariables,
              resource,
              action,
            }}
          />
        ))}
      </Flex>
    </Surface>
  );
};

ResourceActionGroup.propTypes = {
  resource: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.string),
  queryVariables: PermissionsPropTypes.queryVariables,
};

export default ResourceActionGroup;
