import { isArray } from "lodash";
import PropTypes from "prop-types";
import { RRule } from "rrule";

const RRuleToText = ({ rrule }) => {
  try {
    const parsedRRule = RRule.fromString(rrule);
    const { bymonthday } = parsedRRule.options;

    /**
     * there's a bug in the rrule node module we're using that causes bymonthday
     * to be stored incorrectly. To account for this, we are using bysetpos=-1
     * with a bymonthday value of all the potential last dates of the month
     * (28, 29, 30, and 31) to grab the last member of the set of days in the
     * selected month
     *
     * Stack overflow post with bysetpos functionality explanation:
     * https://stackoverflow.com/questions/24943832/rrule-explanaiton-using-byday-and-bysetpos/24950973#24950973
     *
     * Without this translation the displayed schedule text is misleading -- e.g.
     * it will show up as "every May on the 28th, 29th, 30th, and 31st" instead
     * of "every May on the last day of the month
     */
    if (isArray(bymonthday) && bymonthday.length > 1) {
      parsedRRule.options.bymonthday = -1;
    }

    const text = parsedRRule.toText();

    return <span>{text}</span>;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("failed to parse rrule", e);
    return "";
  }
};

RRuleToText.propTypes = {
  rrule: PropTypes.string.isRequired,
};

export default RRuleToText;
