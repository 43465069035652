import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "../../BintiSign.module.scss";

const BLOCK_BORDER_SIZE = 2;

/**
 * Renders a signature block in the right location on top of the PDF page,
 * with some content in the block (usually either an information message or
 * a signature image) and possibly some buttons that may be provided.
 */
const ScaledSignatureBlock = ({
  scale,
  location,
  blockContent,
  buttonContent,
  containerClassName,
  contentClassName,
}) => {
  // Omg wai https://github.com/prettier/prettier/issues/187
  const scaledX = location.x * scale;
  const scaledY = location.y * scale;

  return (
    <div
      className={classnames(styles.signatureBlock, containerClassName)}
      style={{
        left: scaledX - BLOCK_BORDER_SIZE,
        top: scaledY - BLOCK_BORDER_SIZE,
      }}
    >
      <div
        className={classnames(styles.signatureBlockSignature, contentClassName)}
        style={{
          width: location.width * scale,
          height: location.height * scale,
        }}
      >
        {blockContent}
      </div>
      {buttonContent}
    </div>
  );
};

ScaledSignatureBlock.propTypes = {
  containerClassName: PropTypes.string,
  contentClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  location: PropTypes.object.isRequired,
  scale: PropTypes.number.isRequired,
  blockContent: PropTypes.node.isRequired,
  buttonContent: PropTypes.node,
};

export default ScaledSignatureBlock;
