import I18n from "i18n-js";
import { Fragment } from "react";

/**
 * Component to indicate visually that a field is required for AFCARS reporting.
 * AFCARS stands for Adoption and Foster Care Analysis and Reporting System and is an
 * important compliance requirement for child welfare agencies.
 *
 * https://www.acf.hhs.gov/cb/data-research/adoption-fostercare
 */
const AfcarsRequired = () => (
  <Fragment>
    <span
      style={{ color: "#FF9900" }}
      aria-hidden="true"
      title={I18n.t("admin.common.required_for_afcars")}
    >
      Δ
    </span>
    <span className="is-hidden-accessible">
      {I18n.t("admin.common.required_for_afcars")}
    </span>
  </Fragment>
);

export default AfcarsRequired;
