import URI from "urijs";

$(() => {
  $(document).on("change", 'select[name="end_user[locale]"]', e => {
    const current = new URI(window.location);
    const $target = $(e.target);
    const locale = $("html").attr("lang");

    if ($target.val() !== locale) {
      window.location = current.setSearch("locale", $target.val());
    }
  });

  $(document).on("click", ".js-locale-dropdown a", e => {
    const $target = $(e.target);
    const locale = $target.data("locale");

    $.ajax({
      type: "POST",
      url: `/users/locale/${locale}`,
      success: () => {
        window.location.reload();
      },
    });
  });
});
