import { useMutation, useQuery } from "@apollo/client";
import {
  SurfaceForm,
  InputDate,
  InputTextarea,
  Actions,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import PropTypes from "prop-types";
import React from "react";

import Spinner from "@components/Spinner";

import UpdateHearingSession from "@graphql/mutations/UpdateHearingSession";
import HearingSessionQuery from "@graphql/queries/HearingSession.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const HearingSession = ({ id, sessionNumber, onSubmit, onCancel }) => {
  const { data, loading } = useQuery(HearingSessionQuery, {
    variables: { id },
  });

  const [updateHearingSesssion] = useMutation(UpdateHearingSession, {
    refetchQueries: [{ query: HearingSessionQuery, variables: { id } }],
    onCompleted: onSubmit,
  });

  const { formState, setFormAttribute } = useBintiForm(data?.hearingSession);

  if (loading) {
    return <Spinner />;
  }

  return (
    <SurfaceForm
      title={I18n.t("javascript.components.appeals.hearing_session_number", {
        session: sessionNumber,
      })}
      actions={<Actions cancelAction={onCancel} />}
      onSubmit={preventDefault(() =>
        updateHearingSesssion({
          variables: {
            hearingSessionId: formState.id,
            hearingSessionDate: formState.hearingSessionDate,
            hearingNotes: formState.hearingNotes,
          },
        })
      )}
    >
      <InputDate
        label={I18n.t("javascript.components.appeals.hearing_session_date")}
        name="hearingSessionDate"
        value={formState.hearingSessionDate}
        onChange={setFormAttribute("hearingSessionDate")}
        required
      />
      <InputTextarea
        label={I18n.t("javascript.components.appeals.hearing_session_notes")}
        name="hearingNotes"
        value={formState.hearingNotes}
        onChange={setFormAttribute("hearingNotes")}
      />
    </SurfaceForm>
  );
};

HearingSession.propTypes = {
  id: BintiPropTypes.ID,
  sessionNumber: PropTypes.number,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default HearingSession;
