import { useMutation } from "@apollo/client";
import { Button } from "@heart/components";
import { useState } from "react";

import SendTestDripEmailMutation from "@graphql/mutations/SendTestDripEmail.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const SendTestDripEmail = ({ dripEmailId }) => {
  const [wasSent, setWasSent] = useState(false);
  const [sendTestDripEmail] = useMutation(SendTestDripEmailMutation);

  const onClick = preventDefault(() => {
    sendTestDripEmail({ variables: { dripEmailId } }).then(() =>
      setWasSent(true)
    );
  });

  return (
    <Button variant="secondary" disabled={wasSent} {...{ onClick }}>
      {wasSent ? "Test Email Sent!" : "Send Test Email"}
    </Button>
  );
};

SendTestDripEmail.propTypes = {
  dripEmailId: BintiPropTypes.ID.isRequired,
};

export default SendTestDripEmail;
