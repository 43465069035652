import { Breadcrumbs, ContentTabs, Flex } from "@heart/components";

import ChildrenInCare from "./children-in-care.svg";
import Education from "./education.svg";
import ImportantDates from "./important-dates.svg";
import Information from "./information.svg";
import ParentCard from "./parent-card.svg";
import Providers from "./providers.svg";

const Sidebar = () => (
  <Flex column style={{ width: "425px" }}>
    <img src={ParentCard} alt="ParentCard" />
    <img src={ImportantDates} alt="ImportantDates" />
  </Flex>
);

const Summary = () => (
  <Flex column>
    <img src={Information} alt="Information" />
    <img src={ChildrenInCare} alt="Children in care" />
    <img src={Education} alt="Education" />
    <img src={Providers} alt="Providers" />
  </Flex>
);

const ParentFile = () => (
  <Flex column style={{ margin: "20px" }}>
    <Breadcrumbs
      pages={[
        { label: "Home", href: "/homepage" },
        { label: "Parents", href: "/admin/applications" },
        { label: "Emily Lopez", href: "" },
      ]}
    />
    <Flex row>
      <Sidebar></Sidebar>
      <Flex column>
        <ContentTabs
          tabs={[
            { title: "Summary", contents: <Summary /> },
            {
              title: "Case Management",
              contents: "Case Management",
            },
            {
              title: "Requirements",
              contents: "Requirements",
            },
            {
              title: "Placements",
              contents: "Placements",
            },
            {
              title: "Relationships",
              contents: "Relationships",
            },
            {
              title: "Case Notes",
              contents: "Case Notes",
            },
          ]}
        ></ContentTabs>
      </Flex>
    </Flex>
  </Flex>
);

export default ParentFile;
