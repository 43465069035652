import { Text } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import ShowHide from "@components/ShowHide";
import T from "@components/T";

const ReasonPlacementEndedCell = ({
  daysOfNotice,
  explanation,
  placementEndedReason,
  requester,
}) => (
  <React.Fragment>
    <Text as="div">{placementEndedReason || ""}</Text>
    {requester && (
      <Text as="div">
        <T t="admin.children.reason_placement_ended_cell.requester" />:{" "}
        {requester}
      </Text>
    )}
    {daysOfNotice && (
      <Text>
        <T t="admin.children.reason_placement_ended_cell.days_of_notice" />:{" "}
        {daysOfNotice}
      </Text>
    )}
    {explanation && (
      <ShowHide
        renderHeader={() => <T t="create_or_edit_service.details" />}
        renderBody={() => <Text>{explanation}</Text>}
      />
    )}
  </React.Fragment>
);

ReasonPlacementEndedCell.propTypes = {
  daysOfNotice: PropTypes.number,
  explanation: PropTypes.string,
  placementEndedReason: PropTypes.string,
  requester: PropTypes.string,
};

export default ReasonPlacementEndedCell;
