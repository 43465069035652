import { gql, useMutation, useQuery } from "@apollo/client";
import { Actions, SurfaceForm } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import Spinner from "@components/Spinner";
import BannerContainer from "@components/shared/banner/BannerContainer";

import UpdateBackgroundCheckMutation from "@graphql/mutations/UpdateBackgroundCheck.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import BackgroundCheckForm from "./BackgroundCheckForm";

const BackgroundCheckQuery = gql`
  query BackgroundCheck($id: ID!) {
    backgroundCheck(id: $id) {
      id
      name
      agencyId
      backgroundCheckTypeId
      rolesSpec
      step
      renewalRequired
      required
    }
  }
`;

/**
 * Form for updating a background check
 */
const UpdateBackgroundCheckForm = ({ id, cancelPath, onSuccessRedirectTo }) => {
  const [updateBackgroundCheck, { error: mutationError }] = useMutation(
    UpdateBackgroundCheckMutation,
    {
      onCompleted: () => window.location.replace(onSuccessRedirectTo),
    }
  );
  const {
    data,
    error: queryError,
    loading,
  } = useQuery(BackgroundCheckQuery, {
    variables: { id },
  });

  const { formState, setFormAttribute } = useBintiForm(data?.backgroundCheck);

  if (loading) {
    return <Spinner />;
  }

  const error = queryError || mutationError;
  if (error) {
    return <BannerContainer type="error" message={error.message} />;
  }

  return (
    <SurfaceForm
      title={I18n.t("admin.background.check.title")}
      onSubmit={preventDefault(() =>
        updateBackgroundCheck({
          variables: { input: formState },
        })
      )}
      actions={
        <Actions
          primaryText={I18n.t("admin.background.check.update")}
          cancelText={I18n.t("views.common.cancel")}
          cancelHref={cancelPath}
        />
      }
    >
      <BackgroundCheckForm
        formState={formState}
        setFormAttribute={setFormAttribute}
      />
    </SurfaceForm>
  );
};

UpdateBackgroundCheckForm.propTypes = {
  id: BintiPropTypes.ID,
  cancelPath: PropTypes.string.isRequired,
  onSuccessRedirectTo: PropTypes.string,
};

export default UpdateBackgroundCheckForm;
