import { Actions, Flex, Surface } from "@heart/components";
import PropTypes from "prop-types";
import {
  editProviderRoleRequirementMatrixPath,
  providerRoleRequirementMatrixDashboardPath,
} from "routes";

import { Table, Tbody, Tr, Th, Td } from "@components/reusable_ui/Table";

import BintiPropTypes from "@lib/BintiPropTypes";

const ShowProviderRoleRequirementMatrix = ({
  matrix,
  agency,
  uploadTypeSlugs,
}) => (
  <Surface>
    <Flex column>
      <Actions
        primaryText="Edit"
        primaryHref={editProviderRoleRequirementMatrixPath({ id: matrix.id })}
        secondaryText="Back to Dashboard"
        secondaryHref={providerRoleRequirementMatrixDashboardPath()}
      />
      <Table aria-label={`Information for ${matrix.name}`}>
        <Tbody>
          <Tr>
            <Th>{"Name"}</Th>
            <Td>{matrix.name}</Td>
          </Tr>
          <Tr>
            <Th>{"Slug"}</Th>
            <Td>{matrix.slug}</Td>
          </Tr>
          <Tr>
            <Th>{"Agency"}</Th>
            <Td>{agency.name}</Td>
          </Tr>
          <Tr>
            <Th>{"Provider Role"}</Th>
            <Td>{matrix.provider_role}</Td>
          </Tr>
          <Tr>
            <Th>{"Subrole"}</Th>
            <Td>{matrix.subrole}</Td>
          </Tr>
          <Tr>
            <Th>{"License Type"}</Th>
            <Td>{matrix.license_type}</Td>
          </Tr>
          <Tr>
            <Th>{"Upload Type Slugs"}</Th>
            <Td>
              <ul>
                {uploadTypeSlugs.map(slug => (
                  <li key={slug}>{slug}</li>
                ))}
              </ul>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Flex>
  </Surface>
);
ShowProviderRoleRequirementMatrix.propTypes = {
  matrix: PropTypes.shape({
    id: BintiPropTypes.ID,
    name: PropTypes.string,
    slug: PropTypes.string,
    provider_role: PropTypes.string,
    subrole: PropTypes.string,
    license_type: PropTypes.string,
  }),
  agency: PropTypes.shape({ name: PropTypes.string }),
  uploadTypeSlugs: PropTypes.arrayOf(PropTypes.string),
};
export default ShowProviderRoleRequirementMatrix;
