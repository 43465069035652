import { IconMenu, Icons, Modal } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

import { policy } from "@lib/graphqlHelpers";

const { t } = translationWithRoot(
  "requirements.requirement_table.actions_cell"
);

const ActionsMenu = ({
  holderToken,
  fulfillment,
  completeFormInstance,
  deleteRequirement,
}) => {
  const { isFulfilled, isOverridden, formInstance, requirement } = fulfillment;

  const [hideDeleteModal, setHideDeleteModal] = useState(true);
  const [submittingDeletion, setSubmittingDeletion] = useState(false);
  const linkItems = [];
  if (policy(requirement).mayDestroy()) {
    linkItems.push({
      description: t("delete_requirement"),
      onClick: () => setHideDeleteModal(false),
    });
  }
  if (
    formInstance &&
    policy(formInstance).mayComplete() &&
    !isFulfilled &&
    !isOverridden
  ) {
    linkItems.push({
      description: t("sample_fill"),
      onClick: () => {
        completeFormInstance({
          variables: {
            formInstanceId: formInstance.id,
            holderToken,
          },
        });
      },
    });
  }

  return (
    <Fragment>
      <IconMenu
        icon={Icons.EllipsisVertical}
        menuDescription={t("view_additional", {
          name: fulfillment.requirement.title,
        })}
        linkItems={linkItems}
        rightAligned
      />

      {/* Delete Requirement Confirmation Modal */}
      <Modal
        title={t("are_you_sure_you_want_to_delete")}
        hidden={hideDeleteModal}
        onCancel={() => setHideDeleteModal(true)}
        onSubmit={async () => {
          await setSubmittingDeletion(true);
          await deleteRequirement({
            variables: { requirementId: fulfillment.requirement.id },
          });
          await setHideDeleteModal(true);
          await setSubmittingDeletion(false);
        }}
        submitting={submittingDeletion}
        submittingText={t("deleting")}
        submitText={t("yes_delete")}
        submitDangerButton
      />
    </Fragment>
  );
};
ActionsMenu.propTypes = {
  holderToken: PropTypes.string.isRequired,
  fulfillment: PropTypes.shape({
    isFulfilled: PropTypes.bool.isRequired,
    isOverridden: PropTypes.bool.isRequired,
    formInstance: PropTypes.shape({
      id: PropTypes.string.isRequired,
      editUrl: PropTypes.string,
    }),
    requirement: PropTypes.shape({
      id: PropTypes.number.isRequired,
      form: PropTypes.shape({ slug: PropTypes.string }),
      title: PropTypes.string.isRequired,
      uploadType: PropTypes.shape({ slug: PropTypes.string }),
    }).isRequired,
    waitingForSignature: PropTypes.bool.isRequired,
  }),
  /** GraphQL Mutations */
  completeFormInstance: PropTypes.func.isRequired,
  deleteRequirement: PropTypes.func.isRequired,
};

export default ActionsMenu;
