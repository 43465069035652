import { Link, ModalNotice, Text, generateTabParam } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { relationshipsDashboardAdminChildPath } from "routes";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "family_finding.potential_kin_search.modal_notice"
);

/**
 * Displays the success modal after linking connections in FF&E Integrated
 * Search. Has 2 broad configurations, depending on `keystoneAgencyHuman`.
 *
 * If `keystoneAgencyHuman` is present, this component assumes it is only
 * displaying the success of creating a single AgencyHuman, i.e. after submit on
 * LinkPotentialConnectionModal.
 *
 * If `keystoneAgencyHuman` is _not_ present, this component assumes it is
 * displaying the results of several mutations, contained in
 * `linkConnectionResults`.
 *
 * `linkConnectionResults` should be an array of settled Promises, resulting
 * from calling a mutation function. The Promises will be sorted based on their
 * status, and the failures will be displayed separately.
 */
const ResultModalNotice = ({
  keystoneAgencyHuman,
  childAgencyHumans,
  hidden,
  onClose,
  linkConnectionResults = [],
}) => {
  const linksToChildren = (
    <Fragment>
      {t("click_to_view_connections_of")}
      {childAgencyHumans.map(({ childId, label }) => (
        <Link
          key={childId}
          href={`${relationshipsDashboardAdminChildPath(
            childId
          )}?${generateTabParam("Potential Connections")}`}
        >
          {label}
        </Link>
      ))}
    </Fragment>
  );

  if (keystoneAgencyHuman) {
    return (
      <ModalNotice
        hidden={hidden}
        onClose={onClose}
        title={t("profile_was_created", {
          name: keystoneAgencyHuman.firstName,
        })}
      >
        {linksToChildren}
      </ModalNotice>
    );
  }

  const successes = linkConnectionResults.filter(
    result => result.status === "fulfilled"
  );
  const failures = linkConnectionResults.filter(
    result => result.status === "rejected"
  );

  return (
    <ModalNotice
      hidden={hidden}
      onClose={onClose}
      title={t("potential_connections_created", { count: successes.length })}
      icon={isEmpty(successes) ? "error" : "success"}
    >
      {!isEmpty(successes) && linksToChildren}
      <If condition={failures.length > 0}>
        {t("could_not_create", { count: failures.length })}
        {failures.map((failure, idx) => (
          <Text key={idx} textColor="danger-500">
            {failure.reason.message}
          </Text>
        ))}
        <Text className="centered">{t("contact_support")}</Text>
      </If>
    </ModalNotice>
  );
};
ResultModalNotice.propTypes = {
  /** Whether modal should be hidden or not */
  hidden: PropTypes.bool.isRequired,
  /** Function called when the modal is closed */
  onClose: PropTypes.func.isRequired,
  /** When present, keystone's name will be displayed in the modal title */
  keystoneAgencyHuman: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
  }),
  /** List of children to link out to within the modal
   * We're using a `label` as the fullName + DOB as these entries are coming
   * back from a GraphQL query powered input
   */
  childAgencyHumans: PropTypes.arrayOf(
    PropTypes.shape({
      childId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  // An array of settled Promises, e.g. from Promise.allSettled
  linkConnectionResults: PropTypes.array,
};

export default ResultModalNotice;
