import {
  InputText,
  MultiInputTemplate,
  InputDate,
  InputDropdown,
  InputRadioGroup,
  InputTextarea,
} from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "background_check_exemptions.recommendation_inputs"
);

const ExemptionRecommendations = ({
  setFormAttribute,
  exemptionRecommendations,
  recommendedOutcomes,
  workerRoles,
}) => (
  <Fragment>
    <MultiInputTemplate
      title={t("recommendation")}
      onChange={setFormAttribute("exemptionRecommendations")}
      onDelete={setFormAttribute("exemptionRecommendationIdsToDelete")}
      startingValues={exemptionRecommendations || []}
      defaultValues={{
        conditions: "",
        notes: "",
        workerRole: "",
        recommendedBy: "",
        recommendation: "pending",
      }}
      sectionInputs={({ getMultiInputProps }) => (
        <Fragment>
          <InputText
            {...getMultiInputProps({ id: "recommendedBy" })}
            type="text"
            label={t("recommended_by")}
          />
          <InputDropdown
            {...getMultiInputProps({ id: "workerRole" })}
            label={t("role")}
            values={workerRoles.map(role => ({
              label: I18n.t(
                `activerecord.enums.background_check_exemption.worker_roles.${role}`
              ),
              value: role,
            }))}
          />
          <InputDate
            {...getMultiInputProps({ id: "recommendationDate" })}
            label={t("recommendation_date")}
          />
          <InputDropdown
            {...getMultiInputProps({ id: "recommendation" })}
            label={t("recommendation")}
            values={recommendedOutcomes.map(outcome => ({
              label: I18n.t(
                `activerecord.enums.background_check_exemption.outcomes.${outcome}`
              ),
              value: outcome,
            }))}
          />
          <InputRadioGroup
            {...getMultiInputProps({ id: "isLimitedToSpecificChild" })}
            label={t("is_limited_to_specific_child")}
            orientation="row"
            values={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
          <InputText
            {...getMultiInputProps({ id: "conditions" })}
            label={t("conditions")}
          />
          <InputTextarea
            {...getMultiInputProps({ id: "notes" })}
            label={t("notes")}
            rows={5}
          />
        </Fragment>
      )}
      addSectionText={t("add_another")}
      removeSectionText={t("remove")}
    />
  </Fragment>
);

ExemptionRecommendations.propTypes = {
  setFormAttribute: PropTypes.func.isRequired,
  exemptionRecommendations: PropTypes.array.isRequired,
  recommendedOutcomes: PropTypes.array.isRequired,
  workerRoles: PropTypes.array.isRequired,
};

export default ExemptionRecommendations;
