import { useMutation } from "@apollo/client";
import {
  Button,
  Flex,
  FlexItem,
  Link,
  Text,
  InputText,
  InputHidden,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState } from "react";

import MutationForm from "@components/MutationForm";

import UpdateUser from "@graphql/mutations/UpdateUser.graphql";

const ChangeEmailForm = ({
  csrfToken,
  currentEmail,
  currentUrl,
  explicitlyHasNoEmail = false,
  onSubmit,
  userId,
  userCanChangeEmail = true,
}) => {
  const [editing, setEditing] = useState(false);
  const [emailValue, setEmailValue] = useState(currentEmail || "");
  const [noEmail, setNoEmail] = useState(explicitlyHasNoEmail);
  const [mutate, { loading }] = useMutation(UpdateUser);

  const handleSuccess = () => setEditing(false);
  const handleNoEmail = () => {
    mutate({
      variables: {
        userId,
        explicitlyHasNoEmail: true,
      },
    });
    setNoEmail(true);
  };

  const ActionButtons = () => {
    if (emailValue) {
      return (
        <Button variant="secondary" onClick={() => setEditing(true)}>
          Change Email
        </Button>
      );
    } else if (noEmail) {
      return (
        <Button variant="secondary" onClick={() => setEditing(true)}>
          Add Email
        </Button>
      );
    }
    return (
      <React.Fragment>
        <Button onClick={() => setEditing(true)}>Yes</Button>
        <Button
          variant="secondary"
          disabled={loading}
          onClick={onSubmit || handleNoEmail}
        >
          No
        </Button>
      </React.Fragment>
    );
  };

  const emailMessage = () => {
    if (emailValue) {
      return <Link href={`mailto:${emailValue}`}>{emailValue}</Link>;
    } else if (noEmail) {
      return (
        <Text>{I18n.t("admin.common.applicant_does_not_have_email")}</Text>
      );
    }
    return <p>Does the applicant have an email?</p>;
  };

  const handleCancel = () => {
    setEmailValue(currentEmail || "");
    setEditing(false);
  };

  return editing ? (
    <div>
      <MutationForm
        csrfToken={csrfToken}
        failureUrl={currentUrl}
        mutation={UpdateUser}
        onSuccess={handleSuccess}
        onSubmit={onSubmit}
        successUrl={currentUrl}
      >
        <InputHidden name="userId" value={userId} />
        <Flex align="center">
          <FlexItem expand="lg">
            <Flex column>
              <InputText
                type="email"
                label="Email Address"
                hideLabel
                onChange={setEmailValue}
                name="email"
                placeholder={currentEmail || "Add email address here"}
                required
                value={emailValue}
              />
              <If condition={currentEmail}>
                <InputText
                  label={I18n.t("admin.common.how_did_you_verify_email")}
                  id={`emailChangeExplanation-${userId}`}
                  name="emailChangeExplanation"
                  required
                />
              </If>
            </Flex>
          </FlexItem>
          <Button type="submit">Save Changes</Button>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Flex>
      </MutationForm>
    </div>
  ) : (
    <Flex align="center">
      <FlexItem expand="lg">{emailMessage()}</FlexItem>
      <If condition={userCanChangeEmail}>
        <ActionButtons />
      </If>
    </Flex>
  );
};

ChangeEmailForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEmail: PropTypes.string,
  currentUrl: PropTypes.string.isRequired,
  explicitlyHasNoEmail: PropTypes.bool,
  onSubmit: PropTypes.func,
  userId: PropTypes.string.isRequired,
  userCanChangeEmail: PropTypes.bool,
};

export default ChangeEmailForm;
