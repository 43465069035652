import classNames from "classnames";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { useEffect, useCallback, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";

import { isValidPhoneNumber } from "@lib/phoneNumbers";

import styles from "./InputPhone.module.scss";
import { BasicInputLayout, inputCommonPropTypes } from "./common";

/** A phone number input!  This input uses `react-phone-number-input`
 * under the hood to do country-selection and phone number formatting.
 * A few things to note:
 *   * `onChange` is always invoked with a formatted phone number
 *   * This component will set its own error text when the phone number is invalid,
 *     which will also fail HTML validation and prevent submission of forms when you
 *     use `<Button type="submit">` and `<form onSubmit={}>`.
 */
const InputPhone = props => {
  const { value, onChange, required } = props;
  let { error } = props;
  const [phoneNumberValue, setPhoneNumberValue] = useState(value);
  const isNumberInvalid =
    phoneNumberValue && !isValidPhoneNumber(phoneNumberValue);
  useEffect(() => setPhoneNumberValue(value), [value]);

  const inputRef = useRef();
  const handleChange = useCallback(
    newValue => {
      setPhoneNumberValue(newValue);
      // phoneNumberValue tracks raw user input, but if we use the
      // value from the input itself it will include number spacing -
      // which is consistent with form POSTs that will use input.value
      if (onChange) onChange(inputRef.current.value);
    },
    [onChange]
  );

  useEffect(() => {
    // "Populate with fake data" mutates this input directly on the DOM
    // (very not ideal); we need to inform this component when that
    // happens so it can update its own state tracking.
    inputRef.current.addEventListener("change", e => {
      handleChange(e.currentTarget.value);
    });
  }, [handleChange]);

  const validate = e => {
    if (phoneNumberValue && !isValidPhoneNumber(phoneNumberValue)) {
      e.target.setCustomValidity(I18n.t("views.common.enter_valid_phone"));
    } else {
      e.target.setCustomValidity("");
    }
  };

  const getError = () => {
    if (isNumberInvalid) {
      error = I18n.t("views.common.enter_valid_phone");
    }
    return error;
  };

  return (
    <BasicInputLayout
      {...props}
      error={getError()}
      inputComponent={inputProps => (
        <div
          className={classNames(styles.input, {
            [styles.containsError]: error,
          })}
        >
          <PhoneInput
            {...inputProps}
            value={phoneNumberValue}
            onChange={handleChange}
            defaultCountry="US"
            international
            ref={inputRef}
            onBlur={validate}
            pattern={required ? ".{7,15}" : ".*"}
          />
        </div>
      )}
    />
  );
};

InputPhone.propTypes = {
  ...inputCommonPropTypes,
  /** `onChange` is invoked with the formatted number. */
  onChange: PropTypes.func,
  /** A phone number, formatted or otherwise */
  value: PropTypes.string,
};
export default InputPhone;
