import { useMutation } from "@apollo/client";
import { Link, Modal } from "@heart/components";
import PropTypes from "prop-types";
import { useState, Fragment } from "react";

import OverrideModal from "@components/requirements/OverrideModal";

import DeleteOverrideRecord from "@graphql/mutations/DeleteOverrideRecord.graphql";

import { policy } from "@lib/graphqlHelpers";

// TODO(ENG-12169): Flock/merge with Override component on DocumentCell
const OverrideRecordEditDelete = ({
  holderToken,
  fulfillment,
  overrideRecord,
  replaceOverrideRecord,
}) => {
  const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false);
  const [isDeleteModalHidden, setIsDeleteModalHidden] = useState(true);

  const [deleteOverrideRecord, { loading: isDeleteModalSubmitting }] =
    useMutation(DeleteOverrideRecord, {
      onCompleted: () => setIsDeleteModalHidden(true),
    });

  const onDelete = () =>
    deleteOverrideRecord({
      variables: {
        holderToken: holderToken,
        overrideRecordId: overrideRecord.id,
      },
    });

  const onSubmit = async ({ reason, details, optionKey, grantDate }) => {
    await replaceOverrideRecord({
      reason,
      details,
      optionKey,
      grantDate,
      overrideRecord,
    }).then(() => {
      setIsOverrideModalOpen(false);
    });
  };

  return (
    <Fragment>
      <If condition={policy(overrideRecord).mayUpdate()}>
        <Link onClick={() => setIsOverrideModalOpen(true)}>edit</Link>
      </If>
      <If condition={policy(overrideRecord).mayDestroy()}>
        <Link onClick={() => setIsDeleteModalHidden(false)}>delete</Link>
      </If>
      <OverrideModal
        {...{
          fulfillment,
          overrideRecord,
          onSubmit,
          isModalOpen: isOverrideModalOpen,
          onCloseModal: () => setIsOverrideModalOpen(false),
        }}
      />
      {/* Delete Override Confirmation Modal */}
      <Modal
        title={I18n.t(
          "javascript.components.requirements.requirement_table.document_cell.override.are_you_sure"
        )}
        hidden={isDeleteModalHidden}
        submitting={isDeleteModalSubmitting}
        onSubmit={onDelete}
        onCancel={() => setIsDeleteModalHidden(true)}
        submitDangerButton
        submitText={I18n.t(
          "javascript.components.requirements.requirement_table.document_cell.override.delete"
        )}
        submittingText={I18n.t(
          "javascript.components.requirements.requirement_table.document_cell.deleting"
        )}
      />
    </Fragment>
  );
};

OverrideRecordEditDelete.propTypes = {
  holderToken: PropTypes.string.isRequired,
  fulfillment: PropTypes.object.isRequired,
  overrideRecord: PropTypes.object.isRequired,
  replaceOverrideRecord: PropTypes.func.isRequired,
};

export default OverrideRecordEditDelete;
