import {
  InputDropdown,
  InputRadioGroup,
  InputText,
  InputTextarea,
  LiveRegion,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

import {
  EXITED_CARE_REASONS,
  MOVED_TO_OTHER_PLACEMENT_REASONS_CAREGIVER_REQUESTED,
  MOVED_TO_OTHER_PLACEMENT_REASONS_DEPT_OR_AGENCY_REQUESTED,
} from "@root/constants";

import styles from "./PlacementEnd.module.scss";

const { T, t } = translationWithRoot("placement_ended_reason");

const dropdownValues = json =>
  Object.entries(json).map(([value, label]) => ({ value, label }));

const Details = ({
  endedExplanation: originalEndedExplanation,
  showInCareRadio,
  setFormAttribute,
}) => {
  const [endedExplanation, setEndedExplanation] = useState(
    originalEndedExplanation
  );

  return (
    <Fragment>
      <If condition={showInCareRadio}>
        <InputRadioGroup
          label={<T t="still_in_care" />}
          name="in_agency_care"
          values={[
            { value: true, label: I18n.t("javascript.components.common.yes") },
            { value: false, label: t("not_in_care") },
          ]}
          required
          onChange={setFormAttribute("inAgencyCare")}
        />
      </If>
      <InputTextarea
        label={<T t="details" />}
        name="placement_period[placement_ended_reason_explanation]"
        value={endedExplanation}
        onChange={value => {
          setEndedExplanation(value);
          setFormAttribute("placementEndedReasonExplanation")(value);
        }}
      />
    </Fragment>
  );
};

Details.propTypes = {
  endedExplanation: PropTypes.string,
  showInCareRadio: PropTypes.bool.isRequired,
  setFormAttribute: PropTypes.func,
};

const ExitedCare = ({
  endedExplanation,
  endedReason: originalEndedReason,
  setFormAttribute,
}) => {
  const [endedReason, setEndedReason] = useState(
    originalEndedReason || undefined
  );

  return (
    <LiveRegion>
      <div className={styles.inputs}>
        <InputDropdown
          label={<T t="reason_child_exited" />}
          name="placement_period[placement_ended_reason]"
          value={endedReason}
          values={dropdownValues(EXITED_CARE_REASONS)}
          onChange={value => {
            setFormAttribute("placementEndedReason")(value);
            setEndedReason(value);
          }}
          required
        />
        <If condition={endedReason}>
          <Details
            endedExplanation={endedExplanation}
            showInCareRadio={false}
            setFormAttribute={setFormAttribute}
          />
        </If>
      </div>
    </LiveRegion>
  );
};

ExitedCare.propTypes = {
  endedReason: PropTypes.string,
  endedExplanation: PropTypes.string,
  setFormAttribute: PropTypes.func,
};

const MovedToPlacement = ({
  daysOfNotice: originalDaysOfNotice,
  isCurrentPlacement,
  requester: originalRequester,
  endedExplanation,
  endedReason: originalEndedReason,
  setFormAttribute,
}) => {
  const [daysOfNotice, setDaysOfNotice] = useState(originalDaysOfNotice);
  const [requester, setRequester] = useState(originalRequester || undefined);
  const [endedReason, setEndedReason] = useState(
    originalEndedReason || undefined
  );

  return (
    <LiveRegion>
      <div className={styles.inputs}>
        <InputDropdown
          label={<T t="requester" />}
          name="placement_period[placement_ended_requester]"
          values={[
            { value: "caregiver", label: t("caregiver") },
            { value: "department_or_agency", label: t("department_or_agency") },
            { value: "child", label: t("child") },
          ]}
          value={requester}
          onChange={value => {
            setRequester(value);
            setFormAttribute("placementEndedRequester")(value);
          }}
          required
        />
        <If condition={requester === "caregiver"}>
          <InputText
            label={<T t="days_of_notice" />}
            name="placement_period[days_of_notice]"
            type="number"
            value={daysOfNotice}
            onChange={value => {
              setDaysOfNotice(value);
              setFormAttribute("daysOfNotice")(value);
            }}
          />
          <InputDropdown
            label={<T t="placement_ended_reason_caregiver" />}
            name="placement_period[placement_ended_reason]"
            value={endedReason}
            values={dropdownValues(
              MOVED_TO_OTHER_PLACEMENT_REASONS_CAREGIVER_REQUESTED
            )}
            onChange={value => {
              setEndedReason(value);
              setFormAttribute("placementEndedReason")(value);
            }}
            required
          />
        </If>
        <If condition={requester === "department_or_agency"}>
          <InputDropdown
            label={<T t="placement_ended_reason_agency" />}
            name="placement_period[placement_ended_reason]"
            value={endedReason}
            values={dropdownValues(
              MOVED_TO_OTHER_PLACEMENT_REASONS_DEPT_OR_AGENCY_REQUESTED
            )}
            onChange={value => {
              setEndedReason(value);
              setFormAttribute("placementEndedReason")(value);
            }}
            required
          />
        </If>
        <If condition={(requester && endedReason) || requester === "child"}>
          <Details
            endedExplanation={endedExplanation}
            showInCareRadio={isCurrentPlacement}
            setFormAttribute={setFormAttribute}
          />
        </If>
      </div>
    </LiveRegion>
  );
};

MovedToPlacement.propTypes = {
  endedReason: PropTypes.string,
  daysOfNotice: PropTypes.string,
  isCurrentPlacement: PropTypes.bool.isRequired,
  requester: PropTypes.string,
  endedExplanation: PropTypes.string,
  setFormAttribute: PropTypes.func,
};

const PlacementEndedReason = ({
  daysOfNotice,
  endedExplanation,
  endedReason,
  isCurrentPlacement,
  requester,
  setFormAttribute = () => () => {},
}) => {
  let movedTo;
  if (endedReason && Object.keys(EXITED_CARE_REASONS).includes(endedReason)) {
    movedTo = "exited_care";
  } else if (endedReason) {
    movedTo = "moved_to_another_placement";
  }
  const [childMovedTo, setChildMovedTo] = useState(movedTo);

  return (
    <LiveRegion>
      <div className={styles.inputs}>
        <InputDropdown
          label={<T t="moved_to" />}
          values={[
            {
              value: "moved_to_another_placement",
              label: t("moved_to_another_placement"),
            },
            { value: "exited_care", label: t("exited_care") },
          ]}
          value={childMovedTo}
          onChange={setChildMovedTo}
          required
        />
        <If condition={childMovedTo === "exited_care"}>
          <ExitedCare
            endedExplanation={endedExplanation}
            endedReason={endedReason}
            setFormAttribute={setFormAttribute}
          />
        </If>
        <If condition={childMovedTo === "moved_to_another_placement"}>
          <MovedToPlacement
            endedReason={endedReason}
            daysOfNotice={daysOfNotice}
            isCurrentPlacement={isCurrentPlacement}
            requester={requester}
            endedExplanation={endedExplanation}
            setFormAttribute={setFormAttribute}
          />
        </If>
      </div>
    </LiveRegion>
  );
};

PlacementEndedReason.propTypes = {
  daysOfNotice: PropTypes.string,
  endedExplanation: PropTypes.string,
  endedReason: PropTypes.string,
  isCurrentPlacement: PropTypes.bool.isRequired,
  requester: PropTypes.string,
  setFormAttribute: PropTypes.func,
};

export default PlacementEndedReason;
