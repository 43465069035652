import { gql, useQuery } from "@apollo/client";
import {
  Background,
  Flex,
  InputDropdown,
  Surface,
  Text,
} from "@heart/components";
import LogoSpinner from "@heart/components/loading_overlay/LogoSpinner";
import I18n from "i18n-js";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";

import T from "@components/T";
import UpdateAvailabilityLink from "@components/update_availability_link/UpdateAvailabilityLink";

import styles from "./Availability.module.scss";
import ChildrenInHome from "./ChildrenInHome";

export const ChildrenInHomeQuery = gql`
  query ChildrenInHomeQuery($agencyPlacementId: ID!) {
    agencyPlacement(agencyPlacementId: $agencyPlacementId) {
      availabilityUpdatedAt
      currentChildrenInHome {
        id
        fullName
        dependentName
        permissions
      }
      respiteChildrenInHome {
        id
        fullName
        dependentName
        permissions
      }
    }
  }
`;

const formatDate = date =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);

export const Availability = ({
  agencyPlacementId,
  bedsTemporarilyUnavailable = 0,
  capacity,
  inputName = null,
  onBedsUpdated = () => {},
  writeMode = false,
}) => {
  const [availability, setAvailability] = useState("?");
  const [availabilityUpdatedAt, setAvailabilityUpdatedAt] = useState();
  const [bedsUnavailable, setBedsUnavailable] = useState(0);
  const [childrenInHomeCount, setChildrenInHomeCount] = useState(0);
  const [currentChildrenInHome, setCurrentChildrenInHome] = useState([]);
  const [respiteChildrenInHome, setRespiteChildrenInHome] = useState([]);
  const { loading, data } = useQuery(ChildrenInHomeQuery, {
    variables: { agencyPlacementId },
  });

  useEffect(() => {
    if (data) {
      const { agencyPlacement } = data;

      if (agencyPlacement.availabilityUpdatedAt) {
        setAvailabilityUpdatedAt(
          formatDate(agencyPlacement.availabilityUpdatedAt)
        );
      }
      setCurrentChildrenInHome(agencyPlacement.currentChildrenInHome);
      setRespiteChildrenInHome(agencyPlacement.respiteChildrenInHome);
    }
  }, [data]);

  useEffect(() => {
    setChildrenInHomeCount(
      currentChildrenInHome.length + respiteChildrenInHome.length
    );
  }, [currentChildrenInHome, respiteChildrenInHome]);

  useEffect(() => {
    setBedsUnavailable(bedsTemporarilyUnavailable);
  }, [bedsTemporarilyUnavailable]);

  useEffect(() => {
    if (capacity) {
      const availabilityCalculation =
        capacity - childrenInHomeCount - bedsUnavailable;

      setAvailability(Math.max(availabilityCalculation, 0));
    }
  }, [bedsUnavailable, capacity, childrenInHomeCount]);

  if (loading) {
    return (
      <Surface title={I18n.t("common.availability")}>
        <div style={{ width: "50px", height: "50px" }}>
          <LogoSpinner />
        </div>
      </Surface>
    );
  }

  const onDropdownChange = value => {
    setBedsUnavailable(value);
    onBedsUpdated(value);
  };
  const dropdownOptions = Array.from({ length: capacity + 1 }, (_, n) =>
    String(n)
  );
  const equationStyles = { alignSelf: "center" };

  return (
    <div className={styles.availabilityContainer}>
      <Surface title={I18n.t("common.availability")}>
        <Flex justify="center" mobileColumn className={styles.calculation}>
          <Background className={styles.term} color="info-50">
            <Flex column align="center">
              <h3>{I18n.t("admin.common.capacity")}</h3>
              <Text data-testid="capacity-value" textStyle="emphasis-300">
                {capacity || "?"}
              </Text>
            </Flex>
          </Background>
          <Text style={equationStyles} textStyle="emphasis-300">
            -
          </Text>
          <Background className={styles.term} color="info-50">
            <Flex column align="center">
              <h3>
                <T t={"end_user.end_user_upload_type_table.children_in_home"} />
              </h3>
              <Text textStyle="emphasis-300">{childrenInHomeCount}</Text>
            </Flex>
          </Background>
          <Text style={equationStyles} textStyle="emphasis-300">
            -
          </Text>
          <Background className={styles.term} color="info-50">
            <Flex column align="center">
              {writeMode ? (
                <Fragment>
                  <InputDropdown
                    disabled={!capacity}
                    hideBlank
                    label={
                      <T
                        t={
                          "placements.availability.beds_temporarily_unavailable"
                        }
                      />
                    }
                    name={inputName}
                    onChange={onDropdownChange}
                    value={String(bedsUnavailable)}
                    values={dropdownOptions}
                  />
                  {capacity && (
                    <UpdateAvailabilityLink
                      label={I18n.t(
                        "javascript.components.placements.availability.up_to_date"
                      )}
                      url={`/admin/placements/${agencyPlacementId}/update_availability_updated_at`}
                    />
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <h3>
                    <T
                      t={"placements.availability.beds_temporarily_unavailable"}
                    />
                  </h3>
                  <Text textStyle="emphasis-300">{bedsUnavailable}</Text>
                  {availabilityUpdatedAt && (
                    <Text>
                      {`${I18n.t(
                        "javascript.components.placements.availability.as_of"
                      )} ${availabilityUpdatedAt}`}
                    </Text>
                  )}
                </Fragment>
              )}
            </Flex>
          </Background>
          <Text style={equationStyles} textStyle="emphasis-300">
            =
          </Text>
          <Background className={styles.term} color="info-100">
            <Flex column align="center">
              <h3>{I18n.t("common.availability")}</h3>
              <Text data-testid="availability-value" textStyle="emphasis-300">
                {availability}
              </Text>
            </Flex>
          </Background>
        </Flex>
        <ChildrenInHome
          placedChildren={currentChildrenInHome}
          respiteChildren={respiteChildrenInHome}
        />
      </Surface>
    </div>
  );
};

Availability.propTypes = {
  agencyPlacementId: PropTypes.number.isRequired,
  bedsTemporarilyUnavailable: PropTypes.number,
  capacity: PropTypes.number,
  inputName: PropTypes.string,
  onBedsUpdated: PropTypes.func,
  writeMode: PropTypes.bool,
};

export default Availability;
