import { get, isNil } from "lodash";

import { B64PARAMS } from "@root/constants";

import {
  ENCODED_EMPTY_OBJECT,
  decodeAndParse,
  stringifyAndEncode,
} from "./base64Helpers";

/** Returns the current encoded params in the URL. Useful primarily
 * for cases where we want to preserve the params when changing the route
 */
export const getEncodedBase64SearchParams = () => {
  const params = new URLSearchParams(window.location.search).get(B64PARAMS);
  return params || ENCODED_EMPTY_OBJECT;
};

/** Returns a JSON blob of the search params in the URL */
export const getBase64SearchParams = () => {
  // defaults to encoded empty object if not present
  const params = getEncodedBase64SearchParams();
  return decodeAndParse(params);
};

/** Return an encoded set of params pulling from the existing URL params
 * and tacking on any additional updates for specified attributes.
 *
 * Useful for changing routes when additional arguments are required
 */
export const addEncodedBase64SearchParams = (parameters = []) => {
  const params = getBase64SearchParams();
  parameters.forEach(({ attribute, value }) => {
    if (isNil(value) || value === "") {
      delete params[attribute];
    } else {
      params[attribute] = value;
    }
  });

  return stringifyAndEncode(params);
};

/** Determine existing search params in URL json blob for a specific attribute
 *
 * `attribute` can be any path in the json blob, e.g. "query.status"
 */
export const getBase64SearchParamForAttribute = ({ attribute }) => {
  const params = getBase64SearchParams();
  return get(params, attribute);
};

/** Maintain existing search params & update provided attribute to value */
export const setBase64SearchParams = parameters => {
  const newParams = new URLSearchParams(window.location.search);

  newParams.set(B64PARAMS, addEncodedBase64SearchParams(parameters));

  /** Remove legacy param, if present. Specifically for ContentTabs, we're
   * still supporting the use of `tab=` as a query param to avoid breaking
   * existing links, but when folks visit a page using the old param we want
   * to clear it and move it to the new base64 param
   */
  parameters.forEach(({ attribute }) => newParams.delete(attribute));

  window.history.pushState({}, "", `${window.location.pathname}?${newParams}`);
};

/** Clear all but provided search params */
export const clearAllOtherBase64SearchParams = (
  { preserveAttributes } = { preserveAttributes: [] }
) => {
  const newParams = new URLSearchParams(window.location.search);
  newParams.set(B64PARAMS, {});

  const preservedAttrs = {};
  preserveAttributes.forEach(attribute => {
    const val = getBase64SearchParamForAttribute({ attribute });
    preservedAttrs[attribute] = val;
  });

  newParams.set(B64PARAMS, stringifyAndEncode(preservedAttrs));
  window.history.pushState({}, "", `${window.location.pathname}?${newParams}`);
};
