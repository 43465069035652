import { useMutation, useReactiveVar } from "@apollo/client";
import { Modal, toast } from "@heart/components";
import PropTypes from "prop-types";

import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import DeleteApiToken from "@graphql/mutations/DeleteApiToken.graphql";
import ApiTokensQuery from "@graphql/queries/ApiTokens.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const ApiTokenDeleteModal = ({ apiTokenIdToDelete, resetStateCallback }) => {
  const queryVariables = useReactiveVar(queryVariablesVar);

  const [deleteApiToken, { loading }] = useMutation(DeleteApiToken, {
    onCompleted: () => {
      toast.success({
        title: "Success!",
        message: "Deleted API token",
      });
      resetStateCallback();
    },
    refetchQueries: [{ query: ApiTokensQuery, variables: queryVariables }],
  });

  return (
    <Modal
      title="Delete API Token"
      submitting={loading}
      submitDangerButton
      submitText="Delete"
      submittingText="Deleting..."
      onCancel={() => {
        resetStateCallback();
      }}
      onSubmit={async () => {
        await deleteApiToken({
          variables: { apiTokenId: apiTokenIdToDelete },
        });
      }}
      hidden={false}
    >
      Are you sure you want to delete this API Token? This action cannot be
      undone.
    </Modal>
  );
};

ApiTokenDeleteModal.propTypes = {
  apiTokenIdToDelete: BintiPropTypes.ID,
  resetStateCallback: PropTypes.func,
};
export default ApiTokenDeleteModal;
