import { Button, GraphQLDataTable, Surface, Link } from "@heart/components";
import {
  adminAgencyPath,
  adminApplicationTemplatesPath,
  newProviderRoleRequirementMatrixPath,
  showProviderRoleRequirementMatrixPath,
} from "routes";

import ProviderRoleRequirementMatrixPaginatedQuery from "@graphql/queries/ProviderRoleRequirementMatrixPaginatedQuery";

const ProviderRoleRequirementMatrixDashboard = () => {
  const filters = [
    {
      label: "Name",
      type: "search",
      field: "nameIn",
    },
    {
      label: "Agency",
      type: "search",
      field: "agencyIn",
    },
  ];

  const columns = [
    {
      columnName: { name: "Name" },
      id: "name",
      sortBy: "name",
      cell: ({ id, name }) => (
        <Link
          href={showProviderRoleRequirementMatrixPath({
            id: id,
          })}
        >
          {name}
        </Link>
      ),
    },
    {
      columnName: { name: "Slug" },
      id: "slug",
      sortBy: "slug",
      cell: "slug",
    },
    {
      columnName: { name: "License Type" },
      id: "license_type",
      sortBy: "license_type",
      cell: ({ licenseType }) => (
        <Link
          href={adminApplicationTemplatesPath({
            "q[license_type_in][]": licenseType,
          })}
          target="_blank"
        >
          {licenseType}
        </Link>
      ),
    },

    {
      columnName: { name: "Provider Role" },
      id: "provider_role",
      sortBy: "provider_role",
      cell: "providerRole",
    },
    {
      columnName: { name: "Subrole" },
      id: "subrole",
      sortBy: "subrole",
      cell: "subrole",
    },
    {
      columnName: { name: "Upload Types" },
      id: "upload_types",
      cell: ({ uploadTypeSlugs }) => uploadTypeSlugs.join(", "),
    },
    {
      columnName: { name: "Agency" },
      id: "agency",
      cell: ({ agency: { id, name } }) => (
        <Link href={adminAgencyPath(id)} target="_blank">
          {name}
        </Link>
      ),
    },
  ];
  return (
    <Surface title={"Provider Role Requirement Matrix Dashboard"}>
      <GraphQLDataTable
        query={ProviderRoleRequirementMatrixPaginatedQuery}
        queryTitle="providerRoleRequirementMatrices"
        filters={filters}
        columns={columns}
        actions={
          <Button href={newProviderRoleRequirementMatrixPath()}>
            Create Staff Member Requirement Matrix
          </Button>
        }
      />
    </Surface>
  );
};
ProviderRoleRequirementMatrixDashboard.propTypes = {};
export default ProviderRoleRequirementMatrixDashboard;
