/* This file contains variables and functions used to generate the default text in the EmailPreview and SMSPreview components */
import { translationWithRoot } from "@components/T";

const boy = "boy";
const child = "child";
const childUnknownGender = "child_unknown_gender";
const girl = "girl";

const { t } = translationWithRoot("availability_request");
const defaultEmailSignature = t("email_draft.signature");

/* boolean value representing whether the child should be referred to as 'child' (vs. 'boy' or 'girl') */
const referToAsChildBoolean = childForPlacement =>
  childForPlacement.referAsTranslationKey &&
  childForPlacement.referAsTranslationKey === child;
/* boolean value representing whether the child should be referred to as 'child (unknown gender)' (vs. 'child' or 'boy' or 'girl') */
const referToAsChildUnknownGenderBoolean = childForPlacement =>
  childForPlacement.referAsTranslationKey &&
  childForPlacement.referAsTranslationKey === childUnknownGender;
/* boolean value representing whether the child should be referred to as 'child' OR 'child (unknown gender)' */
const referToAsChildOrUnknownGenderBoolean = childForPlacement =>
  childForPlacement.referAsTranslationKey &&
  (childForPlacement.referAsTranslationKey === child ||
    childForPlacement.referAsTranslationKey === childUnknownGender);
/* boolean value representing whether the child should be referred to as 'boy' */
const referToAsBoyBoolean = childForPlacement =>
  childForPlacement.referAsTranslationKey &&
  childForPlacement.referAsTranslationKey === boy;
/* boolean value representing whether the child should be referred to as 'girl' */
const referToAsGirlBoolean = childForPlacement =>
  childForPlacement.referAsTranslationKey &&
  childForPlacement.referAsTranslationKey === girl;

/* boolean value representing whether the child has age=0 */
const age0Boolean = childForPlacement => childForPlacement.age === 0;
/* boolean value representing whether the child has age>0 */
const ageAbove0Boolean = childForPlacement => childForPlacement.age > 0;
/* boolean value representing whether we don't know age of the child */
const unknownAgeBoolean = childForPlacement =>
  childForPlacement.age !== 0 && !childForPlacement.age;

const formDefaultEmailTextSiblingGroup = childrenForPlacement =>
  childrenForPlacement
    .map(childForPlacement => {
      if (age0Boolean(childForPlacement)) {
        if (referToAsChildBoolean(childForPlacement)) {
          return t("email_draft.sibling_group_with_age_0");
        } else if (referToAsChildUnknownGenderBoolean(childForPlacement)) {
          return t("email_draft.sibling_group_with_age_0_unknown_gender");
        } else if (referToAsBoyBoolean(childForPlacement)) {
          return t("email_draft.sibling_group_with_age_0_boy");
        } else if (referToAsGirlBoolean(childForPlacement)) {
          return t("email_draft.sibling_group_with_age_0_girl");
        }
      } else if (ageAbove0Boolean(childForPlacement)) {
        if (referToAsBoyBoolean(childForPlacement)) {
          return t("email_draft.sibling_group_with_age_above_0_boy", {
            age: childForPlacement.age,
          });
        } else if (referToAsGirlBoolean(childForPlacement)) {
          return t("email_draft.sibling_group_with_age_above_0_girl", {
            age: childForPlacement.age,
          });
        } else if (referToAsChildOrUnknownGenderBoolean(childForPlacement)) {
          return t("email_draft.sibling_group_with_age_above_0", {
            age: childForPlacement.age,
            refer_as: t(`refer_as.${childForPlacement.referAsTranslationKey}`),
          });
        }
      } else if (unknownAgeBoolean(childForPlacement)) {
        if (referToAsChildBoolean(childForPlacement)) {
          return t("email_draft.sibling_group_without_age");
        } else if (referToAsChildUnknownGenderBoolean(childForPlacement)) {
          return t("email_draft.sibling_group_without_age_unknown_gender");
        } else if (referToAsBoyBoolean(childForPlacement)) {
          return t("email_draft.sibling_group_without_age_boy");
        } else if (referToAsGirlBoolean(childForPlacement)) {
          return t("email_draft.sibling_group_without_age_girl");
        }
      }
      return t(`refer_as.${childForPlacement.referAsTranslationKey}`);
    })
    .join(", ");

const formDefaultSMSChildDescription = childrenForPlacement =>
  childrenForPlacement
    .map(childForPlacement => {
      if (age0Boolean(childForPlacement)) {
        if (referToAsChildBoolean(childForPlacement)) {
          return t("sms_draft.age_0");
        } else if (referToAsChildUnknownGenderBoolean(childForPlacement)) {
          return t("sms_draft.age_0_unknown_gender");
        } else if (referToAsBoyBoolean(childForPlacement)) {
          return t("sms_draft.age_0_boy");
        } else if (referToAsGirlBoolean(childForPlacement)) {
          return t("sms_draft.age_0_girl");
        }
      } else if (ageAbove0Boolean(childForPlacement)) {
        if (referToAsChildOrUnknownGenderBoolean(childForPlacement)) {
          return t("sms_draft.age_above_0", {
            age: childForPlacement.age,
            refer_as: t(`refer_as.${childForPlacement.referAsTranslationKey}`),
          });
        } else if (referToAsBoyBoolean(childForPlacement)) {
          return t("sms_draft.age_above_0_boy", {
            age: childForPlacement.age,
          });
        } else if (referToAsGirlBoolean(childForPlacement)) {
          return t("sms_draft.age_above_0_girl", {
            age: childForPlacement.age,
          });
        }
      } else if (unknownAgeBoolean(childForPlacement)) {
        if (referToAsChildBoolean(childForPlacement)) {
          return t("sms_draft.age_unknown");
        } else if (referToAsChildUnknownGenderBoolean(childForPlacement)) {
          return t("sms_draft.age_unknown_unknown_gender");
        } else if (referToAsBoyBoolean(childForPlacement)) {
          return t("sms_draft.age_unknown_boy");
        } else if (referToAsGirlBoolean(childForPlacement)) {
          return t("sms_draft.age_unknown_girl");
        }
      }
      return t(`refer_as.${childForPlacement.referAsTranslationKey}`);
    })
    .join(", ");

const formDefaultEmailTextOneChild = ([childForPlacement]) => {
  if (age0Boolean(childForPlacement)) {
    if (referToAsChildBoolean(childForPlacement)) {
      return t("email_draft.one_child_with_age_0");
    } else if (referToAsChildUnknownGenderBoolean(childForPlacement)) {
      return t("email_draft.one_child_with_age_0_unknown_gender");
    } else if (referToAsBoyBoolean(childForPlacement)) {
      return t("email_draft.one_child_with_age_0_boy");
    } else if (referToAsGirlBoolean(childForPlacement)) {
      return t("email_draft.one_child_with_age_0_girl");
    }
  } else if (ageAbove0Boolean(childForPlacement)) {
    if (referToAsChildOrUnknownGenderBoolean(childForPlacement)) {
      return t("email_draft.one_child_with_age_above_0", {
        age: childForPlacement.age,
        refer_as: t(`refer_as.${childForPlacement.referAsTranslationKey}`),
      });
    } else if (referToAsBoyBoolean(childForPlacement)) {
      return t("email_draft.one_child_with_age_above_0_boy", {
        age: childForPlacement.age,
      });
    } else if (referToAsGirlBoolean(childForPlacement)) {
      return t("email_draft.one_child_with_age_above_0_girl", {
        age: childForPlacement.age,
      });
    }
  } else if (unknownAgeBoolean(childForPlacement)) {
    if (referToAsChildOrUnknownGenderBoolean(childForPlacement)) {
      return t("email_draft.one_child_without_age", {
        refer_as: t(`refer_as.${childForPlacement.referAsTranslationKey}`),
      });
    } else if (referToAsBoyBoolean(childForPlacement)) {
      return t("email_draft.one_child_without_age_boy");
    } else if (referToAsGirlBoolean(childForPlacement)) {
      return t("email_draft.one_child_without_age_girl");
    }
  }
  return t(`refer_as.${childForPlacement.referAsTranslationKey}`);
};

const formDefaultEmailTextLearnMore = (agencyName, agencyWorker) => {
  if (agencyWorker.mobilePhoneNumber) {
    return t("email_draft.learn_more_w_phone", {
      agency_name: agencyName,
      worker_name: agencyWorker.name,
      worker_number: agencyWorker.mobilePhoneNumber,
      worker_email: agencyWorker.email,
    });
  }

  return t("email_draft.learn_more", {
    agency_name: agencyName,
    worker_name: agencyWorker.name,
    worker_email: agencyWorker.email,
  });
};

const formDefaultSMSTextLearnMore = agencyWorker =>
  t("sms_draft.learn_more", {
    worker_email: agencyWorker.email,
    worker_number: agencyWorker.mobilePhoneNumber,
  });

const formDefaultEmailGreeting = (siblingGroup, agencyName, childText) => {
  if (siblingGroup) {
    return t("email_draft.greeting_sibing_group", {
      agency_name: agencyName,
      children_description: childText,
    });
  }

  return t("email_draft.greeting_one_child", {
    agency_name: agencyName,
    child_description: childText,
  });
};

const formDefaultSMSGreeting = (
  siblingGroup,
  agencyName,
  defaultSMSChildText
) =>
  siblingGroup
    ? t("sms_draft.opening_sibling_group", {
        children_description: defaultSMSChildText,
        agency_name: agencyName,
      })
    : t("sms_draft.opening_one_child", {
        child_description: defaultSMSChildText,
        agency_name: agencyName,
      });

export {
  referToAsChildBoolean,
  referToAsChildUnknownGenderBoolean,
  referToAsChildOrUnknownGenderBoolean,
  referToAsBoyBoolean,
  referToAsGirlBoolean,
  age0Boolean,
  ageAbove0Boolean,
  unknownAgeBoolean,
  defaultEmailSignature,
  formDefaultEmailTextSiblingGroup,
  formDefaultEmailTextOneChild,
  formDefaultEmailTextLearnMore,
  formDefaultSMSTextLearnMore,
  formDefaultEmailGreeting,
  formDefaultSMSChildDescription,
  formDefaultSMSGreeting,
};
