/* eslint-disable no-shadow */
import { gql, useQuery } from "@apollo/client";
import { Icons, InputDropdown, LiveRegion } from "@heart/components";

const AgencyDropdown = ({ value, ...inputProps }) => {
  const { data: { agencies } = {}, loading } = useQuery(gql`
    query Agencies {
      agencies {
        id
        name
      }
    }
  `);

  const agencyValues = agencies?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const content = loading ? (
    <Icons.Spinner />
  ) : (
    <InputDropdown
      {...inputProps}
      values={agencyValues}
      value={String(value)}
    />
  );

  return <LiveRegion>{content}</LiveRegion>;
};

// This is otherwise a standard dropdown but the values are
// hydrated from the backend.
const { values, ...propTypes } = InputDropdown.propTypes;
AgencyDropdown.propTypes = propTypes;

export default AgencyDropdown;
