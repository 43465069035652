import { ADDRESS_TYPE_PHYSICAL_HOME } from "@root/constants";

export const convertToPhoneNumberInputs = ({ phoneNumbers }) =>
  phoneNumbers?.map(({ phoneNumber }) => ({
    phoneNumber,
    personType: "AgencyHuman",
    active: true,
    category: null,
  }));

// Convert the addresses from the API into AddressInput objects
export const convertToAddressInputs = ({
  addresses,
  agencyId,
  primarySubdivisions,
}) =>
  addresses?.map(address => ({
    agencyId: agencyId,
    customFields: "",
    inactive: false,
    primary: false,
    type: ADDRESS_TYPE_PHYSICAL_HOME,
    addressLine1: address.addressLine1,
    city: address.city,
    primarySubdivision: primarySubdivisions.find(
      subdiv => subdiv.code === address.state
    ).name,
    postalCode: address.zip,
    countryCode: "US",
  }));

export const selectedObjectsFromCheckboxGroup = (selections, data) =>
  selections?.map(selection => {
    // `formState.phoneNumbers` may be either strings like `searchData_phoneNumbers_NNN`
    // or objects with a `value` property formatted accordingly.
    const phoneNumberIdentifier = selection.value || selection;
    // Extract the phone number index from the identifier.
    const n = phoneNumberIdentifier.split("_")[2];
    return data?.at(n);
  });
