export const ENCODED_EMPTY_OBJECT = "e30=";

/** Base64 encoding and decoding helpers
 *
 * These are a set of very simple helpers, essentially renaming the built-in
 * `btoa` and `atob` functions to `encode` and `decode` respectively. We are
 * renaming them for code readability, as the built in names are not particularly
 * descriptive
 */

/** encode str into base64 */
export const encode = str => btoa(str);

/** decode str from base64 */
export const decode = str => atob(str);

/** decode str from base64 and parse into JSON blob */
export const decodeAndParse = (str = ENCODED_EMPTY_OBJECT) =>
  JSON.parse(decode(str));

/** stringify provided attributes and encode into base64 */
export const stringifyAndEncode = (attrs = {}) => encode(JSON.stringify(attrs));

export default { encode, decode, decodeAndParse, stringifyAndEncode };
