import { ContainsTrustedHTML, Notice, PageContainer } from "@heart/components";
import PropTypes from "prop-types";

/**
 * To create a Notice from Rails, you can do:
 *
 * ```js
 * flash[:long_error] = {
 *   title: "Your application failed to update",
 *   content: tag.p(...)
 * }
 * ```
 *
 * or
 *
 * ```js
 * flash[:long_error] = {
 *   title: "Your application failed to update",
 *   error_list: ["strings of errors"]
 * }
 * ```
 *
 * This component is a shim for the Notice component allowing us to render it with HTML content;
 * since Rails will be passing it a lot of information from deep inside `flash[]` calls,
 * we let Rails be responsible for some of the HTML if it wants to be.
 *
 * It also defaults the title to "Notice" when not given.
 *
 * It also wraps itself in a `<PageContainer>` so it's not against the left edge
 * of the page in ActiveAdmin layouts - this isn't the ideal way to accomplish this,
 * but we'd need to alter ActiveAdmin layout CSS to get this to work otherwise.
 */
const RailsNotice = ({ title = "Notice", children, errorList }) => (
  <PageContainer>
    <Notice title={title} errorList={errorList}>
      <div className="prose">
        <ContainsTrustedHTML
          trustedSource="Rails calls to flash[]"
          html={children}
        ></ContainsTrustedHTML>
      </div>
    </Notice>
  </PageContainer>
);
RailsNotice.propTypes = {
  ...Notice.propTypes,
  /** Title isn't required; it will default to "Notice" */
  title: PropTypes.string,
};

export default RailsNotice;
