import { isNumber, isString } from "lodash";
import PropTypes from "prop-types";

import T from "@components/T";

import { useGeneratedId } from "@lib/generateId";

import styles from "./ProgressArc.module.scss";

const WIDTH = 100; // 100 as in 100%, this icon scales with its container
const HEIGHT = 100; // 100 as in 100%, this icon scales with its container
const TAU = 2 * Math.PI;
const NINETY_DEG = -TAU / 4;
// see $primary-100 in app/javascript/nonModuleScss/colors.scss
const FOREGROUND_COLOR = "#1772e5";
// see $colors-danger-600 in app/javascript/nonModuleScss/colors.scss
// to match AI assistant color
const SECONDARY_FOREGROUND_COLOR = "#BD0059";
const BACKGROUND_COLOR = "#EEEEEE";
const EPSILON = 0.000000001;

const RADIUS = WIDTH / 2;

/**
 * An SVG path that represents a partial arc of a circle.
 */
const Arc = ({ progress, color = FOREGROUND_COLOR }) => {
  const percentage = Math.min(progress / 100.0, 0.9999999);

  if (percentage <= EPSILON) return null;

  const angle = TAU * percentage;
  const x2 = RADIUS * Math.cos(NINETY_DEG + angle);
  const y2 = RADIUS * Math.sin(NINETY_DEG + angle);
  const fillMode = percentage < 0.5 ? "0,1" : "1,1";

  const path = [
    `M0,${-RADIUS}`, // Start at the top of the circle
    `A${RADIUS},${RADIUS}`, // Draw a circular arc of radius RADIUS
    "0", // No rotation
    fillMode, // Fill works different once we get past 50%
    `${x2},${y2}`, // Arc to the point computed by the trig above
    "L0,0", // Draw a line back to the center of the circle
    "Z", // Done.
  ].join(" ");

  return (
    <path
      d={path}
      style={{ fill: color, zIndex: 1 }}
      vectorEffect="non-scaling-stroke"
    />
  );
};

Arc.propTypes = {
  /** The percent completeness of the task this icon represents */
  progress: PropTypes.number,
  /** The color of the arc */
  color: PropTypes.string,
};

/**
 * A pie-chart style icon that represents the partial progress of a task.
 */
const ProgressIcon = ({
  progress,
  secondaryProgress,
  secondaryProgressTitle,
}) => {
  const labelId = useGeneratedId();

  return (
    <svg
      className={styles.icon}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      data-testid="progress-arc-partial"
      data-test-progress-number={progress}
      role="img"
      aria-labelledby={labelId}
    >
      <title id={labelId}>
        <T t="progress_arc.progress_percentage" percentage={progress} />
        <If condition={isNumber(secondaryProgress)}>
          {" - "}
          {secondaryProgressTitle}
        </If>
      </title>
      <circle r="50%" cx="50%" cy="50%" style={{ fill: BACKGROUND_COLOR }} />
      <g transform={`translate(${RADIUS},${RADIUS})`}>
        <Arc progress={progress} />
        <If condition={isNumber(secondaryProgress)}>
          <Arc
            progress={secondaryProgress}
            color={SECONDARY_FOREGROUND_COLOR}
          />
        </If>
      </g>
    </svg>
  );
};

ProgressIcon.propTypes = {
  /** The percent completeness of the task this icon represents */
  progress: PropTypes.number,
  /**
   * an optional "sub" completeness of a task. Should be less than
   * or equal to progress, if provided (otherwise it'll cover the
   * primary progress). This will be rendered as another arc on
   * top of the main one with a different color. Currently this
   * feature is only used for AI review indication on forms.
   */
  secondaryProgress: PropTypes.number,
  /**
   * An additional progress title to add describing what the secondary progress
   * represents. This is required if you specify a secondaryProgress and it must
   * include the value of secondaryProgress.
   */
  secondaryProgressTitle: (props, propName, componentName) => {
    // no secondary progress, no title needed
    if (!isNumber(props.secondaryProgress) && !props.secondaryProgress) {
      return undefined;
    }

    // secondaryProgressTitle is required if secondaryProgress is provided
    if (!isString(props[propName])) {
      return new Error(
        `'${propName}' must be supplied to '${componentName}' when 'secondaryProgress' is provided`
      );
    }

    if (!props[propName].include(props.secondaryProgress.toString())) {
      return new Error(
        `'${propName}' must contain the value of 'secondaryProgress'`
      );
    }

    // make the linter happy
    return undefined;
  },
};

export default ProgressIcon;
