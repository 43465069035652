import { Button } from "@heart/components";
import Honeybadger from "@honeybadger-io/js";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import preventDefault from "@lib/preventDefault";

/**
 * Login with Google button to make the initial call to Google to get our
 * auth token from them, then pass that token back to Binti to prove who we are.
 */
const GoogleSSO = ({ googleOauth2ClientId, redirectPath, callbackPath }) => {
  const [hasGoogleApiLoaded, setHasGoogleApiLoaded] = useState(false);

  useEffect(() => {
    // poll a bit in case the google api library isn't yet initialized. usually takes at most
    // one retry.
    const setLoadCallback = () => {
      if (!window.google?.accounts) {
        setTimeout(setLoadCallback, 200);
      } else {
        setHasGoogleApiLoaded(true);
      }
    };

    setTimeout(setLoadCallback, 200);
  });

  const callback = response => {
    // whatever the response, redirect and let the server sort it out.
    // we either authenticated successfully or not and the server will
    // know what to do.
    if (response && !response.error) {
      $.ajax({
        type: "POST",
        url: callbackPath,
        data: response,
        success: () => {
          window.location = redirectPath;
        },
      });
    } else {
      if (
        response &&
        response.error &&
        response.error !== "popup_closed_by_user"
      ) {
        Honeybadger.notify("Error with Google SSO", {
          details: {
            responseDetails: response.details,
            responseError: response.error,
          },
        });
      }
      window.location = redirectPath;
    }
  };

  const oauthClient =
    hasGoogleApiLoaded &&
    window.google.accounts.oauth2.initCodeClient({
      client_id: googleOauth2ClientId,
      scope: "email",
      ux_mode: "popup",
      callback: callback,
    });

  const onClick = preventDefault(() => {
    oauthClient?.requestCode();
  });

  return (
    <Button
      variant="secondary"
      disabled={!hasGoogleApiLoaded}
      onClick={onClick}
    >
      Login with Google
    </Button>
  );
};

GoogleSSO.propTypes = {
  googleOauth2ClientId: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
  callbackPath: PropTypes.string.isRequired,
};

export default GoogleSSO;
