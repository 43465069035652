import PropTypes from "prop-types";
import { Component } from "react";

import styles from "./RedirectButton.module.scss";

class RedirectButton extends Component {
  componentDidMount() {
    const { url, delay } = this.props;

    setTimeout(() => {
      window.location = url;
    }, delay * 1000);
  }

  render() {
    const { url, text } = this.props;
    return (
      <a className={styles["primary-button"]} href={url}>
        {text || url}
      </a>
    );
  }
}

RedirectButton.propTypes = {
  url: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default RedirectButton;
