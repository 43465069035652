import { Modal, Flex, Icons } from "@heart/components";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";
import OverrideModal from "@components/requirements/OverrideModal";

import { LUXON_DATEPICKER_FORMAT } from "@lib/dates";
import { policy } from "@lib/graphqlHelpers";

import styles from "./DocumentCell.module.scss";
import createOrEditOverrideRecord from "./createOrEditOverrideRecord";

const { t } = translationWithRoot(
  "requirements.requirement_table.document_cell"
);

export const deleteOverrideOnSubmit = async ({
  holderToken,
  overrideRecordId,
  /** State setters for Modal */
  setHideDeleteModal,
  setSubmittingDeletion,
  /** GraphQL Mutations */
  deleteOverrideRecord,
}) => {
  await setSubmittingDeletion(true);
  await deleteOverrideRecord({
    variables: { holderToken, overrideRecordId },
  });
  await setHideDeleteModal(true);
  await setSubmittingDeletion(false);
};

const Override = ({
  holderToken,
  fulfillment,
  lastItem = false,
  overrideRecord,
  /** GraphQL Mutations */
  createFormOverride,
  createUploadTypeOverride,
  deleteOverrideRecord,
}) => {
  const {
    id: overrideRecordId,
    creatorName,
    details,
    grantDate,
    reason,
  } = overrideRecord;
  const [hideDeleteModal, setHideDeleteModal] = useState(true);
  const [showOverrideModal, setShowOverrideModal] = useState(false);
  const [submittingDeletion, setSubmittingDeletion] = useState(false);

  return (
    <Flex column>
      <Flex justify="space-between">
        {t("override.waived_by", {
          date: DateTime.fromISO(grantDate).toFormat(LUXON_DATEPICKER_FORMAT),
          creatorName,
        })}
        <Flex align="center">
          <If condition={policy(overrideRecord).mayUpdate()}>
            <Icons.Pencil
              description={t("override.edit")}
              onClick={() => setShowOverrideModal(true)}
            />
          </If>
          <If condition={policy(overrideRecord).mayDestroy()}>
            <Icons.Trash
              description={t("override.delete")}
              onClick={() => setHideDeleteModal(false)}
            />
          </If>
        </Flex>
      </Flex>
      {`${reason
        .split("_")
        .map(word => capitalize(word))
        .join(" ")} - ${details}`}
      <If condition={!lastItem}>
        <hr className={styles.dividingLine} />
      </If>

      {/* Delete Override Confirmation Modal */}
      <Modal
        title={t("override.are_you_sure")}
        hidden={hideDeleteModal}
        submitting={submittingDeletion}
        onSubmit={async () => {
          deleteOverrideOnSubmit({
            holderToken,
            overrideRecordId,
            setHideDeleteModal,
            setSubmittingDeletion,
            deleteOverrideRecord,
          });
        }}
        onCancel={() => setHideDeleteModal(true)}
        submitDangerButton
        submitText={t("delete_record")}
        submittingText={t("deleting")}
      />

      {/* Edit Override Modal */}
      <OverrideModal
        fulfillment={fulfillment}
        isModalOpen={showOverrideModal}
        onSubmit={createOrEditOverrideRecord({
          fulfillment,
          holderToken,
          createFormOverride,
          createUploadTypeOverride,
          overrideRecordIdToReplace: overrideRecordId,
          setShowOverrideModal,
        })}
        onCloseModal={() => setShowOverrideModal(false)}
        overrideRecord={overrideRecord}
      />
    </Flex>
  );
};
Override.propTypes = {
  /** id of the entity that this override is
   * associated with
   */
  holderToken: PropTypes.string.isRequired,
  /** The fulfillment that this override is
   * associated with
   */
  fulfillment: PropTypes.shape({
    overrideRecords: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        creatorName: PropTypes.string.isRequired,
        details: PropTypes.string.isRequired,
        grantDate: PropTypes.string.isRequired,
        reason: PropTypes.string.isRequired,
      })
    ),
  }),
  /** Boolean indicating whether this override is the
   * last one in a list of overrides. Functionally,
   * controls whether a dividing line shows up at the bottom
   * of the attachment information
   */
  lastItem: PropTypes.bool,
  /** The override being displayed */
  overrideRecord: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creatorName: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
    grantDate: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
  }),
  /** GraphQL mutation for creating an override if it is
   * associated with a ChildFormInstance
   */
  createFormOverride: PropTypes.func.isRequired,
  /** GraphQL mutation for creating an override if it is
   * associated with a ScheduledUploadTypeRequirement
   */
  createUploadTypeOverride: PropTypes.func.isRequired,
  /** GraphQL mutation for deleting an override */
  deleteOverrideRecord: PropTypes.func.isRequired,
};

export default Override;
