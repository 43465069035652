import PropTypes from "prop-types";

import BintiPropTypes from "@lib/BintiPropTypes";

export default {
  queryVariables: PropTypes.shape({
    permissionRuleSetId: BintiPropTypes.ID,
    roleId: BintiPropTypes.ID,
    resource: PropTypes.string,
    action: PropTypes.string,
  }),
};
