import { gql, useMutation, useQuery } from "@apollo/client";
import { Icons, Link, ArrayDataTable } from "@heart/components";
import { Spinner } from "@heart/components/icon/Icon";
import { PropTypes } from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";
import BannerContainer from "@components/shared/banner/BannerContainer";

import PermissionRuleSets from "@graphql/queries/PermissionRuleSets.graphql";

const { t } = translationWithRoot("permissions.agency_relationships");

const DeletePermissionRuleSet = gql`
  mutation DeletePermissionRuleSet($id: ID!) {
    deletePermissionRuleSet(input: { id: $id }) {
      id
    }
  }
`;

const PermissionRuleSetsTable = () => {
  const { loading, data, error: queryError } = useQuery(PermissionRuleSets);

  const permissionRuleSets = data?.permissionRuleSets || [];

  const [deleteRuleSet, { loading: deleting, error: deleteError }] =
    useMutation(DeletePermissionRuleSet, {
      refetchQueries: [{ query: PermissionRuleSets }],
    });

  const errorMessage = [queryError?.message, deleteError?.message]
    .filter(Boolean)
    .join(", ");

  if (errorMessage) {
    return (
      <BannerContainer type="error" message={`Ooopsie! ${errorMessage}`} />
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Table
      permissionRuleSets={permissionRuleSets}
      onDelete={id => deleteRuleSet({ variables: { id } })}
      deleting={deleting}
    />
  );
};

const Table = ({ permissionRuleSets, onDelete, deleting }) => (
  <ArrayDataTable
    title={t("existing_permission_rule_sets")}
    columns={[
      {
        cell: row => (
          <Link href={`/permission_rule_sets/${row.id}`}>{row.label}</Link>
        ),
        columnName: {
          name: t("permission_rule_set_label"),
        },
        columnSort: "label",
        filter: {
          filterBy: "label",
          label: t("search_by_label"),
          type: "search",
        },
        id: "label",
      },
      {
        columnName: { name: I18n.t("javascript.components.common.actions") },
        cell: row => (
          <Icons.Trash
            description={t("delete_permission_rule_set")}
            onClick={() => onDelete(row.id)}
            disabled={deleting}
          />
        ),
        id: "id",
      },
    ]}
    data={permissionRuleSets}
  />
);

Table.propTypes = {
  permissionRuleSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onDelete: PropTypes.func,
  deleting: PropTypes.bool,
};

export default PermissionRuleSetsTable;
