import PropTypes from "prop-types";
import { useCallback } from "react";

import styles from "../../../BintiSign.module.scss";

const ReadOnlySignature = ({ width, height, signature }) => {
  const signatureCanvasRef = useCallback(
    node => {
      if (!node) return;

      const context = node.getContext("2d");
      const image = new Image();

      image.onload = () => {
        context.clearRect(0, 0, width, height);
        context.fillStyle = "#000";
        context.globalAlpha = 0.1;
        context.fillRect(0, 0, width, height);
        context.globalAlpha = 1.0;
        context.drawImage(image, 0, 0);
      };
      image.src = signature.signatureUri;
    },
    [signature.signatureUri]
  );

  return (
    <div
      className={styles.signatureInputPad}
      style={{ maxWidth: width, minHeight: height }}
    >
      <canvas
        className={styles.signatureCanvas}
        ref={signatureCanvasRef}
        width={width}
        height={height}
      />
    </div>
  );
};

ReadOnlySignature.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  signature: PropTypes.object.isRequired,
};

export default ReadOnlySignature;
