import { translationWithRoot } from "@components/T";

import { mapConstantSetToValues } from "@lib/constantsConversion";

import {
  FAMILY_FINDING_CONTACT_LOG_STATUSES,
  FAMILY_FINDING_CONTACT_LOG_CONTACT_METHODS,
} from "@root/constants";

const { t: contactLogsT } = translationWithRoot("family_finding.contact_logs");

const tableFilters = () => [
  {
    label: contactLogsT("contact_logs_table.search_contacts"),
    type: "search",
    field: "contactNameContains",
  },
  {
    label: contactLogsT("common.status"),
    type: "dropdown",
    field: "statusEq",
    values: mapConstantSetToValues({
      constant: FAMILY_FINDING_CONTACT_LOG_STATUSES,
      translationKey: "family_finding_contact_log.status",
    }),
  },
  {
    label: contactLogsT("common.contact_method"),
    type: "dropdown",
    field: "contactMethodEq",
    values: mapConstantSetToValues({
      constant: FAMILY_FINDING_CONTACT_LOG_CONTACT_METHODS,
      translationKey: "family_finding_contact_log.contact_method",
    }),
  },
  {
    label: contactLogsT("common.date_contacted"),
    type: "custom_dates",
    customDatesLabels: {
      start: contactLogsT("contact_logs_table.contacted_after"),
      end: contactLogsT("contact_logs_table.contacted_before"),
    },
    field: "contactedOn",
  },
];

export default tableFilters;
