import PropTypes from "prop-types";

import ProgressArc from "@components/ProgressArc";

import DocumentTableDataCell from "./DocumentTableDataCell";

const DocumentStatus = ({ doc }) => {
  const progress = doc.percentageComplete || 0;
  const progressString = (doc.percentageComplete && `(${progress}%)`) || "";

  if (doc.status === "complete") {
    return (
      <DocumentTableDataCell header="Status">
        <ProgressArc progress={100} progressIncomplete={false} />
        {progressString}
      </DocumentTableDataCell>
    );
  }

  if (doc.status === "not_applicable") {
    return <DocumentTableDataCell header="Status">N/A</DocumentTableDataCell>;
  }

  return (
    <DocumentTableDataCell header="Status">
      <ProgressArc progress={progress} progressIncomplete={true} />
      {progressString}
    </DocumentTableDataCell>
  );
};

DocumentStatus.propTypes = {
  doc: PropTypes.object.isRequired,
};

export default DocumentStatus;
