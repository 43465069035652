import { Button, Flex, Link, Text } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";

const { T } = translationWithRoot("common");

const OtpError = ({ error, onRetry, forgotPasswordUrl }) => {
  const actions = forgotPasswordUrl ? (
    <Link href={forgotPasswordUrl}>
      <Text textStyle="body-100">
        <T t="forgot_your_password" />
      </Text>
    </Link>
  ) : (
    <Button onClick={onRetry}>
      <T t="try_again" />
    </Button>
  );

  return (
    <Flex column align="center" gap="300">
      <Text textStyle="body-100" textColor="neutral-500">
        {error.message}
      </Text>
      <div>{actions}</div>
    </Flex>
  );
};

OtpError.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string }),
  onRetry: PropTypes.func,
  forgotPasswordUrl: PropTypes.string,
};

export default OtpError;
