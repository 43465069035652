/* eslint-disable react/prop-types */

/* eslint-disable react/display-name */
import PropTypes from "prop-types";

import StripedTable from "../../shared/StripedTable.js";
import EmailCell from "../cells/EmailCell.js";
import GenericCell from "../cells/GenericCell.js";
import TelephoneCell from "../cells/TelephoneCell.js";

const WorkerContacts = props => {
  const columns = [
    {
      Header: I18n.t("views.contacts.common.name"),
      accessor: "name",
      Cell: ({ value }) => (
        <GenericCell
          header={I18n.t("views.contacts.common.name")}
          value={value}
        />
      ),
    },
    {
      Header: I18n.t("views.contacts.worker.agency_name"),
      accessor: "agency",
      Cell: ({ value }) => (
        <GenericCell
          header={I18n.t("views.contacts.worker.agency_name")}
          value={value}
        />
      ),
    },
    {
      Header: I18n.t("views.contacts.common.phone"),
      accessor: "phoneNumber",
      Cell: ({ value }) => (
        <TelephoneCell
          header={I18n.t("views.contacts.common.phone")}
          phoneNumber={value}
        />
      ),
    },
    {
      Header: I18n.t("views.contacts.common.email"),
      accessor: "email",
      Cell: ({ value }) => (
        <EmailCell
          header={I18n.t("views.contacts.common.email")}
          email={value}
        />
      ),
    },
    {
      Header: I18n.t("views.contacts.common.role"),
      accessor: "role",
      Cell: ({ value }) => (
        <GenericCell
          header={I18n.t("views.contacts.common.role")}
          value={value}
        />
      ),
    },
  ];
  const { title = "", contactsList = [] } = props;
  if (contactsList.length === 0) {
    return null;
  }
  return <StripedTable title={title} data={contactsList} columns={columns} />;
};

WorkerContacts.propTypes = {
  contactsList: PropTypes.array,
  title: PropTypes.string,
};

export default WorkerContacts;
