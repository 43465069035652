import { InputHidden } from "@heart/components";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import styles from "./NeededForInput.module.scss";

const CHILD_KEY = "child";
const APPLICANT_KEY = "applicant";
const CO_APPLICANT_KEY = "co_applicant";
const OTHER_ADULT_IN_HOME_KEY = "other_adult_in_home";
const CHILD_IN_HOME_KEY = "child_in_home";
const REFERENCE_KEY = "reference_user";
const STAFF_MEMBER_KEY = "staff_member";
const APPLICATION_KEY = "application";
const PARENT_KEY = "parent";

/** For entities that could apply to either the application, the child, or any given
 * end-user, this input allows you to specify which is applicable.
 *
 *   Upload Types (or Forms) > New > "Needed For"
 */
const NeededForInput = ({ name, neededFor = [] }) => {
  const [neededForApplication, setNeededForApplication] = useState(
    isEqual(neededFor, [APPLICATION_KEY])
  );
  const [neededForChild, setNeededForChild] = useState(
    isEqual(neededFor, [CHILD_KEY])
  );
  const neededForApplicant = neededFor.includes(APPLICANT_KEY);
  const neededForCoApplicant = neededFor.includes(CO_APPLICANT_KEY);
  const neededForOtherAdult = neededFor.includes(OTHER_ADULT_IN_HOME_KEY);
  const neededForChildInHome = neededFor.includes(CHILD_IN_HOME_KEY);
  const neededForReference = neededFor.includes(REFERENCE_KEY);
  const neededForStaffMember = neededFor.includes(STAFF_MEMBER_KEY);
  const neededForParent = neededFor.includes(PARENT_KEY);
  const inputName = `${name}[]`;

  return (
    <fieldset className="choices">
      <li className="choice">
        <label>
          <input
            type="radio"
            checked={neededForApplication}
            onChange={() => {
              setNeededForApplication(true);
              setNeededForChild(false);
            }}
          />
          Application (provided only by caseworker)
        </label>
      </li>
      <li className="choice">
        <label>
          <input
            type="radio"
            checked={neededForChild}
            onChange={() => {
              setNeededForChild(true);
              setNeededForApplication(false);
            }}
          />
          Child (provided only by caseworker)
        </label>
      </li>
      {(neededForApplication || neededForChild) && (
        <InputHidden
          name={inputName}
          value={neededForApplication ? APPLICATION_KEY : CHILD_KEY}
        />
      )}
      <li className="choice">
        <label>
          <input
            type="radio"
            checked={!neededForApplication && !neededForChild}
            onChange={() => {
              setNeededForApplication(false);
              setNeededForChild(false);
            }}
          />
          End users
        </label>
      </li>
      {!(neededForApplication || neededForChild) && (
        <div className={styles.endUserRoles}>
          <li className="choice">
            <label>
              <input
                type="checkbox"
                name={inputName}
                defaultChecked={neededForApplicant}
                value={APPLICANT_KEY}
              />{" "}
              Applicant
            </label>
          </li>
          <li className="choice">
            <label>
              <input
                type="checkbox"
                name={inputName}
                defaultChecked={neededForCoApplicant}
                value={CO_APPLICANT_KEY}
              />{" "}
              Co-applicant
            </label>
          </li>
          <li className="choice">
            <label>
              <input
                type="checkbox"
                name={inputName}
                defaultChecked={neededForOtherAdult}
                value={OTHER_ADULT_IN_HOME_KEY}
              />{" "}
              Other adult in home
            </label>
          </li>
          <li className="choice">
            <label>
              <input
                type="checkbox"
                name={inputName}
                defaultChecked={neededForChildInHome}
                value={CHILD_IN_HOME_KEY}
              />{" "}
              Child in home
            </label>
          </li>
          <li className="choice">
            <label>
              <input
                type="checkbox"
                name={inputName}
                defaultChecked={neededForReference}
                value={REFERENCE_KEY}
              />{" "}
              Reference
            </label>
          </li>
          <li className="choice">
            <label>
              <input
                type="checkbox"
                name={inputName}
                defaultChecked={neededForStaffMember}
                value={STAFF_MEMBER_KEY}
              />{" "}
              Staff Member (Congregate Care/Agency Providers only)
            </label>
          </li>
          <li className="choice">
            <label>
              <input
                type="checkbox"
                name={inputName}
                defaultChecked={neededForParent}
                value={PARENT_KEY}
              />{" "}
              Parent (Currently CCWIS demo only)
            </label>
          </li>
        </div>
      )}
    </fieldset>
  );
};

NeededForInput.propTypes = {
  name: PropTypes.string.isRequired,
  neededFor: PropTypes.arrayOf(PropTypes.string),
};

export default NeededForInput;
