/**
 * Conditionally show/hide sections to edit (made for agencies in admin/user)
 */

$(() => {
  const $userRole = $("#agency_worker_role");
  if ($userRole.length === 0) {
    return;
  }

  const $addAgencyBtn = $(
    ".edit.admin_agency_workers .has_many_add, " +
      ".new.admin_agency_workers .has_many_add"
  );
  const isBintiAdmin = $("input[name='is_binti_admin']").val() === "true";

  $(".agency_admin_assignments").on("has_many_add:after", () => {
    if (!isBintiAdmin) {
      $(".agency_admin_assignments .has_many_remove").hide();
    }
  });

  $("#agency_worker_role").on("change", () => {
    if ($userRole.val() === "caseworker" || $userRole.val() === "binti_admin") {
      $(".has_many_fields").show();
      if ($(".inputs.has_many_fields").length >= 1) {
        $addAgencyBtn.hide();
        return;
      }
      $addAgencyBtn.click();
      $addAgencyBtn.hide();

      // don't show the remove button for non-admin
      if (!isBintiAdmin) {
        $(".agency_admin_assignments .has_many_remove").hide();
      }
    } else {
      $(".has_many_fields").hide();
      $addAgencyBtn.hide();
    }
  });
});
