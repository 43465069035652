import Honeybadger from "@honeybadger-io/js";
import { max } from "lodash";
import { Duration } from "luxon";
import { useState, useEffect } from "react";
import { fetch as fetchPolyfill } from "whatwg-fetch";

import { isDevelopmentEnvironment } from "@lib/environment";

import UserSessionTimeoutWarningModal from "./UserSessionTimeoutWarningModal";

/**
 * The default time we should poll the server, 1 minute in millis
 * @type {number}
 */
const DEFAULT_NEXT_POLL_TIME = Duration.fromObject({ minutes: 1 }).as(
  "milliseconds"
);

/**
 * Open Modal if there is less than 5 minutes left
 * @type {number}
 */
const PROMPT_ON_MILLISECONDS_LEFT = Duration.fromObject({
  minutes: 5,
}).as("milliseconds");

const UserSessionTimeoutWarning = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRefreshButtonDisabled, setRefreshButtonDisabled] = useState(false);
  const goBackToLoginPage = () => {
    // We reload the current page which then redirects
    // to the login page. This way when the user logs back in, they
    // land on the page they were on before being logged out.
    window.location.reload();
  };
  const onClick = () => {
    handleClick({
      setRefreshButtonDisabled,
      setIsModalOpen,
      goBackToLoginPage,
      fetch: fetchPolyfill,
    });
  };

  useEffect(() => {
    // make sure its one of the last requests to be processed. This is to make
    // sure that the last time check before the 5 minute warning modal pops up
    // is done a few seconds after when less than 5 minutes are remaining.
    if (!isDevelopmentEnvironment())
      setTimeout(() =>
        pollForSessionTimeout({
          fetch: fetchPolyfill,
          goBackToLoginPage,
          setIsModalOpen,
        })
      );
  }, []);

  return (
    <UserSessionTimeoutWarningModal
      isModalOpen={isModalOpen}
      onClick={onClick}
      isRefreshButtonDisabled={isRefreshButtonDisabled}
    />
  );
};

export default UserSessionTimeoutWarning;

/**
 * @internal - Exported for testing purposes only
 * @param args {Object}
 */
export const pollForSessionTimeout = async args => {
  const { fetch, goBackToLoginPage, setIsModalOpen } = args;

  try {
    const response = await fetch("/check_session_timeout");
    const text = await response.text();

    if (response.ok) {
      const timeLeft = Duration.fromObject({
        seconds: parseInt(text, 10),
      }).as("milliseconds");

      if (timeLeft <= 0) {
        // if the server returns 0, it means the session has timed out.
        return goBackToLoginPage();
      }

      if (timeLeft <= PROMPT_ON_MILLISECONDS_LEFT) {
        // Ask user if they want to continue via modal.
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }

      // Poll again 5 minutes before the session expires, or the default time if session
      // expires sooner.
      const nextPoll = max([
        DEFAULT_NEXT_POLL_TIME,
        timeLeft - PROMPT_ON_MILLISECONDS_LEFT,
      ]);

      setTimeout(() => {
        pollForSessionTimeout(args);
      }, nextPoll);
    } else {
      // Response is non 2xx
      setTimeout(() => {
        pollForSessionTimeout(args);
      }, DEFAULT_NEXT_POLL_TIME);

      Honeybadger.notify(
        "Unknown error in UserSessionTimeoutWarning - pollForSessionTimeout",
        {
          details: {
            responseText: text,
            responseCode: response.status,
          },
        }
      );
    }
  } catch (err) {
    // Network error (i.e. server is down or device has no internet) or code error
    setTimeout(() => {
      pollForSessionTimeout(args);
    }, DEFAULT_NEXT_POLL_TIME);

    Honeybadger.notify(err);
  }

  return undefined;
};

/**
 * @internal - Exported for testing purposes only
 * @param args {Object}
 */
export const handleClick = args => {
  const { setRefreshButtonDisabled, setIsModalOpen, fetch, goBackToLoginPage } =
    args;

  setRefreshButtonDisabled(true);
  return fetch("/refresh_session")
    .then(response => {
      if (response?.ok) {
        setIsModalOpen(false);
      } else if (response.status === 401) {
        setIsModalOpen(false);
        response.text().then(text => {
          if (text === "timed out") {
            goBackToLoginPage();
          }
        });
      } else {
        response.text().then(text =>
          Honeybadger.notify(
            "Unknown error in UserSessionTimeoutWarning - refresh",
            {
              details: {
                responseText: text,
                responseCode: response.status,
              },
            }
          )
        );
      }
      setRefreshButtonDisabled(false);
    })
    .catch(err => {
      Honeybadger.notify(err);
      setRefreshButtonDisabled(false);
    });
};
