import { useMutation } from "@apollo/client";
import { InputCheckboxGroup } from "@heart/components";
import PropTypes from "prop-types";
import { useRef, useEffect, useState } from "react";

import UpdateContactInfoVisibility from "@graphql/mutations/UpdateContactInfoVisibility.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import styles from "./ContactInfoVisibility.module.scss";

const ContactInfoVisibility = ({ initialVisibilityState, agencyWorkerId }) => {
  const [isVisible, setIsVisible] = useState(initialVisibilityState);

  const [updateContactInfoVisibility, { loading }] = useMutation(
    UpdateContactInfoVisibility
  );

  const update = () => {
    updateContactInfoVisibility({
      variables: {
        isVisible: isVisible.toString(),
        agencyWorkerId,
      },
    });
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    update();
  }, [isVisible]);

  const onSubmit = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={styles.container}>
      <InputCheckboxGroup
        className={styles.container}
        name="contact_info_visibility"
        onChange={onSubmit}
        value={[isVisible.toString()]}
        values={[
          {
            value: "true",
            label:
              "Make my contact information visible to families who are assigned to me",
          },
        ]}
        disabled={loading}
      />
    </div>
  );
};

ContactInfoVisibility.propTypes = {
  initialVisibilityState: PropTypes.bool.isRequired,
  agencyWorkerId: BintiPropTypes.ID.isRequired,
};

export default ContactInfoVisibility;
