import styles from "./SignatureInput.module.scss";

/**
 * An element with no display, but one that styles some text to force our
 * signature font to be loaded.
 */
const SignatureFontLoader = () => (
  <span className={styles.ensureSignatureFontIsLoaded}>.</span>
);

export default SignatureFontLoader;
