import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./Label.module.scss";

const Label = ({
  htmlFor,
  required,
  children,
  id,
  focusInput,
  hide = false,
}) => {
  const Component = id ? "p" : "label";
  return (
    <Component
      id={id}
      htmlFor={htmlFor}
      className={cn(styles.label, {
        [styles["label--required"]]: required,
        [styles.hidden]: hide,
      })}
      onClick={focusInput}
    >
      {children}
    </Component>
  );
};

Label.propTypes = {
  htmlFor: props => {
    if (!props.htmlFor && props.isTrueLabel) {
      return new Error("`htmlFor` is required when `isTrueLabel` is true");
    }
    return undefined;
  },
  required: PropTypes.bool,
  hide: PropTypes.bool,
  children: PropTypes.node.isRequired,
  /** This component handles two cases -
   *    * `<label for>` targeting a form field, or
   *    * `<p id={id}>` assuming there's an element elsewhere that's
   *      `aria-labelledby={id}`.  There's no reason for a `<label>` element
   *      to have an ID, so the presence of one triggers the latter case.
   */
  id: PropTypes.string,
  /** Real <label> labels will generally automatically support focusing
   * the input on clicking, but in some special cases, we need to do it
   * manually. If you have one of those rare cases (e.g. contenteditable divs),
   * provide this function that focuses on the input. It will be invoked
   * on the label being clicked.
   */
  focusInput: PropTypes.func,
};

export default Label;
