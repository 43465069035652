$(function () {
  function hideAllImages() {
    $(".js-afcars-images").hide();
  }

  function resetAllTabs() {
    $(".js-afcars-tab").removeClass("js-afcars-tab-clicked");
  }

  $(".js-afcars-tab").click(function () {
    hideAllImages();
    resetAllTabs();

    const tabClass = $(this).attr("class");
    const tabId = tabClass.substr(tabClass.length - 1);

    $(".js-afcars-images-" + tabId).show();
    $(this).addClass("js-afcars-tab-clicked");
  });
});
