import { Icons } from "@heart/components";
import PropTypes from "prop-types";

const SortIcon = ({ direction, isActiveSortColumn }) => {
  let icon = <Icons.Sort />;
  if (isActiveSortColumn) {
    switch (direction) {
      case "ASC":
        icon = <Icons.CaretUp />;
        break;
      case "DESC":
        icon = <Icons.CaretDown />;
        break;
      default:
        icon = <Icons.Sort />;
    }
  }
  return icon;
};

SortIcon.propTypes = {
  /** True if the column is the one currently being sorted on */
  isActiveSortColumn: PropTypes.bool,
  /** Indicates which sort icon should be displayed */
  direction: PropTypes.oneOf(["ASC", "DESC", "NONE"]),
};
export default SortIcon;
