import { Flex, InputDropdown, InputText, InputHidden } from "@heart/components";
import { isEmpty, omit, uniqueId } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import { RRule } from "rrule";

import RRuleCountInput from "./RRuleCountInput";
import RRuleMonthInput from "./RRuleMonthInput";
import RRuleWeekInput from "./RRuleWeekInput";
import RRuleYearInput from "./RRuleYearInput";

const IGNORED_OPTIONS = Object.freeze([
  "dtstart",
  "wkst",
  "byhour",
  "byminute",
  "bysecond",
]);

const FREQUENCY_MAP = Object.freeze({
  [RRule.MONTHLY]: "month(s)",
  [RRule.YEARLY]: "year(s)",
  [RRule.WEEKLY]: "week(s)",
  [RRule.DAILY]: "days(s)",
});

const newRRule = options => {
  const { interval, byweekday, bynmonthday } = options;
  const newOptions = omit(options, IGNORED_OPTIONS);

  // workaround for an apparent bug in rruleJS where, when nothing is passed in
  // for byweekday, it gets defaulted to `[4]` (i.e. every friday, or BYDAY=FR)
  if (isEmpty(byweekday)) {
    newOptions.byweekday = [];
  }

  // workaround for bug in rruleJS where, when `bymonthday` is -1, it will also
  // add a `bynmonthday` of `[-1]`, but then it appears to not actually have
  // handling for `bynmonthday` and raises an error trying to parse it.
  if (!isEmpty(bynmonthday)) {
    delete newOptions.bynmonthday;
  }

  if (!isEmpty(interval)) {
    newOptions.interval = parseInt(interval, 10);
  }
  return new RRule(newOptions, /* noCache = */ true);
};

export const newRRuleFromString = rruleString => {
  const rruleObject = RRule.fromString(rruleString);

  // use our internal method to consistently build a new RRule object the same
  // every time every time, whether from a string or from an options object
  return newRRule(rruleObject.origOptions);
};

/**
 * Allows you to configure a recurrence schedule (eg for a requirement template.)
 * Case Management Setup > Schedules > Edit > "Scheduled Requirement Templates"
 *   >
 */
class RRuleInput extends Component {
  constructor(props) {
    super(props);
    this.intervalId = uniqueId("interval-");

    const { rruleString } = props;

    if (rruleString) {
      const rruleObject = newRRuleFromString(rruleString);
      this.state = {
        rruleObject,
        options: omit(rruleObject.options, IGNORED_OPTIONS),
      };
    } else {
      const options = { freq: RRule.MONTHLY, interval: 1 };
      this.state = { options, rruleObject: newRRule(options) };
    }
  }

  componentDidMount() {
    if (this.props.rruleString !== this.state.rruleObject.toString()) {
      this.fireCallbacks();
    }
  }

  fireCallbacks = () => {
    const { onRRuleChange } = this.props;

    if (onRRuleChange) {
      const { rruleObject } = this.state;
      onRRuleChange(rruleObject.toString());
    }
  };

  onOptionsChange = update => {
    const { options: oldOptions } = this.state;
    const options = Object.assign({}, oldOptions, update);

    this.setState(
      { rruleObject: newRRule(options), options },
      this.fireCallbacks
    );
  };

  onFrequencyChange = value => {
    const options = { freq: parseInt(value, 10), interval: 1 };
    this.setState(
      { rruleObject: newRRule(options), options },
      this.fireCallbacks
    );
  };

  onIntervalChange = value => {
    const { options: oldOptions } = this.state;
    const options = Object.assign({}, oldOptions, { interval: value });

    this.setState(
      { rruleObject: newRRule(options), options },
      this.fireCallbacks
    );
  };

  render() {
    const { name } = this.props;
    const {
      rruleObject,
      options: { freq, interval },
    } = this.state;

    const stringRRule = rruleObject.toString().replace(/^RRULE:/, "");

    return (
      <div className="contains-inputs">
        <InputDropdown
          data-testid="frequency"
          label="Repeats"
          value={freq}
          onChange={this.onFrequencyChange}
          values={[
            { value: String(RRule.MONTHLY), label: "Monthly" },
            { value: String(RRule.YEARLY), label: "Yearly" },
            { value: String(RRule.WEEKLY), label: "Weekly" },
            { value: String(RRule.DAILY), label: "Daily" },
          ]}
        />
        <Flex row align="center">
          Every
          <InputText
            label="Every"
            hideLabel
            type="number"
            id={this.intervalId}
            value={String(interval)}
            onChange={this.onIntervalChange}
          />
          {`  ${FREQUENCY_MAP[freq]}`}
        </Flex>
        <If condition={freq === RRule.WEEKLY}>
          <RRuleWeekInput
            rruleObject={rruleObject}
            onRRuleChange={this.onOptionsChange}
          />
        </If>
        <If condition={freq === RRule.MONTHLY}>
          <RRuleMonthInput
            rruleObject={rruleObject}
            onRRuleChange={this.onOptionsChange}
          />
        </If>
        <If condition={freq === RRule.YEARLY}>
          <RRuleYearInput
            rruleObject={rruleObject}
            onRRuleChange={this.onOptionsChange}
          />
        </If>
        <RRuleCountInput
          rruleObject={rruleObject}
          onRRuleChange={this.onOptionsChange}
        />
        <If condition={name}>
          <InputHidden name={name} value={stringRRule} />
        </If>
      </div>
    );
  }
}

RRuleInput.propTypes = {
  name: PropTypes.string,
  rruleString: PropTypes.string,
  onRRuleChange: PropTypes.func,
};

export default RRuleInput;
