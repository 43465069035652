import PropTypes from "prop-types";
import { useRef, useEffect } from "react";

// This is a HOC implementation of the approach here:
// https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-with-react-hook-ba77c37c7e82

const HandlesOutsideClicks = ({ onOutsideClick, children }) => {
  const node = useRef();

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    onOutsideClick();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return <div ref={node}>{children}</div>;
};

HandlesOutsideClicks.propTypes = {
  onOutsideClick: PropTypes.func,
  children: PropTypes.node,
};

export default HandlesOutsideClicks;
