import { useQuery } from "@apollo/client";
import { compact, isEmpty, sortBy } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import AppRequirementReconciliation from "@graphql/queries/EndUserApplicationRequirementReconciliation.graphql";
import StageRequirementReconciliation from "@graphql/queries/StageRequirementReconciliation.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import { typeEq } from "@lib/graphqlHelpers";

import iconFormActive from "@sprockets/images/stage_icons/icon-form-active.svg";

import EndUserUploadTypeTableSection from "./EndUserUploadTypeTableSection";

// just a string, so this is good enough for sorting purposes.
const MAX_ISO8601_DATE = "9999-12-31";

const isUploadTypeRequirement = typeEq("UploadTypeRequirement");

/**
 * Given a requirement and a possibly falsy userAgencyProfileId, returns
 * true if the profile matches that on the requirement (or both are missing).
 */
const userAgencyProfileMatches = ({ requirement, userAgencyProfileId }) => {
  if (requirement.userAgencyProfile && userAgencyProfileId) {
    return requirement.userAgencyProfile.id === userAgencyProfileId.toString();
  }
  return !requirement.userAgencyProfile && !userAgencyProfileId;
};

/**
 * Given a slug and (possibly) a userAgencyProfileId, finds any fulfillment
 * that may match within the given requirementReconciliation.
 */
const fulfillmentFor = ({
  slug,
  userAgencyProfileId,
  requirementReconciliation,
}) =>
  requirementReconciliation.requirementFulfillments.find(({ requirement }) => {
    if (!isUploadTypeRequirement(requirement)) return false;
    if (requirement.uploadType.slug !== slug) return false;

    return userAgencyProfileMatches({ requirement, userAgencyProfileId });
  });

/**
 * Extracts the relevant fulfillments for a given stage and userAgencyProfile id
 * from the given requirementReconciliation.
 */
const stageFulfillments = ({
  applicationStage,
  userAgencyProfileIds = [undefined],
  requirementReconciliation,
}) =>
  compact(
    userAgencyProfileIds.flatMap(userAgencyProfileId =>
      applicationStage.uploadTypes.map(({ slug }) =>
        fulfillmentFor({
          slug,
          userAgencyProfileId,
          requirementReconciliation,
        })
      )
    )
  );

/**
 * Extracts the relevant ongoing fulfillments (those requiring expiration
 * dates)for given userAgencyProfiles from the given requirementReconciliation.
 *
 * Returns them sorted by date of action.
 */
const ongoingFulfillments = ({
  userAgencyProfileIds = [undefined],
  requirementReconciliation,
}) =>
  sortBy(
    compact(
      userAgencyProfileIds.flatMap(userAgencyProfileId =>
        requirementReconciliation.requirementFulfillments.filter(
          ({ requirement }) =>
            isUploadTypeRequirement(requirement) &&
            userAgencyProfileMatches({ requirement, userAgencyProfileId }) &&
            requirement.uploadType.requiresExpirationDate
        )
      )
    ),
    fulfillment => {
      if (!fulfillment.isFulfilled) {
        return DateTime.local().toISO(); // gimme now.
      }

      if (fulfillment.dateActionNeededBy) {
        return fulfillment.dateActionNeededBy;
      }

      return MAX_ISO8601_DATE;
    }
  );

// TODO ENG-8390 Remove `recommendedDisplayDate` which relates to hacky BIAS stuff
const TableHead = ({ recommendedDisplayDate }) => (
  <thead>
    <tr>
      <th>
        <img src={iconFormActive} className="offset-icon" alt="" aria-hidden />
        <span>
          {I18n.t("views.application_stages.normal.supporting_documents")}
        </span>
        <If condition={!isEmpty(recommendedDisplayDate)}>
          <span> - {recommendedDisplayDate}</span>
        </If>
      </th>
      <th className="right-align">
        {I18n.t("views.application_stages.normal.progress")}
      </th>
    </tr>
  </thead>
);
TableHead.propTypes = {
  recommendedDisplayDate: PropTypes.string.isRequired,
};

/**
 * Displays a table of upload type for end users. If applicationStageId is
 * present, we will display only the records for that stage. If it's missing,
 * we'll show all documents related to requirements with expiration dates.
 */
const EndUserUploadTypeTable = ({
  applicationId,
  applicationStageId,
  recommendedDisplayDate,
  sections,
}) => {
  const query = applicationStageId
    ? StageRequirementReconciliation
    : AppRequirementReconciliation;

  const { loading, error, data } = useQuery(query, {
    variables: { applicationId, applicationStageId },
  });

  if (loading || error) {
    return false;
  }

  const { applicationStage, applicationUploadTypeRequirementReconciliation } =
    data;

  const sectionMapping = sections
    .map(({ i18nRoleKey, isHeaderHidden, userAgencyProfileIds }) => {
      let sectionFulfillments;

      if (applicationStageId) {
        sectionFulfillments = stageFulfillments({
          applicationStage,
          userAgencyProfileIds,
          requirementReconciliation:
            applicationUploadTypeRequirementReconciliation,
        });
      } else {
        sectionFulfillments = ongoingFulfillments({
          userAgencyProfileIds,
          requirementReconciliation:
            applicationUploadTypeRequirementReconciliation,
        });
      }

      return {
        i18nRoleKey,
        isHeaderHidden,
        sectionFulfillments,
      };
    })
    .filter(({ sectionFulfillments }) => !isEmpty(sectionFulfillments));

  if (isEmpty(sectionMapping)) {
    // if there aren't any requirements for anyone, don't even render the
    // table header.
    return false;
  }

  return (
    <table className="space-above-5 space-below-5 navigation-table">
      <TableHead {...{ recommendedDisplayDate }} />
      <tbody>
        {sectionMapping.map(sectionProps => (
          <EndUserUploadTypeTableSection
            key={sectionProps.i18nRoleKey}
            applicationId={applicationId}
            {...sectionProps}
          />
        ))}
      </tbody>
    </table>
  );
};

EndUserUploadTypeTable.propTypes = {
  applicationId: PropTypes.number.isRequired,
  applicationStageId: PropTypes.number,
  recommendedDisplayDate: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      i18nRoleKey: PropTypes.string.isRequired,
      isHeaderHidden: PropTypes.bool,
      userAgencyProfileIds: PropTypes.arrayOf(BintiPropTypes.ID),
    })
  ),
};

export default EndUserUploadTypeTable;
