import { useMutation } from "@apollo/client";
import {
  Actions,
  InputFilterable,
  InputDate,
  PageContainer,
  SurfaceForm,
  InputTextarea,
  toast,
} from "@heart/components";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import flattenErrorMessages from "@utils/flattenErrorMessages";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import CreateOrUpdatePermanencyGoal from "@graphql/mutations/CreateOrUpdatePermanencyGoal.graphql";
import AgencyPlacementNameAutocomplete from "@graphql/queries/AgencyPlacementNameAutocomplete.graphql";

import preventDefault from "@lib/preventDefault";

import {
  PERMANENCY_GOAL_EXITED_CARE_REASONS,
  PERMANENCY_GOAL_STATUSES,
} from "@root/constants";

const { T, t } = translationWithRoot("permanency_goal_form");

const ALLOWED_GOAL_STATUS_TRANSITIONS_FOR_ACTIVE_PLAN = {
  active: ["active", "finalized", "disrupted", "historical"],
  historical: ["active", "finalized", "disrupted", "historical"],
};

const ALLOWED_GOAL_STATUS_TRANSITIONS_FOR_FINALIZED_PLAN = {
  finalized: ["active", "finalized", "disrupted"],
  historical: [],
};

const ALLOWED_GOAL_STATUS_TRANSITIONS_FOR_DISRUPTED_PLAN = {
  disrupted: [],
  historical: [],
};

const ALLOWED_PLAN_STATUS_TRANSITIONS = {
  active: ALLOWED_GOAL_STATUS_TRANSITIONS_FOR_ACTIVE_PLAN,
  finalized: ALLOWED_GOAL_STATUS_TRANSITIONS_FOR_FINALIZED_PLAN,
  disrupted: ALLOWED_GOAL_STATUS_TRANSITIONS_FOR_DISRUPTED_PLAN,
};

export const determineStatusOptions = ({ planStatus, goalStatus }) => {
  const allStatuses = Object.entries(PERMANENCY_GOAL_STATUSES).map(
    ([value, label]) => ({
      value,
      label,
    })
  );

  // If there's no planStatus, that means it's a new goal, so we show all options
  if (isNil(planStatus)) {
    return allStatuses;
  }

  const allowedStatuses =
    ALLOWED_PLAN_STATUS_TRANSITIONS[planStatus][goalStatus];

  // if the planStatus/goalStatus combo isn't represented by our constants,
  // we show all status options in the dropdown and let the backend validation
  // handle the rest
  if (allowedStatuses === undefined) {
    return allStatuses;
  }

  return allowedStatuses.map(status => ({
    value: status,
    label: PERMANENCY_GOAL_STATUSES[status],
  }));
};

/**
 * UI for adding or editing a Permanency Goal.
 *   Child Data page > Permanency Plan table > "Add Goal" or "Edit" row action
 */
const PermanencyGoalForm = ({
  permanencyGoal,
  isPersisted,
  redirectPath,
  agencyPlacementName,
  planStatus,
}) => {
  const [goalAttributes, setGoalAttributes] = useState({
    id: permanencyGoal.id,
    agencyPlacementId: permanencyGoal.agency_placement_id,
    goal: permanencyGoal.goal,
    goalOtherDescription: permanencyGoal.goal_other_description,
    status: permanencyGoal.status,
    finalizedDate: permanencyGoal.finalized_date,
    disruptedDate: permanencyGoal.disrupted_date,
    markedActiveDate: permanencyGoal.marked_active_date,
    markedHistoricalDate: permanencyGoal.marked_historical_date,
    adoptionsPlacementAgreementSignedDate:
      permanencyGoal.adoptions_placement_agreement_signed_date,
  });
  const [placementName, setPlacementName] = useState(agencyPlacementName);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createOrUpdatePermanencyGoal] = useMutation(
    CreateOrUpdatePermanencyGoal,
    {
      onError: err => {
        toast.negative({
          title: t("form_submission_error"),
          message: flattenErrorMessages(err),
        });
        setIsSubmitting(false);
      },
      onCompleted: () => {
        window.location = redirectPath;
      },
    }
  );

  const onSubmit = preventDefault(() => {
    setIsSubmitting(true);

    if (["active", "historical"].includes(goalAttributes.status)) {
      goalAttributes.finalizedDate = null;
      goalAttributes.disruptedDate = null;
    }

    if (goalAttributes.status === "finalized") {
      goalAttributes.disruptedDate = null;
    }

    const variables = {
      goalAttributes,
      childId: permanencyGoal.child_id,
    };

    createOrUpdatePermanencyGoal({
      variables,
    });
  });

  const valuesFromAutocompleteResponse = response =>
    (response?.agencyPlacementMatches || []).map(({ id, displayName }) => ({
      value: id,
      label: displayName,
    }));

  const statusOptions = determineStatusOptions({
    planStatus,
    goalStatus: permanencyGoal.status,
  });

  return (
    <PageContainer>
      <SurfaceForm
        title={isPersisted ? t("update_goal") : t("add_goal")}
        onSubmit={onSubmit}
        actions={
          <Actions
            primaryDisabled={false}
            isSubmitting={isSubmitting}
            primarySubmittingText={t("submitting_text")}
            primaryText={<T t={isPersisted ? "update_goal" : "add_goal"} />}
            cancelHref={redirectPath}
            cancelText={t("cancel_text")}
          />
        }
      >
        <InputFilterable
          label={t("goal")}
          required
          options={Object.entries(PERMANENCY_GOAL_EXITED_CARE_REASONS).map(
            ([value, label]) => ({ value, label })
          )}
          value={
            isNil(goalAttributes.goal)
              ? null
              : {
                  value: goalAttributes.goal,
                  label:
                    PERMANENCY_GOAL_EXITED_CARE_REASONS[goalAttributes.goal],
                }
          }
          onChange={({ value: goal }) =>
            setGoalAttributes({ ...goalAttributes, goal })
          }
        />
        <If condition={goalAttributes.goal === "exit_other"}>
          <InputTextarea
            label={t("goal_other_description")}
            value={permanencyGoal.goal_other_description}
            onChange={goalOtherDescription => {
              setGoalAttributes({
                ...goalAttributes,
                goalOtherDescription,
              });
            }}
          />
        </If>
        <InputFilterable
          label={t("permanency_goal_status")}
          required
          // TODO ENG-14226 i18n
          options={statusOptions}
          disabled={statusOptions.length === 0}
          value={
            isNil(goalAttributes.status)
              ? null
              : {
                  value: goalAttributes.status,
                  label: PERMANENCY_GOAL_STATUSES[goalAttributes.status],
                }
          }
          onChange={({ value: status }) =>
            setGoalAttributes({
              ...goalAttributes,
              status,
            })
          }
        />
        <InputDate
          label={t("marked_active_date")}
          value={goalAttributes.markedActiveDate}
          onChange={markedActiveDate => {
            setGoalAttributes({
              ...goalAttributes,
              markedActiveDate,
            });
          }}
        />
        <If condition={goalAttributes.status === "historical"}>
          <InputDate
            label={t("marked_historical_date")}
            value={goalAttributes.markedHistoricalDate}
            onChange={markedHistoricalDate =>
              setGoalAttributes({
                ...goalAttributes,
                markedHistoricalDate,
              })
            }
          />
        </If>
        <InputAutocompleteGraphQL
          label={t("permanency_placement")}
          query={AgencyPlacementNameAutocomplete}
          valuesFromResponse={valuesFromAutocompleteResponse}
          value={{
            value: JSON.stringify(goalAttributes.agencyPlacementId),
            label: placementName,
          }}
          onChange={({ value: id, label: displayName }) => {
            setGoalAttributes({
              ...goalAttributes,
              agencyPlacementId: parseInt(id, 10),
            });
            setPlacementName(displayName);
          }}
        />
        <If
          condition={["finalized", "disrupted"].includes(goalAttributes.status)}
        >
          <InputDate
            label={t("finalized_date")}
            value={goalAttributes.finalizedDate}
            onChange={finalizedDate =>
              setGoalAttributes({
                ...goalAttributes,
                finalizedDate,
              })
            }
            required
          />
        </If>
        <If condition={goalAttributes.status === "disrupted"}>
          <InputDate
            label={t("disrupted_date")}
            value={goalAttributes.disruptedDate}
            onChange={disruptedDate =>
              setGoalAttributes({
                ...goalAttributes,
                disruptedDate,
              })
            }
            required
          />
        </If>
        <If condition={goalAttributes.goal === "adoption_finalized"}>
          <InputDate
            label={t("adoption_placement_agreement_signed_date")}
            value={goalAttributes.adoptionsPlacementAgreementSignedDate}
            onChange={adoptionsPlacementAgreementSignedDate =>
              setGoalAttributes({
                ...goalAttributes,
                adoptionsPlacementAgreementSignedDate,
              })
            }
          />
        </If>
      </SurfaceForm>
    </PageContainer>
  );
};

PermanencyGoalForm.propTypes = {
  redirectPath: PropTypes.string.isRequired,
  permanencyGoal: PropTypes.shape({
    id: PropTypes.number,
    goal: PropTypes.string,
    goal_other_description: PropTypes.string,
    status: PropTypes.string,
    marked_active_date: PropTypes.string,
    marked_historical_date: PropTypes.string,
    finalized_date: PropTypes.string,
    disrupted_date: PropTypes.string,
    adoptions_placement_agreement_signed_date: PropTypes.string,
    child_id: PropTypes.number,
    agency_placement_id: PropTypes.number,
  }).isRequired,
  agencyPlacementName: PropTypes.string,
  isPersisted: PropTypes.bool,
  planStatus: PropTypes.string,
};

export default PermanencyGoalForm;
