import { times } from "lodash";
import { useState } from "react";

// A helper function to generate ids for elements that need them.
// Helps us avoid using <input> tags nested in <label>s (for example).

// based on https://gist.github.com/gordonbrander/2230317
const generateId = () => `_${Math.random().toString(36).substring(2, 11)}`;

/**
 * Helper to create a generator for use in useState to keep ids consistent
 * across re-renders of a single component.
 *
 * Example:
 *
 * const MyAwesomeInput = () => {
 *   const [labels] = useState(generateIds("name", "phoneNumber"));
 *
 *   return (
 *     <div>
 *        <div>
 *          <label htmlFor={labels.name}>Name</label>
 *          <input id={labels.name} />
 *        </div>
 *        <div>
 *          <label htmlFor={labels.phoneNumber}>Phone Number</label>
 *          <input id={labels.phoneNumber} />
 *        </div>
 *     </div>
 *   )
 * }
 */
export const generateIds =
  (...idNames) =>
  () =>
    idNames.reduce((acc, id) => ({ [id]: generateId(), ...acc }), {});

/**
 * React hook to keep a generated id in state so it doesn't change on every
 * render.
 */
export const useGeneratedId = () => {
  const [id] = useState(generateId);

  return id;
};

/**
 * React hook to keep `count` number of generated ids in state so they doesn't
 * change on every render. Returns an array of the ids.
 */
export const useGeneratedIds = count => {
  const [ids] = useState(() => times(count, generateId));

  return ids;
};

export default generateId;
