import { Flex, Link } from "@heart/components";
import PropTypes from "prop-types";

import { policy } from "@lib/graphqlHelpers";

import DeleteRequirementLink from "./DeleteRequirementLink";

const UploadTypeRequirementTitle = ({ requirement, title }) => {
  const { uploadType } = requirement;

  return (
    <Flex justify="space-between">
      <span>{title}</span>

      <If condition={policy(uploadType).mayShow()}>
        <Link href={uploadType.viewLink}> ({uploadType.slug})</Link>
      </If>

      <If condition={policy(requirement).mayDestroy()}>
        <DeleteRequirementLink requirement={requirement} />
      </If>
    </Flex>
  );
};

UploadTypeRequirementTitle.propTypes = {
  requirement: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default UploadTypeRequirementTitle;
