import { Upload } from "@heart/components";
import * as HeartTable from "@heart/components/table/Table";
import classNames from "classnames";
import { get } from "lodash";
import PropTypes from "prop-types";

import styles from "./TableCell.module.scss";
import tableCommonStyles from "./tableCommon.module.scss";

const { Td } = HeartTable;

/** A table cell!  This component gets the cell's content out of the
 * row's data and creates a `<Td>` out of it.  It also renders an
 * `Upload` component if instructed to do so.
 */
const TableCell = ({
  rowData,
  cell,
  onUpload,
  iconOnlyUpload,
  removeRightPadding,
}) => {
  let content;
  if (typeof cell === "string") {
    content = get(rowData, cell);
  } else {
    content = cell(rowData);
  }
  if (onUpload) {
    content = (
      <Upload onUpload={onUpload(rowData)} iconOnly={iconOnlyUpload}>
        <div
          className={classNames(styles.uploadCellContents, {
            [styles.removeRightPadding]: removeRightPadding,
          })}
        >
          {content}
        </div>
      </Upload>
    );
  }
  return (
    <Td
      className={classNames(tableCommonStyles.tableCell, {
        [styles.uploadCell]: onUpload,
        [styles.removeRightPadding]: removeRightPadding,
      })}
    >
      {content}
    </Td>
  );
};

TableCell.propTypes = {
  /** An object containing the data for this row in the table */
  rowData: PropTypes.object.isRequired,
  /** An accessor function to get this cell's data from `rowData`, or
   *  a string for simple property lookup */
  cell: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  /** Function to be called when the user uploads into this cell.  If provided,
   * this cell becomes an `Upload` component and the below props are relevant */
  onUpload: PropTypes.func,
  /** Whether the dropzone for this column should be icon-only */
  iconOnlyUpload: PropTypes.bool,
  /** remove right side padding from the table cell */
  removeRightPadding: PropTypes.bool,
};

export default TableCell;
