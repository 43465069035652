import { entries } from "lodash";
import PropTypes from "prop-types";

import styles from "../../BintiSign.module.scss";
import SignatureLocation from "./SignatureLocation";

/**
 * Table of signature locations, which show the pages which require the user's
 * signature, one line per location.
 */
const SignatureLocations = ({
  signatureLocationsForRoleByPage,
  pageRefs,
  scrollTo,
}) => (
  <div className={styles.signatureLocations}>
    {entries(signatureLocationsForRoleByPage).map(([pageNumber, locations]) => (
      <SignatureLocation
        key={`signature-location-${pageNumber}`}
        scrollTo={scrollTo}
        pageRef={pageRefs[pageNumber - 1]}
        locations={locations}
      />
    ))}
  </div>
);

SignatureLocations.propTypes = {
  scrollTo: PropTypes.func.isRequired,
  signatureLocationsForRoleByPage: PropTypes.object.isRequired,
  pageRefs: PropTypes.array,
};

export default SignatureLocations;
