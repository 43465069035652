import { InputCheckbox, InputHidden } from "@heart/components";
import PropTypes from "prop-types";
import { useState, Fragment } from "react";

import NestedEnumFormInput from "@components/inputs/nested_enum/NestedEnumFormInput";

const ConfidentialLicenseWrapper = ({
  confidentialInput,
  confidentialOtherReasonInput,
  confidentialReasonInput,
  confidentialReasonOptions,
  isConfidential,
  selectedConfidentialReason,
  selectedConfidentialReasonOtherExplanation,
}) => {
  const [confidentialLicense, setConfidentialLicense] =
    useState(isConfidential);
  const [selectedReason, setSelectedReason] = useState(
    selectedConfidentialReason
  );
  const [otherExplanation, setotherExplanation] = useState(
    selectedConfidentialReasonOtherExplanation
  );

  const updateConfidentialLicense = () => {
    // confidential is already checked, user is unchecking box
    if (confidentialLicense === true) {
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          I18n.t("views.common.confidential_license.unflag_warning")
        )
      ) {
        setConfidentialLicense(false);
        setSelectedReason("");
        setotherExplanation("");
      }
      // confidential is not checked, user is checking box
    } else if (
      // eslint-disable-next-line no-alert
      window.confirm(
        I18n.t("views.common.confidential_license.flag_warning", {
          role1: I18n.t(
            "activerecord.enums.agency_admin_assignment.agency_roles.admin"
          ),
          role2: I18n.t(
            "activerecord.enums.agency_admin_assignment.agency_roles.approvals"
          ),
        })
      )
    ) {
      setConfidentialLicense(true);
    } else {
      setConfidentialLicense(false);
    }
  };

  return (
    <div className="contains-inputs" data-testid="confidential-license-wrapper">
      {/* adding default hidden input to always send value */}
      <InputHidden name={confidentialInput} value="false" />
      <InputHidden name={confidentialReasonInput} value="" />
      <InputHidden name={confidentialOtherReasonInput} value="" />
      <InputCheckbox
        value={confidentialLicense === true}
        onChange={updateConfidentialLicense}
        htmlValue="true"
        name={confidentialInput}
        label={I18n.t(
          "views.common.confidential_license.applicants_should_be_considered_confidential"
        )}
        description={I18n.t(
          "views.common.confidential_license.applicants_should_be_confidential_hint_text"
        )}
      />
      {confidentialLicense && (
        <Fragment>
          <NestedEnumFormInput
            options={confidentialReasonOptions}
            label={I18n.t(
              "views.common.confidential_license.reason_for_confidentiality"
            )}
            optionInputName={confidentialReasonInput}
            detailsInputName={confidentialOtherReasonInput}
            placeholderLabel={I18n.t(
              "views.common.confidential_license.placeholder_text"
            )}
            initialSelectedOptionPath={selectedReason}
            initialSelectedOptionDetails={otherExplanation}
            isRequired={true}
          />
        </Fragment>
      )}
    </div>
  );
};

ConfidentialLicenseWrapper.propTypes = {
  confidentialInput: PropTypes.string,
  confidentialOtherReasonInput: PropTypes.string,
  confidentialReasonInput: PropTypes.string,
  confidentialReasonOptions: PropTypes.array,
  isConfidential: PropTypes.bool,
  selectedConfidentialReason: PropTypes.string,
  selectedConfidentialReasonOtherExplanation: PropTypes.string,
};

export default ConfidentialLicenseWrapper;
