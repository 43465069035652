import PropTypes from "prop-types";
import { Component } from "react";

import { DocumentsClientContext } from "../../clients/DocumentsClient";
import AwaitingPartial from "./AwaitingPartial";
import DownloadBlankFormLink from "./DownloadBlankFormLink";
import DownloadPartialLink from "./DownloadPartialLink";
import GeneratePartialLink from "./GeneratePartialLink";

class PartialLink extends Component {
  render() {
    const { doc, backgroundLoading } = this.props;

    if (doc.blankFormAvailable) {
      return <DownloadBlankFormLink doc={doc} />;
    }

    if (doc.partialAvailable) {
      return <DownloadPartialLink doc={doc} />;
    }

    if (doc.canCreatePartial) {
      return (
        <DocumentsClientContext.Consumer>
          {client => (
            <GeneratePartialLink {...{ doc, backgroundLoading, client }} />
          )}
        </DocumentsClientContext.Consumer>
      );
    }

    if (doc.creatingPartial) {
      return (
        <DocumentsClientContext.Consumer>
          {client => (
            <AwaitingPartial {...{ doc, backgroundLoading, client }} />
          )}
        </DocumentsClientContext.Consumer>
      );
    }

    return false;
  }
}

PartialLink.propTypes = {
  doc: PropTypes.object.isRequired,
  backgroundLoading: PropTypes.bool.isRequired,
};

export default PartialLink;
