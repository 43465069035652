import { isEmpty } from "lodash";

import { formatPhoneNumberIntl } from "@lib/phoneNumbers";

import determineUserAgencyProfile from "./determineUserAgencyProfile";

const findEmailAndPhoneNumber = ({ agencyId, person }) => {
  const { userAgencyProfiles, phoneNumber, email } = person;
  if (userAgencyProfiles) {
    const userAgencyProfile = determineUserAgencyProfile({
      agencyId,
      person,
    });

    const primaryPhoneNumbers = userAgencyProfile.phoneNumbers.filter(
      ({ primary }) => primary
    );

    const primaryNumber = isEmpty(primaryPhoneNumbers)
      ? ""
      : primaryPhoneNumbers[0].phoneNumber;

    const emailAddress = userAgencyProfile.email;
    return {
      phoneNumber: formatPhoneNumberIntl(primaryNumber),
      email: emailAddress,
    };
  }
  return {
    phoneNumber: formatPhoneNumberIntl(phoneNumber),
    email,
  };
};

export default findEmailAndPhoneNumber;
