import { Link } from "@heart/components";
import I18n from "i18n-js";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import useCollapse from "react-collapsed";

/**
 * Component that wraps another React component and displays a
 * Show Less/More button to toggle between truncated and non-
 * truncated views
 */
const ExpandableContent = ({ children, collapsedHeight = 100, onExpanded }) => {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    collapsedHeight,
  });

  if (!children) return null;

  return (
    <div>
      <section {...getCollapseProps()}>{children}</section>
      <Link
        {...getToggleProps({
          onClick: () => {
            if (!isNil(onExpanded)) onExpanded();
            else setExpanded(prevExpanded => !prevExpanded);
          },
        })}
      >
        {isExpanded
          ? I18n.t("views.common.show_less")
          : I18n.t("views.common.show_more")}
      </Link>
    </div>
  );
};

ExpandableContent.propTypes = {
  /** Content to be expanded or collapsed */
  children: PropTypes.node,
  /** Max height of content in pixels */
  collapsedHeight: PropTypes.number,
  /** Custom functionality for the "Show More" button */
  onExpanded: PropTypes.func,
};

export default ExpandableContent;
