import PropTypes from "prop-types";
import React from "react";

import SurfaceBase from "../surface/SurfaceBase";
import InputContainer from "./InputContainer";

/**
 * A visually-separate section for creating an extra level of
 * information hierarchy atop a Surface
 */
const SurfaceFormGroup = ({
  title,
  subtitle,
  secondary,
  hideTitle,
  children,
  "data-testid": testId,
}) => (
  <SurfaceBase
    rootElement="div"
    variant="outline"
    title={title}
    subtitle={subtitle}
    secondary={secondary}
    hideTitle={hideTitle}
    data-testid={testId}
  >
    <InputContainer>{children}</InputContainer>
  </SurfaceBase>
);

SurfaceFormGroup.propTypes = {
  /** Title of the section, displayed in the top left. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Whether or not the title is hidden.  If the title is hidden and there is no
   * content in `secondary`, the entire header will be hidden. */
  hideTitle: PropTypes.bool,
  /** Subtitle of the section, displayed in the top left */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Secondary content - usually a call-to-action, displayed in the top right */
  secondary: PropTypes.node,
  /** Section contents */
  children: PropTypes.node.isRequired,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default SurfaceFormGroup;
