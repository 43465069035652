import { ApolloConsumer } from "@apollo/client";
import { InputFilterableAsync } from "@heart/components";
import PropTypes from "prop-types";

import FormAutocomplete from "@graphql/queries/FormAutocomplete.graphql";

const FormSelect = ({ name, label = "Form" }) => (
  <ApolloConsumer>
    {client => {
      const loadOptions = query => {
        if (query.length < 2) return [];

        return client
          .query({
            query: FormAutocomplete,
            variables: { query },
          })
          .then(({ data: { formAutocomplete } }) =>
            formAutocomplete.map(({ name: formName, slug }) => ({
              value: slug,
              label: `${formName} (${slug})`,
            }))
          );
      };

      return (
        <InputFilterableAsync
          label={label}
          cacheOptions
          {...{ loadOptions, name }}
        />
      );
    }}
  </ApolloConsumer>
);

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default FormSelect;
