/* This is all Binti admin side and fairly tricky, so leave in the warnings */

/* eslint-disable no-console */
import { useQuery, useMutation } from "@apollo/client";
import { last } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";

import TopScrollbar from "@components/shared/TopScrollbar";

import SetApplicationStageTemplateFormSlugs from "@graphql/mutations/SetApplicationStageTemplateFormSlugs.graphql";
import SetApplicationStageTemplateUploadTypeSlugs from "@graphql/mutations/SetApplicationStageTemplateUploadTypeSlugs.graphql";
import ApplicationTemplateQuery from "@graphql/queries/ApplicationTemplate.graphql";

import { reorder, insert } from "../../dndHelpers";
import AllForms from "./AllForms";
import AllUploadTypes from "./AllUploadTypes";
import ApplicationStageTemplate from "./ApplicationStageTemplate";
import styles from "./ApplicationTemplateDesigner.module.scss";
import ApplicationTemplateDesignerViewOptions from "./ApplicationTemplateDesignerViewOptions";
import RequirementDisplayContext from "./RequirementDisplayContext";

const stageTemplateDroppableId = (stageTemplate, type) =>
  `stage-template-${type}:${stageTemplate.id}`;

//
// Root component for the Application template designer feature.
//
// The designer feature is intended to enable the following:
//
// 1. See all forms and upload types associated as requirements for the
//    application template. (via AllForms and AllUploadTypes components.)
// 2. Of the forms and upload types associated with the application template,
//    see which are displayed to applicants and/or other adults via a stage.
// 3. Add and remove forms and upload types from the application template
//    requirements.
// 4. Add and remove forms and upload types from applicant and/or other adult
//    stage templates.
//
// Adding and arranging forms within stages is done via drag and drop, but
// children components manage some of the draggable options and drop targets
// (droppables) so this component manages the root drag and drop context that
// is shared across all those components.
//
const ApplicationTemplateDesigner = ({ slug }) => {
  const { loading, error, data } = useQuery(ApplicationTemplateQuery, {
    variables: { slug },
  });
  const [setApplicationStageTemplateFormSlugs] = useMutation(
    SetApplicationStageTemplateFormSlugs
  );

  const [setApplicationStageTemplateUploadTypeSlugs] = useMutation(
    SetApplicationStageTemplateUploadTypeSlugs
  );

  // controls which role of stage layouts are shown
  const [stageRole, setStageRole] = useState("applicant");

  //
  // display options
  //
  const [isCompact, setIsCompact] = useState(false);
  const [showOnlyNormalStages, setShowOnlyNormalStages] = useState(false);
  const [showUploadTypesInStages, setShowUploadTypesInStages] = useState(true);
  const [showFormsInStages, setShowFormsInStages] = useState(true);

  if (loading) {
    return "loading";
  }

  if (error) {
    return <pre>{error.toString()}</pre>;
  }

  const { applicationTemplate } = data;

  const slugFromDraggableId = draggableId => last(draggableId.split(":"));
  const stageTemplateFromDroppableId = droppableId => {
    const [, stageTemplateId] = droppableId.split(":", 2);

    return applicationTemplate.applicationStageTemplates.find(
      ({ id }) => id === stageTemplateId
    );
  };

  const onFormDragToStage = ({ destination, draggableId }) => {
    // From the set of all forms...
    if (destination.droppableId.startsWith("stage-template-form:")) {
      // To a stage template
      const stageTemplate = stageTemplateFromDroppableId(
        destination.droppableId
      );
      const formSlug = slugFromDraggableId(draggableId);

      if (stageTemplate.forms.find(u => u.slug === formSlug)) {
        console.warn("Form already exists in stage", formSlug);
        return;
      }

      const formSlugs = insert(
        stageTemplate.forms.map(f => f.slug),
        destination.index,
        formSlug
      );
      setApplicationStageTemplateFormSlugs({
        variables: { applicationStageTemplateId: stageTemplate.id, formSlugs },
      });
    } else {
      console.warn("Attempted to drop form on non-form area", draggableId);
    }
  };

  const onUploadTypeDragToStage = ({ destination, draggableId }) => {
    // From the set of all upload types...
    if (destination.droppableId.startsWith("stage-template-uploadType:")) {
      // To a stage template
      const stageTemplate = stageTemplateFromDroppableId(
        destination.droppableId
      );
      const uploadTypeSlug = slugFromDraggableId(draggableId);

      if (stageTemplate.uploadTypes.find(u => u.slug === uploadTypeSlug)) {
        console.warn("Upload type already exists in stage", uploadTypeSlug);
        return;
      }

      const uploadTypeSlugs = insert(
        stageTemplate.uploadTypes.map(u => u.slug),
        destination.index,
        uploadTypeSlug
      );

      setApplicationStageTemplateUploadTypeSlugs({
        variables: {
          applicationStageTemplateId: stageTemplate.id,
          uploadTypeSlugs,
        },
      });
    } else {
      console.warn(
        "Attempted to drop uploadType on non-upload type area",
        draggableId
      );
    }
  };

  const onReorderDrag = ({ source, destination }) => {
    // Reordering within a single stage template
    const stageTemplate = stageTemplateFromDroppableId(destination.droppableId);

    if (destination.droppableId.startsWith("stage-template-form:")) {
      const formSlugs = reorder(
        stageTemplate.forms.map(f => f.slug),
        source.index,
        destination.index
      );

      setApplicationStageTemplateFormSlugs({
        variables: { applicationStageTemplateId: stageTemplate.id, formSlugs },
      });
    } else if (
      destination.droppableId.startsWith("stage-template-uploadType:")
    ) {
      const uploadTypeSlugs = reorder(
        stageTemplate.uploadTypes.map(u => u.slug),
        source.index,
        destination.index
      );

      setApplicationStageTemplateUploadTypeSlugs({
        variables: {
          applicationStageTemplateId: stageTemplate.id,
          uploadTypeSlugs,
        },
      });
    }
  };

  const onDragEnd = ({ source, destination, draggableId }) => {
    if (!destination) return;

    if (source.droppableId === "forms") {
      onFormDragToStage({ destination, draggableId });
    } else if (source.droppableId === "uploadTypes") {
      onUploadTypeDragToStage({ destination, draggableId });
    } else if (source.droppableId === destination.droppableId) {
      onReorderDrag({ source, destination });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <RequirementDisplayContext.Provider value={{ isCompact }}>
        <div className={styles.designerFrame}>
          <div className={styles.allOfATypePanel}>
            <AllUploadTypes {...{ applicationTemplate, stageRole }} />
            <AllForms
              defaultIsCollapsed
              {...{ applicationTemplate, stageRole }}
            />
          </div>
          <TopScrollbar>
            <ApplicationTemplateDesignerViewOptions
              {...{
                stageRole,
                setStageRole,
                isCompact,
                setIsCompact,
                showOnlyNormalStages,
                setShowOnlyNormalStages,
                showUploadTypesInStages,
                setShowUploadTypesInStages,
                showFormsInStages,
                setShowFormsInStages,
              }}
            />
            <div className={styles.applicantStagesWrapper}>
              <div className={styles.applicantStages}>
                {applicationTemplate.applicationStageTemplates
                  .filter(({ role }) => role === stageRole)
                  .filter(
                    ({ type }) =>
                      type === "NormalStageTemplate" || !showOnlyNormalStages
                  )
                  .map(applicationStageTemplate => (
                    <ApplicationStageTemplate
                      key={applicationStageTemplate.id}
                      id={applicationStageTemplate.id}
                      uploadTypeDroppableId={stageTemplateDroppableId(
                        applicationStageTemplate,
                        "uploadType"
                      )}
                      formDroppableId={stageTemplateDroppableId(
                        applicationStageTemplate,
                        "form"
                      )}
                      applicationTemplate={applicationTemplate}
                      showFormsInStages={showFormsInStages}
                      showUploadTypesInStages={showUploadTypesInStages}
                    />
                  ))}
              </div>
            </div>
          </TopScrollbar>
        </div>
      </RequirementDisplayContext.Provider>
    </DragDropContext>
  );
};

ApplicationTemplateDesigner.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default ApplicationTemplateDesigner;
