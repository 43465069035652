import I18n from "i18n-js";

const dateFilterValues = [
  [I18n.t("javascript.components.data_table.date_range.this_week"), "thisWeek"],
  [I18n.t("javascript.components.data_table.date_range.next_week"), "nextWeek"],
  [
    I18n.t("javascript.components.data_table.date_range.this_month"),
    "thisMonth",
  ],
  [
    I18n.t("javascript.components.data_table.date_range.next_month"),
    "nextMonth",
  ],
];

export default dateFilterValues;
