import {
  Text,
  Flex,
  HeartTable,
  InputRadioGroup,
  Surface,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const { T } = translationWithRoot(
  "placement_preferences.characteristics_open_to_table"
);

const CharacteristicsOpenToTable = ({
  characteristicsOptions,
  characteristicsOpenTo,
  onCharacteristicChanged,
  title,
  subtitle,
}) => (
  <Surface title={title} subtitle={subtitle}>
    <Table>
      <Thead>
        <Tr>
          <Th>
            <Text textStyle="supporting-100">
              <T t="characteristics_header" />
            </Text>
          </Th>
          <Th>
            <Text textStyle="supporting-100">
              <T t="settings_header" />
            </Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {characteristicsOptions.map((slug, index) => (
          <Tr key={slug}>
            <Td>
              <Text textStyle="body-100">{`${index + 1}) ${I18n.t(
                `activerecord.enums.common.child_characteristics.${slug}`
              )}`}</Text>
            </Td>
            <Td>
              <Flex row justify="end" gap="300">
                <InputRadioGroup
                  hideLabel
                  id={slug}
                  name={`child_medical_behavioral_characteristics_open_to[${slug}]`}
                  label={I18n.t(
                    `activerecord.enums.common.child_characteristics.${slug}`
                  )}
                  values={[
                    {
                      value: "true",
                      label: I18n.t(
                        "javascript.components.placement_preferences.characteristics_open_to_table.setting_yes"
                      ),
                    },
                    {
                      value: "false",
                      label: I18n.t(
                        "javascript.components.placement_preferences.characteristics_open_to_table.setting_no"
                      ),
                    },
                  ]}
                  orientation="row"
                  onChange={value => onCharacteristicChanged(slug, value)}
                  value={String(characteristicsOpenTo[slug])}
                />
              </Flex>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Surface>
);

CharacteristicsOpenToTable.propTypes = {
  characteristicsOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCharacteristicChanged: PropTypes.func.isRequired,
  characteristicsOpenTo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default CharacteristicsOpenToTable;
