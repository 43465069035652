import PropTypes from "prop-types";

import styles from "../MobileStyling.module.scss";

const GenericCell = props => {
  const { header = "", value = "" } = props;
  return (
    <div className={styles.cell}>
      <div className={styles.smallHeader}>{header}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

GenericCell.propTypes = {
  header: PropTypes.string,
  value: PropTypes.string,
};

export default GenericCell;
