import { Button, Icons, InputHidden } from "@heart/components";
import {
  debounce,
  filter,
  find,
  findLast,
  flatten,
  includes,
  isEmpty,
  last,
  merge,
  split,
  toString,
  values,
} from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState, useEffect } from "react";

import preventDefault from "@lib/preventDefault";

import "../../lib/polyfills/submitterPolyfill";
import Child from "../../models/Child";
import Csrf from "../Csrf";
import Relationship from "./Relationship";

const Relationships = ({
  errors,
  agencyId,
  application: {
    id: applicationId,
    childAssociated,
    applicantName,
    status: applicationStatus,
  },
  existingChildren = [],
  formAction,
  agencyPlacementId,
}) => {
  const [isRelated, setRelationship] = useState(toString(childAssociated));
  const [disabledAddChild, setDisabledAddChild] = useState(false);
  const [unmountedChildren, setUnmountedChildren] = useState([]);

  useEffect(() => setDisabledAddChild(checkDisabledAddChild));

  const checkDisabledAddChild = () =>
    !isEmpty(children) && !last(children).isReal();

  const [children, setChildren] = useState(() =>
    existingChildren.map(
      c =>
        new Child(
          c.id,
          c.firstName,
          c.lastName,
          c.relationship,
          c.latestPlacementId
        )
    )
  );

  // Custom submit event to handle different redirect logics
  // to allow worker to add child or make placements
  // Can totally avoid these logic if the team agree with
  // mounting another component and making graphql call to create child or placement
  // rather than server side render page
  // Please check out #temp-relationships-page-discussion for details
  // https://binti.slack.com/archives/C01U73ACNE7
  const handleSubmit = preventDefault(e => {
    const form = document.querySelector("#relationship-form");
    const formData = new FormData(form);
    const { submitter } = e.nativeEvent;
    // Disable all submit button in this form to avoid double submit
    // Do not set state to avoid React re-render
    document
      .querySelectorAll("#relationship-form input[type='submit']")
      .forEach(el => {
        el.disabled = true; // eslint-disable-line
      });
    let redirectUrl;
    if (submitter.id.match(/add-child/)) {
      redirectUrl = "/admin/children/new";
      formData.append("need_redirect", true);
    } else if (submitter.id.match(/add-placement-periods/)) {
      const childId = split(submitter.id, "-", 1)[0];
      const child = find(children, ["id", childId]);
      redirectUrl = `/admin/children/${child.id}/placement_periods/new?agency_placement_id=${agencyPlacementId}`;
      formData.append("need_redirect", true);
    } else if (submitter.id.match(/edit-placement-periods/)) {
      const childId = split(submitter.id, "-", 1)[0];
      const placementId = split(submitter.id, "-", 2)[1];
      const child = find(children, ["id", childId]);
      redirectUrl = `/admin/children/${child.id}/placement_periods/${placementId}/edit`;
      formData.append("need_redirect", true);
    } else {
      form.submit();
      return;
    }
    // TODO: https://binti.atlassian.net/browse/ENG-5425
    // Fix code or show security this is ok to bypass Cloud Armor
    // This request returns 403 when Cloud Armor rule protocolattack-stable was on
    // https://binti.slack.com/archives/CC3SSMDKP/p1621919646124100?thread_ts=1621882055.105200&cid=CC3SSMDKP
    // We polyfill fetch at the window level already
    fetch(formAction, { method: "PUT", body: formData }).then(response => {
      if (response.ok) {
        window.location.href = redirectUrl;
      } else {
        throw new Error("Unknown Error in <Relationships />");
      }
    });
  });

  const handleAddChild = () => {
    const child = new Child();
    const newChildren = [...children];
    newChildren.push(child);
    setChildren(newChildren);
  };

  const onRelationshipChange = e => {
    e.persist(); // Remove this line when upgraded to React 17 https://fb.me/react-event-pooling
    setRelationship(e.target.value);
  };
  const addChild = (childId, value, label) => {
    const newChildren = children.map(child => {
      if (child.id === childId) {
        const newChild = merge(new Child(), child);
        // Setting id to real child id value to reflect child.isReal() new value
        // It's important to determine if the add another child button should be disabled for not.
        newChild.id = value;
        newChild.value = value;
        newChild.label = label;
        // Fetch previously removed child and set its relationships and placement data,
        // so the user can retrieve accidentilly removed child
        // and allow user to edit previously created pre-approval placement period
        const prevRemovedChild = findLast(unmountedChildren, ["id", value]);
        newChild.relationship = toString(prevRemovedChild?.relationship);
        newChild.latestPlacementId = toString(
          prevRemovedChild?.latestPlacementId
        );
        return newChild;
      }
      return child;
    });
    setChildren(newChildren);
  };
  const removeChild = childId => {
    const childToRemove = find(children, ["id", childId]);
    const newUnmountedChildren = [...unmountedChildren, childToRemove];
    const newChildren = children.filter(child => child.id !== childId);
    setUnmountedChildren(newUnmountedChildren);
    setChildren(newChildren);
  };
  // Refactor: <CaseNote /> & <MergeChildren /> are using the same function.
  // We should create <ChildAutoCompleteSelect />
  // https://binti.atlassian.net/browse/ENG-4514
  const loadChildOptions = debounce((prefix, callback) => {
    if (prefix.length < 3) {
      callback([]);
    } else {
      $.ajax({
        url: "/admin/children/children_autocomplete",
        dataType: "json",
        contentType: "application/json",
        data: { term: prefix, agency_id: agencyId },
        success: ({ results }) => {
          const existingChildIds = children.map(({ id }) => id);
          const filteredResult = filter(
            results,
            ({ id: resultId }) =>
              !includes(existingChildIds, toString(resultId))
          );
          callback(filteredResult);
        },
      });
    }
  }, 500);

  const setChildProperty = (childId, properties) => {
    const oldChild = find(children, ["id", childId]);
    const newChild = merge(new Child(), oldChild);
    merge(newChild, properties);
    const newChildren = children.map(child => {
      if (child.id === childId) {
        return newChild;
      }
      return child;
    });

    setChildren(newChildren);
  };

  const showErrors = () => {
    const errorsMessages = flatten(values(errors)).map((err, idx) => (
      <li key={idx}>{err}</li>
    ));
    if (errorsMessages.length > 0) {
      return <ul className="errors">{errorsMessages}</ul>;
    }
    return null;
  };

  // Error when use directly on jsx.
  const relationshipFor = name =>
    I18n.t(
      "javascript.components.children_in_home.relationships.relationship_for",
      { name }
    );

  // TODO ENG-12478 This component could be simplified by using InputRadioGroup
  return (
    <form
      action={formAction}
      onSubmit={handleSubmit}
      method="POST"
      id="relationship-form"
    >
      <InputHidden name="_method" value="PUT" />
      <Csrf />
      <fieldset className="inputs">
        <legend>
          <span>{relationshipFor(applicantName)}</span>
        </legend>

        <ol className="contains-inputs">
          {showErrors()}
          {/*
            Hidden field because we don't want to connect application and
            agency_placement(Relationship) using belongs_to
          */}
          <InputHidden name="application_id" value={applicationId} />
          <fieldset className="choices">
            <legend className="label">
              {I18n.t(
                "javascript.components.children_in_home.relationships.is_this_caregiver_associated_with_a_child_currently_in_care"
              )}
            </legend>

            <ol
              className="choices-group"
              data-testid="child-associated-with-home"
            >
              <li className="choice">
                <label>
                  <input
                    type="radio"
                    name="application[child_associated_with_home]"
                    value="true"
                    checked={isRelated === "true"}
                    onChange={onRelationshipChange}
                  />
                  {I18n.t(
                    "javascript.components.children_in_home.relationships.answer_yes"
                  )}
                </label>
              </li>
              <li className="choice">
                <label>
                  <input
                    type="radio"
                    name="application[child_associated_with_home]"
                    value="false"
                    checked={isRelated === "false"}
                    onChange={onRelationshipChange}
                  />
                  {I18n.t(
                    "javascript.components.children_in_home.relationships.answer_no"
                  )}
                </label>
              </li>
            </ol>
          </fieldset>
          {isRelated === "true" && (
            <Fragment>
              {children.map(child => (
                <Relationship
                  key={child.id}
                  child={child}
                  applicationId={applicationId}
                  applicantName={applicantName}
                  applicationStatus={applicationStatus}
                  mountChild={addChild}
                  unmountChild={removeChild}
                  setRelationship={setChildProperty}
                  loadChildOptions={loadChildOptions}
                />
              ))}
              <Button
                icon={Icons.Plus}
                variant="secondary"
                onClick={handleAddChild}
                disabled={disabledAddChild}
              >
                {children.length > 0 ? "Add another Child" : "Add Child"}
              </Button>
            </Fragment>
          )}
          <Button data-testid="relationship-submit" type="submit">
            Submit
          </Button>
        </ol>
      </fieldset>
    </form>
  );
};

Relationships.propTypes = {
  formAction: PropTypes.string.isRequired,
  errors: PropTypes.array,
  application: PropTypes.shape({
    applicantName: PropTypes.string.isRequired,
    childAssociated: PropTypes.bool,
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }),
  existingChildren: PropTypes.array,
  agencyId: PropTypes.number.isRequired,
  agencyPlacementId: PropTypes.number.isRequired,
};

// Not exporting this because it's just a proposal for now
const Spacer = ({
  top,
  bottom,
  left,
  right,
  children,
  style,
  ...restProps
}) => {
  const customStyles = {
    ...style,
    paddingTop: top,
    paddingBottom: bottom,
    paddingLeft: left,
    paddingRight: right,
  };

  return (
    <div {...restProps} style={customStyles}>
      {children}
    </div>
  );
};

Spacer.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.any,
};

export default Relationships;
