import { Flex, FlexItem, Surface, InputText } from "@heart/components";

import styles from "./PrototypeOtherAdultsForm.module.scss";

/**
 * **This is a prototype** - a starting point for Licensing's
 * implementation and a demonstration of Heart components in use.
 */
const PrototypeOtherAdultsForm = () => (
  <Surface
    title="Other adults"
    subtitle="Other adults are people who interact frequently with children in the home"
  >
    <Surface>
      <Flex column gap="300">
        <h3 className={styles.formTitle}>Add another adult</h3>
        <Flex row gap="300">
          <img
            src="http://placekitten.com/76/76"
            className={styles.avatar}
            alt="avatar"
          />
          <FlexItem expand="sm">
            <OtherAdultsFormInputs />
          </FlexItem>
        </Flex>
      </Flex>
    </Surface>
  </Surface>
);

const OtherAdultsFormInputs = () => (
  <Flex column>
    <Flex row tabletColumn>
      <FlexItem expand="sm">
        <InputText label="Role in Household" placeholder="Select" />
      </FlexItem>
      <FlexItem expand="sm">
        <InputText label="Role details" placeholder="Select" />
      </FlexItem>
    </Flex>
    <Flex row tabletColumn>
      <FlexItem expand="sm">
        <InputText label="First name" placeholder="Select" />
      </FlexItem>
      <FlexItem expand="sm">
        <InputText label="Middle name" placeholder="Select" />
      </FlexItem>
      <FlexItem expand="sm">
        <InputText label="Last name" placeholder="Select" />
      </FlexItem>
      <FlexItem expand="sm">
        <InputText label="Suffix" placeholder="Select" />
      </FlexItem>
    </Flex>
    <Flex row tabletColumn>
      <FlexItem expand="lg">
        <InputText label="Email" placeholder="Select" />
      </FlexItem>
      <FlexItem expand="lg">
        <InputText label="Phone" placeholder="Select" />
      </FlexItem>
      <FlexItem expand="sm">
        <InputText label="Date of Birth" placeholder="Select" />
      </FlexItem>
    </Flex>
    <Flex row tabletColumn>
      <FlexItem expand="sm">
        <InputText label="Gender" placeholder="Select" />
      </FlexItem>
      <FlexItem expand="lg">
        <InputText label="Ethnicity" placeholder="Select" />
      </FlexItem>
    </Flex>
    <Flex row tabletColumn>
      <FlexItem expand="sm">
        <InputText label="Start date" placeholder="Select" />
      </FlexItem>
      <FlexItem expand="sm">
        <InputText label="End date" placeholder="Select" />
      </FlexItem>
    </Flex>
  </Flex>
);

export default PrototypeOtherAdultsForm;
