import PropTypes from "prop-types";
import { Component } from "react";

import Spinner from "../Spinner";

const RETRY_PERIOD = 2000;
const MAX_TRIES = 10;

// This component will simply keep refetching the document it is passed.
// Once the partial PDF is generated, its parent will just unmount it.
class AwaitingPartial extends Component {
  state = {
    tries: 0,
  };

  componentDidMount() {
    this.fetchDocument();
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  }

  fetchDocument = () => {
    const { doc, client } = this.props;
    const { tries } = this.state;

    this.timeoutId = null;

    if (tries < MAX_TRIES) {
      client.getDocument(doc, () => {
        this.setState({ tries: tries + 1 }, () => {
          this.timeoutId = window.setTimeout(this.fetchDocument, RETRY_PERIOD);
        });
      });
    }
  };

  render() {
    const { tries } = this.state;

    if (tries < MAX_TRIES) {
      return (
        <div>
          <p>
            Generating incomplete PDF <Spinner />
          </p>
          <p>
            You can wait on this page for it to finish or visit other pages and
            return to download it later.
          </p>
        </div>
      );
    }

    return (
      <p>
        The incomplete PDF is taking a while to generate. Please revisit this
        page in a few minutes to download it.
      </p>
    );
  }
}

AwaitingPartial.propTypes = {
  doc: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default AwaitingPartial;
