import PropTypes from "prop-types";
import React from "react";

import styles from "./ItemListTable.module.scss";

/**
 * This table style is for when you are interacting with the complete row,
 * and not an invididual item in the row
 */

const ItemListTable = ({ children, headers }) => (
  <table className={styles.table}>
    <thead>
      <tr className="is-hidden-accessible">
        {headers.map(header => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
    <tbody className={styles.itemListRows}>{children}</tbody>
  </table>
);

ItemListTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

export default ItemListTable;
