// these shadow builtin window.escape and window.unescape, so rename them
import {
  escape as lodashEscape,
  isEmpty,
  maxBy,
  sortBy,
  toArray,
  unescape as lodashUnescape,
} from "lodash";

$(function () {
  /*
   * decaffeinate suggestions:
   * DS101: Remove unnecessary use of Array.from
   * DS102: Remove unnecessary code created because of implicit returns
   * DS207: Consider shorter variations of null checks
   * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
   */
  // cf. http://stackoverflow.com/a/1203361
  const get_extension = function (filename) {
    const a = filename.split(".");
    if (a.length === 1 || (a[0] === "" && a.length === 2)) {
      return "";
    }
    return a.pop();
  };

  const get_file_number = function (filename) {
    const number_from_filename =
      filename != null ? filename.match(/\.*(\d+)(?:\.\w+)?$/) : undefined;
    if (number_from_filename) {
      return parseInt(number_from_filename[1], 10);
    } else {
      return 1;
    }
  };

  const is_image = file => file.file_content_type.match(/image/);

  const thumbnail_url = function (file) {
    if (is_image(file)) {
      return file.thumbnail_url;
    } else {
      let extension;
      if (file.file_filename) {
        extension = get_extension(file.file_filename);
      }
      if (!extension) {
        extension = "_blank";
      }
      return `/file_icons/${extension}.png`;
    }
  };

  // stolen from refile
  // https://github.com/refile/refile/blob/d7a42dcd7cf631ba94b01231f535bda061f6af92/app/assets/javascripts/refile.js#L8-L20
  const formData = function (file, filename) {
    const data = new FormData();
    data.append("file", file, filename);
    return data;
  };

  const storeDateApplicationSigned = function () {
    $("#date-application-signed-modal").show();
    const $self = $(this);

    return $("#submit-date-application-signed").on("click", function () {
      const date_signed = $("#date-application-signed").val();
      return $.ajax({
        url: $self.data("needs-date-signed-update-path"),
        method: "post",
        data: { date_application_signed: date_signed },
        error(jqXHR, textStatus, errorThrown) {
          if (jqXHR.status === 400) {
            return $("#date-application-signed-modal .inline-errors").text(
              jqXHR.responseJSON["error"]
            );
          } else if (jqXHR.status === 500) {
            return $("#date-application-signed-modal .inline-errors").text(
              "Internal Error. Please try again."
            );
          }
        },
        success(response) {
          $("#date-application-signed-modal").hide();
          $self.data("needs-date-signed", false);
          return $self.trigger("click");
        },
      });
    });
  };

  const runIfNeedDateSigned = callback =>
    function (e) {
      const needs_date_signed = $(this).data("needs-date-signed");
      if (!needs_date_signed) {
        return true;
      }

      e.preventDefault();
      e.stopPropagation();
      return callback.apply(this, [e]);
    };

  $(() =>
    $(document).on(
      "click",
      ".js-document-attachments, .js-admin-received-box",
      runIfNeedDateSigned(storeDateApplicationSigned)
    )
  );

  $(() => $(document).on("click", ".close-modal", () => $(".modal").hide()));

  //#####################
  // Attachments glue:
  //
  // makes attachments work cleanly with refile API
  // depends on jQuery, Underscore
  //#####################

  class AttachmentField {
    constructor(options) {
      // Initialize UI
      this.handleFileSelect = this.handleFileSelect.bind(this);
      this.handleFileDrop = this.handleFileDrop.bind(this);
      this.addFiles = this.addFiles.bind(this);
      this.getDeleteHref = this.getDeleteHref.bind(this);
      this.showAdminProgress = this.showAdminProgress.bind(this);
      this.removeAdminProgress = this.removeAdminProgress.bind(this);
      this.adminRenderFileList = this.adminRenderFileList.bind(this);
      this.adminLiHtml = this.adminLiHtml.bind(this);
      this.removeOtherButtons = this.removeOtherButtons.bind(this);
      this.removeUploadButton = this.removeUploadButton.bind(this);
      this.allSiblingFormsAreComplete =
        this.allSiblingFormsAreComplete.bind(this);
      this.renderFileList = this.renderFileList.bind(this);
      if (options == null) {
        options = {};
      }
      const { el } = options;
      this.$el = $(el).wrap('<div class="attachments" />').parent();
      const multiple =
        $(el).data("action") === "admin-upload" &&
        $(el).data("multiple-attachments");
      this.$input = $(el).attr("multiple", multiple).detach();
      this.has_changed = false;
      if (!options.inputId) {
        options.inputId = el.id;
      }
      if (!options.inputName) {
        options.inputName = el.name || $(el).data("name");
      }
      if (!options.inputValue) {
        options.inputValue = this.$input.data("attachments-value");
      }
      if (!options.toggleSelector) {
        options.toggleSelector = this.$input.data("attachments-toggle");
      }

      this.applicant_name = Binti.applicant_name;

      if (this.$input.data("action") === "admin-upload") {
        this.$el.html(this.adminRender(options));
      } else {
        this.$el.html(this.render(options));
      }
      this.$el.append(this.$input);
      this.$el.find(".js-attachments").removeClass("js-attachments");

      this.$el.on("click", ".choose-files .js-upload", event => {
        event.preventDefault();
        event.stopPropagation();
        return this.$input.trigger("click");
      });

      // Initialize Underscore Collection to Hold Filelist
      this.files = [];
      this.$hiddenField = this.$el.find(".attachments-input");
      this.$dropzone = this.$el.find(".js-attachments-dropzone");
      this.$filelist = this.$el.find(".js-attachments-filelist");

      if (options.toggleSelector) {
        $(options.toggleSelector)
          .on("change", event => {
            if (event.target.checked) {
              this.$el.closest(".wrapper-to-expand-collapse").slideUp(200);
              return this.$hiddenField.val(""); // clear 'output files'
            } else {
              this.$el.closest(".wrapper-to-expand-collapse").slideDown(200);
              return this.renderFileList();
            }
          })
          .trigger("change"); // Toggle the change event to initialize state.
      }

      if ((options.inputValue && options.inputValue.slice(0, 1)) === "[") {
        // IE we're expecting a JSON array
        this.files = JSON.parse(lodashUnescape(options.inputValue));
        if (this.$input.data("action") !== "admin-upload") {
          this.renderFileList();
        }
      }

      // Bind Events
      this.$input.on("change", this.handleFileSelect);
      this.$dropzone.on("drop", this.handleFileDrop);
    }

    render(data) {
      if (data == null) {
        data = {};
      }
      return `\
<div class="js-attachments-dropzone attachments-dropzone">
  <div class="icon"></div>
  <div class="right-side">
  <div class="choose-files">
    <a class="js-upload" href="#">
      ${I18n.t("assets.javascripts.attachments.click_here_to_choose_files")}
    </a>
  </div>
  <input class="attachments-input" type="hidden" id="${data.inputId}"
    name="${data.inputName}" value="${data.inputValue}"></input>
  <div class="or-drag-and-drop">
    ${I18n.t("assets.javascripts.attachments.or_drag_n_drop")}
  </div>
  </div>
</div>
<ul class="js-attachments-filelist"></ul>\
`;
    }

    adminRender(data) {
      if (data == null) {
        data = {};
      }
      return `\
<div class="js-attachments-dropzone admin-attachments-dropzone">
  <div class="right-side">
    <div class="choose-files">
      <button class="js-upload secondary-button">Upload</button>
    </div>
    <input class="attachments-input" type="hidden" id="${data.inputId}"
      name="${data.inputName}" value="${data.inputValue}"></input>
  </div>
</div>
<ul class="js-attachments-filelist"></ul>\
`;
    }

    handleFileSelect(event) {
      // IE11 bug, details: https://github.com/facebook/react/issues/8793
      if (event.target.files.length === 0) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      this.addFiles(event.target.files);
      return this.$input.val(""); // necessary in case user adds, deletes, and adds same file
    }

    handleFileDrop(event) {
      event.preventDefault();
      event = event.originalEvent;
      return this.addFiles(event.dataTransfer.files);
    }

    generate_filename(file, index, question_title) {
      let filename =
        this.applicant_name != null
          ? `${this.applicant_name} ${question_title}`
          : question_title;

      filename = filename.replace(/[^\w\s]+/g, "").replace(/\s+/g, "_");

      if (this.$input.data("action") !== "admin-upload") {
        let highestFileNumber = 0;
        if (!isEmpty(this.files)) {
          const highestFile = maxBy(this.files, f =>
            get_file_number(f.file_filename)
          );

          highestFileNumber = get_file_number(highestFile.file_filename);
        }

        const fileNumber = highestFileNumber + index + 1;
        filename += `_${fileNumber}`;
      }

      const extension = get_extension(file.name);
      if (extension) {
        filename += `.${extension}`;
      }

      return filename;
    }

    addFiles(files) {
      const $question = this.$el.closest(".question-type-attachment");
      this.has_changed = true;

      const question_title =
        this.$input.data("action") === "admin-upload"
          ? this.$input.data("title")
          : $question
              .find("label.control-label, label.js-attachment-label")
              .text();

      if (toArray(files).some(file => file.size > Binti.MAX_FILE_SIZE)) {
        this.$el.prepend(
          `\
<div class="alert alert-danger alert-dismissable" role="alert">
  <button type="button" class="close" data-dismiss="alert">
    <span aria-hidden="true">&times;</span>
    <span class="sr-only">Close</span>
  </button>
 ${Binti.MAX_FILE_SIZE_ERROR_MESSAGE}
</div>\
`
        );

        return;
      }

      if (this.$input.data("action") === "admin-upload") {
        this.showAdminProgress();
        return this.storePermanently(toArray(files));
      }

      Array.from(files).forEach((file, index) => {
        const $progress = $(`\
<div class="progress">
<div class="progress-bar" role="progressbar"
  style="width: 0%;"></div>
</div>\
`);
        this.$dropzone.append($progress);

        const options = {
          filename: this.generate_filename(file, index, question_title),
        };
        return this.storeFileInCache(
          file,
          options,
          response => {
            // success
            $progress.remove();
            this.files.push({
              file_id: response.id,
              file_filename: options.filename,
              file_size: file.size,
              file_content_type: file.type,
              url: response.url,
              thumbnail_url: response.thumbnail_url,
            });
            return this.renderFileList();
          },
          () => {
            // error
            $progress.remove();
            return this.$el.prepend(
              `\
<div class="alert alert-danger alert-dismissable" role="alert">
<button type="button" class="close" data-dismiss="alert">
  <span aria-hidden="true">&times;</span>
  <span class="sr-only">Close</span></button>
<strong>Uh oh!</strong>
&nbsp;There was a problem uploading ${file.name}.
</div>\
`
            );
          },
          progress =>
            $progress.find(".progress-bar").css("width", `${progress}%`)
        );
      });
      return this.renderFileList();
    }

    storePermanently(files) {
      const self = this;
      const data = new FormData();

      for (let file of Array.from(files)) {
        data.append("files[]", file);
      }

      data.append("category", this.$input.data("attachment-category"));
      data.append(
        "optional_subject_role",
        this.$input.data("attachment-optional-subject-role")
      );
      data.append(
        "application_form_id",
        this.$input.data("application-form-id")
      );
      data.append("questionnaire_key", this.$input.data("questionnaire-key"));
      data.append("question_path", this.$input.data("question-path"));
      data.append("list_block_index", this.$input.data("list-block-index"));
      data.append("title", this.$input.data("title"));
      data.append("key", this.$input.data("key"));
      data.append(
        "has_parent_document",
        this.$input.data("has-parent-document")
      );

      return $.ajax({
        url: this.$input.data("update-path"),
        method: "post",
        cache: false,
        contentType: false,
        processData: false,
        data,
        success(response) {
          self.removeAdminProgress();
          for (let attachment of Array.from(response)) {
            self.adminRenderFileList(
              attachment["id"],
              attachment["url"],
              attachment["type"],
              attachment["virus_detected"],
              attachment["invalid_mime_type"]
            );
          }

          self.removeOtherButtons();

          // TODO maybe refactor so that we template the whole document row in
          // the client, rather than just file list. It might be cleaner
          if (!self.$input.data("multiple-attachments")) {
            return self.removeUploadButton();
          }
        },
      });
    }

    storeFileInCache(file, options, success, error, progress) {
      // stolen from / inspired by refile
      const xhr = new XMLHttpRequest();
      xhr.file = file;

      xhr.addEventListener("load", function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          const parsed_response = JSON.parse(xhr.response);
          return success(parsed_response);
        } else {
          return error();
        }
      });

      let percentage = 0;

      xhr.upload.addEventListener("progress", function (progressEvent) {
        if (progressEvent.lengthComputable) {
          percentage = (progressEvent.loaded / progressEvent.total) * 100;
        } else {
          percentage++;
        }

        if (percentage > 100) {
          percentage = 100;
        }

        return progress(percentage);
      });

      // the initial uploads always go to the cache;
      // refile will copy them to the permanent store when the questionnaire
      // response is saved
      // the cache url's don't expire; we must wipe the cache regularly
      const url = "/attachments/cache";

      xhr.open("POST", url, true);
      return xhr.send(formData(file, options.filename));
    }

    getDeleteHref(attachment_id, type) {
      const update_path = this.$input.data("update-path");
      const application_id = update_path.match(
        /applications\/(\d+)\/admin_upload_multiple_attachments/
      )[1];
      const application_form_id = this.$input.data("application-form-id");
      const path = `/application/${application_id}/documents/${attachment_id}`;
      const query_string = (() => {
        if (type === "signed_form") {
          return `application_form_id=${application_form_id}`;
        } else if (type === "questionnaire_response_attachment") {
          const list_block_index = this.$input.data("list-block-index");
          const question_path = this.$input.data("question-path");
          const questionnaire_key = this.$input.data("questionnaire-key");
          if (list_block_index != null) {
            return (
              `list_block_index=${list_block_index}&application_form_id=` +
              `${application_form_id}&question_path=${question_path}` +
              `&questionnaire_key=${questionnaire_key}`
            );
          } else {
            return (
              `application_form_id=${application_form_id}&question_path=` +
              `${question_path}&questionnaire_key=${questionnaire_key}`
            );
          }
        } else if (type === "other_document") {
          return "";
        }
      })();
      return `${path}?${query_string}`;
    }

    showAdminProgress() {
      const $row = this.$dropzone.closest("tr");
      const $cell = $row.find(".col-document");
      return $cell.append(
        `\
<ul class="admin-upload-progress">
  <li>Uploading and scanning for viruses...</ul>\
`
      );
    }

    removeAdminProgress() {
      const $row = this.$dropzone.closest("tr");
      return $row.find(".col-document").find(".admin-upload-progress").remove();
    }

    adminRenderFileList(id, url, type, virus_detected, invalid_mime_type) {
      const $row = this.$dropzone.closest("tr");
      const $cell = $row.find(".col-document");
      const $list = $cell.find("ul");
      const title = this.$input.data("title");
      const num_infected = parseInt($cell.find(".js-num-infected").text(), 10);
      const num_invalid_mime_type = parseInt(
        $cell.find(".js-num-invalid-mime-type").text(),
        10
      );
      if (num_infected && num_infected >= 1 && virus_detected) {
        $cell.find(".js-num-infected").text(num_infected + 1);
      }
      if (
        num_invalid_mime_type &&
        num_invalid_mime_type >= 1 &&
        invalid_mime_type
      ) {
        return $cell
          .find(".js-num-invalid-mime-type")
          .text(num_invalid_mime_type + 1);
      } else if (!virus_detected && !invalid_mime_type && $list.length) {
        const $list_items = $list.find("li");
        const n = $list_items.length + 1;

        // change "of n-1" to "of n" for existing attachment titles
        $list_items.each((index, list_item) => {
          return $(list_item)
            .find("a")
            .first()
            .text(`${title} (${index + 1} of ${n})`);
        });

        return $list.append(
          this.adminLiHtml(
            id,
            url,
            type,
            virus_detected,
            invalid_mime_type,
            n,
            num_infected,
            num_invalid_mime_type
          )
        );
      } else if (!virus_detected && !invalid_mime_type) {
        $cell.append(
          `\
<ul>
  ${this.adminLiHtml(id, url, type, virus_detected, invalid_mime_type, 1, 0, 0)}
</ul>\
`
        );
        const $status_cell = $row.find(".js-status");
        $status_cell.find("img").remove();
        $status_cell.append(
          `\
<div class="icon-completed-checkmark">
  <span class="is-hidden-accessible">Icon completed checkmark</span>
</div>\
`
        );
        if (
          type === "signed_form" &&
          this.$input.data("parent-document-title") != null &&
          this.allSiblingFormsAreComplete()
        ) {
          const $parent_row = $(
            `tr:contains(${this.$input.data("parent-document-title")})`
          );
          const $parent_status_cell = $parent_row.find(".js-status");
          $parent_status_cell.find("img").remove();
          return $parent_status_cell.append(
            `\
<div class="icon-completed-checkmark">
  <span class="is-hidden-accessible">Icon completed checkmark</span>
</div>\
`
          );
        }
      } else if (virus_detected && (!num_infected || num_infected === 0)) {
        return $cell.append(
          this.adminLiHtml(
            id,
            url,
            type,
            virus_detected,
            invalid_mime_type,
            1,
            1,
            num_invalid_mime_type
          )
        );
      } else if (
        invalid_mime_type &&
        (!num_invalid_mime_type || num_invalid_mime_type === 0)
      ) {
        return $cell.append(
          this.adminLiHtml(
            id,
            url,
            type,
            virus_detected,
            invalid_mime_type,
            1,
            num_infected,
            1
          )
        );
      }
    }

    adminLiHtml(
      id,
      url,
      type,
      virus_detected,
      invalid_mime_type,
      n,
      num_infected,
      num_invalid_mime_type
    ) {
      const delete_href =
        !virus_detected && !invalid_mime_type
          ? this.getDeleteHref(id, type)
          : undefined;
      const title = this.$input.data("title");
      const position = n > 1 ? ` (${n} of ${n})` : "";
      if (!virus_detected && !invalid_mime_type) {
        return `\
<li>
  <a target="_blank" href="${url}">${title}${position}</a>
  (just uploaded) |
  <a data-confirm="Are you sure?" rel="nofollow" data-method="delete"
      href="${delete_href}">
    delete
  </a>
</li>\
`;
      } else if (virus_detected) {
        return `\
<span class="virus-detected">
  <span class="js-num-infected">${num_infected}</span>
    file(s) were found to have viruses and were not uploaded.
</span>\
`;
      } else if (invalid_mime_type) {
        return `\
<span class="invalid-mime-type">
  <span class="js-num-invalid-mime-type">${num_invalid_mime_type}</span>
    file(s) were found to have an invalid type and were not uploaded.
</span>\
`;
      }
    }

    removeOtherButtons() {
      const $row = this.$dropzone.closest("tr");
      const $na_cell = $row.find("td.na-column");
      const $received_cell = $row.find("td.received-column");
      $na_cell.empty();
      return $received_cell.empty();
    }

    removeUploadButton() {
      const $row = this.$dropzone.closest("tr");
      const $upload_cell = $row.find("td.upload-column");
      return $upload_cell.empty();
    }

    allSiblingFormsAreComplete() {
      const parent_document_title = this.$input.data("parent-document-title");
      const $sibling_inputs = $(
        "input[" + `data-parent-document-title='${parent_document_title}'` + "]"
      );
      for (let $sibling_input of Array.from($sibling_inputs)) {
        const $sibling_row = $($sibling_input).closest("tr");
        const $sibling_status_cell = $sibling_row.find(".js-status");
        const $sibling_status_img = $sibling_status_cell.find("img");
        const has_checkmark_image =
          $sibling_status_img.length > 0
            ? $sibling_status_img
                .attr("src")
                .indexOf("icon-completed-checkmark") > -1
            : false;
        const has_checkmark_class = $sibling_status_cell
          .find("div")
          .hasClass("icon-completed-checkmark");
        if (!has_checkmark_image && !has_checkmark_class) {
          return false;
        }
      }
      return true;
    }

    renderFileList() {
      // NOTE why do I need to wrap in $() here to set value?
      // TODO break this out, suppress URLs
      $(this.$hiddenField).val(
        lodashEscape(JSON.stringify(toArray(this.files)))
      );
      //debugger if this.files.toArray().length > 0
      this.$filelist.empty();
      return sortBy(this.files, file =>
        get_file_number(file.file_filename)
      ).forEach(file => {
        const applicant_name_underscore = `${this.applicant_name} `
          .replace(/[^\w\s]+/g, "")
          .replace(/\s+/g, "_");
        const filename =
          file.file_filename != null
            ? file.file_filename.replace(
                new RegExp(`^${applicant_name_underscore}`),
                ""
              )
            : undefined;
        let buffer = `\
<li>
<div class="img-wrapper">
<img src=\"${thumbnail_url(file)}\">
</div>
<a target=\"_blank\" href=\"${file.url}\"
  class="attachment-filename">${filename}</a>\
`;

        buffer += `\
<a href="javascript:void(0)" title="${I18n.t(
          "assets.javascripts.attachments.delete"
        )}" class="remove-file">
  <i class="far fa-times-circle"></i>
</a>
</li>\
`;

        if (file.url === "") {
          buffer = `\
<div class="img-wrapper">
<img src=\"/file_icons/_blank.png\">
</div>
<li>${I18n.t(
            "helpers.questionnaire_responses_helper.file_permission_denied"
          )}</li>\
`;
        }

        const $li = $(buffer);
        this.$filelist.append($li);
        return $li.find("a.remove-file").on("click", () => {
          return this.remove_file(file);
        });
      });
    }

    remove_file(file) {
      this.files = this.files.filter(el => el !== file);
      this.has_changed = true;
      return this.renderFileList();
    }
  }

  $.fn.attachments = function (callback) {
    const attachment_fields = this.map(function () {
      return new AttachmentField({ el: this });
    }).get();

    if (attachment_fields.length && callback != null) {
      callback(attachment_fields);
    }
    return this;
  };

  // initialize all list-blocks, except listblock prototypes
  $(".js-attachments:visible").attachments(function (attachments) {
    let is_submitting = false;

    attachments.forEach(attachment =>
      attachment.$el.parents("form").on("submit", e => (is_submitting = true))
    );

    return window.Binti.onBeforeUnloadCallbacks.push(function () {
      if (is_submitting) {
        return;
      }
      if (attachments.some(instance => instance.has_changed)) {
        return "Your files have not been saved, are you sure you want to leave?";
      }
    });
  });

  // initialize new attachments classes for attachments in newly
  // expanded list-blocks
  $(document).on("block-expanded", event =>
    $(".js-attachments:visible", event.target).attachments()
  );

  // Prevent the browser from firing default drag events and redirecting
  $(document).on("dragenter", event => event.preventDefault());
  $(document).on("dragover", function (event) {
    $("body").addClass("is-drag-active");
    if (event.originalEvent) {
      event = event.originalEvent;
    }
    const effect = event.dataTransfer.effectAllowed;
    if (effect === "move" || effect === "linkMove") {
      event.dataTransfer.dropEffect = "move";
    } else {
      event.dataTransfer.dropEffect = "copy";
    }
    event.preventDefault();
    return event.stopPropagation();
  });
  return $(document).on("drop dragleave", function (event) {
    $("body").removeClass("is-drag-active");
    event.preventDefault();
    return event.stopPropagation();
  });
});
