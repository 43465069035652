import { Button, Flex } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import AgencyDropdown from "@components/permissions/AgencyDropdown";
import CustomRolesTable from "@components/permissions/CustomRolesTable";
import RoleForm from "@components/permissions/RoleForm";

import BintiPropTypes from "@lib/BintiPropTypes";

import T from "../T";

const CustomRoles = ({ viewOnly, agencies, allAgencies }) => {
  const [selectedAgencyId, setSelectedAgencyId] = useState(agencies[0]?.id);
  const [agencyList, setAgencyList] = useState(
    agencies.map(agency => ({ name: agency.name, id: parseInt(agency.id, 10) }))
  );
  const [formHidden, setFormHidden] = useState(true);

  const onAddRole = role => {
    const newRoleAgencyId = parseInt(role.agency.id, 10);
    if (!agencyList.find(agency => agency.id === newRoleAgencyId)) {
      setAgencyList(list => [
        ...list,
        { name: role.agency.name, id: newRoleAgencyId },
      ]);
    }
    if (newRoleAgencyId !== selectedAgencyId) {
      setSelectedAgencyId(newRoleAgencyId);
    }
  };

  return (
    <Flex column gap="100">
      <If condition={agencyList.length > 0}>
        <AgencyDropdown
          agencies={agencyList}
          selectedAgencyId={selectedAgencyId}
          onChange={setSelectedAgencyId}
        />
        <CustomRolesTable agencyId={selectedAgencyId} viewOnly={viewOnly} />
      </If>
      <If condition={!viewOnly && !formHidden}>
        <RoleForm
          allAgencies={allAgencies}
          parentComponentAgencyId={selectedAgencyId}
          setParentComponentAgencyId={setSelectedAgencyId}
          onAddRole={onAddRole}
          setFormHidden={setFormHidden}
        />
      </If>
      <If condition={!viewOnly && formHidden}>
        {/* this div is used to fix button expanding problem with flex */}
        <div>
          <Button
            onClick={() => {
              setFormHidden(false);
            }}
          >
            <T t={"permissions.new_role_button"} />
          </Button>
        </div>
      </If>
    </Flex>
  );
};

CustomRoles.propTypes = {
  viewOnly: PropTypes.bool,
  agencies: PropTypes.arrayOf(
    PropTypes.shape({ id: BintiPropTypes.ID, name: PropTypes.string })
  ),
  allAgencies: PropTypes.arrayOf(
    PropTypes.shape({ id: BintiPropTypes.ID, name: PropTypes.string })
  ),
};

export default CustomRoles;
