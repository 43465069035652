import { useQuery, gql, useMutation } from "@apollo/client";
import { LoadingOverlay } from "@heart/components";

import ErrorBanner from "@components/shared/banner/ErrorBanner";

import BintiPropTypes from "@lib/BintiPropTypes";

import AgenciesAssignedForm from "./AgenciesAssignedForm";

const assignedSearchesFragment = gql`
  fragment AssignedSearches on PlacementSearch {
    id
    assignedSearches {
      id
      agency {
        id
        name
      }
      response
    }
  }
`;

const updatePlacementSearchUnassignAgencyMutation = gql`
  ${assignedSearchesFragment}
  mutation UpdatePlacementSearchUnassignAgency(
    $input: UpdatePlacementSearchUnassignAgencyInput!
  ) {
    updatePlacementSearchUnassignAgency(input: $input) {
      placementSearch {
        ...AssignedSearches
      }
    }
  }
`;

const updatePlacementSearchAssignedAgencyResponseMutation = gql`
  ${assignedSearchesFragment}
  mutation UpdatePlacementSearchAssignedAgencyResponse(
    $input: UpdatePlacementSearchAssignedAgencyResponseInput!
  ) {
    updatePlacementSearchAssignedAgencyResponse(input: $input) {
      placementSearch {
        ...AssignedSearches
      }
    }
  }
`;

const agenciesAssignedEditDataQuery = gql`
  ${assignedSearchesFragment}
  query AgenciesAssignedEditData($placementSearchId: ID!) {
    placementSearch(id: $placementSearchId) {
      ...AssignedSearches
    }
  }
`;

const AgenciesAssignedEdit = ({ placementSearchId }) => {
  const {
    data,
    loading: dataQueryLoading,
    error: dataQueryError,
  } = useQuery(agenciesAssignedEditDataQuery, {
    variables: { placementSearchId },
  });

  const [unassignAgency, { loading: unassignLoading, error: unassignError }] =
    useMutation(updatePlacementSearchUnassignAgencyMutation);

  const [
    updateAssignedAgencyResponse,
    { loading: updateLoading, error: updateError },
  ] = useMutation(updatePlacementSearchAssignedAgencyResponseMutation);

  const error = dataQueryError || unassignError || updateError;
  const loading = dataQueryLoading || unassignLoading || updateLoading;

  return (
    <LoadingOverlay active={loading}>
      <If condition={error}>
        <ErrorBanner message={error.message} />
      </If>
      <AgenciesAssignedForm
        formState={data?.placementSearch}
        onUnassign={agencyId =>
          unassignAgency({
            variables: { input: { placementSearchId, agencyId } },
          })
        }
        onUpdateResponse={(assignedSearchId, response) =>
          updateAssignedAgencyResponse({
            variables: {
              input: { placementSearchId, assignedSearchId, response },
            },
          })
        }
        showResponseColumn
      />
    </LoadingOverlay>
  );
};

AgenciesAssignedEdit.propTypes = {
  placementSearchId: BintiPropTypes.ID,
};

export default AgenciesAssignedEdit;
