/* eslint-disable react/prop-types */

/* eslint-disable react/display-name */
import PropTypes from "prop-types";

import StripedTable from "../../shared/StripedTable.js";
import EmailCell from "../cells/EmailCell.js";
import GenericCell from "../cells/GenericCell.js";
import TelephoneCell from "../cells/TelephoneCell.js";

const GeneralContacts = props => {
  const columns = [
    {
      Header: I18n.t("views.contacts.common.name"),
      accessor: "contactName",
      Cell: ({ value }) => (
        <GenericCell
          header={I18n.t("views.contacts.common.name")}
          value={value}
        />
      ),
    },
    {
      Header: I18n.t("views.contacts.common.phone"),
      accessor: "phoneNumber",
      Cell: ({ value }) => (
        <TelephoneCell
          header={I18n.t("views.contacts.common.phone")}
          phoneNumber={value}
        />
      ),
    },
    {
      Header: I18n.t("views.contacts.common.email"),
      accessor: "email",
      Cell: ({ value }) => (
        <EmailCell
          header={I18n.t("views.contacts.common.email")}
          email={value}
        />
      ),
    },
    {
      Header: I18n.t("views.contacts.general.additional_info"),
      accessor: "additionalInformation",
      Cell: ({ value }) => (
        <GenericCell
          header={I18n.t("views.contacts.general.additional_info")}
          value={value}
        />
      ),
    },
  ];
  const { title = "", contactsList = [] } = props;

  if (contactsList.length === 0) {
    return null;
  }
  return <StripedTable title={title} data={contactsList} columns={columns} />;
};

GeneralContacts.propTypes = {
  contactsList: PropTypes.array,
  title: PropTypes.string,
};

export default GeneralContacts;
