import { Avatar, Flex, Text } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import styles from "./Sidebar.module.scss";

const { t } = translationWithRoot("heart.components.sidebar");

const ListItems = ({ items = [], ordered, withDividers }) => {
  const ListComponent = ordered ? "ol" : "ul";
  return (
    <ListComponent className={styles.sidebarItems}>
      {items.map(({ id, items: subitems, content, ...props }) => (
        <li
          key={id}
          {...props}
          className={classNames(styles.sidebarItem, {
            [styles.addBorder]: withDividers,
          })}
        >
          {content}
          <If condition={subitems}>
            <ListItems items={subitems} ordered={ordered} />
          </If>
        </li>
      ))}
    </ListComponent>
  );
};
ListItems.propTypes = {
  items: PropTypes.array,
  ordered: PropTypes.bool,
  withDividers: PropTypes.bool,
};

/** A Sidebar that can be given to our Layout component */
const Sidebar = ({
  avatar,
  label,
  ordered = false,
  fixed,
  items,
  withDividers,
}) => (
  <Flex
    as="nav"
    gap="300"
    column
    aria-labelledby="sidebarLabel"
    className={classNames({
      [styles.fixed]: fixed,
    })}
    data-heart-component="Sidebar"
  >
    <If condition={avatar}>
      <Flex align="center" justify="center" column>
        <Avatar
          size={800}
          firstName={avatar.firstName}
          lastName={avatar.lastName}
        />
        <Text textStyle="emphasis-200">{label}</Text>
      </Flex>
    </If>
    <div id="sidebarLabel" className={styles.hidden}>
      {t("navigation", { label })}
    </div>
    <ListItems items={items} ordered={ordered} withDividers={withDividers} />
  </Flex>
);
Sidebar.propTypes = {
  /** When present, displays the provided label and an Avatar */
  avatar: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  /** A descriptor for the entire sidebar, required for a11y */
  label: PropTypes.string.isRequired,
  /** Are the elements of the sidebar in a specific order (e.g. form navigation)
   * or are they independent elements (e.g. different pages for a profile)?
   * Defaults to false
   */
  ordered: PropTypes.bool,
  /** Set to true if the sidebar should be fixed as you scroll */
  fixed: PropTypes.bool,
  /** What items should be listed in the sidebar */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** A unique id for the item */
      id: PropTypes.string.isRequired,
      /** Any subitems for the item. Subitems should match
       * the same prop structure as the items prop
       */
      items: PropTypes.array,
      /** Content of the item */
      content: PropTypes.node.isRequired,
    })
  ),
  /** Should the items in the sidebar be visually divided? */
  withDividers: PropTypes.bool,
};

export default Sidebar;
