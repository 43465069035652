import PropTypes from "prop-types";

import useSurfaceContext from "../surface/useSurfaceContext";
import styles from "./SurfaceForm.module.scss";

/**
 * This is the analogue of a `fieldset` but it points to the Surface's
 * `title` as its label.  Must be used on a `Surface`.
 */
const InputContainer = ({ children }) => {
  const { titleId } = useSurfaceContext();
  return (
    <div
      role="group"
      aria-labelledby={titleId}
      className={styles.inputContainer}
    >
      {children}
    </div>
  );
};

InputContainer.propTypes = { children: PropTypes.node };

export default InputContainer;
