import { useMutation, useReactiveVar } from "@apollo/client";
import { Modal, InputDropdown, toast } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";
import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import UpdateWorkflow from "@graphql/mutations/integrations/UpdateWorkflow.graphql";
import Workflows from "@graphql/queries/integrations/Workflows.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const { t } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});

/**
 * Modal to edit an integration workflow status. This will turn off
 * the workflow in it's entirety for all subscribers and is effectively
 * a kill switch for the workflow. It will not proactively deque any jobs that are waiting to
 * be processed.
 */
const WorkflowEditModal = ({ workflowIdToEdit, resetStateCallback }) => {
  const queryVariables = useReactiveVar(queryVariablesVar);

  const [workflowStatus, setWorkflowStatus] = useState(false);
  const [updateWorkflow, { loading }] = useMutation(UpdateWorkflow, {
    onCompleted: () => {
      toast.success({
        title: "Success!",
        message: "Updated Workflow",
      });
      resetStateCallback();
    },
    refectchQueries: [{ query: Workflows, variables: queryVariables }],
  });

  return (
    <Modal
      asForm
      title="Edit Workflow Status"
      submitting={loading}
      submitText="Save"
      submittingText="Saving..."
      submitDisabled={!workflowStatus}
      onCancel={() => {
        resetStateCallback();
      }}
      onSubmit={async () => {
        await updateWorkflow({
          variables: { workflowId: workflowIdToEdit, status: workflowStatus },
        });
      }}
      hidden={false}
    >
      <InputDropdown
        label="Status"
        values={[
          { label: t("labels.enabled"), value: "enabled" },
          { label: t("labels.disabled"), value: "disabled" },
        ]}
        hideBlank={true}
        value={workflowStatus}
        onChange={setWorkflowStatus}
      />
    </Modal>
  );
};

WorkflowEditModal.propTypes = {
  workflowIdToEdit: BintiPropTypes.ID,
  resetStateCallback: PropTypes.func,
};
export default WorkflowEditModal;
