import { useMutation, useQuery } from "@apollo/client";
import { Button, Flex, LiveRegion } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";
import BannerContainer from "@components/shared/banner/BannerContainer";

import ReconcileLineItems from "@graphql/mutations/ReconcileLineItems.graphql";
import LineItemsNeedUpdate from "@graphql/queries/LineItemsNeedUpdate.graphql";

import { formatAsLongDate } from "@lib/dates";

const { t } = translationWithRoot("payments_report");

export const RegenerationButton = ({ childIds, startDate, endDate }) => {
  const refetchQueries = [
    { query: LineItemsNeedUpdate, variables: { childIds, startDate, endDate } },
  ];
  const {
    data,
    error: queryError,
    loading,
  } = useQuery(LineItemsNeedUpdate, {
    variables: { childIds, startDate, endDate },
  });
  const [
    reconcileLineItems,
    { error: mutationError, loading: mutationLoading },
  ] = useMutation(ReconcileLineItems, { refetchQueries });

  const needsUpdate = data?.lineItemsNeedUpdate?.needsUpdate;
  const onClick = async () => {
    await reconcileLineItems({ variables: { childIds, startDate, endDate } });
    window.location.reload();
  };

  const errors = [queryError, mutationError].filter(Boolean).join(", ");

  return (
    <Flex column>
      <LiveRegion>
        <If condition={!isEmpty(errors)}>
          <BannerContainer type={"error"} message={errors} />
        </If>
        <If condition={loading || needsUpdate || errors}>
          <Flex>
            <Button
              onClick={onClick}
              submitting={loading || mutationLoading}
              submittingText={
                loading ? t("checking_for_updates") : t("refreshing")
              }
            >
              {t("update_all_data", {
                startDate: formatAsLongDate(startDate),
                endDate: formatAsLongDate(endDate),
              })}
            </Button>
          </Flex>
        </If>
      </LiveRegion>
    </Flex>
  );
};

export default RegenerationButton;

RegenerationButton.propTypes = {
  childIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};
