import { useMutation } from "@apollo/client";
import { isEmpty, partition } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { Fragment } from "react";

import ShowHide from "@components/ShowHide";
import ExpirationDateControl from "@components/end_user/ExpirationDateControl";
import RecordWithAttachments from "@components/requirements/RecordWithAttachments";

import DeleteUploadedRecordAttachment from "@graphql/mutations/DeleteUploadedRecordAttachment.graphql";

import { sortDatesAsc, sortDatesDesc } from "@lib/dates";
import { typeEq, policy } from "@lib/graphqlHelpers";

import styles from "./ApplicationRequirementTable.module.scss";
import OverrideRecordDisplay from "./OverrideRecordDisplay";

const UploadedRecordList = ({
  records,
  requirement,
  holderToken,
  deleteRecordAttachment,
  aroundLoader,
  disabled,
}) => (
  <Fragment>
    {records.map(record => (
      <li key={record.id} className={styles.recordRow}>
        <RecordWithAttachments
          deleteAttachment={deleteRecordAttachment(record)}
          record={record}
          attachmentProperties={["attachments", "uploadedRecordFiles"]}
        />
        {requirement.uploadType &&
          requirement.uploadType.requiresExpirationDate && (
            <ExpirationDateControl
              holderToken={holderToken}
              disabled={
                disabled || !!record.deletedAt || !policy(record).mayUpdate()
              }
              {...{ record, aroundLoader }}
            />
          )}
      </li>
    ))}
  </Fragment>
);

UploadedRecordList.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  requirement: PropTypes.object.isRequired,
  holderToken: PropTypes.string.isRequired,
  aroundLoader: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  deleteRecordAttachment: PropTypes.func,
};

const UploadTypeDocuments = ({
  fulfillment,
  disabled,
  aroundLoader,
  holderToken,
  showOverrideRecords = false,
  replaceOverrideRecord,
}) => {
  const { requirement, records } = fulfillment;

  const [deleteUploadedRecordAttachment] = useMutation(
    DeleteUploadedRecordAttachment
  );

  const uploadedRecords = sortDatesAsc(
    records.filter(record => typeEq("UploadedRecord", record)),
    "expirationDate"
  );

  const overrideRecords = sortDatesAsc(
    records.filter(record => typeEq("OverrideRecord", record)),
    "updatedAt"
  );

  const [expiredRecords, activeRecords] = partition(
    uploadedRecords,
    ({ expirationDate }) => {
      // returns true if the record is expired
      if (!requirement.uploadType.requiresExpirationDate) return false;
      if (isEmpty(expirationDate)) return false;

      return DateTime.fromISO(expirationDate).endOf("day") < DateTime.local();
    }
  );

  const deleteRecordAttachment = record =>
    aroundLoader(({ attachmentId, attachmentType }) =>
      deleteUploadedRecordAttachment({
        variables: {
          holderToken: holderToken,
          uploadedRecordId: record.id,
          attachmentId,
          attachmentType,
        },
      })
    );

  return (
    <Fragment>
      <ul className={styles.recordAttachmentList}>
        <If condition={!isEmpty(activeRecords)}>
          <UploadedRecordList
            records={activeRecords}
            {...{
              holderToken,
              aroundLoader,
              deleteRecordAttachment,
              disabled,
              requirement,
            }}
          />
        </If>
        <If condition={showOverrideRecords}>
          {sortDatesDesc(overrideRecords, "deletedAt").map(overrideRecord => (
            <li
              key={`override-record:${overrideRecord.id}`}
              className={styles.recordRow}
            >
              <OverrideRecordDisplay
                {...{
                  holderToken,
                  fulfillment,
                  replaceOverrideRecord,
                  overrideRecord,
                }}
              />
            </li>
          ))}
        </If>
      </ul>
      <If condition={!isEmpty(expiredRecords)}>
        <div className={styles.showHideLessRelevant}>
          <ShowHide
            renderHeader={() => "Expired documents"}
            renderBody={() => (
              <ul className={styles.recordAttachmentList}>
                <UploadedRecordList
                  records={expiredRecords}
                  {...{
                    holderToken,
                    aroundLoader,
                    deleteRecordAttachment,
                    disabled,
                    requirement,
                  }}
                />
              </ul>
            )}
          />
        </div>
      </If>
    </Fragment>
  );
};

UploadTypeDocuments.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  aroundLoader: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  holderToken: PropTypes.string.isRequired,
  showOverrideRecords: PropTypes.bool,
  replaceOverrideRecord: PropTypes.func,
};

export default UploadTypeDocuments;
