import { Button, Flex } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("views.common", {
  escapeJavascriptRoot: true,
});

/**
 * This component encapsulates the "row of buttons" behavior that's common to
 * forms, modals, and other scenarios.  It allows us to have consistency across the
 * app when it comes to the gap between items, what order they're laid out in, and
 * which controls are used.
 */
const Actions = ({
  formId,
  cancelAction,
  cancelHref,
  cancelText = t("cancel"),
  secondaryAction,
  secondaryHref,
  secondaryText = t("save_and_go_back"),
  secondaryDisabled,
  secondarySubmittingText = t("saving"),
  secondaryIsSubmitting,
  primaryAction,
  primaryHref,
  primaryText = t("submit"),
  primaryDisabled,
  primaryIsDanger = false,
  primarySubmittingText = t("submitting"),
  isSubmitting,
}) => (
  <Flex justify="end" data-heart-component="Actions">
    <If condition={cancelHref || cancelAction}>
      <Button
        form={formId}
        variant="tertiary"
        onClick={cancelAction}
        href={cancelHref}
        type="reset"
        disabled={isSubmitting || secondaryIsSubmitting}
      >
        {cancelText}
      </Button>
    </If>
    <If condition={secondaryHref || secondaryAction}>
      <Button
        form={formId}
        variant="secondary"
        onClick={secondaryAction}
        href={secondaryHref}
        submitting={secondaryIsSubmitting}
        submittingText={secondarySubmittingText}
        disabled={secondaryDisabled || isSubmitting}
      >
        {secondaryText}
      </Button>
    </If>
    <Button
      form={formId}
      onClick={primaryAction}
      href={primaryHref}
      submitting={isSubmitting}
      submittingText={primarySubmittingText}
      type="submit"
      disabled={primaryDisabled || secondaryIsSubmitting}
      variant={primaryIsDanger ? "danger" : "primary"}
    >
      {primaryText}
    </Button>
  </Flex>
);

Actions.propTypes = {
  /** An ID for the form associated with these Actions. This tells the
   * Actions which form they should submit, which is useful when multiple
   * forms are on one page.
   */
  formId: PropTypes.string,
  /** Action to take when cancel action is clicked */
  cancelAction: PropTypes.func,
  /** Link to navigate to when user clicks cancel */
  cancelHref: PropTypes.string,
  /** Text for cancel action */
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Action to take when secondary action is clicked */
  secondaryAction: PropTypes.func,
  /** Link to navigate to when secondary action is clicked */
  secondaryHref: PropTypes.string,
  /** Text for secondary action */
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Whether secondary action should be disabled */
  secondaryDisabled: PropTypes.bool,
  /** Text for secondary action when submit is in progress */
  secondarySubmittingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  /** Whether secondary action spinner should be displayed or not. Also
   * disables all actions when `true` */
  secondaryIsSubmitting: PropTypes.bool,
  /** Action to take when primary action is clicked */
  primaryAction: PropTypes.func,
  /** Link to navigate to when primary action is clicked */
  primaryHref: PropTypes.string,
  /** Text for primary action */
  primaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Whether primary action should be a danger button */
  primaryIsDanger: PropTypes.bool,
  /** Whether primary action should be disabled */
  primaryDisabled: PropTypes.bool,
  /** Whether primary action spinner should be displayed or not. Also
   * disables all actions when `true` */
  isSubmitting: PropTypes.bool,
  /** Text for primary action when submit is in progress */
  primarySubmittingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default Actions;
