import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { forwardRef, useState, useEffect } from "react";

import InputHidden from "./InputHidden";
import { inputCommonPropTypes, BasicInputLayout } from "./common";

// This is the time formatting for HTML attributes of the <input> element -
// Other code will zero out the seconds and milliseconds before passing times to
// this function, but we need to strip them from the ISO string here as well.
const formatDateTimeObject = dateTime => {
  if (!dateTime) return "";

  return dateTime.toISOTime({
    suppressMilliseconds: true,
    suppressSeconds: true,
    includeOffset: false,
  });
};

// We strip the seconds and milliseconds from any incoming times
// to simplify the component and to clean up the UI - it asks the user
// for seconds and milliseconds if the given value contains them.
//
// We use the timezone as specified in the ISO string, NOT the browser's
// default time zone.
const timeISOPropToDateTime = timeProp =>
  timeProp
    ? DateTime.fromISO(timeProp, { setZone: true }).startOf("minute")
    : null;

/**
 * The `InputTime` component is used to collect a time input.
 * All props and callbacks accept ISO-formatted strings.
 *
 * Please be aware that this component only supports hours and minutes,
 * not seconds or milliseconds. The component does not provide a timezone.
 *
 *
 * ### Note
 * If you're using this in a server `POST` request, congratulations, you're the first!
 * Send a message to #guild-frontend and we can make sure that workflow gets QAed and we
 * can remove this message; consider this to be 'beta' functionality until such time.
 */
const InputTime = forwardRef(
  ({ value, onChange, minTime, maxTime, ...props }, ref) => {
    const [dateTime, setDateTime] = useState(timeISOPropToDateTime(value));
    useEffect(() => setDateTime(timeISOPropToDateTime(value)), [value]);

    const handleChange = newValue => {
      const newDateTime = timeISOPropToDateTime(newValue);
      setDateTime(newDateTime);
      if (onChange)
        onChange(
          newDateTime.toISOTime({
            suppressMilliseconds: true,
            suppressSeconds: true,
            includeOffset: false,
          })
        );
    };

    return (
      <BasicInputLayout
        {...props}
        inputComponent={({
          name,
          disabled,
          "data-testid": dataTestId,
          ...commonInputProps
        }) => (
          <div data-heart-component="InputTime" data-testid={dataTestId}>
            <input
              type="time"
              {...commonInputProps}
              disabled={disabled}
              onChange={e => handleChange(e.target.value)}
              value={formatDateTimeObject(dateTime)}
              min={formatDateTimeObject(timeISOPropToDateTime(minTime))}
              max={formatDateTimeObject(timeISOPropToDateTime(maxTime))}
              ref={ref}
            />
            <InputHidden
              // When we use this input to POST data via `<form method="POST">`,
              // we submit this hidden field instead, with the ISO time string.
              data-testid="input-time-iso-value-with-offset"
              value={
                dateTime
                  ? dateTime.toISOTime({
                      suppressMilliseconds: true,
                      suppressSeconds: true,
                      includeOffset: false,
                    })
                  : ""
              }
              name={name}
              disabled={disabled}
            />
          </div>
        )}
      />
    );
  }
);

InputTime.displayName = "InputTime";
InputTime.propTypes = {
  /** The initial (or current) field value as an ISO-formatted string. */
  value: PropTypes.string,
  /** Invoked with the current ISO string value as an argument */
  onChange: PropTypes.func,
  /** The earliest time users are allowed to select (as ISO string) */
  min: PropTypes.string,
  /** The latest time users are allowed to select (as ISO string) */
  max: PropTypes.string,
  /** *not an actual prop, just a check that all times are ISO* */
  isoCheck: (props, _, componentName) => {
    ["min", "max", "value"].forEach(propName => {
      if (props[propName] && !DateTime.fromISO(props[propName]).isValid) {
        throw new Error(
          `Invalid prop '${propName}' passed to ${componentName}: '${props[propName]}' ` +
            "is not a valid ISO string"
        );
      }
    });
  },
  ...inputCommonPropTypes,
};

export default InputTime;
