import { Link } from "@heart/components";
import I18n from "i18n-js";
import { isEmpty, isNil } from "lodash";

import isPlaceholderEmail from "../isPlaceholderEmail";

export default ({
  applicationId,
  createHumanPlacementProviderRole,
  updateHumanPlacementProviderRole,
  messageDisplay: { displayError, displayInfo, displayNotice },
  placementProviderId,
  setAddingNewAdult,
  setEditingForAdult,
  adult,
  setlocalErrorMessages = () => {},
  existingUserAgencyProfiles = {},
}) => {
  const onSuccess = () => {
    setEditingForAdult(false);

    if (isEmpty(adult.email) || isPlaceholderEmail({ email: adult.email })) {
      displayInfo(
        I18n.t("views.applications.application_other_adult.no_email")
      );
    } else if (!isNil(adult.id)) {
      displayNotice(
        I18n.t(
          "views.applications.application_other_adult.updated_successfully"
        )
      );
    } else {
      displayNotice(
        I18n.t(
          "views.reference_requests.create.reference_or_other_adult_with_email",
          { email: adult.email }
        )
      );
    }
  };

  const onError = errors => {
    setlocalErrorMessages([
      <Link key={"edit_adult_error"} href="#oa_error_banner">
        {I18n.t("javascript.components.other_adults.scroll_up_for_errors")}
      </Link>,
    ]);
    displayError(
      errors
        .map(({ message, type }) =>
          type.includes("EmailCollisionError")
            ? I18n.t(
                "views.applications.application_other_adult.email_collision_error",
                { email: adult.email }
              )
            : message
        )
        .filter(Boolean)
        .join(", ")
    );
  };

  // Top level variables
  const variables = {
    applicationId: applicationId,
    placementProviderId: placementProviderId,
  };

  // Characteristics of the OA
  const personAttributes = {
    role: "otherAdultInHome",
    subrole: adult.subrole,
    firstName: adult.firstName,
    middleName: adult.middleName,
    lastName: adult.lastName,
    suffix: adult.suffix,
    ...(!isEmpty(adult.email) && { email: adult.email }),
    ...(!isEmpty(adult.phoneNumber) && { phoneNumber: adult.phoneNumber }),
  };

  if (
    personAttributes.email &&
    existingUserAgencyProfiles[personAttributes.email]
  ) {
    variables.userAgencyProfileId =
      existingUserAgencyProfiles[personAttributes.email].id;
  }

  Object.assign(personAttributes, {
    joinedAt: adult.joinedAt,
    ...(typeof adult.dateOfBirth !== "undefined" && {
      dateOfBirth: adult.dateOfBirth,
    }),
  });

  variables.memberInput = personAttributes;

  if (!isNil(adult.id)) {
    variables.userAgencyProfileId = adult.userAgencyProfileId;
    updateHumanPlacementProviderRole({ variables }).then(
      ({
        data: {
          updateHumanPlacementProviderRole: { errors: updateErrors },
        },
      }) => {
        if (isEmpty(updateErrors)) {
          onSuccess();
        } else {
          onError(updateErrors);
        }
      }
    );
  } else {
    createHumanPlacementProviderRole({ variables }).then(
      ({
        data: {
          createHumanPlacementProviderRole: { errors: creationErrors },
        },
      }) => {
        if (isEmpty(creationErrors)) {
          setAddingNewAdult(false);
          onSuccess();
        } else {
          onError(creationErrors);
        }
      }
    );
  }
};
