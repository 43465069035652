import PropTypes from "prop-types";
import { Fragment } from "react";

import T from "@components/T";

import StripedTable from "../shared/StripedTable";
import ButtonCell from "./ButtonCell";
import GenericCell from "./GenericCell";

const PreApprovalPlacementHistoryTable = ({ rows, applicantName }) => {
  const columns = [
    {
      Header: `${I18n.t(
        "javascript.components.pre_approval_placement_history_table.column.placement.title"
      )}`,
      Cell: () => (
        <GenericCell
          header={
            <T t="pre_approval_placement_history_table.column.placement.title" />
          }
          value={applicantName}
        />
      ),
    },
    {
      Header: `${I18n.t(
        "javascript.components.pre_approval_placement_history_table.column.start_date.title"
      )}`,
      accessor: "startDate",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (
        <GenericCell
          header={
            <T t="pre_approval_placement_history_table.column.start_date.title" />
          }
          value={value}
        />
      ),
    },
    {
      Header: `${I18n.t(
        "javascript.components.pre_approval_placement_history_table.column.end_date.title"
      )}`,
      accessor: "endDate",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (
        <GenericCell
          header={
            <T t="pre_approval_placement_history_table.column.end_date.title" />
          }
          value={value}
        />
      ),
    },
    {
      Header: `${I18n.t(
        "javascript.components.pre_approval_placement_history_table.column.actions.title"
      )}`,
      accessor: "editId",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (
        <ButtonCell
          header={
            <T t="pre_approval_placement_history_table.column.actions.title" />
          }
          value={value}
        />
      ),
    },
  ];

  return (
    <Fragment>
      {rows.length === 0 && (
        <p>
          <T t="pre_approval_placement_history_table.no_records" />
        </p>
      )}
      {rows.length > 0 && (
        <StripedTable
          title={I18n.t(
            "javascript.components.pre_approval_placement_history_table.title"
          )}
          data={rows}
          columns={columns}
        />
      )}
    </Fragment>
  );
};

PreApprovalPlacementHistoryTable.propTypes = {
  rows: PropTypes.array.isRequired,
  applicantName: PropTypes.string.isRequired,
};

export default PreApprovalPlacementHistoryTable;
