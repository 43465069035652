import { Button } from "@heart/components";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import SignaturePad from "signature_pad/dist/signature_pad";

import T from "@components/T";

import styles from "./SignatureInput.module.scss";

const DrawnInput = ({
  width,
  height,
  updateSignatureUri,
  canCancel,
  cancel,
}) => {
  const [canSave, setCanSave] = useState(false);
  const [signaturePad, setSignaturePad] = useState();

  const signatureCanvasRef = useCallback(node => {
    if (!node) return;

    setSignaturePad(
      new SignaturePad(node, {
        onBegin: () => {
          setCanSave(true);
        },
      })
    );
  }, []);

  const onClear = () => {
    signaturePad.clear();
  };

  const onSave = () => {
    updateSignatureUri(signaturePad.toDataURL());
  };

  return (
    <div
      className={styles.signatureInput}
      style={{ maxWidth: width, minHeight: height }}
    >
      <canvas
        className={styles.signatureCanvas}
        ref={signatureCanvasRef}
        width={width}
        height={height}
      />
      <div>
        <Button disabled={!canSave} onClick={onSave}>
          <T t="bintisign.signature_input.common.save_my_signature" />
        </Button>
        <Button onClick={onClear}>
          <T t="bintisign.signature_input.drawn_input.clear" />
        </Button>
        {canCancel && (
          <Button onClick={() => cancel()}>
            <T t="bintisign.signature_input.common.cancel" />
          </Button>
        )}
      </div>
    </div>
  );
};

DrawnInput.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  updateSignatureUri: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  canCancel: PropTypes.bool.isRequired,
};

export default DrawnInput;
