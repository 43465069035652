import { Link, Flex, Icons } from "@heart/components";
import PropTypes from "prop-types";
import { Component } from "react";

import styles from "./ComparePreviousAnswer.module.scss";
import { SIMILARITY_VALUES, SIMILARITY_LEVEL } from "./comparisonHelper";

const QUESTION_TYPE_STYLE_MAP = {
  string: "short-answer-box",
  text: "long-answer-box",
};

class ComparePreviousAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = { showPreviousAnswer: false };
  }

  openAnswer = () => {
    this.setState({ showPreviousAnswer: true });
  };

  closeAnswer = () => {
    this.setState({ showPreviousAnswer: false });
  };

  render() {
    const { similarity, previousValue, questionType } = this.props;
    const showLink = !this.state.showPreviousAnswer;
    const showAnswer = this.state.showPreviousAnswer;
    const boxStyle = QUESTION_TYPE_STYLE_MAP[questionType];

    if (!previousValue) {
      return (
        <div className={styles.annotation}>
          No previous form answer provided
        </div>
      );
    }

    return (
      <div>
        {showLink && (
          <div className={styles.annotation}>
            <SimilarityText similarity={similarity} />
            <Link onClick={this.openAnswer}>previous form answer</Link>
          </div>
        )}
        {showAnswer && (
          <Flex
            row
            justify="space-between"
            align="center"
            className={styles[boxStyle]}
            data-testid="previous-value"
          >
            {previousValue}
            <Icons.Times description="Close" onClick={this.closeAnswer} />
          </Flex>
        )}
      </div>
    );
  }
}

const SimilarityText = ({ similarity }) => {
  switch (similarity) {
    case SIMILARITY_LEVEL.SAME:
      return (
        <span>
          <b>Same</b> as{" "}
        </span>
      );
    case SIMILARITY_LEVEL.MOSTLY_DIFFERENT:
      return (
        <span>
          <b>Mostly different</b> from{" "}
        </span>
      );
    case SIMILARITY_LEVEL.PARTIALLY_DIFFERENT:
      return (
        <span>
          <b>Partially different</b> from{" "}
        </span>
      );
    case SIMILARITY_LEVEL.DIFFERENT:
      return (
        <span>
          <b>Completely different</b> from{" "}
        </span>
      );
    default:
      throw RangeError("Unknown similarity level");
  }
};

SimilarityText.propTypes = {
  similarity: PropTypes.oneOf(SIMILARITY_VALUES).isRequired,
};

ComparePreviousAnswer.propTypes = {
  similarity: PropTypes.oneOf(SIMILARITY_VALUES).isRequired,
  previousValue: PropTypes.string,
  questionType: PropTypes.string.isRequired,
};

export default ComparePreviousAnswer;
