/**
 * Utility wrapper function to wrap a browser-event callback such that it
 * call preventDefault() without cluttering the code of the callback itself.
 *
 * The most common reason you'd want to do this is that if you don't call
 * preventDefault() on an onClick callback and your component is rendered within
 * the context of a submittable form, your button, even if it's not a submit
 * button, will cause the form to submit.
 */
export default fn => e => {
  e.preventDefault();
  return fn(e);
};
