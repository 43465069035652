import { InputDropdown } from "@heart/components";
import PropTypes from "prop-types";
import React, { useState } from "react";

import ReplaceTemplate from "./ReplaceTemplate";
import styles from "./ReplaceTemplate.module.scss";

/**
 * ReplaceTemplates component renders a list of ReplaceTemplate components
 * for the default locales and provides a dropdown to select other locales.
 *
 * @param {Object} templates - An object containing template data for each locale
 * @param {String} uploadPath - The path to upload templates
 * @param {Array} defaultLocales - An array of human translated locales
 */
const ReplaceTemplates = ({ templates, uploadPath, defaultLocales }) => {
  const [selectedLocale, setSelectedLocale] = useState("");
  const otherLocales = Object.keys(templates).filter(
    locale => !defaultLocales.includes(locale)
  );

  return (
    <div className={styles.templateWrapper}>
      {/* Render ReplaceTemplate for only "en" and "es" locales */}
      {defaultLocales.map(locale => (
        <ReplaceTemplate
          key={locale}
          locale={locale}
          uploadPath={uploadPath}
          templateDownloadUrl={templates[locale].templateDownloadUrl}
          templateFilename={templates[locale].templateFilename}
        />
      ))}

      {/* Select menu for other machine translatd locales */}
      <InputDropdown
        id="locale-dropdown"
        label="Additional locales:"
        onChange={setSelectedLocale}
        blankOptionText={"--Select a Locale--"}
        value={selectedLocale}
        values={otherLocales.map(locale => ({
          value: locale,
          label: locale,
        }))}
      />

      {selectedLocale && (
        <ReplaceTemplate
          key={selectedLocale}
          locale={selectedLocale}
          uploadPath={uploadPath}
          templateDownloadUrl={templates[selectedLocale].templateDownloadUrl}
          templateFilename={templates[selectedLocale].templateFilename}
        />
      )}
    </div>
  );
};

ReplaceTemplates.propTypes = {
  templates: PropTypes.object.isRequired,
  uploadPath: PropTypes.string.isRequired,
  defaultLocales: PropTypes.array.isRequired,
};

export default ReplaceTemplates;
