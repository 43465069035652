import { useState } from "react";

/**
 * Intended for use with the MessageDisplay component.
 */
export default () => {
  const [infoText, setInfoText] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [noticeText, setNoticeText] = useState(null);
  const clearText = () => {
    setInfoText(null);
    setErrorText(null);
    setNoticeText(null);
  };
  return {
    infoText,
    errorText,
    noticeText,
    displayError: text => {
      clearText();
      setErrorText(text);
    },
    displayInfo: text => {
      clearText();
      setInfoText(text);
    },
    displayNotice: text => {
      clearText();
      setNoticeText(text);
    },
  };
};
