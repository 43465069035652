import I18n from "i18n-js";
import { sortBy } from "lodash";
import { DateTime } from "luxon";

/** Zero padded dates, i.e. 01/02/2025 */
export const LUXON_DATEPICKER_FORMAT = "MM/dd/yyyy";

export const LUXON_FULL_DATE_FORMAT = "MMMM dd, yyyy";

export const LUXON_SHORT_DATE_FORMAT = "M/d/yyyy";

// matches en.time.formats.datetime_with_zone
export const LUXON_DATETIME_WITH_ZONE = "MM/dd/yyyy hh:mma ZZZZ";

// just a string, so this is good enough for sorting purposes.
export const MAX_ISO8601_DATE = "9999-12-31";

/** date: a date in any format
 * useful for when we have unnormalized data coming from third party sources
 *
 * returns a luxon DateTime object
 */
export const dateTimeFromAnyFormat = (date, options) =>
  date ? DateTime.fromJSDate(new Date(date), options) : undefined;

export const sortDatesAsc = (array, property) =>
  sortBy(array, record => record[property] || MAX_ISO8601_DATE);

export const sortDatesDesc = (array, property) =>
  sortDatesAsc(array, property).reverse();

/** date: a date in the format "YYYY-MM-DD"
 *
 * returns date in a full date format (e.g. October 14, 1983)
 */
export const formatAsFullDate = date =>
  DateTime.fromISO(date).toFormat(LUXON_FULL_DATE_FORMAT);

/**
 * Formats an ISO 8601 date as a long date in the format "MM/dd/yyyy".
 *
 * @param {string} date - A date in ISO 8601 format.
 * @param {object} [options] - Options passed to `DateTime.fromISO`.
 * @returns {string} The formatted date in the long date format.
 */
export const formatAsLongDate = (date, options) =>
  DateTime.fromISO(date, options).toFormat("MM/dd/yyyy");

/** dateTime: an ISO String with date, time, and timezone components
 *
 * Returns formatted short time, e.g. 1:07 PM
 *
 * NOTE: Uses the timezone from the ISO String, NOT the default browser timezone
 */
export const formatDateTimeAsShortTime = dateTime =>
  DateTime.fromISO(dateTime, {
    setZone: true,
  }).toFormat("h:mm a");

/** dateTime: an ISO String with date, time, and timezone components
 *
 * Returns formatted short date, e.g.	8/6/2014
 *
 * NOTE: Uses the timezone from the ISO String, NOT the default browser timezone
 */
export const formatDateTimeAsShortDate = dateTime =>
  DateTime.fromISO(dateTime, {
    setZone: true,
  }).toFormat(LUXON_SHORT_DATE_FORMAT);

/** dateRange: one of "thisWeek", "nextWeek", "thisMonth", "nextMonth"
 *
 * returns an array of a start and end date for the provided range
 * as determined based on today's date
 */
export const determinePrefixedRangeDates = ({ dateRange }) => {
  switch (dateRange) {
    case "thisWeek":
      return [
        dateCalculations.firstDayOfWeek(),
        dateCalculations.lastDayOfWeek(),
      ];
    case "nextWeek":
      return [
        dateCalculations.firstDayOfNextWeek(),
        dateCalculations.lastDayOfNextWeek(),
      ];
    case "thisMonth":
      return [
        dateCalculations.firstDayOfMonth(),
        dateCalculations.lastDayOfMonth(),
      ];
    case "nextMonth":
      return [
        dateCalculations.firstDayOfNextMonth(),
        dateCalculations.lastDayOfNextMonth(),
      ];
    default:
      return [null, null];
  }
};

/** startDate: beginning of date range, inclusive (optional)
 * endDate: end of date range, inclusive (optional)
 * dateToCompare: date in question
 *
 * returns true or false based on whether the dateToCompare falls
 * in the provided range
 */
export const dateWithinRange = ({ startDate, endDate, dateToCompare }) => {
  const date = dateTimeFromAnyFormat(dateToCompare);
  let parsedStartDate;
  let parsedEndDate;
  if (startDate) {
    parsedStartDate = dateTimeFromAnyFormat(startDate);
  }
  if (endDate) {
    parsedEndDate = dateTimeFromAnyFormat(endDate);
  }
  if (parsedStartDate && date < parsedStartDate) return false;
  if (parsedEndDate && date > parsedEndDate) return false;
  return true;
};

const today = () => DateTime.local();

// Wrapping all these in functions allows us to mock out the date in tests
export const dateCalculations = {
  firstDayOfMonth: () => today().startOf("month"),
  lastDayOfMonth: () => today().endOf("month").startOf("day"),
  firstDayOfNextMonth: () => today().plus({ months: 1 }).startOf("month"),
  lastDayOfNextMonth: () =>
    today().plus({ month: 1 }).endOf("month").startOf("day"),
  // Assumes week starts on Monday
  firstDayOfWeek: () => today().startOf("week"),
  lastDayOfWeek: () => today().endOf("week").startOf("day"),
  firstDayOfNextWeek: () => today().plus({ weeks: 1 }).startOf("week"),
  lastDayOfNextWeek: () =>
    today().plus({ weeks: 1 }).endOf("week").startOf("day"),
};

const daysUntilToday = date =>
  Math.floor(DateTime.fromISO(date).diff(DateTime.local(), ["days"]).days);

/** dueDate: a date in the format "YYYY-MM-DD"
 *
 * returns true if the date is before today,
 * returns false if the date is today or in the future
 */
export const isOverdue = dueDate => Math.sign(daysUntilToday(dueDate)) === -1;

/** dueDate: a date in the format "YYYY-MM-DD"
 *
 * returns a string indicating the number of days left
 * or days overdue depending on the dueDate's relation
 * to today's date
 */
export const daysToDueDate = dueDate =>
  I18n.t(
    `javascript.components.dates.days_${
      isOverdue(dueDate) ? "overdue" : "left"
    }`,
    {
      number_of_days: Math.abs(daysUntilToday(dueDate)),
    }
  );
