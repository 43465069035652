import { Flex } from "@heart/components";

import AfcarsTable from "./AfcarsTable";

const NumbersAtAGlance = () => (
  <Flex column>
    <h1 style={{ marginLeft: "20px" }}>Numbers at a Glance</h1>
    <AfcarsTable
      key="numbers-at-a-glance"
      headers={["Fiscal Year", "2019", "2020", "2021", "2022", "2023"]}
      data={[
        [
          "Number in foster care on September 30 of the FY",
          "24,641",
          "24,404",
          "24,563",
          "23,518",
          "23,507",
        ],
        [
          "Number entered foster care during the FY",
          "16,799",
          "16,175",
          "15,597",
          "13,392",
          "13,212",
        ],
        [
          "Number exited foster care during the FY",
          "15,094",
          "15,505",
          "14,572",
          "13,712",
          "12,654",
        ],
        [
          "Number served by the foster care system during the FY",
          "39,735",
          "39,909",
          "39,135",
          "37,230",
          "36,161",
        ],
        [
          "Number waiting to be adopted on September 30 of the FY",
          "7,977",
          "9,144",
          "9,283",
          "9,073",
          "9,046",
        ],
        [
          "Number waiting to be adopted as of the end of the FY for whom parental rights (for all living parents) had been terminated",
          "4,819",
          "5,727",
          "5,850",
          "5,150",
          "5,649",
        ],
        [
          "Number adopted with public child welfare agency involvement during the FY",
          "3,831",
          "4,455",
          "4,714",
          "4,534",
          "3,938",
        ],
      ]}
    />
  </Flex>
);

export default NumbersAtAGlance;
