import { gql, useMutation } from "@apollo/client";
import { Button } from "@heart/components";
import PropTypes from "prop-types";

import PhoneNumberFragment from "@graphql/fragments/PhoneNumber.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const MarkPhoneNumberActivityMutation = gql`
  ${PhoneNumberFragment}
  mutation MarkPhoneNumberActivity($input: MarkPhoneNumberActivityInput!) {
    markPhoneNumberActivity(input: $input) {
      phoneNumber {
        ...PhoneNumber
      }
    }
  }
`;

/**
 * Component to mark a phone number as active or inactive.
 *
 * @param {string} id - The ID of the phone number.
 * @param {boolean} active - The current active status of the phone number.
 */
const MarkPhoneNumberActivity = ({ id, active }) => {
  const [markPhoneNumberActivity, { loading }] = useMutation(
    MarkPhoneNumberActivityMutation
  );

  return (
    <Button
      submitting={loading}
      variant="secondary"
      onClick={() =>
        markPhoneNumberActivity({
          variables: {
            input: { id, active: !active },
          },
        })
      }
    >
      {active ? "mark inactive" : "mark as active"}
    </Button>
  );
};

MarkPhoneNumberActivity.propTypes = {
  id: BintiPropTypes.ID,
  active: PropTypes.bool,
};

export default MarkPhoneNumberActivity;
