import { Layout } from "@heart/components";

import BackgroundCheckTypeTable from "./BackgroundCheckTypesTable";

const BackgroundCheckTypes = () => (
  <Layout
    pageTitle={"Background Check Types"}
    main={{
      content: <BackgroundCheckTypeTable />,
    }}
  />
);

export default BackgroundCheckTypes;
