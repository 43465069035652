import PropTypes from "prop-types";
import { useState } from "react";

import ChildGendersOpenTo from "@components/placement_preferences/ChildGendersOpenTo";

import { GENDERS_OPEN_TO_OPTIONS } from "@root/constants";

const ChildGendersOpenToAAWrapper = ({
  gendersOpenToInput,
  openToAllGendersInput,
  openToAllGendersInputName,
  gendersOpenToInputName,
}) => {
  const [gendersOpenTo, setGendersOpenTo] = useState(
    openToAllGendersInput === "true"
      ? GENDERS_OPEN_TO_OPTIONS.map(gender =>
          I18n.t(`activerecord.enums.common.gender.${gender}`, {
            locale: "en",
          })
        )
      : gendersOpenToInput
  );

  const [openToAllGenders, setOpenToAllGenders] = useState(
    openToAllGendersInput
  );

  const onChangeHandler = ({ gendersOpenToValue, openToAllGendersValue }) => {
    setGendersOpenTo(gendersOpenToValue);
    setOpenToAllGenders(openToAllGendersValue);
  };

  return (
    <ChildGendersOpenTo
      gendersOpenTo={gendersOpenTo}
      openToAllGenders={openToAllGenders}
      openToAllGendersInputName={openToAllGendersInputName}
      gendersOpenToInputName={gendersOpenToInputName}
      requiredInput={true}
      onChangeHandler={onChangeHandler}
    />
  );
};

ChildGendersOpenToAAWrapper.propTypes = {
  gendersOpenToInput: PropTypes.arrayOf(PropTypes.string),
  openToAllGendersInput: PropTypes.oneOf(["true", "false"]),
  openToAllGendersInputName: PropTypes.string.isRequired,
  gendersOpenToInputName: PropTypes.string.isRequired,
};

export default ChildGendersOpenToAAWrapper;
