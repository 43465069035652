import { useQuery, useReactiveVar } from "@apollo/client";
import {
  generateTabParam,
  Breadcrumbs,
  Flex,
  HeartTable,
  InputCheckbox,
  Surface,
} from "@heart/components";
import React from "react";

import Spinner from "@components/Spinner";
import { translationWithRoot } from "@components/T";
import BannerContainer from "@components/shared/banner/BannerContainer";

import PermissionRuleSetQuery from "@graphql/queries/PermissionRuleSet.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import ResourceActionsTable from "../ResourceActionsTable";
import {
  hideNoRuleResourceActionsVar,
  hideUnsupportedResourceActionsVar,
  permissionsDevModeVar,
} from "../permissionsReactiveVars";

const { Table, Td, Tr, Th, Tbody, Thead, Caption } = HeartTable;

const { t } = translationWithRoot("permissions.agency_relationships");

const PermissionRuleSet = ({ id }) => {
  const { data, loading, error } = useQuery(PermissionRuleSetQuery, {
    variables: { id },
  });

  const hideNoRuleResourceActions = useReactiveVar(
    hideNoRuleResourceActionsVar
  );
  const hideUnsupportedResourceActions = useReactiveVar(
    hideUnsupportedResourceActionsVar
  );
  const devMode = useReactiveVar(permissionsDevModeVar);

  const permissionRuleSet = data?.permissionRuleSet;

  if (error)
    return (
      <BannerContainer type="error" message={`Ooopsie! ${error.message}`} />
    );

  if (loading) return <Spinner />;

  return (
    <Flex column gap="100">
      <Breadcrumbs
        pages={[
          { label: t("title"), href: "/agency_relationships" },
          {
            label: t("permission_rule_sets"),
            href: `/agency_relationships?${generateTabParam(
              "Permission Rule Sets"
            )}`,
          },
          {
            label: t("permission_rule_set"),
            href: `/permission_rule_sets/${id}`,
          },
        ]}
      />

      <Surface title={t("permission_rule_set_info")}>
        <Table>
          <Caption hidden>{t("permission_rule_set_info")}</Caption>
          <Thead hidden>
            <Tr>
              <Th>Key</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody withHiddenThead>
            <Tr>
              <Td>Label</Td>
              <Td>{permissionRuleSet.label}</Td>
            </Tr>
          </Tbody>
        </Table>
        <Flex>
          <InputCheckbox
            onChange={() =>
              hideNoRuleResourceActionsVar(!hideNoRuleResourceActions)
            }
            value={hideNoRuleResourceActions}
            label="Hide inactive"
          />
          <InputCheckbox
            onChange={() =>
              hideUnsupportedResourceActionsVar(!hideUnsupportedResourceActions)
            }
            value={hideUnsupportedResourceActions}
            label="Hide unsupported"
          />
          <InputCheckbox
            onChange={() => permissionsDevModeVar(!devMode)}
            value={devMode}
            label="Dev mode"
          />
        </Flex>
      </Surface>
      <ResourceActionsTable
        queryVariables={{ permissionRuleSetId: id, roleId: null }}
      />
    </Flex>
  );
};

PermissionRuleSet.propTypes = {
  id: BintiPropTypes.ID,
};

export default PermissionRuleSet;
