$(function () {
  $("select.js-forms-pdf-strategy").on("change", function (event) {
    switch ($(event.target).val()) {
      case "filled_in_on_hellosign":
        $(".js-forms-hellosign-template-ids").removeClass("hidden");
        $(".js-forms-bintisign-doc-uploads").addClass("hidden");
        break;
      case "bintisign_doc":
        $(".js-forms-hellosign-template-ids").addClass("hidden");
        $(".js-forms-bintisign-doc-uploads").removeClass("hidden");
        break;
      default:
        $(".js-forms-hellosign-template-ids").addClass("hidden");
        $(".js-forms-bintisign-doc-uploads").addClass("hidden");
        break;
    }
  });
});
