import { Flex, FlexItem, Modal, Sidebar, Text } from "@heart/components";
import useCurrentPageSearchParam from "@heart/components/layout/page_layout/useCurrentPageSearchParam";
import { useSessionStorageValue } from "@react-hookz/web";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import { Children, Fragment, useContext, useState } from "react";

import ProgressArc from "@components/ProgressArc";
import { translationWithRoot } from "@components/T";

import Clickable from "../clickable/Clickable";
import { WizardPageContext } from "./Wizard";
import styles from "./WizardSidebar.module.scss";

const { t } = translationWithRoot("heart.components.wizard");

const SectionTitle = ({ sectionTitle }) => {
  const { value: isVisible } = useSessionStorageValue(
    `${sectionTitle}IsVisible`,
    { defaultValue: false, initializeWithValue: false }
  );

  return (
    <Clickable
      className={styles.removeClickableStyles}
      href={`#${sectionTitle}`}
    >
      <Text
        textColor={isVisible ? "emphasis-500" : "neutral-500"}
        textStyle="body-100"
      >
        {sectionTitle}
      </Text>
    </Clickable>
  );
};
SectionTitle.propTypes = {
  /** The name of this section */
  sectionTitle: PropTypes.string.isRequired,
};

const WizardSidebar = ({ label }) => {
  const [pageToNavigateTo, setPageToNavigateTo] = useState();
  const { pages } = useContext(WizardPageContext);

  const { currentPage, navigateToPage } = useCurrentPageSearchParam({
    defaultPage: 0,
  });
  const parsedCurrentPage = parseInt(currentPage, 10);

  const items = pages.map((page, index) => {
    const {
      props: { pageTitle, progress, progressIncomplete, children },
    } = page;

    const isCurrentPage = index === parsedCurrentPage;
    const isClickablePage =
      (index < parsedCurrentPage || progress > 0) && !isCurrentPage;

    let status = "Pending: ";
    if (isCurrentPage) status = "Current: ";
    else if (progress === 100 && !progressIncomplete) status = "Completed: ";

    const PageTitleComponent = isClickablePage ? Clickable : "span";

    return {
      id: pageTitle,
      items: isCurrentPage
        ? /** Extract the sectionTitles of each WizardFieldset to display in the
           * sidebar if the page that contains them is visible
           */
          Children.map(children || [], child => {
            const sectionTitle = child?.props?.sectionTitle;
            return sectionTitle
              ? {
                  id: sectionTitle,
                  content: <SectionTitle sectionTitle={sectionTitle} />,
                }
              : undefined;
          })
        : [],
      content: (
        <Fragment>
          <Text textColor={isCurrentPage ? "emphasis-500" : "neutral-500"}>
            {t("step", { number: index + 1 })}
          </Text>
          <Flex justify="space-between">
            <FlexItem>
              <span className={styles.hidden}>{status}</span>
              <PageTitleComponent
                className={styles.removeClickableStyles}
                onClick={() => {
                  if (isClickablePage) {
                    if (pages[parsedCurrentPage].props.confirmBeforeCancel)
                      setPageToNavigateTo(index);
                    else navigateToPage({ page: index });
                  }
                }}
              >
                <Text
                  textColor={isCurrentPage ? "emphasis-500" : "neutral-500"}
                  textStyle="emphasis-100"
                >
                  {pageTitle}
                </Text>
              </PageTitleComponent>
            </FlexItem>
            <ProgressArc
              progress={progress}
              progressIncomplete={progressIncomplete}
            />
          </Flex>
        </Fragment>
      ),
    };
  });
  return (
    <Fragment>
      <Modal
        title={t("are_you_sure")}
        submitDangerButton
        submitText={t("discard_changes")}
        onCancel={() => setPageToNavigateTo()}
        onSubmit={() => {
          setPageToNavigateTo();
          navigateToPage({ page: pageToNavigateTo });
        }}
        hidden={isNil(pageToNavigateTo)}
      >
        {t("unsaved_changes_warning")}
      </Modal>
      <Sidebar
        ordered={true}
        fixed={true}
        label={label}
        items={items}
        withDividers={true}
      />
    </Fragment>
  );
};
WizardSidebar.propTypes = {
  /** A description of the wizard, used for a11y properties */
  label: PropTypes.string.isRequired,
};

export default WizardSidebar;
