import {
  Flex,
  InputRadioGroup,
  InputText,
  InputTextarea,
  Surface,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";

const PlacementInformation = ({
  directions,
  onDirectionsChanged,
  contactEmail,
  onContactEmailChanged,
  therapeuticCare,
  onTherapeuticCareChanged,
  contactInformation,
  onContactInformationChanged,
  state,
  groupHome,
}) => (
  <Surface title="Placement Information">
    <Flex column>
      <InputTextarea
        label="Contact information"
        id="contact_information"
        onChange={onContactInformationChanged}
        value={contactInformation || ""}
      />
      <If condition={groupHome}>
        <InputTextarea
          label="Additional directions to facility"
          id="comments_text"
          onChange={onDirectionsChanged}
          value={directions || ""}
        />
      </If>
      <InputText
        type="email"
        label="Contact Email"
        onChange={onContactEmailChanged}
        value={contactEmail || ""}
      />
      <InputRadioGroup
        label={I18n.st(state, "therapeutic_care")}
        id="therapeutic_care_radio"
        onChange={onTherapeuticCareChanged}
        value={String(therapeuticCare) || null}
        values={[
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ]}
      />
    </Flex>
  </Surface>
);

PlacementInformation.propTypes = {
  directions: PropTypes.string,
  onDirectionsChanged: PropTypes.func.isRequired,
  contactEmail: PropTypes.string,
  onContactEmailChanged: PropTypes.func.isRequired,
  therapeuticCare: PropTypes.bool,
  onTherapeuticCareChanged: PropTypes.func.isRequired,
  contactInformation: PropTypes.string,
  onContactInformationChanged: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  groupHome: PropTypes.bool,
};

export default PlacementInformation;
