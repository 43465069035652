import { useQuery, gql, useMutation } from "@apollo/client";
import { LoadingOverlay } from "@heart/components";

import ErrorBanner from "@components/shared/banner/ErrorBanner";

import BintiPropTypes from "@lib/BintiPropTypes";

import AddAgencies from "./AddAgencies";

const assignedSearchesFragment = gql`
  fragment AssignedSearches on PlacementSearch {
    id
    assignedSearches {
      id
      agency {
        id
        name
      }
      response
    }
  }
`;

const updatePlacementSearchAddAssignedAgenciesMutation = gql`
  ${assignedSearchesFragment}
  mutation UpdatePlacementSearchAddAssignedAgencies(
    $input: UpdatePlacementSearchAddAssignedAgenciesInput!
  ) {
    updatePlacementSearchAddAssignedAgencies(input: $input) {
      placementSearch {
        ...AssignedSearches
      }
    }
  }
`;

const agenciesAssignedEditDataQuery = gql`
  ${assignedSearchesFragment}
  query AgenciesAssignedEditData($placementSearchId: ID!) {
    placementSearch(id: $placementSearchId) {
      ...AssignedSearches
    }
  }
`;

const AddAgenciesEdit = ({ placementSearchId, agencyId }) => {
  const {
    data,
    loading: dataQueryLoading,
    error: dataQueryError,
  } = useQuery(agenciesAssignedEditDataQuery, {
    variables: { placementSearchId },
  });

  const [
    addAgenciesAssigned,
    { loading: addAgenciesLoading, error: addAgenciesError },
  ] = useMutation(updatePlacementSearchAddAssignedAgenciesMutation);

  const error = dataQueryError || addAgenciesError;

  return (
    <LoadingOverlay active={dataQueryLoading || addAgenciesLoading}>
      <If condition={error}>
        <ErrorBanner message={error.message} />
      </If>

      <AddAgencies
        agencyId={agencyId}
        onSubmit={assignedAgencies =>
          addAgenciesAssigned({
            variables: {
              input: {
                placementSearchId,
                assignedAgencyIds: assignedAgencies.map(a => a.id),
              },
            },
          })
        }
        assignedSearches={data?.placementSearch.assignedSearches}
      />
    </LoadingOverlay>
  );
};

AddAgenciesEdit.propTypes = {
  agencyId: BintiPropTypes.ID,
  placementSearchId: BintiPropTypes.ID,
};

export default AddAgenciesEdit;
