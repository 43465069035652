import PropTypes from "prop-types";
import { useState } from "react";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

/** Prompts users to reassign workers to a different role when deleting
 * an existing custom role.
 *   Roles and Permissions > Customized Agency Roles > Delete
 */
const BulkReassignModal = ({ fromRole, agencyId, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    <StepOne
      key={1}
      fromRole={fromRole}
      agencyId={agencyId}
      onSubmit={() => setActiveStep(1)}
      onClose={onClose}
    />,
    <StepTwo
      key={2}
      fromRole={fromRole}
      agencyId={agencyId}
      onClose={onClose}
    />,
  ];

  return <div>{steps[activeStep]}</div>;
};

BulkReassignModal.propTypes = {
  fromRole: PropTypes.object,
  agencyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
};

export default BulkReassignModal;
