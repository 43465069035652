import PropTypes from "prop-types";
import { Component } from "react";

import preventDefault from "@lib/preventDefault";

class GeneratePartialLink extends Component {
  onClick = preventDefault(() => {
    const { doc, client } = this.props;

    client.createPartial(doc);
  });

  render() {
    const { backgroundLoading } = this.props;

    return (
      <a href="#" onClick={this.onClick} disabled={backgroundLoading}>
        Generate Incomplete PDF
      </a>
    );
  }
}

GeneratePartialLink.propTypes = {
  doc: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  backgroundLoading: PropTypes.bool,
};

export default GeneratePartialLink;
