import { useMutation } from "@apollo/client";
import { Modal } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React from "react";

import DeleteHearingSession from "@graphql/mutations/DeleteHearingSession.graphql";
import AppealQuery from "@graphql/queries/Appeal.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const DeleteHearingSessionModal = ({
  hearingSessionId,
  onCancel,
  appealId,
}) => {
  const [deleteHearingSession] = useMutation(DeleteHearingSession, {
    refetchQueries: [{ query: AppealQuery, variables: { appealId } }],
  });

  return (
    <Modal
      title={I18n.t("javascript.components.appeals.delete_appeal_title")}
      onCancel={onCancel}
      onSubmit={() => deleteHearingSession({ variables: { hearingSessionId } })}
    >
      {I18n.t("javascript.components.appeals.delete_appeal_text")}
    </Modal>
  );
};

DeleteHearingSessionModal.propTypes = {
  hearingSessionId: BintiPropTypes.ID,
  onCancel: PropTypes.func,
  appealId: BintiPropTypes.ID,
};

export default DeleteHearingSessionModal;
