import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";

class RRuleCountInput extends Component {
  constructor(props) {
    super(props);

    const { count } = props.rruleObject.options;

    this.state = { count: count || 1, indefinitely: !count };
  }

  updateRRule = () => {
    const { onRRuleChange } = this.props;
    const { indefinitely, count } = this.state;

    if (indefinitely) {
      onRRuleChange({ count: null });
    } else {
      onRRuleChange({ count });
    }
  };

  onRadioChange = e => {
    const indefinitely = e.target.value === "indefinitely";

    this.setState({ indefinitely }, this.updateRRule);
  };

  onCountChange = e => {
    const { value } = e.target;
    const count = isEmpty(value) ? "" : parseInt(value, 10);
    this.setState({ count }, this.updateRRule);
  };

  render() {
    const { indefinitely, count } = this.state;

    return (
      <fieldset className="choices">
        <ol className="choices-group">
          <li className="choice">
            <label>
              <input
                type="radio"
                checked={indefinitely}
                onChange={this.onRadioChange}
                value="indefinitely"
              />
              Repeats Indefinitely
            </label>
          </li>
          <li className="choice">
            <label>
              <input
                type="radio"
                checked={!indefinitely}
                onChange={this.onRadioChange}
                value="finite"
              />
              Specific number of occurrences
            </label>
            <label hidden={indefinitely}>
              <input
                value={count}
                disabled={indefinitely}
                onChange={this.onCountChange}
              />{" "}
              times
            </label>
          </li>
        </ol>
      </fieldset>
    );
  }
}

RRuleCountInput.propTypes = {
  rruleObject: PropTypes.object.isRequired,
  onRRuleChange: PropTypes.func.isRequired,
};

export default RRuleCountInput;
