import { gql, useQuery } from "@apollo/client";
import {
  InputDropdown,
  InputDate,
  InputTextarea,
  LoadingOverlay,
  Flex,
} from "@heart/components";
import { PropTypes } from "prop-types";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

import ReasonSearchClosed from "../ReasonSearchClosed";

const { t } = translationWithRoot("placement_search");

export const reasonClosedNeedsExplanationTitles = [
  "closed_other",
  "silp",
  "hospitalization",
  "juvenile_detention_center",
  "hotel",
  "receiving_center",
  "office",
  "shelter",
  "other",
].reduce(
  (reasons, reason) => ({
    ...reasons,
    [reason]: t(`reason_closed_explanation_title.${reason}`),
  }),
  {}
);

const PlacementSearchDetailsForm = ({
  placementSearchId,
  childId,
  formState,
  setFormAttribute,
}) => {
  const detailsQuery = useQuery(
    gql`
      query PlacementSearchFormDetailsQuery(
        $childId: ID
        $placementSearchId: ID
      ) {
        placementSearchFormData(
          childId: $childId
          searchId: $placementSearchId
        ) {
          reasonRequestedOptions {
            label
            value
          }
          closedOptions {
            label
            value
          }
          responseOptions {
            label
            value
          }
        }
      }
    `,
    {
      variables: {
        childId,
        placementSearchId: placementSearchId,
      },
    }
  );
  const formData = detailsQuery.data;
  const formLoading = detailsQuery.loading;

  // These queries are separate so that PlacementSearchId can be required for this one
  // and optional for the one above
  const searchQuery = useQuery(
    gql`
      query PlacementSearchDetailsQuery(
        $placementSearchId: ID!
        $placementSearchIdExists: Boolean!
      ) {
        placementSearch(id: $placementSearchId)
          @include(if: $placementSearchIdExists) {
          isAssignedFromOtherAgency
        }
      }
    `,
    {
      variables: {
        placementSearchId: placementSearchId || 0,
        placementSearchIdExists: !!placementSearchId,
      },
    }
  );
  const searchData = searchQuery.data;
  const searchLoading = searchQuery.loading;

  const isPlacementFound = formState?.reasonClosed === "placement_found";

  const { reasonRequestedOptions, closedOptions } =
    formData?.placementSearchFormData || {
      reasonRequestedOptions: [],
      closedOptions: [],
      responseOptions: [],
    };

  const { isAssignedFromOtherAgency } = placementSearchId
    ? searchData.placementSearch
    : {};

  const loading = formLoading || (placementSearchId && searchLoading);

  return (
    <LoadingOverlay active={loading}>
      <Flex column>
        <InputDate
          disabled={isAssignedFromOtherAgency}
          label={t("date_placement_requested")}
          value={formState.requestedAt}
          onChange={setFormAttribute("requestedAt")}
          required
        />
        <InputDropdown
          disabled={isAssignedFromOtherAgency}
          label={t("reason_requested.title")}
          value={formState.reasonRequested}
          values={reasonRequestedOptions}
          onChange={setFormAttribute("reasonRequested")}
        />
        <InputTextarea
          disabled={isAssignedFromOtherAgency}
          label={t("reason_requested_explanation")}
          value={formState.reasonRequestedExplanation}
          onChange={setFormAttribute("reasonRequestedExplanation")}
        />
        <InputDate
          disabled={isAssignedFromOtherAgency}
          label={t("date_placement_due")}
          value={formState.dueAt}
          onChange={setFormAttribute("dueAt")}
        />
        <InputDate
          label={t("search_completed_date")}
          value={formState.closedAt}
          onChange={setFormAttribute("closedAt")}
          required={isPlacementFound}
        />
        <ReasonSearchClosed
          hasPreviousPlacement={formState.hasPreviousPlacement}
          values={closedOptions}
          value={formState.reasonClosed}
          onChange={setFormAttribute("reasonClosed")}
        />
        <If
          condition={reasonClosedNeedsExplanationTitles[formState.reasonClosed]}
        >
          <InputTextarea
            label={reasonClosedNeedsExplanationTitles[formState.reasonClosed]}
            value={formState.reasonClosedOtherExplanation}
            onChange={setFormAttribute("reasonClosedOtherExplanation")}
          />
        </If>
      </Flex>
    </LoadingOverlay>
  );
};

PlacementSearchDetailsForm.propTypes = {
  placementSearchId: BintiPropTypes.ID,
  childId: BintiPropTypes.ID,
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
};

export default PlacementSearchDetailsForm;
