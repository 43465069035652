import { useMutation } from "@apollo/client";
import { Button } from "@heart/components";
import { omit } from "lodash";
import PropTypes from "prop-types";

import T from "@components/T";

import SignSigningEventLocation from "@graphql/mutations/SignSigningEventLocation.graphql";
import UnsignSigningEventLocation from "@graphql/mutations/UnsignSigningEventLocation.graphql";

import styles from "../../BintiSign.module.scss";
import rolesT from "../../rolesT";
import ScaledSignatureBlock from "./ScaledSignatureBlock";

const fitSignature = ({ blockDimensions, signatureDimensions }) => {
  if (
    signatureDimensions.width <= blockDimensions.width &&
    signatureDimensions.height <= blockDimensions.height
  ) {
    return signatureDimensions;
  }

  const sigRatio =
    (1.0 * signatureDimensions.height) / signatureDimensions.width;
  const blockRatio = (1.0 * blockDimensions.height) / blockDimensions.width;

  if (sigRatio < blockRatio) {
    // signature is squatter than the block -> resize to width
    const pxWidth = Math.min(blockDimensions.width, signatureDimensions.width);
    const pxHeight = pxWidth * sigRatio;

    return { width: pxWidth, height: pxHeight };
  }

  // image is taller than the block -> resize to height
  const pxHeight = Math.min(blockDimensions.height, signatureDimensions.height);
  const pxWidth = pxHeight / sigRatio;

  return { width: pxWidth, height: pxHeight };
};

/**
 * Renders a signature block that the current user is allowed to sign.
 */
const MySignatureBlock = ({
  isSigned,
  signingEvent,
  activeSigner,
  location,
  locationIndex,
  scale,
}) => {
  const [signSigningEventLocation] = useMutation(SignSigningEventLocation);
  const [unsignSigningEventLocation] = useMutation(UnsignSigningEventLocation);

  const contentClassName = [styles.mySigner];
  let blockContent = (
    <T t="bintisign.common.role_signature" role={rolesT(location.signerRole)} />
  );
  let buttonContent;
  if (activeSigner.signature) {
    if (isSigned) {
      const onClick = () => {
        unsignSigningEventLocation({
          variables: {
            signingEventId: signingEvent.id,
            role: activeSigner.role,
            location: omit(location, "__typename"),
            locationIndex,
          },
        });
      };

      contentClassName.push(styles.renderedImage);
      blockContent = (
        <img
          src={activeSigner.signature.signatureUri}
          {...fitSignature({
            blockDimensions: {
              width: location.width * scale,
              height: location.height * scale,
            },
            signatureDimensions: activeSigner.signature,
          })}
          alt={I18n.t(
            "javascript.components.bintisign.my_signature_block.your_signature"
          )}
        />
      );
      buttonContent = (
        <Button {...{ onClick }}>
          <T t="bintisign.my_signature_block.clear_my_signature" />
        </Button>
      );
    } else {
      const onClick = () => {
        signSigningEventLocation({
          variables: {
            signingEventId: signingEvent.id,
            role: activeSigner.role,
            signatureId: activeSigner.signature.id,
            location: omit(location, "__typename"),
            locationIndex,
          },
        });
      };

      blockContent = <T t="bintisign.my_signature_block.your_signature" />;
      buttonContent = (
        <Button {...{ onClick }}>
          <T t="bintisign.my_signature_block.sign_here" />
        </Button>
      );
      contentClassName.push(styles.unsigned);
    }
  } else {
    blockContent = (
      <T t="bintisign.my_signature_block.please_create_your_signature" />
    );
  }

  return (
    <ScaledSignatureBlock
      {...{ contentClassName, location, scale, blockContent, buttonContent }}
    />
  );
};

MySignatureBlock.propTypes = {
  signingEvent: PropTypes.object.isRequired,
  activeSigner: PropTypes.object.isRequired,
  isSigned: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  locationIndex: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
};

export default MySignatureBlock;
