import { Flex } from "@heart/components";

import AfcarsTable from "./AfcarsTable";

const ChildrenEnteringFosterCare = () => (
  <Flex column>
    <h1 style={{ marginLeft: "20px" }}>Children Entering Foster Care</h1>
    <h2 style={{ marginLeft: "20px", padding: "0" }}>FY 2023 (N=13,212)</h2>
    <Flex row>
      <Flex column style={{ flex: 1 }}>
        <AfcarsTable
          stats
          key="age-stats"
          headers={["Age at Entry", "Year"]}
          data={[
            ["Mean", "6.0"],
            ["Median", "5.0"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="age-stats-2"
          headers={["Age at Entry", "%", "Number"]}
          data={[
            ["Less than 1 Year", "22%", "2,845"],
            ["1 Year", "8%", "1,003"],
            ["2 Years", "7%", "934"],
            ["3 Years", "6%", "830"],
            ["4 Years", "6%", "745"],
            ["5 Years", "6%", "746"],
            ["6 Years", "5%", "707"],
            ["7 Years", "5%", "652"],
            ["8 Years", "4%", "590"],
            ["9 Years", "4%", "502"],
            ["10 Years", "4%", "485"],
            ["11 Years", "4%", "518"],
            ["12 Years", "4%", "487"],
            ["13 Years", "4%", "469"],
            ["14 Years", "4%", "489"],
            ["15 Years", "3%", "429"],
            ["16 Years", "3%", "377"],
            ["17 Years", "2%", "254"],
            ["18 Years", "1%", "69"],
            ["19 Years", "0%", "53"],
            ["20 Years", "0%", "27"],
          ]}
        />
      </Flex>
      <Flex column style={{ flex: 1 }}>
        <AfcarsTable
          stats
          normalizeNumbers
          key="race-eth"
          headers={["Race/Ethnicity", "%", "Number"]}
          data={[
            ["American Indian/Alaska Native", "0%", "21"],
            ["Asian", "0%", "23"],
            ["Black or African American", "29%", "3,820"],
            ["Native Hawaiian/Other Pacific Islander", "0%", "0"],
            ["Hispanic (of any race)", "18%", "2,376"],
            ["White", "44%", "5,851"],
            ["Unknown/Unable to Determine", "2%", "202"],
            ["Two or More Races", "7%", "914"],
          ]}
          note="All races exclude children of Hispanic origin. Children of Hispanic ethnicity may be any race."
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="Circumstances Associated with Child's Removal"
          headers={[
            "Circumstances Associated with Child's Removal",
            "%",
            "Number",
          ]}
          data={[
            ["Neglect", "56%", "7,436"],
            ["Drug Abuse (Parent)", "48%", "6,369"],
            ["Caretaker Inability To Cope", "17%", "2,199"],
            ["Housing", "13%", "1,755"],
            ["Physical Abuse", "13%", "1,713"],
            ["Abandonment", "8%", "1,043"],
            ["Alcohol Abuse (Parent)", "8%", "1,020"],
            ["Parent Incarceration", "7%", "923"],
            ["Sexual Abuse", "4%", "532"],
            ["Child Behavior Problem", "2%", "308"],
            ["Parent Death", "2%", "271"],
            ["Drug Abuse (Child)", "1%", "153"],
            ["Relinquishment", "1%", "77"],
            ["Alcohol Abuse (Child)", "0%", "20"],
            ["Child Disability", "0%", "8"],
          ]}
          note="These categories are not mutually exclusive, so percentages will total more than 100% and counts will be more than the total number of entries."
        />
      </Flex>
    </Flex>
  </Flex>
);

export default ChildrenEnteringFosterCare;
