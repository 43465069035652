import PropTypes from "prop-types";

import ListField from "./ListField";
import ParagraphField from "./ParagraphField";
import styles from "./ProfileSection.module.scss";
import TextField from "./TextField";

const ProfileSection = ({ title, fields }) => (
  <div className={styles.profileSectionContainer}>
    <h2>{title}</h2>
    <div className={styles.fieldsContainer}>
      {fields.map(({ fieldTitle, text, items, type }) => (
        <div className={styles.fieldContainer} key={fieldTitle}>
          <div className={styles.fieldTitle}>{fieldTitle}</div>
          {type === "list" && <ListField items={items} />}
          {type === "paragraph" && <ParagraphField items={items} />}
          {type === "text" && <TextField text={text} />}
        </div>
      ))}
    </div>
  </div>
);

ProfileSection.propTypes = {
  /** Title for the whole ProfileSection */
  title: PropTypes.string.isRequired,
  /** Array of objects where each object maps to a Field type
   * rendered inside this component */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      /** Title of this Field */
      fieldTitle: PropTypes.string.isRequired,
      /** Field type: list, paragraph, or text */
      type: PropTypes.string.isRequired,
      /** Required if type is text */
      text: PropTypes.string,
      /** Required if type is list or paragraph */
      items: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};
export default ProfileSection;
