import { uniqueId, toString } from "lodash";

// This model is used for storing and displaying react-select tag
// in <Relationships /> and <Relationship />

class Child {
  constructor(
    id = uniqueId("mockChildren_"),
    firstName,
    lastName,
    relationship,
    latestPlacementId,
    value
  ) {
    this.id = toString(id);
    this.firstName = toString(firstName);
    this.lastName = toString(lastName);
    this.relationship = toString(relationship);
    this.latestPlacementId = toString(latestPlacementId);
    // value and label are for react-select
    // I tried to make it into a nested selectTag: {value, label} or
    // get selectTag(), but can't make it work.
    this.value = toString(value);
    if (!this.id.match(/mockChildren_/)) {
      this.value = this.id;
      this.label = `${this.firstName} ${this.lastName} ( ${this.id} )`;
    }
  }

  isReal() {
    if (this.id.match(/mockChildren_/)) return false;
    return true;
  }
}

export default Child;
