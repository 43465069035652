import { Flex, Alert } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import useGeneratedId from "@lib/generateId";

const UserSessionTimeoutWarningModal = ({
  isModalOpen,
  onClick,
  isRefreshButtonDisabled = false,
}) => {
  const describedby = useGeneratedId();

  return (
    <Alert
      hidden={!isModalOpen}
      onSubmit={onClick}
      submitDisabled={isRefreshButtonDisabled}
      submitText={I18n.t(
        "javascript.components.user_session_timeout_warning.stay_on_page"
      )}
      title={I18n.t(
        "javascript.components.user_session_timeout_warning.modal_title"
      )}
    >
      <Flex column align="center">
        <p id={describedby}>
          {I18n.t("javascript.components.user_session_timeout_warning.warning")}
        </p>
      </Flex>
    </Alert>
  );
};

UserSessionTimeoutWarningModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isRefreshButtonDisabled: PropTypes.bool,
};

export default UserSessionTimeoutWarningModal;
