import { useMutation } from "@apollo/client";
import { Actions, SurfaceForm } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import CreateBackgroundCheck from "@graphql/mutations/CreateBackgroundCheck.graphql";

import preventDefault from "@lib/preventDefault";

import BackgroundCheckForm from "./BackgroundCheckForm";
import ProviderRoles from "./ProviderRoles";

/**
 * Form for creating a new background check
 */
const CreateBackgroundCheckForm = ({ cancelPath, onSuccessRedirectTo }) => {
  const [createBackgroundCheck] = useMutation(CreateBackgroundCheck, {
    onCompleted: () => window.location.replace(onSuccessRedirectTo),
  });

  const { formState, setFormAttribute } = useBintiForm(null, {
    initialValue: { rolesSpec: ProviderRoles.map(pr => pr.value) },
  });

  return (
    <SurfaceForm
      title={I18n.t("admin.background.check.title")}
      onSubmit={preventDefault(() =>
        createBackgroundCheck({
          variables: { input: formState },
        })
      )}
      actions={
        <Actions
          primaryText={I18n.t("admin.background.check.create")}
          cancelText={I18n.t("views.common.cancel")}
          cancelHref={cancelPath}
        />
      }
    >
      <BackgroundCheckForm
        formState={formState}
        setFormAttribute={setFormAttribute}
      />
    </SurfaceForm>
  );
};

CreateBackgroundCheckForm.propTypes = {
  cancelPath: PropTypes.string.isRequired,
  onSuccessRedirectTo: PropTypes.string,
};

export default CreateBackgroundCheckForm;
