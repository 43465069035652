$(function () {
  const $coApplicantPanel = $(".co-applicant.panel");
  const $coApplicantFields = $coApplicantPanel.find(":input");

  $("input[name='application[single_parent]']").on("change", function () {
    if (this.value === "true") {
      $coApplicantPanel.hide();
      // using disabled attribute to toggle the client-side validations
      // (using disabled rather than required so that we can toggle ALL fields
      // even if some are required and some are optional)
      $coApplicantFields.attr("disabled", true);
      // completely cleanup form state
      $coApplicantFields.val("");
      $coApplicantPanel.find(".has-error").removeClass("has-error");
      $(".help-block").remove();
    } else {
      $coApplicantFields.attr("disabled", false);
      $coApplicantPanel.show();
    }
  });

  $(document).on("click", ".js-next-stage .is-disabled", function () {
    return false;
  });
});
