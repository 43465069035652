import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Flex,
  InputDropdown,
  InputFilterable,
  SurfaceForm,
  InputText,
  Actions,
} from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";
import { providerRoleRequirementMatrixDashboardPath } from "routes";

import CreateProviderRoleRequirementMatrix from "@graphql/mutations/CreateProviderRoleRequirementMatrix.graphql";

import preventDefault from "@lib/preventDefault";

const NewStaffRoleRequirementMatrix = ({
  agencies,
  licenseTypeOptions,
  subroleOptions,
}) => {
  const [agency, setAgency] = useState("");
  const [isAutogeneratedSlug, setAutogeneratedSlug] = useState(true);
  const [staffRoleRequirementMatrixInput, setStaffRoleRequirementMatrixInput] =
    useState({ slug: "", name: "" });
  const { data: uploadTypeData, loading: uploadTypeLoading } = useQuery(
    gql`
      query UploadTypes($agencyId: ID) {
        uploadTypes(agencyId: $agencyId) {
          id
          slug
        }
      }
    `,
    { variables: agency !== "" ? { agencyId: parseInt(agency.value, 10) } : {} }
  );

  const [create] = useMutation(CreateProviderRoleRequirementMatrix, {
    onCompleted: () => {
      window.location.assign(providerRoleRequirementMatrixDashboardPath());
    },
  });

  const createProviderRoleMatrixAndRedirect = () => {
    const fixedState = {
      ...staffRoleRequirementMatrixInput,
      uploadTypeSlugs: staffRoleRequirementMatrixInput.uploadTypeSlugs.map(
        value => value.value
      ),
      providerRole: "staff_member",
    };
    create({
      variables: { input: fixedState },
    });
  };

  return (
    <SurfaceForm
      title="New Staff Requirements Matrix"
      subtitle={
        "Agency, license type, and subroles " +
        "select which subset of people the selected upload types will" +
        " become requirements for."
      }
      onSubmit={preventDefault(createProviderRoleMatrixAndRedirect)}
      actions={<Actions />}
    >
      <Flex column>
        <InputText
          label="Name of this requirement matrix"
          required
          onChange={value => {
            if (isAutogeneratedSlug) {
              setStaffRoleRequirementMatrixInput({
                ...staffRoleRequirementMatrixInput,
                name: value,
                slug: value.toLowerCase().replaceAll(" ", "-"),
              });
            } else {
              setStaffRoleRequirementMatrixInput({
                ...staffRoleRequirementMatrixInput,
                name: value,
              });
            }
          }}
        />
        <InputText
          label="slug of this requirement matrix"
          required
          onChange={value => {
            setAutogeneratedSlug(value === "");
            setStaffRoleRequirementMatrixInput({
              ...staffRoleRequirementMatrixInput,
              slug: value,
            });
          }}
          value={staffRoleRequirementMatrixInput.slug}
        />
        <InputFilterable
          label="Agency"
          required
          values={agencies.map(([id, slug]) => ({
            label: slug,
            value: id.toString(),
          }))}
          onChange={value => {
            setStaffRoleRequirementMatrixInput({
              ...staffRoleRequirementMatrixInput,
              agencyId: value.value,
            });
            setAgency(value);
          }}
        />
        <InputDropdown
          label="License type"
          required
          values={licenseTypeOptions}
          onChange={value => {
            setStaffRoleRequirementMatrixInput({
              ...staffRoleRequirementMatrixInput,
              licenseType: value,
            });
          }}
        />
        <InputDropdown
          label="Staff requirements are always for staff roles. Optionally select a subrole of staff these requirements are applicable to."
          values={subroleOptions}
          onChange={value => {
            setStaffRoleRequirementMatrixInput({
              ...staffRoleRequirementMatrixInput,
              subrole: value,
            });
          }}
        />
        <InputFilterable
          label="Select upload types"
          error={
            !staffRoleRequirementMatrixInput.uploadTypeSlugs ||
            staffRoleRequirementMatrixInput.uploadTypeSlugs.length === 0
              ? "Please select one or more upload type slugs"
              : null
          }
          required
          isMulti
          values={
            uploadTypeLoading || agency === ""
              ? []
              : uploadTypeData.uploadTypes.map(type => ({
                  label: type.slug,
                  value: type.slug,
                }))
          }
          onChange={value => {
            setStaffRoleRequirementMatrixInput({
              ...staffRoleRequirementMatrixInput,
              uploadTypeSlugs: value,
            });
          }}
        />
      </Flex>
    </SurfaceForm>
  );
};
NewStaffRoleRequirementMatrix.propTypes = {
  agencies: PropTypes.arrayOf(PropTypes.array),
  licenseTypeOptions: PropTypes.array,
  subroleOptions: PropTypes.array,
};
export default NewStaffRoleRequirementMatrix;
