import PropTypes from "prop-types";

import MySignatureBlock from "./MySignatureBlock";
import SomeoneElsesSignatureBlock from "./SomeoneElsesSignatureBlock";

/**
 * A signature block overlaid on a PDF page. This component determines if this
 * block is actionable for the current user (i.e. it matches a role they're
 * allowed to sign) or not and renders the appropriate block in each case.
 */
const SignatureBlock = ({
  activeSigner,
  signingEvent,
  location,
  locationIndex,
  isSigned,
  pageWidth,
}) => {
  const { pageWidth: locationPageWidth } = location;
  const scale = pageWidth / locationPageWidth;
  const props = {
    activeSigner,
    signingEvent,
    isSigned,
    location,
    locationIndex,
    scale,
  };

  if (activeSigner.role === location.signerRole) {
    return <MySignatureBlock {...props} />;
  }

  return <SomeoneElsesSignatureBlock {...props} />;
};

SignatureBlock.propTypes = {
  signingEvent: PropTypes.object.isRequired,
  activeSigner: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  locationIndex: PropTypes.number.isRequired,
  pageWidth: PropTypes.number.isRequired,
  isSigned: PropTypes.bool.isRequired,
};

export default SignatureBlock;
