import { useQuery, useMutation } from "@apollo/client";
import { Button, Flex, LoadingOverlay } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import PropTypes from "prop-types";

import UpdatePlacementSearchDetailsMutation from "@graphql/mutations/UpdatePlacementSearchDetails.graphql";
import PlacementSearchDetailsQuery from "@graphql/queries/PlacementSearchDetails.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import PlacementSearchDetailsForm from "./PlacementSearchDetailsForm";

const PlacementSearchDetailsEdit = ({ id, childId, onCompleted }) => {
  const { data, loading: dataLoading } = useQuery(PlacementSearchDetailsQuery, {
    variables: { id },
  });

  const { formState, setFormAttribute } = useBintiForm(data?.placementSearch);

  const [updatePlacementSearch, { loading: updateLoading }] = useMutation(
    UpdatePlacementSearchDetailsMutation,
    {
      onCompleted: ({ updatePlacementSearchDetails: { placementSearch } }) => {
        onCompleted();
        if (
          data.placementSearch.reasonClosed !== "placement_found" &&
          placementSearch.reasonClosed === "placement_found"
        ) {
          if (placementSearch.isAssignedFromOtherAgency) {
            window.location = "/admin/placement_search_dashboards";
          } else {
            window.location = `/admin/children/${childId}/placement_periods/new`;
          }
        }
      },
    }
  );

  const onSubmit = () =>
    updatePlacementSearch({
      variables: {
        input: {
          ..._.pick(formState, [
            "id",
            "requestedAt",
            "reasonRequested",
            "reasonRequestedExplanation",
            "dueAt",
            "closedAt",
            "reasonClosed",
            "reasonClosedOtherExplanation",
          ]),
        },
      },
    });

  return (
    <LoadingOverlay active={dataLoading || updateLoading}>
      <Flex column align="end">
        <PlacementSearchDetailsForm
          formState={formState}
          setFormAttribute={setFormAttribute}
          childId={childId}
          placementSearchId={id}
        />
        <Button type="submit" onClick={preventDefault(onSubmit)}>
          Submit
        </Button>
      </Flex>
    </LoadingOverlay>
  );
};

PlacementSearchDetailsEdit.propTypes = {
  id: BintiPropTypes.ID.isRequired,
  childId: BintiPropTypes.ID,
  onCompleted: PropTypes.func,
};

export default PlacementSearchDetailsEdit;
