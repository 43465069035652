import * as HeartTable from "@heart/components/table/Table";
import classNames from "classnames";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import useSurfaceContext from "../../surface/useSurfaceContext";

const { Caption } = HeartTable;

const { t } = translationWithRoot("data_table");

/** A table caption! Infers the caption from the surrounding surface title if none
 * is explicitly provided.  In that scenario, the caption is hidden. */
const TableCaption = ({ caption, tableIsSortable }) => {
  const { title: surfaceTitle } = useSurfaceContext();
  if (!surfaceTitle && !caption)
    // eslint-disable-next-line no-console
    console.error(
      "Please use a caption if this DataTable is not on top of a Surface"
    );

  return (
    <Caption>
      <span className={classNames({ "is-hidden-accessible": !caption })}>
        {caption || surfaceTitle}
      </span>
      <If condition={tableIsSortable}>
        <span className="is-hidden-accessible">{t("sort_notice")}</span>
      </If>
    </Caption>
  );
};

TableCaption.propTypes = {
  caption: PropTypes.string,
  tableIsSortable: PropTypes.bool.isRequired,
};

export default TableCaption;
