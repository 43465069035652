import { InputText } from "@heart/components";
import PropTypes from "prop-types";
import { useRef, useState } from "react";

const onClick =
  ({ text, delimiter, textareaRef }) =>
  () => {
    let { selectionStart } = textareaRef.current;

    // we're at the end of the line, go back one to make the rest work.
    if (text[selectionStart] === "\n") {
      selectionStart -= 1;
    }

    let end = text.indexOf("\n", selectionStart);

    if (end === -1) {
      end = text.length - 1;
    }

    let start = text.lastIndexOf("\n", selectionStart);

    if (start === -1) {
      start = 0;
    } else {
      start += 1;
    }

    if (delimiter) {
      const quoteStart = text.indexOf(delimiter, start);

      // no quotes on this line, so give up.
      if (quoteStart === -1) return;

      const quoteEnd = text.lastIndexOf(delimiter, end);
      // no quotes on this line, so give up.
      if (quoteEnd === -1) return;

      // only one quote on the line or empty string? nevermind.
      if (quoteStart + 1 >= quoteEnd) return;

      start = quoteStart + 1;
      end = quoteEnd;
    }

    textareaRef.current.setSelectionRange(start, end);

    document.execCommand("copy");
  };

const EZCopyTextarea = ({ text, delimiter = '"' }) => {
  const textareaRef = useRef(null);
  const [filter, setFilter] = useState("");

  const value = `${text
    .split("\n")
    .filter(line => line.includes(filter))
    .join("\n")}\n`; // add \n to end so selecting the last line works well

  return (
    <div className="contains-inputs">
      <InputText label="Filter" onChange={setFilter} value={filter} />
      <textarea
        style={{ minHeight: 500 }}
        ref={textareaRef}
        onClick={onClick({ text: value, delimiter, textareaRef })}
        readOnly
        {...{ value }}
      />
    </div>
  );
};

EZCopyTextarea.propTypes = {
  text: PropTypes.string.isRequired,
  delimiter: PropTypes.string,
};

export default EZCopyTextarea;
