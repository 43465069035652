import { Flex, Icons, Pill } from "@heart/components";
import PropTypes from "prop-types";

import { daysToDueDate, formatAsFullDate, isOverdue } from "@lib/dates";

/** Cell contents displaying a due date with conditional styling
 * if an entity is due soon or overdue
 *
 * **Note:** This story has the date locked to October 24, 2022 so that
 * the date calculations remain constant for testing purposes
 */
const DueDateCell = ({ fulfillment }) => {
  const {
    dateActionNeededBy: dueDate,
    isActionNeededSoon: isDueSoon,
    isFulfilled,
  } = fulfillment;
  const overdue = isOverdue(dueDate);

  if (dueDate == null) {
    return "";
  }
  if (!isFulfilled && (isDueSoon || overdue)) {
    return (
      <Flex justify="center">
        <Flex column align="center">
          <Pill
            variant={overdue ? "warning" : "alert"}
            text={formatAsFullDate(dueDate)}
          />
          <Flex align="center">
            <Icons.ExclamationTriangle />
            {daysToDueDate(dueDate)}
          </Flex>
        </Flex>
      </Flex>
    );
  }
  return <Flex justify="center">{formatAsFullDate(dueDate)}</Flex>;
};
DueDateCell.propTypes = {
  fulfillment: PropTypes.shape({
    dateActionNeededBy: PropTypes.string,
    isActionNeededSoon: PropTypes.bool.isRequired,
    isFulfilled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DueDateCell;
