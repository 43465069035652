import {
  InputDate,
  InputDropdown,
  InputText,
  InputTextarea,
  InputRadioGroup,
} from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "background_check_exemptions.decision_inputs"
);

const ExemptionDecision = ({ formState, setFormAttribute, outcomes }) => (
  <Fragment>
    <InputDropdown
      // outcome determines the final status of an exemption (approves or denies it)
      label={t("exemption_decision")}
      name="outcome"
      value={formState.outcome || "pending"}
      values={outcomes.map(outcome => ({
        label: I18n.t(
          `activerecord.enums.background_check_exemption.outcomes.${outcome}`
        ),
        value: outcome,
      }))}
      onChange={setFormAttribute("outcome")}
      hideBlank
    />
    <If condition={formState.outcome === "approved_with_conditions"}>
      <InputTextarea
        label={t("conditions")}
        name="conditions"
        value={formState.conditions}
        onChange={setFormAttribute("conditions")}
        rows={5}
      />
    </If>
    <InputRadioGroup
      label={t("is_limited_to_specific_child")}
      name={"is_limited_to_specific_child"}
      orientation="row"
      value={formState.isLimitedToSpecificChild}
      values={[
        { label: "Yes", value: true },
        { label: "No", value: false },
      ]}
      onChange={setFormAttribute("isLimitedToSpecificChild")}
    />
    <InputDate
      label={t("decision_date")}
      name="decision_date"
      value={formState.decisionDate}
      onChange={setFormAttribute("decisionDate")}
    />
    <InputText
      label={t("decision_made_by")}
      name="approved_by"
      value={formState.approvedBy}
      onChange={setFormAttribute("approvedBy")}
    />
    <InputTextarea
      label={t("exemption_notes")}
      name="notes"
      value={formState.notes}
      onChange={setFormAttribute("notes")}
      rows={5}
    />
  </Fragment>
);

ExemptionDecision.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
  outcomes: PropTypes.array.isRequired,
};

export default ExemptionDecision;
