import { Layout } from "@heart/components";
import useCurrentPageSearchParam from "@heart/components/layout/page_layout/useCurrentPageSearchParam";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import DocumentsTable from "../family_finding/documents/DocumentsTable";
import AgencyHumanSidebar from "./AgencyHumanSidebar";

const { t } = translationWithRoot("agency_human.family_finding_documents");

const FamilyFindingDocuments = ({ agencyHuman, agencyHumanName }) => {
  const { id, first_name: firstName, last_name: lastName } = agencyHuman;
  // must use this hook to get the correct bolding applied to the sidebar link
  useCurrentPageSearchParam({ defaultPage: "family_finding_documents" });

  return (
    <Layout
      pageTitle={t("page_title", { name: agencyHumanName })}
      sidebar={{
        fullHeight: true,
        opaque: true,
        content: (
          <AgencyHumanSidebar
            avatar={{
              firstName,
              lastName,
            }}
            label={agencyHumanName}
            agencyHumanId={id}
          />
        ),
      }}
      main={{
        content: <DocumentsTable agencyHumanId={id} />,
      }}
    />
  );
};
FamilyFindingDocuments.propTypes = {
  agencyHuman: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  agencyHumanName: PropTypes.string.isRequired,
};

export default FamilyFindingDocuments;
