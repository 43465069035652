import {
  InputText,
  InputFilterableLegacyAjax,
  InputTextarea,
} from "@heart/components";
import classNames from "classnames";
import { mapKeys, startCase } from "lodash";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";

import labelStyles from "@components/reusable_ui/forms/Label.module.scss";

import FamilyInformationFields from "./FamilyInformationFields";

/** When choosing a respite provider, this component lets users choose between
 * different types of respite providers.
 *   Children > Add Respite > "Respite Provider Name"
 */
class RespiteProviderFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.providerType,
    };
  }

  handleOptionChange = changeEvent => {
    const providerType = changeEvent.target.value;
    this.setState({
      selectedOption: providerType,
    });
  };

  handlePreviousState = providerType => {
    const matchingType = providerType && this.props.providerType;
    if (
      matchingType &&
      this.props.selectedPlacement.value &&
      this.props.selectedPlacement.label
    ) {
      return {
        value: this.props.selectedPlacement.value,
        label: this.props.selectedPlacement.label,
      };
    }
  };

  render() {
    const usStateOptions = mapKeys(this.props.usStateOptions, (value, key) =>
      startCase(key)
    );
    return (
      <div data-testid="respite-fields-container" className="form-group">
        <p
          className={classNames(
            labelStyles.label,
            labelStyles["label--required"]
          )}
        >
          Respite Provider Name
        </p>
        <div className="contains-inputs">
          <label>
            <input
              type="radio"
              value="placed_with_family"
              checked={this.state.selectedOption === "placed_with_family"}
              onChange={this.handleOptionChange}
              name="placement_period[provider_type]"
            />
            Community Family
          </label>
          {this.state.selectedOption === "placed_with_family" && (
            <InputFilterableLegacyAjax
              url="/admin/placements/placement_by_reason_closed"
              params={{
                reason_closed: this.state.selectedOption,
                agency_id: this.props.agencyId,
              }}
              name="placement_period[agency_placement_id]"
              previousState={this.handlePreviousState("placed_with_family")}
              id="community_family"
              label="Select family"
            />
          )}
        </div>
        <div className="contains-inputs">
          <label>
            <input
              type="radio"
              value="went_with_relatives"
              checked={this.state.selectedOption === "went_with_relatives"}
              onChange={this.handleOptionChange}
              name="placement_period[provider_type]"
            />
            Relative Family
          </label>
          {this.state.selectedOption === "went_with_relatives" && (
            <InputFilterableLegacyAjax
              url="/admin/placements/placement_by_reason_closed"
              params={{
                reason_closed: this.state.selectedOption,
                agency_id: this.props.agencyId,
              }}
              name="placement_period[agency_placement_id]"
              previousState={this.handlePreviousState("went_with_relatives")}
              id="relative_family"
              label="Select relative family"
            />
          )}
        </div>
        <div className="contains-inputs">
          <label>
            <input
              type="radio"
              value="placed_with_private_agency"
              checked={
                this.state.selectedOption === "placed_with_private_agency"
              }
              onChange={this.handleOptionChange}
              name="placement_period[provider_type]"
            />
            Private Agency
          </label>
          {this.state.selectedOption === "placed_with_private_agency" && (
            <Fragment>
              <div data-testid="private-agency">
                {/** TODO ENG-12783 without this testId, `cy.findField("Agency")` */}
                {/** gets confused by the radio label above */}
                <InputFilterableLegacyAjax
                  url="/admin/placements/placement_by_reason_closed"
                  params={{
                    reason_closed: this.state.selectedOption,
                    agency_id: this.props.agencyId,
                  }}
                  name="placement_period[agency_placement_id]"
                  label="Agency"
                  required
                  previousState={this.handlePreviousState(
                    "placed_with_private_agency"
                  )}
                  id="private_agency"
                />
              </div>
              <InputText
                label="License Number"
                id="placement_period[license_number]"
                type="text"
                name="placement_period[license_number]"
                value={this.props.licenseNumber || ""}
              />
              <FamilyInformationFields
                familyName={this.props.familyName}
                phoneNumber={this.props.phoneNumber}
                address={this.props.address}
                usStateOptions={usStateOptions}
              />
            </Fragment>
          )}
        </div>
        <div className="contains-inputs">
          <label>
            <input
              type="radio"
              value="non_binti_caregiver"
              checked={this.state.selectedOption === "non_binti_caregiver"}
              onChange={this.handleOptionChange}
              name="placement_period[provider_type]"
            />
            Add Name of Caregiver not in Binti
          </label>
          {this.state.selectedOption === "non_binti_caregiver" && (
            <Fragment>
              <FamilyInformationFields
                familyName={this.props.familyName}
                phoneNumber={this.props.phoneNumber}
                address={this.props.address}
                usStateOptions={usStateOptions}
              />
              <InputTextarea
                label="Relationship to Child - Explanation"
                required
                name="placement_period[relationship_to_child]"
                id="placement_period[relationship_to_child]"
                rows={3}
                value={this.props.relationshipToChild || ""}
              />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

RespiteProviderFields.propTypes = {
  agencyId: PropTypes.number,
  providerType: PropTypes.string,
  selectedPlacement: PropTypes.object,
  familyName: PropTypes.string,
  licenseNumber: PropTypes.string,
  phoneNumber: PropTypes.string,
  relationshipToChild: PropTypes.string,
  address: PropTypes.shape({
    addressStreet: PropTypes.string,
    addressCity: PropTypes.string,
    addressState: PropTypes.string,
    addressZipcode: PropTypes.string,
  }),
  usStateOptions: PropTypes.object,
};

export default RespiteProviderFields;
