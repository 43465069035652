$(() => {
  const $stageNumber = $("input#application_stage_template_stage_number:first");

  $stageNumber.on("change keydown paste input", () => {
    $("input[name*='application_stage_template[stage_number]'")
      .filter(":hidden")
      .val($stageNumber.val());
  });

  const togglePartnerAgencyFields = () => {
    const partnerAgencyFields = $(
      ".js-dependent-partner-agency-logo, .js-dependent-partner-agency-worker"
    );
    if ($(".js-dependent-partner-agency select").val() === "") {
      partnerAgencyFields.hide();
    } else {
      partnerAgencyFields.show();
    }
  };

  $(".js-dependent-partner-agency select").change(togglePartnerAgencyFields);
});
