import { Alert, Button, Icons } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

import useDropzoneWrapper from "./useDropzoneWrapper";

const { t } = translationWithRoot("upload");

/**
 * An Upload variant that looks like a button.
 *
 * TODO ENG-11941
 * deduplicate code between this and Upload add multiple + photosOnly support
 */
const UploadButton = ({
  onUpload,
  passedInputProps,
  multiple = true,
  "data-testid": testId,
  label = t("upload"),
}) => {
  const [error, setError] = useState(null);
  const onDrop = async files => {
    setIsUploading(true);
    await onUpload(files);
    setIsUploading(false);
  };
  const { getRootProps, getInputProps } = useDropzoneWrapper({
    onDrop,
    noDrag: true,
    noClick: true,
    multiple,
    setError,
  });

  const { tabindex, style, ...inputProps } = (
    passedInputProps || getInputProps
  )();

  const [isUploading, setIsUploading] = useState(false);

  return (
    <Fragment>
      <Alert
        hidden={!error}
        isAlert={true}
        title={I18n.t("javascript.components.common.alert")}
        onSubmit={() => setError(null)}
        submitText={t("try_again")}
      >
        {I18n.t(`javascript.components.upload.errors.${error}`)}
      </Alert>
      <span
        {...getRootProps({
          disabled: isUploading,
        })}
      >
        <label>
          <input
            type="file"
            data-testid={testId}
            /** name attribute is used for capybara tests, if we convert all
             * of the capybara tests we should be able to remove it
             */
            name="UploadButtonInput"
            {...inputProps}
          />
          <Button
            asSpan
            icon={Icons.FileUpload}
            submitting={isUploading}
            submittingText={t("uploading")}
            variant="secondary"
          >
            {label}
          </Button>
        </label>
      </span>
    </Fragment>
  );
};

UploadButton.propTypes = {
  /** Function called with array of files uploaded. **Note**: this function
   * will only be called with files that meet our accepted file types list
   */
  onUpload: PropTypes.func,
  /** Optional input props to pass in - this is used for when this button is
   * nested within a dropzone (eg on a table row) AVOID THIS PATTERN. Adding for legacy
   * cleanup but will be deprecating it soon (hopefully)
   */
  passedInputProps: PropTypes.func,
  /** Allow multiple files to be uploaded.  Defaults to true */
  multiple: PropTypes.bool,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
  /** An optional label to use. Defaults to "Upload" */
  label: PropTypes.string,
};

export default UploadButton;
