import { useMutation } from "@apollo/client";
import { Button, InputText, Surface, Text } from "@heart/components";
import { Spinner } from "@heart/components/icon/Icon";

import { translationWithRoot } from "@components/T";
import BannerContainer from "@components/shared/banner/BannerContainer";

import CreateBackgroundCheckType from "@graphql/mutations/CreateBackgroundCheckType.graphql";
import BackgroundCheckTypes from "@graphql/queries/BackgroundCheckTypes.graphql";

const { t } = translationWithRoot("background_check_types");

const CreateBackgroundCheckTypeForm = () => {
  const [createBackgroundCheckType, { loading, error }] = useMutation(
    CreateBackgroundCheckType,
    {
      refetchQueries: [{ query: BackgroundCheckTypes }],
    }
  );

  const onSubmit = data => {
    const fd = new FormData(data.target);
    createBackgroundCheckType({
      variables: {
        name: fd.get("name"),
        description: fd.get("description"),
        slug: fd.get("slug"),
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <BannerContainer type="error" message={`Ooopsie! ${error.message}`} />
    );
  }

  return (
    <Surface hideTitle title={t("table_title")}>
      <Text textStyle="emphasis-200">{t("create_bg_type")}</Text>
      <form onSubmit={onSubmit} className="contains-inputs">
        <InputText
          id="name"
          label={t("columns.name")}
          name="name"
          required
          rows={1}
          type="text"
        />
        <InputText
          id="slug"
          label={t("columns.slug")}
          name="slug"
          required
          rows={1}
          type="text"
        />
        <InputText
          id="description"
          label={t("columns.description")}
          name="description"
          required={false}
          rows={1}
          type="text"
        />
        <div>
          <Button type="submit">{t("create_new_bg_type")}</Button>
        </div>
      </form>
    </Surface>
  );
};

export default CreateBackgroundCheckTypeForm;
