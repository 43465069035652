import { useMutation } from "@apollo/client";
import {
  Actions,
  Flex,
  LoadingOverlay,
  Surface,
  SurfaceForm,
} from "@heart/components";
import I18n from "i18n-js";
import _ from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import CreatePlacementSearchMutation from "@graphql/mutations/CreatePlacementSearch.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import AgenciesAssignedForm from "./agencies_assigned/AgenciesAssignedForm";
import AddAgencies from "./agencies_assigned/add_agencies/AddAgencies";
import PlacementSearchDetailsForm from "./placement_search_details/PlacementSearchDetailsForm";
import WorkersAssignedForm from "./workers_assigned/WorkersAssignedForm";
import AddWorkers from "./workers_assigned/add_workers/AddWorkers";

const { t } = translationWithRoot("placement_search");

const getRedirectUrl = (childId, placementSearchId) =>
  `/admin/children/${childId}/placement_periods/new?placement_search_id=${placementSearchId}`;

const isPlacementFound = search => search?.reasonClosed === "placement_found";

const isRespite = search => search?.reasonClosed === "respite";

const PlacementSearchCreate = ({ agencyId, childId, showAssignedAgencies }) => {
  const [formState, setFormState] = useState({
    agencyWorkers: [],
    assignedSearches: [],
    requestedAt: new Date(),
  });

  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const [createPlacementSearch, { loading }] = useMutation(
    CreatePlacementSearchMutation,
    {
      onCompleted: data => {
        const newSearch = data.createPlacementSearch.placementSearch;
        if (isPlacementFound(newSearch)) {
          window.location = getRedirectUrl(
            childId,
            data.createPlacementSearch.placementSearch.id
          );
        } else if (isRespite(newSearch)) {
          window.location = `/admin/children/${childId}/respites/new`;
        } else {
          window.location = `/admin/placement_search_dashboards?success_flash=${t(
            "created_successfully"
          )}`;
        }
      },
    }
  );

  const setFormAttribute = attributeName => value =>
    setFormState({ ...formState, [attributeName]: value });

  const onSubmit = () => {
    setSubmissionInProgress(true);
    createPlacementSearch({
      variables: {
        input: {
          ..._.omit(
            formState,
            "agencyWorkers",
            "isAssignedFromOtherAgency",
            "assignedSearches"
          ),
          agencyWorkerIds: formState.agencyWorkers?.map(aw => aw.id),
          assignedAgencyIds:
            formState.assignedSearches?.map(as => as.agency.id) || [],
          childId,
        },
      },
    });
  };

  // TOOD: i18n
  return (
    <LoadingOverlay active={loading}>
      <SurfaceForm
        actions={
          <Actions
            primaryText={isPlacementFound ? "Save and Continue" : "Submit"}
            isSubmitting={submissionInProgress}
          />
        }
        title="Create Placement Search"
        onSubmit={preventDefault(onSubmit)}
      >
        <Surface
          title={I18n.t(
            "javascript.components.placement_search.workers_assigned.title"
          )}
        >
          <Flex column>
            <WorkersAssignedForm
              formState={formState}
              onPrimaryWorkerChanged={setFormAttribute(
                "primaryWorkerAssignedId"
              )}
              onWorkerUnassigned={workerId => {
                setFormState({
                  agencyWorkers: formState.agencyWorkers.filter(
                    ({ id }) => id !== workerId
                  ),
                });
              }}
            />
            <AddWorkers
              childId={childId}
              onSubmit={newWorkers => {
                const agencyWorkers = [
                  ...formState.agencyWorkers,
                  ...newWorkers,
                ];
                setFormState({
                  ...formState,
                  agencyWorkers,
                  primaryWorkerAssignedId:
                    formState.primaryWorkerAssignedId || agencyWorkers[0].id,
                });
              }}
              workersAssigned={formState.agencyWorkers}
            />
          </Flex>
        </Surface>
        <If condition={showAssignedAgencies}>
          <Surface
            title={I18n.t(
              "javascript.components.placement_search.agencies_assigned.title"
            )}
          >
            <Flex column>
              <AgenciesAssignedForm
                formState={formState}
                onUnassign={id =>
                  setFormState({
                    ...formState,
                    assignedSearches: formState.assignedSearches.filter(
                      as => as.agency.id !== id
                    ),
                  })()
                }
              />
              <AddAgencies
                agencyId={agencyId}
                onSubmit={newAgencies =>
                  setFormState({
                    ...formState,
                    assignedSearches: [
                      ...formState.assignedSearches,
                      ...newAgencies.map(agency => ({ agency })),
                    ],
                  })
                }
                assignedSearches={formState.assignedSearches}
              />
            </Flex>
          </Surface>
        </If>
        <PlacementSearchDetailsForm
          formState={formState}
          setFormAttribute={setFormAttribute}
          childId={childId}
        />
      </SurfaceForm>
    </LoadingOverlay>
  );
};

PlacementSearchCreate.propTypes = {
  agencyId: BintiPropTypes.ID,
  childId: BintiPropTypes.ID,
  showAssignedAgencies: PropTypes.bool,
};

export default PlacementSearchCreate;
