import {
  InputDate,
  InputDropdown,
  InputText,
  InputTextarea,
  InputHidden,
  PageContainer,
  Notice,
  SurfaceFieldset,
} from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import T from "@components/T";

const CreateOrEditService = ({
  serviceTemplates,
  service,
  categories,
  fundingSources,
  errors,
}) => {
  const [serviceList, setServiceList] = useState({
    category: service.serviceCategories[0],
    values: serviceTemplates.map(template => [template.name, template.id]),
  });
  const [isRecurring, setIsRecurring] = useState(Boolean(service.rrule));
  const [fundingSource, setFundingSource] = useState(service.fundingSourceId);

  const updateServiceList = category => {
    const values = serviceTemplates
      .filter(template => template.serviceCategories.includes(category))
      .map(template => [template.name, template.id]);

    setServiceList({ category, values });
  };

  const categoryAndServiceInputs = (
    <Fragment>
      <InputDropdown
        label={<T t="create_or_edit_service.category" />}
        value={serviceList.category || ""}
        values={categories}
        onChange={updateServiceList}
        required
      />
      {serviceList.category && (
        <InputDropdown
          label={<T t="create_or_edit_service.service" />}
          name="service[service_template_id]"
          value={service.serviceTemplateId || ""}
          values={serviceList.values}
          hideBlank
          required
        />
      )}
    </Fragment>
  );

  const referredDateInput = (
    <InputDate
      label={<T t="create_or_edit_service.referred_on" />}
      name="service[referred_date]"
      value={service.referredDate}
    />
  );

  const offeredDateInput = (
    <InputDate
      label={<T t="create_or_edit_service.offered_on" />}
      name="service[offered_date]"
      value={service.offeredDate}
    />
  );

  const acceptedDateInput = (
    <InputDate
      label={<T t="create_or_edit_service.accepted_on" />}
      name="service[accepted_date]"
      value={service.acceptedDate}
    />
  );

  const dateInputLabelKey = isRecurring ? "start_date" : "service_date";
  const frequencyAndStartAndEndDateInputs = (
    <Fragment>
      <InputDropdown
        label={<T t="create_or_edit_service.frequency" />}
        name="service[rrule]"
        blankOptionText="One-Time"
        value={service.rrule || ""}
        values={[
          ["Daily", "FREQ=DAILY"],
          ["Weekly", "FREQ=WEEKLY"],
          ["Monthly", "FREQ=MONTHLY"],
        ]}
        onChange={rruleString => setIsRecurring(Boolean(rruleString))}
      />
      <InputDate
        label={<T t={`create_or_edit_service.${dateInputLabelKey}`} />}
        name="service[start_date]"
        value={service.startDate}
      />
      <Choose>
        <When condition={isRecurring}>
          <InputDate
            label={<T t="create_or_edit_service.end_date" />}
            name="service[end_date]"
            value={service.endDate}
          />
        </When>
        <When condition={!isRecurring}>
          <InputHidden name="service[end_date]" />
        </When>
      </Choose>
    </Fragment>
  );

  const fundingSourcesInput = fundingSources.length > 0 && (
    <InputDropdown
      label={<T t="create_or_edit_service.funding_source" />}
      name="service[new_funding_source_id]"
      value={fundingSource || ""}
      values={fundingSources.map(source => [source.name, source.id])}
      onChange={setFundingSource}
    />
  );

  const billableAmountInput = (
    <InputText
      type="number"
      label={<T t="create_or_edit_service.billable_amount" />}
      name="service[billable_amount]"
      value={service.billableAmount}
      step="0.01"
    />
  );

  const detailsInput = (
    <InputTextarea
      label={<T t="create_or_edit_service.details" />}
      name="service[details]"
      value={service.details || ""}
    />
  );

  return (
    <PageContainer>
      {errors && errors.length > 0 && (
        <Notice title="Cannot save service" errorList={errors}>
          Cannot save service because:
        </Notice>
      )}
      <SurfaceFieldset
        title={<T t="create_or_edit_service.input_panel_title" />}
      >
        {categoryAndServiceInputs}
        {referredDateInput}
        {offeredDateInput}
        {acceptedDateInput}
      </SurfaceFieldset>
      <SurfaceFieldset title={<T t="create_or_edit_service.service_used" />}>
        {frequencyAndStartAndEndDateInputs}
        {fundingSourcesInput}
        {billableAmountInput}
        {detailsInput}
      </SurfaceFieldset>
    </PageContainer>
  );
};

export default CreateOrEditService;

const serviceCategoriesShape = PropTypes.arrayOf(PropTypes.string);

CreateOrEditService.propTypes = {
  serviceTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      serviceCategories: serviceCategoriesShape.isRequired,
    })
  ).isRequired,
  service: PropTypes.shape({
    id: PropTypes.number,
    serviceCategories: serviceCategoriesShape,
    serviceTemplateId: PropTypes.number,
    name: PropTypes.string,
    referredDate: PropTypes.string,
    offeredDate: PropTypes.string,
    acceptedDate: PropTypes.string,
    used: PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    rrule: PropTypes.string,
    fundingSourceId: PropTypes.number,
    billableAmount: PropTypes.number,
    details: PropTypes.string,
  }).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  fundingSources: PropTypes.array.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};
