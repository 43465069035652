import { ArrayDataTable, Layout, Surface } from "@heart/components";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";

import {
  BarElement,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Tooltip,
} from "chart.js";

const RecruitmentSourcesTable = ({
  title,
  columns,
  data,
  xAxisLabel,
  yAxisLabel,
}) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisLabel,
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel,
        },
      },
    },
  };

  const chartData = {
    labels: data.histogram.map(d => d.label_1),
    datasets: [
      {
        data: data.histogram.map(d => d.count),
        backgroundColor: "#1774e5", // binti blue
      },
    ],
  };

  return (
    <Layout
      main={{
        content: (
          <Surface>
            <div className="chart-container">
              <Bar options={options} data={chartData} width={"80%"} />
            </div>
            <ArrayDataTable title={title} columns={columns} data={data.table} />
          </Surface>
        ),
      }}
    />
  );
};

RecruitmentSourcesTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.object,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
};

export default RecruitmentSourcesTable;
