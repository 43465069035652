import Honeybadger from "@honeybadger-io/js";

$(() => {
  Honeybadger.configure(Binti.honeybadgerConfig);
  if (process.env.NODE_ENV === "development") {
    // When you configure HB for development mode, all you get is a "console.debug"
    // message saying that the notification was dropped, but the contents are not included.
    // There's an email thread between engineering@binti.com and HB support, but in the
    // meantime, let's just monkeypatch Honeybadger.notify.
    //
    // TODO ENG-7413 Check for emails from HB to see if they've got a solution for us
    //
    // eslint-disable-next-line no-console
    Honeybadger.notify = console.error.bind(
      null,
      "Honeybadger Notice (dev mode):"
    );
  }

  if (Binti.currentUserId) {
    Honeybadger.setContext({ user_id: Binti.currentUserId });
  }

  Honeybadger.setContext({ tags: "javascript" });
});
