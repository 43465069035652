import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  InputDropdown,
  InputText,
  Text,
} from "@heart/components";
import { Spinner } from "@heart/components/icon/Icon";
import { PropTypes } from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";
import AgencyDropdown from "@components/shared/AgencyDropdown";
import BannerContainer from "@components/shared/banner/BannerContainer";

import AgencyRelationships from "@graphql/queries/AgencyRelationships.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const { t } = translationWithRoot("permissions.agency_relationships");

const CreateAgencyRelationship = gql`
  mutation CreateAgencyRelationship(
    $label: String
    $sourceAgencyId: ID!
    $targetAgencyId: ID!
    $permissionRuleSetId: ID
  ) {
    createAgencyRelationship(
      input: {
        label: $label
        sourceAgencyId: $sourceAgencyId
        targetAgencyId: $targetAgencyId
        permissionRuleSetId: $permissionRuleSetId
      }
    ) {
      agencyRelationship {
        id
      }
    }
  }
`;

const PermissionRuleSets = gql`
  query PermissionRuleSets {
    permissionRuleSets {
      id
      label
    }
  }
`;

const CreateAgencyRelationshipForm = () => {
  const {
    data: permissionRuleSetsData,
    loading: permissionRuleSetsLoading,
    error: permissionRuleSetsError,
  } = useQuery(PermissionRuleSets);

  const [createAgencyRelationship, { loading: adding, error: addingError }] =
    useMutation(CreateAgencyRelationship, {
      refetchQueries: [{ query: AgencyRelationships }],
    });

  const create = data => {
    const fd = new FormData(data.target);
    createAgencyRelationship({
      variables: {
        label: fd.get("label"),
        sourceAgencyId: fd.get("sourceAgencyId"),
        targetAgencyId: fd.get("targetAgencyId"),
        permissionRuleSetId: fd.get("permissionRuleSetId"),
      },
    });
  };

  const permissionRuleSets = permissionRuleSetsData?.permissionRuleSets || [];
  const error = addingError || permissionRuleSetsError;

  if (adding || permissionRuleSetsLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <BannerContainer type="error" message={`Ooopsie! ${error.message}`} />
    );
  }

  return <Form permissionRuleSets={permissionRuleSets} onSubmit={create} />;
};

const Form = ({ permissionRuleSets, onSubmit }) => (
  <Flex column>
    <Text textStyle="emphasis-200">Create Agency Relationship</Text>
    <form onSubmit={onSubmit} className="contains-inputs">
      <InputText
        id="label"
        label={t("relationship_label")}
        name="label"
        required
        rows={1}
        type="text"
      />
      <AgencyDropdown
        label={t("source_agency")}
        id="sourceAgencyId"
        name="sourceAgencyId"
        required
      />
      <InputDropdown
        name="permissionRuleSetId"
        id="permissionRuleSetId"
        label={t("permission_rule_set")}
        values={permissionRuleSets.map(({ id, label }) => ({
          label: label,
          value: id,
        }))}
        required
      />
      <AgencyDropdown
        label={t("target_agency")}
        id="targetAgencyId"
        name="targetAgencyId"
        required
      />
      {/* TODO ENG-12656 to shrink button */}
      <div>
        <Button type="submit">{t("create_agency_relationship")}</Button>
      </div>
    </form>
  </Flex>
);

Form.propTypes = {
  permissionRuleSets: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, id: BintiPropTypes.ID })
  ),
  onSubmit: PropTypes.func,
};

export default CreateAgencyRelationshipForm;
