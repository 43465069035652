import { useQuery } from "@apollo/client";
import { PageContainer, Surface, ArrayDataTable } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";

import FamilyFindingClearPersonReportDocuments from "@graphql/queries/FamilyFindingClearPersonReportDocuments.graphql";

import tableColumns, { getUploadDateColumnIndex } from "./tableColumns";

const { t } = translationWithRoot("family_finding.documents.documents_table");

const DocumentsTable = ({ agencyHumanId, displayAssociatedKin = false }) => {
  const { data, loading } = useQuery(FamilyFindingClearPersonReportDocuments, {
    variables: { agencyHumanId },
  });
  const documents = data?.familyFindingClearPersonReportDocuments || [];

  return (
    <PageContainer fullWidth>
      <Surface hideTitle title={t("table_title")}>
        <ArrayDataTable
          title={t("table_title")}
          columns={tableColumns({ displayAssociatedKin, documents })}
          data={documents}
          defaultSortAttributes={{
            columnIndex: getUploadDateColumnIndex({ displayAssociatedKin }),
            direction: "DESC",
          }}
          loading={loading}
        />
      </Surface>
    </PageContainer>
  );
};
DocumentsTable.propTypes = {
  agencyHumanId: PropTypes.string.isRequired,
  displayAssociatedKin: PropTypes.bool,
};

export default DocumentsTable;
