import {
  Surface,
  Breadcrumbs,
  Link,
  Pill,
  Layout,
  Actions,
  GraphQLDataTable,
} from "@heart/components";
import I18n from "i18n-js";
import { toLower } from "lodash";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

import PermanencyGoals from "@graphql/queries/PermanencyGoals.graphql";

import {
  PERMANENCY_GOAL_STATUSES,
  PERMANENCY_GOAL_EXITED_CARE_REASONS,
} from "@root/constants";

const { t: tHeaderLinks } = translationWithRoot("admin.header_links", {
  escapeJavascriptRoot: true,
});
const { t: tReport } = translationWithRoot("permanency_report");
const { t: tEnum } = translationWithRoot("activerecord.enums", {
  escapeJavascriptRoot: true,
});

const baseCsvUrl = "permanency/download.csv";

const PermanencyReport = () => {
  const [csvUrl, setCsvUrl] = useState();

  return (
    <Surface hideTitle title="Permanency Report">
      <GraphQLDataTable
        query={PermanencyGoals}
        queryTitle="permanencyGoals"
        actions={
          <Actions
            primaryHref={csvUrl}
            primaryText={I18n.t("common.download_csv")}
          />
        }
        filters={[
          {
            label: tReport("columns.child_name"),
            type: "search",
            field: "childNameContains",
          },
          {
            label: tReport("columns.goal"),
            type: "dropdown",
            field: "goalEq",
            values: Object.keys(PERMANENCY_GOAL_EXITED_CARE_REASONS).map(
              rubyEnumValue => ({
                value: rubyEnumValue.toUpperCase(),
                label: tEnum(`permanency_goal.goal.${rubyEnumValue}`),
              })
            ),
          },
          {
            label: tReport("columns.status"),
            type: "dropdown",
            field: "statusEq",
            values: Object.keys(PERMANENCY_GOAL_STATUSES).map(
              rubyEnumValue => ({
                value: rubyEnumValue.toUpperCase(),
                label: tEnum(`permanency_goal.status.${rubyEnumValue}`),
              })
            ),
          },
        ]}
        columns={[
          {
            columnName: { name: tReport("columns.child_name") },
            id: "child_name",
            sortBy: "child_name",
            cell: ({ child }) => (
              <Link href={child.linkToView}>{child.fullName}</Link>
            ),
          },
          {
            columnName: { name: tReport("columns.child_age") },
            id: "child_age",
            sortBy: "child_age",
            cell: "child.age",
          },
          {
            columnName: { name: tReport("columns.goal") },
            id: "goal",
            sortBy: "goal",
            // TODO ENG-13935 in the mocks, this is a link
            cell: ({ goal }) => tEnum(`permanency_goal.goal.${toLower(goal)}`),
          },
          {
            columnName: { name: tReport("columns.status") },
            id: "status",
            sortBy: "status",
            cell: ({ status }) => (
              <Pill
                variant={
                  {
                    ACTIVE: "alert",
                    FINALIZED: "success",
                    DISRUPTED: "warning",
                    HISTORICAL: "info",
                  }[status]
                }
                text={tEnum(`permanency_goal.status.${toLower(status)}`)}
              />
            ),
          },
          {
            columnName: { name: tReport("columns.referring_county") },
            id: "referring_county",
            sortBy: "referring_county",
            cell: ({ child }) => {
              if (!child.referringAgency) return null;
              return (
                <Link href={child.referringAgency.linkToView}>
                  {child.referringAgency.name}
                </Link>
              );
            },
          },
          {
            columnName: { name: tReport("columns.placing_county") },
            id: "placing_county",
            cell: ({ agencyPlacement, nonBintiFamilyCounty }) => {
              if (agencyPlacement?.placingCounty) {
                return agencyPlacement.placingCounty;
              }
              return nonBintiFamilyCounty;
            },
          },
          {
            columnName: { name: tReport("columns.permanency_placement_name") },
            id: "permanency_placement_name",
            sortBy: "permanency_placement_name",
            cell: "agencyPlacement.displayName",
          },
          {
            columnName: { name: tReport("columns.marked_active_date") },
            id: "marked_active_date",
            sortBy: "marked_active_date",
            cell: "markedActiveDate",
          },
          {
            columnName: {
              name: tReport("columns.finalized_date"),
            },
            id: "finalized_date",
            sortBy: "finalized_date",
            cell: "finalizedDate",
          },
          {
            columnName: { name: tReport("columns.disrupted_date") },
            id: "disrupted_date",
            sortBy: "disrupted_date",
            cell: "disruptedDate",
          },
          {
            columnName: { name: tReport("columns.worker_assigned") },
            id: "worker_assigned",
            cell: ({ child }) => {
              if (!child.caseCarryingWorkers) return null;
              return (
                <Fragment>
                  {child.caseCarryingWorkers.map(worker => (
                    <Link key={worker.id} href={worker.linkToView}>
                      {worker.name}{" "}
                    </Link>
                  ))}
                </Fragment>
              );
            },
          },
        ]}
        onFilterChange={filters => {
          const queryParams = new URLSearchParams(filters);
          setCsvUrl(`${baseCsvUrl}?${queryParams}`);
        }}
      />
    </Surface>
  );
};

const PermanencyReportPage = () => (
  <Layout
    pageTitle={tHeaderLinks("permanency_report")}
    breadcrumbs={
      <Breadcrumbs
        pages={[
          {
            label: tHeaderLinks("reports"),
            href: "/", // There is no landing page for reports
          },
          {
            label: tHeaderLinks("permanency_report"),
            href: "#",
          },
        ]}
      />
    }
    main={{ content: <PermanencyReport /> }}
  />
);

export default PermanencyReportPage;
