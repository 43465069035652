import { InputDate } from "@heart/components";
import PropTypes from "prop-types";
import { Component } from "react";

import { DocumentsClientContext } from "../../clients/DocumentsClient";

class DocumentDateSignedInput extends Component {
  setDateSigned = client => date => {
    const { doc } = this.props;

    client.setDateSigned(doc, date);
  };

  render() {
    const { backgroundLoading } = this.props;

    return (
      <DocumentsClientContext.Consumer>
        {client => (
          <div>
            <InputDate
              label="Date signed"
              disabled={backgroundLoading}
              onChange={this.setDateSigned(client)}
            />
          </div>
        )}
      </DocumentsClientContext.Consumer>
    );
  }
}

DocumentDateSignedInput.propTypes = {
  doc: PropTypes.object.isRequired,
  backgroundLoading: PropTypes.bool,
};

export default DocumentDateSignedInput;
