import { Flex } from "@heart/components";
import PropTypes from "prop-types";
import React, { useState } from "react";

import AgencyWorkerRoleDropdown from "./AgencyWorkerRoleDropdown";
import CanChangeStatusCheckbox from "./CanChangeStatusCheckbox";
import CanOverrideApprovalCheckbox from "./CanOverrideApprovalCheckbox";

const {
  default: AgencyWorkerAgencyDropdown,
} = require("./AgencyWorkerAgencyDropdown");

const AgencyWorkerAgencyAndRoleDropdowns = ({
  isBintiAdmin,
  workerId,
  userAgencyId,
  explicitCanOverrideApprovalExisting,
  explicitCanOverrideApprovalRoles,
  canGrantOverrideApproval,
  explicitCanChangeStatusExisting,
  explicitCanChangeStatusRoles,
}) => {
  const [selectedAgencyId, setSelectedAgencyId] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null);

  return (
    <Flex gap="100" column>
      <AgencyWorkerAgencyDropdown
        hidden={!isBintiAdmin}
        workerId={workerId}
        selectedAgencyId={
          isBintiAdmin ? selectedAgencyId : userAgencyId.toString()
        }
        setSelectedAgencyId={setSelectedAgencyId}
      />
      <AgencyWorkerRoleDropdown
        workerId={workerId}
        userAgencyId={userAgencyId}
        selectedAgencyId={selectedAgencyId}
        selectedRoleId={selectedRoleId}
        setSelectedRoleId={setSelectedRoleId}
      />
      <CanOverrideApprovalCheckbox
        workerId={workerId}
        selectedRoleId={selectedRoleId}
        explicitCanOverrideApprovalExisting={
          explicitCanOverrideApprovalExisting
        }
        explicitCanOverrideApprovalRoles={explicitCanOverrideApprovalRoles}
        canGrantOverrideApproval={canGrantOverrideApproval}
      />
      <CanChangeStatusCheckbox
        workerId={workerId}
        selectedRoleId={selectedRoleId}
        explicitCanChangeStatusExisting={explicitCanChangeStatusExisting}
        explicitCanChangeStatusRoles={explicitCanChangeStatusRoles}
      />
    </Flex>
  );
};

AgencyWorkerAgencyAndRoleDropdowns.propTypes = {
  isBintiAdmin: PropTypes.bool,
  workerId: PropTypes.number,
  userAgencyId: PropTypes.number,
  explicitCanOverrideApprovalExisting: PropTypes.bool,
  explicitCanOverrideApprovalRoles: PropTypes.arrayOf(PropTypes.number),
  canGrantOverrideApproval: PropTypes.bool,
  explicitCanChangeStatusExisting: PropTypes.bool,
  explicitCanChangeStatusRoles: PropTypes.arrayOf(PropTypes.number),
};

export default AgencyWorkerAgencyAndRoleDropdowns;
