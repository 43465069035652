import {
  InputDate,
  InputDropdown,
  InputRadioGroup,
  InputText,
  InputTextarea,
  MultiInputTemplate,
} from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "background_check_exemptions.interview_log_inputs"
);

const ExemptionInterviewLog = ({
  setFormAttribute,
  interviewMethodData,
  exemptionInterviews,
}) => (
  <Fragment>
    <MultiInputTemplate
      title={t("interview")}
      onChange={setFormAttribute("exemptionInterviews")}
      onDelete={setFormAttribute("exemptionInterviewIdsToDelete")}
      startingValues={exemptionInterviews || []}
      defaultValues={{
        notes: "",
        tribePresence: "not_applicable",
        interviewMethod: "",
      }}
      sectionInputs={({ getMultiInputProps }) => (
        <Fragment>
          <InputText
            {...getMultiInputProps({ id: "interviewee" })}
            type="text"
            label={t("interviewee")}
          />
          <InputDate
            {...getMultiInputProps({ id: "interviewDate" })}
            label={t("interview_date")}
          />
          <InputDropdown
            {...getMultiInputProps({ id: "interviewMethod" })}
            label={t("interview_method")}
            values={interviewMethodData.map(method => ({
              label: I18n.t(
                `activerecord.enums.background_check_exemption.interview_methods.${method}`
              ),
              value: method,
            }))}
          />
          <InputRadioGroup
            {...getMultiInputProps({ id: "tribePresence" })}
            label={t("is_tribe_present")}
            values={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
              { label: "Not applicable", value: "not_applicable" },
            ]}
          />
          <InputTextarea
            {...getMultiInputProps({ id: "notes" })}
            label={t("notes")}
            rows={5}
          />
        </Fragment>
      )}
      addSectionText={t("add_another")}
      removeSectionText={t("remove")}
    />
  </Fragment>
);

ExemptionInterviewLog.propTypes = {
  setFormAttribute: PropTypes.func.isRequired,
  interviewMethodData: PropTypes.array.isRequired,
  exemptionInterviews: PropTypes.array.isRequired,
};

export default ExemptionInterviewLog;
