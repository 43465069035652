import { useQuery } from "@apollo/client";
import { HeartTable, Link } from "@heart/components";
import I18n from "i18n-js";

import DefaultRolesQuery from "@graphql/queries/DefaultRoles";

import T from "../T";

const { Table, Thead, Tbody, Tr, Th, Td } = HeartTable;

const DefaultRolesTable = () => {
  const {
    data: { defaultRoles } = { defaultRoles: [] },
    error,
    loading,
  } = useQuery(DefaultRolesQuery);

  if (loading) return <p>{I18n.t("common.loading")}</p>;
  if (error) return <T t="permissions.error_loading_roles" />;
  if (defaultRoles.length === 0) return <T t="permissions.no_roles_found" />;

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>
            <T t="permissions.role_name" />
          </Th>
          <Th colSpan="2"></Th>
        </Tr>
      </Thead>
      <Tbody>
        {defaultRoles.map(role => (
          <Tr key={role.id}>
            <Td>{role.name}</Td>
            <Td>
              <Link href={role.path}>
                <T t="permissions.show_role" />
              </Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

DefaultRolesTable.propTypes = {};

export default DefaultRolesTable;
