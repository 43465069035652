import { Surface, Link, DetailsTable } from "@heart/components";
import classnames from "classnames";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { Table, Tbody, Thead, Tr, Th, Td } from "@components/reusable_ui/Table";

import styles from "../people/PersonCard.module.scss";

const OrganizationProfile = ({
  agencyPlacementProviderProfile,
  address,
  licenseManagers,
  licenseInformation,
  applications,
  showLicenseManagerUsername,
}) => (
  <Fragment>
    <Surface
      title={I18n.t("javascript.components.provider_profile.provider_details")}
    >
      <DetailsTable
        details={[
          {
            label: I18n.t(
              "javascript.components.provider_profile.provider_name"
            ),
            value: agencyPlacementProviderProfile.providerName,
          },
          {
            label: I18n.t("javascript.components.provider_profile.provider_id"),
            value: agencyPlacementProviderProfile.providerExternalId,
          },
          {
            label: I18n.t("javascript.components.provider_profile.fein"),
            value: agencyPlacementProviderProfile.providerFein,
          },
        ]}
      />
    </Surface>
    <Surface title={I18n.t("admin.common.address.home_address")}>
      <div className={classnames("space-below-2", styles.personPanel)}>
        <Table aria-label={I18n.t("admin.common.address.home_address")}>
          <Tbody>
            <Tr>
              <Th>{I18n.t("admin.common.address.physical_address")}</Th>
              <Td>
                {address.street}
                <br />
                {address.city}, {address.state} {address.zipcode}
                <br />
                {address.county}
                <br />
              </Td>
            </Tr>
            <If condition={!address.mailing_address_same_as_physical}>
              <Tr>
                <Th>{I18n.t("admin.common.address.mailing_address")}</Th>
                <Td>
                  {address.mailing_street}
                  <br />
                  {address.mailing_city}, {address.mailing_state}{" "}
                  {address.mailing_zipcode}
                  <br />
                </Td>
              </Tr>
            </If>
          </Tbody>
        </Table>
        <If condition={address.mailing_address_same_as_physical}>
          {I18n.t("admin.common.address.mailing_address_same_as_physical")}
        </If>
      </div>
    </Surface>
    <Surface
      title={I18n.t("javascript.components.provider_profile.license_managers")}
    >
      <Table>
        <Thead>
          <Tr>
            <Th>{I18n.t("admin.common.name")}</Th>
            <Th>{I18n.t("admin.common.role")}</Th>
            <Th>{I18n.t("admin.common.email")}</Th>
            <If condition={showLicenseManagerUsername}>
              <Th>{I18n.t("admin.common.username")}</Th>
            </If>
            <Th>{I18n.t("admin.common.phone_number")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {licenseManagers.map(lm => (
            <Tr key={lm.id}>
              <Td>{lm.name}</Td>
              <Td>{lm.role}</Td>
              <Td>{lm.mailto_address}</Td>
              <If condition={showLicenseManagerUsername}>
                <Td>{lm.username}</Td>
              </If>
              <Td>{lm.phone_number}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Surface>
    <Surface
      title={I18n.t("admin.applications.application.license_information")}
    >
      <DetailsTable
        details={[
          {
            label: I18n.t("admin.common.capacity"),
            value: licenseInformation.capacity
              ? `${licenseInformation.capacity} beds`
              : "Please select the number of beds",
          },
          {
            label: I18n.t("decorators.agency_placement_decorator.approved_for"),
            value: licenseInformation.approved_for,
          },
          {
            label: I18n.t(
              "javascript.components.provider_profile.license_specialization"
            ),
            value: licenseInformation.specialization,
          },
        ]}
      />
    </Surface>
    <Surface
      title={I18n.t("application.applications")}
      subtitle={I18n.t(
        "javascript.components.provider_profile.previous_applications"
      )}
    >
      <div className={classnames("space-below-2", styles.personPanel)}>
        <Table>
          <Thead>
            <Tr>
              <Th>
                {I18n.t(
                  "admin.applications.application.other_applications.columns.application_type"
                )}
              </Th>
              <Th>
                {I18n.t(
                  "admin.applications.application.other_applications.columns.application_template"
                )}
              </Th>
              <Th>
                {I18n.t(
                  "admin.applications.application.other_applications.columns.documents"
                )}
              </Th>
              <Th>{"Organization Profile"}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {applications.map(application => (
              <Tr key={application.id}>
                <Td>
                  {application.type === "InitialApplication"
                    ? I18n.t("application.initial")
                    : I18n.t("application.renewal")}
                </Td>
                <Td>{application.application_template}</Td>
                <Td>
                  <Link href={application.documents}>
                    {I18n.t(
                      "admin.applications.application.other_applications.columns.documents"
                    )}
                  </Link>
                </Td>
                <Td>
                  <Link
                    href={application.organization_profile_link}
                    disabled={application.is_current_app}
                  >
                    {"profile"}
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </Surface>
  </Fragment>
);

OrganizationProfile.propTypes = {
  agencyPlacementProviderProfile: PropTypes.shape({
    providerName: PropTypes.string,
    providerExternalId: PropTypes.string,
    providerFein: PropTypes.string,
  }).isRequired,
  address: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    county: PropTypes.string,
    mailing_address_same_as_physical: PropTypes.bool.isRequired,
    mailing_street: PropTypes.string,
    mailing_city: PropTypes.string,
    mailing_state: PropTypes.string,
    mailing_zipcode: PropTypes.string,
  }).isRequired,
  licenseManagers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      role: PropTypes.string,
      email: PropTypes.string,
      phone_number: PropTypes.string,
    }).isRequired
  ),
  licenseInformation: PropTypes.shape({
    capacity: PropTypes.integer,
    approved_for: PropTypes.string,
    specialization: PropTypes.string,
  }).isRequired,
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      application_template: PropTypes.string,
      documents: PropTypes.string,
      organization_profile_link: PropTypes.string,
      is_current_app: PropTypes.bool,
    }).isRequired
  ),
  showLicenseManagerUsername: PropTypes.bool,
};

export default OrganizationProfile;
