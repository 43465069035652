import { Fragment } from "react";

import LocalBannerSubscriber from "@components/shared/banner/LocalBannerSubscriber";

import RailsNotifications from "./rails/RailsNotifications";
import ToastContainer from "./toast/ToastContainer";

/**
 * This is where all Binti page notifications will go!  This includes:
 *   * A `<ToastContainer>` so that calls to `toast()` will succeed
 *   * A `<LocalBannerSubscriber>` so that calls to `useBanner()` will produce banners here
 *   * All of our Rails flash messages (see the `rails/*` stories)
 *
 * You won't need to use this component directly - it's not exposed in the Heart public API
 * and is only listed here as documentation for how notifications in Rails work. One is
 * rendered automatically on ActiveAdmin pages and also on the main application layout.
 *
 * In the future, this component will be rendered by `<Layout>` instead of being rendered directly
 * by Rails.  By rendering notifications within React instead of in a Rails ERB, flash notifications
 * can now be under the same component tree as the main page content.  This means that pages built
 * using `<Layout>` will be able to position the notifications where they belong, including sidebars
 * and page margins.
 */
const Notifications = ({ flashMessages }) => (
  <Fragment>
    <ToastContainer />
    <LocalBannerSubscriber />
    <RailsNotifications flashMessages={flashMessages} />
  </Fragment>
);

Notifications.propTypes = {
  /** Passed into `RailsNotifications` */
  flashMessages: RailsNotifications.propTypes.flashMessages,
};

export default Notifications;
