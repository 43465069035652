import PropTypes from "prop-types";

import styles from "./ProfileSection.module.scss";

const TextField = ({ text }) => <div className={styles.fieldText}>{text}</div>;

TextField.propTypes = {
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
export default TextField;
