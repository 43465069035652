import { useMutation } from "@apollo/client";
import {
  Surface,
  SurfaceForm,
  Icons,
  DetailsTable,
  InputDate,
  InputDropdown,
  InputTextarea,
  Actions,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import UpdateAppeal from "@graphql/mutations/UpdateAppeal";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const FinalAppealOutcome = ({ appeal, refetch, appealOutcomes }) => {
  const [editing, setEditing] = useState(false);
  const [updateAppeal] = useMutation(UpdateAppeal);
  const handleAppeal = preventDefault(() => {
    updateAppeal({
      variables: {
        appealId: appeal.id,
        appealResolvedDate,
        appealOutcome,
        conditions,
        appealOutcomeNotes,
      },
      onCompleted: () => {
        refetch();
        setEditing(false);
      },
    });
  });

  const setStateToAppeal = () => {
    const {
      appealResolvedDate,
      appealOutcome,
      conditions,
      appealOutcomeNotes,
    } = appeal;
    setAppealResolvedDate(appealResolvedDate);
    setAppealOutcome(appealOutcome);
    setConditions(conditions);
    setAppealOutcomeNotes(appealOutcomeNotes);
    setEditing(false);
  };

  useEffect(setStateToAppeal, [appeal]);

  const [appealResolvedDate, setAppealResolvedDate] = useState(
    appeal.appealResolvedDate
  );
  const [appealOutcome, setAppealOutcome] = useState(
    appeal.appealOutcome || ""
  );
  const [conditions, setConditions] = useState(appeal.conditions);
  const [appealOutcomeNotes, setAppealOutcomeNotes] = useState(
    appeal.appealOutcomeNotes
  );

  if (editing)
    return (
      <SurfaceForm
        title={I18n.t("javascript.components.appeals.final_appeal_outcome")}
        actions={
          <Actions
            cancelAction={() => {
              setStateToAppeal();
            }}
          />
        }
        onSubmit={handleAppeal}
      >
        <InputDate
          label={I18n.t(
            "javascript.components.appeals.appeal_decision_received_date"
          )}
          name="appealResolvedDate"
          value={appealResolvedDate}
          onChange={setAppealResolvedDate}
          required={false}
        />
        <InputDropdown
          label={I18n.t("javascript.components.appeals.appeal_outcome")}
          values={appealOutcomes.map(([label, value]) => [label, value])}
          name="appealOutcome"
          value={appealOutcome}
          onChange={setAppealOutcome}
          required={false}
        />
        <InputTextarea
          label={I18n.t(
            "javascript.components.appeals.appeal_withdrawal_conditions"
          )}
          name="conditions"
          value={conditions}
          onChange={setConditions}
          required={false}
        />
        <InputTextarea
          label={I18n.t("javascript.components.appeals.appeal_outcome_notes")}
          name="appealOutcomeNotes"
          value={appealOutcomeNotes}
          onChange={setAppealOutcomeNotes}
          required={false}
        />
      </SurfaceForm>
    );

  return (
    <Surface
      title={I18n.t("javascript.components.appeals.final_appeal_outcome")}
      secondary={
        <Icons.Pencil
          description={I18n.t(
            "javascript.components.appeals.appeal_outcome_edit"
          )}
          onClick={() => setEditing(true)}
        />
      }
    >
      <DetailsTable
        details={[
          {
            label: I18n.t("javascript.components.appeals.appeal_outcome"),
            value: appealOutcome
              ? I18n.t(`javascript.components.appeal_outcomes.${appealOutcome}`)
              : "",
          },
          {
            label: I18n.t("javascript.components.appeals.appeal_outcome_date"),
            value: appealResolvedDate,
          },
          {
            label: I18n.t("javascript.components.appeals.conditions"),
            value: conditions,
          },
          {
            label: I18n.t("javascript.components.appeals.appeal_outcome_notes"),
            value: appealOutcomeNotes,
          },
        ]}
      />
    </Surface>
  );
};

FinalAppealOutcome.propTypes = {
  appeal: PropTypes.shape({
    id: BintiPropTypes.ID,
    appealResolvedDate: PropTypes.date,
    appealOutcome: PropTypes.string,
    conditions: PropTypes.string,
    appealOutcomeNotes: PropTypes.string,
  }),
  application: PropTypes.shape({
    id: BintiPropTypes.ID,
    type: PropTypes.string,
    statusDate: PropTypes.date,
  }),
  appealOutcomes: PropTypes.array,
  refetch: PropTypes.func,
};

export default FinalAppealOutcome;
