import { gql, useQuery } from "@apollo/client";
import { Flex, Link, Modal } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
import { adminAgencyWorkerPath } from "routes";

import { Table, Caption, Tbody, Tr, Td } from "@components/reusable_ui/Table";

import BintiPropTypes from "@lib/BintiPropTypes";
import prettyJson from "@lib/prettyJson";

const GetDebugInfo = gql`
  query pdfOfAllDocsDebugInfo($applicationId: ID!) {
    pdfOfAllDocsDebugInfo(applicationId: $applicationId) {
      honeybadgerApplicationQueryUrl
      honeybadgerJobIdQueryUrl
      jobId
      jobInfo
      events {
        initiatedAt
        metadata
        other
        queuingModelClass
        queuingModelId
        queuingOperation
        whodunnit
        whodunnitName
      }
    }
  }
`;

const PdfOfAllDocsDebuggingModal = ({ applicationId, isOpen, onClose }) => {
  const { data, loading } = useQuery(GetDebugInfo, {
    variables: { applicationId },
  });

  const pdfOfAllDocsDebugInfo = data?.pdfOfAllDocsDebugInfo;

  return (
    <Modal
      hidden={!isOpen}
      submitText="Close"
      onCancel={onClose}
      onSubmit={onClose}
      title="PDF of all docs debugging info"
      hideCancel
    >
      <Flex>
        <LoadingOverlay active={loading}>
          <If condition={pdfOfAllDocsDebugInfo}>
            <Table>
              <Caption>Events</Caption>
              <Tbody>
                {pdfOfAllDocsDebugInfo.events.map((event, index) => (
                  <Fragment key={event.initiatedAt}>
                    <Tr>
                      <Td>Initiated at</Td>
                      <Td>{event.initiatedAt}</Td>
                    </Tr>
                    <If condition={event.whodunnit}>
                      <Tr>
                        <Td>Initiated by</Td>
                        <Td>
                          <Link
                            href={adminAgencyWorkerPath(event.whodunnit)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {event.whodunnitName || "Acting User"}
                          </Link>
                        </Td>
                      </Tr>
                    </If>
                    <If condition={event.queuingModelId}>
                      <Tr>
                        <Td>Triggered by change to</Td>
                        <Td>
                          {event.queuingModelClass}({event.queuingModelId})
                          <If condition={event.queuingOperation}>
                            {" "}
                            [{event.queuingOperation}]
                          </If>
                        </Td>
                      </Tr>
                    </If>
                    <If condition={event.other}>
                      <Tr>
                        <Td>Additional info</Td>
                        <Td>{event.other}</Td>
                      </Tr>
                    </If>
                    <If condition={event.metadata}>
                      <Tr>
                        <Td>Debug info</Td>
                        <Td>
                          <pre>{prettyJson(event.metadata)}</pre>
                        </Td>
                      </Tr>
                    </If>
                    <If
                      condition={
                        index < pdfOfAllDocsDebugInfo.events.length - 1
                      }
                    >
                      <Tr>
                        <Td colSpan={2}></Td>
                      </Tr>
                    </If>
                  </Fragment>
                ))}
              </Tbody>
            </Table>
            <div className="space-above-2" />
            <Table>
              <Caption>Sidekiq debug info</Caption>
              <Tbody>
                <Tr>
                  <Td>Job ID</Td>
                  <Td>{pdfOfAllDocsDebugInfo.jobId}</Td>
                </Tr>
                <Tr>
                  <Td>Job Info</Td>
                  <Td>
                    <pre>{prettyJson(pdfOfAllDocsDebugInfo.jobInfo)}</pre>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <div className="space-above-2" />
            <Table>
              <Caption>Honeybadger links</Caption>
              <Tbody>
                <Tr>
                  <Td>
                    <Link
                      href={pdfOfAllDocsDebugInfo.honeybadgerJobIdQueryUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Possible Honeybadgers for this job
                    </Link>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Link
                      href={
                        pdfOfAllDocsDebugInfo.honeybadgerApplicationIdQueryUrl
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Possible Honeybadgers for this application (any job)
                    </Link>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </If>
        </LoadingOverlay>
      </Flex>
    </Modal>
  );
};

PdfOfAllDocsDebuggingModal.propTypes = {
  applicationId: BintiPropTypes.ID.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PdfOfAllDocsDebuggingModal;
