import { useMutation } from "@apollo/client";
import { Text, InputHidden } from "@heart/components";
import InputCsrfToken from "@heart/components/inputs/InputCsrfToken";
import serialize from "form-serialize";
import PropTypes from "prop-types";
import React from "react";

import preventDefault from "@lib/preventDefault";

const MutationForm = ({
  csrfToken,
  mutation,
  successUrl,
  failureUrl,
  children,
  onSuccess,
  onSubmit,
}) => {
  const [mutate, { data, error }] = useMutation(mutation, {
    onError: () => {},
  });
  const handleSubmit = preventDefault(() => {
    // Serialize data from the form
    const formData = serialize(event.target, { hash: true });

    // Call the mutation with the form data
    mutate({ variables: formData });
  });

  const form = (
    <form
      action="/graphql_form"
      method="POST"
      onSubmit={onSubmit || handleSubmit}
    >
      <InputCsrfToken csrfToken={csrfToken} />
      <InputHidden name="__mutation__" value={mutation.loc.source.body} />
      <InputHidden name="__successPath__" value={successUrl} />
      <InputHidden name="__failurePath__" value={failureUrl} />
      {children}
    </form>
  );

  if (data) {
    onSuccess(data);
  }

  if (error) {
    return (
      <React.Fragment>
        <Text textColor="danger-500">{error.message}</Text>
        {form}
      </React.Fragment>
    );
  }

  return form;
};

MutationForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  mutation: PropTypes.object.isRequired,
  successUrl: PropTypes.string.isRequired,
  failureUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default MutationForm;
