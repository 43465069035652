import { useQuery } from "@apollo/client";
import { DetailsTable } from "@heart/components";
import React from "react";

import Spinner from "@components/Spinner";

import HearingSessionQuery from "@graphql/queries/HearingSession.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const ViewHearingSession = ({ id }) => {
  const { data, loading } = useQuery(HearingSessionQuery, {
    variables: { id },
  });

  if (loading) {
    return <Spinner />;
  }

  const {
    hearingSession: { hearingNotes, hearingSessionDate },
  } = data || { hearingSession: {} };

  return (
    <DetailsTable
      details={[
        {
          label: I18n.t("javascript.components.appeals.hearing_session_notes"),
          value: hearingNotes,
        },
        {
          label: I18n.t("javascript.components.appeals.hearing_session_date"),
          value: hearingSessionDate,
        },
      ]}
    />
  );
};

ViewHearingSession.propTypes = {
  id: BintiPropTypes.ID,
};

export default ViewHearingSession;
