import { Button } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import OverrideModal from "@components/requirements/OverrideModal";

import { policy } from "@lib/graphqlHelpers";
import { isOverridable } from "@lib/overridableHelpers";

const OverrideActionModalLauncher = ({ fulfillment, onOverride }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  if (!policy(fulfillment).mayCreateOverrideRecord()) return false;

  const { requirement } = fulfillment;

  const disabled = !isOverridable({
    requirement,
    providerRole: "caseworker",
  });
  const notOverridableReason = "This record is required and may not be waived";

  const onSubmit = async ({ reason, details, optionKey, grantDate }) => {
    await onOverride({ reason, details, optionKey, grantDate }).then(() => {
      setIsModalOpen(false);
    });
  };

  return (
    <div>
      <OverrideModal
        {...{
          fulfillment,
          isModalOpen,
          onSubmit,
          onCloseModal: () => {
            setIsModalOpen(false);
          },
        }}
      />
      <Button
        variant="tertiary"
        description={disabled ? notOverridableReason : undefined}
        disabled={disabled}
        onClick={() => setIsModalOpen(true)}
      >
        Waive
      </Button>
    </div>
  );
};

OverrideActionModalLauncher.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  onOverride: PropTypes.func.isRequired,
};

export default OverrideActionModalLauncher;
