import {
  InputFilterable,
  PageContainer,
  Actions,
  SurfaceForm,
} from "@heart/components";
import stringify from "json-stringify-pretty-compact";
import { keys } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";

import preventDefault from "@lib/preventDefault";

import EZCopyTextarea from "../EZCopyTextarea";

const ANY_AGENCY = -1;
const GENERIC = 0;

class SendSignature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forms: props.forms,
      locales: null,
      selected: {
        agencyId: ANY_AGENCY,
        form: props.form,
        locale: "en",
      },
      requesting: false,
      result: props.result,
    };
  }

  submit = preventDefault(() => {
    const { selected } = this.state;

    this.setState({ requesting: true });
    $.ajax({
      method: "post",
      url: "/applications/request_form_signature",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        form_id: selected.form.id,
        locale: selected.locale,
      }),
      success: result => {
        this.setState({ result, requesting: false });
      },
      error: () => {
        alert("request failed"); // eslint-disable-line no-alert
        this.setState({ requesting: false });
      },
    });
  });

  selectAgency = selectedOption => {
    const agencyId = selectedOption.value;
    const { selected } = this.state;
    const newSelected = Object.assign({}, selected, { agencyId });

    const { forms } = this.props;
    const visibleForms = forms
      .filter(form => {
        if (agencyId === GENERIC) {
          return !form.agency_id;
        }
        if (agencyId === ANY_AGENCY) {
          return true;
        }

        return form.agency_id === agencyId;
      })
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return -1;
        return 0;
      });

    this.setState({ forms: visibleForms, selected: newSelected, result: null });
  };

  selectForm = selectedOption => {
    const formId = selectedOption.value;
    const form = this.props.forms.find(f => f.id === formId);
    const { selected } = this.state;
    const newSelected = Object.assign({}, selected, { form });

    const newLocales = keys(form.hellosign_template_id_translations);

    this.setState({ selected: newSelected, locales: newLocales, result: null });
  };

  selectLocale = selectedOption => {
    const locale = selectedOption.value;
    const { selected } = this.state;
    const newSelected = Object.assign({}, selected, { locale });

    this.setState({ selected: newSelected, result: null });
  };

  render() {
    const { agencies } = this.props;
    const { forms, selected, locales, result, requesting } = this.state;

    return (
      <PageContainer>
        <SurfaceForm
          title="Send Signatures"
          onSubmit={this.submit}
          actions={
            <Actions
              primaryText="Request Form Signature"
              primarySubmittingText="Requesting"
              isSubmitting={requesting}
              primaryDisabled={!selected.form}
            />
          }
        >
          <InputFilterable
            label="Agency"
            name="agency-select"
            id="signature-agency-select"
            onChange={this.selectAgency}
            options={[
              { value: ANY_AGENCY, label: "Any" },
              { value: GENERIC, label: "Generic" },
            ].concat(
              agencies.map(agency => ({
                value: agency.id,
                label: agency.name,
              }))
            )}
          />

          <InputFilterable
            label="Form"
            name="form-select"
            id="signature-form-select"
            onChange={this.selectForm}
            options={forms.map(form => ({
              value: form.id,
              label: `${form.name_translations.en} (${form.slug})`,
            }))}
          />

          {locales && (
            <InputFilterable
              label="Locale"
              onChange={this.selectLocale}
              defaultValue={{ value: "en", label: "en" }}
              options={locales.map(locale => ({
                value: locale,
                label: locale,
              }))}
            />
          )}

          {result && <EZCopyTextarea text={stringify(result)} />}
        </SurfaceForm>
      </PageContainer>
    );
  }
}

SendSignature.propTypes = {
  agencies: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  result: PropTypes.object,
  form: PropTypes.object,
};

export default SendSignature;
