import { Button } from "@heart/components";
import classnames from "classnames";
import { keys } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import T from "@components/T";

import DrawnInput from "./DrawnInput";
import styles from "./SignatureInput.module.scss";
import SignatureTextInput from "./SignatureTextInput";

const INPUT_TYPES = Object.freeze({
  drawn: {
    component: DrawnInput,
    label: <T t="bintisign.signature_input.signature_input_switcher.draw" />,
  },
  text: {
    component: SignatureTextInput,
    label: <T t="bintisign.signature_input.signature_input_switcher.type_in" />,
  },
});

/**
 * Control to let the user switch between types of signature input, e.g.
 * drawn vs text-generated signatures.
 */
const SignatureInputTypeSwitcher = props => {
  const [inputType, setInputType] = useState("drawn");
  const { component: TypedSignatureInput } = INPUT_TYPES[inputType];

  return (
    <div className={styles.signatureSwitcher}>
      <ul className={styles.switcherOptions} role="tablist">
        {keys(INPUT_TYPES).map(type => (
          <li key={type}>
            <Button
              className={classnames(styles.switcherOption, {
                [styles.selectedOption]: type === inputType,
              })}
              role="tab"
              aria-selected={(type === inputType).toString()}
              onClick={() => {
                setInputType(type);
              }}
            >
              {INPUT_TYPES[type].label}
            </Button>
          </li>
        ))}
      </ul>
      <TypedSignatureInput {...props} />
    </div>
  );
};

SignatureInputTypeSwitcher.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  updateSignatureUri: PropTypes.func.isRequired,
  canCancel: PropTypes.bool.isRequired,
  cancel: PropTypes.func,
  name: PropTypes.string,
};

export default SignatureInputTypeSwitcher;
