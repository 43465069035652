import { useMutation, useReactiveVar } from "@apollo/client";
import { Modal } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import PropTypes from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";
import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import CreateBackgroundCheckType from "@graphql/mutations/CreateBackgroundCheckType.graphql";
import BackgroundCheckTypes from "@graphql/queries/BackgroundCheckTypes.graphql";

import BackgroundCheckTypeForm from "./BackgroundCheckTypeForm";

const { t } = translationWithRoot("background_check_types");

const CreateBackgroundCheckTypeModal = ({ hidden, onClose }) => {
  const queryVariables = useReactiveVar(queryVariablesVar);

  const [createBackgroundCheckType, { loading }] = useMutation(
    CreateBackgroundCheckType,
    {
      onCompleted: onClose,
      refetchQueries: [
        { query: BackgroundCheckTypes, variables: queryVariables },
      ],
    }
  );

  const { formState, setFormAttribute } = useBintiForm();

  return (
    <Modal
      asForm
      hidden={hidden}
      title={t("create_new_bg_type")}
      submitting={loading}
      onSubmit={() => createBackgroundCheckType({ variables: formState })}
      submitText={t("create_new_bg_type")}
      onCancel={onClose}
    >
      <BackgroundCheckTypeForm
        formState={formState}
        setFormAttribute={setFormAttribute}
      />
    </Modal>
  );
};

CreateBackgroundCheckTypeModal.propTypes = {
  hidden: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateBackgroundCheckTypeModal;
