import { useQuery, useMutation } from "@apollo/client";
import { range, uniqueId } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import CreateApplicationReference from "@graphql/mutations/CreateApplicationReference.graphql";
import RemoveApplicationReference from "@graphql/mutations/RemoveApplicationReference.graphql";
import UpdateApplicationReference from "@graphql/mutations/UpdateApplicationReference.graphql";
import ApplicationReferences from "@graphql/queries/ApplicationReferences.graphql";

import MessageDisplay from "../MessageDisplay";
import useMessageDisplay from "../useMessageDisplay";
import Reference from "./Reference";
import ReferenceEditor from "./ReferenceEditor";
import addOrEditReference from "./addOrEditReference";
import removeReference from "./removeReference";

const getBlankFormId = (referredUserAgencyProfileId, applicationId) =>
  uniqueId(`blank-${referredUserAgencyProfileId || 1}-${applicationId}`);

const ReferencesForm = ({
  agencyId,
  applicationId,
  helpText,
  referencesRequired = 0,
  referredUserAgencyProfileId,
  readOnly,
}) => {
  const { data, error, refetch } = useQuery(ApplicationReferences, {
    variables: { applicationId },
  });

  /* If references for the application are required on a per-applicant
   *   basis, we need to filter through all the references associated with
   *   the application and only take into account those that are tied to
   *   the applicant in question via the referredUserAgencyProfileId
   */
  let savedReferences = data ? data.applicationReference : [];
  if (referredUserAgencyProfileId) {
    savedReferences = savedReferences.filter(
      ({ referredUserAgencyProfileId: profileId }) =>
        referredUserAgencyProfileId === parseInt(profileId, 10)
    );
  }
  const requiredRemaining = referencesRequired - savedReferences.length;

  const [requiredRemainingIds, setRequiredRemainingIds] = useState(
    range(requiredRemaining).map(() =>
      getBlankFormId(referredUserAgencyProfileId, applicationId)
    )
  );

  const [editing, setEditing] = useState({});
  /* localErrorMessages is an array that contains strings or JSX components
   * that are displayed in OtherAdultEditor component. We set state here so that
   * we can pass down global error states into this component.
   */
  const [localErrorMessages, setlocalErrorMessages] = useState([]);

  const setEditingForReference = ({ id, value }) => {
    setEditing({ ...editing, [id]: value });
  };
  /* When rendering each form, if we are reliant on an arbitrary index the
   *   form instances don't behave independently. For example, submitting one
   *   will shift the indices by 1 and put the reference data that was just
   *   submitted into the next form instance when the submitted one disappears.
   *   To avoid this issue, we determine uniqueIds to associate with each form
   *   instance.
   */
  useEffect(() => {
    if (requiredRemaining > requiredRemainingIds.length) {
      setRequiredRemainingIds([
        ...requiredRemainingIds,
        ...range(requiredRemaining - requiredRemainingIds.length).map(() =>
          getBlankFormId(referredUserAgencyProfileId, applicationId)
        ),
      ]);
    } else if (requiredRemaining < requiredRemainingIds.length) {
      /* When a form is submitted we remove the uniqueId from the list, so
       *   this case should only come into play when first rendering the forms.
       */
      setRequiredRemainingIds(
        requiredRemainingIds.splice(
          requiredRemainingIds.length - requiredRemaining
        )
      );
    }
  }, [data]);

  const messageDisplay = useMessageDisplay();

  const [createApplicationReference] = useMutation(CreateApplicationReference);
  const [removeApplicationReference] = useMutation(RemoveApplicationReference);
  const [updateApplicationReference] = useMutation(UpdateApplicationReference);

  if (error) return <pre>{error.toString()}</pre>;
  if (!data) return null;

  return (
    <section data-testid="reference-section">
      {/* Adding banner subscribers into individual components is an antipattern,
       *    but the applicant side doesn't have a top level BannerContainer. For other
       *    components that do have a top level BannerContainer, the useBanner hooks
       *    should render global banners without needing a banner subscriber.
       */}
      <p className="space-above-2 space-below-2">{helpText}</p>
      <div className="space-below-2" id="ref_error_banner">
        <MessageDisplay messageData={messageDisplay} />
      </div>

      {savedReferences.map(reference => {
        const setEditingForThisReference = value =>
          setEditingForReference({ id: reference.id, value });
        return editing[reference.id] ? (
          <ReferenceEditor
            key={reference.id}
            existingReference={reference}
            addOrEditReference={referenceParams => {
              addOrEditReference({
                applicationId,
                createApplicationReference,
                updateApplicationReference,
                messageDisplay,
                referenceOrFormId: reference.id,
                refetch,
                referenceParams,
                referredUserAgencyProfileId,
                requiredRemainingIds,
                setEditingForThisReference,
                setlocalErrorMessages,
              });
            }}
            setEditingForReference={setEditingForThisReference}
            localErrorMessages={localErrorMessages}
          />
        ) : (
          <Reference
            key={reference.id}
            reference={reference}
            agencyId={agencyId}
            readOnly={readOnly}
            removeReference={ref =>
              removeReference({
                messageDisplay: messageDisplay,
                reference: ref,
                refetch,
                removeApplicationReference,
              })
            }
            setEditingForReference={setEditingForThisReference}
            displayNotice={messageDisplay.displayNotice}
          />
        );
      })}

      <If condition={!readOnly}>
        {requiredRemainingIds.map(id => {
          const setEditingForThisReference = value =>
            setEditingForReference({ id: id, value });

          return (
            <ReferenceEditor
              key={id}
              addOrEditReference={referenceParams => {
                addOrEditReference({
                  applicationId,
                  createApplicationReference,
                  updateApplicationReference,
                  messageDisplay,
                  referenceOrFormId: id,
                  refetch,
                  referenceParams,
                  referredUserAgencyProfileId,
                  requiredRemainingIds,
                  setEditingForThisReference,
                  setlocalErrorMessages: setlocalErrorMessages,
                });
              }}
              setEditingForReference={setEditingForThisReference}
              localErrorMessages={localErrorMessages}
            />
          );
        })}
      </If>
    </section>
  );
};

ReferencesForm.propTypes = {
  agencyId: PropTypes.number.isRequired,
  applicationId: PropTypes.number.isRequired,
  helpText: PropTypes.string,
  referencesRequired: PropTypes.number.isRequired,
  referredUserAgencyProfileId: PropTypes.number,
  readOnly: PropTypes.bool,
};

export default ReferencesForm;
