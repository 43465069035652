import { Button } from "@heart/components";
import classnames from "classnames";
import { isEmpty, isString, uniqueId } from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";

import preventDefault from "@lib/preventDefault";

import styles from "./HasMany.module.scss";

const NEW_PREFIX = "that-new-new-";
const isPersisted = ({ id }) => !(isString(id) && id.startsWith(NEW_PREFIX));
const newItem = () => ({ id: uniqueId(NEW_PREFIX) });

/**
 * Component to work with specialized ActiveAdmin HasMany forms. You probably
 * only need to use this component if you're doing some special logic among
 * the "has many" items, but if you just need the standard has_many behavior
 * with a react component, you can use the "output buffer trick":
 *
 * f.has_many :things do |ff|
 *   ff.template.output_buffer << (
￼*     react_component(
 *       "MyComponent",
 *       { object_id: ff.object.id },
 *       camelize_props: true
 *     )
 *   end
 * end
 *
 * If you have decided you need this component, the usage pattern looks like
 * the following:
 *
 * 1. Create a collection component, e.g. good_cause/GoodCauseBarriers. This
 *    component maintains any state necessary render the individual item
 *    components as described below.
 * 2. The collection component should render this component, HasMany, passing in
 *    a render function for individual items as well as callbacks for when
 *    an item or the collection is modified.
 * 3. Create an individual item component that handles creating new items,
 *    deleting items, and editing items. The instances of this component are
 *    rendered by HasMany and they communicate with the collection component
 *
 */
const HasMany = ({
  renderItem,
  items,
  addItem,
  updateItem,
  removeItem,
  description,
}) => {
  useEffect(() => {
    if (isEmpty(items)) {
      addItem(newItem());
    }
  }, [items]);

  const onAddItem = preventDefault(() => {
    addItem(newItem());
  });

  const onRemoveItem = item =>
    preventDefault(() => {
      if (isPersisted(item)) {
        updateItem({ ...item, markedForDestruction: true });
      } else {
        removeItem(item);
      }
    });

  return (
    <div>
      <ul className={styles.hasManyList}>
        {items.map(item => (
          <li
            key={item.id}
            className={classnames("contains-inputs", {
              [styles.markedForDestruction]: item.markedForDestruction,
            })}
          >
            {renderItem({
              item,
              isPersisted: isPersisted(item),
            })}
            {items.length > 1 && (
              <div className={styles.removeButtonWrapper}>
                <Button variant="danger" onClick={onRemoveItem(item)}>
                  Remove {description}
                </Button>
              </div>
            )}
          </li>
        ))}
      </ul>
      <Button onClick={onAddItem}>Add {description}</Button>
    </div>
  );
};

HasMany.propTypes = {
  renderItem: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  addItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  description: PropTypes.string,
};

export default HasMany;
