import styles from "@heart/core/colors.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

export const availableColors = Object.keys(styles)
  .filter(rule => rule.startsWith("bg-color-"))
  .map(rule => rule.replace("bg-color-", ""));

// Choose a reasonable default for the text color.  Eventually this will be
// more sophisticated than "choose the neutral with the opposite intensity"
// and will give children an array of text options in addition to setting a
// default.
const reasonableTextStyle = intensity =>
  intensity >= 500
    ? styles["text-color-neutral-0"]
    : styles["text-color-neutral-600"];

// I think there's a way we could export the available colors as JS and not have
// to do string matching here, but this is good enough for now.
const intensityFromColorName = color => parseInt(color.match(/\d{1,3}/)[0], 10);
/**
 * Set a background color of this component!  Powered by our design tokens.
 *
 * Note: Environment and Map colors are not intended for use within Binti outside of
 * our environment logos and family maps
 */
const Background = ({ as, className, children, color, ...props }) => {
  const Component = as || "div";

  return (
    <Component
      className={classNames(
        className,
        styles[`bg-color-${color}`],
        reasonableTextStyle(intensityFromColorName(color))
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

Background.propTypes = {
  /** Which color family this Background should have */
  color: PropTypes.oneOf(availableColors).isRequired,
  /** What kind of DOM element to use.  Defaults to `<div>` */
  as: PropTypes.string,
  /** Additional classes to customize the appearance of this Background */
  className: PropTypes.string,
  /** If an onClick prop is provided, the whole container will be clickable
   *  and will have hover / focus styling (only incentive containers for now) */
  children: PropTypes.node.isRequired,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default Background;
