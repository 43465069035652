import PropTypes from "prop-types";
import { Fragment } from "react";

import BannerContainer from "@components/shared/banner/BannerContainer";

/**
 * This is intended to display one of these messages at a time, with appropriate styling.
 * This is a replacement for useBanner, except the state is managed by each individual component.
 * useMessageDisplay.js has the intended state management already set up for use with this component.
 * example:
 *   const messageDisplay = useMessageDisplay()
 *
 * (later in JSX)
 *   <MessageDisplay messageData={messageDisplay} />
 *
 * Then use the messageDisplay set* methods as part of setting info, errors, and notices, and
 * the pills will be displayed appripriately ala LocalBannerSubscriber (but not global to the page).
 */
const MessageDisplay = ({
  messageData: { infoText, errorText, noticeText },
}) => (
  <Fragment>
    <If condition={infoText}>
      <BannerContainer message={infoText} type="default" />
    </If>
    <If condition={errorText}>
      <BannerContainer message={errorText} type="error" />
    </If>
    <If condition={noticeText}>
      <BannerContainer message={noticeText} type="notice" />
    </If>
  </Fragment>
);

MessageDisplay.propTypes = {
  messageData: PropTypes.shape({
    infoText: PropTypes.string,
    errorText: PropTypes.string,
    noticeText: PropTypes.string,
  }),
};

export default MessageDisplay;
