import { Button } from "@heart/components";
import PropTypes from "prop-types";

import { policy } from "@lib/graphqlHelpers";

import styles from "./EndUserUploadTypeTable.module.scss";
import ExpirationDateControl from "./ExpirationDateControl";

const EndUserUploadTypeRecords = ({
  applicationId,
  deleteUploadedRecordAttachment,
  records,
  uploadType,
}) => {
  const { requiresExpirationDate } = uploadType;
  const holderToken = `Application-${applicationId}`;

  return (
    <div className={styles.uploadTypeRecords}>
      {records.map(record => (
        <div key={record.id} className={styles.uploadTypeRecord}>
          {record.attachments
            .concat(record.uploadedRecordFiles)
            .map(({ id, downloadUrl, name, __typename: attachmentType }) => (
              <span key={id}>
                <a href={downloadUrl} target={id}>
                  {name}
                </a>{" "}
                {policy(record).mayDestroyAttachment() && (
                  <Button
                    onClick={() => {
                      deleteUploadedRecordAttachment({
                        variables: {
                          holderToken: holderToken,
                          uploadedRecordId: record.id,
                          attachmentId: id,
                          attachmentType,
                        },
                      });
                    }}
                    title={I18n.t(
                      "javascript.components.end_user.end_user_upload_type_records.delete_upload"
                    )}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </Button>
                )}
              </span>
            ))}
          {(record.expirationDate || requiresExpirationDate) && (
            <ExpirationDateControl holderToken={holderToken} record={record} />
          )}
        </div>
      ))}
    </div>
  );
};

EndUserUploadTypeRecords.propTypes = {
  applicationId: PropTypes.number.isRequired,
  deleteUploadedRecordAttachment: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  uploadType: PropTypes.object.isRequired,
};

export default EndUserUploadTypeRecords;
