export const reorder = (array, startIndex, endIndex) => {
  const result = Array.from(array);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const insert = (array, index, newItem) => [
  ...array.slice(0, index),
  newItem,
  ...array.slice(index),
];

export const remove = (array, index) => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
];
