import { InputTextarea, SurfaceFormGroup } from "@heart/components";
import PropTypes from "prop-types";

import EnumDropdown from "@components/reusable_ui/forms/EnumDropdown";

import { ENUM_OVERRIDDEN_REASONS } from "@root/constants";

const adminT = t =>
  I18n.t(`admin.applications.application.override_status_change.${t}`);
const activerecordT = t => I18n.t(`activerecord.attributes.application.${t}`);

const OverridePanel = ({
  onChangeReason,
  onChangeExplanation,
  reason,
  explanation,
  visible,
}) => {
  if (!visible) return null;

  return (
    <SurfaceFormGroup title={adminT("title")} subtitle={adminT("help_text")}>
      <EnumDropdown
        label={activerecordT("overridden_reason")}
        value={reason}
        {...ENUM_OVERRIDDEN_REASONS}
        onChange={onChangeReason}
      />
      <InputTextarea
        label={activerecordT("overridden_explanation")}
        value={explanation}
        rows={3}
        onChange={onChangeExplanation}
      />
    </SurfaceFormGroup>
  );
};

OverridePanel.propTypes = {
  onChangeReason: PropTypes.func.isRequired,
  onChangeExplanation: PropTypes.func.isRequired,
  reason: PropTypes.string,
  explanation: PropTypes.string,
  visible: PropTypes.bool.isRequired,
};

export default OverridePanel;
