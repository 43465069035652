import { InputText } from "@heart/components";
import PropTypes from "prop-types";

const ApiTokensEdit = ({ setTokenName }) => (
  <InputText
    pattern="^(?!\s*$).+" // this ensures a non-empty string for HTML validation
    label="New API token name"
    required
    onChange={setTokenName}
  />
);

ApiTokensEdit.propTypes = {
  setTokenName: PropTypes.func,
};

export default ApiTokensEdit;
