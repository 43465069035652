// AttachmentActions.test.js
import { MockedProvider } from "@apollo/client/testing";
import {
  render,
  act,
  waitFor,
  screen,
  fireEvent,
} from "@testing-library/react";
import React from "react";

import GenerateHellosignPartialPdf from "@graphql/mutations/GenerateHellosignPdf.graphql";
import ApplicationForm from "@graphql/queries/ApplicationForm.graphql";
import FeatureFlag from "@graphql/queries/FeatureFlag.graphql";

import AttachmentActions from "./AttachmentActions";

const mockFulfillment = {
  isFulfilled: false,
  records: [
    {
      id: "1",
      form: { pdfStrategy: "filledInOnHellosign", partialAvailable: false },
    },
  ],
};

const mockRequirement = { id: "1" };

const mocks = [
  {
    request: {
      query: GenerateHellosignPartialPdf,
      variables: { applicationFormId: "1" },
    },
    result: {
      data: {
        generateHellosignPdf: { applicationFormId: "1" },
      },
    },
  },
  {
    request: {
      query: FeatureFlag,
      variables: { flag: "ff_hellosign_partial_02_2024" },
    },
    result: {
      data: { featureFlag: true },
    },
  },
  {
    request: {
      query: ApplicationForm,
      variables: { applicationFormId: "1" },
    },
    result: jest.fn(() => ({
      data: { applicationForm: { id: "1", partialAvailable: false } },
    })),
  },
  {
    request: {
      query: ApplicationForm,
      variables: { applicationFormId: "1" },
    },
    result: {
      data: { applicationForm: { id: "1", partialAvailable: true } },
    },
  },
];

describe("AttachmentActions", () => {
  it("stops polling after MAX_POLLING_ATTEMPTS", async () => {
    jest.useFakeTimers();

    const { getByText } = render(
      <MockedProvider mocks={mocks} addTypename={false}>
        <AttachmentActions
          fulfillment={mockFulfillment}
          requirement={mockRequirement}
        />
      </MockedProvider>
    );

    await (() => {
      const generateButton = getByText(/generate_incomplete_pdf/i);
      generateButton.click();
    });

    // Advance timers to simulate the entire polling duraction
    act(() => {
      jest.advanceTimersByTime(8000);
    });

    await waitFor(async () => {
      const generateButton = await screen.findByText(
        /generate incomplete pdf/i
      );
      expect(generateButton).toBeInTheDocument();
      expect(generateButton).not.toBeDisabled();
    });

    jest.useRealTimers();
  });

  it("renders the GenerateIncompletePdf button when conditions are met", async () => {
    jest.useFakeTimers();

    render(
      <MockedProvider mocks={mocks} addTypename={false}>
        <AttachmentActions
          fulfillment={mockFulfillment}
          requirement={mockRequirement}
        />
      </MockedProvider>
    );

    // Advance timers to allow any initial loading state to resolve
    act(() => {
      jest.advanceTimersByTime(0);
    });

    expect(
      await screen.findByText(/generate incomplete pdf/i)
    ).toBeInTheDocument();
  });

  it("disables the 'Generate Incomplete PDF' button after being clicked", async () => {
    const { getByText } = render(
      <MockedProvider mocks={mocks} addTypename={false}>
        <AttachmentActions
          fulfillment={mockFulfillment}
          requirement={mockRequirement}
        />
      </MockedProvider>
    );

    // Find the 'Generate Incomplete PDF' button
    // Using waitFor to ensure the component has loaded' button
    const generateButton = await waitFor(() =>
      getByText(/generate incomplete pdf/i)
    );

    // Simulate a click event on the 'Generate Incomplete PDF' button
    fireEvent.click(generateButton);

    await waitFor(() => {
      // Check that the button is disabled after being clicked
      expect(generateButton).toBeDisabled();
    });
  });
});
