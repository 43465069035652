import { InputText } from "@heart/components";
import { useState, useEffect } from "react";

import { translationWithRoot } from "@components/T";

import { inputCommonPropTypes, textualInputPropTypes } from "../common";

const { t } = translationWithRoot("heart.components.inputs.input_ssn");

/** An input for social security numbers built atop InputText. It validates the SSN:
 * - Has 9 digits
 * - Is divided into 3 parts by hyphens, following a 3-2-4 digit pattern
 * - The first part should not be 000, 666, or between 900 and 999
 * - The second part should be between 01 and 99
 * - the third part should be from 0001 to 9999
 *
 * The validation occurs on each keystroke and if the input text is invalid
 * an error message will appear. It will disappear when the text is considered valid.
 * Additionally, the invalid input will fail HTML validation and prevent submission
 * of forms when you use `<Button type="submit">` and `<form onSubmit={}>`.
 * A validation tooltip will appear on the input after attempting to submit the form.
 */
const InputSSN = props => {
  const { value, onChange, error } = props;

  const [ssn, setSSN] = useState(value);
  useEffect(() => setSSN(value), [value]);

  const formatSSN = val => {
    const rawArray = val.split("");
    if (rawArray.filter(x => x === "-").length === 2) {
      return val;
    }
    const numbers = rawArray.filter(x => x !== "-");
    return [numbers.slice(0, 3), numbers.slice(3, 5), numbers.slice(5)]
      .map(x => x.join(""))
      .filter(x => x !== "")
      .join("-");
  };

  const changeHandler = val => {
    const formattedSSN = formatSSN(val);
    setSSN(formattedSSN);
    if (onChange) onChange(formattedSSN);
  };

  const isValidSSN = (val = "") =>
    val.length === 0
      ? true
      : val.match("^(?!(000|666|9))\\d{3}-(?!00)\\d{2}-(?!0000)\\d{4}$");

  const validate = e => {
    if (isValidSSN(ssn)) {
      e.target.setCustomValidity("");
    } else {
      e.target.setCustomValidity(t("enter_valid_ssn"));
    }
  };

  return (
    <InputText
      {...props}
      type="text"
      value={ssn}
      onChange={changeHandler}
      onBlur={validate}
      placeholder="xxx-xx-xxxx"
      maxCharacters={11}
      error={isValidSSN(ssn) ? error : t("enter_valid_ssn")}
    />
  );
};

InputSSN.propTypes = {
  ...inputCommonPropTypes,
  ...textualInputPropTypes,
};

export default InputSSN;
