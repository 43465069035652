import { Button, toast } from "@heart/components";
import { Microphone, MicrophoneSlash } from "@heart/components/icon/Icon";
import { useMountEffect } from "@react-hookz/web";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

import VoiceInputRecorder from "./VoiceInputRecorder";
import useVolumePulser from "./useVolumePulser";

/**
 * Displays a microphone icon button that allows the user to record
 * their voice input. This component is basically a UI wrapper
 * around VoiceInputRecorder.
 */
const VoiceInput = ({
  onNewMessage,
  onStart,
  onClick,
  pauseTimeout,
  startEnabled,
}) => {
  const [voiceInputRecorder, setVoiceInputRecorder] = useState(null);
  const { onAudioActivity, PulserContainer } = useVolumePulser();

  const enableListening = useCallback(async () => {
    onClick();

    if (!VoiceInputRecorder.supportsAudioRecording()) {
      toast.negative(
        I18n.t(
          "javascript.components.voice_navigation.common.voice_input.audio_not_supported"
        )
      );
      return;
    }

    if (voiceInputRecorder) {
      await voiceInputRecorder.stop();
    }

    setVoiceInputRecorder(
      await VoiceInputRecorder.createRecorder({
        onInput: onNewMessage,
        onStart,
        onSpeech: onAudioActivity,
        pauseTimeout,
      })
    );
  }, [
    onClick,
    voiceInputRecorder,
    onNewMessage,
    onStart,
    onAudioActivity,
    pauseTimeout,
  ]);

  useMountEffect(() => {
    // if startEnabled is true, enable listening on mount
    if (startEnabled && !voiceInputRecorder) {
      enableListening();
    }
  });

  useEffect(() => {
    // changes to pauseTimeout should reset the voiceInputRecorder and
    // stop recording.
    if (voiceInputRecorder) {
      voiceInputRecorder.stop();
      setVoiceInputRecorder(null);
    }
    // only run this when pauseTimeout changes, not voiceInputRecorder
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pauseTimeout]);

  useEffect(() => {
    // changes to onStart should be passed to the voiceInputRecorder
    if (voiceInputRecorder) {
      voiceInputRecorder.onStart = onStart;
    }
  }, [onStart, voiceInputRecorder]);

  // stop recording when component unmounts
  useEffect(() => {
    const cleanup = async () => {
      await voiceInputRecorder?.stop();
    };

    return cleanup;
  }, [voiceInputRecorder]);

  const disableListening = async () => {
    onClick();
    await voiceInputRecorder.stop();
    setVoiceInputRecorder(null);
  };

  return (
    <PulserContainer>
      <Button
        onClick={voiceInputRecorder ? disableListening : enableListening}
        icon={voiceInputRecorder ? MicrophoneSlash : Microphone}
        description={
          voiceInputRecorder
            ? I18n.t(
                "javascript.components.voice_navigation.common.voice_input.stop_listening"
              )
            : I18n.t(
                "javascript.components.voice_navigation.common.voice_input.start_listening"
              )
        }
        round
      />
    </PulserContainer>
  );
};

VoiceInput.propTypes = {
  /**
   * Callback function to call when a new voice input chunk recorded.
   */
  onNewMessage: PropTypes.func.isRequired,
  /**
   * Callback function to call when the user starts speaking. Can be
   * used to interrupt playback of other audio. Note that any return
   * value is ignored, so for example promises will not be awaited.
   */
  onStart: PropTypes.func.isRequired,
  /**
   * Callback function to call when the user clicks the microphone button.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Time in milliseconds to wait before considering the user to be done
   * speaking for this chunk.
   */
  pauseTimeout: PropTypes.number,
  /**
   * Whether the audio recording should be enabled upon component mount.
   */
  startEnabled: PropTypes.bool,
};

export default VoiceInput;
