/* eslint-disable eqeqeq */
import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import BintiPropTypes from "@lib/BintiPropTypes";

const {
  Surface,
  InputText,
  LoadingOverlay,
  InputFilterable,
} = require("@heart/components");

const emptyArray = [];

/**
 * ScheduleTypeEdit component renders a form for schedule types.
 *
 * @param {string} scheduleId - The ID of the schedule.
 * @param {Object} formState - The state of the form.
 * @param {Function} setFormAttribute - Function to set form attributes.
 */
const ScheduleTypeEdit = ({ agencyId, formState, setFormAttribute }) => {
  const { data, loading } = useQuery(
    gql`
      query ScheduleTypeEdit($agencyId: ID!) {
        scheduleFormData(agencyId: $agencyId) {
          programOptions {
            label
            value
          }
          levelOfCareOptions {
            label
            value
          }
        }
      }
    `,
    { variables: { agencyId } }
  );
  const { programOptions, levelOfCareOptions } = data?.scheduleFormData || {
    programOptions: emptyArray,
    levelOfCareOptions: emptyArray,
  };

  return (
    <LoadingOverlay active={loading}>
      <Surface title="Schedule Type">
        <div>
          <p>
            <strong>
              Provide only one of the following: Levels Of Care, Programs, or
              Age Range. If none of the following are selected, the schedule
              will apply to all children.
            </strong>
          </p>
        </div>
        <div>
          <p>
            <strong>
              For schedules scoped to specific Levels of Care or Programs, the
              schedule will apply to children with at least one of the indicated
              Levels of Care or Programs.
            </strong>
          </p>
        </div>

        <InputFilterable
          isMulti
          label="Levels of Care"
          value={formState?.levelsOfCare?.map(id =>
            levelOfCareOptions.find(option => option.value == id)
          )}
          options={levelOfCareOptions}
          onChange={levels =>
            setFormAttribute("levelsOfCare")(levels.map(({ value }) => value))
          }
        />
        <Surface title="Programs">
          <InputFilterable
            isMulti
            label="Programs"
            value={formState?.programIds?.map(id =>
              programOptions.find(option => option.value == id)
            )}
            values={programOptions}
            onChange={programs =>
              setFormAttribute("programIds")(programs.map(({ value }) => value))
            }
          />
        </Surface>
        <Surface title="Age Range">
          <InputText
            type="text"
            label="Youngest age"
            value={formState.youngestAge}
            onChange={setFormAttribute("youngestAge")}
          />
          <InputText
            type="text"
            label="Oldest age"
            value={formState.oldestAge}
            onChange={setFormAttribute("oldestAge")}
          />
        </Surface>
      </Surface>
    </LoadingOverlay>
  );
};

ScheduleTypeEdit.propTypes = {
  agencyId: BintiPropTypes.ID,
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
};

export default ScheduleTypeEdit;
