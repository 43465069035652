/* eslint-disable no-console, no-alert, import/prefer-default-export */
export const tryReload = (_jqXHR, textStatus, errorThrown) => {
  console.log(textStatus, errorThrown);
  window.alert(I18n.t("javascript.components.common.generic_error"));

  window.location.reload();
};

export const badRequest = (jqXHR, textStatus, errorThrown) => {
  try {
    const jsonResponse = JSON.parse(jqXHR.responseText);

    if (jsonResponse.error === "virus_detected") {
      window.alert(I18n.t("attachments.errors.virus_detected"));
    } else if (jsonResponse.error === "empty_file") {
      window.alert(I18n.t("attachments.errors.empty_file"));
    } else if (jsonResponse.error === "invalid_mime_type") {
      window.alert(I18n.t("attachments.errors.unpermitted_format"));
    } else if (jsonResponse.error === "bad_category") {
      // this shouldn't happen unless we have a bug or the user manually
      // messes with the JS.
      tryReload(jqXHR, textStatus, errorThrown);
    }
  } catch (_e) {
    tryReload(jqXHR, textStatus, errorThrown);
  }
};
/* eslint-enable no-console, no-alert */

const RATE_LIMIT_TIMEOUT = 61000;

// Use this instead of jQuery statusCode handling which doesn't allow branching
// and fallback behavior.
export const handleAjaxErrors =
  ({ onRecoverableError, onRateLimit }) =>
  (jqXHR, textStatus, errorThrown) => {
    if (jqXHR.status === 429) {
      if (onRateLimit) {
        // eslint-disable-next-line no-console
        console.log("rate limited. will try again in a minute.");
        setTimeout(onRateLimit, RATE_LIMIT_TIMEOUT);
      }
    } else if (jqXHR.status === 400) {
      badRequest(jqXHR, textStatus, errorThrown);
      onRecoverableError();
    } else {
      tryReload(jqXHR, textStatus, errorThrown);
    }
  };

export const noop = () => undefined;
export const ajaxDefaults = ({
  onRecoverableError = noop,
  noContent = false,
  onRateLimit,
} = {}) => {
  if (noContent) {
    return Object.freeze({
      error: handleAjaxErrors({ onRecoverableError, onRateLimit }),
    });
  }

  return Object.freeze({
    dataType: "json",
    contentType: "application/json",
    error: handleAjaxErrors({ onRecoverableError, onRateLimit }),
  });
};
