import { Button, InputFilterableLegacyAjax } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import styles from "./ApplicationTemplateDesigner.module.scss";

const FormAdder = ({ onAdd, agencySlug }) => {
  const autocompleteUrl = `/admin/forms/slug_autocomplete?agency_slug=${agencySlug}`;
  const [value, setValue] = useState("");

  return (
    <div className={styles.adder}>
      <div className={styles.searchBox}>
        <InputFilterableLegacyAjax
          name="Form"
          url={autocompleteUrl}
          onChange={({ value: newValue }) => setValue(newValue)}
          label="Add Form"
        />
      </div>
      <Button
        onClick={() => {
          onAdd(value);
          setValue("");
        }}
        className={styles.addButton}
      >
        +
      </Button>
    </div>
  );
};

FormAdder.propTypes = {
  onAdd: PropTypes.func.isRequired,
  agencySlug: PropTypes.string.isRequired,
};

export default FormAdder;
