import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import styles from "./SignatureInput.module.scss";

const STARTING_FONT_SIZE = 80;

const TextSignature = ({ width, height, name, onImageUpdate }) => {
  const signatureCanvasRef = useRef();

  /**
   * renders the current name onto the canvas, but auto-scales it down
   * so that it fits.
   */
  const renderName = () => {
    const canvas = signatureCanvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, width, height);

    for (let size = STARTING_FONT_SIZE; size > 0; size -= 1) {
      context.font = `${size}px SignatureFont`;
      const measurement = context.measureText(name);

      if (measurement.width < width && size < height) {
        context.fillText(
          name,
          (width - measurement.width) / 2,
          (height + size) / 2
        );

        if (onImageUpdate) {
          onImageUpdate(canvas.toDataURL("image/png"));
        }

        break;
      }
    }
  };

  useEffect(() => {
    renderName();
  });

  useEffect(() => {
    const context = signatureCanvasRef.current.getContext("2d");
    context.clearRect(0, 0, width, height);

    renderName();
  }, [name]);

  return (
    <canvas
      className={styles.signatureCanvas}
      ref={signatureCanvasRef}
      width={width}
      height={height}
    />
  );
};

TextSignature.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  name: PropTypes.string,
  onImageUpdate: PropTypes.func,
};

export default TextSignature;
