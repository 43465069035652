import { ContentTabs, PageContainer, Surface } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import useFeatureFlag from "@lib/useFeatureFlag";

import ContactLogsTable from "./contact_logs/ContactLogsTable";
import RelationshipsTable from "./relationships/RelationshipsTable";
import RelationshipsTableOrGenogram from "./relationships/RelationshipsTableOrGenogram";

const { t } = translationWithRoot("family_finding.relationships_dashboard");

/** The foundational component for the Family Finding relationships dashboard
 *
 * This component contains the tabs for the relationships table, contact logs table,
 * and potential connections table
 */
const RelationshipsDashboard = ({
  childAgencyHumanId,
  addPersonFormPath,
  downloadRelationshipsCsvPath,
  editContactLogBasePath,
  downloadContactLogsCsvPath,
}) => {
  const { flag: ffAddPotentialConnections012024 } = useFeatureFlag(
    "ff_add_potential_connections_01_2024"
  );

  return (
    <PageContainer fullWidth>
      <Surface hideTitle title={t("relationships_dashboard")}>
        <ContentTabs
          tabs={[
            {
              title: t("relationships"),
              contents: (
                <RelationshipsTableOrGenogram
                  tab="relationships"
                  addPersonFormPath={addPersonFormPath}
                  childAgencyHumanId={childAgencyHumanId}
                  downloadRelationshipsCsvPath={downloadRelationshipsCsvPath}
                />
              ),
            },
            {
              title: t("contact_logs"),
              contents: (
                <ContactLogsTable
                  childAgencyHumanId={childAgencyHumanId}
                  editContactLogBasePath={editContactLogBasePath}
                  downloadContactLogsCsvPath={downloadContactLogsCsvPath}
                />
              ),
            },
            ffAddPotentialConnections012024 && {
              title: t("potential_connections"),
              contents: (
                <RelationshipsTable
                  tab="potentialConnections"
                  addPersonFormPath={addPersonFormPath}
                  keystoneAgencyHumanId={childAgencyHumanId}
                  downloadRelationshipsCsvPath={downloadRelationshipsCsvPath}
                  ffAddPotentialConnections012024={
                    ffAddPotentialConnections012024
                  }
                />
              ),
            },
            /** we can remove this filter when we remove the potential connections
             * feature flag as well */
          ].filter(Boolean)}
        />
      </Surface>
    </PageContainer>
  );
};
RelationshipsDashboard.propTypes = {
  addPersonFormPath: PropTypes.string.isRequired,
  childAgencyHumanId: PropTypes.string.isRequired,
  downloadRelationshipsCsvPath: PropTypes.string.isRequired,
  editContactLogBasePath: PropTypes.string.isRequired,
  downloadContactLogsCsvPath: PropTypes.string.isRequired,
};

export default RelationshipsDashboard;
