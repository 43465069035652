import { gql, useQuery } from "@apollo/client";
import { Button } from "@heart/components";
import I18n from "i18n-js";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";

import MessageDisplay from "../MessageDisplay";
import useMessageDisplay from "../useMessageDisplay";
import StaffMember from "./StaffMember";
import StaffMemberEditor from "./StaffMemberEditor";
import { StaffMemberFragment } from "./staffMemberUtils";

const StaffMemberForm = ({
  agencyId,
  placementProviderId,
  setEditingCallback = () => {},
  setAddingNewStaffMemberCallback = () => {},
}) => {
  const StaffMembers = gql`
    ${StaffMemberFragment}
    query PlacementProvider($placementProviderId: ID!) {
      placementProvider(placementProviderId: $placementProviderId) {
        id
        staffMembers {
          ...StaffMember
        }
      }
    }
  `;

  const { data: placementProviderData, error: placementProviderError } =
    useQuery(StaffMembers, { variables: { placementProviderId } });

  const staffMembers = placementProviderData
    ? placementProviderData.placementProvider.staffMembers
    : [].sort(
        ({ joinedAt: joinedAtA }, { joinedAt: joinedAtB }) =>
          DateTime.fromISO(joinedAtA) - DateTime.fromISO(joinedAtB)
      );

  const messageDisplay = useMessageDisplay();
  const [addingNewStaffMember, setAddingNewStaffMemberState] = useState(false);
  const setAddingNewStaffMember = arg => {
    setAddingNewStaffMemberState(arg);
    setAddingNewStaffMemberCallback(arg);
  };
  const [editing, setEditing] = useState({});
  /* localErrorMessages is an array that contains strings or JSX components
   * that are displayed in StaffMemberEditor component. We set state here so that
   * we can pass down global error states into this component.
   */
  const [localErrorMessages, setlocalErrorMessages] = useState([]);

  if (placementProviderError)
    return <pre>{placementProviderError.toString()}</pre>;
  if (!placementProviderData) return null;

  const setEditingForStaffMember = ({ id, value }) => {
    if (!isNil(id)) {
      setEditingCallback({ ...editing, [id]: value });
      setEditing({ ...editing, [id]: value });
    }
  };

  return (
    <Fragment>
      <div className="space-below-2" id="sm_error_banner">
        <MessageDisplay messageData={messageDisplay} />
      </div>
      <Fragment>
        {staffMembers.map(staffMember =>
          editing[staffMember.id] ? (
            <StaffMemberEditor
              key={staffMember.id}
              // Model data
              agencyId={agencyId}
              placementProviderId={placementProviderId}
              existingStaffMember={staffMember}
              // React state
              messageDisplay={messageDisplay}
              setAddingNewStaffMember={setAddingNewStaffMember}
              setEditingForStaffMember={value =>
                setEditingForStaffMember({ id: staffMember.id, value })
              }
              localErrorMessages={localErrorMessages}
              setlocalErrorMessages={setlocalErrorMessages}
            />
          ) : (
            <StaffMember
              key={staffMember.id}
              // Model data
              staffMember={staffMember}
              agencyId={agencyId}
              placementProviderId={placementProviderId}
              // React state
              setEditingForStaffMember={value =>
                setEditingForStaffMember({ id: staffMember.id, value })
              }
              messageDisplay={messageDisplay}
            />
          )
        )}
      </Fragment>
      {addingNewStaffMember ? (
        <StaffMemberEditor
          // Model data
          agencyId={agencyId}
          placementProviderId={placementProviderId}
          // React state
          messageDisplay={messageDisplay}
          setAddingNewStaffMember={setAddingNewStaffMember}
          setEditingForStaffMember={value =>
            setEditingForStaffMember({ index: null, value })
          }
          localErrorMessages={localErrorMessages}
          setlocalErrorMessages={setlocalErrorMessages}
        />
      ) : (
        <Button
          variant="secondary"
          onClick={() => setAddingNewStaffMember(true)}
        >
          {I18n.t(
            "views.applications.application_staff_member.add_staff_member"
          )}
        </Button>
      )}
    </Fragment>
  );
};

StaffMemberForm.propTypes = {
  agencyId: PropTypes.number.isRequired,
  placementProviderId: PropTypes.number.isRequired,
  /* callback invoked whenever a staff member is edited. It's called with an object
   that has keys which are ids of staff members, and value is true/false.
  */
  setEditingCallback: PropTypes.func,
  /* Callback invoked whenever a staff member is added.
   The callback is invoked with a boolean argument */
  setAddingNewStaffMemberCallback: PropTypes.func,
  showAddAnotherStaffMember: PropTypes.bool,
};

export default StaffMemberForm;
