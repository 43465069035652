import styles from "@heart/components/inputs/Input.module.scss";
import { isNil, isObject } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { inputCommonPropTypes, BasicInputLayout } from "./common";
import {
  htmlTrueFalseToJavascript,
  javascriptTrueFalseToHtml,
} from "./common/trueFalseConversion";

const renderOption = option => {
  if (option.options) {
    return renderGroup(option.label, option.options);
  }
  const { label, value } = parseOption(option);
  const htmlValue = javascriptTrueFalseToHtml(value);
  return (
    <option value={htmlValue} key={htmlValue}>
      {label}
    </option>
  );
};

const renderGroup = (groupName, options) => (
  <optgroup label={groupName} key={groupName}>
    {options.map(renderOption)}
  </optgroup>
);
const parseOption = option => {
  // Option format: ["Foo", "foo"]
  if (Array.isArray(option)) {
    const [label, value] = option;
    return { label, value };
  }

  // Option format: {label: "Foo", value: "foo"}
  if (isObject(option)) {
    return {
      label: option.label || option.value || option,
      value: isNil(option.value) ? option : option.value,
    };
  }

  // Option format: "foo"
  return { label: option, value: option };
};

/** A dropdown input!  This renders a plain `<select>` input with all the visual
 * styling of Heart inputs and has some data massaging / smoothing to support
 * many different kinds of data structures.
 *
 * _UX Note_: If the list of options is longer than 10 options, please use
 * `<InputFilterable>` instead.
 */
const InputDropdown = props => {
  const { value, values, hideBlank, blankOptionText, onChange, className } =
    props;
  const [dropdownValue, setDropdownValue] = useState(value);

  useEffect(() => setDropdownValue(value), [value]);

  const handleDropdownChanged = e => {
    const javascriptValue = htmlTrueFalseToJavascript(e.target.value);
    setDropdownValue(javascriptValue);
    if (onChange) onChange(javascriptValue);
  };

  return (
    <BasicInputLayout
      {...props}
      inputComponent={inputProps => (
        <select
          {...inputProps}
          className={className}
          onChange={handleDropdownChanged}
          value={dropdownValue}
        >
          <If condition={hideBlank}>
            <option value="" className={styles.hide}></option>
          </If>
          <If condition={!hideBlank}>
            <option value="">{blankOptionText}</option>
          </If>
          {values.map(renderOption)}
        </select>
      )}
    />
  );
};

InputDropdown.propTypes = {
  ...inputCommonPropTypes,
  /** The `onChange` function is invoked with the string value
   * of the chosen option.
   */
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  /** The `values` property accepts one of the following:
   * - array of {label: "Thing", value: "thing"}
   * - array of {label: "Group of Things", options: [{label: "Thing", value: "thing"}]}
   *   - this format creates an `<optgroup>` tag for each group
   * It also supports these for backwards compatibility, **but please do not use them in
   * new code**:
   * - array of `[label, value]` pairs
   * - array of strings that will be used as both label AND value
   * */
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ])
      )
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]).isRequired,
      })
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
              PropTypes.bool,
            ]).isRequired,
          })
        ),
      })
    ),
  ]).isRequired,
  /** Don't allow the user to unset the value of this dropdown once it's been set */
  hideBlank: PropTypes.bool,
  /** Set custom text for the empty value dropdown option */
  blankOptionText: PropTypes.string,
  /** **Deprecated: do not use** */
  className: PropTypes.string,
};
export default InputDropdown;
