import { Button, Flex } from "@heart/components";
import SearchResultsTable from "@heart/components/data_table/search_results_table/SearchResultsTable";
import I18n from "i18n-js";
import { compact, isEmpty, isNumber } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import EmailCampaignList from "@graphql/queries/EmailCampaignList.graphql";

import preventDefault from "@lib/preventDefault";
import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";

const { t, T } = translationWithRoot("email_list_selection");

/** Email campaign creation form. Lets the user filter which
 *  recipients should receive a mass email.
 */
const EmailListSelection = ({
  statuses,
  agencies,
  agencyId,
  languages,
  applicationTemplateLineages,
  licenseTags,
  submitDestination,
}) => {
  const queryParamsFromUrl = useBase64SearchParam("query");
  const pageSize = queryParamsFromUrl?.first || queryParamsFromUrl?.last || 20;

  const [tableTitle, setTableTitle] = useState(t("table_title_without_count"));
  const [filterValues, setFilterValues] = useState({});

  const onQueryCompleted = data => {
    const count = Math.min(pageSize, data.emailCampaignList.nodes.length);
    const { totalCount } = data.emailCampaignList;

    setTableTitle(t("table_title_with_count", { count, totalCount }));
  };

  const createNewCampaign = preventDefault(() => {
    const searchParams = new URLSearchParams();

    searchParams.set(
      "email_campaign[agency_id]",
      (agencyId || filterValues.agencyId).toString()
    );

    filterValues.statuses.forEach(status => {
      searchParams.append("email_campaign[statuses][]", status);
    });

    filterValues.languages.forEach(language => {
      searchParams.append("email_campaign[languages][]", language);
    });

    if (filterValues.applicationTemplateLineages !== undefined) {
      filterValues.applicationTemplateLineages.forEach(
        applicationTemplateLineage => {
          searchParams.append(
            "email_campaign[application_template_lineages][]",
            applicationTemplateLineage
          );
        }
      );
    }

    if (filterValues.licenseTags !== undefined) {
      filterValues.licenseTags.forEach(licenseTag => {
        searchParams.append("email_campaign[license_tags][]", licenseTag);
      });
    }

    const url = new URL(submitDestination, window.location.origin);
    url.search = searchParams.toString();
    window.location.href = url.href;
  });

  const createDisabled =
    !(agencyId || filterValues.agencyId) ||
    [filterValues.statuses, filterValues.languages].some(isEmpty);

  const filters = compact([
    isNumber(agencyId)
      ? null
      : {
          label: I18n.t("views.common.agency"),
          type: "select",
          field: "agencyId",
          values: agencies.map(a => ({
            label: a.name,
            value: a.id.toString(),
          })),
          required: true,
        },
    {
      label: I18n.t("common.status"),
      type: "select",
      isMulti: true,
      field: "statuses",
      values: statuses.map(status => ({
        value: status,
        label: I18n.t(`application.statuses.${status}`),
      })),
      required: true,
    },
    {
      label: I18n.t("common.language"),
      type: "select",
      isMulti: true,
      values: languages.map(l => ({ label: l, value: l })),
      field: "languages",
      defaultValue: ["English"],
      required: true,
      showSelectAll: true,
    },
    licenseTags === undefined
      ? null
      : {
          label: I18n.t("admin.applications.application.license_tags"),
          type: "select",
          isMulti: true,
          values: licenseTags.map(l => ({ label: l[1], value: l[0] })),
          field: "licenseTags",
          required: false,
        },
    applicationTemplateLineages === undefined
      ? null
      : {
          label: I18n.t("admin.applications.application.application_template"),
          type: "select",
          isMulti: true,
          values: applicationTemplateLineages.map(l => ({
            label: l,
            value: l,
          })),
          field: "applicationTemplateLineages",
          required: false,
        },
  ]);

  return (
    <SearchResultsTable
      title={tableTitle}
      subtitle={<T t="can_spam_disclaimer_html" />}
      query={EmailCampaignList}
      queryTitle="emailCampaignList"
      onQueryCompleted={onQueryCompleted}
      onFilterChange={setFilterValues}
      actions={
        <Flex gap="0" justify="end">
          <Button onClick={createNewCampaign} disabled={createDisabled}>
            <T t="create_email" />
          </Button>
        </Flex>
      }
      defaultFilters={
        isNumber(agencyId) ? { agencyId: agencyId.toString() } : {}
      }
      filters={filters}
      columns={[
        {
          columnName: { name: I18n.t("views.common.name") },
          id: "fullName",
          cell: "fullName",
        },
        {
          columnName: { name: I18n.t("admin.common.email"), justify: "end" },
          id: "email",
          cell: "email",
        },
      ]}
      pageSize={pageSize}
      emptyStatePrompt={t("empty_state_prompt")}
    />
  );
};

EmailListSelection.propTypes = {
  /** If provided, the agency dropdown is not shown and this agency id is
   * used for all queries. */
  agencyId: PropTypes.number,
  agencies: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  applicationTemplateLineages: PropTypes.array,
  licenseTags: PropTypes.array,
  submitDestination: PropTypes.string.isRequired,
};

export default EmailListSelection;
