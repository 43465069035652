import { useQuery } from "@apollo/client";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React from "react";

import RoleDropdown from "@components/shared/RoleDropdown";

import AgencyWorker from "@graphql/queries/AgencyWorker.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

/** Dropdown for selecting an agency role given a worker and agency ID.
 *  Stores its value in the global `selectedRoleIdVar` state to be subscribed
 *  in other components.
 *    Agency Workers > New/Edit > Select Role "caseworker" > "Agencies" section
 */
const AgencyWorkerRoleDropdown = ({
  workerId,
  userAgencyId,
  selectedAgencyId,
  selectedRoleId,
  setSelectedRoleId,
}) => {
  const { data } = useQuery(AgencyWorker, {
    variables: { workerId },
    skip: !workerId,
  });

  const { agency: agencyWorkerAgency, roleForPermissions } =
    data?.agencyWorker || {};

  return (
    <RoleDropdown
      label={I18n.t("javascript.components.permissions.agency_role")}
      id="role_dropdown"
      component={RoleDropdown}
      agencyId={selectedAgencyId || agencyWorkerAgency?.id || userAgencyId}
      targetAgencyWorkerId={workerId}
      value={selectedRoleId || roleForPermissions?.id}
      onChange={setSelectedRoleId}
      name={"agency_worker[agency_admin_assignment_attributes][role_id]"}
    />
  );
};

AgencyWorkerRoleDropdown.propTypes = {
  workerId: PropTypes.number,
  userAgencyId: PropTypes.number,
  selectedAgencyId: BintiPropTypes.ID,
  selectedRoleId: PropTypes.string,
  setSelectedRoleId: PropTypes.func,
};

export default AgencyWorkerRoleDropdown;
