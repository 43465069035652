import { gql } from "@apollo/client";
import {
  InputCheckboxGroup,
  InputDate,
  InputDropdown,
} from "@heart/components";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "background_check_exemptions.exemption_details_inputs"
);

const AgencyWorkerAutocompleteQuery = gql`
  query AgencyWorkerAutocompleteQuery(
    $inputQuery: String!
    $withoutPartnerAgencyWorkers: Boolean
  ) {
    agencyWorkerMatches(
      inputQuery: $inputQuery
      withoutPartnerAgencyWorkers: $withoutPartnerAgencyWorkers
    ) {
      id
      name
    }
  }
`;

const ExemptionDetails = ({
  formState,
  setFormAttribute,
  exemptionTypeData,
  notificationMethodData,
}) => {
  const agencyState = formState?.application?.agency?.stateAbbr;

  const workersFromResponse = response =>
    (response?.agencyWorkerMatches || []).map(({ id, name }) => ({
      id,
      label: name,
      value: id,
    }));

  return (
    <Fragment>
      {agencyState === "CA" && (
        <InputDropdown
          label={t("exemption_type")}
          name="exemption_type"
          value={formState.exemptionType || undefined}
          values={exemptionTypeData.map(type => ({
            label: I18n.t(
              `activerecord.enums.background_check_exemption.exemption_types.${type}`
            ),
            value: type,
          }))}
          onChange={setFormAttribute("exemptionType")}
          required={false}
        />
      )}
      <InputAutocompleteGraphQL
        label={t("worker_assigned")}
        query={AgencyWorkerAutocompleteQuery}
        valuesFromResponse={workersFromResponse}
        onChange={setFormAttribute("caseworker")}
        value={formState.caseworker}
        queryVariables={{ withoutPartnerAgencyWorkers: true }}
      />
      <InputDate
        label={t("start_date")}
        name="start_date"
        value={formState.startDate}
        onChange={setFormAttribute("startDate")}
        required={false}
      />
      <InputDate
        label={t("notification_date")}
        name="notification_date"
        value={formState.notificationDate}
        onChange={setFormAttribute("notificationDate")}
        required={false}
      />
      <InputCheckboxGroup
        name="notification_methods"
        label={t("notification_method")}
        value={formState.notificationMethods}
        onChange={setFormAttribute("notificationMethods")}
        required={false}
        values={notificationMethodData.map(method => ({
          label: I18n.t(
            `activerecord.enums.background_check_exemption.notification_methods.${method}`
          ),
          value: method,
        }))}
      />
      <InputDate
        label={t("response_date")}
        name="response_date"
        value={formState.responseDate}
        onChange={setFormAttribute("responseDate")}
        required={false}
      />
    </Fragment>
  );
};

ExemptionDetails.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
  exemptionTypeData: PropTypes.array,
  notificationMethodData: PropTypes.array,
};

export default ExemptionDetails;
