import PropTypes from "prop-types";
import { Component } from "react";

class RedirectLink extends Component {
  componentDidMount() {
    const { url, delay } = this.props;

    setTimeout(() => {
      window.location = url;
    }, delay * 1000);
  }

  render() {
    const { url } = this.props;
    return <a href={url}>{url}</a>;
  }
}

RedirectLink.propTypes = {
  url: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
};

export default RedirectLink;
