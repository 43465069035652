import {
  Button,
  InputFilterable,
  Link,
  Pill,
  Text,
  InputHidden,
} from "@heart/components";
import Icon from "@heart/components/icon/Icon";
import {
  Caption,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@heart/components/table/Table";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";

/** View and edit assigned workers for a given placement search.
 *    Placement Searches > Row Action > Edit Placements Search
 */
const WorkersAssigned = ({
  formName = "placement-search",
  primaryWorkerAssignedId,
  workersAssigned = [],
  workersAvailable = [],
}) => {
  const [assignedWorkers, setAssignedWorkers] = useState(workersAssigned);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [primaryId, setPrimaryId] = useState(primaryWorkerAssignedId);

  const assignWorker = () => {
    const { value: selectedWorkerId } = selectedWorker;
    const worker = workersAvailable.find(
      ({ id }) => id === Number(selectedWorkerId)
    );

    setAssignedWorkers([...assignedWorkers, worker]);
    setSelectedWorker(null);
  };

  const unassignWorker = workerId => {
    setAssignedWorkers(assignedWorkers.filter(({ id }) => id !== workerId));
  };

  const assignedIds = assignedWorkers.map(({ id }) => id);

  const availableWorkers = workersAvailable.filter(
    ({ id }) => !assignedIds.includes(id)
  );

  const tableStyles = {
    verticalAlign: "middle",
  };

  useEffect(() => {
    if (assignedWorkers.length === 1)
      return setPrimaryId(assignedWorkers[0].id);
    if (isEmpty(assignedWorkers)) return setPrimaryId("");
    return null;
  }, [assignedWorkers]);

  return (
    <div className="contains-inputs">
      <InputHidden
        name={`${formName}[agency_worker_ids]`}
        value={assignedIds.join(",")}
      />
      <InputHidden
        name={`${formName}[placements_worker_id]`}
        value={String(primaryId)}
      />
      <Table>
        <Caption>Workers currently assigned</Caption>
        <Thead>
          <Tr>
            <Th>{I18n.t("admin.common.name")}</Th>
            <Th>{I18n.t("common.status")}</Th>
            <Th>{I18n.t("admin.common.actions")}</Th>
          </Tr>
        </Thead>
        <Tbody aria-live="polite">
          <Choose>
            <When condition={!isEmpty(assignedWorkers)}>
              {assignedWorkers.map(({ id, name }) => (
                <Tr key={id}>
                  <Td style={tableStyles}>
                    <Text>{name}</Text>
                  </Td>
                  <Td style={tableStyles}>
                    {assignedWorkers.length > 1 &&
                      (primaryId === id ? (
                        <Pill text={I18n.t("common.primary")} variant="info" />
                      ) : (
                        <Link onClick={() => setPrimaryId(id)}>
                          {I18n.t(
                            "javascript.components.placement_search.workers_assigned.primary_button"
                          )}
                        </Link>
                      ))}
                  </Td>
                  <Td style={tableStyles}>
                    <Icon
                      icon="trash"
                      description={`Unassign ${name}`}
                      disabled={assignedWorkers.length > 1 && primaryId === id}
                      onClick={() => unassignWorker(id)}
                    />
                  </Td>
                </Tr>
              ))}
            </When>
            <Otherwise>
              <Tr>
                <Td colSpan={3} style={{ textAlign: "left" }}>
                  {`${I18n.t(
                    "javascript.components.placement_search.workers_assigned.no_workers_assigned"
                  )} ${I18n.t(
                    "javascript.components.placement_search.workers_assigned.ui_explanation"
                  )}`}
                </Td>
              </Tr>
            </Otherwise>
          </Choose>
        </Tbody>
      </Table>
      <Choose>
        <When condition={!!availableWorkers.length}>
          <InputFilterable
            id={`workersAvailableSelect-${formName}`}
            label="Available workers"
            onChange={value => setSelectedWorker(value)}
            options={availableWorkers.map(({ id, name }) => ({
              label: name,
              value: id.toString(),
            }))}
            value={selectedWorker}
          />
          <Button onClick={assignWorker} disabled={selectedWorker === null}>
            {I18n.t(
              "javascript.components.placement_search.workers_assigned.assign_worker"
            )}
          </Button>
        </When>
        <Otherwise>
          <Text>No workers available to assign.</Text>
        </Otherwise>
      </Choose>
    </div>
  );
};

WorkersAssigned.propTypes = {
  formName: PropTypes.string,
  primaryWorkerAssignedId: PropTypes.number,
  workersAssigned: PropTypes.array,
  workersAvailable: PropTypes.array,
};

export default WorkersAssigned;
