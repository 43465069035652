import {
  Breadcrumbs,
  ContentTabs,
  Flex,
  Link,
  PageContainer,
  Surface,
} from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";
import {
  downloadFamilyFindingContactLogsCsvPath,
  downloadRelationshipsCsvPath,
  newPersonAsChildConnectionPath,
} from "routes";

import ChildRequirementTableWrapper from "@components/child_requirements/ChildRequirementsTable";
import RelationshipsDashboard from "@components/family_finding/RelationshipsDashboard";

import AISurface from "./AISurface";
import aiAlert from "./ai-alert.svg";
import CaseNotes from "./case_notes/CaseNotes";
import ChildInformation from "./child_info/child-information.svg";
import Education from "./child_info/education.svg";
import LinkedParentBadge from "./child_info/linked-parent-badge.svg";
import ParentHeading from "./child_info/parent-heading.svg";
import Providers from "./child_info/providers.svg";
import SiblingsInCare from "./child_info/siblings-in-care.svg";
import UnlinkedParentBadge from "./child_info/unlinked-parent-badge.svg";
import CaseInformation from "./cm/case-information.svg";
import CourtInformation from "./cm/court-information.svg";
import LevelOfCare from "./cm/level-of-care.svg";
import PermanencyGoal from "./cm/permanency-goal.svg";
import FamilyTree from "./family-tree.svg";
import Placements from "./placements/Placements";
import CurrentPlacement from "./sidebar/CurrentPlacement";
import ChildCard from "./sidebar/child-card.svg";
import ImportantDates from "./sidebar/important-dates.svg";

const Sidebar = ({ placement }) => (
  <Flex column style={{ width: "425px" }}>
    <img src={ChildCard} alt="Child card" />
    <If condition={placement?.current}>
      <CurrentPlacement placement={placement} />
    </If>
    <img src={ImportantDates} alt="Important dates" />
  </Flex>
);
Sidebar.propTypes = {
  placement: PropTypes.object,
};

const parentLink = new URL(window.location);
parentLink.searchParams.set("parent", "true");

const ChildInfo = () => (
  <Flex column>
    <img src={ChildInformation} alt="AI Buddy" />
    <div style={{ maxWidth: "98.5%", margin: "0 8px 8px" }}>
      <Surface title="Relatives" hideTitle>
        <img src={SiblingsInCare} alt="Siblings In Care" />
        <img src={ParentHeading} alt="Parent Heading" />
        <Flex row>
          <Link href={parentLink.toString()}>
            <img src={LinkedParentBadge} alt="Linked Parent Badge"></img>
          </Link>
          <img src={UnlinkedParentBadge} alt="Unlinked Parent Badge"></img>
        </Flex>
      </Surface>
    </div>
    <img src={Education} alt="Education" />
    <img src={Providers} alt="Providers" />
  </Flex>
);

const CaseManagement = () => (
  <Flex column>
    <img src={CaseInformation} alt="Case Information" />
    <img src={CourtInformation} alt="Court Information" />
    <img src={LevelOfCare} alt="Level Of Care" />
    <img src={PermanencyGoal} alt="Permanency Goal" />
  </Flex>
);

const ChildFile = ({
  caseNotes,
  caseNoteSummary,
  childId,
  childAgencyHumanId,
  missingNames,
  placementPeriods,
}) => (
  <Flex column style={{ margin: "20px" }}>
    <Breadcrumbs
      pages={[
        { label: "Home", href: "/homepage" },
        { label: "Children/Youth", href: "/admin/children" },
        { label: "Liam Lopez", href: "" },
      ]}
    />
    <Flex row>
      <Sidebar placement={placementPeriods[0]} />
      <Flex column>
        <ContentTabs
          tabs={[
            { title: "Child Info", contents: <ChildInfo /> },
            {
              title: "Case Management",
              contents: <CaseManagement />,
            },
            {
              title: "Requirements",
              contents: (
                <ChildRequirementTableWrapper
                  childId={childId}
                  collatedAttachmentsPdfPath=""
                />
              ),
            },
            {
              title: "Placements",
              contents: (
                <Placements
                  placementPeriods={placementPeriods}
                  childId={childId}
                />
              ),
            },
            {
              title: "Relationships",
              contents: (
                <Fragment>
                  <If condition={!isEmpty(missingNames)}>
                    <AISurface>
                      {missingNames.map(name => (
                        <Flex key={name} row>
                          <img
                            src={aiAlert}
                            alt="AI Alert"
                            aria-hidden="true"
                          />{" "}
                          <b>Unlisted name found in case notes:</b> {name}
                        </Flex>
                      ))}
                    </AISurface>
                  </If>
                  <RelationshipsDashboard
                    childAgencyHumanId={childAgencyHumanId}
                    addPersonFormPath={newPersonAsChildConnectionPath(childId)}
                    downloadRelationshipsCsvPath={downloadRelationshipsCsvPath(
                      childAgencyHumanId
                    )}
                    editContactLogBasePath="/family_finding_contact_logs"
                    downloadContactLogsCsvPath={downloadFamilyFindingContactLogsCsvPath()}
                  />
                  <PageContainer fullWidth>
                    <img
                      src={FamilyTree}
                      alt="Family Tree"
                      width="100%"
                      style={{
                        boxShadow:
                          "0px 6px 10px -1px rgba(6, 25, 56, 0.0705882353)",
                      }}
                    />
                  </PageContainer>
                </Fragment>
              ),
            },
            {
              title: "Case Notes",
              contents: (
                <CaseNotes
                  caseNotes={caseNotes}
                  childId={childId}
                  summary={caseNoteSummary}
                />
              ),
            },
          ]}
        ></ContentTabs>
      </Flex>
    </Flex>
  </Flex>
);

ChildFile.propTypes = {
  caseNotes: PropTypes.arrayOf(PropTypes.object).isRequired,
  childId: PropTypes.number.isRequired,
  childAgencyHumanId: PropTypes.string.isRequired,
  placementPeriods: PropTypes.arrayOf(PropTypes.object).isRequired,
  caseNoteSummary: PropTypes.string,
  missingNames: PropTypes.string,
};

export default ChildFile;
