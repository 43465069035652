import PropTypes from "prop-types";
import { Component } from "react";

import AttachmentDownloadLink from "@components/shared/AttachmentDownloadLink";
import Timestamp from "@components/shared/Timestamp";

import preventDefault from "@lib/preventDefault";

import { DocumentsClientContext } from "../../clients/DocumentsClient";

class DocumentLink extends Component {
  performDelete = client =>
    preventDefault(() => {
      const {
        doc,
        link: { attachmentId },
      } = this.props;

      client.deleteAttachment(doc, attachmentId);
    });

  render() {
    const {
      withLinksToDelete,
      link: {
        title,
        url,
        deletedAt,
        mayDelete,
        filename,
        contentType,
        attachmentId,
      },
    } = this.props;

    const attachmentShim = {
      contentType,
      downloadUrl: url,
      filename,
      id: attachmentId,
      name: title,
    };

    // TODO: do a non-data-confirm confirm

    return (
      <DocumentsClientContext.Consumer>
        {client => (
          <div>
            <If condition={Boolean(url)}>
              <AttachmentDownloadLink attachment={attachmentShim} />
            </If>
            {!url && title}
            {this.timesince()}
            {!deletedAt && withLinksToDelete && mayDelete && (
              <span>
                <a
                  href="#"
                  onClick={this.performDelete(client)}
                  data-confirm="Are you sure?"
                >
                  delete
                </a>
              </span>
            )}
          </div>
        )}
      </DocumentsClientContext.Consumer>
    );
  }

  timesince() {
    const {
      link: { createdAt, deletedAt },
    } = this.props;

    if (deletedAt) {
      return <Timestamp label="deleted" datetimestamp={deletedAt} />;
    }

    return <Timestamp label="uploaded" datetimestamp={createdAt} />;
  }
}

DocumentLink.propTypes = {
  doc: PropTypes.object.isRequired,
  link: PropTypes.object.isRequired,
  withLinksToDelete: PropTypes.bool,
};

export default DocumentLink;
