import { Surface, Icons } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState } from "react";

import BintiPropTypes from "@lib/BintiPropTypes";

import EditFinalAppealOutcome from "./EditFinalAppealOutcome";
import ViewFinalAppealOutcome from "./ViewFinalAppealOutcome";

const FinalAppealOutcome = ({ appealId, appealOutcomeOptions }) => {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <EditFinalAppealOutcome
        appealId={appealId}
        appealOutcomeOptions={appealOutcomeOptions}
        onSubmit={() => setEditing(false)}
        onCancel={() => setEditing(false)}
      />
    );
  }

  return (
    <Surface
      title={I18n.t("javascript.components.appeals.final_appeal_outcome")}
      secondary={
        <Icons.Pencil
          description={I18n.t(
            "javascript.components.appeals.appeal_outcome_edit"
          )}
          onClick={() => setEditing(true)}
        />
      }
    >
      <ViewFinalAppealOutcome appealId={appealId} />
    </Surface>
  );
};

FinalAppealOutcome.propTypes = {
  appealId: BintiPropTypes.ID,
  appealOutcomeOptions: PropTypes.array,
};

export default FinalAppealOutcome;
