import { Icons, DetailsTable, Surface } from "@heart/components";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { LUXON_DATEPICKER_FORMAT } from "@lib/dates";

import constructName from "../constructName";

const IconList = ({ iconActions }) => {
  if (isEmpty(iconActions)) {
    return null;
  }
  return (
    <Fragment>
      {iconActions.map(({ ariaLabel, onClick, icon }, index) => {
        const Icon = Icons[icon];
        return <Icon key={index} description={ariaLabel} onClick={onClick} />;
      })}
    </Fragment>
  );
};

IconList.propTypes = {
  iconActions: PropTypes.arrayOf(
    PropTypes.shape({
      ariaLabel: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
};

const detailsForPerson = ({
  dateOfBirth,
  joinedAt,
  leftAt,
  firstShiftDate,
  subrole,
  externalIdentifier,
}) =>
  [
    {
      label: I18n.t("views.applications.application_staff_member.person_id"),
      value: externalIdentifier,
    },
    {
      label: I18n.t("views.applications.application_staff_member.role"),
      value: subrole
        ? I18n.t(
            `views.applications.application_staff_member.staff_member_types.${subrole}`
          )
        : "",
    },
    {
      label: I18n.t("views.common.date_of_birth"),
      value: dateOfBirth
        ? DateTime.fromISO(dateOfBirth).toFormat(LUXON_DATEPICKER_FORMAT)
        : "",
    },
    {
      label: I18n.t("views.reference_requests.form.joined_date"),
      value: DateTime.fromISO(joinedAt).toFormat(LUXON_DATEPICKER_FORMAT),
    },
    {
      label: I18n.t("views.reference_requests.form.left_date"),
      value: leftAt
        ? DateTime.fromISO(leftAt).toFormat(LUXON_DATEPICKER_FORMAT)
        : null,
    },
    {
      label: I18n.t("views.applications.application_staff_member.first_shift"),
      value: firstShiftDate
        ? DateTime.fromISO(firstShiftDate).toFormat(LUXON_DATEPICKER_FORMAT)
        : null,
    },
  ].filter(item => item.value);

const StaffMemberCard = ({ iconActions, person }) => {
  const fullName = constructName(person);
  return (
    <Surface
      title={fullName}
      secondary={<IconList iconActions={iconActions} />}
    >
      <DetailsTable
        caption={I18n.t(
          "views.applications.application_staff_member.card_caption",
          { fullName }
        )}
        hideCaption={true}
        details={detailsForPerson(person)}
      />
    </Surface>
  );
};

StaffMemberCard.propTypes = {
  iconActions: PropTypes.arrayOf(
    PropTypes.shape({
      ariaLabel: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
  person: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    subrole: PropTypes.string,
    joinedAt: PropTypes.string,
    firstShiftDate: PropTypes.string,
    leftAt: PropTypes.string,
    reasonForLeaving: PropTypes.string,
    dateOfBirth: PropTypes.string,
    externalIdentifier: PropTypes.string,
  }).isRequired,
};

export default StaffMemberCard;
