/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//######################
// Copy to clipboard functionality
//######################

$(() =>
  $(".js-copy-to-clipboard").each(function (_i, el) {
    const $el = $(el);
    const text = $el.text();
    const $ctc_button = $(
      "<i class='fa far fa-copy copy-to-clipboard' title='Copy to clipboard'></i>"
    );
    $el.append($ctc_button);
    return $ctc_button.on("click", function (e) {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      el.appendChild(textarea);
      textarea.focus();
      textarea.setSelectionRange(0, text.length);
      document.execCommand("copy");
      return el.removeChild(textarea);
    });
  })
);
