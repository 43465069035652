import { Breadcrumbs, Layout } from "@heart/components";
import PropTypes from "prop-types";
import { adminChildrenPath } from "routes";

import { translationWithRoot } from "@components/T";
import AgencyServiceRecordForm from "@components/prevention/agency_service_records/AgencyServiceRecordForm";

const { T } = translationWithRoot("prevention.agency_service_records");

/**
 * @param {Array} methodsOfEngagement
 * @param {Number} numUnits
 * @param {Array} agencyServiceRates
 * @param {Array} agencyServiceReferralRecipients
 * @param {String} agencyServiceReferralId
 */
const AgencyServiceRecordFormPage = ({
  methodsOfEngagement,
  numUnits,
  agencyServiceRates,
  agencyServiceReferralRecipients,
  agencyServiceReferralId,
}) => (
  <Layout
    pageTitle={<T t="log_new" />}
    breadcrumbs={
      <Breadcrumbs
        pages={[
          {
            label: <T t="breadcrumbs.home" />,
            href: adminChildrenPath(),
          },
          {
            label: <T t="log_new" />,
            href: "#",
          },
        ]}
      />
    }
    main={{
      content: (
        <AgencyServiceRecordForm
          methodsOfEngagement={methodsOfEngagement}
          numUnits={numUnits}
          agencyServiceRates={agencyServiceRates}
          agencyServiceReferralRecipients={agencyServiceReferralRecipients}
          agencyServiceReferralId={agencyServiceReferralId}
        />
      ),
    }}
  />
);

AgencyServiceRecordFormPage.propTypes = {
  methodsOfEngagement: PropTypes.array,
  numUnits: PropTypes.number,
  agencyServiceRates: PropTypes.array,
  agencyServiceReferralRecipients: PropTypes.array,
  agencyServiceReferralId: PropTypes.string,
};

export default AgencyServiceRecordFormPage;
