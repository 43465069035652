import { Button, InputHidden } from "@heart/components";
import { difference, flatten, isEmpty, values, without } from "lodash";
import PropTypes from "prop-types";
import { Component, Fragment, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import preventDefault from "@lib/preventDefault";

import styles from "./SigningOrderInput.module.scss";

const REMAINING_DROPPABLE_ID = "remaining";

const SigningGroupPropType = PropTypes.arrayOf(PropTypes.string);
const SigningOrderPropType = PropTypes.arrayOf(SigningGroupPropType);

class SigningRole extends Component {
  render() {
    const { signer, index } = this.props;

    return (
      <Draggable key={signer} draggableId={signer} index={index}>
        {provided => (
          <div
            className={styles.signingRole}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            data-testid="signing-role"
          >
            <i className="fas fa-bars" /> {signer}
          </div>
        )}
      </Draggable>
    );
  }
}

SigningRole.propTypes = {
  signer: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

class SigningGroup extends Component {
  render() {
    const { signingGroup, innerRef, index } = this.props;

    return (
      <div className={styles.signingGroup} ref={innerRef}>
        <div className={styles.groupHeader}>Signing Group {index + 1}</div>
        {signingGroup.map((signer, roleIndex) => (
          <SigningRole key={signer} signer={signer} index={roleIndex} />
        ))}
      </div>
    );
  }
}

SigningGroup.propTypes = {
  signingGroup: SigningGroupPropType.isRequired,
  index: PropTypes.number.isRequired,
  innerRef: PropTypes.func.isRequired,
};

const HiddenInputs = ({ name, signingOrder }) => (
  <Fragment>
    {signingOrder.map((signingGroup, groupIndex) =>
      signingGroup.map(signer => (
        <InputHidden
          name={`${name}[${groupIndex}][]`}
          value={signer}
          key={`${groupIndex}-${signer}`}
        />
      ))
    )}
  </Fragment>
);

HiddenInputs.propTypes = {
  name: PropTypes.string.isRequired,
  signingOrder: SigningOrderPropType.isRequired,
};

const TestInput = ({ setSigningOrder, signingOrder }) => {
  if (!window.Binti?.testEnvironment) return false;

  const [localSigningOrder, setLocalSigningOrder] = useState(
    JSON.stringify(signingOrder)
  );

  return (
    <div data-testid="test-signing-order">
      <label>
        Set Signing Order
        <input
          value={localSigningOrder}
          onChange={({ target }) => {
            setLocalSigningOrder(target.value);
          }}
        />
      </label>
      <Button
        onClick={preventDefault(() => {
          setSigningOrder(JSON.parse(localSigningOrder));
        })}
      >
        Set
      </Button>
    </div>
  );
};

TestInput.propTypes = {
  setSigningOrder: PropTypes.func.isRequired,
  signingOrder: SigningOrderPropType.isRequired,
};

const remainingRoles = signingOrder =>
  difference(values(Binti.ProviderRoles), flatten(signingOrder));

class RemainingSignerRoles extends Component {
  render() {
    const { signingOrder } = this.props;

    return (
      <Droppable droppableId={REMAINING_DROPPABLE_ID}>
        {provided => (
          <div className={styles.remainingSignerRoles} ref={provided.innerRef}>
            <div className={styles.groupHeader}>Available roles</div>
            {remainingRoles(signingOrder).map((signer, index) => (
              <SigningRole key={signer} signer={signer} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

RemainingSignerRoles.propTypes = {
  signingOrder: SigningOrderPropType,
};

class SigningOrderInput extends Component {
  constructor(props) {
    super(props);

    this.state = { signingOrder: props.signingOrder || [] };
  }

  updateSigningGroup = (index, group) => {
    const { signingOrder } = this.state;
    const updatedSigningOrder = Object.assign([], signingOrder, {
      [index]: group,
    }).filter(g => !isEmpty(g));

    this.setState({ signingOrder: updatedSigningOrder });
  };

  onDragEnd = ({ source, destination, draggableId: signer }) => {
    const { signingOrder } = this.state;

    if (source.droppableId === destination.droppableId) {
      // we don't care about re-ordering, so ignore
    } else if (source.droppableId === REMAINING_DROPPABLE_ID) {
      const groupIndex = parseInt(destination.droppableId, 10);

      let groupWithNewSigner;

      if (groupIndex < signingOrder.length) {
        // existing group
        groupWithNewSigner = signingOrder[groupIndex].concat(signer).sort();
      } else {
        // new group
        groupWithNewSigner = [signer];
      }

      this.updateSigningGroup(groupIndex, groupWithNewSigner);
    } else if (destination.droppableId === REMAINING_DROPPABLE_ID) {
      const groupIndex = parseInt(source.droppableId, 10);
      const groupWithoutSigner = without(signingOrder[groupIndex], signer);

      this.updateSigningGroup(groupIndex, groupWithoutSigner);
    } else {
      // move from existing group
      const sourceGroupIndex = parseInt(source.droppableId, 10);
      const targetGroupIndex = parseInt(destination.droppableId, 10);

      let groupWithNewSigner;

      if (targetGroupIndex < signingOrder.length) {
        // existing group
        groupWithNewSigner = signingOrder[targetGroupIndex]
          .concat(signer)
          .sort();
      } else {
        // new group
        groupWithNewSigner = [signer];
      }

      const groupWithoutSigner = without(
        signingOrder[sourceGroupIndex],
        signer
      );

      const updatedSigningOrder = Object.assign([], signingOrder, {
        [sourceGroupIndex]: groupWithoutSigner,
        [targetGroupIndex]: groupWithNewSigner,
      }).filter(g => !isEmpty(g));

      this.setState({ signingOrder: updatedSigningOrder });
    }
  };

  render() {
    const { name } = this.props;
    let { signingOrder } = this.state;
    signingOrder = signingOrder || [];

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className={styles.container}>
          <HiddenInputs name={name} signingOrder={signingOrder} />
          <div className={styles.signingOrder}>
            {signingOrder.map((signingGroup, i) => (
              <Droppable key={`signing-group-${i}`} droppableId={i.toString()}>
                {provided => (
                  <Fragment>
                    <SigningGroup
                      innerRef={provided.innerRef}
                      signingGroup={signingGroup}
                      index={i}
                    />
                    {provided.placeholder}
                  </Fragment>
                )}
              </Droppable>
            ))}
            {!isEmpty(remainingRoles(signingOrder)) && (
              <Droppable
                key={`signing-group-${signingOrder.length}`}
                droppableId={signingOrder.length.toString()}
              >
                {provided => (
                  <div ref={provided.innerRef} className={styles.newGroup}>
                    Drag here for new group
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )}
          </div>
          <RemainingSignerRoles signingOrder={signingOrder} />
        </div>
        <TestInput
          setSigningOrder={updatedSigningOrder => {
            this.setState({ signingOrder: updatedSigningOrder });
          }}
          {...{ signingOrder }}
        />
      </DragDropContext>
    );
  }
}

SigningOrderInput.propTypes = {
  name: PropTypes.string.isRequired,
  signingOrder: SigningOrderPropType,
};

export default SigningOrderInput;
