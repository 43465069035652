import colors from "@heart/core/map_colors.scss";
import I18n from "i18n-js";

const generatePin = fill => ({
  path: "M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z",
  fillColor: fill,
  fillOpacity: 1,
});

/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]nitMap" }] */
/* global google, map */
$(() => {
  let defaultZoom;
  let closeButton;
  let infoButton;
  let legendZone;
  let legend;
  const statusObject = {};

  const STATUSES = [
    "recruiting",
    "recruiting_dropout",
    "applying",
    "withdrawn",
    "eligible_for_conversion",
    "conversion",
    "approved",
    "in_renewal_process",
    "closed",
    "denied",
    "ready_for_review",
  ];

  const GRADIENTS = {
    applying: [
      "rgba(255, 130, 85, 0)",
      "rgba(227, 94, 27, 1)",
      "rgba(238, 121, 80, 1)",
      "rgba(227, 94, 27, 1)",
      "rgba(205, 104, 71, 1)",
      "rgba(227, 94, 27, 1)",
      "rgba(255, 165, 93, 1)",
      "rgba(227, 94, 27, 1)",
      "rgba(238, 154, 87, 1)",
      "rgba(227, 94, 27, 1)",
      "rgba(205, 133, 77, 1)",
      "rgba(227, 94, 27, 1)",
      "rgba(255, 127, 50, 1)",
      "rgba(227, 94, 27, 1)",
      "rgba(238, 118, 47, 1)",
    ],
    approved: [
      "rgba(71, 60, 139, 0)",
      "rgba(17, 17, 108, 1)",
      "rgba(72, 118, 255, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(67, 110, 238, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(58, 95, 205, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(0, 0, 255, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(0, 0, 238, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(0, 0, 205, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(0, 0, 139, 1)",
    ],
    closed: [
      "rgba(0, 245, 255, 0)",
      "rgba(15, 195, 255, 1)",
      "rgba(0, 229, 238, 1)",
      "rgba(15, 195, 255, 1)",
      "rgba(0, 197, 205, 1)",
      "rgba(15, 195, 255, 1)",
      "rgba(0, 255, 255, 1)",
      "rgba(15, 195, 255, 1)",
      "rgba(0, 238, 238, 1)",
      "rgba(15, 195, 255, 1)",
      "rgba(0, 205, 205, 1)",
      "rgba(15, 195, 255, 1)",
      "rgba(151, 255, 255, 1)",
      "rgba(15, 195, 255, 1)",
      "rgba(141, 238, 238, 1)",
    ],
    conversion: [
      "rgba(55, 135, 34, 0)",
      "rgba(55, 135, 34, 1)",
      "rgba(60, 179, 113, 1)",
      "rgba(55, 135, 34, 1)",
      "rgba(152, 251, 152, 1)",
      "rgba(55, 135, 34, 1)",
      "rgba(0, 255, 127, 1)",
      "rgba(55, 135, 34, 1)",
      "rgba(124, 252, 0, 1)",
      "rgba(55, 135, 34, 1)",
      "rgba(0, 255, 0, 1)",
      "rgba(55, 135, 34, 1)",
      "rgba(0, 250, 154, 1)",
      "rgba(55, 135, 34, 1)",
      "rgba(173, 255, 47, 1)",
    ],
    denied: [
      "rgba(191, 62, 255, 0)",
      "rgba(94, 40, 209, 1)",
      "rgba(178, 58, 238, 1)",
      "rgba(94, 40, 209, 1)",
      "rgba(154, 50, 205, 1)",
      "rgba(94, 40, 209, 1)",
      "rgba(155, 48, 255, 1)",
      "rgba(94, 40, 209, 1)",
      "rgba(145, 44, 238, 1)",
      "rgba(94, 40, 209, 1)",
      "rgba(125, 38, 205, 1)",
      "rgba(94, 40, 209, 1)",
      "rgba(85, 26, 139, 1)",
      "rgba(94, 40, 209, 1)",
      "rgba(94, 40, 209, 1)",
    ],
    eligible_for_conversion: [
      "rgba(255, 246, 143, 0)",
      "rgba(255, 245, 89, 1)",
      "rgba(238, 230, 133, 1)",
      "rgba(255, 245, 89, 1)",
      "rgba(205, 198, 115, 1)",
      "rgba(255, 245, 89, 1)",
      "rgba(255, 236, 139, 1)",
      "rgba(255, 245, 89, 1)",
      "rgba(238, 220, 130, 1)",
      "rgba(255, 245, 89, 1)",
      "rgba(205, 190, 112, 1)",
      "rgba(255, 245, 89, 1)",
      "rgba(255, 245, 89, 1)",
      "rgba(255, 245, 89, 1)",
    ],
    in_renewal_process: [
      "rgba(71, 60, 139, 0)",
      "rgba(17, 17, 108, 1)",
      "rgba(72, 118, 255, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(67, 110, 238, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(58, 95, 205, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(0, 0, 255, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(0, 0, 238, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(0, 0, 205, 1)",
      "rgba(17, 17, 108, 1)",
      "rgba(0, 0, 139, 1)",
    ],
    recruiting: [
      "rgba(255, 20, 147, 0)",
      "rgba(255, 15, 74, 1)",
      "rgba(238, 18, 137, 1)",
      "rgba(255, 15, 74, 1)",
      "rgba(205, 16, 118, 1)",
      "rgba(255, 15, 74, 1)",
      "rgba(255, 52, 179, 1)",
      "rgba(255, 15, 74, 1)",
      "rgba(238, 48, 167, 1)",
      "rgba(255, 15, 74, 1)",
      "rgba(205, 41, 144, 1)",
      "rgba(255, 15, 74, 1)",
      "rgba(238, 58, 140, 1)",
      "rgba(255, 15, 74, 1)",
      "rgba(205, 50, 120, 1)",
    ],
    recruiting_dropout: [
      "rgba(255, 64, 64, 0)",
      "rgba(193, 69, 64, 1)",
      "rgba(238, 59, 59, 1)",
      "rgba(193, 69, 64, 1)",
      "rgba(205, 51, 51, 1)",
      "rgba(193, 69, 64, 1)",
      "rgba(255, 69, 0, 1)",
      "rgba(193, 69, 64, 1)",
      "rgba(238, 64, 0, 1)",
      "rgba(193, 69, 64, 1)",
      "rgba(205, 55, 0, 1)",
      "rgba(193, 69, 64, 1)",
      "rgba(255, 0, 0, 1)",
      "rgba(193, 69, 64, 1)",
      "rgba(238, 0, 0, 1)",
    ],
    withdraw: [
      "rgba(205, 133, 63, 0)",
      "rgba(222, 184, 135, 1)",
      "rgba(205, 133, 63, 1)",
      "rgba(210, 167, 118, 1)",
      "rgba(205, 133, 63, 1)",
      "rgba(198, 151, 101, 1)",
      "rgba(205, 133, 63, 1)",
      "rgba(186, 134, 85, 1)",
      "rgba(205, 133, 63, 1)",
      "rgba(174, 118, 68, 1)",
      "rgba(205, 133, 63, 1)",
      "rgba(162, 101, 52, 1)",
      "rgba(205, 133, 63, 1)",
      "rgba(150, 85, 35, 1)",
      "rgba(205, 133, 63, 1)",
    ],
    ready_for_review: [
      "rgba(201, 131, 201, 0)",
      "rgba(207, 125, 197, 1)",
      "rgba(213, 119, 192, 1)",
      "rgba(219, 113, 186, 1)",
      "rgba(225, 107, 179, 1)",
      "rgba(230, 100, 172, 1)",
      "rgba(235, 93, 163, 1)",
      "rgba(239, 86, 154, 1)",
      "rgba(243, 79, 144, 1)",
      "rgba(246, 72, 133, 1)",
      "rgba(248, 65, 122, 1)",
      "rgba(250, 59, 110, 1)",
      "rgba(251, 53, 97, 1)",
      "rgba(250, 49, 85, 1)",
      "rgba(249, 47, 71, 1)",
    ],
  };

  STATUSES.forEach(status => {
    statusObject[`${status}Markers`] = [];
    statusObject[`${status}Data`] = [];
  });

  window.initMap = () => {
    const options = {
      // zoom controls
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL,
        position: google.maps.ControlPosition.LEFT_CENTER,
      },
      gestureHandling: "greedy",

      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER,
      },
      fullscreenControl: true,

      // map styles
      styles: [
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [{ saturation: -100 }],
        },
        {
          featureType: "administrative.province",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            { saturation: -100 },
            { lightness: 65 },
            { visibility: "on" },
          ],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            { saturation: -100 },
            { lightness: 50 },
            { visibility: "simplified" },
          ],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [{ saturation: -100 }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "all",
          stylers: [{ lightness: 30 }],
        },
        {
          featureType: "road.local",
          elementType: "all",
          stylers: [{ lightness: 40 }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ saturation: -100 }, { visibility: "simplified" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            { hue: "#ffff00" },
            { lightness: -25 },
            { saturation: -97 },
          ],
        },
        {
          featureType: "water",
          elementType: "labels",
          stylers: [{ lightness: -25 }, { saturation: -100 }],
        },
      ],
    };

    defaultZoom = $("#q_zoom").val();
    if (defaultZoom !== "") {
      // zoom from earlier session
      options.zoom = parseInt(defaultZoom, 10);

      // center from previous lat/lon
      options.center = new google.maps.LatLng(
        $("#q_latitude").val(),
        $("#q_longitude").val()
      );
    } else {
      // set defaults, these will be overridden by the pins if any exist
      // set default zoom
      options.zoom = 12;
      options.center = new google.maps.LatLng(37.775, -122.434);
    }

    window.map = new google.maps.Map(document.getElementById("map"), options);

    window.countyLayer = new google.maps.KmlLayer({
      url: "https://storage.googleapis.com/binti-public/cnt_us_20210728.kmz",
      suppressInfoWindows: true,
      preserveViewport: true,
    });

    // create button to show map legend
    infoButton = document.createElement("button");
    infoButton.setAttribute("aria-label", I18n.t("application.map.show"));
    infoButton.setAttribute("style", "display: none;");
    const infoIcon = document.createElement("icon");
    infoIcon.className = "fa fa-info-circle";
    infoButton.append(infoIcon);

    // create button to hide map legend
    closeButton = document.createElement("button");
    closeButton.setAttribute("aria-label", I18n.t("application.map.hide"));
    closeButton.className = "legend-close-button";
    const closeIcon = document.createElement("icon");
    closeIcon.className = "fa fa-times";
    closeButton.append(closeIcon);

    // create map legend
    legend = document.createElement("ul");
    legend.className = "legend";
    legend.setAttribute("aria-label", I18n.t("application.map.legend"));
    legend.append(closeButton);

    // designate zone that will be populated by legend related components
    legendZone = document.createElement("div");
    legendZone.className = "legend-zone";
    legendZone.append(infoButton);
    legendZone.append(legend);
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legendZone);

    addMarkers();

    google.maps.event.addListener(map, "center_changed", updateStorage);
    google.maps.event.addListener(map, "zoom_changed", updateStorage);
  };

  const updateStorage = () => {
    const center = map.getCenter();

    $("#q_zoom").val(map.getZoom());
    $("#q_latitude").val(center.lat());
    $("#q_longitude").val(center.lng());
  };

  const initButtons = () => {
    $(".js-county-lines").on("click", e => {
      if ($(e.target).hasClass("is-off")) {
        $(e.target).removeClass("is-off");
        window.countyLayer.setMap(map);
      } else {
        $(e.target).addClass("is-off");
        window.countyLayer.setMap(null);
      }
    });

    $(".js-heat-map").on("click", toggleOff);
    $(".js-pins").on("click", toggleOff);

    buttonsChanged();
  };

  const inverse = e => {
    if (
      !$(e.target).hasClass("is-off") &&
      $(e.target).hasClass("js-heat-map")
    ) {
      $(".js-pins").addClass("is-off");
    } else if (
      !$(e.target).hasClass("is-off") &&
      $(e.target).hasClass("js-pins")
    ) {
      $(".js-heat-map").addClass("is-off");
    } else if (
      $(e.target).hasClass("is-off") &&
      $(e.target).hasClass("js-heat-map")
    ) {
      $(".js-pins").removeClass("is-off");
    } else if (
      $(e.target).hasClass("is-off") &&
      $(e.target).hasClass("js-pins")
    ) {
      $(".js-heat-map").removeClass("is-off");
    }
  };

  const toggleOff = e => {
    if ($(e.target).hasClass("is-off")) {
      $(e.target).removeClass("is-off");
    } else {
      $(e.target).addClass("is-off");
    }
    inverse(e);
    buttonsChanged();
  };

  const toggleLegend = e => {
    e.stopPropagation();
    e.preventDefault();
    if ($(e.target).hasClass("fa-times")) {
      infoButton.setAttribute("style", "display: block;");
      legend.setAttribute("style", "display: none;");
    } else {
      infoButton.setAttribute("style", "display: none;");
      legend.setAttribute("style", "display: block;");
    }
  };

  const buttonsChanged = () => {
    const currentStatus = [];
    STATUSES.forEach(status => {
      if (!$(`.js-${status}`).hasClass("is-off")) {
        currentStatus.push(status);
      }
      statusObject[`${status}Markers`].forEach(marker => {
        marker.setVisible(false);
      });
      statusObject[`${status}HeatMap`].setMap(null);
    });

    currentStatus.forEach(status => {
      if (!$(".js-pins").hasClass("is-off")) {
        statusObject[`${status}Markers`].forEach(marker => {
          marker.setVisible(true);
        });
      } else {
        statusObject[`${status}HeatMap`].setMap(map);
      }
    });
  };

  const createContent = arr =>
    $.map(arr, elem => $(`<p class='infobox'>${elem}</p>`)[0].outerHTML).join(
      ""
    );

  const addMarkers = () => {
    const data = window.Binti.map_data;
    let i = 0;
    let pinInfo;
    let lat;
    let lon;
    let latLng;
    let marker;
    let key;
    let content;
    let isConversion;
    let isRenewal;
    const infoWindow = new google.maps.InfoWindow();
    const bounds = new google.maps.LatLngBounds();
    const schoolMarkers = {};

    /** TODO: ENG-10768
     * For a11y we need more than color differentiation between
     * the different categories */
    const DISPLAY_VALUES = {
      recruiting: {
        color: colors["colors-map-pink-200"],
        translationKey: "recruiting",
      },
      recruiting_dropout: {
        color: colors["colors-map-pink-100"],
        translationKey: "recruiting_dropout",
      },
      applying: {
        color: colors["colors-map-orange-200"],
        translationKey: "applying",
      },
      withdrawn: {
        color: colors["colors-map-orange-100"],
        translationKey: "withdrawn",
      },
      eligible_for_conversion: {
        color: colors["colors-map-green-100"],
        translationKey: "eligible_for_conversion",
      },
      conversion: {
        color: colors["colors-map-green-200"],
        translationKey: "in_conversion_process",
      },
      approved: {
        color: colors["colors-map-blue-200"],
        translationKey: "approved",
      },
      in_renewal_process: {
        color: colors["colors-map-blue-200"],
        translationKey: "renewal_applying",
      },
      closed: {
        color: colors["colors-map-blue-100"],
        translationKey: "closed",
      },
      denied: {
        color: colors["colors-map-purple-200"],
        translationKey: "denied",
      },
      ready_for_review: {
        color: colors["colors-map-purple-100"],
        translationKey: "ready_for_review",
      },
    };

    if (
      (Array.isArray(Binti.map_data.statuses) &&
        !Binti.map_data.statuses.includes("ready_for_review")) ||
      (!Array.isArray(Binti.map_data.statuses) &&
        Binti.map_data.statuses.ready_for_review === undefined)
    ) {
      delete DISPLAY_VALUES.ready_for_review;
      delete GRADIENTS.ready_for_review;
      STATUSES.pop();
    }

    for (i = 0; i < data.location_data.length; i += 1) {
      pinInfo = data.location_data[i].pin_information;
      lat = data.location_data[i].latitude;
      lon = data.location_data[i].longitude;
      isConversion = data.location_data[i].is_conversion;
      isRenewal = data.location_data[i].is_renewal;
      key = isRenewal ? "in_renewal_process" : data.location_data[i].status;

      if (i === 0 && defaultZoom !== "") {
        map.panTo(new google.maps.LatLng(lat, lon));
      }

      latLng = new google.maps.LatLng(lat, lon);
      bounds.extend(latLng);

      content = createContent(pinInfo);

      let pinColor = DISPLAY_VALUES[key].color;
      if (key === "applying" && isConversion) {
        pinColor = DISPLAY_VALUES.conversion.color;
      }

      marker = new google.maps.Marker({
        position: latLng,
        map: map,
        visible: false,
        icon: generatePin(pinColor),
      });

      if (statusObject[`${key}Markers`]) {
        statusObject[`${key}Markers`].push(marker);
      }

      if (statusObject[`${key}Data`]) {
        statusObject[`${key}Data`].push(latLng);
      }

      map.fitBounds(bounds);

      /* eslint-disable */
      google.maps.event.addListener(
        marker,
        "click",
        (function (m, c) {
          return function () {
            infoWindow.setContent(c);
            infoWindow.open(map, m);
          };
        })(marker, content)
      );
      /* eslint-enable */
    }

    $.each(window.Binti.schools, (_index, school) => {
      latLng = new google.maps.LatLng(school.latitude, school.longitude);
      schoolMarkers[school.school_id] = new google.maps.Marker({
        position: latLng,
        map: map,
        visible: true,
        icon: school.icon_path,
        title: school.name,
      });
      content = createContent([school.name, school.address]);

      google.maps.event.addListener(
        schoolMarkers[school.school_id],
        "click",
        () => {
          infoWindow.setContent(content);
          infoWindow.open(map, schoolMarkers[school.school_id]);
        }
      );
    });

    $.each(window.Binti.geojson_urls, (_index, url) => {
      // Tests use jQuery.active to see when all ajax requests are complete.
      // We don't control this ajax call, but we can register the call as
      // happening, then clear it in the callback below so that tests can
      // wait for it to finish.
      //
      // eslint-disable-next-line no-console
      console.log("loading geojson");
      $.active += 1;
      map.data.loadGeoJson(url, { uselessMiddleArgument: true }, () => {
        // eslint-disable-next-line no-console
        console.log("geojson loaded");
        if (window.Binti.testEnvironment) {
          $("body").append("geojson loaded");
        }
        $.active -= 1;
      });
      map.data.setStyle({
        fillColor: "green",
        strokeColor: "green",
        strokeWeight: 2,
      });

      map.data.addListener("click", event => {
        const schoolMarker = schoolMarkers[event.feature.getProperty("id")];
        infoWindow.setContent(event.feature.getProperty("name"));
        if (schoolMarker) {
          infoWindow.open(map, schoolMarker);
        } else {
          infoWindow.setPosition(event.latLng);
          infoWindow.open(map);
        }
      });
    });

    /* eslint-disable */
    STATUSES.forEach(function (status) {
      statusObject[status + "HeatMap"] =
        new google.maps.visualization.HeatmapLayer({
          data: statusObject[status + "Data"],
          dissipating: true,
          gradient: GRADIENTS[status],
          opacity: 0.8,
          maxIntensity: 10,
          map: null,
        });

      // Populate map legend
      const legendRow = document.createElement("li");
      const color = document.createElement("div");
      color.className = "color-swatch";
      color.setAttribute(
        "style",
        `background-color: ${DISPLAY_VALUES[status].color};`
      );

      const description = document.createElement("span");
      description.innerHTML = I18n.t(
        `application.statuses.${DISPLAY_VALUES[status].translationKey}`
      );

      legendRow.appendChild(color);
      legendRow.appendChild(description);
      legend.appendChild(legendRow);
    });
    /* eslint-enable */

    closeButton.addEventListener("click", e => toggleLegend(e));
    infoButton.addEventListener("click", e => toggleLegend(e));

    initButtons();
  };

  if (Binti.map_data) {
    const url = [
      "https://maps.googleapis.com/maps/api/js?v=3.37",
      `key=${Binti.google_maps_api_key}`,
      "libraries=visualization",
      "callback=initMap",
    ].join("&");

    $("head").append($(`<script src="${url}" async defer></script>`));
  }
});
