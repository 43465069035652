import PropTypes from "prop-types";

import DocumentToggleButton from "./DocumentToggleButton";

const isMarked = doc => doc.completedOffline;

const MarkReceivedButton = ({ doc, backgroundLoading }) => (
  <DocumentToggleButton
    doc={doc}
    backgroundLoading={backgroundLoading}
    isMarkable={doc.mayBeMarkedReceived}
    isMarked={isMarked}
    mark="markComplete"
    unmark="markIncomplete"
    label={doc.completedOffline ? "Mark incomplete" : "Received"}
  />
);

MarkReceivedButton.propTypes = {
  doc: PropTypes.object.isRequired,
  backgroundLoading: PropTypes.bool,
};

export default MarkReceivedButton;
