import { PropTypes } from "prop-types";
import { useState } from "react";

const ReasonSearchClosed = ({
  hasPreviousPlacement,
  initialReason = "",
  reasons,
  selectClassName,
  selectName,
}) => {
  const [reason, setReason] = useState(initialReason);
  const [hasReasonChanged, setHasReasonChanged] = useState(false);

  const id = "placement_search_reason_closed";

  const onChange = ({ target: { value } }) => {
    setReason(value);
    setHasReasonChanged(true);
  };

  const showMsg =
    hasReasonChanged &&
    !hasPreviousPlacement &&
    reason?.startsWith("not_placed");

  return (
    <div>
      <label htmlFor={id}>Reason Search Closed</label>
      <select
        id={id}
        className={selectClassName}
        name={selectName}
        onChange={onChange}
        value={reason}
      >
        <option value="" label=" "></option>
        {reasons.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <If condition={showMsg}>
        <em>
          Based on the reason selected above, we will select the box on the Edit
          Child page to mark them as not being in agency care. This will move
          the child to the &quot;Not in Agency Care&quot; tab on the Child
          Dashboard.
        </em>
      </If>
    </div>
  );
};

ReasonSearchClosed.propTypes = {
  hasPreviousPlacement: PropTypes.bool,
  initialReason: PropTypes.string,
  reasons: PropTypes.array.isRequired,
  selectClassName: PropTypes.string,
  selectName: PropTypes.string.isRequired,
};

export default ReasonSearchClosed;
