import PropTypes from "prop-types";
import { useState } from "react";

import preventDefault from "@lib/preventDefault";

import NavBar from "./NavBar";
import styles from "./NavBarWrapper.module.scss";

const NavBarWrapper = ({ logo, root, ...rest }) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleOnClick = preventDefault(() => {
    setShowMenu(!showMenu);
  });

  return (
    <nav className={styles["navbar-wrapper"]}>
      <div className={styles["binti-logo"]}>
        <a href={root}>
          <img src={logo} alt="Binti Logo" />
        </a>
      </div>
      <a
        href="#"
        onClick={handleOnClick}
        className={styles.menu}
        data-testid="hamburger-menu"
      >
        {showMenu ? (
          <i className="fas fa-times"></i>
        ) : (
          <i className="fas fa-bars"></i>
        )}
      </a>
      {showMenu && (
        <div className={styles["menu-popup"]}>
          <NavBar {...{ logo, root, ...rest }} hideLogo={true}></NavBar>
        </div>
      )}
    </nav>
  );
};

NavBarWrapper.propTypes = {
  logo: PropTypes.string.isRequired,
  root: PropTypes.string.isRequired,
};

export default NavBarWrapper;
