import {
  InputCheckbox,
  InputCheckboxGroup,
  InputText,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React from "react";

import AgencyDropdown from "@components/shared/AgencyDropdown";
import BackgroundCheckTypeDropdown from "@components/shared/BackgroundCheckTypeDropdown";

import BackgroundCheckStepsDropdown from "./BackgroundCheckStepsDropdown";
import ProviderRoles from "./ProviderRoles";

const BackgroundCheckForm = ({ formState, setFormAttribute }) => (
  <React.Fragment>
    <AgencyDropdown
      label={I18n.t("views.common.agency")}
      required
      value={formState.agencyId}
      onChange={setFormAttribute("agencyId")}
    />
    <InputText
      label={I18n.t("admin.background.check.custom_name")}
      required
      value={formState.name}
      onChange={setFormAttribute("name")}
    />
    <BackgroundCheckTypeDropdown
      label={I18n.t("admin.background.check.type")}
      required
      value={formState.backgroundCheckTypeId}
      onChange={setFormAttribute("backgroundCheckTypeId")}
    />
    <InputCheckboxGroup
      value={formState.rolesSpec}
      values={ProviderRoles}
      label={I18n.t("admin.background.check.needed_for")}
      onChange={setFormAttribute("rolesSpec")}
    />
    <BackgroundCheckStepsDropdown
      value={formState.step}
      onChange={setFormAttribute("step")}
    />
    <InputCheckbox
      label={I18n.t("admin.background.renewal_required")}
      description={I18n.t("admin.background.every_renewal_requires")}
      value={formState.renewalRequired}
      onChange={setFormAttribute("renewalRequired")}
    />
    <InputCheckbox
      label={I18n.t("admin.background.check.required")}
      value={formState.required}
      onChange={setFormAttribute("required")}
      data-testid={"required-checkbox"}
    />
  </React.Fragment>
);

BackgroundCheckForm.propTypes = {
  formState: PropTypes.object,
  onSubmit: PropTypes.func,
  setFormAttribute: PropTypes.func,
};

export default BackgroundCheckForm;
