import { get } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import Block from "./Block";

const GroupBlock = ({ ...props }) => {
  // eslint-disable-next-line camelcase
  const { items, title, description, group_members, renderErrors } = props;
  const groupItems = items || [];

  return (
    <div>
      <If condition={title}>
        <p>{title}</p>
      </If>
      <If condition={description}>
        <p className="description">{description}</p>
      </If>

      {groupItems.map((block, idx) => (
        <div className={`test-list-block-${idx + 1}`} key={block[0].id}>
          <Block
            questions={block}
            title={
              <Fragment>
                {get(group_members, [idx, "group_subtitle"])} - {title}
              </Fragment>
            }
            renderErrors={renderErrors}
          />
        </div>
      ))}
    </div>
  );
};

GroupBlock.propTypes = {
  items: PropTypes.array,
  title: PropTypes.node,
  description: PropTypes.node,
  group_members: PropTypes.array,
  siblings: PropTypes.array,
  renderErrors: PropTypes.bool,
};
export default GroupBlock;
