import { clickable } from "@heart/core/clickable.module.scss";
import classnames from "classnames";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import styles from "./Flex.module.scss";

/**
 * Flexbox convenience component.  Most of the `<Flex>` prop names and
 * values should be similar to the flex properties available in CSS.
 *
 * Not all Flexbox options are available, and that's by design!
 * We want to achieve consistency in our UX by limiting the options available.
 *
 * You can always fallback to using Flexbox 'manually' over in
 * your component's `module.scss` by setting Flex properties yourself -
 * but if you notice yourself doing this repeatedly for the same
 * outcome, raise it to the FE Guild and we might opt to add more props.
 *
 * The `<Flex/>` component accepts a `ref` to enable things like keyboard functionality
 *
 *
 * For those new to Flexbox, here's a cheatsheet:
 *
 * https://css-tricks.com/snippets/css/a-guide-to-flexbox/
 */
const Flex = forwardRef(
  (
    {
      row,
      column,
      mobileColumn,
      tabletColumn,
      gap = "100",
      justify = "start",
      align = "normal",
      wrap = false,
      as,
      className,
      grow,
      fullWidth,
      ...props
    },
    ref
  ) => {
    const Component = as || "div";
    const isClickable = ["button", "a"].includes(as);
    return (
      <Component
        ref={ref}
        className={classnames(
          styles.flex,
          styles[`gap-${gap}`],
          styles[`justify-${justify}`],
          styles[`align-${align}`],
          {
            [styles.row]: row,
            [styles.column]: column,
            [styles.mobileColumn]: mobileColumn,
            [styles.tabletColumn]: tabletColumn,
            [styles.wrap]: wrap,
            [clickable]: isClickable,
            [styles.grow]: grow,
            [styles.fullWidth]: fullWidth,
          },
          className
        )}
        {...props}
      />
    );
  }
);
Flex.displayName = "Flex";

Flex.propTypes = {
  /** Whether or not this Flex is a row.  Mutually exclusive with `column`. */
  row: PropTypes.bool,
  /** Whether or not this Flex is a column.  Mutually exclusive with `row`. */
  column: PropTypes.bool,
  /** If true, turns this row into a column on mobile */
  mobileColumn: PropTypes.bool,
  /** If true, turns this row into a column on tablet and mobile. */
  tabletColumn: PropTypes.bool,
  /** Gap between child elements */
  gap: PropTypes.oneOf(["0", "100", "200", "300"]),
  /** Orientation along the main flex axis */
  justify: PropTypes.oneOf(["start", "end", "center", "space-between"]),
  /** Orientation along the cross flex axis */
  align: PropTypes.oneOf(["center", "start", "end"]),
  /** Whether the elements should wrap if the container is too small */
  wrap: PropTypes.bool,
  /** What kind of DOM element to use.  Defaults to `<div>` */
  as: PropTypes.string,
  /** Additional classes to customize the appearance of this Container */
  className: PropTypes.string,
  children: PropTypes.node,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
  /** If we want flex-grow: 1 on this container.
   *  See https://css-tricks.com/snippets/css/a-guide-to-flexbox/#aa-flex-grow for docs.
   */
  grow: PropTypes.bool,
  /** If we want width: 100% on this container. */
  fullWidth: PropTypes.bool,
};

export const FlexItem = ({ expand = "sm", as, ...props }) => {
  const Component = as || "div";
  return (
    <Component className={classnames(styles[`expand-${expand}`])} {...props} />
  );
};

FlexItem.propTypes = {
  /** How much space this component should take up */
  expand: PropTypes.oneOf(["none", "sm", "md", "lg"]),
  /** What kind of DOM element to use.  Defaults to `<div>` */
  as: PropTypes.string,
  children: PropTypes.node,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};

export default Flex;
