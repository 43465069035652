import { useQuery } from "@apollo/client";
import { InputCheckbox, InputHidden } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import AgencyWorker from "@graphql/queries/AgencyWorker.graphql";

const name =
  "agency_worker[agency_admin_assignment_attributes][explicit_can_change_status]";

/**
 * A GraphQL-powered "Explicitly allow application status changes" checkbox.
 *   New Agency Worker > Role "caseworker" > Set Agency > Set Role to "Agency Worker"
 */
const CanChangeStatusCheckbox = ({
  workerId,
  selectedRoleId,
  explicitCanChangeStatusExisting,
  explicitCanChangeStatusRoles,
}) => {
  const { data } = useQuery(AgencyWorker, {
    variables: { workerId },
    skip: !workerId,
  });

  const { id: roleId } = data?.agencyWorker?.roleForPermissions || {};

  const [explicitCanChangeStatus, setExplicitCanChangeStatus] = useState(
    explicitCanChangeStatusExisting
  );

  if (
    !explicitCanChangeStatusRoles.includes(Number(selectedRoleId || roleId))
  ) {
    return null;
  }

  return (
    <li>
      <InputHidden name={name} value="false" />
      <InputCheckbox
        value={explicitCanChangeStatus}
        onChange={setExplicitCanChangeStatus}
        name={name}
        label={I18n.t(
          "activerecord.attributes.agency_admin_assignment.explicit_can_change_status"
        )}
        htmlValue="true"
      />
    </li>
  );
};

CanChangeStatusCheckbox.propTypes = {
  workerId: PropTypes.number,
  selectedRoleId: PropTypes.string,
  explicitCanChangeStatusExisting: PropTypes.bool.isRequired,
  explicitCanChangeStatusRoles: PropTypes.array.isRequired,
};

export default CanChangeStatusCheckbox;
