// module for Quill to set a11y titles for the toolbar buttons
//
// Quill should do this out of the box but hasn't yet, so at least we
// can use this module to localize while making a11y in the meantime
// https://github.com/quilljs/quill/issues/2038

export default class QuillToolbarA11y {
  constructor(
    quill,
    { translations, setTitle = true, setAriaLabel = true } = {}
  ) {
    const toolbar = quill.getModule("toolbar");

    if (!toolbar) return;

    translations.forEach(spec => {
      const { key, translation } = spec;

      Array.from(toolbar.container.getElementsByClassName(`ql-${key}`))
        .filter(element => {
          if (element.nodeName !== "BUTTON") return false;

          // allows for specification of falsy values
          if ("value" in spec) {
            return element.value === spec.value;
          }

          return true;
        })
        .forEach(button => {
          if (setTitle) {
            button.setAttribute("aria-label", translation);
          }

          if (setAriaLabel) {
            button.setAttribute("title", translation);
          }
        });
    });
  }
}
