import { useMutation } from "@apollo/client";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import EditableDate from "@components/reusable_ui/EditableDate";

import SetDateApplicationStarted from "@graphql/mutations/SetDateApplicationStarted";
import AppRequirementReconciliation from "@graphql/queries/ApplicationRequirementReconciliation.graphql";

import { formatAsLongDate } from "@lib/dates";

const renderDate = date =>
  I18n.t(
    "javascript.components.application_requirements_shared.application_signed_date_control.signed_on",
    { date: formatAsLongDate(date) }
  );

const DateApplicationStartedControl = ({ application, aroundLoader }) => {
  const [setDateApplicationStarted] = useMutation(SetDateApplicationStarted, {
    refetchQueries: [
      {
        query: AppRequirementReconciliation,
        variables: { applicationId: application.id },
      },
    ],
  });

  const onDateChanged = aroundLoader(dateApplicationStarted => {
    if (isEmpty(dateApplicationStarted)) return false;

    return setDateApplicationStarted({
      variables: {
        applicationId: application.id,
        dateApplicationStarted,
      },
    });
  });

  return (
    <EditableDate
      label={I18n.t(
        "javascript.components.application_requirements_shared.application_signed_date_control.date_signed"
      )}
      editDateButtonTitle={I18n.t(
        "javascript.components.application_requirements_shared.application_signed_date_control.edit_date_signed"
      )}
      onChange={onDateChanged}
      required
      value={application.dateApplicationStarted}
      renderDate={renderDate}
    />
  );
};

DateApplicationStartedControl.propTypes = {
  application: PropTypes.object.isRequired,
  aroundLoader: PropTypes.func.isRequired,
};

export default DateApplicationStartedControl;
