import { useMutation } from "@apollo/client";
import {
  Actions,
  InputCheckbox,
  InputHidden,
  InputText,
  PageContainer,
  SurfaceForm,
  toast,
} from "@heart/components";
import InputCsrfToken from "@heart/components/inputs/InputCsrfToken";
import { isEmpty, isFunction } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import { translationWithRoot } from "@components/T";
import HtmlEditor from "@components/inputs/HtmlEditor";

import SendTestCampaignEmail from "@graphql/mutations/SendTestCampaignEmail.graphql";
import UploadEmailCampaignFiles from "@graphql/mutations/UploadEmailCampaignFiles.graphql";

import preventDefault from "@lib/preventDefault";

const { t } = translationWithRoot("email_campaign_form");

const isHtmlEmpty = html => {
  // Before you type in Quill, the content will be "". After you type at all,
  // even if you delete everything, there will be at least a `<p></p>`
  // in the content, so we parse it to see if there's any content at all
  //
  try {
    const parsed = new DOMParser().parseFromString(html, "text/html");
    return isEmpty(parsed.body.innerText.trim());
  } catch {
    // fallback to this simple method of removing all tags in case fancy
    // parsing ever fails.
    return isEmpty(html.replace(/<[^>]*>/g, "").trim());
  }
};

export const EmailCampaignForm = ({
  agencyId,
  statuses,
  languages,
  applicationTemplateLineages,
  licenseTags,
  numberOfRecipients,
  submitDestination,
  csrfToken,
}) => {
  const [haveSentTest, setHaveSentTest] = useState(false);

  const [subject, setSubject] = useState("");
  const [replyToEmail, setReplyToEmail] = useState("");
  const [content, setContent] = useState("");
  const [replyToEmailValid, setReplyToEmailValid] = useState(false);

  const [isSubmittingCreateForm, setIsSubmittingCreateForm] = useState(false);

  const subjectRef = useRef();
  const replyToEmailRef = useRef();
  const acknowledgeResponsibleRef = useRef();
  const acknowledgePIIRef = useRef();

  const [contentInputError, setContentInputError] = useState("");

  const [acknowledgePIIChecked, setAcknowledgePIIChecked] = useState(false);
  const [acknowledgeResponsibleChecked, setAcknowledgeResponsibleChecked] =
    useState(false);
  const [sendTestCampaignEmail, { loading: sendingTest }] = useMutation(
    SendTestCampaignEmail
  );

  useEffect(() => {
    // users should send a new test if they change these things
    setHaveSentTest(false);
  }, [subject, replyToEmail, content]);

  useEffect(() => {
    setReplyToEmailValid(replyToEmailRef.current?.checkValidity());
  }, [replyToEmail]);

  useEffect(() => {
    if (!isHtmlEmpty(content)) {
      setContentInputError("");
    }
  }, [content]);

  const allFieldsPresent =
    replyToEmailValid && !isEmpty(subject) && !isHtmlEmpty(content);

  const onSendTest = preventDefault(() => {
    if (!allFieldsPresent) {
      if (!subjectRef.current.checkValidity()) {
        subjectRef.current.reportValidity();
      } else if (!replyToEmailRef.current.checkValidity()) {
        replyToEmailRef.current.reportValidity();
      }

      if (isHtmlEmpty(content)) {
        setContentInputError(t("content_required"));
      }

      return;
    }

    sendTestCampaignEmail({
      variables: {
        subject,
        replyToEmail,
        content,
        agencyId,
        statuses,
        languages,
        applicationTemplateLineages,
        licenseTags,
      },
    }).then(() => {
      toast.success({
        title: t("test_email_success_title"),
        message: t("test_email_success_message"),
      });
      setHaveSentTest(true);
    });
  });

  const onSubmit = e => {
    // modern compliant browsers will automatically prevent submitting
    // on these required checkboxes, but we add these checks just to
    // make doubly sure the boxes are checked.
    if (!acknowledgeResponsibleChecked) {
      acknowledgeResponsibleRef.current.reportValidity();
      e.preventDefault();
      return;
    }

    if (!acknowledgePIIChecked) {
      acknowledgePIIRef.current.reportValidity();
      e.preventDefault();
      return;
    }

    // Setting the `isSubmittingCreateForm` flag is the main reason we added an
    // onSubmit callback rather than just using the default browser submit.
    // We use it to prevent double submits.
    setIsSubmittingCreateForm(true);

    if (isFunction(e.target.form.requestSubmit)) {
      // all our supported browsers should have this, but just in case
      // (both methods work fine, but `requestSubmit` does the _exact_
      // same thing as clicking a submit button, which we're emulating
      // here.)
      e.target.form.requestSubmit();
    } else {
      e.target.form.submit();
    }
  };

  const [uploadEmailCampaignFiles] = useMutation(UploadEmailCampaignFiles);

  const uploadFile = file =>
    uploadEmailCampaignFiles({ variables: { files: [file] } });

  const primaryText = haveSentTest
    ? t("create_email_campaign", { count: numberOfRecipients })
    : t("send_test_email");

  const primaryAction = haveSentTest ? onSubmit : onSendTest;

  return (
    <PageContainer>
      <SurfaceForm
        title={t("create_email")}
        subtitle={t("number_of_recipients", { count: numberOfRecipients })}
        action={submitDestination}
        method="post"
        actions={
          <Actions
            primaryText={primaryText}
            primaryAction={primaryAction}
            isSubmitting={isSubmittingCreateForm || sendingTest}
            primaryDisabled={isSubmittingCreateForm || sendingTest}
            cancelAction={() => {
              // cancel means go back to the list selection page.
              // going back should preserve the current selection.
              history.back();
            }}
          ></Actions>
        }
      >
        <InputText
          label={t("subject")}
          name="email_campaign[subject]"
          value={subject}
          onChange={setSubject}
          fullWidth
          required
          ref={subjectRef}
        />
        <HtmlEditor
          label={t("content")}
          name="email_campaign[content]"
          uploadedFilesName="email_campaign[attachment_ids]"
          description={t("content_description")}
          error={contentInputError}
          onChange={setContent}
          uploadFile={uploadFile}
          fullWidth
          required
        ></HtmlEditor>
        <InputText
          label={t("reply_to_email")}
          name="email_campaign[reply_to_email]"
          value={replyToEmail}
          onChange={setReplyToEmail}
          description={t("reply_to_email_description")}
          fullWidth
          required
          type="email"
          ref={replyToEmailRef}
        />

        <InputHidden
          name="email_campaign[agency_id]"
          value={agencyId.toString()}
        />
        {statuses.map(status => (
          <InputHidden
            key={status}
            name="email_campaign[statuses][]"
            value={status}
          />
        ))}
        {languages.map(language => (
          <InputHidden
            key={language}
            name="email_campaign[languages][]"
            value={language}
          />
        ))}
        <If condition={licenseTags}>
          {licenseTags.map(licenseTag => (
            <InputHidden
              key={licenseTag}
              name="email_campaign[license_tags][]"
              value={licenseTag}
            />
          ))}
        </If>
        <If condition={applicationTemplateLineages}>
          {applicationTemplateLineages.map(applicationTemplateLineage => (
            <InputHidden
              key={applicationTemplateLineage}
              name="email_campaign[application_template_lineages][]"
              value={applicationTemplateLineage}
            />
          ))}
        </If>
        <InputCheckbox
          label={t("acknowledge_responsible")}
          isChecked={acknowledgeResponsibleChecked}
          onChange={setAcknowledgeResponsibleChecked}
          ref={acknowledgeResponsibleRef}
          required
        />
        <InputCheckbox
          label={t("acknowledge_pii")}
          isChecked={acknowledgePIIChecked}
          onChange={setAcknowledgePIIChecked}
          ref={acknowledgePIIRef}
          required
        />
        <InputCsrfToken csrfToken={csrfToken} />
      </SurfaceForm>
    </PageContainer>
  );
};

EmailCampaignForm.propTypes = {
  agencyId: PropTypes.number.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  languages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  applicationTemplateLineages: PropTypes.arrayOf(PropTypes.string.isRequired),
  licenseTags: PropTypes.arrayOf(PropTypes.string.isRequired),
  numberOfRecipients: PropTypes.number.isRequired,
  submitDestination: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default EmailCampaignForm;
