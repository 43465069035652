import { InputDropdown, InputText } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

export const getOptionFromPath = (selectedPath, options) => {
  if (selectedPath && options) {
    return options.find(({ path }) => selectedPath.startsWith(path));
  }

  return undefined;
};

/**
 * A single level of a nested enum. Receives its current selection from
 * its parent/caller and maintains the selection for its child(ren), if it has
 * one.
 *
 * When someone selects something at this level, it renders the children and
 * alert its parent.
 */
const NestedEnumLevel = ({
  label,
  placeholderLabel,
  levelOptions,
  levelSelectedOption,
  onLevelSelectChange,
  isRequired,
  onSelectedOptionChange,
  initialSelectedOptionPath,

  selectedOptionDetails,
  onOptionDetailsChange,
}) => {
  const childLevelOptions = levelSelectedOption && levelSelectedOption.children;

  const [childSelectedOption, setChildSelectedOption] = useState(
    getOptionFromPath(initialSelectedOptionPath, childLevelOptions)
  );

  const onChange = value => {
    setChildSelectedOption(undefined);
    const newSelectedOption = getOptionFromPath(value, levelOptions);
    onLevelSelectChange(newSelectedOption);
  };

  const onChildLevelSelectChange = option => {
    onSelectedOptionChange(option);
    setChildSelectedOption(option);
  };

  // Empty string value is the placeholder
  const value = levelSelectedOption ? levelSelectedOption.path : "";

  return (
    <div className="contains-inputs">
      <InputDropdown
        required={isRequired}
        label={label}
        value={value}
        values={levelOptions.map(option => ({
          value: option.path,
          label: option.label,
        }))}
        onChange={onChange}
      />
      {levelSelectedOption && levelSelectedOption.details && (
        <InputText
          label={levelSelectedOption.details.label}
          type="text"
          onChange={onOptionDetailsChange}
          value={selectedOptionDetails}
          required={levelSelectedOption.details.required}
        />
      )}
      {childLevelOptions && childLevelOptions.length > 0 && (
        <NestedEnumLevel
          label={levelSelectedOption.label}
          placeholderLabel={
            levelSelectedOption.placeholderLabel || placeholderLabel
          }
          levelOptions={childLevelOptions}
          levelSelectedOption={childSelectedOption}
          onLevelSelectChange={onChildLevelSelectChange}
          {...{
            initialSelectedOptionPath,
            selectedOptionDetails,
            onSelectedOptionChange,
            onOptionDetailsChange,
          }}
        />
      )}
    </div>
  );
};

NestedEnumLevel.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  placeholderLabel: PropTypes.string.isRequired,
  levelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selectable: PropTypes.bool.isRequired,
      children: PropTypes.array.isRequired,
      details: PropTypes.shape({
        required: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  levelSelectedOption: PropTypes.shape({
    key: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    selectable: PropTypes.bool.isRequired,
    children: PropTypes.array.isRequired,
    placeholderLabel: PropTypes.string,
    details: PropTypes.shape({
      required: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  }),
  selectedOptionDetails: PropTypes.string,
  onLevelSelectChange: PropTypes.func.isRequired,
  onSelectedOptionChange: PropTypes.func.isRequired,
  initialSelectedOptionPath: PropTypes.string,
  onOptionDetailsChange: PropTypes.func.isRequired,
};

export default NestedEnumLevel;
