import { InputDropdown } from "@heart/components";
import { PropTypes } from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("placement_search");

const ReasonSearchClosed = ({
  hasPreviousPlacement,
  value,
  values,
  onChange,
  required,
}) => {
  const [hasReasonChanged, setHasReasonChanged] = useState(false);

  const showMsg =
    hasReasonChanged &&
    !hasPreviousPlacement &&
    value?.startsWith("not_placed");

  return (
    <div>
      <InputDropdown
        label={t("reason_search_closed")}
        value={value}
        values={values}
        onChange={v => {
          onChange(v);
          setHasReasonChanged(true);
        }}
        required={required}
      />
      <If condition={showMsg}>
        <em>{t("message")}</em>
      </If>
    </div>
  );
};

ReasonSearchClosed.propTypes = {
  hasPreviousPlacement: PropTypes.bool,
  value: PropTypes.string,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default ReasonSearchClosed;
