import { InputRadioGroup, InputText, InputDropdown } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import { ALLOWED_EVENT_SLUGS } from "@root/constants";

import getErrorsForField from "./getErrorsForField";

const { t } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});

// This form is based on lib/json_schemas/integration_configurations/webhook.json
// and in the future, we could likely generate these forms from other JSON schema files
/**
 * WebhookConfigurationForm component renders a form for creating a Webhook integration
 *
 * @param {Object} props.formState - The current state of the form.
 * @param {Function} props.setFormAttribute - Function to update form attributes.
 * @param {Array} props.validationErrors - Array of validation errors.
 */
const WebhookConfigurationForm = ({
  formState,
  setFormAttribute,
  validationErrors,
}) => {
  const errorsForField = getErrorsForField(validationErrors);

  // we need some of these escapes because of the 'v' flag being added
  // see https://stackoverflow.com/a/76287241/3614985 for more info
  // eslint-disable-next-line no-useless-escape
  const URL_REGEX = /^https:\/\/(?:www\.)?[\w\-]+(\.[\w\-]+)+.*/.source;

  return (
    <Fragment>
      <InputText
        label={t("labels.webhook_endpoint")}
        value={formState.webhookEndpoint || "https://"}
        description={t("descriptions.needs_to_be_https")}
        pattern={URL_REGEX}
        onChange={setFormAttribute("webhookEndpoint")}
        error={errorsForField("webhookEndpoint")}
        required={true}
      />

      <InputDropdown
        label={t("labels.event")}
        value={formState.event}
        values={ALLOWED_EVENT_SLUGS.map(slug => ({
          label: t(`events.${slug}`),
          value: slug,
        }))}
        onChange={setFormAttribute("event")}
        error={errorsForField("event")}
        required={true}
      />
      <InputRadioGroup
        label={t("labels.status")}
        value={formState.status}
        onChange={setFormAttribute("status")}
        error={errorsForField("status")}
        required={true}
        values={[
          {
            label: t("labels.enabled"),
            value: "enabled",
          },
          {
            label: t("labels.disabled"),
            value: "disabled",
          },
        ]}
      />
    </Fragment>
  );
};

WebhookConfigurationForm.propTypes = {
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
  validationErrors: PropTypes.array,
};
export default WebhookConfigurationForm;
