import { Button } from "@heart/components";
import { isNumber } from "lodash";
import PropTypes from "prop-types";

import T from "@components/T";

import rolesT from "../rolesT";

const DEFAULT_TEXT_KEYS = {
  name_already_signed: "bintisign.signer_button.name_already_signed",
  cannot_sign_yet: "bintisign.signer_button.cannot_sign_yet",
  sign_for_name: "bintisign.signer_button.sign_for_name",
  sign: "bintisign.signer_button.sign",
};

// the roles for which the signing button displays the role instead
// of the user's name.
const ROLES_INSTEAD_OF_NAME_ROLES = ["caseworker", "supervisor", "parent"];

const AlreadySignedButton = ({ signerButtonText, role, name }) => (
  <Button disabled data-testid="bintisign-signer-button">
    <T
      t={
        signerButtonText.name_already_signed ||
        DEFAULT_TEXT_KEYS.name_already_signed
      }
      name={name || rolesT(role)}
    />
  </Button>
);

AlreadySignedButton.propTypes = {
  role: PropTypes.string.isRequired,
  signerButtonText: PropTypes.object,
  name: PropTypes.string,
};

const CannotSignYetButton = ({ signerButtonText, role, name }) => {
  let who = name;

  if (ROLES_INSTEAD_OF_NAME_ROLES.includes(role)) {
    who = rolesT(role);
  }

  return (
    <Button disabled data-testid="bintisign-signer-button">
      <T
        t={
          signerButtonText.cannot_sign_yet || DEFAULT_TEXT_KEYS.cannot_sign_yet
        }
        name={who}
      />
    </Button>
  );
};

CannotSignYetButton.propTypes = {
  role: PropTypes.string.isRequired,
  signerButtonText: PropTypes.object,
  name: PropTypes.string,
};

const SignForButton = ({ signerButtonText, role, onClick, name }) => {
  let signForText;

  if (ROLES_INSTEAD_OF_NAME_ROLES.includes(role)) {
    signForText = (
      <T
        t={signerButtonText.sign_for_name || DEFAULT_TEXT_KEYS.sign_for_name}
        name={rolesT(role)}
      />
    );
  } else if (name) {
    signForText = (
      <T
        t={signerButtonText.sign_for_name || DEFAULT_TEXT_KEYS.sign_for_name}
        name={name}
      />
    );
  } else {
    signForText = <T t={signerButtonText.sign || DEFAULT_TEXT_KEYS.sign} />;
  }

  return (
    <Button onClick={onClick} data-testid="bintisign-signer-button">
      {signForText}
    </Button>
  );
};

SignForButton.propTypes = {
  role: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  signerButtonText: PropTypes.object,
  name: PropTypes.string,
};

/**
 * A button that opens a BintiSign document for a user to review and sign as
 * a certain role in the document. E.g. "sign for caseworker",
 * "sign for applicant".
 */
const SignerButton = ({
  name,
  signerButtonText = {},
  requiredSignature: { role, order, isComplete },
  nextAllowedSignatureOrder,
  onClick,
}) => {
  if (isComplete) {
    return <AlreadySignedButton {...{ signerButtonText, role, name }} />;
  }

  if (
    isNumber(nextAllowedSignatureOrder) &&
    order > nextAllowedSignatureOrder
  ) {
    return <CannotSignYetButton {...{ signerButtonText, role, name }} />;
  }

  return <SignForButton {...{ signerButtonText, role, onClick, name }} />;
};

SignerButton.propTypes = {
  requiredSignature: PropTypes.object.isRequired,
  nextAllowedSignatureOrder: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  signerButtonText: PropTypes.object,
  name: PropTypes.string,
};

export default SignerButton;
