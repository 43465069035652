import { useQuery } from "@apollo/client";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import AgencyWorker from "@graphql/queries/AgencyWorker.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import AgencyDropdown from "../shared/AgencyDropdown";

const AgencyWorkerAgencyDropdown = ({
  hidden,
  workerId,
  selectedAgencyId,
  setSelectedAgencyId,
}) => {
  const { data } = useQuery(AgencyWorker, {
    variables: { workerId },
    skip: !workerId,
  });

  const { id: agencyId } = data?.agencyWorker?.agency || {};

  return (
    <div data-testid="agency-dropdown">
      <AgencyDropdown
        hidden={hidden}
        label={I18n.t("views.common.agency")}
        required
        value={selectedAgencyId || agencyId}
        onChange={setSelectedAgencyId}
        name="agency_worker[agency_admin_assignment_attributes][agency_id]"
      />
    </div>
  );
};
AgencyWorkerAgencyDropdown.propTypes = {
  hidden: PropTypes.bool,
  workerId: PropTypes.number,
  selectedAgencyId: BintiPropTypes.ID,
  setSelectedAgencyId: PropTypes.func,
};

export default AgencyWorkerAgencyDropdown;
