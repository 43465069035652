import { createBrowserHistory } from "history";
import PropTypes from "prop-types";
import { useMemo } from "react";

const History = ({ url }) => {
  const history = useMemo(() => {
    createBrowserHistory();
  });
  history.push(url);
};

History.propTypes = {
  url: PropTypes.string,
};
export default History;
