import { Flex, Icons } from "@heart/components";

/** This is a silly little easter egg based on a delightful slack phenomenon
 * https://app.getguru.com/card/iXnqrprT/Ava-and-her-cousin-Hans-my-sisters-fur-baby
 */
const Ava = () => (
  <Flex justify="end">
    <Icons.Star
      onClick={() =>
        window.alert("Ava and her cousin, Hans (my sister's fur baby)")
      }
      description="Ava and her cousin, Hans (my sister's fur baby"
    />
  </Flex>
);
export default Ava;
