import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import RecordWithAttachments from "@components/requirements/RecordWithAttachments";
import { Td, Tr } from "@components/reusable_ui/Table";

import DeleteFormInstanceAttachment from "@graphql/mutations/DeleteFormInstanceAttachment.graphql";

const UnmatchedFormInstanceRow = ({ record, application, aroundLoader }) => {
  const [deleteFormInstanceAttachment] = useMutation(
    DeleteFormInstanceAttachment
  );

  // Delete attachment
  const deleteAttachment = formInstance =>
    aroundLoader(({ attachmentId }) =>
      deleteFormInstanceAttachment({
        variables: {
          attachmentId,
          holderToken: application.holderToken,
          formInstanceId: formInstance.id,
        },
      })
    );

  let formName = record.form.name;

  if (
    record.form.neededFor &&
    record.form.neededFor.includes("reference_user")
  ) {
    let referredUserName = "application";

    if (record.referredUser) {
      referredUserName = `${record.referredUser.fullName}${
        record.referredUser.deletedAt ? " [USER DELETED]" : ""
      }`;
    }

    const referenceUserName = record.referenceUser.fullName;

    formName = `${formName} for ${referredUserName} (Reference: ${referenceUserName})`;
  }

  return (
    <Tr>
      <Td>
        {formName}

        <RecordWithAttachments {...{ deleteAttachment, record }} />
      </Td>
    </Tr>
  );
};

UnmatchedFormInstanceRow.propTypes = {
  record: PropTypes.shape({
    form: PropTypes.shape({
      name: PropTypes.string.isRequired,
      neededFor: PropTypes.arrayOf(PropTypes.string),
    }),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired, // IDs are strings in GraphQL
        downloadUrl: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        deletedAt: PropTypes.string, // ISO string
        permissions: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    referenceUser: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }),
    referredUser: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      deletedAt: PropTypes.string,
    }),
  }).isRequired,
  application: PropTypes.shape({
    holderToken: PropTypes.string.isRequired,
  }).isRequired,
  aroundLoader: PropTypes.func.isRequired,
};

export default UnmatchedFormInstanceRow;
