import PropTypes from "prop-types";

import styles from "../MobileStyling.module.scss";

const EmailCell = props => {
  const { header = "", email = "" } = props;
  return (
    <div className={styles.cell}>
      <div className={styles.smallHeader}>{header}</div>
      <div className={styles.value}>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    </div>
  );
};

EmailCell.propTypes = {
  header: PropTypes.string,
  email: PropTypes.string,
};

export default EmailCell;
