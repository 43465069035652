import { Button, Flex } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("data_table.pagination");
/** Pagination controls for data tables */
const TablePagination = ({ onNext, onPrevious, isFirstPage, isLastPage }) => (
  <Flex gap="300" justify="center">
    <Button variant="primary" onClick={onPrevious} disabled={isFirstPage}>
      {t("previous")}
    </Button>
    <Button variant="primary" onClick={onNext} disabled={isLastPage}>
      {t("next")}
    </Button>
  </Flex>
);

TablePagination.propTypes = {
  /** Function to be called when the user tries to navigate to the next page */
  onNext: PropTypes.func.isRequired,
  /** Function to be called when the user tries to navigate to the previous page */
  onPrevious: PropTypes.func.isRequired,
  /** Whether or not this page of data is the first page */
  isFirstPage: PropTypes.bool.isRequired,
  /** Whether or not this page of data is the last page */
  isLastPage: PropTypes.bool.isRequired,
};

export default TablePagination;
