import { useQuery } from "@apollo/client";
import {
  ArrayDataTable,
  Breadcrumbs,
  Button,
  DetailsTable,
  Flex,
  Layout,
  Pill,
  Surface,
  Text,
} from "@heart/components";
import _ from "lodash";
import { Fragment } from "react";
import {
  adminRootPath,
  agencyServicesPath,
  newAgencyServiceRecordPath,
} from "routes";

import Spinner from "@components/Spinner";
import { translationWithRoot } from "@components/T";
import AgencyServiceRecordsTable from "@components/prevention/agency_service_records/AgencyServiceRecordsTable";

import AgencyServiceReferralQuery from "@graphql/queries/prevention/AgencyServiceReferral.graphql";

const { t, T } = translationWithRoot("prevention");

const { default: BintiPropTypes } = require("@lib/BintiPropTypes");

/**
 * AgencyServiceReferralDetails component displays the details of an agency service referral.
 *
 * @param {string} props.agencyServiceReferralId - The ID of the agency service referral to display.
 * @returns {JSX.Element} The rendered component.
 */
const AgencyServiceReferralDetails = ({ agencyServiceReferralId }) => {
  const {
    data: agencyServiceReferralData,
    loading: agencyServiceReferralLoading,
  } = useQuery(AgencyServiceReferralQuery, {
    variables: { agencyServiceReferralId },
  });

  if (agencyServiceReferralLoading) {
    return <Spinner />;
  }

  const agencyServiceReferral =
    agencyServiceReferralData?.agencyServiceReferral || {};

  const serviceTypes =
    agencyServiceReferral?.agencyService?.serviceTypes.join(", ");
  const areasOfCoverage =
    agencyServiceReferral?.agencyService?.areasOfCoverage.join(", ");
  const serviceRates = agencyServiceReferral?.billableRates
    .map(rate => `${rate.code}-${rate.label}`)
    .join("; ");

  const ebpDisplayTitle =
    agencyServiceReferral.agencyService?.evidenceBasedPractice?.displayTitle;
  const serviceReferralTitle = `${t(
    "agency_service_referrals.dashboard.title"
  )}: ${ebpDisplayTitle} `;
  const capitalizedStatus = _.capitalize(agencyServiceReferral.status);

  const referralInfo = (
    <Surface title={t("agency_service_referrals.details")}>
      <DetailsTable
        details={[
          {
            label: t("agency_services.referring_agency"),
            value: agencyServiceReferral.agency.name,
          },
          {
            label: t("agency_services.name"),
            value: agencyServiceReferral.agencyService.name,
          },
          {
            label: t("agency_services.ffpsa_program"),
            value: agencyServiceReferral.agencyService.isFfpsa ? "Yes" : "No",
          },
          {
            label: t("agency_services.corresponding_ebp"),
            value: ebpDisplayTitle,
          },
          {
            label: t("agency_services.service_types"),
            value: serviceTypes.length === 0 ? t("none") : serviceTypes,
          },
          {
            label: t("agency_services.areas_of_coverage"),
            value: areasOfCoverage.length === 0 ? t("none") : areasOfCoverage,
          },
          {
            label: t("agency_service_referrals.form_page.service_rates"),
            value: serviceRates,
          },
          {
            label: t("agency_service_referrals.number_of_service_units"),
            value:
              agencyServiceReferral.numberOfServiceUnits === null
                ? t("none")
                : agencyServiceReferral.numberOfServiceUnits,
          },
          {
            label: t("agency_service_referrals.form_page.notes"),
            value:
              agencyServiceReferral.notes === 0
                ? t("none")
                : agencyServiceReferral.notes,
          },
        ]}
      />
    </Surface>
  );

  const recipientInfo = (
    <Surface title={t("agency_services.recipients.title")}>
      <ArrayDataTable
        columns={[
          {
            cell: "agencyHuman.fullName",
            columnName: {
              name: t("agency_services.recipients.name"),
            },
            columnSort: () => {},
            id: "recipient_name",
          },
          {
            cell: "startDate",
            columnName: {
              name: t("agency_services.start_date"),
            },
            columnSort: () => {},
            id: "recipient_start_date",
          },
          {
            cell: "endDate",
            columnName: {
              name: t("agency_services.end_date"),
              justify: "end",
            },
            columnSort: () => {},
            id: "recipient_end_date",
          },
        ]}
        data={agencyServiceReferral?.agencyServiceReferralRecipients}
      />
    </Surface>
  );

  const serviceRecordInfo = (
    <Surface
      title={t("agency_service_referrals.associated_service_records")}
      secondary={
        <Button
          href={newAgencyServiceRecordPath({
            agency_service_referral_id: agencyServiceReferralId,
          })}
          variant="primary"
        >
          {<T t="agency_service_records.new" />}
        </Button>
      }
    >
      <AgencyServiceRecordsTable
        agencyServiceReferralId={agencyServiceReferralId}
      />
    </Surface>
  );

  /** TODO ENG-19754 Buttons need onSubmit handler clicked */
  return (
    <Layout
      pageTitle={
        <Flex row>
          <Flex grow>
            <Text>
              {serviceReferralTitle}
              <Pill text={capitalizedStatus} variant="alert" />
            </Text>
          </Flex>
          <Button onClick={() => {}} type="submit" variant="primary">
            {t("agency_service_referrals.accept")}
          </Button>
          <Button onClick={() => {}} type="reset" variant="danger">
            {t("agency_service_referrals.reject")}
          </Button>
        </Flex>
      }
      breadcrumbs={
        <Breadcrumbs
          pages={[
            {
              label: <T t="agency_service_referrals.breadcrumbs.home"></T>,
              href: adminRootPath(),
            },
            {
              label: <T t="breadcrumbs.children_and_youth"></T>,
              href: agencyServicesPath(),
            },
            /** TODO ENG-19754: will add agencyServiceReferralsPath() once PR with route
             * is merged.
             */
            // {
            //   label: "TBD",
            //   href: preventionAgencyServiceReferralsPath(),
            // },
            {
              label: ebpDisplayTitle,
              href: "#",
            },
          ]}
        />
      }
      main={{
        content: (
          <Fragment>
            {referralInfo}
            {recipientInfo}
            {serviceRecordInfo}
          </Fragment>
        ),
      }}
    />
  );
};

AgencyServiceReferralDetails.propTypes = {
  agencyServiceReferralId: BintiPropTypes.ID,
};

export default AgencyServiceReferralDetails;
