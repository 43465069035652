import { InputText, InputPhone, InputDropdown } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

/**
 * PhoneNumberForm component renders a form for phone number input.
 *
 * @param {Object} formState - The form data.
 * @param {Function} setFormAttribute - Function to set form attribute.
 */
const PhoneNumberForm = ({ formState, setFormAttribute }) => (
  <Fragment>
    <td className="min-required-width">
      <InputPhone
        label="Phone number"
        value={formState.phoneNumber}
        onChange={setFormAttribute("phoneNumber")}
        required
      />
    </td>
    <td className="min-required-width">
      <InputDropdown
        label="Type"
        values={["mobile", "home", "work"]}
        value={formState.category}
        onChange={setFormAttribute("category")}
      />
    </td>
    <td>
      <InputText
        label="Notes"
        id={`notes-${formState.id}`}
        type="text"
        value={formState.notes}
        previousValue=""
        onChange={setFormAttribute("notes")}
      />
    </td>
  </Fragment>
);

PhoneNumberForm.propTypes = {
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
};

export default PhoneNumberForm;
