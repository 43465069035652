import {
  inputCommonPropTypes,
  InputGroupLayout,
} from "@heart/components/inputs/common";
import { entries, isEmpty, omitBy } from "lodash";
import PropTypes from "prop-types";

import NestedMulti from "./NestedMulti";

export const findOption = (path, options) => {
  const [prefix, suffix] = path.split(".", 2);
  const option = options.find(({ key }) => prefix === key);

  if (!option) return undefined;

  if (isEmpty(suffix)) return option;

  if (option.children) {
    return findOption(suffix, option.children);
  }

  return undefined;
};

/**
 * NestedMultiFormInput accepts a set of arbitrarily nested options,
 * an optional set of selectedOptions, and a callback onSelectedOptionsChange.
 *
 * onSelectedOptionsChange takes two arguments, the first is the new set of
 * options, the second is an object with a key "valid" which is true if the
 * options are valid and false otherwise.
 *
 * Treat it like a controlled input.
 *
 * Currently, the only use of NestedMultiFormInput is for the Ethnicity component.
 */
const NestedMultiFormInput = props => {
  const { options, selectedOptions = {}, onSelectedOptionsChange } = props;

  // lightweight validation to check if everything has required details
  const isValid = newOptions =>
    entries(newOptions).every(([path, details]) => {
      const option = findOption(path, options);

      if (!option) return false;

      if (option.details?.required && isEmpty(details)) {
        return false;
      }

      if (!option.details && !isEmpty(details)) {
        return false;
      }

      return true;
    });

  const onOptionSelected = (path, details = null) => {
    const newOptions = { ...selectedOptions, [path]: details };
    onSelectedOptionsChange(newOptions, { valid: isValid(newOptions) });
  };

  const onOptionUnselected = path => {
    const newOptions = omitBy(selectedOptions, (_value, key) =>
      key.startsWith(path)
    );
    onSelectedOptionsChange(newOptions, { valid: isValid(newOptions) });
  };

  return (
    <InputGroupLayout
      visuallySeparated
      {...props}
      inputsComponent={() => (
        <NestedMulti
          {...{
            options,
            onOptionSelected,
            onOptionUnselected,
            selectedOptions,
          }}
        />
      )}
    />
  );
};

NestedMultiFormInput.propTypes = {
  ...inputCommonPropTypes,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.object,
  onSelectedOptionsChange: PropTypes.func.isRequired,
};

export default NestedMultiFormInput;
