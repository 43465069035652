import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import styles from "../MobileStyling.module.scss";

const TelephoneCell = ({ header = "", phoneNumber = "" }) => (
  <div className={styles.cell}>
    <div className={styles.smallHeader}>{header}</div>
    <div className={styles.value}>
      {!isEmpty(phoneNumber) && (
        <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
      )}
    </div>
  </div>
);

TelephoneCell.propTypes = {
  header: PropTypes.string,
  phoneNumber: PropTypes.string,
};

export default TelephoneCell;
