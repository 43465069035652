import { Button, Flex, InputCheckbox } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { lazy, Fragment, Suspense, useEffect, useState } from "react";

import preventDefault from "@lib/preventDefault";

const BEAUTIFY_HTML_OPTIONS = Object.freeze({
  unformatted: ["code", "pre", "em", "strong", "span"],
  indent_inner_html: true,
  indent_char: " ",
  indent_size: 2,
  sep: "\n",
  wrap_line_length: 100,
});

let setCompleters;
let htmlBeautify;

// Ace is fairly large and we only need it for binti admin, so load
// it separately
const AceEditor = lazy(() =>
  import("react-ace").then(component =>
    Promise.all([
      import("ace-builds/src-noconflict/mode-yaml"),
      import("ace-builds/src-noconflict/mode-html"),
      import("ace-builds/src-noconflict/theme-solarized_light"),
      import("ace-builds/src-min-noconflict/ext-searchbox"),
      import("ace-builds/src-min-noconflict/ext-language_tools").then(tools => {
        ({ setCompleters } = tools);
      }),
      import("js-beautify").then(({ html_beautify: htmlBeautifyFn }) => {
        htmlBeautify = htmlBeautifyFn;
      }),
    ]).then(() => component)
  )
);

/** HTML and YAML textarea for Binti Admin */
const AceTextarea = ({ mode, id, name, value: initialValue, snippets }) => {
  const [value, setValue] = useState(initialValue || "");
  const [enableSnippets, setEnableSnippets] = useState(!isEmpty(snippets));

  useEffect(() => {
    if (!enableSnippets) return;
    if (!setCompleters) return;

    const completer = {
      getCompletions: (_editor, _session, _pos, _prefix, callback) => {
        const completions = snippets.map(({ shortcut, snippet }) => ({
          caption: shortcut,
          snippet,
          type: "snippet",
        }));

        callback(null, completions);
      },
    };

    setCompleters([completer]);

    // eslint is understandably confused by the inclusion of setCompleters here
    // it's a variable we're setting in the dynamic import resolution step
    // above. The import resolution does trigger a rerender, but eslint has no
    // way of knowing this.
    // TODO https://binti.atlassian.net/browse/ENG-14275
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCompleters, snippets, enableSnippets]);

  const onBeautify = preventDefault(() => {
    setValue(htmlBeautify(value, BEAUTIFY_HTML_OPTIONS));
  });

  return (
    <Suspense fallback="Loading">
      <Fragment>
        <textarea
          id={id}
          name={name}
          value={value}
          onChange={() => {
            // react warns about not having an onChange handler,
            // but we don't need one that does anything since Ace
            // manages `value`
          }}
          style={{ display: "none" }}
        />
        <AceEditor
          mode={mode}
          value={value}
          onChange={newValue => {
            // AceEditor passes a second argument describing the edit,
            // which we don't care about so we ignore it in this handler.
            // setValue would not ignore and issue a warning if we didn't
            // use this wrapper function to drop the second argument.
            setValue(newValue);
          }}
          theme="solarized_light"
          setOptions={{
            useWorker: false,
            useSoftTabs: true,
          }}
          tabSize={2}
          style={{ width: "100%" }}
          enableSnippets={enableSnippets}
          enableLiveAutocompletion={enableSnippets}
        />
        {mode === "html" && (
          <div>
            {/** TODO ENG-12656 this prevents the button from being stretched */}
            <Button onClick={onBeautify}>Beautify HTML</Button>
          </div>
        )}
        {!isEmpty(snippets) && (
          <Flex row justify="end">
            <InputCheckbox
              label="Enable snippets"
              onChange={setEnableSnippets}
              checked={enableSnippets}
            />
          </Flex>
        )}
      </Fragment>
    </Suspense>
  );
};

AceTextarea.propTypes = {
  /** To enable syntax highlight for html or yaml */
  mode: PropTypes.oneOf(["html", "yaml"]).isRequired,
  /** HTML id attrbute for textarea */
  id: PropTypes.string.isRequired,
  /** HTML name attribute for textarea */
  name: PropTypes.string.isRequired,
  /** HTML or YAML content goes here */
  value: PropTypes.string,
  /** Auto-complete snippets */
  snippets: PropTypes.arrayOf(
    PropTypes.shape({
      shortcut: PropTypes.string.isRequired,
      snippet: PropTypes.string.isRequired,
    })
  ),
};

export default AceTextarea;
