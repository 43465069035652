import { compareTwoStrings } from "string-similarity";

export const SIMILARITY_LEVEL = {
  SAME: "same",
  PARTIALLY_DIFFERENT: "partially_different",
  MOSTLY_DIFFERENT: "mostly_different",
  DIFFERENT: "different",
};
Object.freeze(SIMILARITY_LEVEL);

export const SIMILARITY_VALUES = Object.values(SIMILARITY_LEVEL);

Object.freeze(SIMILARITY_VALUES);

export const compareText = (base, changed) => {
  // interpolate in case either base or changed are undefined/null/weird
  const similarity = compareTwoStrings(`${base}`, `${changed}`);

  if (similarity === 1.0) {
    return SIMILARITY_LEVEL.SAME;
  } else if (similarity > 0.5) {
    return SIMILARITY_LEVEL.PARTIALLY_DIFFERENT;
  } else if (similarity > 0.1) {
    return SIMILARITY_LEVEL.MOSTLY_DIFFERENT;
  }
  return SIMILARITY_LEVEL.DIFFERENT;
};
