import { Flex, InputDropdown } from "@heart/components";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import { clearAllOtherBase64SearchParams } from "@lib/base64SearchParams";

import styles from "./GraphQLDataTable.module.scss";
import DataTable from "./common/DataTable";
import TablePagination from "./common/TablePagination";
import Filter from "./common/filter/Filter";
import useFilter from "./common/filter/useFilter";
import useGraphQLTable from "./useGraphQLTable";

const { t } = translationWithRoot("data_table");

/** A GraphQL-compatible table for displaying data that can
 * optionally be sorted and filtered.  Give it a query, column definitions,
 * and optionally some filter specs, and it'll take care of querying the
 * backend in response to pagination / sorting / filtering.
 *
 * **Storybook note**: Mocking out the GraphQL responses for this table is tedious and
 * complicated, so not all features will work in this story:
 *   * Filtering: only the `Availibility` filter (set to `Yes`) and clearing that filter
 *   * Sorting: only `Contacted At`
 *   * Pagination: works, but when sorted/filtered only the first page is defined
 *   * Page Size: works for page sizes 20 and 50 only
 *
 * ### GraphQL / Rails notes
 * Take a look at the `BasePaginatedQuery` Rails superclass for information on how to return
 * a paginated result set from GraphQL. **Note** You _must_ use `BasePaginatedQuery`, as a
 * `BaseQuery` GraphQL query won't be interpreted properly with this table.
 *
 * ### Cypress
 *
 * See the stories for `HeartTable` (the base table component) for Cypress selectors
 * to use on tables.
 */
export const GraphQLDataTableWrapper = ({
  title,
  query,
  queryTitle,
  pageSize,
  actions,
  filters,
  columns,
  onUpload,
  disableUploadForRow,
  disableUploadColumnIdCellIndexes,
  onQueryCompleted,
  onFilterChange,
  defaultSort,
  defaultFilters,
  filtersToPreserveOnClear,
  rowSelectProperty,
}) => {
  const useTableArgs = useGraphQLTable({
    query,
    queryTitle,
    columns,
    pageSize,
    onQueryCompleted,
    onFilterChange,
    defaultSort,
    disableUploadForRow,
    disableUploadColumnIdCellIndexes,
    onUpload,
    defaultFilters,
    filtersToPreserveOnClear,
    ...useFilter({ filters }),
  });
  const { applyFilters, appliedFilterValueFor, clearFilters } = useTableArgs;

  return (
    <Fragment>
      <Flex justify="end" align="start">
        <Filter
          filters={filters}
          applyFilters={applyFilters}
          appliedFilterValueFor={appliedFilterValueFor}
          clearFilters={clearFilters}
        />
        {actions}
      </Flex>
      <GraphQLDataTable
        title={title}
        columns={columns}
        rowSelectProperty={rowSelectProperty}
        {...useTableArgs}
      />
    </Fragment>
  );
};
GraphQLDataTableWrapper.propTypes = {
  /** A caption for the table. Required when the DetailsTable is not
   * on top of a `Surface` (or any `SurfaceBase` like `SurfaceForm` / `Notice` etc)
   * or when the `Surface`'s title doesn't adequately convey to non-sighted users
   * what the data in this table refers to.
   * When inferred from the `Surface` title, it's hidden. */
  title: PropTypes.string,
  /** Actions to render above the top right corner of the table */
  actions: PropTypes.node,
  /** A list of the columns for the table, indicating name, display logic,
   * filterability, sortability, and whether the dropzone for the column
   * should be icon only (when used in conjunction with onUpload)
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cell: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.func, PropTypes.string])
        ),
      ]).isRequired,
      sortBy: PropTypes.string,
      id: PropTypes.string.isRequired,
      columnName: PropTypes.shape({
        name: PropTypes.string.isRequired,
        justify: PropTypes.oneOf(["start", "center", "end"]),
      }).isRequired,
      iconOnlyUpload: PropTypes.bool,
    })
  ).isRequired,
  /** For date based filters, the query variable `${field}Gteq` and `${field}Lteq`
   * will be used */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      customDatesLabels: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
      }),
      type: PropTypes.oneOf([
        "autocomplete_select",
        "custom_dates",
        "date",
        "dropdown",
        "search",
        "select",
      ]).isRequired,
      field: PropTypes.string.isRequired,
      /** For type `autocomplete_select`, a gql query, which should take
       * in an `inputQuery` argument to determine options for the dropdown
       */
      query: PropTypes.object,
      /** For type `autocomplete_select`, a function that will be called with
       * the query results, which should return the options in an array of
       * `{label, value}` objects
       */
      valuesFromResponse: PropTypes.func,
      /** used for select and autocomplete_select filters to allow
       * multiple selections */
      isMulti: PropTypes.bool,
      /** the query will not be executed unless all filters with
       * required === true are set */
      required: PropTypes.bool,
      /** function to evaluate if the variable is considered set. if
       * unspecified, any non-undefined value is considered set.
       */
      isSet: PropTypes.func,
      /** if true, shows a link to "select all" options for a "select"
       * component where isMulti is true.
       */
      showSelectAll: PropTypes.bool,
    })
  ),
  /** Function called with row data to determine whether upload
   * should be disabled for a given row
   */
  disableUploadForRow: PropTypes.func,
  /** column id and cell indexes that should not have an upload dropzone
   *
   * This should be used for Actions columns containing Menus, where
   * the Upload dropzone breaks the behavior of our Menu
   */
  disableUploadColumnIdCellIndexes: PropTypes.shape({
    columnId: PropTypes.string,
    indexes: PropTypes.arrayOf(PropTypes.number),
  }),
  /** Function called with row data which should return a function
   * that can take in files dropped over each row
   */
  onUpload: PropTypes.func,
  /** Query string */
  query: PropTypes.object.isRequired,
  /** String indicating what the `node`s and `pageInfo` are nested under
   * in the query
   */
  queryTitle: PropTypes.string.isRequired,
  /** How many rows we should display to the user per page of results. */
  pageSize: PropTypes.number,
  /** Function called with the result of the query whenever it completes
   * successfully. Receives the data of the query result.
   */
  onQueryCompleted: PropTypes.func,
  /** Function called when a filter values change. Also called initially
   * with filter values from the URL if applicable. It recieves the current
   * filter state as an argument. Note that the filters do not include
   * pagination state.
   */
  onFilterChange: PropTypes.func,
  /** Default sort to use if no query params are specified. */
  defaultSort: PropTypes.shape({
    sortBy: PropTypes.string.isRequired,
    sortDirection: PropTypes.string.isRequired,
  }),
  /** Default filter to use for query. */
  defaultFilters: PropTypes.object,
  /** Filters that should be preserved even if Clear Filters is clicked */
  filtersToPreserveOnClear: PropTypes.arrayOf(PropTypes.string),
  /** When provided, allows users to select rows of the table via checkboxes,
   * and allows bulk select via a checkbox in the table header. `rowSelectProperty`
   * should be set to the property within each data set that describes the row,
   * e.g. the person's name or form's name that the row is about, so that we
   * can provide an a11y friendly label for the checkbox in each row.
   *
   * rowSelectProperty works similarly to the `cell` property in the columns array,
   * in that it can take in either a string to use as a selector within the row data
   * or a function to call on the row data, which **MUST** return a string
   *
   * When using `rowSelectedProperty` the data object **MUST** contain an `id`
   * in order for the functionality to work, as those ids will be used to determine
   * which rows are selected via query params
   */
  rowSelectProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export const GraphQLDataTable = ({
  title,
  columns,
  tableIsSortable,
  loading,
  onSortToggle,
  sortDirection,
  isActiveSortColumn,
  tableData,
  getOnUploadForCell,
  isFirstPage,
  isLastPage,
  fetchPreviousPage,
  fetchNextPage,
  emptyStatePrompt,
  rowSelectProperty,
  configuredPageSize,
  setConfiguredPageSize,
}) => (
  <Fragment>
    <DataTable
      title={title}
      tableIsSortable={tableIsSortable}
      sortDirection={sortDirection}
      columns={columns}
      colHeaderProps={({ column }) => ({
        isSortable: Boolean(column.sortBy),
        isActiveSortColumn: isActiveSortColumn(column.sortBy),
        onSort: () => onSortToggle(column.sortBy),
      })}
      colCellProps={({ rowData, column, cellIndex }) => ({
        onUpload: getOnUploadForCell({ rowData, column, cellIndex }),
      })}
      loading={loading}
      data={tableData}
      emptyStatePrompt={emptyStatePrompt}
      rowSelectProperty={rowSelectProperty}
    />
    <Flex column gap="300" className={styles.navigationButtons}>
      <TablePagination
        onNext={() => {
          fetchNextPage();
          clearAllOtherBase64SearchParams({ preserveAttributes: ["tab"] });
          window.scrollTo(0, 0);
        }}
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
        onPrevious={() => {
          fetchPreviousPage();
          clearAllOtherBase64SearchParams({ preserveAttributes: ["tab"] });
          window.scrollTo(0, 0);
        }}
      />
      <Flex justify="center">
        <InputDropdown
          hideBlank
          label={t("results_per_page")}
          values={["20", "50", "100"]}
          value={configuredPageSize}
          onChange={pageSize => setConfiguredPageSize(parseInt(pageSize, 10))}
        />
      </Flex>
    </Flex>
  </Fragment>
);
/** See wrapper for prop structure */
GraphQLDataTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array.isRequired,
  tableIsSortable: PropTypes.bool,
  loading: PropTypes.bool,
  onSortToggle: PropTypes.func,
  sortDirection: PropTypes.string,
  isActiveSortColumn: PropTypes.func,
  tableData: PropTypes.array,
  getOnUploadForCell: PropTypes.func,
  isFirstPage: PropTypes.bool,
  isLastPage: PropTypes.bool,
  fetchPreviousPage: PropTypes.func,
  fetchNextPage: PropTypes.func,
  /** Prompt to display when action is required to trigger data load */
  emptyStatePrompt: PropTypes.string,
  rowSelectProperty: PropTypes.string,
  /** page size props used to control how many results per page are displayed */
  configuredPageSize: PropTypes.number,
  setConfiguredPageSize: PropTypes.func,
};

export default GraphQLDataTableWrapper;
