import { Flex } from "@heart/components";

import AfcarsTable from "./AfcarsTable";

const ChildrenExitingFosterCare = () => (
  <Flex column>
    <h1 style={{ marginLeft: "20px" }}>Children Entering Foster Care</h1>
    <h2 style={{ marginLeft: "20px", padding: "0" }}>FY 2023 (N=12,654)</h2>
    <Flex row>
      <Flex column style={{ flex: 1 }}>
        <AfcarsTable
          stats
          key="age-stats"
          headers={["Age at Exit", "Year"]}
          data={[
            ["Mean", "7.3"],
            ["Median", "6.0"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="age-stats-2"
          headers={["Age at Exit", "%", "Number"]}
          data={[
            ["Less than 1 Year", "4%", "522"],
            ["1 Year", "10%", "1,197"],
            ["2 Years", "10%", "1,265"],
            ["3 Years", "9%", "1,118"],
            ["4 Years", "7%", "930"],
            ["5 Years", "7%", "840"],
            ["6 Years", "6%", "757"],
            ["7 Years", "5%", "675"],
            ["8 Years", "5%", "641"],
            ["9 Years", "4%", "554"],
            ["10 Years", "4%", "491"],
            ["11 Years", "4%", "491"],
            ["12 Years", "4%", "515"],
            ["13 Years", "3%", "431"],
            ["14 Years", "3%", "418"],
            ["15 Years", "3%", "347"],
            ["16 Years", "3%", "352"],
            ["17 Years", "3%", "359"],
            ["18 Years", "4%", "488"],
            ["19 Years", "1%", "101"],
            ["20 Years", "1%", "86"],
          ]}
        />
        <AfcarsTable
          stats
          key="time-in-care"
          headers={["Time in care", "Months"]}
          data={[
            ["Mean", "19.8"],
            ["Median", "16.1"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="time-in-care-2"
          headers={["Time in Care", "%", "Number"]}
          data={[
            ["Less than 1 Month", "2%", "293"],
            ["1 - 5 Months", "10%", "1321"],
            ["6-11 Months", "23%", "2885"],
            ["12-17 Months", "20%", "2516"],
            ["18-23 Months", "14%", "1788"],
            ["24-29 Months", "11%", "1377"],
            ["30-35 Months", "7%", "906"],
            ["3 - 4 Years", "10%", "1311"],
            ["5 Years or More", "2%", "257"],
          ]}
        />
      </Flex>
      <Flex column style={{ flex: 1 }}>
        <AfcarsTable
          stats
          normalizeNumbers
          key="race-eth"
          headers={["Race/Ethnicity", "%", "Number"]}
          data={[
            ["American Indian/Alaska Native", "0%", "35"],
            ["Asian", "0%", "18"],
            ["Black or African American", "28%", "3,532"],
            ["Native Hawaiian/Other Pacific Islander", "0%", "4"],
            ["Hispanic (of any race)", "18%", "2,246"],
            ["White", "46%", "5,790"],
            ["Unknown/Unable to Determine", "1%", "104"],
            ["Two or More Races", "7%", "924"],
          ]}
          note="All races exclude children of Hispanic origin. Children of Hispanic ethnicity may be any race."
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="Reason for discharge"
          headers={["Reason for Discharge", "%", "Number"]}
          data={[
            [
              "Reunification with Parent(s) or Primary Caretaker(s)",
              "45%",
              "5,624",
            ],
            ["Living with Other Relative(s)", "0%", "7"],
            ["Adoption", "31%", "3,873"],
            ["Emancipation", "6%", "750"],
            ["Guardianship", "18%", "2,245"],
            ["Transfer to Another Agency", "0%", "7"],
            ["Runaway", "0%", "0"],
            ["Death of Child", "0%", "25"],
          ]}
        />
      </Flex>
    </Flex>
  </Flex>
);

export default ChildrenExitingFosterCare;
