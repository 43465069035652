const EMAIL_PLACEHOLDER_FOR_CAREGIVERS_NOT_IN_BINTI =
  "placeholder-for-caregivers-not-in-binti@binti.com";

const isPlaceholderEmail = ({ email }) => {
  const regex = new RegExp("^user_email_placeholder+.*@binti.com");
  return (
    regex.test(email) || email === EMAIL_PLACEHOLDER_FOR_CAREGIVERS_NOT_IN_BINTI
  );
};

export default isPlaceholderEmail;
