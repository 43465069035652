import { useQuery, useReactiveVar } from "@apollo/client";
import { FlexItem, Icons, Text } from "@heart/components";
import React, { Fragment } from "react";

import * as ResourceActionQuery from "@graphql/queries/ResourceAction.graphql";

import PermissionsPropTypes from "./PermissionsPropTypes";
import { permissionsDevModeVar } from "./permissionsReactiveVars";

const ResourceActionDescription = ({ queryVariables }) => {
  const { loading, error, data } = useQuery(ResourceActionQuery, {
    variables: queryVariables,
  });
  const devMode = useReactiveVar(permissionsDevModeVar);

  if (loading || error) {
    return null;
  }

  const {
    resourceAction: { description, action, agencyRelationshipSupport },
  } = data;

  return (
    <FlexItem expand="md" as="p">
      {description}
      {devMode ? ` (${action}) ` : " "}
      <Fragment>
        {agencyRelationshipSupport === "supported" && (
          <Text textColor="emphasis-500">
            <Icons.Check description="supported" />
          </Text>
        )}
        {agencyRelationshipSupport === "unsupported" && (
          <Text textColor="danger-500">
            <Icons.Ban description="unsupported" />
          </Text>
        )}
        {agencyRelationshipSupport === "unknown" && (
          <Text textColor="attention-100">
            <Icons.ExclamationTriangle description="unknown support" />
          </Text>
        )}
      </Fragment>
    </FlexItem>
  );
};

ResourceActionDescription.propTypes = {
  queryVariables: PermissionsPropTypes.queryVariables,
};

export default ResourceActionDescription;
