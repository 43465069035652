import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./NewPasswordField.module.scss";
import {
  produceRules,
  computePasswordScore,
  allSuggestionKeys,
  getSuggestionKeys,
} from "./passwordScore";

const STRENGTHS = {
  WEAK: "WEAK",
  AVERAGE: "AVERAGE",
  STRONG: "STRONG",
  CANT_BE_BETTER: "CANT_BE_BETTER",
};

const passwordStrength = score => {
  if (score < 50) return STRENGTHS.WEAK;
  if (score < 80) return STRENGTHS.AVERAGE;
  if (score < 100) return STRENGTHS.STRONG;
  return STRENGTHS.CANT_BE_BETTER;
};

const Meter = ({ value }) => (
  <div
    className={classnames(styles.meter, styles[passwordStrength(value)])}
    style={{ width: `${value || 5}%` }}
  />
);

Meter.propTypes = {
  value: PropTypes.number.isRequired,
};

const Suggestions = ({ rules, password }) => {
  const allSuggestions = allSuggestionKeys(rules);
  const suggestions = getSuggestionKeys(rules)(password);
  if (!suggestions.length) return null;
  return (
    <ul className="fa-ul">
      {allSuggestions.map((suggestion, idx) => (
        <Suggestion
          key={idx}
          suggestionKey={suggestion}
          passFail={!suggestions.includes(suggestion)}
        />
      ))}
    </ul>
  );
};

Suggestions.propTypes = {
  rules: PropTypes.array.isRequired,
  password: PropTypes.string.isRequired,
};

const Suggestion = ({ suggestionKey, passFail }) => {
  const iconClass = classnames(styles.suggestion, "fas", {
    [`fa-check ${styles.pass}`]: passFail,
    [`fa-times ${styles.fail}`]: !passFail,
  });
  return (
    <li className="inline-hints">
      <span className="fa-li">
        <i className={iconClass} />
      </span>
      <span>
        {I18n.t(
          `javascript.components.inputs.new_password_field.suggestion.${suggestionKey}`
        )}
      </span>
    </li>
  );
};

Suggestion.propTypes = {
  suggestionKey: PropTypes.string.isRequired,
  passFail: PropTypes.bool.isRequired,
};

const PasswordStrength = ({ password, username }) => {
  if (password.length < 3) return null;

  const rules = produceRules({ username });
  const score = computePasswordScore(rules)(password);
  const strength = passwordStrength(score);

  return (
    <div>
      <Meter value={score} />
      <span className={`inline-hints ${styles.strength}`}>
        {I18n.t(
          `javascript.components.inputs.new_password_field.strength.${strength}`
        )}
      </span>
      <Suggestions rules={rules} password={password} />
    </div>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
  username: PropTypes.string,
};

export default PasswordStrength;
