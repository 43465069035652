import { gql } from "@apollo/client";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import PropTypes from "prop-types";

import createNameAndDOBLabel from "@components/common/createNameAndDOBLabel";

/**  Visible for testing purposes only */
export const SearchQuery = gql`
  query AgencyHumanAutocompleteQuery(
    $inputQuery: String!
    $filterForChildren: Boolean!
  ) {
    agencyHumanMatches(
      inputQuery: $inputQuery
      filterForChildren: $filterForChildren
    ) {
      id
      fullName
      dateOfBirth
    }
  }
`;

/**
 * Allows searching for adults and chilren based on params passed in.
 */
const SearchableHumanInput = ({
  onChange,
  label,
  required = false,
  filterForChildren = false,
  value,
}) => {
  const humansFromResponse = response => [
    ...(response?.agencyHumanMatches || []).map(
      ({ id, fullName, dateOfBirth }) => ({
        label: createNameAndDOBLabel({ fullName, dateOfBirth }),
        value: id,
      })
    ),
  ];

  return (
    <InputAutocompleteGraphQL
      required={required}
      label={label}
      query={SearchQuery}
      queryVariables={{ filterForChildren }}
      valuesFromResponse={humansFromResponse}
      onChange={onChange}
      value={value}
    />
  );
};

SearchableHumanInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  filterForChildren: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  ]),
  required: PropTypes.bool,
};

export default SearchableHumanInput;
