import PropTypes from "prop-types";
import { Component } from "react";

import preventDefault from "@lib/preventDefault";

import Spinner from "../Spinner";

const SUCCESS = () => {
  window.location.reload();
};
const COMPLETE = () => undefined;
const ERROR = () => undefined;

class AjaxLink extends Component {
  constructor() {
    super();
    this.state = { inProgress: false };
  }

  complete = (...args) => {
    const { complete = COMPLETE } = this.props;

    this.setState({ inProgress: false });

    return complete(...args);
  };

  onClick = preventDefault(() => {
    const {
      href,
      method,
      success = SUCCESS,
      error = ERROR,
      confirmMessage,
    } = this.props;

    // eslint-disable-next-line no-alert
    if (!confirmMessage || window.confirm(confirmMessage)) {
      this.setState({ inProgress: true });

      $.ajax({ method, url: href, success, complete: this.complete, error });
    }
  });

  render() {
    const { inProgress } = this.state;
    const { children } = this.props;

    return (
      <span>
        <a href="#" onClick={this.onClick} disabled={inProgress}>
          {children}
        </a>
        {inProgress && <Spinner />}
      </span>
    );
  }
}

AjaxLink.propTypes = {
  href: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  success: PropTypes.func,
  complete: PropTypes.func,
  error: PropTypes.func,
  confirmMessage: PropTypes.string,
};

export default AjaxLink;
