import { InputText } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";

import parameterize from "@lib/parameterize";

const { t } = translationWithRoot("background_check_types");

const BackgroundCheckTypeForm = ({ formState, setFormAttribute }) => (
  <div className="contains-inputs">
    <div className="form-group">
      <InputText
        id="name"
        label={t("columns.name")}
        name="name"
        required
        rows={1}
        type="text"
        value={formState.name}
        onChange={name => {
          const parameterizedSlug = parameterize(name);
          setFormAttribute("name")(name);
          setFormAttribute("slug")(parameterizedSlug);
        }}
      />
      <InputText
        id="slug"
        label={t("columns.slug")}
        name="slug"
        required
        rows={1}
        type="text"
        value={formState.slug}
        onChange={setFormAttribute("slug")}
      />
      <InputText
        id="description"
        label={t("columns.description")}
        name="description"
        required={false}
        rows={1}
        type="text"
        value={formState.description}
        onChange={setFormAttribute("description")}
      />
    </div>
  </div>
);

BackgroundCheckTypeForm.propTypes = {
  formState: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
  }),
  setFormAttribute: PropTypes.func,
};

export default BackgroundCheckTypeForm;
