import { gql, useQuery } from "@apollo/client";
import { InputDropdown } from "@heart/components";
import { Spinner } from "@heart/components/icon/Icon";
import I18n from "i18n-js";
import PropTypes from "prop-types";

const BackgroundCheckStepsDropdown = ({ value, onChange }) => {
  const { data, loading } = useQuery(gql`
    query BackgroundCheckSteps {
      backgroundCheckSteps {
        label
        value
      }
    }
  `);

  if (loading) {
    return <Spinner />;
  }

  return (
    <InputDropdown
      required
      value={value}
      values={data.backgroundCheckSteps}
      label={I18n.t("admin.background.step_type")}
      description={I18n.t("admin.background.step_type_affects")}
      onChange={onChange}
    />
  );
};

BackgroundCheckStepsDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default BackgroundCheckStepsDropdown;
