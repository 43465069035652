import { Button } from "@heart/components";
import PropTypes from "prop-types";
import { Component } from "react";

import preventDefault from "@lib/preventDefault";

import { DocumentsClientContext } from "../../clients/DocumentsClient";

class DocumentToggleButton extends Component {
  submit = client =>
    preventDefault(() => {
      const { doc, mark, unmark, isMarked } = this.props;

      if (isMarked(doc)) {
        client[unmark](doc);
      } else {
        client[mark](doc);
      }
    });

  render() {
    const { isMarkable, label, backgroundLoading } = this.props;

    if (!isMarkable) return false;

    return (
      <DocumentsClientContext.Consumer>
        {client => (
          <Button
            variant="secondary"
            onClick={this.submit(client)}
            disabled={backgroundLoading}
          >
            {label}
          </Button>
        )}
      </DocumentsClientContext.Consumer>
    );
  }
}

DocumentToggleButton.propTypes = {
  doc: PropTypes.object.isRequired,
  isMarkable: PropTypes.bool.isRequired,
  isMarked: PropTypes.func.isRequired,
  mark: PropTypes.string.isRequired,
  unmark: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  backgroundLoading: PropTypes.bool,
};

export default DocumentToggleButton;
