import {
  InputCheckbox,
  InputDropdown,
  SurfaceFormGroup,
} from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

const { T } = translationWithRoot("permissions.assignment_to_record_condition");

/** Configure an Assignment To Record permission condition.
 *  Permissions > Show/Edit a role > Edit a permission > Add Condition >
 *    Set Condition Type to "Assignment To Record"
 */
const AssignmentToRecordConditionConfig = ({ onChange }) => {
  const [rootResourceEnabled, setRootResourceEnabled] = useState(false);
  const [rootResource, setRootResource] = useState();
  const onRootResourceChange = value => {
    onChange({ rootResource: value });
    setRootResource(value);
  };
  return (
    <SurfaceFormGroup title="Condition Configuration">
      <InputCheckbox
        value={rootResourceEnabled}
        onChange={setRootResourceEnabled}
        label={<T t="enable_root_resource" />}
      />
      <If condition={rootResourceEnabled}>
        <InputDropdown
          required
          label={<T t="root_resource" />}
          value={rootResource}
          values={[
            ["Application", "Application"],
            ["Child", "Child"],
            ["Intake Referral", "IntakeReferral"],
            ["Investigations", "Investigations"],
          ]}
          onChange={onRootResourceChange}
        />
      </If>
    </SurfaceFormGroup>
  );
};

AssignmentToRecordConditionConfig.propTypes = {
  onChange: PropTypes.func,
};

export default AssignmentToRecordConditionConfig;
