import { Button } from "@heart/components";
import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import generateId from "@lib/generateId";
import { OPTION_KEY_KEY } from "@lib/overridableHelpers";

import { reorder, remove } from "../../dndHelpers";
import OverridableDetailsOptionSpecInput from "./OverridableDetailsOptionSpecInput";
import styles from "./OverridableInput.module.scss";

const OverridableDetailsOptionsSpecInput = ({
  options = [],
  updateOptions,
}) => {
  const addOption = () => {
    updateOptions(
      options.concat({ [OPTION_KEY_KEY]: `change_me${generateId()}` })
    );
  };

  const updateOption = originalKey => optionUpdate => {
    const newOptions = options.map(option => {
      if (option[OPTION_KEY_KEY] === originalKey) {
        return optionUpdate;
      }

      return option;
    });

    updateOptions(newOptions);
  };

  const removeOption = index => () => {
    updateOptions(remove(options, index));
  };

  const onDragEnd = ({ source, destination }) => {
    updateOptions(reorder(options, source.index, destination.index));
  };

  const canAdd = !options.find(option =>
    option[OPTION_KEY_KEY].match(/change_me/)
  );

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="details">
          {provided => (
            <div ref={provided.innerRef}>
              {options.map((option, index) => (
                <OverridableDetailsOptionSpecInput
                  index={index}
                  key={`option-${index}`}
                  option={option}
                  updateOption={updateOption(option[OPTION_KEY_KEY])}
                  removeOption={removeOption(index)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <div className={styles.addOptionContainer}>
          <Button onClick={addOption} disabled={!canAdd}>
            Add Option
          </Button>
        </div>
      </DragDropContext>
    </div>
  );
};

OverridableDetailsOptionsSpecInput.propTypes = {
  options: PropTypes.array,
  updateOptions: PropTypes.func.isRequired,
};

export default OverridableDetailsOptionsSpecInput;
