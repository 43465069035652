export default (
  status,
  outOfCountySending,
  {
    statusesAllowingDate,
    statusesRequiringReason,
    statusesRequiringExplanation,
    statusesRequiringDateAppSigned,
    statusesAllowingNotSigned,
    statusesAllowingAppeal,
  }
) => ({
  allowDate: statusesAllowingDate.includes(status),
  requireStatusReason: statusesRequiringReason.includes(status),
  requireExplanation: statusesRequiringExplanation.includes(status),
  requireDateApplicationSigned:
    statusesRequiringDateAppSigned.includes(status) && !outOfCountySending,
  allowNotSigned: statusesAllowingNotSigned.includes(status),
  allowAppeal: statusesAllowingAppeal.includes(status),
});
