import { Modal, Flex, Text, Icons } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";

import useDeleteRole from "../useDeleteRole";

const { T } = translationWithRoot("permissions.bulk_reassign_modal");

const StepTwo = ({ fromRole, agencyId, onClose }) => {
  const [deleteRole, { loading: deleteInProgress }] = useDeleteRole(agencyId, {
    onCompleted: onClose,
  });

  return (
    <Modal
      hidden={false}
      title={
        <Flex justify="space-between" gap="300">
          <div>
            <T t="title" /> {fromRole.name}
          </div>
          <Text color="neutral-200">step 2 of 2</Text>
        </Flex>
      }
      cancelText={<T t="step_two.cancel" />}
      submitText={
        deleteInProgress ? <Icons.Spinner /> : <T t="step_two.submit" />
      }
      onSubmit={() => deleteRole({ variables: { roleId: fromRole.id } })}
      onCancel={onClose}
      data-testid="bulk-reassign-modal-step-2"
    >
      <p>
        <T t={"step_two.body.part_1"} />
        <br />
        <T t={"step_two.body.part_2"} />
      </p>
    </Modal>
  );
};

StepTwo.propTypes = {
  fromRole: PropTypes.object,
  agencyId: PropTypes.number,
  onClose: PropTypes.func,
};

export default StepTwo;
