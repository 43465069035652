import {
  InputText,
  InputTextarea,
  MultiInputTemplate,
  InputDate,
} from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "background_check_exemptions.record_request_inputs"
);

const ExemptionRecordRequestLog = ({
  setFormAttribute,
  exemptionRecordRequests,
}) => (
  <Fragment>
    <MultiInputTemplate
      title={t("record_request")}
      addSectionText={t("add_another")}
      removeSectionText="Remove"
      onChange={setFormAttribute("exemptionRecordRequests")}
      onDelete={setFormAttribute("exemptionRecordRequestIdsToDelete")}
      startingValues={exemptionRecordRequests || []}
      defaultValues={{ requestNotes: "" }}
      sectionInputs={({ getMultiInputProps }) => (
        <Fragment>
          <InputText
            {...getMultiInputProps({ id: "source" })}
            type="text"
            label={t("record_source")}
            description={t("record_source_explanation")}
          />
          <InputDate
            {...getMultiInputProps({ id: "requestDate" })}
            label={t("initial_date")}
            description={t("additional_dates_explanation")}
          />
          <InputDate
            {...getMultiInputProps({ id: "receivedDate" })}
            label={t("received_date")}
          />
          <InputTextarea
            {...getMultiInputProps({ id: "requestNotes" })}
            type="text"
            label={t("notes")}
          />
        </Fragment>
      )}
    />
  </Fragment>
);

ExemptionRecordRequestLog.propTypes = {
  setFormAttribute: PropTypes.func.isRequired,
  exemptionRecordRequests: PropTypes.array,
};

export default ExemptionRecordRequestLog;
