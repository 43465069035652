import { useMountEffect } from "@react-hookz/web";
import { isNull } from "lodash";
import { useEffect, useState } from "react";

import useLocation from "@lib/react-use/useLocation";
import { getSearchParamForAttribute, setSearchParams } from "@lib/searchParams";

const useCurrentPageSearchParam = ({ defaultPage }) => {
  const attribute = "currentPage";
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState();

  useMountEffect(() => {
    if (isNull(getSearchParamForAttribute({ attribute }))) {
      setSearchParams([{ attribute, value: defaultPage }]);
    }
  });

  useEffect(() => {
    const currentPageParam = getSearchParamForAttribute({ attribute });
    setCurrentPage(currentPageParam);
  }, [location.search]);

  const navigateToPage = ({ page }) => {
    history.pushState({}, document.title, window.location.href);
    setSearchParams([{ attribute, value: page }]);
  };

  return {
    currentPage,
    navigateToPage,
  };
};

export default useCurrentPageSearchParam;
