import { Flex, FlexItem, InputFilterable } from "@heart/components";

import FormSelect from "./FormSelect";

const AddForm = () => (
  <Flex row>
    <FlexItem expand="sm">
      <FormSelect name="form_slug" />
    </FlexItem>

    <FlexItem expand="sm">
      <InputFilterable
        label="When the application stage number doesn't match the template stage number"
        name="on_stage_number_mismatch"
        defaultValue={{ value: "error", label: "Treat as error, don't add" }}
        options={[
          { value: "error", label: "Treat as error, don't add" },
          {
            value: "first",
            label: "Add the form to the FIRST stage with the same role",
          },
          {
            value: "last",
            label: "Add the form to the LAST stage with the same role",
          },
        ]}
      />
    </FlexItem>
  </Flex>
);

export default AddForm;
