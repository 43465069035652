import { Flex, Modal } from "@heart/components";
import { camelCase, isEmpty } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { translationWithRoot } from "@components/T";
import CreateOrEditOverrideForm from "@components/requirements/CreateOrEditOverrideForm";

const { T } = translationWithRoot(
  "requirements.create_or_edit_override_form.modal"
);

const OverrideModal = ({
  fulfillment,
  overrideRecord,
  isModalOpen,
  onSubmit,
  onCloseModal,
}) => {
  const {
    reason: initialReason,
    optionKey: initialOptionKey,
    details: initialDetails,
    grantDate: initialGrantDate = DateTime.local().toISO(),
  } = overrideRecord || {};

  const [submitting, setSubmitting] = useState(false);
  const [reason, setReason] = useState(camelCase(initialReason));
  const [optionKey, setOptionKey] = useState(initialOptionKey);
  const [details, setDetails] = useState(initialDetails);
  const [grantDate, setGrantDate] = useState(initialGrantDate);
  const disabled = [reason, optionKey, grantDate].some(isEmpty);

  const { requirement, title } = fulfillment;

  useEffect(() => {
    const {
      reason: refreshedReason,
      optionKey: refreshedOptionKey,
      details: refreshedDetails,
      grantDate: refreshedGrantDate = DateTime.local().toISO(),
    } = overrideRecord || {};
    setReason(camelCase(refreshedReason));
    setOptionKey(refreshedOptionKey);
    setDetails(refreshedDetails);
    setGrantDate(refreshedGrantDate);
  }, [overrideRecord]);

  return (
    <Modal
      hidden={!isModalOpen}
      title={<T t="title" />}
      submitting={submitting}
      onSubmit={async () => {
        await setSubmitting(true);
        await onSubmit({ reason, details, optionKey, grantDate });
        setSubmitting(false);
      }}
      submitDisabled={disabled}
      submitText={<T t="submit_button" />}
      onCancel={onCloseModal}
    >
      <Flex column>
        <div>
          <p>
            <T t="requirement_name" /> {title}
          </p>
          <If condition={requirement.userAgencyProfile}>
            <p>
              <T t="for" /> {requirement.userAgencyProfile.fullName} (
              {requirement.providerRole})
            </p>
          </If>
        </div>
        <CreateOrEditOverrideForm
          {...{
            requirement,

            details,
            grantDate,
            reason,
            optionKey,

            setDetails,
            setGrantDate,
            setOptionKey,
            setReason,
          }}
        />
      </Flex>
    </Modal>
  );
};

OverrideModal.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  overrideRecord: PropTypes.object,
};

export default OverrideModal;
