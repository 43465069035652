import { Upload, UploadButton } from "@heart/components";
import PropTypes from "prop-types";
import { Component } from "react";

import { DocumentsClientContext } from "../../clients/DocumentsClient";

class DocumentUpload extends Component {
  onDrop = client => async files => {
    const { doc } = this.props;

    if (files.length === 0) {
      // files were rejected
      return;
    }

    if (files.some(file => file.size > Binti.MAX_FILE_SIZE)) {
      if (window.Binti.testEnvironment) {
        // Eh... this is pretty bonkers but it would appear something, either
        // Selenium, Chrome or Capybara, is funky and we won't actually be able
        // to `accept_alert` here unless we trigger this twice...
        window.alert(Binti.MAX_FILE_SIZE_ERROR_MESSAGE); // eslint-disable-line
      }
      window.alert(Binti.MAX_FILE_SIZE_ERROR_MESSAGE); // eslint-disable-line
    } else {
      await client.uploadAttachments(doc, files);
    }
  };

  onDropRejected = () => {
    /* eslint-disable no-alert */
    window.alert(I18n.t("attachments.errors.unpermitted_format"));
    /* eslint-enable no-alert */
  };

  render() {
    const { doc } = this.props;

    if (!doc.isUploadable) {
      return false;
    }

    return (
      <DocumentsClientContext.Consumer>
        {client => (
          <Upload
            multiple={doc.supportsMultipleAttachments}
            iconOnly
            onUpload={this.onDrop(client)}
          >
            <UploadButton
              multiple={doc.supportsMultipleAttachments}
              onUpload={this.onDrop(client)}
            />
          </Upload>
        )}
      </DocumentsClientContext.Consumer>
    );
  }
}

DocumentUpload.propTypes = {
  doc: PropTypes.object.isRequired,
};

export default DocumentUpload;
