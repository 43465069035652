import { useQuery } from "@apollo/client";
import {
  Flex,
  GraphQLDataTable,
  Layout,
  LabeledContent,
  Link,
  Surface,
  TableCellList,
  DisplaySSN,
} from "@heart/components";
import { isEmpty, snakeCase } from "lodash";
import { DateTime } from "luxon";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import ClearIntegrationConfigurationQuery from "@graphql/queries/ClearIntegrationConfiguration.graphql";
import CurrentUserQuery from "@graphql/queries/CurrentUser.graphql";
import PotentialKinSearchAuditTrailRecords from "@graphql/queries/PotentialKinSearchAuditTrailRecords.graphql";

import { mapConstantSetToValues } from "@lib/constantsConversion";
import { LUXON_DATETIME_WITH_ZONE } from "@lib/dates";

import { POTENTIAL_KIN_SEARCH_AUDIT_TRAIL_RECORD_SEARCH_TYPES } from "@root/constants";

const { T, t } = translationWithRoot("family_finding.potential_kin_search");

const renderSearchFields = (searchFields = {}) => (
  <TableCellList
    items={Object.keys(searchFields)
      .filter(
        fieldName =>
          // Skip the __typename field from GraphQL
          fieldName !== "__typename" &&
          // Skip empty fields
          !isEmpty(searchFields[fieldName])
      )
      .map(fieldName => {
        if (fieldName === "ssn") {
          return (
            <DisplaySSN
              key={fieldName}
              ssn={searchFields[fieldName]}
              showLabel
            />
          );
        }

        return (
          <LabeledContent
            key={fieldName}
            label={t(`search_fields.${snakeCase(fieldName)}`)}
            content={searchFields[fieldName]}
          />
        );
      })}
  />
);

const Table = () => {
  const { data: currentUser } = useQuery(CurrentUserQuery);
  const { data: configuration = {} } = useQuery(
    ClearIntegrationConfigurationQuery,
    {
      variables: { agencyId: currentUser?.user?.agencyId },
      skip: !currentUser?.user?.agencyId,
    }
  );

  const columns = [
    {
      columnName: { name: t("audit_trail.columns.created_at") },
      id: "createdAt",
      cell: ({ createdAt }) =>
        DateTime.fromISO(createdAt).toFormat(LUXON_DATETIME_WITH_ZONE),
    },
    currentUser?.user?.bintiAdmin && {
      columnName: { name: t("audit_trail.columns.agency") },
      id: "agencyName",
      cell: "agencyName",
    },
    {
      columnName: { name: t("audit_trail.columns.search_type") },
      id: "searchType",
      cell: ({ searchType, recordUrl }) =>
        recordUrl ? (
          <Link href={recordUrl}>
            {t(`audit_trail.search_type.${searchType}`)}
          </Link>
        ) : (
          t(`audit_trail.search_type.${searchType}`)
        ),
    },
    {
      columnName: { name: t("audit_trail.columns.search_fields") },
      id: "searchFields",
      cell: ({ searchFields }) => renderSearchFields(searchFields),
    },
    {
      columnName: { name: t("audit_trail.columns.search_id") },
      id: "clearPersonSearchId",
      cell: "clearPersonSearchId",
    },
    {
      columnName: {
        name: t("audit_trail.columns.worker"),
        justify: "end",
      },
      id: "worker",
      cell: "workerName",
    },
    currentUser?.user?.bintiAdmin && {
      columnName: {
        name: t("audit_trail.columns.masquerade_owner"),
        justify: "end",
      },
      id: "userMasqueradeOwner",
      cell: "userMasqueradeOwner.name",
    },
  ].filter(Boolean);

  const filters = [
    {
      label: t("audit_trail.filters.created_at"),
      type: "custom_dates",
      field: "createdAt",
    },
    {
      label: t("audit_trail.filters.criteria"),
      type: "search",
      field: "searchCriteriaContains",
    },
    {
      label: t("audit_trail.filters.worker"),
      type: "search",
      field: "workerNameContains",
    },
    {
      label: t("audit_trail.filters.search_type"),
      type: "dropdown",
      field: "searchTypeEq",
      values: mapConstantSetToValues({
        constant: POTENTIAL_KIN_SEARCH_AUDIT_TRAIL_RECORD_SEARCH_TYPES,
        translationKey: "search_type",
        translationPath:
          "javascript.components.family_finding.potential_kin_search.audit_trail",
      }),
    },
  ];

  return (
    <Fragment>
      <If
        condition={
          configuration.clearIntegrationConfiguration?.pricingModel === "window"
        }
      >
        <Surface
          title={t("audit_trail.window_pricing_model_title", {
            cap: configuration.clearIntegrationConfiguration?.transactionFeeCap,
          })}
        >
          <Flex>
            {t("audit_trail.window_pricing_model_description")}
            <Link
              href="https://signon.thomsonreuters.com/?productid=QKV"
              target="_blank"
            >
              <T t="audit_trail.window_pricing_model_quick_view" />
            </Link>
          </Flex>
        </Surface>
      </If>
      <Surface title={t("audit_trail.table_title")}>
        <GraphQLDataTable
          query={PotentialKinSearchAuditTrailRecords}
          queryTitle="potentialKinSearchAuditTrailRecords"
          filters={filters}
          columns={columns}
        />
      </Surface>
    </Fragment>
  );
};

const AuditTrail = () => (
  <Layout
    pageTitle={t("audit_trail.page_title")}
    subtitle={t("audit_trail.page_subtitle")}
    main={{ content: <Table /> }}
  />
);

export default AuditTrail;
