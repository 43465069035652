import browserDetect from "browser-detect";

$(() => {
  function pullReports() {
    const pullReportsHook = $(".js-pull-reports");
    const browser = browserDetect();
    if (browser) {
      if (browser.name === "ie") {
        $(pullReportsHook.parents(".paginated_collection")[0]).hide();
        $(pullReportsHook.parents("#main_content")[0])
          .text(
            `Due to security enhancements to further protect your data the Internet
            Explorer 11 browser(or older) is not supported for these reports! Please contact our
            amazing customer support chat to collect your report immediately, or for additional
            support reach out to support@binti.com.`
          )
          .css("padding", "20px");
      }
    }
  }
  pullReports();
});
