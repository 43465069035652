import { useMutation } from "@apollo/client";
import { Link, Modal } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import T from "@components/T";

import ResetFormInstanceSignatures from "@graphql/mutations/ResetFormInstanceSignatures.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

export const ResetSignaturesLink = ({ formInstance }) => {
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [resetFormInstanceSignatures] = useMutation(
    ResetFormInstanceSignatures
  );

  const onConfirmClick = () => {
    // note that this never get set back to enabled - this button should be
    // disappeared by the parent when this works.
    setDisabled(true);

    resetFormInstanceSignatures({
      variables: { formInstanceId: formInstance.id },
    }).then(() => {
      // this change updates state in a way that may affect requirement
      // fulfillments for either CM or applications. Ideally, it would
      // automatically fetch that appropriately updated fulfillment, but
      // this operation should be infrequently used and if it becomes
      // necessary, we can then make this data refresh more elegant.
      window.location.reload();
    });
  };

  return (
    <Fragment>
      <Modal
        onCancel={() => {
          setModalOpen(false);
        }}
        onSubmit={onConfirmClick}
        hidden={!modalOpen}
        title={I18n.t("javascript.components.common.reset_signatures")}
        data-testid="reset-signatures-modal"
      >
        <T t="common.confirm_reset" />
      </Modal>
      <Link
        onClick={() => {
          setModalOpen(true);
        }}
        disabled={disabled}
      >
        <T t="common.reset_signatures" />
      </Link>
    </Fragment>
  );
};

ResetSignaturesLink.propTypes = {
  formInstance: PropTypes.shape({ id: BintiPropTypes.ID }).isRequired,
};

export default ResetSignaturesLink;
