import { useMutation } from "@apollo/client";
import { Link } from "@heart/components";
import PropTypes from "prop-types";

import T from "@components/T";

import RegenerateFormInstancePdf from "@graphql/mutations/RegenerateFormInstancePdf.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

export const RegenerateCompletedForm = ({ formInstance }) => {
  const [regenerateFormInstancePdf] = useMutation(RegenerateFormInstancePdf);

  return (
    <Link
      onClick={() => {
        regenerateFormInstancePdf({
          variables: { formInstanceId: formInstance.id },
        });
      }}
    >
      <T t="common.regenerate_completed_form" />
    </Link>
  );
};

RegenerateCompletedForm.propTypes = {
  formInstance: PropTypes.shape({ id: BintiPropTypes.ID }).isRequired,
};

export default RegenerateCompletedForm;
