const STATUS_TO_REASON_MAP = Object.freeze({
  recruiting_dropout: "validRecruitingDropoutReasonsValues",
  denied: "validDeniedReasonsValues",
  in_adoption_process: "validAdoptionOrNotConvertedValues",
  withdrawn: "validWithdrawnReasonsValues",
  closed: "validClosedReasonsValues",
});

// Returns the valid status change reasons for a specific status so that the
// status reason dropdown can get populated
export default (application, applicationFormStatusData, applicationStatus) => {
  const reasons = [];

  applicationFormStatusData.statusChangeReasons.forEach(statusChangeReason => {
    const [reason, value] = statusChangeReason;

    if (
      applicationStatus === "closed" &&
      application.licenseType === "family_home" &&
      // values come from application_status_02_reasons.rb
      value >= 400 &&
      (value < 490 ||
        value === 1000 ||
        (application.applicationType === "CaliforniaConversionApplication" &&
          value <= 499))
    ) {
      reasons.push(reason);
    } else if (
      STATUS_TO_REASON_MAP[applicationStatus] &&
      applicationFormStatusData[
        STATUS_TO_REASON_MAP[applicationStatus]
        // This ?. is needed because the validClosedReasonsValues member of
        // applicationFormStatusData isn't always present.
      ]?.includes(value)
    ) {
      if (
        ["child_placing_agency", "congregate_care"].includes(
          application.licenseType
        ) ||
        value < 800 ||
        value === 1000
      ) {
        // Remove any org-only reasons for family homes, until ENG-17187 is done.
        reasons.push(reason);
      }
    }
  });

  return reasons;
};
