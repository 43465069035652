import PropTypes from "prop-types";

import ExpandableContent from "@components/reusable_ui/ExpandableContent";

const MAX_NON_TRUNCATED_LENGTH = 240;

const Notes = ({ contactLog, setContactLogToView }) => {
  const { notes } = contactLog;
  if (!notes) return null;

  if (notes.length <= MAX_NON_TRUNCATED_LENGTH) {
    return <pre>{notes}</pre>;
  }

  return (
    <ExpandableContent
      collapsedHeight={50}
      onExpanded={() => setContactLogToView(contactLog)}
    >
      <pre>{notes}</pre>
    </ExpandableContent>
  );
};

Notes.propTypes = {
  contactLog: PropTypes.shape({
    notes: PropTypes.string,
  }).isRequired,
  setContactLogToView: PropTypes.func.isRequired,
};

export default Notes;
