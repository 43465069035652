import PropTypes from "prop-types";
import { Fragment } from "react";

const ENV_DISPLAY_NAMES = {
  "pre-prod": "pre-production",
};
const EnvironmentName = ({ k8sNamespace }) => (
  <Fragment>
    <If condition={k8sNamespace !== "prod"}>
      {ENV_DISPLAY_NAMES[k8sNamespace] || k8sNamespace}
    </If>
  </Fragment>
);

EnvironmentName.propTypes = {
  k8sNamespace: PropTypes.string.isRequired,
};

export default EnvironmentName;
