import { compact, first } from "lodash";

$(() => {
  if (Binti.onBeforeUnloadCallbacks.length) {
    window.addEventListener("beforeunload", e => {
      const mapped = window.Binti.onBeforeUnloadCallbacks.map(callback =>
        callback()
      );
      const message = first(compact(mapped));

      if (message) {
        // Adds cross browser support
        // https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent
        //
        // Note some browsers don't display our message, but their own.
        (e || window.event || {}).returnValue = message; // Gecko + IE
        return message; // Webkit, Safari, Chrome etc.
      }

      return undefined;
    });
  }
});
