import { InputFilterableAsync } from "@heart/components";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";

import { inputCommonPropTypes } from "./common";

/**
 * Legacy AJAX autocomplete component.  Fetches `autocomplete_url` via AJAX
 * after 3 characters and lets the user choose something from that collection.
 *
 * **Please do not use in new code!**  Per our best practices [1], all new code
 * that does async data loading should be using GraphQL.
 *
 * [1]: `rubocop/cop/lint/discourage_json_response.rb`
 */
class InputFilterableLegacyAjax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: this.props.previousState,
      errorMessage: null,
    };
  }

  onSelect = selectedValue => {
    const { onChange } = this.props;

    const callback = () => {
      if (onChange) {
        onChange(selectedValue);
      }
    };

    if (selectedValue === null) {
      this.setState({ selectedValue: {} }, callback);
    } else {
      this.setState({ selectedValue }, callback);
    }
  };

  loadOptions = debounce((prefix, callback) => {
    const { params, url } = this.props;
    if (prefix.length < 3) {
      callback([]);
    } else {
      $.ajax({
        url: url,
        dataType: "json",
        contentType: "application/json",
        data: Object.assign({}, params, { term: prefix }),
        success: results => {
          // TODO this used to have `cache: false` and I don't know what that does
          callback(results.results);
        },
        failure: () => {
          this.setState({
            errorMessage:
              "There was an error in loading options.  Please try again.",
          });
        },
      });
    }
  }, 200);

  render() {
    const { url, params, onChange, error, ...inputProps } = this.props;

    return (
      <InputFilterableAsync
        {...inputProps}
        cacheOptions={true}
        loadOptions={this.loadOptions}
        onChange={this.onSelect}
        placeholder={"Please enter 3 or more characters"}
        value={this.state.selectedValue}
        filterOptions={options => options}
        error={this.state.errorMessage || error}
      />
    );
  }
}

InputFilterableLegacyAjax.propTypes = {
  ...inputCommonPropTypes,
  /**
   * The autocomplete endpoint should return results as a json object with
   * the key "results", each element of which should have keys "value" and
   * "label". The search term is specified in the JSON request key "term".
   */
  url: PropTypes.string.isRequired,
  /** Select input tag value */
  value: PropTypes.number,
  /** parameter to send with autocomplete request */
  params: PropTypes.object,
  /** Previous selected value */
  previousState: PropTypes.object,
};

export default InputFilterableLegacyAjax;
