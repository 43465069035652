import { useMutation } from "@apollo/client";
import { Flex } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import RegenerateCompletedForm from "@components/bintisign/entrypoint/RegenerateCompletedForm";
import ResetSignaturesLink from "@components/bintisign/entrypoint/ResetSignaturesLink";

import DeleteFormInstanceAttachment from "@graphql/mutations/DeleteFormInstanceAttachment.graphql";

import { sortDatesDesc } from "@lib/dates";
import { policy, typeEq } from "@lib/graphqlHelpers";

import styles from "./ApplicationRequirementTable.module.scss";
import AttachmentActions from "./AttachmentActions";
import DateApplicationStartedControl from "./DateApplicationStartedControl";
import FormInstanceWithHiddenOtherAttachments from "./FormInstanceWithHiddenOlderAttachments";
import OverrideRecordDisplay from "./OverrideRecordDisplay";

const shouldShowResetSignatures = formInstance =>
  formInstance.areSignaturesResettable &&
  policy(formInstance).mayResetSignatures();

const shouldShowRegenerateCompletedForm = formInstance =>
  formInstance.state === "allSigned" &&
  policy(formInstance).mayDownloadAttachment();

const ApplicationFormDocuments = ({
  application,
  fulfillment,
  aroundLoader,
  replaceOverrideRecord,
}) => {
  const [deleteFormInstanceAttachment] = useMutation(
    DeleteFormInstanceAttachment
  );

  const { requirement, records } = fulfillment;
  const relevantRecords = sortDatesDesc(records, "updatedAt").reverse();
  const applicationFormRecords = relevantRecords.filter(record => {
    if (!typeEq("FormInstance", record)) return false;

    const applicationForm = record;

    if (!isEmpty(applicationForm.attachments)) {
      return true;
    }

    if (shouldShowResetSignatures(applicationForm)) {
      return true;
    }

    if (shouldShowRegenerateCompletedForm(applicationForm)) {
      return true;
    }

    return false;
  });

  const overrideRecords = relevantRecords.filter(record =>
    typeEq("OverrideRecord", record)
  );

  // Delete attachment
  const deleteAttachment = formInstance =>
    aroundLoader(({ attachmentId }) =>
      deleteFormInstanceAttachment({
        variables: {
          attachmentId,
          holderToken: application.holderToken,
          formInstanceId: formInstance.id,
        },
      })
    );

  const { form } = requirement;
  const showDateApplicationStartedControl =
    form.sourceForDaysSinceSigned &&
    applicationFormRecords.some(
      applicationForm => applicationForm.completedOffline
    );

  return (
    <Fragment>
      <Flex column align="start">
        <If condition={showDateApplicationStartedControl}>
          <DateApplicationStartedControl
            {...{ application, aroundLoader, form }}
          />
        </If>
      </Flex>

      <Flex column align="start">
        <If condition={!isEmpty(applicationFormRecords)}>
          <ul className={styles.recordAttachmentList}>
            {applicationFormRecords.map(formInstance => (
              <Fragment key={formInstance.id}>
                <If condition={shouldShowResetSignatures(formInstance)}>
                  <li>
                    <ResetSignaturesLink formInstance={formInstance} />
                  </li>
                </If>
                <If condition={shouldShowRegenerateCompletedForm(formInstance)}>
                  <li>
                    <RegenerateCompletedForm formInstance={formInstance} />
                  </li>
                </If>
                <If condition={!isEmpty(formInstance.attachments)}>
                  <FormInstanceWithHiddenOtherAttachments
                    formInstance={formInstance}
                    deleteAttachment={deleteAttachment(formInstance)}
                  />
                </If>
              </Fragment>
            ))}
          </ul>
        </If>
      </Flex>

      <AttachmentActions
        fulfillment={fulfillment}
        requirement={requirement}
        application={application}
      />

      <Flex column align="start">
        <If condition={!isEmpty(overrideRecords)}>
          <ul className={styles.recordAttachmentList}>
            {sortDatesDesc(overrideRecords, "deletedAt").map(overrideRecord => (
              <li
                key={`override-record:${overrideRecord.id}`}
                className={styles.recordRow}
              >
                <OverrideRecordDisplay
                  {...{
                    holderToken: application.holderToken,
                    fulfillment,
                    replaceOverrideRecord,
                    overrideRecord,
                  }}
                />
              </li>
            ))}
          </ul>
        </If>
      </Flex>
    </Fragment>
  );
};

ApplicationFormDocuments.propTypes = {
  application: PropTypes.object.isRequired,
  fulfillment: PropTypes.object.isRequired,
  aroundLoader: PropTypes.func.isRequired,
  replaceOverrideRecord: PropTypes.func.isRequired,
};

export default ApplicationFormDocuments;
