$(() => {
  function toggleVisibilityOfProtectedTribe() {
    const protectedTribeNames = $("#child_protected_tribe_names_input");
    const cibNumber = $("#child_cib_number_input");
    if ($(".toggle-protected-tribe-names:checked").val() === "true") {
      protectedTribeNames.show();
      cibNumber.show();
    } else {
      protectedTribeNames.hide();
      cibNumber.hide();
    }
  }
  $(document).ready(toggleVisibilityOfProtectedTribe);
  $(document).on(
    "click",
    ".toggle-protected-tribe-names",
    toggleVisibilityOfProtectedTribe
  );
});
