/**
 * Returns a function that filters validation errors for a specific field.
 * @param {Array} validationErrors - The array of validation error objects.
 * @returns {Function} - A function that takes a key and returns the error messages for that key.
 */
const getErrorsForField = validationErrors => key =>
  validationErrors
    .map(err => err.message)
    .filter(msg => msg.includes(`/${key}`))
    .join("\n");

export default getErrorsForField;
