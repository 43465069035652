import { useQuery } from "@apollo/client";
import {
  Breadcrumbs,
  Layout,
  Surface,
  DetailsTable,
  HeartTable,
  LabeledContent,
  TableCellList,
  Icons,
} from "@heart/components";
import { Fragment } from "react";
import { adminChildrenPath, agencyServicesPath } from "routes";

import Spinner from "@components/Spinner";
import { translationWithRoot } from "@components/T";

import AgencyServiceQuery from "@graphql/queries/prevention/AgencyService.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const { Table, Caption, Td, Th, Thead, Tr, Tbody } = HeartTable;

const { t, T } = translationWithRoot("prevention");

const AgencyServiceDetails = ({ agencyServiceId }) => {
  const { data: agencyServiceData, loading: agencyServiceLoading } = useQuery(
    AgencyServiceQuery,
    {
      variables: { agencyServiceId },
    }
  );

  if (agencyServiceLoading) {
    return <Spinner />;
  }

  const agencyService = agencyServiceData?.preventionAgencyService || {};

  const serviceTypes = agencyService.serviceTypes.join(", ");
  const areasOfCoverage = agencyService.areasOfCoverage.join(", ");
  const fundingSources = agencyService.fundingSources.join(", ");

  const serviceInfo = (
    <Surface title={agencyService.name}>
      <DetailsTable
        details={[
          {
            label: t("agency_services.provider"),
            value: agencyService.providerAgency.name,
          },
          {
            label: t("agency_services.ffpsa_program"),
            value: agencyService.isFfpsa ? "Yes" : "No",
          },
          {
            label: t("agency_services.corresponding_ebp"),
            value:
              agencyService.evidenceBasedPractice?.displayTitle || t("none"),
          },
          {
            label: t("agency_services.service_types"),
            value: serviceTypes.length === 0 ? t("none") : serviceTypes,
          },
          {
            label: t("agency_services.areas_of_coverage"),
            value: areasOfCoverage.length === 0 ? t("none") : areasOfCoverage,
          },
          {
            label: t("agency_services.funding_sources"),
            value: fundingSources.length === 0 ? t("none") : fundingSources,
          },
        ]}
      />
    </Surface>
  );

  const serviceRateTable = (
    <Table>
      <Caption>{t("agency_service_rates.title_plural")}</Caption>
      <Thead>
        <Tr>
          <Th>{t("agency_services.dashboard.columns.service_rate_name")}</Th>
          <Th>{t("agency_services.information")}</Th>
          <Th>{t("agency_services.dashboard.columns.actions")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {agencyService.agencyServiceRates.map(agencyServiceRate => (
          <Tr key={agencyServiceRate.id}>
            <Td>
              <TableCellList
                items={[agencyServiceRate.label, agencyServiceRate.code]}
              ></TableCellList>
            </Td>
            <Td>
              <TableCellList
                items={[
                  <LabeledContent
                    key="start_date"
                    label={t("agency_services.start_date")}
                    content={`${agencyServiceRate.startDate}`}
                  />,
                  <LabeledContent
                    key="end_date"
                    label={t("agency_services.end_date")}
                    content={`${agencyServiceRate.endDate}`}
                  />,
                  <LabeledContent
                    key="billing_structure"
                    label={t("agency_service_rates.how_billed")}
                    content={t(
                      `agency_service_rates.billing_structures.${agencyServiceRate.billingStructure}`
                    )}
                  />,
                  <If
                    condition={agencyServiceRate.sessionsPerService}
                    key="sessions_per_service"
                  >
                    <LabeledContent
                      label={t("agency_service_rates.sessions_per_service")}
                      content={agencyServiceRate.sessionsPerService}
                    />
                  </If>,
                  <LabeledContent
                    key="amount"
                    label={t("agency_service_rates.amount")}
                    content={agencyServiceRate.amount}
                  />,
                ]}
              />
            </Td>
            <Td>
              <Icons.Pencil description="Edit" onClick={() => {}} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Layout
      pageTitle={<T t="agency_services.details"></T>}
      breadcrumbs={
        <Breadcrumbs
          pages={[
            {
              label: <T t="breadcrumbs.children_and_youth"></T>,
              href: adminChildrenPath(),
            },
            {
              label: <T t="agency_services.dashboard.title"></T>,
              href: agencyServicesPath(),
            },
            {
              label: <T t="agency_services.details"></T>,
              href: "#",
            },
          ]}
        />
      }
      main={{
        content: (
          <Fragment>
            {serviceInfo}
            {serviceRateTable}
          </Fragment>
        ),
      }}
    />
  );
};

AgencyServiceDetails.propTypes = {
  agencyServiceId: BintiPropTypes.ID,
};

export default AgencyServiceDetails;
