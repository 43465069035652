import { Breadcrumbs, Layout } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment } from "react";

import BintiPropTypes from "@lib/BintiPropTypes";

import AppealDetails from "./AppealDetails/AppealDetails";
import FinalAppealOutcome from "./FinalAppealOutcome/FinalAppealOutcome";
import HearingDetails from "./HearingDetails/HearingDetails";

const Appeal = ({
  appealId,
  applicationId,
  representatives,
  appealLocations,
  applicationType,
  appealOutcomeOptions,
  allowDelete,
}) => (
  <Layout
    breadcrumbs={
      <Breadcrumbs
        pages={[
          {
            label: I18n.t("javascript.components.appeals.application_status"),
            href: `/admin/applications/${applicationId}/change_status`,
          },
          {
            label: I18n.t(
              "javascript.components.appeals.license_decision_appeals"
            ),
            href: `/applications/${applicationId}/appeals/${appealId}`,
          },
        ]}
      />
    }
    main={{
      content: (
        <Fragment>
          <AppealDetails
            appealId={appealId}
            applicationId={applicationId}
            representatives={representatives}
            appealLocations={appealLocations}
            applicationType={applicationType}
            showDeleteIcon={allowDelete}
          />
          <HearingDetails appealId={appealId} />
          <FinalAppealOutcome
            appealId={appealId}
            appealOutcomeOptions={appealOutcomeOptions}
          />
        </Fragment>
      ),
      narrow: true,
    }}
  />
);

Appeal.propTypes = {
  appealId: BintiPropTypes.ID,
  applicationId: BintiPropTypes.ID,
  appealOutcomeOptions: PropTypes.array,
  representatives: PropTypes.array,
  appealLocations: PropTypes.array,
  applicationType: PropTypes.string,
  allowDelete: PropTypes.bool,
};

export default Appeal;
