import { gql, useMutation } from "@apollo/client";
import {
  Button,
  Flex,
  FlexItem,
  InputDate,
  InputText,
  InputFilterable,
} from "@heart/components";
import classnames from "classnames";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState, Fragment } from "react";

import InputPanel from "@components/reusable_ui/forms/InputPanel";
import BannerContainer from "@components/shared/banner/BannerContainer";

import { LUXON_DATEPICKER_FORMAT } from "@lib/dates";
import preventDefault from "@lib/preventDefault";

import { STAFF_SUBROLES } from "@root/constants";

import determineUserAgencyProfile from "../determineUserAgencyProfile";
import addOrEditStaffMember from "./addOrEditStaffMember";
import { StaffMemberFragment } from "./staffMemberUtils";

// import { getPlacementProviderChangeRefetchQueries } from "../other_adults_form/placementProviderChangeUtils";

/* This component is used as both an add and edit form, as determined
 *   by its parent element StaffMemberForm. If an existingStaffMember is provided,
 *   the values from that staff member will be used to populate the form values,
 *   otherwise all fields will start blank except the subrole.
 */
const StaffMemberEditor = ({
  // Model data
  agencyId,
  placementProviderId,
  existingStaffMember,
  // React state
  messageDisplay,
  setAddingNewStaffMember,
  setEditingForStaffMember,
  localErrorMessages,
  setlocalErrorMessages,
}) => {
  const CreateStaffMember = gql`
    ${StaffMemberFragment}
    mutation CreateStaffMember(
      $placementProviderId: ID!
      $agencyId: ID
      $userAgencyProfileId: ID
      $memberInput: PlacementProviderMemberInput!
    ) {
      createHumanPlacementProviderRole(
        input: {
          placementProviderId: $placementProviderId
          userAgencyProfileId: $userAgencyProfileId
          agencyId: $agencyId
          memberInput: $memberInput
        }
      ) {
        placementProvider {
          id
          staffMembers {
            ...StaffMember
          }
        }
        errors {
          attribute
          message
          type
        }
      }
    }
  `;

  const UpdateStaffMember = gql`
    ${StaffMemberFragment}
    mutation UpdateStaffMember(
      $placementProviderId: ID!
      $userAgencyProfileId: ID!
      $memberInput: PlacementProviderMemberInput!
    ) {
      updateHumanPlacementProviderRole(
        input: {
          placementProviderId: $placementProviderId
          userAgencyProfileId: $userAgencyProfileId
          memberInput: $memberInput
        }
      ) {
        placementProvider {
          id
          staffMembers {
            ...StaffMember
          }
        }
        errors {
          attribute
          message
          type
        }
      }
    }
  `;

  const [createPerson] = useMutation(CreateStaffMember);
  const [updatePerson] = useMutation(UpdateStaffMember);

  const userAgencyProfile = determineUserAgencyProfile({
    agencyId,
    person: existingStaffMember || {},
  });

  const constructStaffMemberObject = () => {
    const {
      id = null,
      firstName,
      middleName,
      lastName,
      suffix,
      subrole,
      joinedAt,
      firstShiftDate,
    } = existingStaffMember || {};
    const {
      id: userAgencyProfileId,
      dateOfBirth,
      externalIdentifier,
    } = userAgencyProfile;

    return {
      id,
      firstName: firstName || "",
      middleName: middleName || "",
      lastName: lastName || "",
      suffix: suffix || "",
      externalIdentifier,
      subrole,
      userAgencyProfileId,
      supressInviteEmail: false,
      dateOfBirth,
      joinedAt,
      firstShiftDate,
    };
  };

  const [staffMember, setStaffMember] = useState(
    constructStaffMemberObject(existingStaffMember)
  );

  const validate = () => {
    const errs = [];

    if (!staffMember.subrole) {
      errs.push(
        I18n.t("views.applications.application_staff_member.missing_role")
      );
    }

    if (errs.length > 0) {
      setlocalErrorMessages(errs);
      return false;
    }

    return true;
  };

  const onSubmit = preventDefault(() => {
    finishSubmit();
  });

  const finishSubmit = () => {
    const valid = validate();
    if (valid) {
      addOrEditStaffMember({
        createPerson,
        updatePerson,
        messageDisplay,
        placementProviderId,
        agencyId,
        setAddingNewStaffMember,
        setEditingForStaffMember,
        staffMember,
        setlocalErrorMessages,
      });
    }
  };

  return (
    <Fragment>
      <form
        onSubmit={onSubmit}
        className={classnames({ "space-below-2": existingStaffMember })}
      >
        <InputPanel
          title={`${I18n.t(
            `views.reference_requests.form.${
              existingStaffMember ? "edit" : "add"
            }`
          )} ${I18n.t(
            "views.applications.application_staff_member.staff_member_information"
          )}`}
        >
          <Flex row as="span">
            <FlexItem expand="sm">
              <InputText
                type="text"
                label={I18n.t("common.first_name")}
                required
                value={staffMember.firstName}
                onChange={value =>
                  setStaffMember({ ...staffMember, firstName: value })
                }
              />
            </FlexItem>
            <FlexItem expand="sm">
              <InputText
                type="text"
                label={I18n.t("common.middle_name")}
                value={staffMember.middleName}
                onChange={value =>
                  setStaffMember({ ...staffMember, middleName: value })
                }
              />
            </FlexItem>
          </Flex>
          <Flex row as="span">
            <FlexItem expand="sm">
              <InputText
                type="text"
                label={I18n.t("common.last_name")}
                required={true}
                value={staffMember.lastName}
                onChange={value =>
                  setStaffMember({ ...staffMember, lastName: value })
                }
              />
            </FlexItem>
            <FlexItem expand="sm">
              <InputText
                type="text"
                label={I18n.t("common.suffix")}
                value={staffMember.suffix}
                onChange={value =>
                  setStaffMember({ ...staffMember, suffix: value })
                }
              />
            </FlexItem>
          </Flex>
          <FlexItem expand="sm">
            <InputText
              type="text"
              label={I18n.t(
                "views.applications.application_staff_member.person_id"
              )}
              value={staffMember.externalIdentifier}
              onChange={value =>
                setStaffMember({ ...staffMember, externalIdentifier: value })
              }
            />
          </FlexItem>
          <InputFilterable
            label={I18n.t("views.applications.application_staff_member.role")}
            onChange={({ value }) =>
              setStaffMember({ ...staffMember, subrole: value })
            }
            value={{
              value: staffMember.subrole,
              label: staffMember.subrole
                ? I18n.t(
                    `views.applications.application_staff_member.staff_member_types.${staffMember.subrole}`
                  )
                : "",
            }}
            options={STAFF_SUBROLES.sort().map(role => ({
              value: role,
              label: I18n.t(
                `views.applications.application_staff_member.staff_member_types.${role}`
              ),
            }))}
            required
          />
          <InputDate
            label={I18n.t("views.common.date_of_birth")}
            name="staffMember[date_of_birth]"
            value={staffMember.dateOfBirth}
            maxDate={DateTime.local().toFormat(LUXON_DATEPICKER_FORMAT)}
            onChange={date =>
              setStaffMember({ ...staffMember, dateOfBirth: date })
            }
          />
          <InputDate
            label={I18n.t("views.reference_requests.form.joined_date")}
            name="staffMember[joined_at]"
            value={staffMember.joinedAt}
            onChange={date =>
              setStaffMember({ ...staffMember, joinedAt: date })
            }
            required
            maxDate={staffMember.firstShiftDate}
          />
          <InputDate
            label={I18n.t(
              "views.applications.application_staff_member.first_shift"
            )}
            name="staffMember[first_shift_date]"
            value={staffMember.firstShiftDate}
            onChange={date =>
              setStaffMember({ ...staffMember, firstShiftDate: date })
            }
            minDate={staffMember.joinedAt}
          />
          <If condition={!isEmpty(localErrorMessages)}>
            <BannerContainer
              type={"error"}
              /* localErrorMessages is an array that contains strings or JSX components.
               * 'reduce' syntax allow us to join these two different types of values.
               */
              message={localErrorMessages.reduce((prev, curr) => [
                prev,
                ", ",
                curr,
              ])}
            />
          </If>
          <Flex row className="space-above-3">
            <Button type="submit" variant="primary">
              {I18n.t("views.application_stages.staff_member_section.save")}
            </Button>
            <Button
              variant="tertiary"
              onClick={preventDefault(() => {
                if (existingStaffMember) {
                  setlocalErrorMessages();
                  setEditingForStaffMember(false);
                } else {
                  setlocalErrorMessages();
                  setAddingNewStaffMember(false);
                }
              })}
            >
              {I18n.t("views.common.cancel")}
            </Button>
          </Flex>
        </InputPanel>
      </form>
    </Fragment>
  );
};

StaffMemberEditor.propTypes = {
  agencyId: PropTypes.number.isRequired,
  placementProviderId: PropTypes.number.isRequired,
  messageDisplay: PropTypes.object.isRequired,
  existingStaffMember: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    subrole: PropTypes.string,
    userAgencyProfiles: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setAddingNewStaffMember: PropTypes.func.isRequired,
  setEditingForStaffMember: PropTypes.func.isRequired,
  /* localErrorMessages is an array that contains error strings or JSX components.
   * Its state is set originially in the parent, OtherAdultForm, and receives updates
   * both in this component and in addOrEditStaffMember, a function also passed
   * in from the parent.
   */
  localErrorMessages: PropTypes.array.isRequired,
  setlocalErrorMessages: PropTypes.func.isRequired,
};

export default StaffMemberEditor;
