import { useQuery } from "@apollo/client";
import { Flex, LoadingOverlay, Surface } from "@heart/components";

import { translationWithRoot } from "@components/T";

import PlacementSearchQuery from "@graphql/queries/PlacementSearch.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import AgenciesAssignedEdit from "./agencies_assigned/AgenciesAssignedEdit";
import AddAgenciesEdit from "./agencies_assigned/add_agencies/AddAgenciesEdit";
import PlacementSearchDetails from "./placement_search_details/PlacementSearchDetails";
import Response from "./response/Response";
import UpdateWorkersAssigned from "./workers_assigned/UpdateWorkersAssigned";
import AddWorkersEdit from "./workers_assigned/add_workers/AddWorkersEdit";

const { t } = translationWithRoot("placement_search");

const PlacementSearchFormEdit = ({ id, agencyId, childId }) => {
  const { data, loading: dataLoading } = useQuery(PlacementSearchQuery, {
    variables: { id },
  });

  const { isAssignedFromOtherAgency, assignableToOtherAgency } =
    data?.placementSearch || {};

  return (
    <LoadingOverlay active={dataLoading}>
      <Surface>
        <Surface title={t("workers_assigned.title")}>
          <Flex column>
            <UpdateWorkersAssigned placementSearchId={id} />
            <AddWorkersEdit placementSearchId={id} childId={childId} />
          </Flex>
        </Surface>
        <If condition={assignableToOtherAgency}>
          <Surface title={t("agencies_assigned.title")}>
            <Flex column>
              <AgenciesAssignedEdit placementSearchId={id} />
              <AddAgenciesEdit placementSearchId={id} agencyId={agencyId} />
            </Flex>
          </Surface>
        </If>
        <If condition={isAssignedFromOtherAgency}>
          <Response placementSearchId={id} />
        </If>
        <PlacementSearchDetails id={id} childId={childId} />
      </Surface>
    </LoadingOverlay>
  );
};

PlacementSearchFormEdit.propTypes = {
  id: BintiPropTypes.ID.isRequired,
  agencyId: BintiPropTypes.ID,
  childId: BintiPropTypes.ID,
};

export default PlacementSearchFormEdit;
