import T from "@components/T";

import BintiSignWaiter from "./BintiSignWaiter";

const contentWhileLoading = () => (
  <div>
    <T t="bintisign.bintisign_waiter.preparing_your_document" />
  </div>
);

/**
 * A waiter that needs to be small enough to fit in a table.
 */
const BintiSignTableWaiter = passthroughProps => (
  <BintiSignWaiter
    contentWhileLoading={contentWhileLoading}
    {...passthroughProps}
  />
);

BintiSignTableWaiter.propTypes = BintiSignWaiter.passthroughProps;

export default BintiSignTableWaiter;
