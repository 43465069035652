import { InputCheckbox } from "@heart/components";
import classNames from "classnames";
import I18n from "i18n-js";
import { isEmpty, isNil } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import generateId from "@lib/generateId";

import styles from "./NestedMulti.module.scss";

/** Translate a provided key, taking into account that the path may
 * be for a top-level option, which will have both a `label` - the actual
 * translation for the option - and a series of other translations for
 * the options nested under the one we're translating
 */
export const translateLabel = ({ i18nKey }) => {
  let translatedLabel = I18n.t(i18nKey);
  if (typeof translatedLabel === "object") {
    translatedLabel = translatedLabel.label;
  }
  return translatedLabel;
};

/**
 * An individual option to select, possibly with child options. If there
 * are child options, this component has a NestedMulti child, which will
 * then be mutually recursive with this component. :)
 */
const NestedMultiOption = ({
  onOptionSelected,
  onOptionUnselected,
  option: { label, path, details, children: childOptions, i18n_key: i18nKey },
  selectedOptions,
}) => {
  const [id] = useState(generateId);

  const onCheckChange = checked => {
    const callback = checked ? onOptionSelected : onOptionUnselected;
    callback(path);
  };

  const onDetailsChange = ({ target }) => {
    onOptionSelected(path, target.value);
  };

  const checked = Object.keys(selectedOptions).some(key =>
    key.startsWith(path)
  );

  const detailsType = details?.type || "text";

  const translatedLabel = isNil(i18nKey) ? label : translateLabel({ i18nKey });

  return (
    <div className={classNames(styles.level, styles.container)}>
      <InputCheckbox
        label={translatedLabel}
        onChange={onCheckChange}
        value={checked}
        htmlValue={path}
      />
      {details && (
        <div>
          {detailsType === "text" && (
            <label className="is-hidden-accessible" htmlFor={`${id}-details`}>
              {`${translatedLabel}:`}
            </label>
          )}
          <input
            type={detailsType}
            id={`${id}-details`}
            value={selectedOptions[path] || ""}
            onChange={onDetailsChange}
            disabled={!checked}
            required={!!details.required}
          />
        </div>
      )}
      {childOptions && checked && (
        <NestedMulti
          options={childOptions}
          {...{ onOptionSelected, onOptionUnselected, selectedOptions }}
        />
      )}
    </div>
  );
};

NestedMultiOption.propTypes = {
  option: PropTypes.object.isRequired,
  onOptionSelected: PropTypes.func.isRequired,
  onOptionUnselected: PropTypes.func.isRequired,
  selectedOptions: PropTypes.object.isRequired,
};

/**
 * NestedEnum is the root of a tree of NestedEnumLevel components that
 * exists to do operations that are particular to the root node, namely:
 *
 *                            NestedMulti
 *                          /             \
 *             NestedMultiOption         NestedMultiOption
 *                       /
 *               NestedMulti
 */
const NestedMulti = ({
  options,
  onOptionSelected,
  onOptionUnselected,
  selectedOptions,
}) => (
  <If condition={!isEmpty(options)}>
    <div className={styles.container}>
      {options.map(option => (
        <NestedMultiOption
          key={option.path}
          {...{
            option,
            onOptionSelected,
            onOptionUnselected,
            selectedOptions,
          }}
        />
      ))}
    </div>
  </If>
);

NestedMulti.propTypes = {
  options: PropTypes.array.isRequired,
  onOptionSelected: PropTypes.func.isRequired,
  onOptionUnselected: PropTypes.func.isRequired,
  selectedOptions: PropTypes.object.isRequired,
};

export default NestedMulti;
