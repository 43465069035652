import classnames from "classnames";

import useUpload from "./useUpload";
import styles from "./useUploadRow.module.scss";

/**
 * Callback to upload files
 *
 * @callback uploadFilesCallback
 * @param {File[]} files
 */

/**
 * @typedef {Object} UploadRowState
 * @property {function} aroundLoader wraps another function such that
 *   `isLoading` becomes true when the function is first called, then becomes
 *   false when it finishes. If the function returns a Promise, `isLoading`
 *   will become false when the promise concludes.
 * @property {function} getInputProps function that returns props to be
 *   added to an <input> element in the row. There MUST be such an
 *   <input> element rendered in one of the table cells.
 * @property {function} getTrProps function that returns props to be
 *   added to the Tr component of the row. Its return value should be spread
 *   into the <Tr> props.
 * @property {boolean} isLoading boolean that indicates if actively loading
 *   something. This value will be true when either `uploadFiles` is active or
 *   in the time between when a function wrapped with `aroundLoader` begins and
 *   ends.
 * @property {boolean} isDragActive boolean that indicates if a drag is active
 *   over this row.
 * @property {function} openFileDialog function to open file dialog explicitly
 *   on user input, e.g. when clicking a button.
 */

/**
 * Hook to enable drag and drop on a table row with our Binti styling.
 *
 * Example usage:
 *
 * ```js
 * const { getTrProps, getInputProps, aroundLoader, isLoading } =
 *   useUploadRow(uploadSomehow);
 *
 * <Tr {...getTrProps()}>
 *   <Td>
 *     <Button disabled={isLoading}>Some button</Button>
 *   </Td>
 *   <Td>
 *     <Button onClick={aroundLoader(() => loadSomething())}>Load!</Button>
 *   </Td>
 *   <Td>
 *     <UploadButton passedInputProps={getInputProps} />
 *   </Td>
 * </Td>
 * ```
 *
 * @param {uploadFilesCallback} uploadFiles
 * @returns {UploadRowState}
 * */
export default uploadFiles => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    openFileDialog,
    isLoading,
    aroundLoader,
  } = useUpload(uploadFiles);

  const { className, ...rootProps } = getRootProps();

  const getTrProps = () => ({
    ...rootProps,
    className: classnames(className, { [styles.dragActive]: isDragActive }),
  });

  return {
    aroundLoader,
    getInputProps,
    getTrProps,
    isLoading,
    openFileDialog,
    isDragActive,
  };
};
