import PropTypes from "prop-types";

import browser from "./browser";

const BrowserDisplay = ({ userAgent = navigator.userAgent }) => {
  const { browserString } = browser(userAgent);

  return <span>{browserString || "Unknown browser"}</span>;
};

BrowserDisplay.propTypes = {
  userAgent: PropTypes.string,
};

export default BrowserDisplay;
