import { useMutation, useReactiveVar } from "@apollo/client";
import { Modal, toast } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import UpdateApiToken from "@graphql/mutations/UpdateApiToken.graphql";
import ApiTokensQuery from "@graphql/queries/ApiTokens.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import ApiTokensEdit from "./ApiTokensEdit";

/**
 * Edit modal for API token.
 *
 * @param {string} apiTokenIdToEdit - The ID of the API token to edit.
 * @param {function} resetStateCallback - Callback function to reset the state.
 */
const ApiTokenEditModal = ({ apiTokenIdToEdit, resetStateCallback }) => {
  const queryVariables = useReactiveVar(queryVariablesVar);

  const [tokenName, setTokenName] = useState(false);
  const [updateApiToken, { loading }] = useMutation(UpdateApiToken, {
    onCompleted: () => {
      toast.success({
        title: "Success!",
        message: "Updated API token",
      });
      resetStateCallback();
    },
    refetchQueries: [{ query: ApiTokensQuery, variables: queryVariables }],
  });

  return (
    <Modal
      asForm
      title="Edit Token Name"
      submitting={loading}
      submitText="Save"
      submittingText="Saving..."
      onCancel={() => {
        resetStateCallback();
      }}
      onSubmit={async () => {
        await updateApiToken({
          variables: { apiTokenId: apiTokenIdToEdit, tokenName: tokenName },
        });
      }}
      hidden={false}
    >
      <ApiTokensEdit setTokenName={setTokenName} />
    </Modal>
  );
};

ApiTokenEditModal.propTypes = {
  apiTokenIdToEdit: BintiPropTypes.ID,
  resetStateCallback: PropTypes.func,
};
export default ApiTokenEditModal;
