import { Flex } from "@heart/components";

import AfcarsTable from "./AfcarsTable";

const ChildrenWaitingToBeAdopted = () => (
  <Flex column>
    <h1 style={{ marginLeft: "20px" }}>Children Entering Foster Care</h1>
    <h2 style={{ marginLeft: "20px", padding: "0" }}>
      September 30, 2023 (N=9,046)
    </h2>
    <Flex row>
      <Flex column style={{ flex: 1 }}>
        <AfcarsTable
          stats
          key="age-stats"
          headers={["Age", "Year"]}
          data={[
            ["Mean", "7.2"],
            ["Median", "6.0"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="age-stats-2"
          headers={["Age", "%", "Number"]}
          data={[
            ["Less than 1 Year", "4%", "356"],
            ["1 Year", "10%", "915"],
            ["2 Years", "10%", "900"],
            ["3 Years", "8%", "739"],
            ["4 Years", "7%", "666"],
            ["5 Years", "6%", "553"],
            ["6 Years", "6%", "514"],
            ["7 Years", "5%", "476"],
            ["8 Years", "5%", "442"],
            ["9 Years", "5%", "427"],
            ["10 Years", "4%", "391"],
            ["11 Years", "4%", "387"],
            ["12 Years", "4%", "378"],
            ["13 Years", "5%", "417"],
            ["14 Years", "5%", "417"],
            ["15 Years", "4%", "400"],
            ["16 Years", "4%", "388"],
            ["17 Years", "3%", "280"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="placement-type"
          headers={["Placement type", "%", "Number"]}
          data={[
            ["Pre-Adoptive Home", "4%", "353"],
            ["Foster Family Home (Relative)", "36%", "3,266"],
            ["Foster Family Home (Non-Relative)", "50%", "4,505"],
            ["Group Home", "8%", "713"],
            ["Institution", "2%", "145"],
            ["Supervised Independent Living", "0%", "0"],
            ["Runaway", "1%", "64"],
            ["Trial Home Visit", "0%", "0"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="sex-stats"
          headers={["Sex", "%", "Number"]}
          data={[
            ["Male", "52%", "4,662"],
            ["Female", "48%", "4,382"],
          ]}
        />
        <AfcarsTable
          stats
          key="time-in-care"
          headers={["Time in care", "Months"]}
          data={[
            ["Mean", "27.0"],
            ["Median", "23.7"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="time-in-care-2"
          headers={["Time in Care", "%", "Number"]}
          data={[
            ["Less than 1 Month", "1%", "59"],
            ["1 - 5 Months", "5%", "482"],
            ["6-11 Months", "11%", "1,017"],
            ["12-17 Months", "17%", "1,499"],
            ["18-23 Months", "17%", "1,564"],
            ["24-29 Months", "16%", "1,416"],
            ["30-35 Months", "10%", "921"],
            ["3 - 4 Years", "18%", "1,627"],
            ["5 Years or More", "5%", "461"],
          ]}
        />
      </Flex>
      <Flex column style={{ flex: 1 }}>
        <AfcarsTable
          stats
          key="age-entry-stats"
          headers={["Age", "Year"]}
          data={[
            ["Mean", "5.0"],
            ["Median", "4.0"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="age-entry-stats-2"
          headers={["Age at Entry into Foster Care", "%", "Number"]}
          data={[
            ["Less than 1 Year", "26%", "2,328"],
            ["1 Year", "8%", "747"],
            ["2 Years", "7%", "664"],
            ["3 Years", "6%", "565"],
            ["4 Years", "6%", "514"],
            ["5 Years", "5%", "493"],
            ["6 Years", "6%", "517"],
            ["7 Years", "5%", "418"],
            ["8 Years", "5%", "409"],
            ["9 Years", "5%", "438"],
            ["10 Years", "4%", "385"],
            ["11 Years", "4%", "372"],
            ["12 Years", "4%", "346"],
            ["13 Years", "4%", "366"],
            ["14 Years", "3%", "238"],
            ["15 Years", "2%", "173"],
            ["16 Years", "1%", "61"],
            ["17 Years", "0%", "12"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="race-eth"
          headers={["Race/Ethnicity", "%", "Number"]}
          data={[
            ["American Indian/Alaska Native", "0%", "3"],
            ["Asian", "0%", "6"],
            ["Black or African American", "28%", "2,516"],
            ["Native Hawaiian/Other Pacific Islander", "0%", "7"],
            ["Hispanic (of any race)", "15%", "1,377"],
            ["White", "48%", "4,364"],
            ["Unknown/Unable to Determine", "1%", "98"],
            ["Two or More Races", "7%", "675"],
          ]}
          note="All races exclude children of Hispanic origin. Children of Hispanic ethnicity may be any race."
        />
        <AfcarsTable
          stats
          key="time-since-tpr"
          headers={["Time Since TPR", "Months"]}
          data={[
            ["Mean", "14.7"],
            ["Median", "8.0"],
          ]}
          note="Of Children Waiting for Adoption whose Parents' Parental Rights have been Terminated (N=5,649), Time Elapsed since Termination of Parental Rights as of September 30, 2021."
        />
      </Flex>
    </Flex>
  </Flex>
);

export default ChildrenWaitingToBeAdopted;
