import { ContentTabs, Flex } from "@heart/components";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import CreatePermissionRuleSetForm from "../PermissionRuleSets/CreatePermissionRuleSetForm";
import PermissionRuleSetsTable from "../PermissionRuleSets/PermissionRuleSetsTable";
import AgencyRelationshipsTable from "./AgencyRelationshipsTable";
import CreateAgencyRelationshipForm from "./CreateAgencyRelationshipForm";

const { t } = translationWithRoot("permissions.agency_relationships");

const AgencyRelationships = () => (
  <Fragment>
    <ContentTabs
      tabs={[
        {
          contents: (
            <Flex column gap="300">
              <AgencyRelationshipsTable />
              <CreateAgencyRelationshipForm />
            </Flex>
          ),
          title: t("title"),
        },
        {
          contents: (
            <Flex column gap="300">
              <PermissionRuleSetsTable />
              <CreatePermissionRuleSetForm />
            </Flex>
          ),
          title: t("permission_rule_sets"),
        },
      ]}
    />
  </Fragment>
);

export default AgencyRelationships;
