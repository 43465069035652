import { Modal } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "family_finding.potential_kin_search.confirmation_modal"
);

const ConfirmationModal = ({ onSubmit, onCancel, hidden }) => (
  <Modal
    title={t("title")}
    hidden={hidden}
    onSubmit={onSubmit}
    onCancel={onCancel}
    submitText={t("submit_text")}
  >
    {t("confirm_clear_cost")}
  </Modal>
);
ConfirmationModal.propTypes = {
  hidden: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationModal;
