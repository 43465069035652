import classnames from "classnames";
import PropTypes from "prop-types";
import { Fragment } from "react";

import ProgressArc from "@components/ProgressArc";
import T from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

import EndUserUploadTypeAction from "./EndUserUploadTypeAction";
import styles from "./EndUserUploadTypeTable.module.scss";

const classnamesFor = fulfillment => ({
  [styles.actionNeededSoon]: fulfillment.isActionNeededSoon,
  [styles.actionCritical]: fulfillment.isActionCritical,
});

/**
 * Path to the page where a user can upload records for the given upload type
 * and user agency profile (if present).
 */
const uploadTypePath = ({ slug, applicationId, userAgencyProfileId }) => {
  const base = `/application/${applicationId}/upload_types/${slug}`;

  if (userAgencyProfileId) {
    return `${base}?user_agency_profile_id=${userAgencyProfileId}`;
  }

  return base;
};

const EndUserUploadTypeTableSection = ({
  applicationId,
  i18nRoleKey,
  isHeaderHidden,
  sectionFulfillments,
}) => (
  <Fragment key={`section-${i18nRoleKey}`}>
    {isHeaderHidden || (
      <tr className={styles.roleRow}>
        <td colSpan={2}>
          <T t={`end_user.end_user_upload_type_table.${i18nRoleKey}`} />
        </td>
      </tr>
    )}
    {sectionFulfillments.map(fulfillment => {
      const {
        requirement,
        requirement: {
          uploadType: { slug },
        },
      } = fulfillment;

      const userAgencyProfileId = requirement.userAgencyProfile?.id;
      const path = uploadTypePath({
        slug,
        applicationId,
        userAgencyProfileId,
      });

      return (
        <tr
          key={`fulfillment-${slug}-${i18nRoleKey}-${requirement}-${userAgencyProfileId}`}
          className={classnames(
            styles.uploadTypeRequirementRow,
            classnamesFor(fulfillment)
          )}
        >
          <td>
            <a href={path} className={styles.requirementTitleLink}>
              {fulfillment.title}
              <EndUserUploadTypeAction fulfillment={fulfillment} />
            </a>
          </td>
          <td>
            <a href={path} className={styles.progressColumnLink}>
              <ProgressArc
                progress={fulfillment.progressPercentage}
                progressIncomplete={!fulfillment.isFulfilled}
              />
              <i
                className={classnames("fa fa-chevron-right", styles.chevron)}
                aria-hidden
              />
            </a>
          </td>
        </tr>
      );
    })}
  </Fragment>
);

EndUserUploadTypeTableSection.propTypes = {
  applicationId: BintiPropTypes.ID,
  i18nRoleKey: PropTypes.string.isRequired,
  isHeaderHidden: PropTypes.bool,
  sectionFulfillments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EndUserUploadTypeTableSection;
