import { Flex, Icons, FlexItem } from "@heart/components";
import classNames from "classnames";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import styles from "./NewPasswordField.module.scss";
import PasswordStrength from "./PasswordStrength";

const NewPasswordField = ({
  username,
  name = "user[password]",
  id = "user_password",
}) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Fragment>
      <Flex column>
        <Flex align="center">
          <FlexItem>
            <input
              value={password}
              onChange={e => setPassword(e.target.value)}
              autoComplete="on"
              className={classNames("form-control", styles.passwordInput)}
              type={showPassword ? "text" : "password"}
              name={name}
              id={id}
            />
          </FlexItem>
          <FlexItem expand="none" className={styles.showHideIcon}>
            <If condition={!showPassword}>
              <Icons.Eye
                onClick={() => {
                  setShowPassword(true);
                }}
                description={I18n.t(
                  "javascript.components.permissions.login.show_password"
                )}
              />
            </If>
            <If condition={showPassword}>
              <Icons.EyeSlash
                onClick={() => {
                  setShowPassword(false);
                }}
                description={I18n.t(
                  "javascript.components.permissions.login.hide_password"
                )}
              />
            </If>
          </FlexItem>
        </Flex>
        <PasswordStrength password={password} username={username} />
      </Flex>
    </Fragment>
  );
};

NewPasswordField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  username: PropTypes.string,
};

export default NewPasswordField;
