$(function () {
  const titleize = function (string) {
    if (string === "in_adoption_process") {
      return "Not Converting";
    } else {
      return (string.charAt(0).toUpperCase() + string.slice(1)).replace(
        "_",
        " "
      );
    }
  };

  const $email = $(".js-email-change");
  const $email_change_explanation = $(".js-email-change-explanation");
  const $not_signed = $("li[id^=application_not_signed]");

  const is_binti_admin = $("input[name='is_binti_admin']").val() === "true";

  $("select#application_status").on("change", function () {
    on_status_change($(this).val());
  });

  $(".js-applicant-training-track").on("change", function () {
    on_applicant_training_track_change($(this).val());
  });

  // We want to check the name, phonenumber and email fields
  // for presence of co_applicant. This is what the backend also
  // uses. This was updated since we add new radio/checkbox fields
  // with input values set, related to protected tribes.
  const $co_applicant_form = $("#co_applicant_form");
  const is_co_applicant_present_for_our_purposes = () =>
    $co_applicant_form
      .find("input[type='text'],input[type='email']")
      .toArray()
      .some(field => field.value);
  function on_applicant_training_track_change(training_track) {
    if (is_co_applicant_present_for_our_purposes()) {
      $($(".js-co-applicant-training-track")[0]).val(training_track);
    }
  }

  function setup_training_tracks_based_on_co_applicant_presence() {
    const $hint = $("li[id*='training_track'] .inline-hints");
    const $co_applicant_training_track_input_wrapper = $(
      "li[id*='co_applicant_agency_profile_attributes_training_track']"
    );

    if (is_co_applicant_present_for_our_purposes()) {
      $hint.show();
      $co_applicant_training_track_input_wrapper.show();
    } else {
      $hint.hide();
      $co_applicant_training_track_input_wrapper.find("select").val("");
      $co_applicant_training_track_input_wrapper.hide();
    }
  }

  setup_training_tracks_based_on_co_applicant_presence();
  // `:input` matches ANY "input" (eg, <input>, <select>, <textarea>, etc)
  // elsewhere we use `input` instead to be more specific
  // cf. https://api.jquery.com/input-selector/
  $co_applicant_form
    .find(":input")
    .on("change", setup_training_tracks_based_on_co_applicant_presence);

  function date_label(status) {
    if (status === "licensed") {
      return "Licensed date or last renewal date";
    }

    return `${titleize(status)} date`;
  }

  function on_status_change(status, initialPageLoad = false) {
    const status_data = Binti.application_form_status_data;
    if (!status_data) {
      return;
    }

    const $date = $(".js-application-status-date");
    const $reason = $("li[id^=application_status_reason]");
    const $explanation = $("li[id^=application_status_explanation]");

    const $date_input = $date.find("input");
    const original_date = Binti.original_status_date;

    function show_date(status, animationDelay) {
      $date.find("label").html(date_label(status));
      $date.show(animationDelay);
    }

    const {
      statuses_requiring_date,
      statuses_allowing_date,
      statuses_allowing_not_signed,
      statuses_requiring_explanation,
      statuses_requiring_reason,
      original_status,
      status_change_reasons,
      application_type,
    } = status_data;

    // cf. http://stackoverflow.com/a/15386005/283398
    const statuses_with_optional_date = $(statuses_allowing_date)
      .not(statuses_requiring_date)
      .get();

    const animationDelay = initialPageLoad ? 0 : 250;

    if (
      status !== original_status &&
      statuses_allowing_date.indexOf(status) >= 0
    ) {
      // status has changed, and new status may need new date, if the user hasn't
      // already provided the new date on an earlier submit, so maybe wipe out
      // existing date, if any, and show date field
      if (!initialPageLoad) {
        $date_input.val(null);
      }
      show_date(status, animationDelay);
    } else if (
      // licensed date is optional, so allow binti admin to add it later if they
      // didn't set it initially
      status === original_status &&
      is_binti_admin &&
      statuses_with_optional_date.indexOf(status) >= 0 &&
      original_date === ""
    ) {
      show_date(status, animationDelay);
    } else if (
      status === original_status &&
      statuses_allowing_date.indexOf(status) >= 0
    ) {
      // status either hasn't changed or has returned to original status, so no
      // new date is required, but we still want to send the existing date to the
      // server, to be a good client
      $date.hide(animationDelay);
      $date_input.val(original_date);
    } else {
      $date.hide(animationDelay);
      $date_input.val(null);
    }

    if (
      status !== original_status &&
      statuses_requiring_reason.indexOf(status) >= 0
    ) {
      if (!initialPageLoad) {
        $reason.find(":input").val(null);
      }
      $reason.find("label").html(`${titleize(status)} reason*`);
      const $select = $reason.find("select");
      const original_select_value = $select.val();
      $select.find("option[value!='']").remove();
      $.each(status_change_reasons, function (_, ...rest) {
        const [reason, value] = rest[0];
        let shouldShow = false;

        if (
          status === "closed" &&
          value >= 400 &&
          (value < 490 ||
            (application_type === "CaliforniaConversionApplication" &&
              value <= 499))
        ) {
          shouldShow = true;
        } else if (
          status === "recruiting_dropout" &&
          status_data.valid_recruiting_dropout_reasons_values.includes(value)
        ) {
          shouldShow = true;
        } else if (
          status === "denied" &&
          status_data.valid_denied_reasons_values.includes(value)
        ) {
          shouldShow = true;
        } else if (
          status === "in_adoption_process" &&
          status_data.valid_adoption_or_not_converted_values.includes(value)
        ) {
          shouldShow = true;
        } else if (
          status === "withdrawn" &&
          status_data.valid_withdrawn_reasons_values.includes(value)
        ) {
          shouldShow = true;
        } else if (value >= 1000) {
          shouldShow = true;
        }

        if (shouldShow) {
          $select.append($(`<option value='${reason}'>${reason}</option>`));
        }
      });

      if (original_select_value !== "") {
        $select.val(original_select_value);
      }
      $reason.show(animationDelay);
    } else {
      if (statuses_requiring_reason.indexOf(status) === -1) {
        $reason.find(":input").val(null);
      }

      $reason.hide(animationDelay);
    }

    if (
      status !== original_status &&
      statuses_requiring_explanation.indexOf(status) >= 0
    ) {
      if (!initialPageLoad) {
        $explanation.find(":input").val(null);
      }
      $explanation.find("label").html(`${titleize(status)} explanation*`);
      $explanation.show(animationDelay);
    } else {
      if (statuses_requiring_explanation.indexOf(status) === -1) {
        $explanation.find(":input").val(null);
      }

      $explanation.hide(animationDelay);
    }

    if (statuses_allowing_not_signed.indexOf(status) >= 0) {
      $not_signed.show(animationDelay);
    } else {
      $not_signed.find(":input").prop("checked", false);
      $not_signed.hide(animationDelay);
    }
  }

  on_status_change($("select#application_status").val(), true);

  $("input[name='application[single_parent]']").on("change", function () {
    on_single_applicant_change($(this).val());
  });

  function on_single_applicant_change(single_parent, animationDelay = 250) {
    if (single_parent === "false") {
      $co_applicant_form.show(animationDelay);
    } else {
      $co_applicant_form.hide(animationDelay);
    }
  }

  on_single_applicant_change(
    $("input[name='application[single_parent]'][checked='checked']").val()
  );
});
