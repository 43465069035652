import { Layout, Surface } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { GraphQLDataTable } from "../GraphQLDataTable";
import useFilter from "../common/filter/useFilter";
import useGraphQLTable from "../useGraphQLTable";
import SearchResultsTableSidebar from "./SearchResultsTableSidebar";

/** A table designed to display data after filters have been selected
 *
 * This is in opposition of our DataTables which display data first, which
 * can then be whittled down through the application of filters
 *
 * This story only works with Name sorting, looking at EmailListSelection
 * is a much better example of this component in action!
 */
const SearchResultsTable = ({
  pageTitle,
  subtitle,
  breadcrumbs,
  title,
  actions,
  emptyStatePrompt,
  ...args
}) => {
  const useTableProps = useGraphQLTable({
    ...args,
    ...useFilter({ filters: args.filters }),
  });

  return (
    <Layout
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      sidebar={{
        title: I18n.t("admin.filters.filters"),
        content: <SearchResultsTableSidebar {...args} {...useTableProps} />,
        wide: true,
      }}
      main={{
        content: (
          <Fragment>
            <Surface title={title} subtitle={subtitle}>
              <GraphQLDataTable
                {...args}
                {...useTableProps}
                emptyStatePrompt={emptyStatePrompt}
              />
            </Surface>
            {actions}
          </Fragment>
        ),
      }}
    />
  );
};

SearchResultsTable.propTypes = {
  /** A title for the page */
  pageTitle: PropTypes.string,
  /** An instance of <Breadcrumbs/> indicating where in the information
   * architecture this page lives
   */
  breadcrumbs: PropTypes.node,
  /** The title of the table */
  title: PropTypes.string,
  /** Subtitle of the table. Should usually be a simple string, but can
   * be a node if it has links. */
  subtitle: PropTypes.node,
  /** Actions to render above the top right corner of the table */
  actions: PropTypes.node,
  /** Prompt to display when filters have not been selected for the table yet */
  emptyStatePrompt: PropTypes.string,
};

export default SearchResultsTable;
