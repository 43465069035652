import { Text, Flex, HeartTable, Icons } from "@heart/components";

import styles from "./CaregiverTable.module.scss";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const ContactInfoCell = () => (
  <Flex row gap="300">
    <img
      src="http://placekitten.com/76/76"
      className={styles.avatar}
      alt="avatar"
    />
    <Flex column>
      <div className={styles.contactInfoPrimary}>Austin Lan</div>
      <Flex row className={styles.contactInfoSecondary}>
        <Icons.Envelope />
        austin@binti.com
      </Flex>
      <Flex className={styles.contactInfoSecondary}>
        <Icons.Phone />
        408-306-0508
      </Flex>
    </Flex>
  </Flex>
);

const StatusCell = () => (
  <Flex column>
    <div className={styles.status}>Living in Home</div>
    <a href="google.com" className={styles.statusLink}>
      Edit Status
    </a>
  </Flex>
);

const InformationCell = () => (
  <Flex column gap="0">
    <InformationItem property="Days in Home" value="10/2/2022 - Present" />
    <InformationItem property="Date of Birth" value="2/12/1987" />
    <InformationItem property="Ethnicity" value="Prefer not to answer" />
    <InformationItem property="Gender" value="Non-binary" />
  </Flex>
);

// eslint-disable-next-line react/prop-types
const InformationItem = ({ property, value }) => (
  <Flex row justify="space-between">
    <Text
      as="div"
      textColor="neutral-600"
      className={styles.informationProperty}
    >
      {property}
    </Text>
    <div className={styles.informationValue}>{value}</div>
  </Flex>
);

const ActionsCell = () => (
  <Flex row justify="end" gap="300">
    <Icons.Pencil />
    <Icons.Envelope />
    <Icons.Ellipsis />
  </Flex>
);

/**
 * **This is a prototype** - a starting point for Licensing's
 * implementation and a demonstration of Heart components in use.
 */
const CaregiverTable = () => (
  <Table>
    <Thead>
      <Tr>
        <Th>Name</Th>
        <Th>Status</Th>
        <Th>Information</Th>
        <Th>Controls</Th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr>
        <Td>
          <ContactInfoCell />
        </Td>
        <Td>
          <StatusCell />
        </Td>
        <Td>
          <InformationCell />
        </Td>
        <Td>
          <ActionsCell />
        </Td>
      </Tr>
    </Tbody>
  </Table>
);
export default CaregiverTable;
