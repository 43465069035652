const onUploadForCell =
  ({ disableUploadForRow, disableUploadColumnIdCellIndexes, onUpload }) =>
  ({ rowData, column, cellIndex }) => {
    if (!disableUploadForRow && !disableUploadColumnIdCellIndexes)
      return onUpload;

    const disableForRow = disableUploadForRow && disableUploadForRow(rowData);
    const disableForCell =
      disableUploadColumnIdCellIndexes?.columnId === column.id &&
      (disableUploadColumnIdCellIndexes.indexes || []).includes(cellIndex);
    return disableForRow || disableForCell ? undefined : onUpload;
  };

export default onUploadForCell;
