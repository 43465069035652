import PropTypes from "prop-types";

import InputHidden from "./InputHidden";

/**
 * A CSRF (cross-site request forgery) token used when submitting an HTML form
 * to let Rails know that the form originated from this server. To obtain the
 * token, call `form_authenticity_token` on the Ruby side and pass it to the
 * frontend somehow, e.g. `react_component` props being the most common way you
 * might do so.
 */
const InputCsrfToken = ({ csrfToken }) => (
  <InputHidden name="authenticity_token" value={csrfToken} />
);

InputCsrfToken.propTypes = {
  csrfToken: PropTypes.string.isRequired,
};
export default InputCsrfToken;
