import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import {
  FILE_INPUT_ACCEPT_VALUE,
  FILE_INPUT_ACCEPT_VALUE_AUDIO_ONLY,
  FILE_INPUT_ACCEPT_VALUE_PHOTOS_ONLY,
} from "@root/constants";

/**
 * Wrapper hook for react-dropzone's `useDropzone`, but uses our defaults
 * and conventions.
 */
const useDropzoneWrapper = ({
  onDrop,
  noClick,
  noDrag,
  setError,
  multiple,
  photosOnly = false,
  audioOnly = false,
}) => {
  if (photosOnly && audioOnly) {
    throw new Error("photosOnly and audioOnly cannot both be true");
  }

  const accept = (() => {
    if (photosOnly) return FILE_INPUT_ACCEPT_VALUE_PHOTOS_ONLY;
    if (audioOnly) return FILE_INPUT_ACCEPT_VALUE_AUDIO_ONLY;
    return FILE_INPUT_ACCEPT_VALUE;
  })();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
    noClick,
    noDrag,
    multiple,
    onDropRejected: rejections => {
      const firstError = rejections[0].errors[0].code;
      setError(firstError);
    },
    // fs access doesn't play nicely with playwright
    // https://github.com/microsoft/playwright/issues/18267
    useFsAccessApi: false,
  });
  return { getRootProps, getInputProps, isDragActive };
};

useDropzoneWrapper.propTypes = {
  /** Function called with array of files uploaded. **Note**: this function
   * will only be called with files that meet our accepted file types list
   */
  onDrop: PropTypes.func.isRequired,
  /** Disables click functionality of dropzone */
  noClick: PropTypes.bool,
  /** Disables drag and drop functionality of dropzone */
  noDrag: PropTypes.bool,
  /** Allow multiple files to be uploaded. */
  multiple: PropTypes.bool,
  /** Function called when dropzone errors */
  setError: PropTypes.func.isRequired,
  /**
   * If true, only photos will be accepted by this dropzone. Cannot be true
   * at the same time as `audioOnly`.
   */
  photosOnly: PropTypes.bool,
  /**
   * If true, only audio files will be accepted by this dropzone. Cannot be
   * true at the same time as `photosOnly`.
   */
  audioOnly: PropTypes.bool,
};

export default useDropzoneWrapper;
