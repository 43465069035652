import PropTypes from "prop-types";

import T from "@components/T";

import ProgressArc from "../../../ProgressArc";
import styles from "../../BintiSign.module.scss";

/**
 * Lists a location in the document where the user must sign, a status of
 * whether they have signed that location yet or not, and a link to the page
 * with that location.
 */
const SignatureLocation = ({ locations, scrollTo, pageRef }) => (
  <div className={styles.signatureLocation}>
    <div className={styles.pageNumber}>
      <button
        onClick={() => {
          scrollTo(pageRef);
        }}
        disabled={!pageRef}
      >
        <T
          t="bintisign.signature_location.page_number"
          number={locations[0].location.pageNumber}
        />
      </button>
    </div>
    <div className={styles.signatureCount}>
      <T
        t="bintisign.signature_location.number_signatures_required"
        number={locations.length}
      />
    </div>
    <div className={styles.signaturePageStatus}>
      <ProgressArc
        progress={
          100 *
          (locations.filter(({ isSigned }) => isSigned).length /
            locations.length)
        }
      />
    </div>
  </div>
);

SignatureLocation.propTypes = {
  locations: PropTypes.array.isRequired,
  scrollTo: PropTypes.func.isRequired,
  pageRef: PropTypes.object,
};

export default SignatureLocation;
