import { Button } from "@heart/components";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

import preventDefault from "@lib/preventDefault";

import styles from "./NavBarMenu.module.scss";

class NavBarMenu extends Component {
  constructor(props) {
    super(props);
    const currentlyOnLink = props.links.filter(
      link => link.path.match(props.currentUrl) != null
    );
    this.state = {
      showMenu: currentlyOnLink.length > 0,
    };
  }

  handleClick = preventDefault(() => {
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
  });

  subMenu = () => {
    const { links } = this.props;
    const onClick = event => {
      const link = links[event.target.name];
      $.ajax({
        dataType: link.dataType,
        url: link.path,
        type: link.type,
        data: link.data,
        success: function handleResponse() {
          if (link.redirectLink) {
            window.location.replace(link.redirectLink);
          } else {
            window.location.reload();
          }
        },
        error: function onError() {
          // eslint-disable-next-line no-alert
          alert("An error occurred. Please refresh page and try again.");
        },
      });
    };

    const getLink = (link, index) => {
      if (link.type === "GET") {
        return (
          <li key={`link_${index}`} className={styles["nested-bg"]}>
            <a name={index} href={link.path}>
              {link.name}
            </a>
          </li>
        );
      }
      return (
        <li key={`link_${index}`} className={styles["nested-bg"]}>
          <Button
            className={styles["link-button"]}
            name={index}
            onClick={onClick}
          >
            {link.name}
          </Button>
        </li>
      );
    };

    return <ul>{links.map((link, index) => getLink(link, index))}</ul>;
  };

  render() {
    const { showMenu } = this.state;
    const { title, iconClass = "", linkClass, links } = this.props;
    return (
      <div className={styles["drop-down-menu"]}>
        <ul className={styles.list}>
          <li>
            <a
              href={links[0].path}
              className={classnames(styles.link, linkClass)}
              onClick={this.handleClick}
            >
              {showMenu ? (
                <i className="fas fa-caret-up"></i>
              ) : (
                <i className="fas fa-caret-down"></i>
              )}
              {iconClass && <i className={classnames(iconClass, "fas")}></i>}
              {title}
            </a>
            {showMenu && this.subMenu()}
          </li>
        </ul>
      </div>
    );
  }
}

NavBarMenu.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  iconClass: PropTypes.string,
  linkClass: PropTypes.string,
  currentUrl: PropTypes.string,
};

export default NavBarMenu;
