import { Button, Icons } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

import AddWorkersForm from "./AddWorkersForm";

const { t } = translationWithRoot("placement_search.workers_assigned");

const AddWorkers = ({ workersAssigned, childId, onSubmit }) => {
  const [formHidden, setFormHidden] = useState(true);

  return (
    <Fragment>
      <If condition={formHidden}>
        {/* to make button not span whole length */}
        <div>
          <Button variant="secondary" onClick={() => setFormHidden(false)}>
            <Icons.Plus />
            {t("add_worker")}
          </Button>
        </div>
      </If>
      <If condition={!formHidden}>
        <AddWorkersForm
          workersAssigned={workersAssigned}
          childId={childId}
          onSubmit={newWorkers => {
            onSubmit(newWorkers);
            setFormHidden(true);
          }}
          onCancel={() => setFormHidden(true)}
        />
      </If>
    </Fragment>
  );
};

AddWorkers.propTypes = {
  workersAssigned: PropTypes.object,
  childId: BintiPropTypes.ID,
  primaryWorkerAssignedId: BintiPropTypes.ID,
  onSubmit: PropTypes.func,
};

export default AddWorkers;
