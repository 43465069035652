import { useMutation } from "@apollo/client";
import { Icons } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import EditableDate from "@components/reusable_ui/EditableDate";

import SetUploadedRecordExpirationDate from "@graphql/mutations/SetUploadedRecordExpirationDate.graphql";

import { isOverdue, formatAsLongDate } from "@lib/dates";

import styles from "./EndUserUploadTypeTable.module.scss";

const renderExpirationDate = date => {
  if (date == null) {
    return "";
  }

  const formattedDate = formatAsLongDate(date);

  const isExpired = isOverdue(date);
  if (isExpired) {
    return (
      <span className={styles.expired}>
        {I18n.t("javascript.components.end_user.expiration_date.expired", {
          date: formattedDate,
        })}{" "}
        <Icons.ExclamationTriangle />
      </span>
    );
  }

  return I18n.t("javascript.components.end_user.expiration_date.expires", {
    date: formattedDate,
  });
};

const ExpirationDateControl = ({
  holderToken,
  record,
  aroundLoader = fn =>
    (...args) =>
      fn(...args),
  disabled = false,
}) => {
  const [setUploadedRecordExpirationDate] = useMutation(
    SetUploadedRecordExpirationDate
  );

  const updateExpirationDate = aroundLoader(expirationDate => {
    if (isEmpty(expirationDate)) return false;

    return setUploadedRecordExpirationDate({
      variables: {
        holderToken,
        uploadedRecordId: record.id,
        expirationDate,
      },
    });
  });

  return (
    <EditableDate
      label={I18n.t(
        "javascript.components.end_user.edit_expiration_date.expiration_date"
      )}
      description={I18n.t(
        "javascript.components.end_user.edit_expiration_date.needs_expiration_date"
      )}
      editDateButtonTitle={I18n.t(
        "javascript.components.end_user.expiration_date.edit_expiration"
      )}
      renderDate={renderExpirationDate}
      disabled={disabled}
      onChange={updateExpirationDate}
      required
      value={record.expirationDate}
    />
  );
};

ExpirationDateControl.propTypes = {
  holderToken: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  /** optional wrapper method to signal the beginning and end of the update */
  aroundLoader: PropTypes.func,
  /** optional boolean to disable editing */
  disabled: PropTypes.bool,
};

export default ExpirationDateControl;
