import { Text } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

const ModalTitle = ({ displayLargeTitle, title, id }) => (
  <Text
    as={displayLargeTitle ? "h2" : "h3"}
    textStyle={displayLargeTitle ? "emphasis-300" : "emphasis-100"}
    id={id}
  >
    {title}
  </Text>
);

ModalTitle.propTypes = {
  displayLargeTitle: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  id: PropTypes.string,
};
export default ModalTitle;
