import { Link } from "@heart/components";
import PropTypes from "prop-types";

import BintiPropTypes from "@lib/BintiPropTypes";

const RoleAgencyWorkersLink = ({ numUsersInRole, id }) => (
  <Link
    href={`/admin/agency_workers?q[agency_admin_assignment_role_id_eq]=${id}`}
  >
    {numUsersInRole}
  </Link>
);

RoleAgencyWorkersLink.propTypes = {
  id: BintiPropTypes.ID,
  numUsersInRole: PropTypes.number,
};

export default RoleAgencyWorkersLink;
