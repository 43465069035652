import { useMutation, useQuery } from "@apollo/client";
import { InputDropdown } from "@heart/components";
import Modal from "@heart/components/modal/Modal";
import PropTypes from "prop-types";
import { useState } from "react";

import CreatePermissionCondition from "@graphql/mutations/CreatePermissionCondition";
import * as ResourceActionQuery from "@graphql/queries/ResourceAction.graphql";

import AssignmentToRecordConditionConfig from "./AssignmentToRecordConditionConfig";
import AttributeBasedConditionConfig from "./AttributeBasedConditionConfig";
import PermissionsPropTypes from "./PermissionsPropTypes";

const AddPermissionCondition = ({ hidden, onClose, queryVariables }) => {
  const { loading, error, data } = useQuery(ResourceActionQuery, {
    variables: queryVariables,
  });
  const [createPermissionCondition] = useMutation(CreatePermissionCondition);
  const [conditionType, setConditionType] = useState();
  const [conditionConfig, setConditionConfig] = useState({});

  if (loading || error) {
    return null;
  }

  const {
    resourceAction: {
      conditionsAvailable,
      conditionTypesAvailable,
      permissionRules,
    },
  } = data;

  if (!conditionType && conditionTypesAvailable.length > 0) {
    setConditionType(conditionTypesAvailable[0]);
  }

  const ruleId = permissionRules[0]?.id || 0;

  const onSubmit = () => {
    createPermissionCondition({
      variables: { type: conditionType, conditionConfig, ruleId },
      refetchQueries: [
        {
          query: ResourceActionQuery,
          variables: queryVariables,
        },
      ],
      onCompleted: onClose,
    });
  };

  return (
    <Modal
      hidden={hidden}
      cancelText="Cancel"
      submitText="Add"
      onSubmit={onSubmit}
      onCancel={onClose}
      title="Add Condition to Permissions"
      data-testid="add-permission-condition-modal"
    >
      <form className="contains-inputs">
        <InputDropdown
          label="Condition Type"
          value={conditionType}
          values={conditionTypesAvailable}
          onChange={setConditionType}
          hideBlank={true}
        />
        <If condition={conditionType === "AttributeBasedCondition"}>
          <AttributeBasedConditionConfig
            onChange={setConditionConfig}
            configOptions={conditionsAvailable.AttributeBasedCondition}
          />
        </If>
        <If condition={conditionType === "AssignmentToRecordCondition"}>
          <AssignmentToRecordConditionConfig onChange={setConditionConfig} />
        </If>
        <If
          condition={conditionType === "SuperviseeAssignmentToRecordCondition"}
        >
          <AssignmentToRecordConditionConfig onChange={setConditionConfig} />
        </If>
      </form>
    </Modal>
  );
};

AddPermissionCondition.propTypes = {
  hidden: PropTypes.bool,
  onClose: PropTypes.func,
  queryVariables: PermissionsPropTypes.queryVariables,
};

export default AddPermissionCondition;
