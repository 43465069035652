import { useMutation } from "@apollo/client";
import {
  Surface,
  SurfaceForm,
  Icons,
  DetailsTable,
  InputDate,
  InputTextarea,
  Actions,
  Button,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import UpdateAppeal from "@graphql/mutations/UpdateAppeal";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import HearingSession from "./HearingSession";

const HearingDetails = ({ appeal, refetch }) => {
  const [editing, setEditing] = useState(false);
  const [addingNewSession, setAddingNewSession] = useState(false);
  const [updateAppeal] = useMutation(UpdateAppeal);

  const handleAppeal = preventDefault(() => {
    updateAppeal({
      variables: {
        appealId: appeal.id,
        preHearingNotes,
        preHearingDate,
      },
      onCompleted: () => {
        refetch();
        setEditing(false);
      },
    });
  });

  const setStateToAppeal = () => {
    const { preHearingDate, preHearingNotes } = appeal;
    setPreHearingNotes(preHearingNotes);
    setPreHearingDate(preHearingDate);
    setEditing(false);
  };

  useEffect(setStateToAppeal, [appeal]);
  const { hearingSessions } = appeal;
  const [preHearingDate, setPreHearingDate] = useState(appeal.preHearingDate);
  const [preHearingNotes, setPreHearingNotes] = useState(
    appeal.preHearingNotes
  );

  const hearingDisplayDetails = [
    {
      label: I18n.t("javascript.components.appeals.pre_hearing_date"),
      value: preHearingDate,
    },
    {
      label: I18n.t("javascript.components.appeals.pre_hearing_notes"),
      value: preHearingNotes,
    },
  ];
  hearingSessions.forEach((session, index) => {
    hearingDisplayDetails.push({
      label: I18n.t("javascript.components.appeals.hearing_date", {
        session: index + 1,
      }),
      value: session.hearingSessionDate,
    });
    hearingDisplayDetails.push({
      label: I18n.t("javascript.components.appeals.hearing_notes", {
        session: index + 1,
      }),
      value: session.hearingNotes,
    });
  });

  if (editing)
    return (
      <Surface title={I18n.t("javascript.components.appeals.hearing_details")}>
        <SurfaceForm
          title="Edit Hearing Details"
          actions={
            <Actions
              cancelAction={() => {
                setStateToAppeal();
              }}
            />
          }
          onSubmit={handleAppeal}
        >
          <InputDate
            label={I18n.t("javascript.components.appeals.pre_hearing_date")}
            name="preHearingDate"
            value={preHearingDate}
            onChange={setPreHearingDate}
            required={false}
          />
          <InputTextarea
            label={I18n.t("javascript.components.appeals.pre_hearing_notes")}
            name="preHearingNotes"
            value={preHearingNotes}
            onChange={setPreHearingNotes}
            required={false}
          />
        </SurfaceForm>
        {hearingSessions.map((session, index) => (
          <HearingSession
            key={index}
            existingHearingSession={session}
            sessionNumber={index + 1}
            appealId={appeal.id}
            refetchQueries={refetch}
          />
        ))}
        {addingNewSession ? (
          <HearingSession
            addingNewSession
            setAddingNewSession={setAddingNewSession}
            existingHearingSession={{}}
            sessionNumber={hearingSessions.length + 1}
            appealId={appeal.id}
            refetchQueries={refetch}
          />
        ) : (
          <Button variant="secondary" onClick={() => setAddingNewSession(true)}>
            {I18n.t("javascript.components.appeals.add_new_hearing_session")}
          </Button>
        )}
      </Surface>
    );

  return (
    <Surface
      title={I18n.t("javascript.components.appeals.hearing_details")}
      secondary={
        <Icons.Pencil
          description={I18n.t(
            "javascript.components.appeals.hearing_details_edit"
          )}
          onClick={() => {
            setEditing(true);
          }}
        />
      }
    >
      <DetailsTable details={hearingDisplayDetails} />
    </Surface>
  );
};

HearingDetails.propTypes = {
  appeal: PropTypes.shape({
    id: BintiPropTypes.ID,
    preHearingNotes: PropTypes.string,
    preHearingDate: PropTypes.date,
    hearingSessions: PropTypes.arrayOf(
      PropTypes.shape({
        hearingNotes: PropTypes.string,
        hearingSessionDate: PropTypes.date,
      })
    ),
  }),
  refetch: PropTypes.func,
};

export default HearingDetails;
