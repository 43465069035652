import classnames from "classnames";
import PropTypes from "prop-types";

import T from "@components/T";
import { Table, Tbody, Th, Thead, Tr } from "@components/reusable_ui/Table";

import { typeEq } from "@lib/graphqlHelpers";

import styles from "../requirements/Requirement.module.scss";
import SupportingDocumentsV2Row from "./SupportingDocumentsV2Row";

/**
 * Table for supporting documents v2 in the legacy documents page. The new requirements
 * page uses the more generic ApplicationRequirementTable instead of this one.
 */
const SupportingDocumentsV2Table = ({
  title,
  requirementFulfillments,
  application,
}) => (
  <div
    className={classnames(
      styles.breakoutTable,
      "test-application-requirement-table"
    )}
  >
    <div className={styles.tableTitle} data-testid="requirements-table-title">
      {title}
    </div>
    <Table>
      <Thead>
        <Tr>
          <Th>
            <T t="requirements.requirement_table.columns.title" />
          </Th>
          <Th>Status</Th>
          <Th>Document</Th>
          <Th>Mark N/A</Th>
          <Th>Received Paper Copy</Th>
          <Th>Upload or Drag &amp; Drop</Th>
        </Tr>
      </Thead>
      <Tbody>
        {requirementFulfillments.map(fulfillment => {
          if (typeEq("UploadTypeRequirement", fulfillment.requirement)) {
            return (
              <SupportingDocumentsV2Row
                key={fulfillment.id}
                {...{ fulfillment, application }}
              />
            );
          }

          return false;
        })}
      </Tbody>
    </Table>
  </div>
);

SupportingDocumentsV2Table.propTypes = {
  title: PropTypes.string.isRequired,
  application: PropTypes.object.isRequired,
  requirementFulfillments: PropTypes.array.isRequired,
  isEditable: PropTypes.bool.isRequired, // true if we should show an edit column
};

export default SupportingDocumentsV2Table;
