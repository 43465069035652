import PropTypes from "prop-types";

const DownloadPartialLink = ({ doc }) => (
  <a
    href={`/applications/${doc.applicationId}/documents/${doc.id}/partial`}
    target={`partial-${doc.id}`}
  >
    Download Incomplete PDF
  </a>
);

DownloadPartialLink.propTypes = {
  doc: PropTypes.object.isRequired,
};

export default DownloadPartialLink;
