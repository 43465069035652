const stringArgType = ({ name, required = false, description }) => ({
  [name]: {
    name,
    type: { name: "string", required },
    description,
  },
});
const booleanArgType = ({ name, required = false, description }) => ({
  [name]: {
    name,
    type: { name: "boolean", required },
    description,
  },
});
const functionArgType = ({ name, required = false, description }) => ({
  [name]: {
    name,
    type: { name: "function", required },
    description,
  },
});
const choiceArgType = ({ name, required = false, description, choices }) => ({
  [name]: {
    name,
    type: { required },
    options: choices,
    description,
  },
});

/**
 * Since we're sharing PropTypes between components using `{...propTypes}` operators,
 * Storybook can no longer statically infer the documentation for inputs' common props.
 *
 * (It does a really shallow text analysis of the source file to produce documentation)
 *
 * This exports Storybook ArgTypes[1] which instruct Storybook what the arguments for
 * stories can be.  They're 1-to-1 with the prop types in `inputCommonPropTypes.js`.
 *
 * [1]: https://storybook.js.org/docs/react/api/argtypes
 */
export default {
  label: {
    name: "label",
    type: { name: "string", required: true },
    description: "The text to use as the primary label for this input",
  },
  ...stringArgType({
    name: "description",
    description:
      "Optional description to give the user more context about how to " +
      "respond to this input",
  }),
  ...stringArgType({
    name: "error",
    description: "Optional error text that will be displayed to the user",
  }),
  ...stringArgType({
    name: "id",
    description:
      "HTML ID for this input.  **Do not use for testing or CSS**. " +
      "Usually you can leave this blank and the input will generate one.",
  }),
  ...stringArgType({
    name: "id",
    description:
      "HTML ID for this input.  **Do not use for testing or CSS**. " +
      "Usually you can leave this blank and the input will generate one.",
  }),
  ...stringArgType({
    name: "name",
    description:
      "HTML `name` for this input, only necessary when POSTing forms and usually " +
      "blank in GraphQL-powered forms.",
  }),
  ...booleanArgType({
    name: "required",
    description:
      "Whether or not this input is required in order to submit the form",
  }),
  ...booleanArgType({
    name: "disabled",
    description:
      "Disable the input, preventing user interaction.  " +
      "**Note**: When you disable an input, its value will not be sent along with form POSTs!",
  }),
  ...booleanArgType({
    name: "hideLabel",
    description:
      "Optionally hide the label of this input for sighted users - " +
      "use this sparingly and only in contexts where the input's purpose is obvious",
  }),
  ...stringArgType({
    name: "data-testid",
    description:
      "For when you need direct access to the input element for testing. " +
      "Please use sparingly and favor semantic finder methods where possible.",
  }),
  ...booleanArgType({
    name: "hidden",
    description:
      "Whether or not the input should be hidden but still present in the DOM",
  }),
};

export const inputGroupCommonArgTypes = {
  ...choiceArgType({
    name: "orientation",
    description: "To indicate orientation of the options",
    choices: ["column", "row"],
  }),
};

export const textualInputArgTypes = {
  ...stringArgType({
    name: "value",
    description:
      "The initial value (uncontrolled) of this input, or the current value (controlled).",
  }),
  ...functionArgType({
    name: "onChange",
    description:
      "`onChange` is invoked with the text value as the only argument ",
  }),
  ...stringArgType({
    name: "placeholder",
    description:
      "A sample value placeholder for users to see what kind of input we expect",
  }),
  ...stringArgType({
    name: "maxCharacters",
    description:
      "The maximum number of characters a user can enter before this field is invalid",
  }),
};
