import { ContainsTrustedHTML } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import Toast from "../toast/ToastComponent";
import { TOAST_TYPES } from "../toast/toast";

const { t } = translationWithRoot("toast");

const legacyToastTypeMap = {
  notice: "success",
  alert: "notable",
  error: "negative",
};

/**
 * In order to create Toasts using the Rails `flash[]` API, we render one of these
 * components for each flash message at page load time, which will launch a toast as soon
 * as the JS is loaded.
 *
 * Because `flash[]` only supports simple string messages, these kinds of toasts will have
 * generic titles like "Info", "Success", etc (in these cases, there's nothing to pass
 * to `title`).  If this becomes an issue, we can write code in `RailsNotifications`
 * to handle objects as the value for `flash[]` as well as plain strings.
 *
 * To use Toasts in Rails, you can do:
 *   * `flash[:informative]`, `flash[:success]`, `flash[:notable]`, and `flash[:negative]`
 *     * These are the ideal ways to invoke Toasts in Rails.  There are more options for
 *       various kinds of messages and it's more clear which `:value` will result in which Toast.
 *   * `flash[:alert]` and `flash[:notice]`
 *     * ActiveAdmin implicitly adds flashes on `:alert` and `:notice`, so these are still
 *       supported and you may need to override them occasionally.
 *   * `redirect_to(some_url, { informative: "FYI" })` or `{success: "Success"}`/`{notable: "Warning"}`, etc
 *     * Same note as above about `alert` and `notice` - you may need to do `{notice: "Success"}`
 *       instead of `{success: "Success"}` if you want to override the default message.
 *   * You can also render a `<RailsToast>` directly in an ERB file if the situation calls for it.
 *
 * This component differs from Toast in that it:
 *   * Accepts the legacy `:notice` / `:error` flash message types and turns them into known Toasts
 *   * Chooses a default title as described above
 *   * Trusts HTML that we've put in `flash[]`
 */
const RailsToast = ({ type: flashType, title, message: htmlMessage }) => {
  const type = legacyToastTypeMap[flashType] || flashType;
  return (
    <Fragment>
      <Toast
        type={type}
        title={title || t(`status.${type}`)}
        message={
          <ContainsTrustedHTML
            trustedSource="Rails calls to flash[]"
            html={htmlMessage}
          />
        }
      />
    </Fragment>
  );
};

RailsToast.propTypes = {
  /** The Toast's title; defaults to "Success|Error|..." if not provided */
  title: PropTypes.string,
  /** The Toast's message content */
  message: PropTypes.node.isRequired,
  /** What kind of Toast this is */
  type: PropTypes.oneOf(TOAST_TYPES.concat(Object.keys(legacyToastTypeMap)))
    .isRequired,
};

export default RailsToast;
