import PropTypes from "prop-types";

import ExpandableContent from "@components/reusable_ui/ExpandableContent";

const MAX_NON_TRUNCATED_LENGTH = 6;

const CharacteristicsOpenTo = ({ listOfCharacteristicsOpenTo }) => {
  if (!listOfCharacteristicsOpenTo) return null;

  const listItems = listOfCharacteristicsOpenTo.map(characteristic => (
    <li key={characteristic}>{characteristic}</li>
  ));

  if (listOfCharacteristicsOpenTo.length <= MAX_NON_TRUNCATED_LENGTH) {
    return <ul>{listItems}</ul>;
  }

  return (
    <ExpandableContent>
      <ul>{listItems}</ul>
    </ExpandableContent>
  );
};

CharacteristicsOpenTo.propTypes = {
  listOfCharacteristicsOpenTo: PropTypes.array,
};

export default CharacteristicsOpenTo;
