import { debounce } from "lodash";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function () {
  // ######################
  // Toggle visibility of Co-applicant section
  // ######################

  const on_number_of_applicants_change = function () {
    const $co_applicant_wrapper = $("#co-applicant-wrapper");

    if ($(this).val() === "Two") {
      $co_applicant_wrapper.show();
      return $co_applicant_wrapper.find(":input").attr("disabled", null);
    } else {
      $co_applicant_wrapper.hide();
      return $co_applicant_wrapper.find(":input").attr("disabled", "disabled");
    }
  };

  const suggestApplicantDuplicates = (role, messageSelector) =>
    function () {
      const firstName = $(`.js-${role}-duplicates-fname`).val();
      const middleName = $(`.js-${role}-duplicates-mname`).val();
      const lastName = $(`.js-${role}-duplicates-lname`).val();
      const fullName = [firstName, middleName, lastName]
        .filter(String)
        .join(" ");

      const $message = $(messageSelector);
      let userHtml;
      $message.text("");
      if (fullName.length >= 6) {
        return $.ajax({
          url: "/admin/end_users/applicant_name_match",
          data: { term: fullName },
        }).done(function (collection) {
          $message.text("");
          if (collection.results.length > 0) {
            userHtml = collection.results
              .map(user =>
                $("<a></a>").attr("href", user.url).text(user.name).outerHTML()
              )
              .join("<span>, </span>");
            $message.append(
              "We found a similar name for an applicant in Binti: "
            );
            return $message.append($.parseHTML(userHtml));
          }
        });
      }
    };

  $.fn.outerHTML = function (s) {
    if (s) {
      return this.before(s).remove();
    } else {
      return $("<p>").append(this.eq(0).clone()).html();
    }
  };

  $("input[name='intake_form[number_of_applicants]']").change(
    on_number_of_applicants_change
  );
  const $input = $(
    ".js-applicant-duplicates-fname, .js-applicant-duplicates-mname, .js-applicant-duplicates-lname"
  );
  const $coApplicantInput = $(
    ".js-co-applicant-duplicates-fname, .js-co-applicant-duplicates-mname, .js-co-applicant-duplicates-lname"
  );
  $(".js-applicant-duplicates-lname").after(
    '<p class="js-applicant-duplicates-message inline-hints"></p>'
  );
  $(".js-co-applicant-duplicates-lname").after(
    '<p class="js-co-applicant-duplicates-message inline-hints"></p>'
  );

  $input.on(
    "keyup",
    debounce(
      suggestApplicantDuplicates(
        "applicant",
        ".js-applicant-duplicates-message"
      ),
      100
    )
  );
  $coApplicantInput.on(
    "keyup",
    debounce(
      suggestApplicantDuplicates(
        "co-applicant",
        ".js-co-applicant-duplicates-message"
      ),
      100
    )
  );

  // if the page takes a while to reload, disable forms so people don't
  // fill in inputs that will just be erased on reload
  return $("#intake_form_application_template_id").on("change", () =>
    $("fieldset,input,select").prop("disabled", true)
  );
});
