import { Button, Flex, Text, LoadingOverlay } from "@heart/components";
import { compact } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useMemo, useState } from "react";

import { translationWithRoot } from "@components/T";

import ConfirmationModal from "./ConfirmationModal";
import LinkAllPotentialConnectionsModal from "./LinkAllPotentialConnectionsModal";
import PotentialKinTable from "./PotentialKinTable";
import ResultModalNotice from "./ResultModalNotice";

const { t } = translationWithRoot(
  "family_finding.potential_kin_search.potential_kin"
);
const { t: commonT } = translationWithRoot(
  "family_finding.potential_kin_search.common"
);

const PotentialKin = ({
  createClearPersonReport,
  reportData,
  comprehensiveReportPending,
  mutationLoading,
  comprehensiveReportLoaded,
}) => {
  const [hideConfirmationModal, setHideConfirmationModal] = useState(true);
  const [modalTypeVisibility, setModalTypeVisibility] = useState();
  const [childAgencyHumans, setChildAgencyHumans] = useState([]);
  const [linkConnectionResults, setLinkConnectionResults] = useState([]);

  const potentialKin = useMemo(
    () =>
      compact([
        ...(reportData.potentialRelatives || []),
        ...(reportData.potentialAssociates || []),
      ]),
    [reportData.potentialRelatives, reportData.potentialAssociates]
  );
  return (
    <Fragment>
      <ConfirmationModal
        submitting={mutationLoading}
        onSubmit={() => {
          createClearPersonReport();
          setHideConfirmationModal(true);
        }}
        onCancel={() => setHideConfirmationModal(true)}
        hidden={hideConfirmationModal}
      />
      <If condition={comprehensiveReportPending}>
        <Flex gap="200" justify="space-between">
          <Text textStyle="supporting-150">
            {t("run_comprehensive_search_call_to_action")}
          </Text>
          <Button
            submitting={mutationLoading}
            onClick={() => setHideConfirmationModal(false)}
          >
            {commonT("run_comprehensive_search")}
          </Button>
        </Flex>
      </If>
      <If condition={!comprehensiveReportPending}>
        <LoadingOverlay active={!comprehensiveReportLoaded}>
          <Flex column gap="200">
            <Flex justify="end">
              <Button
                onClick={() => setModalTypeVisibility("linkAll")}
                variant="secondary"
              >
                {t("link_all_potential_kin_as_potential_connections")}
              </Button>
              <If condition={reportData}>
                <LinkAllPotentialConnectionsModal
                  hidden={modalTypeVisibility !== "linkAll"}
                  setModalTypeVisibility={setModalTypeVisibility}
                  onCancel={() => setModalTypeVisibility(undefined)}
                  childAgencyHumans={childAgencyHumans}
                  setChildAgencyHumans={setChildAgencyHumans}
                  potentialKin={potentialKin}
                  setLinkConnectionResults={setLinkConnectionResults}
                />
                <ResultModalNotice
                  hidden={modalTypeVisibility !== "success"}
                  onClose={() => setModalTypeVisibility(undefined)}
                  childAgencyHumans={childAgencyHumans}
                  linkConnectionResults={linkConnectionResults}
                />
              </If>
            </Flex>
            <PotentialKinTable
              dataLoaded={comprehensiveReportLoaded}
              caption={t("potential_relatives")}
              potentialKin={reportData?.potentialRelatives || []}
            />
            <PotentialKinTable
              dataLoaded={comprehensiveReportLoaded}
              caption={t("potential_fictive_kin")}
              potentialKin={reportData?.potentialAssociates || []}
            />
          </Flex>
        </LoadingOverlay>
      </If>
    </Fragment>
  );
};

PotentialKin.propTypes = {
  // Function to run CreateClearPersonReport mutation
  createClearPersonReport: PropTypes.func.isRequired,
  // Data returned from the comprehensive Person Report query
  reportData: PropTypes.object.isRequired,
  // True if the ClearPersonReport (aka Comprehensive Search) record has not been created in the DB
  comprehensiveReportPending: PropTypes.bool.isRequired,
  // True if CreateClearPersonReport is currently loading
  mutationLoading: PropTypes.bool,
  // True if Clear has generated the report and we've finished polling for the data
  comprehensiveReportLoaded: PropTypes.bool,
};

export default PotentialKin;
