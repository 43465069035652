import { gql, useQuery } from "@apollo/client";
import { Breadcrumbs, Layout } from "@heart/components";
import { Spinner } from "@heart/components/icon/Icon";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment } from "react";

import AppealDetails from "./AppealDetails";
import FinalAppealOutcome from "./FinalAppealOutcome";
import HearingDetails from "./HearingDetails";

const appealInformation = gql`
  query Appeal($appealId: ID!) {
    appeal(appealId: $appealId) {
      id
      representative
      appealStartedDate
      approvingAgencyNotifiedDate
      docsSentToAppealsBodyDate
      appealsBodyNotifiedDate
      appealResolvedDate
      appealOutcome
      conditions
      appealOutcomeNotes
      preHearingNotes
      preHearingDate
      appealLocation
      hearingSessions {
        id
        hearingNotes
        hearingSessionDate
      }
    }
  }
`;

const Appeal = ({
  appealId,
  application,
  representatives,
  appealLocations,
  applicationType,
  appealOutcomes,
  allowDelete,
}) => {
  const { data, loading, refetch } = useQuery(appealInformation, {
    variables: { appealId: appealId },
  });

  if (loading) {
    return <Spinner />;
  }
  return (
    <Layout
      breadcrumbs={
        <Breadcrumbs
          pages={[
            {
              label: I18n.t("javascript.components.appeals.application_status"),
              href: `/admin/applications/${application.id}/change_status`,
            },
            {
              label: I18n.t(
                "javascript.components.appeals.license_decision_appeals"
              ),
              href: `/applications/${application.id}/appeals/${appealId}`,
            },
          ]}
        />
      }
      main={{
        content: (
          <Fragment>
            <AppealDetails
              appeal={data.appeal}
              refetch={refetch}
              application={application}
              representatives={representatives}
              appealLocations={appealLocations}
              applicationType={applicationType}
              showDeleteIcon={allowDelete}
            />
            <HearingDetails
              appeal={data.appeal}
              refetch={refetch}
              application={application}
            />
            <FinalAppealOutcome
              appeal={data.appeal}
              refetch={refetch}
              application={application}
              appealOutcomes={appealOutcomes}
            />
          </Fragment>
        ),
        narrow: true,
      }}
    />
  );
};

Appeal.propTypes = {
  appealId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  application: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  appealOutcomes: PropTypes.array,
  representatives: PropTypes.array,
  appealLocations: PropTypes.array,
  applicationType: PropTypes.string,
  allowDelete: PropTypes.bool,
};

export default Appeal;
