import { Button, Text, InputText, InputHidden } from "@heart/components";
import { BasicInputLayout } from "@heart/components/inputs/common";
import { useToggle } from "@react-hookz/web";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState } from "react";

import AceTextarea from "@components/inputs/AceTextarea";

const defaultWelcomeEmailSubject = I18n.t(
  "mailers.devise_mailer.applicant_entered_by_caseworker.subject",
  { applicant_name: "APPLICANT_NAME" }
);

const defaultWelcomeEmailTemplate = `<div style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 40px; max-width: 600px;">
    <p>${I18n.t("views.common.mailer_greeting", {
      name: "APPLICANT_NAME",
    })}</p>
    <p>${I18n.t(
      "views.reference_notifications_mailer.applicant_entered_by_caseworker_welcome.account_created",
      { agency_name: "AGENCY_NAME", applicant_email: "APPLICANT_EMAIL" }
    )}<p>
    <div style="display: flex; justify-content: center;">
      <a
        class="btn-primary"
        href="SET_PASSWORD_LINK"
        style="color: #fff; text-decoration: none; background-color: #1b74e6; line-height: 2; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 3px; text-transform: capitalize; margin: 0; padding: 0; border-color: #1b74e6; border-style: solid; border-width: 10px 30px;"
        target="_blank"
      >
        ${I18n.t("views.devise.common.set_my_password")}
      </a>
    </div>
    <p>
      ${I18n.t("views.layouts.admin_notifications_mailer.valediction_html", {
        from_agency: "AGENCY_NAME",
      })}
    </p>
    <p>
      ${I18n.t(
        "views.layouts.admin_notifications_mailer.in_support_of_agency_help_no_workers_html",
        { agency_name: "AGENCY_NAME" }
      )}
    </p>
  </div>`;

const CustomWelcomeEmailInput = ({
  subject,
  body,
  allowableTags,
  allowableVariables,
  toggleText,
}) => {
  const [showForm, toggleShowForm] = useToggle(
    Boolean(subject.template || body.template)
  );
  const [subjectTemplate, setSubjectTemplate] = useState(
    subject.template || defaultWelcomeEmailSubject
  );

  return (
    <React.Fragment>
      <If condition={showForm}>
        <fieldset className="inputs">
          <ol>
            <li>
              <InputText
                label={I18n.t("admin.agencies.subject")}
                name={`agency[${subject.fieldName}]`}
                value={subjectTemplate}
                onChange={setSubjectTemplate}
              />
            </li>
            <li>
              {/** TODO ENG-13115 AceTextarea is not officially a Heart input yet */}
              <BasicInputLayout
                label={I18n.t("admin.agencies.message_content")}
                inputComponent={inputProps => (
                  <AceTextarea
                    {...inputProps}
                    mode="html"
                    name={`agency[${body.fieldName}]`}
                    value={body.template || defaultWelcomeEmailTemplate}
                  />
                )}
              />
              <Text textStyle="supporting-50">
                {I18n.t("admin.agencies.tags_allowed")} {allowableTags}.
              </Text>
              <br />
              <Text textStyle="supporting-50">
                {`${I18n.t(
                  "admin.agencies.variables_allowed"
                )} ${allowableVariables}`}
              </Text>
            </li>
          </ol>
        </fieldset>
      </If>
      <If condition={!showForm}>
        <InputHidden value="" name={`agency[${subject.fieldName}]`} />
        <InputHidden value="" name={`agency[${body.fieldName}]`} />
      </If>
      <Button variant="secondary" onClick={toggleShowForm}>
        {showForm ? I18n.t("views.common.cancel") : toggleText}
      </Button>
    </React.Fragment>
  );
};

CustomWelcomeEmailInput.propTypes = {
  subject: PropTypes.shape({
    template: PropTypes.string,
    fieldName: PropTypes.string,
  }),
  body: PropTypes.shape({
    template: PropTypes.string,
    fieldName: PropTypes.string,
  }),
  allowableTags: PropTypes.string,
  allowableVariables: PropTypes.string,
  toggleText: PropTypes.string,
};

export default CustomWelcomeEmailInput;
