import { InputTextarea } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import styles from "./AvailabilityRequest.module.scss";

const { t } = translationWithRoot("availability_request.email_draft");

const EmailDraft = ({ emailText, setEmailText }) => (
  <div className={styles.inputContainer}>
    <InputTextarea
      label={t("label")}
      value={emailText}
      rows={15}
      onChange={setEmailText}
      className={styles.nonResizableTextareaInput}
    />
  </div>
);

EmailDraft.propTypes = {
  emailText: PropTypes.string.isRequired,
  setEmailText: PropTypes.func.isRequired,
};

export default EmailDraft;
