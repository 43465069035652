import { useQuery, useMutation } from "@apollo/client";
import {
  Actions,
  InputDate,
  InputFilterable,
  InputText,
  LoadingOverlay,
  PageContainer,
  SurfaceForm,
} from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import T from "@components/T";

import CreateLevelOfCareAssignment from "@graphql/mutations/CreateLevelOfCareAssignment.graphql";
import UpdateLevelOfCareAssignment from "@graphql/mutations/UpdateLevelOfCareAssignment.graphql";
import LevelOfCareAssignmentQuery from "@graphql/queries/LevelOfCareAssignment.graphql";
import LevelOfCareAssignmentBeforeAfter from "@graphql/queries/LevelOfCareAssignmentBeforeAfter.graphql";
import LevelsOfCareQuery from "@graphql/queries/LevelsOfCare.graphql";

import preventDefault from "@lib/preventDefault";

import styles from "./UpdateLevelOfCare.module.scss";

// TODO: https://binti.atlassian.net/browse/ENG-6086 (Add to storybook)
const UpdateLevelOfCare = ({
  childId,
  levelOfCareAssignmentId,
  onSuccessRedirectTo,
}) => {
  const {
    loading: loadingLevelOfCareQuery,
    data: { currentLevelOfCareAssignment = null, levelsOfCare = [] } = {},
  } = useQuery(LevelsOfCareQuery, {
    variables: { childId },
  });

  const { loading: loadingAssignmentQuery, error: assignmentQueryError } =
    useQuery(LevelOfCareAssignmentQuery, {
      variables: { levelOfCareAssignmentId },
      skip: !levelOfCareAssignmentId,
      onCompleted: ({
        levelOfCareAssignment: { levelOfCareId, startDate },
      }) => {
        setLevelOfCareId(levelOfCareId);
        setStartDate(startDate);
      },
    });

  const {
    loading: loadingBeforeAfterQuery,
    data: {
      beforeAfterAssignments: [beforeAssignment, afterAssignment] = [],
    } = {},
  } = useQuery(LevelOfCareAssignmentBeforeAfter, {
    variables: { levelOfCareAssignmentId, childId },
    skip: !levelOfCareAssignmentId,
  });

  const [createLevelOfCareAssignment, { loading: creating }] = useMutation(
    CreateLevelOfCareAssignment,
    {
      onCompleted: () => {
        redirect();
      },
    }
  );

  const [updateLevelOfCareAssignment, { loading: updating }] = useMutation(
    UpdateLevelOfCareAssignment,
    {
      onCompleted: () => {
        redirect();
      },
    }
  );

  const redirect = () => {
    window.location.replace(onSuccessRedirectTo);
  };

  const [levelOfCareId, setLevelOfCareId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [formErrors, setFormErrors] = useState([]);

  const onSubmit = preventDefault(() => {
    setFormErrors([]);
    if (levelOfCareAssignmentId) {
      onUpdate();
    } else {
      onCreate();
    }
  });

  const onDateChange = value => {
    setFormErrors([]);
    setStartDate(value);
  };

  const onCreate = () => {
    if (validateStartDateOnCreate()) {
      createLevelOfCareAssignment({
        variables: {
          childId: childId,
          levelOfCareId: levelOfCareId,
          startDate: startDate,
        },
      });
    }
  };

  const onUpdate = () => {
    if (validateStartDateOnUpdate()) {
      updateLevelOfCareAssignment({
        variables: {
          levelOfCareId: levelOfCareId,
          startDate: startDate,
          levelOfCareAssignmentId: levelOfCareAssignmentId,
        },
      });
    }
  };

  const validateStartDateOnUpdate = () => {
    if (
      startDate &&
      // default to start date if before and/or after dates are missing
      // so that Date.parse does not throw an error
      (Date.parse(startDate) <
        Date.parse(beforeAssignment?.startDate || startDate) ||
        Date.parse(startDate) >
          Date.parse(afterAssignment?.startDate || startDate))
    ) {
      setFormErrors(errors => [
        ...errors,
        I18n.t(
          "activerecord.errors.models.level_of_care_assignment.edit_start_date"
        ),
      ]);
      return false;
    }
    return true;
  };

  const validateStartDateOnCreate = () => {
    if (
      startDate &&
      currentLevelOfCareAssignment &&
      Date.parse(startDate) < Date.parse(currentLevelOfCareAssignment.startDate)
    ) {
      setFormErrors(errors => [
        ...errors,
        I18n.t(
          "activerecord.errors.models.level_of_care_assignment.start_date"
        ),
      ]);
      return false;
    }
    return true;
  };

  const getLevelOfCareLabel = id => {
    if (id) {
      return levelsOfCare.filter(level => level.id === id)[0].name;
    }
    return "";
  };

  const setLevelOfCareOptions = () => {
    let filteredList;
    if (levelOfCareAssignmentId) {
      // onUpdate we do not want the edited level of care to be equal
      // to the previous one or the one after so we remove those options from the list,
      // if they exist.
      filteredList = levelsOfCare.filter(
        level =>
          level.id !== beforeAssignment?.levelOfCareId &&
          level.id !== afterAssignment?.levelOfCareId
      );
    } else {
      // onCreate we do not want the edited level of care to be equal
      // to the previous one. We remove this option if it exists.
      filteredList = levelsOfCare.filter(
        level => level.id !== currentLevelOfCareAssignment?.levelOfCareId
      );
    }
    return filteredList.map(level => ({
      value: level.id,
      label: level.name,
    }));
  };

  return (
    <PageContainer>
      <LoadingOverlay
        active={
          loadingLevelOfCareQuery ||
          loadingAssignmentQuery ||
          loadingBeforeAfterQuery ||
          creating ||
          updating
        }
        backgroundTransparent={
          loadingLevelOfCareQuery ||
          loadingAssignmentQuery ||
          loadingBeforeAfterQuery
        }
      >
        <div className={styles.errors}>
          {formErrors.map((error, index) => (
            <div key={`${error}_${index}`}>{error}</div>
          ))}
          {assignmentQueryError &&
            assignmentQueryError.graphQLErrors.map(({ message }, index) => (
              <div key={`${index}`}>{message}</div>
            ))}
        </div>
        {!loadingLevelOfCareQuery &&
          !loadingAssignmentQuery &&
          !loadingBeforeAfterQuery && (
            <SurfaceForm
              title={<T t="level_of_care_assignment.new_or_edit" />}
              onSubmit={onSubmit}
              actions={
                <Actions
                  primaryDisabled={
                    creating || updating || !(startDate && levelOfCareId)
                  }
                />
              }
            >
              {!levelOfCareAssignmentId && currentLevelOfCareAssignment && (
                <InputText
                  label={<T t="level_of_care_assignment.current" />}
                  name="currentLevelsOfCareId"
                  disabled
                  data-testid="current-level-of-care-input"
                  value={
                    levelsOfCare.filter(
                      ({ id }) =>
                        id === currentLevelOfCareAssignment.levelOfCareId
                    )[0].name
                  }
                />
              )}
              <InputFilterable
                label={
                  levelOfCareAssignmentId ? (
                    <T t="level_of_care_assignment.edit" />
                  ) : (
                    <T t="level_of_care_assignment.new" />
                  )
                }
                required
                name="levelsOfCareId"
                data-testid="current-level-of-care-input"
                value={{
                  label: getLevelOfCareLabel(levelOfCareId),
                  value: levelOfCareId,
                }}
                onChange={target => setLevelOfCareId(target.value)}
                values={setLevelOfCareOptions()}
              />
              <InputDate
                required
                value={startDate}
                onChange={onDateChange}
                label={<T t="level_of_care_assignment.date" />}
                name="startDate"
              />
            </SurfaceForm>
          )}
      </LoadingOverlay>
    </PageContainer>
  );
};
UpdateLevelOfCare.propTypes = {
  childId: PropTypes.string.isRequired,
  levelOfCareAssignmentId: PropTypes.string,
  onSuccessRedirectTo: PropTypes.string,
};

export default UpdateLevelOfCare;
