import { InputRadioGroup, InputFilterable, InputText } from "@heart/components";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("views.common.protected_tribe", {
  escapeJavascriptRoot: true,
});

/** A component to ask for ICWA details
 *
 * This can be used as either an uncontrolled Active Admin form input or
 * as a controlled input for React & GraphQL powered forms.
 */
const ProtectedTribes = ({
  enrolledMemberQuestionLabel,
  tribeOptions,
  isRequired = false,
  isAfcarsRequired = false,
  // ⬇ provide these props for Active Admin ⬇
  tribesInputName,
  protectedTribeInputName,
  indianBloodCertNumberInputName,
  values,
  // ⬇ provide this prop for React/GraphQL ⬇
  onChange,
}) => {
  const [tribes, setTribes] = useState(values || {});

  const setValueForField = inputId => newSectionValue =>
    inputId === "isProtectedTribeMember" && !newSectionValue
      ? setTribes({ [inputId]: newSectionValue })
      : setTribes({
          ...tribes,
          [inputId]: newSectionValue,
        });

  useEffect(() => {
    if (onChange) onChange(tribes);
  }, [onChange, tribes]);

  return (
    <div className="contains-inputs">
      <InputRadioGroup
        label={enrolledMemberQuestionLabel}
        onChange={setValueForField("isProtectedTribeMember")}
        required={isRequired}
        afcarsRequired={isAfcarsRequired}
        name={protectedTribeInputName}
        value={tribes.isProtectedTribeMember}
        values={[
          { label: t("answer_yes"), value: true },
          { label: t("answer_no"), value: false },
        ]}
      />
      <InputFilterable
        label={t("tribe_name")}
        afcarsRequired={isAfcarsRequired}
        isMulti
        hidden={!tribes.isProtectedTribeMember}
        name={tribesInputName}
        onChange={setValueForField("tribeNames")}
        options={tribeOptions.map(tribe => ({
          label: tribe,
          value: tribe,
        }))}
        value={tribes.tribeNames || []}
      />
      <InputText
        label={t("indian_blood_certificate")}
        type="text"
        hidden={!tribes.isProtectedTribeMember}
        name={indianBloodCertNumberInputName}
        value={tribes.tribeIdentification}
        onChange={setValueForField("tribeIdentification")}
      />
    </div>
  );
};

ProtectedTribes.propTypes = {
  /** The label for the first question */
  enrolledMemberQuestionLabel: PropTypes.string,
  /** A list of tribes with which to populate the dropdown */
  tribeOptions: PropTypes.array,
  /** Whether these inputs should be required */
  isRequired: PropTypes.bool,
  /** Whether these inputs are required for AFCARS completeness */
  isAfcarsRequired: PropTypes.bool,
  /** Name of field, used for ActiveAdmin usecases */
  tribesInputName: PropTypes.string,
  /** Name of field, used for ActiveAdmin usecases */
  protectedTribeInputName: PropTypes.string,
  /** Name of field, used for ActiveAdmin usecases */
  indianBloodCertNumberInputName: PropTypes.string,
  /** Values for the inputs, used in conjunction with an onChange to make
   * this a component **controlled** by the component that renders ProtectedTribes
   */
  values: PropTypes.shape({
    isProtectedTribeMember: PropTypes.bool,
    tribeNames: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    tribeIdentification: PropTypes.string,
  }),
  /** A function that takes in the name of the field being updated and returns a function
   * that will be called with the new value when the field is updated. Used when this
   * component is **controlled** by the component that renders ProtectedTribes
   */
  onChange: PropTypes.func,
};

export default ProtectedTribes;
