import { Button } from "@heart/components";
import I18n from "i18n-js";

/** Just opens the print dialog. We used to have a button that
 * linked to a backend-rendered PDF but we replaced it with this. */
const ViewAsPdf = () => (
  <Button onClick={() => window.print()} variant="secondary">
    {I18n.t("admin.child.view_as_pdf")}
  </Button>
);

export default ViewAsPdf;
