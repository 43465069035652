import { Breadcrumbs, PageContainer } from "@heart/components";
import PropTypes from "prop-types";
import { useEffect } from "react";

import BintiPropTypes from "@lib/BintiPropTypes";

import ResourceActionsTable from "./ResourceActionsTable";
import RoleInfo from "./RoleInfo";
import UsersInRoleTable from "./UsersInRoleTable";
import { viewOnlyUserVar } from "./permissionsReactiveVars";

const Role = ({ roleId, permissionsViewOnly }) => {
  useEffect(() => viewOnlyUserVar(permissionsViewOnly), [permissionsViewOnly]);

  // Is there a key that depends on the slug? Or the name? Could be causing a partial
  // Re-render
  return (
    <PageContainer>
      <Breadcrumbs
        pages={[
          { label: "Roles", href: "/roles" },
          { label: "Role", href: "" },
        ]}
      />
      <RoleInfo roleId={roleId} />
      <ResourceActionsTable
        queryVariables={{ roleId, permissionRuleSetId: null }}
      />
      <UsersInRoleTable roleId={roleId} />
    </PageContainer>
  );
};

Role.propTypes = {
  roleId: BintiPropTypes.ID,
  permissionsViewOnly: PropTypes.bool,
};

export default Role;
