import { gql, useQuery } from "@apollo/client";
import {
  Flex,
  InputCheckbox,
  InputFilterable,
  LoadingOverlay,
} from "@heart/components";
import { uniq } from "lodash";
import PropTypes from "prop-types";

import BintiPropTypes from "@lib/BintiPropTypes";

import ScheduleSpecificationForm from "./ScheduleSpecificationForm";

// Name formats are required for ActiveAdmin compatibility

const FOREIGN_KEY_FOR_TEMPLATE_TYPE = {
  ScheduledUploadTypeRequirementTemplate: "uploadTypeId",
  ScheduledFormRequirementTemplate: "formId",
};
const DISPLAY_NAME_FOR_TEMPLATE_TYPE = {
  ScheduledUploadTypeRequirementTemplate: "Upload Type",
  ScheduledFormRequirementTemplate: "Form",
};

/**
 * Allows you to configure the schedule for a requirement template.
 * Case Management Setup > Schedules > Edit > "Scheduled Requirement Templates"
 */
const RequirementTemplateForm = ({
  agencyId,
  templateType,
  formState,
  setFormAttribute,
}) => {
  const { data, loading } = useQuery(
    gql`
      query RequirementTemplateForm($agencyId: ID!, $templateType: String!) {
        scheduleFormData(agencyId: $agencyId, templateType: $templateType) {
          startDateEventOptions {
            label
            value
          }
          associationOptions {
            label
            value
          }
        }

        featureFlag(flag: "ff_resource_parent_portal_05_2024")
      }
    `,
    {
      variables: {
        agencyId,
        templateType,
      },
    }
  );

  const { associationOptions } = data?.scheduleFormData || {
    associationOptions: [],
  };

  const foreignKey = FOREIGN_KEY_FOR_TEMPLATE_TYPE[formState.type];
  const selectedOption = associationOptions.find(
    option => option.value === formState[foreignKey]
  );

  return (
    <LoadingOverlay active={loading}>
      {`[${DISPLAY_NAME_FOR_TEMPLATE_TYPE[formState.type]}] ${
        selectedOption?.label || "New"
      }`}
      <Flex column>
        <InputFilterable
          label={DISPLAY_NAME_FOR_TEMPLATE_TYPE[formState.type]}
          values={associationOptions}
          value={selectedOption}
          onChange={({ value }) => setFormAttribute(foreignKey)(value)}
        />
        <ScheduleSpecificationForm
          agencyId={agencyId}
          templateType={formState.type}
          formState={formState.specification}
          setFormAttribute={attr => value => {
            setFormAttribute("specification")({
              ...formState.specification,
              [attr]: value,
            });
          }}
        />
        {/* Move this conditional logic into a resolver/service */}
        <If
          condition={
            // As of writing, this feature is not available for ScheduledFormRequirement
            // forms. Regardless of the feature flag being on or off.
            formState.requirementType !== "ScheduledFormRequirement" &&
            data?.featureFlag
          }
        >
          <InputCheckbox
            value={Boolean(formState.fulfillableBy?.includes("caregiver"))}
            onChange={setFormAttribute("caregiversCanUpload")}
            label="Caregivers can upload"
          />
        </If>
      </Flex>
    </LoadingOverlay>
  );
};

RequirementTemplateForm.propTypes = {
  agencyId: BintiPropTypes.ID,
  templateType: PropTypes.string,
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
};

export default RequirementTemplateForm;
