import { Breadcrumbs } from "@heart/components";
import { last } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import T from "@components/T";
import DraftContainer from "@components/availability_request/DraftContainer";
import PreviewContainer from "@components/availability_request/PreviewContainer";

import {
  defaultEmailSignature,
  formDefaultEmailTextSiblingGroup,
  formDefaultEmailTextOneChild,
  formDefaultEmailTextLearnMore,
  formDefaultEmailGreeting,
  formDefaultSMSChildDescription,
  formDefaultSMSTextLearnMore,
  formDefaultSMSGreeting,
} from "./defaultText";

const AvailabilityRequestContainer = ({
  childrenForPlacement,
  siblingGroup,
  agencyWorker,
  agencyPlacements,
  agency,
  refererUrl,
  smsDisabled,
  childrenPath,
  canSendSms,
}) => {
  const defaultEmailTextLearnMore = formDefaultEmailTextLearnMore(
    agency.name,
    agencyWorker
  );

  const defaultEmailChildText = siblingGroup
    ? formDefaultEmailTextSiblingGroup(childrenForPlacement)
    : formDefaultEmailTextOneChild(childrenForPlacement);

  const defaultEmailTextGreeting = formDefaultEmailGreeting(
    siblingGroup,
    agency.name,
    defaultEmailChildText
  );

  const defaultSMSChildText =
    formDefaultSMSChildDescription(childrenForPlacement);
  const defaultSMSOpening = formDefaultSMSGreeting(
    siblingGroup,
    agency.name,
    defaultSMSChildText
  );

  const defaultSMSLearnMore = formDefaultSMSTextLearnMore(agencyWorker);

  const [channels, setChannels] = useState(["email"]);
  const [previewMode, setPreviewMode] = useState(false);
  const [emailText, setEmailText] = useState(
    `${defaultEmailTextGreeting}\n${defaultEmailTextLearnMore}\n\n${defaultEmailSignature}`
  );
  const [SMSText, setSMSText] = useState(
    `${defaultSMSOpening} ${defaultSMSLearnMore}`
  );

  const childrenNames = () => {
    const children = childrenForPlacement.map(({ fullName }) => fullName);
    if (siblingGroup) {
      return `${children.slice(0, -1).join(", ")} and ${last(children)}`;
    }
    return last(children);
  };

  return (
    <Fragment>
      <Breadcrumbs
        pages={[
          {
            label: <T t="availability_request.children" />,
            href: childrenPath,
          },
          {
            label: <T t="availability_request.view_matching_families" />,
            href: refererUrl,
          },
          {
            label: <T t="availability_request.draft_availability_message" />,
            href: "#",
          },
        ]}
      />
      <h2>
        <T t="availability_request.header" names={childrenNames()} />
      </h2>
      <If condition={previewMode}>
        <PreviewContainer
          childrenIds={childrenForPlacement.map(child => child.id.toString())}
          agencyPlacementIds={agencyPlacements.map(agencyPlacement =>
            agencyPlacement.id.toString()
          )}
          onCancel={() => setPreviewMode(false)}
          emailText={emailText}
          SMSText={SMSText}
          channels={channels}
          agency={agency}
          refererURL={refererUrl}
          canSendSms={canSendSms}
        />
      </If>
      <If condition={!previewMode}>
        <DraftContainer
          agency={agency}
          agencyPlacements={agencyPlacements}
          channels={channels}
          emailText={emailText}
          SMSText={SMSText}
          onCancelRedirectTo={refererUrl}
          onPreview={() => setPreviewMode(true)}
          setEmailText={setEmailText}
          setSMSText={setSMSText}
          setChannels={setChannels}
          smsDisabled={smsDisabled}
        />
      </If>
    </Fragment>
  );
};

AvailabilityRequestContainer.propTypes = {
  childrenForPlacement: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      age: PropTypes.number,
      referAsTranslationKey: PropTypes.string,
      fullName: PropTypes.string,
    })
  ).isRequired,
  siblingGroup: PropTypes.bool.isRequired,
  agencyWorker: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    mobilePhoneNumber: PropTypes.string,
  }).isRequired,
  agency: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
    .isRequired,
  refererUrl: PropTypes.string.isRequired,
  agencyPlacements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
      mobilePhoneNumber: PropTypes.string,
      optedOut: PropTypes.bool,
    })
  ).isRequired,
  smsDisabled: PropTypes.bool.isRequired,
  childrenPath: PropTypes.string.isRequired,
  canSendSms: PropTypes.bool.isRequired,
};

export default AvailabilityRequestContainer;
