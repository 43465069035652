import { gql, useQuery } from "@apollo/client";
import { toast } from "@heart/components";
import { Spinner } from "@heart/components/icon/Icon";

import SsoCard from "./SsoCard";
import styles from "./SsoCardGroup.module.scss";

const processString = s => s?.toLowerCase().replaceAll(" ", "");
const matchesSearchTerm = searchTerm => ssoCardData => {
  if (!searchTerm) {
    return ssoCardData;
  }
  return processString(ssoCardData.name).includes(processString(searchTerm));
};

const SsoCardGroup = () => {
  const { data, loading, error } = useQuery(
    gql`
      query SsoButtons {
        ssoButtons {
          id
          name
          uri
        }
        ssoLogin @client {
          ssoSearchTerm
        }
      }
    `
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    toast.negative({
      title: "Error fetching SSO buttons",
    });

    return null;
  }

  const {
    ssoLogin: { ssoSearchTerm },
    ssoButtons,
  } = data;

  const filteredData = ssoButtons.filter(matchesSearchTerm(ssoSearchTerm));

  return (
    <div className={styles.container}>
      {filteredData.map(args => (
        <SsoCard key={args.id} {...args} />
      ))}
    </div>
  );
};

export default SsoCardGroup;
