import { InputDropdown } from "@heart/components";
import PropTypes from "prop-types";

import BintiPropTypes from "@lib/BintiPropTypes";

const AgencyDropdown = ({
  agencies,
  selectedAgencyId,
  onChange,
  ...inputProps
}) => (
  <InputDropdown
    {...inputProps}
    key={`agency_dropdown__${selectedAgencyId}`}
    name="agencyId"
    label="Agency"
    value={selectedAgencyId}
    values={agencies.map(agency => [agency.name, agency.id])}
    onChange={onChange}
    hideBlank={true}
  />
);

AgencyDropdown.propTypes = {
  agencies: PropTypes.arrayOf(
    PropTypes.shape({ id: BintiPropTypes.ID, name: PropTypes.string })
  ),
  onChange: PropTypes.func,
  selectedAgencyId: BintiPropTypes.ID,
};

export default AgencyDropdown;
