import { Flex, FlexItem, Text } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";

import useSurfaceContext from "../surface/useSurfaceContext";
import styles from "./DetailsTable.module.scss";

/** A way to display detailed information about an individual object,
 * rather than a collection
 *
 * ### Cypress
 *
 * Find a value within DetailsTable using `cy.detailsTableValue({caption: "Caption", detail: "Detail"})`
 * (for example, `cy.detailsTableValue({caption: "Personal Information", detail: "Status"})`)
 * If no caption is explicitly given, it uses the surrounding `Surface` title and hides it.
 */
const DetailsTable = ({
  caption,
  subcaption,
  hideCaption = false,
  details,
  actions,
  "data-testid": testId,
}) => {
  const { title: surfaceTitle } = useSurfaceContext();
  if (!surfaceTitle && !caption)
    // eslint-disable-next-line no-console
    console.error(
      "Please use a caption if this DetailsTable is not on top of a Surface"
    );

  // only hide the `<caption>` if there is no caption, subcaption,
  // and actions
  const hideCaptionElement =
    (!caption || hideCaption) && !subcaption && !actions;

  return (
    <table data-testid={testId} className={styles.table}>
      <caption
        className={classNames(
          classNames(styles.caption, { [styles.hide]: hideCaptionElement })
        )}
      >
        <Flex justify="space-between" gap="300" fullWidth>
          <Flex column>
            <Text
              textStyle="emphasis-200"
              className={classNames({
                [styles.hide]: !caption || hideCaption,
              })}
            >
              {caption || surfaceTitle}
            </Text>
            <If condition={subcaption}>
              <Text textStyle="body-100">{subcaption}</Text>
            </If>
          </Flex>
          <FlexItem expand="none">{actions}</FlexItem>
        </Flex>
      </caption>
      <tbody className={styles.tbody}>
        {details.map(({ label, value }) => (
          <tr key={label} className={styles.tr}>
            <th className={styles.th} scope="row">
              {label}
            </th>
            <td className={styles.td}>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

DetailsTable.propTypes = {
  /** A caption for the table. Required when the DetailsTable is not
   * on top of a `Surface` (or any `SurfaceBase` like `SurfaceForm` / `Notice` etc)
   * or when the `Surface`'s title doesn't adequately convey to non-sighted users
   * what the data in this table refers to.
   * When inferred from the `Surface` title, it's hidden. */
  caption: PropTypes.string,
  /** A subcaption for the table */
  subcaption: PropTypes.string,
  /** Hide the caption for non-sighted users.  When a caption is inferred from
   * the `Surface` title and none is given, this is assumed to be `true`. */
  hideCaption: PropTypes.bool,
  /** Actions to render below the caption and above the table */
  actions: PropTypes.node,
  /** An array of labels and values to display in the table */
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.node,
      ]),
    })
  ).isRequired,
  "data-testid": PropTypes.string,
};

export default DetailsTable;
