import { gql, useQuery } from "@apollo/client";
import { InputDropdown, LoadingOverlay, Text } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

const { t } = translationWithRoot("placement_period");

const CareProviderDropdown = ({
  childId,
  placementPeriodId,
  formState,
  setFormAttribute,
}) => {
  const { data, loading } = useQuery(
    gql`
      query CareProviderDropdownFormData(
        $childId: ID!
        $placementPeriodId: ID
        $placementType: String
      ) {
        placementPeriodFormData(
          childId: $childId
          placementPeriodId: $placementPeriodId
          placementType: $placementType
        ) {
          permittedAttributes
          careProviderOptions {
            label
            value
            warnings
          }
        }
      }
    `,
    {
      variables: {
        childId,
        placementPeriodId,
        placementType: formState.placementType,
      },
    }
  );

  const permittedAttributes =
    data?.placementPeriodFormData?.permittedAttributes || [];

  const careProviderOptions =
    data?.placementPeriodFormData?.careProviderOptions || [];

  const selectedProvider = careProviderOptions?.find(
    option => option.value === formState.careProvider
  );

  return (
    <LoadingOverlay active={loading}>
      <If condition={careProviderOptions.length > 0}>
        <InputDropdown
          disabled={!permittedAttributes.includes("care_provider")}
          required
          name="placement_period[care_provider]"
          label={t("care_provider")}
          values={careProviderOptions}
          value={formState.careProvider}
          onChange={setFormAttribute("careProvider")}
        />
      </If>
      <Text textColor="danger-500">{selectedProvider?.warnings}</Text>
    </LoadingOverlay>
  );
};

CareProviderDropdown.propTypes = {
  childId: BintiPropTypes.ID,
  placementPeriodId: BintiPropTypes.ID,
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
};

export default CareProviderDropdown;
