import classnames from "classnames";
import { capitalize } from "lodash";
import PropTypes from "prop-types";
import { createElement, forwardRef } from "react";

import styles from "./Table.module.scss";

// eslint-disable-next-line react/display-name
const ElementWrapper = ({
  passthroughRef,
  type,
  children,
  className,
  ...props
}) =>
  createElement(
    type,
    {
      className: classnames(styles[type], className),
      ref: passthroughRef,
      ...props,
    },
    children
  );

ElementWrapper.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  passthroughRef: PropTypes.object,
};

const makeWrappedElement = type => {
  const element = forwardRef((passthrough, ref) => (
    <ElementWrapper passthroughRef={ref} {...{ type, ...passthrough }} />
  ));

  element.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
  };

  element.displayName = capitalize(type);

  return element;
};

export const Table = makeWrappedElement("table");
export const Thead = makeWrappedElement("thead");
export const Tbody = makeWrappedElement("tbody");
export const Tr = makeWrappedElement("tr");
export const Td = makeWrappedElement("td");
export const Th = makeWrappedElement("th");
export const Caption = makeWrappedElement("caption");
