/* rails-erb-loader-dependencies ../config/locales/ */
import I18n from "i18n-js";
import { compact } from "lodash";

// Make I18n.l behave like it does in Ruby for single argument calls - it should
// assume the value is a date and format it with our default.
const originalL = I18n.l;
export const l = (scope, value, options) => {
  if (!value && !options) {
    // If someone passes in a moment object, let's automatically convert
    // it for them.
    if (typeof scope === "object" && scope.toISOString) {
      return originalL("date.formats.default", scope.toISOString());
    }

    return originalL("date.formats.default", scope);
  }

  return originalL(scope, value, options);
};

export const st = (stateAbbr, translationPath, ...rest) => {
  const stateTranslation = I18n.t(
    compact(["us", stateAbbr?.toLowerCase(), translationPath]).join("."),
    ...rest
  );

  if (
    stateTranslation === "translation missing" ||
    stateTranslation.startsWith("[missing")
  ) {
    return I18n.t(compact(["us", translationPath]).join("."), ...rest);
  }

  return stateTranslation;
};
