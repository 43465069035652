import { useQuery } from "@apollo/client";
import { Icons, Link, Surface, HeartTable } from "@heart/components";
import I18n from "i18n-js";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import Spinner from "@components/Spinner";
import { translationWithRoot } from "@components/T";
import ErrorBanner from "@components/shared/banner/ErrorBanner";

import Role from "@graphql/queries/Role.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import RoleAgencyWorkersLink from "./RoleAgencyWorkersLink";

const { Table, Td, Tr, Th, Tbody, Thead, Caption } = HeartTable;

const { t } = translationWithRoot("permissions");

const RoleInfoViewer = ({ roleId, toggleToEditing }) => {
  const { data, loading, error } = useQuery(Role, {
    variables: { roleId },
  });
  if (loading) return <Spinner />;
  if (error) return <ErrorBanner message={error.message} />;

  const {
    slug,
    name,
    agency,
    originatedFrom,
    createdAt,
    createdBy,
    usersInRole,
    isCustom,
  } = data.role;

  // Only allow editing custom roles
  const editPencil = isCustom ? (
    <Icons.Pencil onClick={toggleToEditing} description={"Edit Role Info"} />
  ) : null;

  return (
    <Surface title={t("role_information")} secondary={editPencil}>
      <Table>
        <Caption hidden>{t("role_information")}</Caption>
        <Thead hidden>
          <Tr>
            <Th>Key</Th>
            <Th>Value</Th>
          </Tr>
        </Thead>
        <Tbody withHiddenThead>
          <Tr>
            <Td className="min-required-width">
              {I18n.t("javascript.components.permissions.role_name")}
            </Td>
            <Td>{name}</Td>
          </Tr>

          <Tr>
            <Td className="min-required-width">{I18n.t("common.slug")}</Td>
            <Td>{slug}</Td>
          </Tr>
          <If condition={isCustom}>
            <Tr>
              <Td className="min-required-width">
                {I18n.t("views.common.agency")}
              </Td>
              <If condition={!agency}>
                <Td className="min-required-width">{t("none")}</Td>
              </If>
              <If condition={agency}>
                <Td>{agency.name}</Td>
              </If>
            </Tr>
            <Tr>
              <Td className="min-required-width">{t("original_role")}</Td>
              <Td>
                <If condition={!originatedFrom}>{t("none")}</If>
                <If condition={originatedFrom}>
                  <Link
                    href={`/roles/${originatedFrom.id}-${originatedFrom.slug}`}
                  >
                    {originatedFrom.name}
                  </Link>
                </If>
              </Td>
            </Tr>
          </If>
          <Tr>
            <Td className="min-required-width">{t("created_by")}</Td>
            <Td>
              <If condition={!createdBy}>{t("none")}</If>
              <If condition={createdBy}>
                <Link href={`/admin/agency_workers/${createdBy.id}`}>
                  {createdBy.name}
                </Link>
              </If>
            </Td>
          </Tr>
          <Tr>
            <Td className="min-required-width">{t("created_date")}</Td>
            <Td>{DateTime.fromISO(createdAt).toFormat("M/dd/yyyy")}</Td>
          </Tr>
          <Tr>
            <Td className="min-required-width">
              {t("number_of_users_in_role")}
            </Td>
            <Td>
              <RoleAgencyWorkersLink
                id={roleId}
                numUsersInRole={usersInRole.length}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Surface>
  );
};

RoleInfoViewer.propTypes = {
  roleId: BintiPropTypes.ID,
  toggleToEditing: PropTypes.func,
};

export default RoleInfoViewer;
