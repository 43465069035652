import {
  Link,
  InputPhone,
  Button,
  InputText,
  Modal,
  Text,
} from "@heart/components";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import InputPanel from "@components/reusable_ui/forms/InputPanel";
import BannerContainer from "@components/shared/banner/BannerContainer";

import preventDefault from "@lib/preventDefault";

import styles from "./ReferenceEditor.module.scss";

const pattern = input => (isEmpty(input) ? ".*" : ".{7,15}");

const ReferenceEditor = ({
  existingReference,
  addOrEditReference,
  setEditingForReference,
  localErrorMessages,
}) => {
  const [reference, setReference] = useState({
    firstName: existingReference?.firstName || "",
    middleName: existingReference?.middleName || "",
    lastName: existingReference?.lastName || "",
    suffix: existingReference?.suffix || "",
    email: existingReference?.email || "",
    phoneNumber: existingReference?.phoneNumber || "",
  });

  const [confirmationModalHidden, setConfirmationModalHidden] = useState(true);
  const hideConfirmationModal = () => setConfirmationModalHidden(true);
  const showConfirmationModal = () => setConfirmationModalHidden(false);

  const onSubmit = preventDefault(() => {
    if (
      existingReference?.email &&
      reference.email !== existingReference?.email
    ) {
      showConfirmationModal();
    } else {
      addOrEditReference(reference);
    }
  });

  return (
    <form
      onSubmit={onSubmit}
      className="space-below-2"
      data-testid="new-reference-form"
    >
      <InputPanel
        title={`${I18n.t("views.reference_requests.form.add")} ${I18n.t(
          "views.applications.application_reference.reference"
        )}`}
      >
        <Modal
          title={I18n.t(
            "views.reference_requests.form.changed_email_modal_title"
          )}
          onCancel={() => {
            hideConfirmationModal();
          }}
          onSubmit={() => {
            addOrEditReference(reference);
          }}
          hidden={confirmationModalHidden}
        >
          <Text>
            {I18n.t("views.reference_requests.form.changed_email_modal_text")}
          </Text>
        </Modal>
        <span className={styles.flexContainer}>
          <span className={styles.flexChild}>
            <InputText
              label={I18n.t("common.first_name")}
              required={true}
              value={reference.firstName}
              onChange={value =>
                setReference({ ...reference, firstName: value })
              }
            />
          </span>
          <span className={styles.flexChild}>
            <InputText
              label={I18n.t("common.middle_name")}
              value={reference.middleName}
              onChange={value =>
                setReference({ ...reference, middleName: value })
              }
            />
          </span>
        </span>
        <span className={styles.flexContainer}>
          <span className={styles.flexChild}>
            <InputText
              label={I18n.t("common.last_name")}
              required={true}
              value={reference.lastName}
              onChange={value =>
                setReference({ ...reference, lastName: value })
              }
            />
          </span>
          <span className={styles.flexChild}>
            <InputText
              label={I18n.t("common.suffix")}
              value={reference.suffix}
              onChange={value => setReference({ ...reference, suffix: value })}
            />
          </span>
        </span>
        <InputText
          label={I18n.t("views.common.email_address")}
          value={reference.email}
          onChange={value =>
            setReference({
              ...reference,
              email: value,
            })
          }
        />
        <InputPhone
          label={I18n.t("views.common.phone_number")}
          pattern={pattern(reference.phoneNumber || "")}
          id="phone-number"
          value={reference.phoneNumber}
          defaultCountry="US"
          international
          onChange={value => setReference({ ...reference, phoneNumber: value })}
        />
        <If condition={!isEmpty(localErrorMessages)}>
          <BannerContainer
            type={"error"}
            /* localErrorMessages is an array that contains strings or JSX components.
             * 'reduce' syntax allow us to join these two different types of values.
             */
            message={localErrorMessages
              .slice(1)
              .reduce(
                (prev, curr) => [...prev, ", ", curr],
                [localErrorMessages[0]]
              )}
          />
        </If>
        <Button type="submit" variant="primary">
          {existingReference
            ? I18n.t("views.reference_requests.form.save")
            : I18n.t("views.reference_requests.form.save_and_send_request")}
        </Button>
        <If condition={existingReference}>
          <Link
            onClick={preventDefault(() => {
              setEditingForReference(false);
            })}
          >
            {I18n.t("views.common.cancel")}
          </Link>
        </If>
      </InputPanel>
    </form>
  );
};

ReferenceEditor.propTypes = {
  addOrEditReference: PropTypes.func.isRequired,
  existingReference: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    complete: PropTypes.bool,
  }),
  setEditingForReference: PropTypes.func,
  localErrorMessages: PropTypes.array.isRequired,
};

export default ReferenceEditor;
