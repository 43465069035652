import { useMutation, useReactiveVar } from "@apollo/client";
import { Modal } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import PropTypes from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";
import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import UpdateBackgroundCheckType from "@graphql/mutations/UpdateBackgroundCheckType.graphql";
import BackgroundCheckTypes from "@graphql/queries/BackgroundCheckTypes.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import BackgroundCheckTypeForm from "./BackgroundCheckTypeForm";

const { t } = translationWithRoot("background_check_types");

const UpdateBackgroundCheckTypeModal = ({
  backgroundCheckType,
  hidden,
  onClose,
}) => {
  const queryVariables = useReactiveVar(queryVariablesVar);

  const [updateBackgroundCheckType, { loading }] = useMutation(
    UpdateBackgroundCheckType,
    {
      onCompleted: onClose,
      refetchQueries: [
        { query: BackgroundCheckTypes, variables: queryVariables },
      ],
    }
  );

  const { formState, setFormAttribute } = useBintiForm(backgroundCheckType);

  return (
    <Modal
      asForm
      hidden={hidden}
      title={t("update_bg_type")}
      submitting={loading}
      onSubmit={() => updateBackgroundCheckType({ variables: formState })}
      submitText={t("update_bg_type")}
      onCancel={onClose}
    >
      <BackgroundCheckTypeForm
        formState={formState}
        setFormAttribute={setFormAttribute}
      />
    </Modal>
  );
};

UpdateBackgroundCheckTypeModal.propTypes = {
  backgroundCheckType: PropTypes.shape({
    id: BintiPropTypes.ID,
    name: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
  }),
  hidden: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UpdateBackgroundCheckTypeModal;
