import { trim } from "lodash";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function () {
  const value_entered = () =>
    $(".js-next-stage button")
      .removeClass("is-disabled")
      .removeAttr("disabled");

  const value_empty = () =>
    $(".js-next-stage button")
      .addClass("is-disabled")
      .attr("disabled", "disabled");

  const hash = password => btoa(password);

  const show_iframe = function () {
    const iframe_exists = !!$("#js-iframe-placeholder iframe").length;
    if (iframe_exists) {
      return;
    }

    const $orientation_stage = $(".js-orientation-stage");
    const iframe_src = $orientation_stage.data("iframe-src");
    if (iframe_src === undefined) {
      return;
    }
    if (iframe_src.length === 0) {
      return;
    }

    const iframe_ratio =
      $orientation_stage.data("iframe-height") /
      $orientation_stage.data("iframe-width");
    // the page has 15 px margin, which means the video should be 30px less than
    // the width of the screen
    let width = document.body.clientWidth - 30;

    if (width > $orientation_stage.data("iframe-width")) {
      width = $orientation_stage.data("iframe-width");
    }

    const height = width * iframe_ratio;
    const $iframe =
      $orientation_stage.data("is-src-video-file") === true
        ? $("<video>", { src: iframe_src, width, height, controls: true })
        : $("<iframe>", { src: iframe_src, width, height });
    return $("#js-iframe-placeholder").html($iframe);
  };

  const flag_future_date_error = function () {
    $(".js-in-person-orientation").addClass("has-error");
    $("#js-future-orientation-date-error").text(
      "Date cannot be in the future. You can only " +
        "move to the next step if you have already completed the orientation."
    );
    return value_empty();
  };

  const flag_unparseable_date_error = function () {
    $(".js-in-person-orientation").addClass("has-error");
    $("#js-future-orientation-date-error").text(
      "Please enter date in MM/DD/YYYY format"
    );
    return value_empty();
  };

  const $orientation_stage = $(".js-orientation-stage");
  if (!$orientation_stage.length) {
    return false;
  }

  const orientation_date = $(".js-orientation-date").val();
  let $entered_password = $(".js-entered-password");
  const $correct_password_entered = $("#js-correct-password-entered");

  // if we come back to page, show online or in-person
  // sections as previously seen
  if ($correct_password_entered.length > 0) {
    value_entered();
  } else if (orientation_date != null && !!orientation_date) {
    const parsed_date = new Date(orientation_date);
    if (parsed_date > new Date()) {
      flag_future_date_error();
    } else if (isNaN(parsed_date.getTime())) {
      flag_unparseable_date_error();
    } else {
      value_entered();
    }
  } else if ($entered_password.length) {
    value_empty();
  }

  if (orientation_date != null && !!orientation_date) {
    $(".js-in-person-orientation").removeClass("hidden");
  }

  if ($("#js-iframe-placeholder").length > 0) {
    show_iframe();
  }

  $(".js-entered-password").on("keyup change", function (event) {
    if (
      hash(trim($(this).val().toLowerCase())) === $(this).data("password-hash")
    ) {
      return value_entered();
    } else {
      return value_empty();
    }
  });

  $(".js-orientation-date").on("focus", event =>
    $(".js-orientation-date").on("keyup change", function (event) {
      const entered_date = new Date($(this).val());
      if (entered_date > new Date()) {
        return flag_future_date_error();
      } else if (isNaN(entered_date.getTime())) {
        return flag_unparseable_date_error();
      } else {
        $(".js-in-person-orientation").removeClass("has-error");
        $("#js-future-orientation-date-error").text("");
        return value_entered();
      }
    })
  );

  $(".js-choose-online-orientation").on("click", function () {
    const $orientation_date = $(".js-orientation-date");
    if ($orientation_date.val() != null) {
      $orientation_date.val("");
      value_empty();
    }
    // track `Online Orientation Started` event
    const update_path = $(this).data("update-path");
    $.ajax({
      url: update_path,
      method: "put",
      data: {
        application_stage: {
          online_orientation_started: true,
        },
      },
    });
    $(".js-in-person-orientation").addClass("hidden");
    $(".js-online-orientation").removeClass("hidden");
    return show_iframe();
  });

  return $(".js-choose-in-person-orientation").on("click", function () {
    $entered_password = $(".js-entered-password");
    if ($entered_password.val() != null) {
      $entered_password.val("");
      value_empty();
    }
    $(".js-online-orientation").addClass("hidden");
    return $(".js-in-person-orientation").removeClass("hidden");
  });
});
