import { isEqual } from "lodash";

export const CHILD_KEY = "child";
export const APPLICANT_KEY = "applicant";
export const CO_APPLICANT_KEY = "co_applicant";
export const OTHER_ADULT_IN_HOME_KEY = "other_adult_in_home";
export const CHILD_IN_HOME_KEY = "child_in_home";
export const REFERENCE_KEY = "reference_user";
export const APPLICATION_KEY = "application";

export const isNeededForChild = neededFor => isEqual(neededFor, [CHILD_KEY]);
export const isNeededForApplication = neededFor =>
  isEqual(neededFor, [APPLICATION_KEY]);
export const isNeededForApplicant = neededFor =>
  neededFor.includes(APPLICANT_KEY);
export const isNeededForCoApplicant = neededFor =>
  neededFor.includes(CO_APPLICANT_KEY);
export const isNeededForOtherAdult = neededFor =>
  neededFor.includes(OTHER_ADULT_IN_HOME_KEY);
export const isNeededForChildInHome = neededFor =>
  neededFor.includes(CHILD_IN_HOME_KEY);
export const isNeededForReference = neededFor =>
  neededFor.includes(REFERENCE_KEY);
