import { gql, useQuery } from "@apollo/client";
import { Icons, LoadingOverlay, Surface } from "@heart/components";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

import ResponseEdit from "./ResponseEdit";
import ResponseView from "./ResponseView";

const { t } = translationWithRoot("placement_search.response");

const Response = ({ placementSearchId }) => {
  const [editing, setEditing] = useState(false);
  const { data, loading } = useQuery(
    gql`
      query responseQuery($placementSearchId: ID!) {
        placementSearch(id: $placementSearchId) {
          id
          custodialAgency {
            id
            name
          }
        }
      }
    `,
    {
      variables: { placementSearchId },
    }
  );

  const placementSearch = data?.placementSearch || {};

  return (
    <LoadingOverlay active={loading}>
      <Surface
        title={`${t("title")} ${placementSearch?.custodialAgency?.name}`}
        secondary={
          editing ? (
            <Icons.Times
              description={"close"}
              onClick={() => setEditing(false)}
            />
          ) : (
            <Icons.Pencil
              description={"edit"}
              onClick={() => setEditing(true)}
            />
          )
        }
        data-testid="placement-search-response-container"
      >
        {editing ? (
          <ResponseEdit
            placementSearchId={placementSearchId}
            onCompleted={() => setEditing(false)}
            onCancel={() => setEditing(false)}
          />
        ) : (
          <ResponseView placementSearchId={placementSearchId} />
        )}
      </Surface>
    </LoadingOverlay>
  );
};

Response.propTypes = {
  placementSearchId: BintiPropTypes.ID,
};

export default Response;
