import { Surface, Icons } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState, Fragment } from "react";

import BintiPropTypes from "@lib/BintiPropTypes";

import DeleteAppealModal from "./DeleteAppealModal";
import EditAppealDetails from "./EditAppealDetails";
import ViewAppealDetails from "./ViewAppealDetails";

const AppealDetails = ({
  appealId,
  applicationId,
  representatives,
  applicationType,
  showDeleteIcon,
  appealLocations,
}) => {
  const [editing, setEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  if (editing) {
    return (
      <EditAppealDetails
        appealId={appealId}
        representatives={representatives}
        appealLocations={appealLocations}
        onSubmit={() => setEditing(false)}
        onCancel={() => setEditing(false)}
      />
    );
  }

  return (
    <Surface
      title={I18n.t("javascript.components.appeals.appeal_details")}
      secondary={
        <Fragment>
          <Icons.Pencil
            description={I18n.t(
              "javascript.components.appeals.appeal_details_edit"
            )}
            onClick={() => setEditing(true)}
          />
          <If condition={showDeleteIcon}>
            <Icons.Trash
              description={I18n.t("javascript.components.common.delete")}
              onClick={() => {
                setShowDeleteModal(true);
              }}
            />
          </If>
        </Fragment>
      }
    >
      <If condition={showDeleteModal}>
        <DeleteAppealModal
          appealId={appealId}
          applicationId={applicationId}
          onCancel={() => setShowDeleteModal(false)}
        />
      </If>
      <ViewAppealDetails
        appealId={appealId}
        applicationId={applicationId}
        applicationType={applicationType}
      />
    </Surface>
  );
};

AppealDetails.propTypes = {
  appealId: BintiPropTypes.ID,
  applicationId: BintiPropTypes.ID,
  representatives: PropTypes.array,
  appealLocations: PropTypes.array,
  applicationType: PropTypes.string,
  showDeleteIcon: PropTypes.bool,
};

export default AppealDetails;
