import { Link, Surface } from "@heart/components";
import { identity } from "lodash";
import PropTypes from "prop-types";

import styles from "../CcwisDemo.module.scss";

const CurrentPlacement = ({ placement }) => (
  <div style={{ maxWidth: "98.5%", margin: "0 8px 8px" }}>
    <Surface title="Placement" hideTitle>
      <h2 className={styles.surfaceTitle}>Placement</h2>
      <table className={styles.table}>
        <tbody>
          <If condition={placement.type}>
            <tr>
              <th scope="row">Type</th>
              <td>{placement.type}</td>
            </tr>
          </If>
          <If condition={placement.placementDisplayName}>
            <tr>
              <th scope="row">Name</th>
              <td>
                <Link href={placement.placementLink}>
                  {placement.placementDisplayName}
                </Link>
              </td>
            </tr>
          </If>
          <If condition={placement.privateAgencyAddressComponents.join("")}>
            <tr>
              <th scope="row">Address</th>
              <td>
                {placement.privateAgencyAddressComponents
                  .filter(identity)
                  .map(address => (
                    <p key={address}>{address}</p>
                  ))}
              </td>
            </tr>
          </If>
          <If condition={placement.startDate}>
            <tr>
              <th scope="row">Start&nbsp;date</th>
              <td>{placement.startDate}</td>
            </tr>
          </If>
        </tbody>
      </table>
    </Surface>
  </div>
);

CurrentPlacement.propTypes = {
  placement: PropTypes.object.isRequired,
};

export default CurrentPlacement;
