import { isEmpty } from "lodash";

/**
 * Remove a staff member from a household. Thin wrapper for
 * GraphQL mutation.
 */
export default async ({
  messageDisplay: { displayError, displayNotice },
  placementProviderId,
  userAgencyProfileId,
  removeMember,
  leftAt,
  reasonForLeaving,
  isErroneous,
  setRemoving,
  staffMemberId,
}) => {
  const variables = {
    placementProviderId: placementProviderId,
    userAgencyProfileId: userAgencyProfileId,
    role: "staffMember",
    leftAt: leftAt,
    reasonForLeaving: reasonForLeaving,
    isErroneous: isErroneous,
    id: staffMemberId,
  };

  const {
    data: {
      removeHumanPlacementProviderRole: { errors },
    },
  } = await removeMember({
    variables,
  });
  if (!isEmpty(errors)) {
    displayError(errors.map(({ message }) => message).join(", "));
  } else {
    setRemoving(false);
    displayNotice(
      I18n.t("views.applications.application_staff_member.removed_successfully")
    );
  }
};
