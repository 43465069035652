import { Link, HeartTable, Button } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import createNameAndDOBLabel from "@components/common/createNameAndDOBLabel";

const { Table, Caption, Td, Th, Thead, Tr, Tbody } = HeartTable;

const { t } = translationWithRoot(
  "agency_human.form.personal_info.potential_matches"
);

/** A list of agency humans who already exist in Binti and may be the
 * person that someone is looking for or trying to add to Binti
 *
 * Powers the "Did you mean" table in the Agency Human form
 */
const PotentialAgencyHumanMatches = ({ matches, setFormAttribute }) => (
  <Table>
    <Caption>{t("did_you_mean")}</Caption>
    <Thead>
      <Tr>
        <Th>{t("name")}</Th>
        <Th>{t("actions")}</Th>
      </Tr>
    </Thead>
    <Tbody>
      {matches.map(
        ({
          id,
          fullName,
          dateOfBirth,
          linkToView,
          isEstimatedDateOfBirth,
          estimatedDateOfBirth,
          ...rest
        }) => (
          <Tr key={id}>
            <Td>
              <Link target="_blank" href={linkToView}>
                {createNameAndDOBLabel({
                  fullName,
                  dateOfBirth,
                  isEstimatedDateOfBirth,
                  estimatedDateOfBirth,
                })}
              </Link>
            </Td>
            <Td>
              <Button
                variant="secondary"
                onClick={() =>
                  setFormAttribute("agencyHuman")({
                    value: id,
                    label: fullName,
                    id,
                    fullName,
                    dateOfBirth,
                    isEstimatedDateOfBirth,
                    estimatedDateOfBirth,
                    ...rest,
                  })
                }
              >
                {t("select_person")}
              </Button>
            </Td>
          </Tr>
        )
      )}
    </Tbody>
  </Table>
);

PotentialAgencyHumanMatches.propTypes = {
  /** A list of agency humans found using fuzzy find */
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      dateOfBirth: PropTypes.string,
      linkToView: PropTypes.string.isRequired,
      isEstimatedDateOfBirth: PropTypes.bool,
      estimatedDateOfBirth: PropTypes.string,
    })
  ),
  setFormAttribute: PropTypes.func.isRequired,
};

export default PotentialAgencyHumanMatches;
