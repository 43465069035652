import PropTypes from "prop-types";
import ReactRailsUJS from "react_ujs";

import BintiApolloProvider from "./BintiApolloProvider";

/**
 * Wrapper Component for react_component-rendered components
 * that need to connect to our Binti graphql via the ApolloProvider.
 *
 * There is no need to use this component directly! All components are
 * auto-wrapped with this by `react_component`.
 */
const ReactRailsApolloWrapper = ({ componentName, childProps }) => {
  const Component = ReactRailsUJS.getConstructor(componentName);

  return (
    <BintiApolloProvider>
      <Component {...childProps} />
    </BintiApolloProvider>
  );
};

ReactRailsApolloWrapper.propTypes = {
  componentName: PropTypes.string.isRequired,
  childProps: PropTypes.object.isRequired,
};

export default ReactRailsApolloWrapper;
