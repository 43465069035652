import { gql, useMutation } from "@apollo/client";
import { Actions, SurfaceForm } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";

import RequirementTemplateFragment from "@graphql/fragments/RequirementTemplate.graphql";
import ScheduleFragment from "@graphql/fragments/Schedule.graphql";

import preventDefault from "@lib/preventDefault";

import BasicInfoForm from "./BasicInfoForm";

const emptyFormState = {};

/**
 * ScheduleCreate component for creating a schedule.
 *
 * @param {string} agencyId - The ID of the agency.
 */
const ScheduleCreate = () => {
  const [createSchedule, { loading }] = useMutation(
    gql`
      ${RequirementTemplateFragment}
      ${ScheduleFragment}
      mutation CreateSchedule($input: CreateScheduleInput!) {
        createSchedule(input: $input) {
          schedule {
            ...Schedule
          }
        }
      }
    `,
    {
      onCompleted: ({
        createSchedule: {
          schedule: { id },
        },
      }) => {
        window.location = `${id}/edit`;
      },
    }
  );

  const { formState, setFormAttribute } = useBintiForm(emptyFormState);

  return (
    <SurfaceForm
      title="Create Schedule"
      actions={<Actions isSubmitting={loading} />}
      onSubmit={preventDefault(() =>
        createSchedule({
          variables: {
            input: {
              ...formState,
            },
          },
        })
      )}
    >
      <BasicInfoForm
        formState={formState}
        setFormAttribute={setFormAttribute}
      />
    </SurfaceForm>
  );
};

export default ScheduleCreate;
