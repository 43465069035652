import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import RecordWithAttachments from "@components/requirements/RecordWithAttachments";
import { Td, Tr } from "@components/reusable_ui/Table";

import DeleteUploadedRecordAttachment from "@graphql/mutations/DeleteUploadedRecordAttachment.graphql";

const UnmatchedUploadedRecordRow = ({ record, application, aroundLoader }) => {
  const [deleteUploadedRecordAttachment] = useMutation(
    DeleteUploadedRecordAttachment
  );

  const deleteAttachment = aroundLoader(({ attachmentId }) =>
    deleteUploadedRecordAttachment({
      variables: {
        holderToken: application.holderToken,
        uploadedRecordId: record.id,
        attachmentId,
      },
    })
  );

  return (
    <Tr>
      <Td>
        {record.uploadType.name}

        <RecordWithAttachments {...{ deleteAttachment, record }} />
      </Td>
    </Tr>
  );
};

UnmatchedUploadedRecordRow.propTypes = {
  application: PropTypes.object.isRequired,
  aroundLoader: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export default UnmatchedUploadedRecordRow;
