import { NodeDiagramUnstyledHandle } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import Connection from "./Connection";

/** A node for our genograms, which wraps a Connection card in
 * handles that can be connected to other ConnectionNodes
 */
const ConnectionNode = ({ data }) => (
  <Fragment>
    <NodeDiagramUnstyledHandle id="child" position="bottom" />
    <NodeDiagramUnstyledHandle id="sibling" position="top" />
    <NodeDiagramUnstyledHandle id="parent" position="top" />
    <NodeDiagramUnstyledHandle id="partner" position="bottom" />
    <NodeDiagramUnstyledHandle
      id="fictive-kin"
      position="bottom"
      useAsSource={false}
    />
    <NodeDiagramUnstyledHandle
      id="fictive-kin"
      position="top"
      useAsTarget={false}
    />
    <Connection {...data} />
  </Fragment>
);
ConnectionNode.propTypes = {
  data: PropTypes.shape({
    keystoneAgencyHumanId: PropTypes.string.isRequired,
    relationship: PropTypes.shape({
      lineageType: PropTypes.string,
      kinshipRelationship: PropTypes.string,
      kinshipRelationshipOtherDescription: PropTypes.string,
      fictiveKinDescription: PropTypes.string,
    }),
    setRelationshipToView: PropTypes.func.isRequired,
  }).isRequired,
};

export default ConnectionNode;
