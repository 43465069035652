import { Text } from "@heart/components";
import Icon from "@heart/components/icon/Icon";
import { Table, Tbody, Td, Th, Thead, Tr } from "@heart/components/table/Table";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import { PropTypes } from "prop-types";

import { translationWithRoot } from "@components/T";

import styles from "./AgenciesAssigned.module.scss";
import ResponseDropdown from "./ResponseDropdown";

const { t } = translationWithRoot("placement_search.agencies_assigned");

const emptyArray = [];

const AgenciesAssignedForm = ({
  formState,
  onUnassign,
  onUpdateResponse,
  showResponseColumn,
}) => {
  const assignedSearches = formState?.assignedSearches || emptyArray;

  const assignedAgencyIds =
    assignedSearches?.map(s => s.agency.id) || emptyArray;

  const tableStyles = {
    verticalAlign: "middle",
  };

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>{I18n.t("admin.common.name")}</Th>
          <If condition={showResponseColumn}>
            <Th>
              {I18n.t("javascript.components.placement_search.response.title")}
            </Th>
          </If>
          <Th>{I18n.t("admin.common.actions")}</Th>
        </Tr>
      </Thead>
      <Tbody aria-live="polite">
        <Choose>
          <When condition={!isEmpty(assignedAgencyIds)}>
            {assignedSearches.map(
              ({
                id: assignedSearchId,
                agency: { id: agencyId, name },
                response,
              }) => (
                <Tr key={`${assignedSearchId}-${response}`}>
                  <Td style={tableStyles}>
                    <Text>{name}</Text>
                  </Td>
                  <If condition={showResponseColumn}>
                    <Td
                      style={tableStyles}
                      className={styles[response?.replaceAll("_", "-")]}
                    >
                      <ResponseDropdown
                        value={response}
                        onChange={newResponse =>
                          onUpdateResponse(assignedSearchId, newResponse)
                        }
                      />
                    </Td>
                  </If>
                  <Td style={tableStyles}>
                    <Icon
                      icon="trash"
                      description={`${t("unassign")} ${name}`}
                      onClick={() => onUnassign(agencyId)}
                    />
                  </Td>
                </Tr>
              )
            )}
          </When>
          <Otherwise>
            <Tr>
              <Td colSpan={3} style={{ textAlign: "left" }}>
                {t("no_agencies_assigned")}
              </Td>
            </Tr>
          </Otherwise>
        </Choose>
      </Tbody>
    </Table>
  );
};

AgenciesAssignedForm.propTypes = {
  formState: PropTypes.object,
  onUnassign: PropTypes.func,
  onUpdateResponse: PropTypes.func,
  showResponseColumn: PropTypes.bool,
};

export default AgenciesAssignedForm;
