import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";

import styles from "./StripedTable.module.scss";

const StripedTable = props => {
  const { title = "", columns = [], data = [] } = props;
  return (
    <div>
      <h2 className={styles.tableHeader}> {title} </h2>
      <ReactTable
        className={styles.table}
        columns={columns}
        minRows={0}
        data={data}
        sortable={false}
        showPagination={false}
        defaultPageSize={100}
        getTableProps={() => ({ className: styles.borderTable })}
        getTheadThProps={() => ({ className: styles.th })}
        getTbodyProps={() => ({ className: styles.tbody })}
        getTrProps={() => ({ className: styles.tr })}
        getTdProps={() => ({ className: styles.td })}
        getTheadTrProps={() => ({ className: styles.head })}
        getTrGroupProps={() => ({ className: styles.trGroups })}
      />
    </div>
  );
};

StripedTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  title: PropTypes.string,
};

export default StripedTable;
