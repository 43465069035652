import { useMutation, gql, useQuery } from "@apollo/client";
import { Button, Modal } from "@heart/components";
import PropTypes from "prop-types";
import React, { useState, Fragment } from "react";

import { translationWithRoot } from "@components/T";

import ConvertOtherAdultToCoApplicant from "@graphql/mutations/ConvertOtherAdultToCoApplicant.graphql";

const { T } = translationWithRoot("admin.applications.change_role");

/**
 * A component that allows a user to change the role of a human.
 * @param {object} props
 * @param {number} props.applicationId - The application ID
 * @param {object} props.adult - The other adult HumanPlacementProviderRole object
 * @returns {JSX.Element}
 */
const ChangeAdultRole = ({ applicationId, adult }) => {
  const [submitting, setSubmitting] = useState(false);
  const [confirmationHidden, setConfirmationHidden] = useState(true);
  const [convertOtherAdultToCoApplicant] = useMutation(
    ConvertOtherAdultToCoApplicant
  );

  const ApplicationQuery = gql`
    query Application($id: ID!) {
      application(id: $id) {
        cpaOrGroupCare
        coApplicantAgencyProfile {
          id
        }
      }
    }
  `;

  const { data: applicationData } = useQuery(ApplicationQuery, {
    variables: { id: applicationId },
  });

  const coApplicantPresent =
    !!applicationData?.application?.coApplicantAgencyProfile;

  const modalMessage = coApplicantPresent
    ? "co_applicant_already_exists"
    : "confirm_adult_role_change";

  const submitText = coApplicantPresent ? "got_it" : "submit";

  const convertOAToCoApp = otherAdultId => {
    if (coApplicantPresent) {
      setConfirmationHidden(true);
    } else {
      setSubmitting(true);
      convertOtherAdultToCoApplicant({
        variables: {
          applicationId: applicationId,
          otherAdultId: otherAdultId,
        },
      }).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <Fragment>
      <Button
        style={{ marginLeft: "5px" }}
        variant="secondary"
        submitting={submitting}
        onClick={e => {
          e.preventDefault();
          setConfirmationHidden(false);
        }}
      >
        <T t="convert_to_co_applicant" />
      </Button>

      <Modal
        hidden={confirmationHidden}
        onCancel={() => setConfirmationHidden(true)}
        onSubmit={() => convertOAToCoApp(adult.userId)}
        submitting={submitting}
        submitText={<T t={submitText} />}
        title={<T t="convert_to_co_applicant" />}
      >
        <T t={modalMessage} />
      </Modal>
    </Fragment>
  );
};

ChangeAdultRole.propTypes = {
  applicationId: PropTypes.number.isRequired,
  adult: PropTypes.shape({
    id: PropTypes.string,
    userId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    subrole: PropTypes.string,
    joinedAt: PropTypes.string,
    leftAt: PropTypes.string,
    masqueradeLink: PropTypes.string,
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        phoneNumber: PropTypes.string,
        primary: PropTypes.bool,
      })
    ),
    userAgencyProfiles: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        dateOfBirth: PropTypes.string,
        hardBounce: PropTypes.bool,
      })
    ),
  }),
};

export default ChangeAdultRole;
