import { useState } from "react";

import BintiPropTypes from "@lib/BintiPropTypes";

import RoleInfoEditor from "./RoleInfoEditor";
import RoleInfoViewer from "./RoleInfoViewer";

const RoleInfo = ({ roleId }) => {
  const [isEditing, setEditing] = useState(false);

  const toggleToReadOnly = () => {
    setEditing(false);
  };
  const toggleToEditing = () => {
    setEditing(true);
  };

  if (isEditing) {
    return (
      <RoleInfoEditor roleId={roleId} toggleToReadOnly={toggleToReadOnly} />
    );
  }

  return <RoleInfoViewer toggleToEditing={toggleToEditing} roleId={roleId} />;
};

RoleInfo.propTypes = {
  roleId: BintiPropTypes.ID,
};

export default RoleInfo;
