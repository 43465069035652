import { InputHidden } from "@heart/components";

const Csrf = () => (
  <InputHidden
    name="authenticity_token"
    value={document
      .querySelector("meta[name='csrf-token']")
      ?.getAttribute("content")}
  />
);

export default Csrf;
