import { Flex, FlexItem, Text } from "@heart/components";
import listStyles from "@heart/core/invisible_list.module.scss";
import PropTypes from "prop-types";

export const LabeledContent = ({ label, content }) => {
  if (content) {
    return (
      <Text>
        <If condition={label}>
          <b>{label}</b>:{" "}
        </If>
        {content}
      </Text>
    );
  }
  return null;
};
LabeledContent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  content: PropTypes.any,
};

export const TableCellList = ({ gap = "100", items, transformItem }) => (
  <Flex column gap={gap} as="ul" className={listStyles.invisibleList}>
    {items &&
      items.map((item, index) => (
        <FlexItem key={index} as="li">
          {transformItem ? transformItem(item) : item}
        </FlexItem>
      ))}
  </Flex>
);
TableCellList.propTypes = {
  gap: PropTypes.oneOf(["100", "200", "300"]),
  items: PropTypes.array.isRequired,
  transformItem: PropTypes.func,
};
