import PropTypes from "prop-types";

import T from "@components/T";

const EndUserUploadTypeAction = ({ fulfillment }) => {
  let actionKey;

  if (fulfillment.isActionNeededSoon) {
    actionKey = "end_user.common.action_needed_soon_html";
  }

  if (fulfillment.isActionCritical) {
    actionKey = "end_user.common.action_critical_html";
  }

  if (!actionKey) return false;

  return (
    <div>
      <i className="fa fa-exclamation-triangle" aria-hidden="true" />
      <T t={actionKey} date={I18n.l(fulfillment.dateActionNeededBy)} />
    </div>
  );
};

EndUserUploadTypeAction.propTypes = {
  fulfillment: PropTypes.object.isRequired,
};

export default EndUserUploadTypeAction;
