import { useQuery } from "@apollo/client";
import { Button } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import T from "@components/T";

import ApplicationChildrenInHome from "@graphql/queries/ApplicationChildrenInHome.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import ChildInHomeInput from "./ChildInHomeInput";
import styles from "./ChildrenInHome.module.scss";

/** View/Edit form for children in home.
 *    Application Details > Edit Children in Home
 */
const ChildrenInHomeInput = ({ applicationId, ethnicityOptions, readOnly }) => {
  const { loading, error, data } = useQuery(ApplicationChildrenInHome, {
    variables: { id: applicationId },
  });
  const [creatingNew, setCreatingNew] = useState(false);

  if (loading) return false;

  if (error) {
    return (
      <div>
        <T t="children_in_home.children_in_home_input.could_not_load" />
      </div>
    );
  }

  const {
    application: { applicationChildrenInHome },
    RelationshipToApplicantValues,
    GenderValues,
  } = data;

  return (
    <div className={styles.childrenInHomeContainer}>
      {applicationChildrenInHome.map(applicationChildInHome => (
        <ChildInHomeInput
          key={applicationChildInHome.id}
          {...{
            applicationId,
            applicationChildInHome,
            RelationshipToApplicantValues,
            ethnicityOptions,
            GenderValues,
            readOnly,
          }}
        />
      ))}
      <If condition={!readOnly}>
        {creatingNew && (
          <ChildInHomeInput
            onCreateDone={() => {
              setCreatingNew(false);
            }}
            {...{
              applicationId,
              RelationshipToApplicantValues,
              ethnicityOptions,
              GenderValues,
              readOnly,
            }}
          />
        )}
        {!creatingNew && (
          <Button
            variant="secondary"
            onClick={() => {
              setCreatingNew(true);
            }}
          >
            {applicationChildrenInHome.length === 0 && (
              <T t="children_in_home.children_in_home_input.add_child" />
            )}
            {applicationChildrenInHome.length > 0 && (
              <T t="children_in_home.children_in_home_input.add_another_child" />
            )}
          </Button>
        )}
      </If>
    </div>
  );
};

ChildrenInHomeInput.propTypes = {
  applicationId: BintiPropTypes.ID.isRequired,
  ethnicityOptions: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
};

export default ChildrenInHomeInput;
