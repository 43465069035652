import { InputHidden } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

// This is using jquery logic hooked onto the "js-attachments" class to work
// TODO: remove that.
// Also TODO: don't have two ids that are the same thing, thats ridiculous
class Attachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: Attachment.getCheckboxState(this.props.value),
    };
  }

  static getCheckboxState(value) {
    return (
      value === "hardcopy" || value === "not_applicable" || value === "on_file"
    );
  }

  handleChecked(selected) {
    const value = selected.target.checked && selected.target.value;
    this.setState({ checked: Attachment.getCheckboxState(value) });
  }

  /* eslint-disable class-methods-use-this */
  componentDidMount() {
    const $attachments = $(".js-attachments");

    // Race condition handling:
    // If the jquery Attachments code has already run, we'll need to initialize
    // the dropzone area ourselves manually now. If not, when it does load
    // it'll do this for us.
    if ($attachments.attachments) {
      $attachments.attachments();
    }
  }
  /* eslint-enable class-methods-use-this */

  /* eslint-disable jsx-a11y/label-has-associated-control */
  render() {
    const {
      id,
      title,
      name,
      value,
      description,
      papercopy,
      required,
      disabled,
    } = this.props;

    const wrapperClassName = classNames(
      "question-type-attachment",
      `test-attachment-${id}`,
      {
        required: required,
      }
    );
    const fileInputClassName = classNames("js-attachments");

    return (
      <div className={wrapperClassName}>
        <label className="js-attachment-label" htmlFor={name}>
          {title}
        </label>
        <p className="description">{description}</p>
        <div className="attachment">
          <InputHidden value="nohardcopy" name={name} id={id} />
          <div className="wrapper-to-expand-collapse">
            <input
              type="file"
              className={fileInputClassName}
              data-attachments-value={value}
              data-name={name}
              id={id}
              data-attachments-toggle={`[name='${name}']`}
            />
            {papercopy && <p className="or"> -{I18n.t("application.or")}- </p>}
          </div>
          {papercopy && (
            <label>
              <input
                type="checkbox"
                onChange={this.handleChecked.bind(this)}
                checked={this.state.checked}
                id={id}
                name={name}
                disabled={disabled}
                value="hardcopy"
              />
              {I18n.t(
                "views.questions.attachment.coordinate_with_agency_to_provide"
              )}
            </label>
          )}
        </div>
      </div>
    );
  }
  /* eslint-enable jsx-a11y/label-has-associated-control */
}

// papercopy can be copy for checkbox or "false"
Attachment.propTypes = {
  values: PropTypes.array,
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  papercopy: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.node,
  description: PropTypes.node,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default Attachment;
