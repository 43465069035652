import {
  InputRadioGroup,
  Surface,
  Flex,
  InputTextarea,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";

const FamilyInformation = ({
  directions,
  onDirectionsChanged,
  hasPetInHome,
  onHasPetChanged,
  therapeuticCare,
  onTherapeuticCareChanged,
  emergencyFamily,
  onEmergencyFamChanged,
  canCallAfterHours,
  onCanCallChanged,
  state,
}) => (
  <Surface title="Family Information">
    <Flex column>
      <InputTextarea
        label="Additional directions to home"
        id="comments_text"
        onChange={onDirectionsChanged}
        value={directions || ""}
      />

      <InputRadioGroup
        label="Has pets in home?"
        id="has_pets_in_home_radio"
        onChange={onHasPetChanged}
        value={String(hasPetInHome) || null}
        values={[
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ]}
      />

      <InputRadioGroup
        label={I18n.st(state, "therapeutic_care")}
        id="therapeutic_care_radio"
        onChange={onTherapeuticCareChanged}
        value={String(therapeuticCare) || null}
        values={[
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ]}
      />
    </Flex>
    <Flex column>
      <InputRadioGroup
        label="Emergency Family status"
        id="emergency_placement_radio"
        onChange={onEmergencyFamChanged}
        value={emergencyFamily || null}
        values={[
          {
            value: "is_not_open_to_being_an_emergency_family",
            label: "Not open to being an emergency family",
          },
          {
            value: "open_to_being_an_emergency_family",
            label: "Open to being an emergency family",
          },
          {
            value: "contracted_emergency_family",
            label: "Contracted emergency family",
          },
        ]}
      />

      <InputRadioGroup
        label="Can call after hours?"
        id="can_call_after_hours_radio"
        onChange={onCanCallChanged}
        value={String(canCallAfterHours) || null}
        values={[
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ]}
      />
    </Flex>
  </Surface>
);
FamilyInformation.propTypes = {
  directions: PropTypes.string,
  onDirectionsChanged: PropTypes.func.isRequired,
  hasPetInHome: PropTypes.bool,
  onHasPetChanged: PropTypes.func.isRequired,
  therapeuticCare: PropTypes.bool,
  onTherapeuticCareChanged: PropTypes.func.isRequired,
  emergencyFamily: PropTypes.string,
  onEmergencyFamChanged: PropTypes.func.isRequired,
  canCallAfterHours: PropTypes.bool,
  onCanCallChanged: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
};

export default FamilyInformation;
