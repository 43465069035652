import { get } from "lodash";

import { typeEq } from "@lib/graphqlHelpers";

const uploadRequirementFiles =
  ({
    holderToken,
    /** GraphQL Mutations */
    attachFormInstanceFiles,
    createUploadedRecord,
  }) =>
  ({ requirement, formInstance }) =>
  async files => {
    if (typeEq("ScheduledUploadTypeRequirement", requirement)) {
      await createUploadedRecord({
        variables: {
          holderToken,
          uploadTypeSlug: get(requirement, "uploadType.slug"),
          requirementId: requirement.id,
          files,
        },
      });
    } else if (typeEq("ChildFormRequirement", requirement) && formInstance) {
      await attachFormInstanceFiles({
        variables: {
          formInstanceId: formInstance.id,
          holderToken,
          files,
        },
      });
    }
  };

export default uploadRequirementFiles;
