import { isEmpty } from "lodash";

import flattenGraphqlErrorMessages from "../flattenGraphqlErrorMessages";

export default async ({
  messageDisplay: { displayError },
  reference,
  refetch,
  removeApplicationReference,
}) => {
  const {
    data: {
      removeApplicationReference: { errors },
    },
  } = await removeApplicationReference({
    variables: { id: reference.id },
  });
  if (!isEmpty(errors)) {
    displayError(flattenGraphqlErrorMessages(errors));
  } else {
    refetch();
  }
};
