import colorStyles from "@heart/core/colors.module.scss";
import typographyStyles from "@heart/core/typography.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./Text.module.scss";

// The available `color` props are the CSS class names in `colors.module.scss`
// but without the `text-color-` or `bg-color-` prefix.
export const availableTextColors = Object.keys(colorStyles)
  .filter(rule => rule.startsWith("text-color-"))
  .map(rule => rule.replace("text-color-", ""));

// The available `type` props are the CSS class names in `typography.module.scss`
// but without the `typography-` prefix.
export const availableTextStyles = Object.keys(typographyStyles).map(rule =>
  rule.replace("typography-", "")
);

/**
 * Set a text color for this component!  Powered by our design tokens.
 *
 * Note: Environment and Map colors are not intended for use within Binti outside of
 * our environment logos and family maps
 */
const Text = ({
  as = "span",
  className,
  children,
  textColor,
  textStyle,
  ...props
}) => {
  const Component = as;

  return (
    <Component
      className={classNames(
        className,
        {
          [colorStyles[`text-color-${textColor}`]]: textColor,
          [typographyStyles[`typography-${textStyle}`]]: textStyle,
        },
        { [styles.defeatHeaderStyles]: ["h1", "h2", "h3"].includes(as) }
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

Text.propTypes = {
  /** Which color value this Text should have */
  textColor: PropTypes.oneOf(availableTextColors),
  /** Which typography this Text should have */
  textStyle: PropTypes.oneOf(availableTextStyles),
  /** What kind of DOM element to use.  Defaults to `<span>` */
  as: PropTypes.string,
  /** If an onClick prop is provided, the whole container will be clickable
   *  and will have hover / focus styling (only incentive containers for now) */
  children: PropTypes.node.isRequired,
  /** Additional classes to customize the appearance of this Text.
   * *Please don't use this to change the font size/weight/etc.* */
  className: PropTypes.string,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};

export default Text;
