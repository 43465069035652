import { useMutation } from "@apollo/client";
import { Button } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import T from "@components/T";

import CompleteSigningEventRole from "@graphql/mutations/CompleteSigningEventRole.graphql";

import styles from "../../BintiSign.module.scss";

/**
 * Button to accept all the signatures on the current active signer.
 * Actually sends the backend mutation.
 */
const AcceptButton = ({ activeSigner, signingEvent, onClose }) => {
  const [completeSigningEventRole] = useMutation(CompleteSigningEventRole);
  const [wasClicked, setWasClicked] = useState(false);

  return (
    <div className={styles.acceptButtonArea}>
      <Button
        disabled={wasClicked}
        className={styles.confirmAction}
        onClick={() => {
          setWasClicked(true);
          completeSigningEventRole({
            variables: {
              role: activeSigner.role,
              signingEventId: signingEvent.id,
            },
          }).then(({ data }) => {
            onClose(data?.completeSigningEventRole?.signingEvent);
          });
        }}
      >
        <T t="bintisign.signature_pane.accept" />
      </Button>
      <div className={styles.acceptButtonText}>
        <p>
          <T t="bintisign.signature_pane.by_clicking_accept" />
        </p>
      </div>
      <hr />
    </div>
  );
};

AcceptButton.propTypes = {
  activeSigner: PropTypes.object.isRequired,
  signingEvent: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AcceptButton;
