import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import PhoneNumbersByUser from "@graphql/queries/PhoneNumbersByUser";

import ItemListTable from "../reusable_ui/ItemListTable";
import MarkPhoneNumberActivity from "./MarkPhoneNumberActivity";
import PrimaryStatus from "./PrimaryStatus";

const sortOrder = phoneNumber => {
  if (phoneNumber.primary) {
    return -1;
  }

  if (!phoneNumber.active) {
    return 1;
  }

  return 0;
};

/**
 * PhoneNumberList component renders a list of phone numbers with options to mark them as primary or active/inactive.
 *
 * @param {string} personId - The ID of the person whose phone numbers are being displayed.
 * @param {string} personType - The type of the person (e.g., user, admin).
 * @returns {JSX.Element} The rendered component.
 */
const PhoneNumberList = ({ personId, personType }) => {
  const { data } = useQuery(PhoneNumbersByUser, {
    variables: { personType, personId },
  });

  const phoneNumbers = data?.phoneNumbers || [];

  const sortedPhoneNumbers = [...phoneNumbers].sort(
    (a, b) => sortOrder(a) - sortOrder(b) || a.id - b.id
  );

  return (
    <ItemListTable
      headers={[
        "Number",
        "Phone Category",
        "Change Primary Status",
        "Inactive Status",
      ]}
    >
      {sortedPhoneNumbers.map(
        phoneNumber => (
          <tr key={phoneNumber.id}>
            <td>
              <If condition={phoneNumber.active}>
                <a href={`tel:${phoneNumber.phoneNumber}`}>
                  {phoneNumber.formattedNumber}
                </a>
              </If>
              <If condition={!phoneNumber.active}>
                {phoneNumber.formattedNumber}
              </If>

              <p className="inline-hint">{phoneNumber.notes}</p>
            </td>
            <td>{phoneNumber.category}</td>
            <td>
              <PrimaryStatus
                phoneNumber={phoneNumber}
                personId={personId}
                personType={personType}
              />
            </td>
            <td>
              <MarkPhoneNumberActivity
                id={phoneNumber.id}
                active={phoneNumber.active}
              />
            </td>
          </tr>
        ),
        this
      )}
    </ItemListTable>
  );
};

PhoneNumberList.propTypes = {
  personId: PropTypes.string.isRequired,
  personType: PropTypes.string.isRequired,
};

export default PhoneNumberList;
