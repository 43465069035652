import { ContentTabs, Flex, Link } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import AfcarsTable from "./AfcarsTable";
import ChildrenAdoptedWithPublicAgencyInvolvement from "./ChildrenAdoptedWithPublicAgencyInvolvement";
import ChildrenEnteringFosterCare from "./ChildrenEnteringFosterCare";
import ChildrenExitingFosterCare from "./ChildrenExitingFosterCare";
import ChildrenInFosterCare from "./ChildrenInFosterCare";
import ChildrenWaitingToBeAdopted from "./ChildrenWaitingToBeAdopted";
import NumbersAtAGlance from "./NumbersAtAGlance";

const Afcars = ({ childList }) => (
  <Flex column style={{ margin: "10px" }}>
    <ContentTabs
      tabs={[
        {
          title: "Numbers at a Glance",
          contents: <NumbersAtAGlance />,
        },
        {
          title: "Children in Foster Care",
          contents: <ChildrenInFosterCare />,
        },
        {
          title: "Children Entering Foster Care",
          contents: <ChildrenEnteringFosterCare />,
        },
        {
          title: "Children Exiting Foster Care",
          contents: <ChildrenExitingFosterCare />,
        },
        {
          title: "Children Waiting to be Adopted",
          contents: <ChildrenWaitingToBeAdopted />,
        },
        {
          title: "Children Adopted with Public Agency Involvement",
          contents: <ChildrenAdoptedWithPublicAgencyInvolvement />,
        },
        {
          title: <Fragment>Data&nbsp;Quality</Fragment>,
          count: childList.length,
          contents: (
            <AfcarsTable
              key="missing-fields"
              headers={["Child or Youth Name", "Missing fields"]}
              data={childList.map(({ name, missingFieldCount, editLink }) => [
                name,
                <Link key={name} href={editLink}>
                  <Choose>
                    <When condition={missingFieldCount === 0}>
                      No missing fields
                    </When>
                    <Otherwise>{missingFieldCount} missing fields</Otherwise>
                  </Choose>{" "}
                </Link>,
              ])}
            />
          ),
        },
      ]}
    ></ContentTabs>
  </Flex>
);

Afcars.propTypes = {
  childList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      missingFieldCount: PropTypes.number,
      editLink: PropTypes.string,
    })
  ),
};

export default Afcars;
