import { Link } from "@heart/components";
import PropTypes from "prop-types";

import AttachmentDownloadLink from "@components/shared/AttachmentDownloadLink";
import Timestamp from "@components/shared/Timestamp";

import preventDefault from "@lib/preventDefault";

import styles from "./Requirement.module.scss";

const AttachmentTimestamp = ({ attachment }) => {
  if (attachment.deletedAt) {
    return (
      <span className={styles.recordData}>
        <Timestamp label="deleted" datetimestamp={attachment.deletedAt} />
      </span>
    );
  }

  return (
    <span className={styles.recordData}>
      <Timestamp label="created" datetimestamp={attachment.createdAt} />
    </span>
  );
};

AttachmentTimestamp.propTypes = {
  attachment: PropTypes.object.isRequired,
};

const AttachmentControl = ({
  attachment,
  deleteAttachment,
  mayDownloadAttachment,
  mayDestroyAttachment,
}) => {
  const onDelete = preventDefault(() => {
    // eslint-disable-next-line no-alert
    if (window.confirm(I18n.t("javascript.components.common.are_you_sure"))) {
      deleteAttachment({
        attachmentId: attachment.id,
        attachmentType: attachment.__typename,
      });
    }
  });

  return (
    <div key={attachment.id}>
      <span>
        <If condition={mayDownloadAttachment}>
          <AttachmentDownloadLink attachment={attachment} />
        </If>
        <If condition={!mayDownloadAttachment}>{attachment.name}</If>
      </span>
      <AttachmentTimestamp {...{ attachment }} />
      <If condition={mayDestroyAttachment && !attachment.deletedAt}>
        <Link data-testid="delete-attachment" onClick={onDelete}>
          {I18n.t("javascript.components.common.delete")}
        </Link>
      </If>
    </div>
  );
};

AttachmentControl.propTypes = {
  attachment: PropTypes.object.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  mayDownloadAttachment: PropTypes.bool,
  mayDestroyAttachment: PropTypes.bool,
};

export default AttachmentControl;
