import { Button } from "@heart/components";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";

import generateId from "@lib/generateId";
import { OPTION_KEY_KEY } from "@lib/overridableHelpers";

import styles from "./OverridableInput.module.scss";

const LOCALES = ["en", "es"]; // TODO somehow get this into the I18n global

const OverridableDetailsOptionSpecInput = ({
  index,
  option,
  updateOption,
  removeOption,
}) => {
  const keyId = generateId();
  const localeIds = LOCALES.reduce((acc, locale) => {
    acc[locale] = generateId();
    return acc;
  }, {});

  const updateKey = ({ target: { value } }) => {
    updateOption(Object.assign({}, option, { [OPTION_KEY_KEY]: value }));
  };

  const updateTranslation =
    locale =>
    ({ target: { value } }) => {
      updateOption(Object.assign({}, option, { [locale]: value }));
    };

  return (
    <Draggable draggableId={option[OPTION_KEY_KEY]} index={index}>
      {provided => (
        <div
          className={styles.option}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className={styles.optionHandle} {...provided.dragHandleProps}>
            <i className="fas fa-bars" />
          </div>
          <table className={styles.optionTable}>
            <tbody>
              <tr>
                <th>
                  <label htmlFor={keyId}>Key</label>
                </th>
                <td>
                  <input
                    id={keyId}
                    value={option[OPTION_KEY_KEY] || ""}
                    onChange={updateKey}
                  />
                </td>
              </tr>

              {LOCALES.map(locale => (
                <tr key={locale}>
                  <th>
                    <label htmlFor={localeIds[locale]}>
                      Translation for {locale}
                    </label>
                  </th>
                  <td>
                    <input
                      id={localeIds[locale]}
                      value={option[locale] || ""}
                      onChange={updateTranslation(locale)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button onClick={removeOption}>X</Button>
        </div>
      )}
    </Draggable>
  );
};

OverridableDetailsOptionSpecInput.propTypes = {
  index: PropTypes.number.isRequired,
  option: PropTypes.object.isRequired,
  updateOption: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired,
};

export default OverridableDetailsOptionSpecInput;
