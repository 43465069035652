import I18n from "i18n-js";

import constructName from "../constructName";

/**
 * Send an invite email to an other adult
 */
export const resendOtherAdultEmail = ({
  sendOtherAdultInviteEmail,
  setResending,
  userAgencyProfile,
  displayNotice,
}) => {
  setResending(true);

  const variables = {
    userAgencyProfileId: userAgencyProfile.id,
  };
  sendOtherAdultInviteEmail({ variables });

  displayNotice(
    I18n.t(
      "views.reference_requests.create.reference_or_other_adult_with_email",
      { email: userAgencyProfile.email }
    )
  );
  setResending(false);
};

export const buildEditIconAction = ({ adult, setEditingForAdult }) => ({
  ariaLabel: `${I18n.t("admin.common.edit")} ${constructName(adult)}`,
  onClick: () => setEditingForAdult(true),
  icon: "Pencil",
  "data-testid": "edit-other-adult",
});

export const buildDeleteIconAction = ({ adult, removing, setRemoving }) => ({
  ariaLabel: `${I18n.t("admin.common.delete")} ${constructName(adult)}`,
  onClick: () => {
    setRemoving(true); // This enables a Modal
  },
  icon: removing ? "Spinner" : "Trash",
});
