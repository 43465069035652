import { Button } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import preventDefault from "@lib/preventDefault";

import ScheduledRequirementTemplateFieldset from "./ScheduledRequirementTemplateFieldset";

const NEW_UPLOAD_TYPE_TEMPLATE = {
  upload_type_id: null,
  requirement_type: "ScheduledUploadTypeRequirement",
  specification: {},
  fulfillable_by: false,
};

const NEW_FORM_TEMPLATE = {
  form_id: null,
  requirement_type: "ScheduledFormRequirement",
  specification: {},
};

// Replaces a formtastic `has_many` input for requirement_templates: allows user
//   to create or edit requirement_templates associated with a schedule
const HasManyRequirementTemplatesInput = ({
  requirementTemplates,
  associationOptions,
  startDateEventOptions,
  caregiverPortalFeatureEnabled: showCaregiversCanUpload,
}) => {
  const [selectedRequirementTemplates, updateSelectedRequirementTemplates] =
    useState(requirementTemplates);

  const addUploadTypeTemplate = preventDefault(() => {
    // preventDefault is needed because otherwise the button will
    // submit the form?! It's not clear why that is, though.
    updateSelectedRequirementTemplates(
      selectedRequirementTemplates.concat([NEW_UPLOAD_TYPE_TEMPLATE])
    );
  });

  const addFormTemplate = preventDefault(() => {
    // preventDefault is needed because otherwise the button will
    // submit the form?! It's not clear why that is, though.
    updateSelectedRequirementTemplates(
      selectedRequirementTemplates.concat([NEW_FORM_TEMPLATE])
    );
  });

  const handleRemoveClick = index =>
    preventDefault(() => {
      // TODO: [ENG-5243] This seems to always remove the last requirement_template
      // added, regardless of the index passed in. I suspect this has to do with
      // not ever updating the values of new req templates in state -- they're
      // always just NEW_UPLOAD_TYPE_TEMPLATE even when changed in the UI, but
      // the form submission still sucessfully passes them to the controller.
      // May want to maintain state fully in react and submit via graphql instead
      // of relying on the form submission, but that also probably necessitates
      // converting the entire page to react which is out of scope for this task
      const newTemplatesList = [...selectedRequirementTemplates];
      newTemplatesList.splice(index, 1);

      // preventDefault is needed because otherwise the button will
      // submit the form?! It's not clear why that is, though.
      updateSelectedRequirementTemplates(newTemplatesList);
    });

  return (
    <div>
      <fieldset className="inputs">
        <legend>Scheduled Requirement Templates</legend>
        <ol>
          {selectedRequirementTemplates.map((requirementTemplate, index) => {
            if (
              requirementTemplate.requirement_type ===
              "ScheduledFormRequirement"
            ) {
              return (
                <ScheduledRequirementTemplateFieldset
                  key={`${requirementTemplate.id}_${index}`}
                  requirementTemplate={requirementTemplate}
                  type="ScheduledFormRequirementTemplate"
                  associationOptions={associationOptions.form}
                  startDateEventOptions={startDateEventOptions}
                  // As of writing, this feature is not available for forms,
                  // regardless of the feature flag being on or off.
                  showCaregiversCanUpload={false}
                  index={index}
                  handleRemoveClick={handleRemoveClick}
                />
              );
            }

            return (
              <ScheduledRequirementTemplateFieldset
                key={`${requirementTemplate.id}_${index}`}
                requirementTemplate={requirementTemplate}
                type="ScheduledUploadTypeRequirementTemplate"
                associationOptions={associationOptions.uploadType}
                startDateEventOptions={startDateEventOptions}
                showCaregiversCanUpload={showCaregiversCanUpload}
                index={index}
                handleRemoveClick={handleRemoveClick}
              />
            );
          })}
        </ol>

        <Button variant="secondary" onClick={addUploadTypeTemplate}>
          Add Upload Type Requirement Template
        </Button>
        <Button variant="secondary" onClick={addFormTemplate}>
          Add Form Requirement Template
        </Button>
      </fieldset>
    </div>
  );
};
const associationOptionsType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })
).isRequired;

HasManyRequirementTemplatesInput.propTypes = {
  requirementTemplates: PropTypes.array.isRequired,
  startDateEventOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  associationOptions: PropTypes.shape({
    uploadType: associationOptionsType,
    form: associationOptionsType,
  }).isRequired,
  caregiverPortalFeatureEnabled: PropTypes.bool.isRequired,
};

export default HasManyRequirementTemplatesInput;
