import {
  Flex,
  FlexItem,
  InputDate,
  InputDropdown,
  InputFilterable,
  InputText,
} from "@heart/components";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import { isEmpty, isNil, isString, omit } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import { dateCalculations } from "@lib/dates";

import dateFilterValues from "./dateFilterValues";

const {
  firstDayOfMonth,
  lastDayOfMonth,
  firstDayOfNextMonth,
  lastDayOfNextMonth,
  firstDayOfWeek,
  lastDayOfWeek,
  firstDayOfNextWeek,
  lastDayOfNextWeek,
} = dateCalculations;

const filterByDateRange = ({ value, field, setFilterValuesAttr }) => {
  let startDate;
  let endDate;
  switch (value) {
    case "thisWeek":
      startDate = firstDayOfWeek();
      endDate = lastDayOfWeek();
      break;
    case "nextWeek":
      startDate = firstDayOfNextWeek();
      endDate = lastDayOfNextWeek();
      break;
    case "thisMonth":
      startDate = firstDayOfMonth();
      endDate = lastDayOfMonth();
      break;
    case "nextMonth":
      startDate = firstDayOfNextMonth();
      endDate = lastDayOfNextMonth();
      break;
    default:
  }
  setFilterValuesAttr({
    [field]: value,
    [`${field}Gteq`]: startDate,
    [`${field}Lteq`]: endDate,
  });
};

const filterByCustomDateRange = ({
  field,
  value,
  setFilterValuesAttr,
  startOrEndDateKey,
}) => {
  const queryKey =
    startOrEndDateKey === "startDate" ? `${field}Gteq` : `${field}Lteq`;
  const queryVal = value ? DateTime.fromFormat(value, "yyyy-MM-dd") : null;
  setFilterValuesAttr({ [queryKey]: queryVal });
};

export const flatten = nestedOptions =>
  isEmpty(nestedOptions)
    ? []
    : nestedOptions.reduce(
        (options, value) => [
          ...options,
          ...flatten(value.options),
          omit(value, "options"),
        ],
        []
      );

const TableFilter = ({
  filter: {
    label,
    customDatesLabels,
    type,
    field,
    values,
    query,
    valuesFromResponse,
    isMulti,
    defaultValue,
    showSelectAll,
    required,
  },
  filterValues,
  setFilterValuesAttr,
}) => {
  if (type === "date") {
    return (
      <FlexItem expand="none">
        <InputDropdown
          id={field}
          label={label}
          value={filterValues[field] || ""}
          onChange={value =>
            filterByDateRange({
              value,
              field,
              setFilterValuesAttr,
            })
          }
          values={dateFilterValues}
          required={required}
        />
      </FlexItem>
    );
  }
  if (type === "custom_dates") {
    const minDate = filterValues[`${field}Gteq`]?.toString();
    const maxDate = filterValues[`${field}Lteq`]?.toString();

    const startLabel = customDatesLabels?.start || `${label} Start Date`;
    const endLabel = customDatesLabels?.end || `${label} End Date`;

    return (
      <FlexItem expand="none">
        <Flex>
          <InputDate
            id={`${field}_start_date`}
            key={`${field}_start_date`}
            label={startLabel}
            maxDate={maxDate}
            value={minDate}
            onChange={value =>
              filterByCustomDateRange({
                field,
                value,
                setFilterValuesAttr,
                startOrEndDateKey: "startDate",
              })
            }
            required={required}
          />
          <InputDate
            id={`${field}_end_date`}
            key={`${field}_end_date`}
            label={endLabel}
            minDate={minDate}
            value={maxDate}
            onChange={value =>
              filterByCustomDateRange({
                field,
                value,
                setFilterValuesAttr,
                startOrEndDateKey: "endDate",
              })
            }
            required={required}
          />
        </Flex>
      </FlexItem>
    );
  }
  if (type === "search") {
    return (
      <FlexItem>
        <InputText
          id={field}
          label={label}
          value={filterValues[field]}
          onChange={value => setFilterValuesAttr({ [field]: value })}
          required={required}
        />
      </FlexItem>
    );
  }
  if (type === "dropdown") {
    return (
      <FlexItem>
        <InputDropdown
          id={field}
          label={label}
          value={filterValues[field]}
          values={values}
          onChange={value => setFilterValuesAttr({ [field]: value })}
          required={required}
        />
      </FlexItem>
    );
  }
  if (type === "autocomplete_select") {
    return (
      <FlexItem>
        <InputAutocompleteGraphQL
          name={label}
          id={field}
          onChange={value => setFilterValuesAttr({ [field]: value })}
          label={label}
          required={required}
          value={filterValues[field]}
          query={query}
          valuesFromResponse={valuesFromResponse}
        />
      </FlexItem>
    );
  }
  if (type === "select") {
    let selectedValue = filterValues[field];
    let transformedDefaultValue;

    const transformValue = toTransform => {
      const flattenedValues = flatten(values);
      if (isString(toTransform)) {
        return flattenedValues.find(({ value }) => toTransform === value);
      }

      return toTransform;
    };

    if (isMulti) {
      if (typeof selectedValue === "string") {
        selectedValue = selectedValue.split(",");
      }
      selectedValue = selectedValue?.map(transformValue);
      transformedDefaultValue = defaultValue?.map(transformValue);
    } else {
      selectedValue = transformValue(selectedValue);
      transformedDefaultValue = transformValue(transformedDefaultValue);
    }

    return (
      <FlexItem>
        <InputFilterable
          isClearable
          name={label}
          id={field}
          // this is a workaround as `undefined` doesn't clear out the filter value
          value={selectedValue || null}
          onChange={newValue => {
            if (isNil(newValue)) setFilterValuesAttr({ [field]: undefined });
            else if (isMulti) {
              setFilterValuesAttr({
                [field]: newValue.map(({ value }) => value),
              });
            } else {
              setFilterValuesAttr({ [field]: newValue.value });
            }
          }}
          label={label}
          isMulti={isMulti}
          values={values}
          defaultValue={transformedDefaultValue}
          showSelectAll={showSelectAll}
          required={required}
        />
      </FlexItem>
    );
  }
  return null;
};

TableFilter.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string.isRequired,
    customDatesLabels: PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
    }),
    type: PropTypes.oneOf([
      "autocomplete_select",
      "custom_dates",
      "date",
      "dropdown",
      "search",
      "select",
    ]).isRequired,
    field: PropTypes.string.isRequired,
    values: PropTypes.array,
    /** For type `autocomplete_select`, a gql query, which should take
     * in an `inputQuery` argument to determine options for the dropdown
     */
    query: PropTypes.object,
    /** For type `autocomplete_select`, a function that will be called with
     * the query results, which should return the options in an array of
     * `{label, value}` objects
     */
    valuesFromResponse: PropTypes.func,
    isMulti: PropTypes.bool,
    defaultValue: PropTypes.any,
    showSelectAll: PropTypes.bool,
    required: PropTypes.bool,
  }),
  filterValues: PropTypes.object.isRequired,
  setFilterValuesAttr: PropTypes.func.isRequired,
};

export default TableFilter;
