import { Button } from "@heart/components";

import styles from "./CloseDeleteButton.module.scss";

const CloseDeleteButton = props => (
  <Button className="btn" {...props}>
    <i className="fas fa-times" aria-hidden />
    <span className={styles.screenReader}>Close</span>
  </Button>
);

export default CloseDeleteButton;
