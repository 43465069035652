import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { RRule } from "rrule";

const START = "start";
const DAY_OF_WEEK = "day-of-week"; // uses byweekday

const RRuleWeekInput = ({ rruleObject: { options }, onRRuleChange }) => {
  const [alignment, setAlignment] = useState(
    isEmpty(options.byweekday) ? START : DAY_OF_WEEK
  );
  const [byweekday, setByWeekDay] = useState(options.byweekday || [RRule.MO]);

  useEffect(() => {
    updateRRule();
  }, [alignment, byweekday]);

  const updateRRule = () => {
    switch (alignment) {
      case START:
        onRRuleChange({ byweekday: [] });
        break;
      case DAY_OF_WEEK:
        onRRuleChange({ byweekday });
        break;
      default:
        // this shouldn't happen...
        break;
    }
  };

  const onRadioChange = e => setAlignment(e.target.value);

  const onByWeekDayChange = e =>
    setByWeekDay(e.target.value.split(",").map(d => parseInt(d, 10)));

  return (
    <div>
      <fieldset className="choices">
        {/* FROM EVENT DATE */}
        <ol className="choices-group">
          <li className="choice">
            <label>
              <input
                type="radio"
                checked={alignment === START}
                onChange={onRadioChange}
                value="start"
              />
              From event date
            </label>
          </li>

          {/* ON DAY OF WEEK */}
          <li className="choice">
            <label>
              <input
                type="radio"
                checked={alignment === DAY_OF_WEEK}
                onChange={onRadioChange}
                value={DAY_OF_WEEK}
              />
              On day of the week
            </label>
          </li>
        </ol>
      </fieldset>
      <If condition={alignment === DAY_OF_WEEK}>
        <select
          data-testid="day-of-week-dropdown-weekday"
          value={byweekday.join(",")}
          disabled={alignment !== DAY_OF_WEEK}
          onChange={onByWeekDayChange}
        >
          <option value={RRule.MO.weekday}>Monday</option>
          <option value={RRule.TU.weekday}>Tuesday</option>
          <option value={RRule.WE.weekday}>Wednesday</option>
          <option value={RRule.TH.weekday}>Thursday</option>
          <option value={RRule.FR.weekday}>Friday</option>
          <option value={RRule.SA.weekday}>Saturday</option>
          <option value={RRule.SU.weekday}>Sunday</option>
          <option
            value={[RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]
              .map(d => d.weekday.toString())
              .join(",")}
          >
            Weekday
          </option>
        </select>
      </If>
    </div>
  );
};

RRuleWeekInput.propTypes = {
  rruleObject: PropTypes.object.isRequired,
  onRRuleChange: PropTypes.func.isRequired,
};

export default RRuleWeekInput;
