import {
  Button,
  Flex,
  Icons,
  Surface,
  GraphQLDataTable,
} from "@heart/components";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import BackgroundCheckTypes from "@graphql/queries/BackgroundCheckTypes.graphql";

import CreateBackgroundCheckTypeModal from "./CreateBackgroundCheckTypeModal";
import UpdateBackgroundCheckTypeModal from "./UpdateBackgroundCheckTypeModal";

const { t } = translationWithRoot("background_check_types");

const BackgroundCheckTypesTable = () => {
  const [backgroundCheckType, setBackgroundCheckType] = useState();
  const [createModalHidden, setCreateModalHidden] = useState(true);
  const [updateModalHidden, setUpdateModalHidden] = useState(true);

  return (
    <Surface title={t("table_title")}>
      <GraphQLDataTable
        actions={
          <Flex column justify="end">
            <Button onClick={() => setCreateModalHidden(false)}>
              {t("create_bg_type")}
            </Button>
            <br />
          </Flex>
        }
        defaultSort={{ sortBy: "name", sortDirection: "ASC" }}
        query={BackgroundCheckTypes}
        queryTitle={t("query_title")}
        pageSize={20}
        filters={[
          {
            label: t("columns.search_by_name"),
            type: "search",
            field: "nameContains",
          },
        ]}
        columns={[
          {
            columnName: { name: t("columns.name") },
            id: "name",
            sortBy: "name",
            cell: "name",
          },
          {
            columnName: { name: t("columns.slug") },
            id: "slug",
            sortBy: "slug",
            cell: "slug",
          },
          {
            columnName: { name: t("columns.description") },
            id: "description",
            sortBy: "description",
            cell: "description",
          },
          {
            columnName: {
              name: I18n.t("javascript.components.common.actions"),
            },
            id: "edit-icon",
            cell: bgType => (
              <Icons.Edit
                description={t("columns.edit")}
                onClick={() => {
                  setBackgroundCheckType(bgType);
                  setUpdateModalHidden(false);
                }}
              />
            ),
          },
        ]}
      />
      <UpdateBackgroundCheckTypeModal
        hidden={updateModalHidden}
        onClose={() => setUpdateModalHidden(true)}
        backgroundCheckType={backgroundCheckType}
      />
      <CreateBackgroundCheckTypeModal
        hidden={createModalHidden}
        onClose={() => setCreateModalHidden(true)}
      />
    </Surface>
  );
};

export default BackgroundCheckTypesTable;
