import { gql, useMutation, useQuery } from "@apollo/client";
import { LoadingOverlay } from "@heart/components";
import { PropTypes } from "prop-types";

import ErrorBanner from "@components/shared/banner/ErrorBanner";

import BintiPropTypes from "@lib/BintiPropTypes";

import AddWorkers from "./AddWorkers";

const AddWorkersEdit = ({ placementSearchId, childId }) => {
  const [
    updatePlacementSearchAddAssignedWorkers,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(
    gql`
      mutation UpdatePlacementSearchAddAssignedWorkers(
        $input: UpdatePlacementSearchAddAssignedWorkersInput!
      ) {
        updatePlacementSearchAddAssignedWorkers(input: $input) {
          placementSearch {
            id
            agencyWorkers {
              id
              name
            }
          }
        }
      }
    `
  );

  const { data, loading: dataLoading } = useQuery(
    gql`
      query AddWorkersForm($placementSearchId: ID!) {
        placementSearch(id: $placementSearchId) {
          id
          agencyWorkers {
            id
            name
          }
        }
      }
    `,
    {
      variables: {
        placementSearchId,
      },
    }
  );

  const onSubmit = agencyWorkers => {
    if (!agencyWorkers || agencyWorkers.length === 0) {
      return;
    }

    updatePlacementSearchAddAssignedWorkers({
      variables: {
        input: {
          id: placementSearchId,
          agencyWorkerIds: agencyWorkers.map(aw => aw.id),
        },
      },
    });
  };

  const error = mutationError;

  return (
    <LoadingOverlay active={mutationLoading || dataLoading}>
      <If condition={error}>
        <ErrorBanner message={error.message} />
      </If>
      <AddWorkers
        childId={childId}
        onSubmit={onSubmit}
        workersAssigned={data?.placementSearch.agencyWorkers}
      />
    </LoadingOverlay>
  );
};

AddWorkersEdit.propTypes = {
  placementSearchId: BintiPropTypes.ID,
  childId: BintiPropTypes.ID,
  formName: PropTypes.string,
  onCancel: PropTypes.func,
};

export default AddWorkersEdit;
