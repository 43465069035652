import { Flex } from "@heart/components";
import noScroll from "no-scroll";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import Modal from "react-modal";

import styles from "../BintiSign.module.scss";
import BintiSign from "../viewer/BintiSign";
import CannotSignExplanation from "./CannotSignExplanation";
import SignerButton from "./SignerButton";
import {
  SIGNING_STATE,
  extractUserRequiredSignatures,
  anyUnsignedRoles,
  extractSigningState,
} from "./signingOrderHelper";

/**
 * Connects SignerButtons (e.g. sign for applicant, etc.) which choose the
 * signer who is signing, then launches the BintiSign modal with that signer
 * as its context.
 */
const BintiSignSignerSelector = ({
  signingEvent,
  signerButtonText,
  redirectOnCloseUrl,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeRole, setActiveRole] = useState();

  const { nextAllowedSignatureOrder, signers } = signingEvent;

  const userRequiredSignatures = extractUserRequiredSignatures(signingEvent);
  const onSignerButtonClick = role => () => {
    setActiveRole(role);
    setIsModalOpen(true);
  };

  const onClose = useCallback(
    (latestSigningEvent = signingEvent) => {
      setIsModalOpen(false);
      if (!anyUnsignedRoles(latestSigningEvent) && redirectOnCloseUrl) {
        window.location = redirectOnCloseUrl;
      } else {
        // TODO(ENG-7705): Reconsider page reload, especially on Case Management
        window.location.reload();
      }
    },
    [redirectOnCloseUrl, signingEvent]
  );

  const signerByRole = signerRole =>
    signers.find(({ role }) => signerRole === role);
  const activeSigner = signerByRole(activeRole);

  const signingState = extractSigningState(signingEvent);
  const showSignerButtons = signingState === SIGNING_STATE.CAN_SIGN;

  const requiredSignatureName = requiredSignature => {
    if (requiredSignature.isComplete) {
      return requiredSignature.signature.userName;
    }

    return signerByRole(requiredSignature.role)?.name;
  };

  return (
    <div>
      {showSignerButtons && (
        <Flex className={styles.signerButtons}>
          {userRequiredSignatures.map(requiredSignature => (
            <SignerButton
              key={requiredSignature.role}
              onClick={onSignerButtonClick(requiredSignature.role)}
              name={requiredSignatureName(requiredSignature)}
              {...{
                requiredSignature,
                signerButtonText,
                nextAllowedSignatureOrder,
              }}
            />
          ))}
        </Flex>
      )}
      {!showSignerButtons && (
        <CannotSignExplanation
          {...{ userRequiredSignatures, signingEvent, signingState }}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
        onRequestClose={() => setIsModalOpen(false)}
        onAfterOpen={noScroll.on}
        onAfterClose={noScroll.off}
      >
        <If condition={isModalOpen}>
          <BintiSign
            {...{ activeSigner, signingEvent, onClose, setIsModalOpen }}
          />
        </If>
      </Modal>
    </div>
  );
};

BintiSignSignerSelector.propTypes = {
  signingEvent: PropTypes.object.isRequired,
  redirectOnCloseUrl: PropTypes.string,
  signerButtonText: PropTypes.object,
};

export default BintiSignSignerSelector;
