import styles from "@heart/components/inputs/InputPhone.module.scss";
import {
  BasicInputLayout,
  inputCommonPropTypes,
} from "@heart/components/inputs/common";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { isValidZipcode } from "@lib/zipcodes";

const InputZipcode = props => {
  const { value, error } = props;
  const [zipcodeValue, setZipcodeValue] = useState(value);
  const isZipcodeInvalid = zipcodeValue && !isValidZipcode(zipcodeValue);
  useEffect(() => setZipcodeValue(value), [value]);

  const handleChange = e => {
    setZipcodeValue(e.target.value);
  };

  const validate = e => {
    if (zipcodeValue && !isValidZipcode(zipcodeValue)) {
      e.target.setCustomValidity(
        I18n.t("javascript.components.input_zipcode.enter_valid_zipcode")
      );
    } else {
      e.target.setCustomValidity("");
    }
  };

  return (
    <BasicInputLayout
      {...props}
      error={
        isZipcodeInvalid
          ? I18n.t("javascript.components.input_zipcode.enter_valid_zipcode")
          : error
      }
      inputComponent={inputProps => (
        <div
          className={classNames(styles.input, {
            [styles.containsError]: error,
          })}
        >
          <input
            type="text"
            {...inputProps}
            value={zipcodeValue}
            onChange={handleChange}
            onBlur={validate}
          />
        </div>
      )}
    />
  );
};

InputZipcode.propTypes = {
  ...inputCommonPropTypes,
  /** A zipcode */
  value: PropTypes.string,
};

export default InputZipcode;
