import PropTypes from "prop-types";
import { useState } from "react";

import NestedEnumLevel, { getOptionFromPath } from "./NestedEnumLevel";

/**
 * NestedEnum is the root of a tree of NestedEnumLevel components that
 * exists to do operations that are particular to the root node, namely:
 *
 * 1. to keep track of whether we've changed the selected enum from the
 * initial value passed in, if any
 * 2. call onOptionSelected only when a new valid value has been selected.
 *
 * This component wraps a single NestedEnumLevel, but that component
 * may have arbitrarily deep levels.
 */
const NestedEnum = ({
  label,
  isRequired,
  placeholderLabel,
  options,
  onOptionSelected,
  initialSelectedOptionPath,
  initialSelectedOptionDetails = "",
}) => {
  // after the first change, we want to stop sending down the initial selected
  // option path, so we use this state as an intermediary.
  const [
    passdownInitialSelectedOptionPath,
    setPassdownInitialSelectedOptionPath,
  ] = useState(initialSelectedOptionPath);

  const [selectedOption, setSelectedOption] = useState(
    getOptionFromPath(initialSelectedOptionPath, options)
  );
  const [selectedOptionDetails, setSelectedOptionDetails] = useState(
    initialSelectedOptionDetails
  );

  const [rootSelectedOption, setRootSelectedOption] = useState(selectedOption);

  const onSelectedOptionChange = newSelectedOption => {
    // notify upstream listeners
    if (newSelectedOption && newSelectedOption.selectable) {
      if (!(newSelectedOption.details && newSelectedOption.details.required)) {
        // make sure to clear whatever we had before, but to be selected
        // this value needs details.
        onOptionSelected(newSelectedOption.path);
      }
    }

    // update our own state
    setSelectedOptionDetails("");
    setPassdownInitialSelectedOptionPath(undefined);
    setSelectedOption(newSelectedOption);
  };

  const onRootSelectChange = newSelectedOption => {
    onSelectedOptionChange(newSelectedOption);
    setRootSelectedOption(newSelectedOption);
  };

  const onOptionDetailsChange = newDetails => {
    setSelectedOptionDetails(newDetails);
    onOptionSelected(selectedOption.path, newDetails);
    setPassdownInitialSelectedOptionPath(undefined);
  };

  return (
    <NestedEnumLevel
      levelOptions={options}
      levelSelectedOption={rootSelectedOption}
      onLevelSelectChange={onRootSelectChange}
      initialSelectedOptionPath={passdownInitialSelectedOptionPath}
      isRequired={isRequired}
      {...{
        label,
        placeholderLabel,
        onSelectedOptionChange,
        selectedOptionDetails,
        onOptionDetailsChange,
      }}
    />
  );
};

NestedEnum.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  placeholderLabel: PropTypes.string,
  /** A DSL describing an arbitrarily-deeply-nested set of options.
   * Note that `children` is not React children; it's self-referential
   * to more objects of this same shape. */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selectable: PropTypes.bool.isRequired,
      children: PropTypes.array.isRequired,
      details: PropTypes.shape({
        required: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  onOptionSelected: PropTypes.func.isRequired,
  /** To provide an initially-selected option, provide the path
   * of that option */
  initialSelectedOptionPath: PropTypes.string,
  initialSelectedOptionDetails: PropTypes.string,
};

export default NestedEnum;
