import LogoSpinner from "@heart/components/loading_overlay/LogoSpinner";

import BintiSignWaiter from "./BintiSignWaiter";
import styles from "./BintiSignWaiter.module.scss";

const contentWhileLoading = () => (
  <div className={styles.referenceWaiterContainer}>
    <div className={styles.logoSpinnerSizer}>
      <LogoSpinner />
    </div>
    <p className={styles.loading}>{I18n.t("common.loading")}</p>
  </div>
);

const contentWhenReady = children => (
  <div className={styles.referenceWaiterContainer}>
    <div className={styles.signatureIcon}>
      <i className="fas fa-pen-square" aria-hidden />
    </div>
    {children}
  </div>
);

const signerButtonText = {
  sign: "bintisign.bintisign_reference_waiter.sign_off",
};

/**
 * A BintiSignWaiter that polls after a reference user has finished their
 * single form.
 */
const BintiSignReferenceWaiter = passthroughProps => (
  <BintiSignWaiter
    {...{ contentWhenReady, contentWhileLoading, signerButtonText }}
    {...passthroughProps}
  />
);

BintiSignReferenceWaiter.propTypes = BintiSignWaiter.passthroughProps;

export default BintiSignReferenceWaiter;
