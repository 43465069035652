import browserDetect from "browser-detect";
import { capitalize } from "lodash";

const browserName = browser => {
  if (!browser) return "";

  return capitalize(browser.name);
};

const browserString = browser => {
  if (!browserName(browser)) return null;

  return `${browserName(browser)} (${browser.version})`;
};

export default userAgent => {
  const browser = browserDetect(userAgent);

  return Object.freeze({
    browserString: browserString(browser),
  });
};
