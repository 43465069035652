import URI from "urijs";

$(function () {
  function setBackgroundCheckStatusOptions($bgCheckSelect) {
    const $bgCheckStatusSelect = $bgCheckSelect
      .closest("fieldset")
      .find(".js-background-check-status");
    $bgCheckStatusSelect.find("option[value!='']").remove();

    const backgroundCheckId = $bgCheckSelect.val();
    if (!backgroundCheckId) {
      return;
    }

    const options =
      Binti.BackgroundCheckOptions[parseInt(backgroundCheckId, 10)];

    options.forEach(option => {
      const message = Binti.BackgroundCheckStatusMessages[option];
      $bgCheckStatusSelect.append(
        $(`<option value='${option}'>${message}</option>`)
      );
    });
  }

  function setBackgroundCheckStatusOptionsCb() {
    setBackgroundCheckStatusOptions($(this));
  }

  function hookBackgroundChecks() {
    // remove the handler from the ones we've already hooked...
    $(".js-background-check").off("change", setBackgroundCheckStatusOptionsCb);
    // then add it to all so it fires only once.
    $(".js-background-check").on("change", setBackgroundCheckStatusOptionsCb);
  }

  hookBackgroundChecks();

  $(document).on("has_many_add:after", ".bg_check_logs", hookBackgroundChecks);

  // We do the selection here in javascript rather than on the server because
  // if we initialized the value in activeadmin, it would select the same
  // value for every new "has_many_add" block and we only want it seeded for the
  // first.
  const { background_check_id: backgroundCheckId } = new URI(
    window.location
  ).search(true);

  if (backgroundCheckId) {
    const $checkSelect = $(".js-background-check").first();
    $checkSelect.val(backgroundCheckId);

    setBackgroundCheckStatusOptions($checkSelect);
  }
});
