import { debounce } from "lodash";

$(document).ready(function () {
  function suggestChildDuplicates() {
    const fullName =
      $(".js-child-duplicates-fname").val() +
      " " +
      $(".js-child-duplicates-mname").val() +
      " " +
      $(".js-child-duplicates-lname").val();
    const $message = $(".js-child-duplicates-message");
    let childrenHtml;

    if (fullName.length < 2) {
      $message.text("");
    } else {
      $.ajax({
        url: "/admin/children/children_name_match",
        data: {
          term: fullName,
        },
      }).done(function (collection) {
        $message.text("");
        if (collection.results.length > 0) {
          childrenHtml = collection.results
            .map(function (child) {
              return $("<a></a> ")
                .attr("href", child.url)
                .text(child.name + " (DOB: " + child.birthday + ")")
                .outerHTML();
            })
            .join("<span>, </span>");

          $message.append("We found a similar name for a child in Binti: ");
          $message.append(childrenHtml);
        }
      });
    }
  }

  function toggleDependentChildInfo() {
    const infoPanel = $(".js-dependent-child-information");
    if (this.value === "true") {
      infoPanel.show();
    } else {
      infoPanel.hide();
    }
  }

  function toggleSiblingsField() {
    const siblingNames = $(".js-sibling-names-field");
    if (this.checked) {
      $(".select2-selection__choice__remove").click();
      siblingNames.css("visibility", "hidden");
    } else {
      siblingNames.css("visibility", "visible");
    }
  }

  const $inputs = $(
    ".js-child-duplicates-fname, .js-child-duplicates-mname, .js-child-duplicates-lname"
  );
  $(".js-child-duplicates-lname").after(
    '<p class="js-child-duplicates-message inline-hints"></p>'
  );
  $inputs.on("keyup", debounce(suggestChildDuplicates, 100));
  $(".js-dependent-check input").change(toggleDependentChildInfo);
  $(".js-siblings-check input").change(toggleSiblingsField);
});
