import { Button, Menu } from "@heart/components";
import PropTypes from "prop-types";

/** A Heart `<Menu>` that's opened by a Heart `<Button>`!
 *
 * Pass it all the props for `<Menu>` (see `<Menu>` docs for those)
 * and pass all the props for your button into `buttonProps`.
 */
const ButtonMenu = ({ buttonProps, ...menuProps }) => (
  <Menu
    {...menuProps}
    buttonComponent={({ isOpen, ...menuButtonProps }) => (
      <Button {...buttonProps} {...menuButtonProps} />
    )}
  />
);

ButtonMenu.propTypes = {
  /** Heart `<Button>` props like `children`, `icon`, and `variant`.
   * Don't worry about `description` or `onClick`; those are provided by `Menu`. */
  buttonProps: PropTypes.object,
};

export default ButtonMenu;
