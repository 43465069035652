import { gql, useMutation, useQuery } from "@apollo/client";
import { LoadingOverlay } from "@heart/components";

import BintiPropTypes from "@lib/BintiPropTypes";

import WorkersAssignedForm from "./WorkersAssignedForm";

const updatePlacementSearchUnassignWorkerMutation = gql`
  mutation UpdatePlacementSearchUnassignWorker(
    $input: UpdatePlacementSearchUnassignWorkerInput!
  ) {
    updatePlacementSearchUnassignWorker(input: $input) {
      placementSearch {
        id
        agencyWorkers {
          id
          name
        }
      }
    }
  }
`;

const updatePlacementSearchSetPrimaryWorkerMutation = gql`
  mutation UpdatePlacementSearchSetPrimaryWorker(
    $input: UpdatePlacementSearchSetPrimaryWorkerInput!
  ) {
    updatePlacementSearchSetPrimaryWorker(input: $input) {
      placementSearch {
        id
        primaryWorkerAssignedId
      }
    }
  }
`;

const UpdateWorkersAssignedQuery = gql`
  query UpdateWorkersAssigned($placementSearchId: ID!) {
    placementSearch(id: $placementSearchId) {
      id
      agencyWorkers {
        id
        name
      }
      primaryWorkerAssignedId
    }
  }
`;

const UpdateWorkersAssigned = ({ placementSearchId }) => {
  const { data, loading: dataQueryLoading } = useQuery(
    UpdateWorkersAssignedQuery,
    { variables: { placementSearchId } }
  );

  const [unassignWorker, { loading: unassignLoading }] = useMutation(
    updatePlacementSearchUnassignWorkerMutation
  );

  const onWorkerUnassigned = agencyWorkerId =>
    unassignWorker({
      variables: { input: { placementSearchId, agencyWorkerId } },
    });

  const [setPrimaryWorkerAssigned, { loading: setPrimaryLoading }] =
    useMutation(updatePlacementSearchSetPrimaryWorkerMutation);

  const primaryWorkerChanged = workerId =>
    setPrimaryWorkerAssigned({
      variables: {
        input: {
          placementSearchId,
          primaryWorkerAssignedId: workerId,
        },
      },
    });

  return (
    <LoadingOverlay
      active={dataQueryLoading || unassignLoading || setPrimaryLoading}
    >
      <WorkersAssignedForm
        formState={data?.placementSearch}
        onPrimaryWorkerChanged={primaryWorkerChanged}
        onWorkerUnassigned={onWorkerUnassigned}
      />
    </LoadingOverlay>
  );
};

UpdateWorkersAssigned.propTypes = {
  placementSearchId: BintiPropTypes.ID,
};

export default UpdateWorkersAssigned;
