import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import AgencyDropdown from "@components/shared/AgencyDropdown";

import { sluggify } from "../../legacy/admin/sluggy";

const { Surface, InputText, LoadingOverlay } = require("@heart/components");

const emptyArray = [];

/**
 * BasicInfoForm component renders a form for basic information.
 *
 * @param {Object} formState - The state of the form.
 * @param {Function} setFormAttribute - Function to set form attributes.
 */
const BasicInfoForm = ({ formState, setFormAttribute }) => {
  const { data, loading } = useQuery(gql`
    query Agencies {
      agencies {
        id
        name
      }
    }
  `);
  const { agencies } = data || { agencies: emptyArray };
  const [slugManuallyEntered, setSlugManuallyEntered] = useState(false);

  useEffect(() => {
    if (!slugManuallyEntered) {
      const selectedAgency = agencies.find(a => a.id === formState.agency);
      setFormAttribute("slug")(
        sluggify([selectedAgency?.name, formState.name])
      );
    }
  }, [
    formState.agency,
    formState.name,
    setFormAttribute,
    slugManuallyEntered,
    agencies,
  ]);

  return (
    <LoadingOverlay active={loading}>
      <Surface title="Basic Info">
        <InputText
          type="text"
          label="Name"
          value={formState.name}
          onChange={setFormAttribute("name")}
        />
        <AgencyDropdown
          label={I18n.t("views.common.agency")}
          value={formState.agencyId}
          onChange={setFormAttribute("agencyId")}
        />
        <InputText
          type="text"
          label="Slug"
          value={formState.slug}
          onChange={() => {
            setSlugManuallyEntered(true);
            setFormAttribute("slug");
          }}
        />
      </Surface>
    </LoadingOverlay>
  );
};

BasicInfoForm.propTypes = {
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
};

export default BasicInfoForm;
