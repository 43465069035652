import { InputText } from "@heart/components";
import { APIProvider, useMapsLibrary } from "@vis.gl/react-google-maps";
import { omit, some } from "lodash";
import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";

import { GOOGLE_PLACES_API_KEY } from "@root/NonSecretFrontendKeys";

import { inputTextPropTypes } from "./common";

/**
 * Google's places API has fairly creative data structures. This is, I believe, the correct
 * way to extract basic address fields. I am aware that it seems like an bonkers piece of code.
 */
const extractAddress = addressComponents => {
  // first just extract components from the wild google array to an associative array.
  const normalizedComponents = {};
  addressComponents.forEach(component => {
    switch (component.types[0]) {
      case "street_number":
        normalizedComponents.streetNumber = component.long_name;
        break;
      case "route":
        normalizedComponents.streetName = component.long_name;
        break;
      case "subpremise":
        // This is a "best effort" to fill address line2 for people. It seems google's support
        // of unit numbers is inconsistent, both in terms of if it knows about them, and which
        // "component" it puts them in... but subpremise seems the best bet available...
        normalizedComponents.subPremise = component.long_name;
        break;
      case "locality":
        normalizedComponents.locality = component.long_name;
        break;
      case "administrative_area_level_2":
        normalizedComponents.administrative_area_level_2 = component.long_name;
        break;
      case "administrative_area_level_1": //  Note some countries don't have states
        normalizedComponents.administrative_area_level_1_name =
          component.long_name;
        normalizedComponents.administrative_area_level_1_code =
          component.short_name;
        break;
      case "postal_code":
        normalizedComponents.postalCode = component.long_name;
        break;
      case "country":
        normalizedComponents.countryCode = component.short_name;
        break;
      default:
        // estlint feels strongly about a default, in this case it seems superfluous.
        break;
    }
  });

  // then turn google fields into binti fields.
  return {
    addressLine1: `${normalizedComponents.streetNumber} ${normalizedComponents.streetName}`,
    addressLine2: normalizedComponents.subPremise,
    countryCode: normalizedComponents.countryCode,
    postalCode: normalizedComponents.postalCode,
    city: normalizedComponents.locality,
    primarySubdivisionCode:
      normalizedComponents.administrative_area_level_1_code,
    primarySubdivisionName:
      normalizedComponents.administrative_area_level_1_name,
    secondarySubdivision: normalizedComponents.administrative_area_level_2,
  };
};

// So using google's widget rather than data api solves a ton of front end quirks.
// It also disables your input on any error :| I figure people can type an address without
// autocomplete, so it's preferable to let The Show Go On.
// However, google does not expose error events in any reasonable way I can find -- the internet
// is full of people doing things like watching console.log events for them.
// This is a very "best effort" ("ok effort"?) method, it should generally not run, and should
// not be a problem if it errors. So while I am aware it is a hot mess of a function, it *may well*
// allow people to keep typing if google autocomplete misbehaves
const reenableInputIfTheGoogleLibraryTriesToDisableIt = mutationList => {
  if (!mutationList[0].target.disabled) return;
  const googleBeingDevious = some(
    mutationList,
    mutation =>
      mutation.type === "attributes" &&
      mutation.attributeName === "class" &&
      mutation.target.className.includes("gm-err-autocomplete")
  );
  if (!googleBeingDevious) {
    return;
  }
  const { target } = mutationList[0];
  target.classList.remove("gm-err-autocomplete"); // remove the error class
  target.disabled = false; // re-enable
  target.placeholder = ""; // remove a placeholder-as-error-message;
  target.style.backgroundImage = ""; // remove an angry exclamation mark.
};

/**
 * The exported component InputTextWithAddressAutocomplete adds a google APIProvider
 * In order for us to be able to use the APIs provided by that (i.e. our call to
 * useMapsLibrary), this component has to be a child component of the exported one.
 * Thus we separate the inner component (that has the interesting stuff) from the
 * exported one (that is just wrapping this one with an APIProvider).
 */
const InnerInputTextWithAddressAutocomplete = props => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary("places");
  const { autocompleteEnabled, onAddressPicked } = props;

  useEffect(() => {
    if (!autocompleteEnabled || !places || !inputRef.current) return () => {};

    const options = {
      componentRestrictions: { country: "us" }, // sorry 5 year later person doing an intl launch
      type: ["address", "establishments"],
      fields: ["address_components"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));

    const observer = new MutationObserver(
      reenableInputIfTheGoogleLibraryTriesToDisableIt
    );
    observer.observe(inputRef.current, { attributes: true });

    return () => observer.disconnect();
  }, [places, autocompleteEnabled]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      const place = placeAutocomplete.getPlace();
      const pickedAddress = extractAddress(place.address_components);
      onAddressPicked(pickedAddress);
    });
  }, [onAddressPicked, placeAutocomplete]);

  const forwardedProps = omit(props, "autocompleteEnabled");
  forwardedProps.ref = inputRef;

  return <InputText {...forwardedProps} />;
};

/**
 * Wrapper around InputText that adds google's address autocomplete.
 * Usage/props are identical to InputText with two exceptions:
 * enabled: This prop allows you to disable the autocomplete while keeping the input text acting
 * like a normal input text. Useful in cases where the autocompletion is conditional.
 * onAddressPicked: This is the callback that will be invoked when the user picks an address
 * from the autocomplete suggestions list. The callback is called with one argument, which is
 * the picked address. See the return value of extractAddress for the shape of the object.
 */
const InputTextWithAddressAutocomplete = props => (
  <APIProvider apiKey={GOOGLE_PLACES_API_KEY}>
    <InnerInputTextWithAddressAutocomplete {...props} />
  </APIProvider>
);

InputTextWithAddressAutocomplete.propTypes = {
  ...{
    autocompleteEnabled: PropTypes.bool.isRequired,
    onAddressPicked: PropTypes.func,
  },
  ...inputTextPropTypes,
};

InnerInputTextWithAddressAutocomplete.propTypes =
  InputTextWithAddressAutocomplete.propTypes;

export default InputTextWithAddressAutocomplete;
