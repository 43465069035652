import { Button, InputFilterableLegacyAjax } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import styles from "./ApplicationTemplateDesigner.module.scss";

const UploadTypeAdder = ({ onAdd, agencyId }) => {
  const [value, setValue] = useState("");

  return (
    <div className={styles.adder}>
      <div className={styles.searchBox}>
        <InputFilterableLegacyAjax
          name="UploadType"
          url="/admin/upload_types/slug_autocomplete"
          params={{
            agency_id: agencyId,
          }}
          onChange={({ value: newValue }) => setValue(newValue)}
          label="Add UploadType"
        />
      </div>
      <Button
        onClick={() => {
          onAdd(value);
          setValue("");
        }}
        className={styles.addButton}
      >
        +
      </Button>
    </div>
  );
};

UploadTypeAdder.propTypes = {
  onAdd: PropTypes.func.isRequired,
  agencyId: PropTypes.number.isRequired,
};

export default UploadTypeAdder;
