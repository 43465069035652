/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

//######################
// Allow users to select page size
// http://stackoverflow.com/questions/25653323/activeadmin-allow-users-to-set-per-page-value-in-index
//######################
$(function () {
  const $per_page_selector = $("#hidden_active_admin_per_page");
  if ($(".js-aggregate-report, .unpaginated_form").length === 0) {
    // don't show per page on aggregated indexes
    const per_page_qty = $per_page_selector.val();
    $per_page_selector.detach();
    $("form.filter_form .buttons").before(
      `\
<div class="select input optional filter_form_field filter_select" id="per_page_input"> \
<label for="hidden_active_admin_per_page" class="label">` +
        I18n.t("admin.filters.results_per_page") +
        `</label> \
<select name="per_page" id="hidden_active_admin_per_page"> \
<option selected="selected" value="20">20</option> \
<option value="50">50</option> \
<option value="100">100</option> \
</select> \
</div>`
    );
    if (typeof per_page_qty !== "undefined") {
      return $("#hidden_active_admin_per_page").val(per_page_qty);
    }
  }
});
