import PropTypes from "prop-types";

import Menu from "./Menu";

/** A Heart `<Menu>` that's opened by a Heart `<Button>`!
 *
 * Pass it all the props for `<Menu>` (see `<Menu>` docs for those)
 * and also an `icon` prop.
 */
const IconMenu = ({ icon: Icon, ...menuProps }) => (
  <Menu
    {...menuProps}
    buttonComponent={({ isOpen, ...buttonProps }) => (
      <Icon variant={isOpen ? "activated" : undefined} {...buttonProps} />
    )}
  />
);

IconMenu.propTypes = {
  /** A Heart icon component, like `Icons.ChevronDown` */
  icon: PropTypes.elementType,
};

export default IconMenu;
