import { max, min, values } from "lodash";
import PropTypes from "prop-types";
import { snakeCase } from "snake-case";

import styles from "../BintiSign.module.scss";
import { SIGNING_STATE } from "./signingOrderHelper";

const translateRoles = roles =>
  roles
    .map(role =>
      I18n.t(`activerecord.attributes.user/roles.${snakeCase(role)}`)
    )
    .join(", ");

const intermediateSignersExplanation = ({
  signingEvent,
  userRequiredSignatures,
}) => {
  const { nextAllowedSignatureOrder } = signingEvent;
  const nextUnsignedRoleOrder = min(
    userRequiredSignatures
      .map(({ order }) => order)
      .filter(order => order > nextAllowedSignatureOrder)
  );
  const earlierIncompleteRoles = signingEvent.requiredSignatures
    .filter(
      ({ order, isComplete }) => order < nextUnsignedRoleOrder && !isComplete
    )
    .map(({ role }) => role)
    .sort();

  return I18n.t("common.waiting_for_other_roles_to_sign_first", {
    roles: translateRoles(earlierIncompleteRoles),
  });
};

const earlierSignersExplanation = ({
  signingEvent,
  userRequiredSignatures,
}) => {
  const earliestUserRoleOrder = min(
    userRequiredSignatures.map(({ order }) => order)
  );
  const earlierRoles = signingEvent.requiredSignatures
    .filter(({ order }) => order < earliestUserRoleOrder)
    .map(({ role }) => role)
    .sort();
  return I18n.t("common.waiting_for_other_roles_to_sign_first", {
    roles: translateRoles(earlierRoles),
  });
};

const laterSignersExplanation = ({ signingEvent, userRequiredSignatures }) => {
  const latestUserRoleOrder = max(
    userRequiredSignatures.map(({ order }) => order)
  );
  const laterRoles = signingEvent.requiredSignatures
    .filter(({ order }) => order > latestUserRoleOrder)
    .map(({ role }) => role)
    .sort();
  return I18n.t("common.waiting_for_other_roles_to_sign_later", {
    roles: translateRoles(laterRoles),
  });
};

/**
 * When the user cannot sign, print an informative message about why.
 */
const CannotSignExplanation = ({
  signingEvent,
  userRequiredSignatures,
  signingState,
}) => {
  let message;

  switch (signingState) {
    case SIGNING_STATE.AWAITING_EARLIER_SIGNERS:
      message = earlierSignersExplanation({
        signingEvent,
        userRequiredSignatures,
      });
      break;
    case SIGNING_STATE.AWAITING_LATER_SIGNERS: {
      message = laterSignersExplanation({
        signingEvent,
        userRequiredSignatures,
      });
      break;
    }
    case SIGNING_STATE.AWAITING_INTERMEDIATE_SIGNERS: {
      message = intermediateSignersExplanation({
        signingEvent,
        userRequiredSignatures,
      });
      break;
    }
    default:
      return false;
  }

  return <div className={styles.waitingForSignature}>{message}</div>;
};

CannotSignExplanation.propTypes = {
  signingEvent: PropTypes.object.isRequired,
  userRequiredSignatures: PropTypes.array.isRequired,
  signingState: PropTypes.oneOf(values(SIGNING_STATE)),
};

export default CannotSignExplanation;
