// Polyfill to support closest in IE 11 for the below polyfill to use
import elementClosest from "element-closest";

elementClosest(window);

// Polyfill to support SubmitEvent.submitter
// Used in <Relationships /> to identify
// https://developer.mozilla.org/en-US/docs/Web/API/SubmitEvent/submitter
/* eslint-disable */
(function () {
  let lastBtn = null;
  document.addEventListener(
    "click",
    function (e) {
      if (!e.target.closest) return;
      lastBtn = e.target.closest("[type=submit]");
    },
    true
  );
  document.addEventListener(
    "submit",
    function (e) {
      if (e.submitter) return;
      e.submitter = lastBtn;
    },
    true
  );
})();
/* eslint-enable */
