import { Text } from "@heart/components";
import camelize from "camelize";
import PropTypes from "prop-types";
import React, { Component } from "react";

import T from "@components/T";

import Poller from "../../clients/Poller";
import Spinner from "../Spinner";
import styles from "./HelloSign.module.scss";
import HellosignSignatureButton from "./HellosignSignatureButton";

const t = (key, values) =>
  I18n.t(
    `javascript.components.hellosign.hellosign_signature_launcher.${key}`,
    values
  );

class HellosignSignatureLauncher extends Component {
  constructor(props) {
    super(props);

    if (props.initialSignatureState["pending?"]) {
      this.poller = new Poller(this.poll).start();
    }

    this.state = {
      signatureState: props.initialSignatureState,
    };
  }

  poll = stillPending => {
    const { pollUrl } = this.props;

    $.ajax({
      dataType: "json",
      url: pollUrl,
      success: signatureState => {
        this.setState({ signatureState: camelize(signatureState) });

        if (signatureState["pending?"]) {
          stillPending();
        }
      },
      error: stillPending,
    });
  };

  render() {
    const { role, disabled } = this.props;
    const {
      signatureState: {
        "pending?": pending,
        signerBlobs,
        "needSignatures?": needSignatures,
      },
    } = this.state;

    if (!needSignatures) return false;

    if (pending) {
      return (
        <div className={styles.signatureLauncher}>
          {t("preparing_your_document")}
          <Spinner />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className={styles.signatureLauncher}>
          {signerBlobs.map((blob, i) => {
            const { data, name } = blob;

            const key = (data && data.signatureId) || `${name}-${i}`;

            return (
              <HellosignSignatureButton
                key={key}
                {...{ blob, role }}
                disabled={disabled}
              />
            );
          })}
        </div>
        <If condition={disabled}>
          <Text>
            <T t="admin.applicant_view_button_help_text" />
          </Text>
        </If>
      </React.Fragment>
    );
  }
}

HellosignSignatureLauncher.propTypes = {
  role: PropTypes.string.isRequired,
  pollUrl: PropTypes.string.isRequired,
  initialSignatureState: PropTypes.shape({
    signerBlobs: PropTypes.array,
    "pending?": PropTypes.bool,
    "needSignatures?": PropTypes.bool,
    "laterSignatureRequired?": PropTypes.bool,
    "earlierSignatureRequired?": PropTypes.bool,
    "supervisorSignatureRequiredNext?": PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default HellosignSignatureLauncher;
