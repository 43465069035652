import { gql, useMutation } from "@apollo/client";
import { Button, Flex } from "@heart/components";
import _ from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import ItemListTable from "@components/reusable_ui/ItemListTable";

import PhoneNumbersByUser from "@graphql/queries/PhoneNumbersByUser";

import PhoneNumberForm from "./PhoneNumberForm";

const CreatePhoneNumberMutation = gql`
  mutation CreatePhoneNumber($input: CreatePhoneNumberInput!) {
    createPhoneNumber(input: $input) {
      phoneNumber {
        id
      }
    }
  }
`;

/**
 * CreatePhoneNumber component for creating a new phone number.
 *
 * @param {string} personType - The type of person (e.g., user, admin).
 * @param {string} personId - The ID of the person.
 * @param {function} handleSuccess - Callback function to handle success.
 * @param {function} onCancel - Callback function to handle cancel action.
 */
const CreatePhoneNumber = ({
  personType,
  personId,
  handleSuccess,
  onCancel,
}) => {
  const [phoneNumber, setPhoneNumber] = useState({
    id: null,
    category: null,
    phoneNumber: null,
    notes: null,
    personType,
    personId,
  });
  const setFormAttribute = attribute => value => {
    setPhoneNumber({ ...phoneNumber, [attribute]: value });
  };

  const [createPhoneNumber] = useMutation(CreatePhoneNumberMutation, {
    onCompleted: handleSuccess,
    refetchQueries: [
      { query: PhoneNumbersByUser, variables: { personId, personType } },
    ],
  });

  return (
    <div data-testid="phone-create-form">
      <div data-testid="phone-row">
        <ItemListTable
          headers={[
            "Phone Category",
            "Phone Number",
            "Change Primary Status",
            "Inactive Status",
          ]}
        >
          <PhoneNumberForm
            formState={phoneNumber}
            setFormAttribute={setFormAttribute}
          />
        </ItemListTable>
        <Flex justify="end">
          <Button
            onClick={() =>
              createPhoneNumber({
                variables: {
                  input: { ..._.omit(phoneNumber, "id"), personType, personId },
                },
              })
            }
          >
            Submit
          </Button>
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
        </Flex>
      </div>
    </div>
  );
};

CreatePhoneNumber.propTypes = {
  personType: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreatePhoneNumber;
