import { isEmpty, isNil } from "lodash";

export default ({
  createPerson,
  updatePerson,
  messageDisplay: { displayNotice },
  placementProviderId,
  agencyId,
  setAddingNewStaffMember,
  setEditingForStaffMember,
  staffMember,
  setlocalErrorMessages = () => {},
}) => {
  const onSuccess = () => {
    setEditingForStaffMember(false);
    if (!isNil(staffMember.id)) {
      displayNotice(
        I18n.t(
          "views.applications.application_staff_member.updated_successfully"
        )
      );
    }
    setlocalErrorMessages([]);
  };

  const onError = errors => {
    const errorMessages = errors
      .map(({ message }) =>
        message.includes("External identifier already taken for agency") ||
        message.includes("user already has role on provider")
          ? I18n.t(
              "views.applications.application_staff_member.person_id_in_use"
            )
          : message
      )
      .filter(Boolean);

    setlocalErrorMessages(errorMessages);
  };

  const variables = {
    placementProviderId: placementProviderId,
    agencyId: agencyId,
  };

  // Characteristics of the person
  const personAttributes = {
    role: "staffMember",
    subrole: staffMember.subrole,
    firstName: staffMember.firstName,
    middleName: staffMember.middleName,
    lastName: staffMember.lastName,
    suffix: staffMember.suffix,
    externalIdentifier: staffMember.externalIdentifier,
    joinedAt: staffMember.joinedAt,
    firstShiftDate: staffMember.firstShiftDate,
    dateOfBirth: staffMember.dateOfBirth,
  };

  variables.memberInput = personAttributes;

  if (!isNil(staffMember.id)) {
    variables.userAgencyProfileId = staffMember.userAgencyProfileId;
    updatePerson({ variables }).then(
      ({
        data: {
          updateHumanPlacementProviderRole: { errors: updateErrors },
        },
      }) => {
        if (isEmpty(updateErrors)) {
          onSuccess();
        } else {
          onError(updateErrors);
        }
      }
    );
  } else {
    createPerson({ variables }).then(
      ({
        data: {
          createHumanPlacementProviderRole: { errors: creationErrors },
        },
      }) => {
        if (isEmpty(creationErrors)) {
          setAddingNewStaffMember(false);
          onSuccess();
        } else {
          onError(creationErrors);
        }
      }
    );
  }
};
