/** module for Quill to set the aria-labelledby attribute on the editor */
/* don't copy this pattern for your labels unless you really know what
  you're doing. This approach is non-standard because we're dealing with
  a contenteditable editor. */

export default class QuillLabelledBy {
  constructor(quill, { id }) {
    quill.container
      .getElementsByClassName("ql-editor")[0]
      .setAttribute("aria-labelledby", id);
  }
}
