import { gql, useQuery } from "@apollo/client";
import {
  Layout,
  InputDropdown,
  InputText,
  SurfaceForm,
  Actions,
  InputFilterable,
  InputAddress,
} from "@heart/components";
import { useState } from "react";

import {
  ADDRESS_TYPE_MAILING,
  ADDRESS_TYPE_PHYSICAL_WORK,
} from "@root/constants";

export const ApplicationTemplateQuery = gql`
  query ApplicationTemplates {
    applicationTemplates {
      id
      title
      slug
    }
  }
`;

const IntakeProvider = () => {
  const [address, setAddress] = useState({
    id: "1",
    type: [ADDRESS_TYPE_PHYSICAL_WORK],
    addressLine1: "",
    addressLine2: "",
    city: "",
    primarySubdivision: "",
    countryCode: "US",
    postalCode: "",
    primary: true,
    inactive: false,
  });

  const [mailingAddress, setMailingAddress] = useState({
    id: "1",
    type: [ADDRESS_TYPE_MAILING],
    addressLine1: "",
    addressLine2: "",
    city: "",
    primarySubdivision: "",
    countryCode: "US",
    postalCode: "",
    primary: false,
    inactive: false,
  });

  const { data: applicationTemplatesData } = useQuery(ApplicationTemplateQuery);

  const [provider, setProviderInner] = useState("");
  const [isAddingProvider, setAddingProvider] = useState(false);

  const setProvider = newProvider => {
    if (newProvider === "create-new-provider") {
      setAddingProvider(true);
    } else {
      setAddingProvider(false);
    }
    setProviderInner(newProvider);
  };

  return (
    <Layout
      main={{
        content: (
          <SurfaceForm title="" actions={<Actions />}>
            <InputDropdown
              label="Application Template"
              onChange={() => {}}
              values={
                applicationTemplatesData
                  ? applicationTemplatesData.applicationTemplates.map(
                      ({ slug, title }) => ({
                        label: title,
                        value: slug,
                      })
                    )
                  : []
              }
              required
            />
            <InputDropdown
              label="Provider Name"
              onChange={setProvider}
              values={[
                ["The Thing", "the-thing-value"],
                ["Create new provider", "create-new-provider"],
              ]}
              value={provider}
              required
            />
            <If condition={isAddingProvider}>
              <InputText label="Create Provider" />
            </If>
            <InputText label="Provider ID" />
            <InputText label="Provider Federal Employer Identification Number (FEIN)" />
            <InputAddress
              value={address}
              onChange={setAddress}
              allowedAddressTypes={[ADDRESS_TYPE_PHYSICAL_WORK]}
              disablePrimaryCheckbox
              hideInactiveCheckbox
            />
            <If condition={!address.type.includes(ADDRESS_TYPE_MAILING)}>
              <InputAddress
                value={mailingAddress}
                onChange={setMailingAddress}
                allowedAddressTypes={[ADDRESS_TYPE_MAILING]}
                disablePrimaryCheckbox
                hideInactiveCheckbox
              />
            </If>
            <InputFilterable
              isMulti
              label="Licensing Workers Assigned"
              onChange={() => {}}
              values={[
                {
                  label: "The Thing",
                  value: "the-id",
                },
                {
                  label: "The Other Thing",
                  value: "the-other-id",
                },
              ]}
            />
            <InputFilterable
              isMulti
              label="Support Workers Assigned"
              onChange={() => {}}
              values={[
                {
                  label: "The Thing",
                  value: "the-id",
                },
                {
                  label: "The Other Thing",
                  value: "the-other-id",
                },
              ]}
            />
          </SurfaceForm>
        ),
      }}
      pageTitle="New Provider License"
    />
  );
};

export default IntakeProvider;
