import { useMutation } from "@apollo/client";
import { Link } from "@heart/components";
import PropTypes from "prop-types";

import CompleteFormInstance from "@graphql/mutations/CompleteFormInstance.graphql";

// TODO(ENG-7785): Make this usable in the Applications-side requirements table. Only
// used by the ChildRequirementsTable for now.
const CompleteFormInstanceLink = ({ formInstance, holderToken }) => {
  const [completeFormInstance] = useMutation(CompleteFormInstance);

  const onComplete = () => {
    completeFormInstance({
      variables: { formInstanceId: formInstance.id, holderToken: holderToken },
    });
  };
  return <Link onClick={onComplete}>sample fill</Link>;
};

CompleteFormInstanceLink.propTypes = {
  formInstance: PropTypes.object.isRequired,
  holderToken: PropTypes.string.isRequired,
};

export default CompleteFormInstanceLink;
