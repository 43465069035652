import { isEmpty } from "lodash";

export default ({ graphQLErrors, networkError }) => {
  let errors = [];
  if (!isEmpty(graphQLErrors)) {
    errors = graphQLErrors.map(({ message }) => message);
  }
  if (!isEmpty(networkError)) {
    errors.push(networkError);
  }

  return errors.join(", ");
};
