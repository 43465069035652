import PropTypes from "prop-types";

const PresentNotPresent = ({ value }) => {
  if (value) return <i className="fas fa-check" />;

  return <i className="fas fa-ban" />;
};

PresentNotPresent.propTypes = { value: PropTypes.bool };

export default PresentNotPresent;
