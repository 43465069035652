import { useLazyQuery, useMutation } from "@apollo/client";
import { Button } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import T from "@components/T";

import CreateAgencyWorkerSignature from "@graphql/mutations/CreateAgencyWorkerSignature.graphql";
import CreateUserAgencyProfileSignature from "@graphql/mutations/CreateUserAgencyProfileSignature.graphql";
import SigningEvent from "@graphql/queries/SigningEvent.graphql";

import ReadOnlySignature from "./ReadOnlySignature";
import styles from "./SignatureInput.module.scss";
import SignatureInputTypeSwitcher from "./SignatureInputTypeSwitcher";

// TODO figure out a good way to keep these in sync with the ruby side
const DIMENSIONS = {
  width: 253,
  height: 100,
};

/**
 * Displays the current signer's signature if it exists and allows users to
 * create a new signature if they need to or want to.
 */
const SignatureInput = ({
  activeSigner: { name, userAgencyProfile, signature },
  signingEvent,
}) => {
  const isSignaturePresent = !!signature;
  const [isLocked, setIsLocked] = useState(isSignaturePresent);
  const [createUserAgencyProfileSignature] = useMutation(
    CreateUserAgencyProfileSignature
  );
  const [createAgencyWorkerSignature] = useMutation(
    CreateAgencyWorkerSignature
  );
  const [refreshSigningQuery, { refetch }] = useLazyQuery(SigningEvent, {
    variables: { id: signingEvent.id },
    fetchPolicy: "network-only",
  });

  const afterCreateSignature = () => {
    if (refetch) {
      refetch();
    } else {
      refreshSigningQuery();
    }
    setIsLocked(true);
  };

  const updateSignatureUri = signatureUri => {
    if (userAgencyProfile) {
      createUserAgencyProfileSignature({
        variables: { userAgencyProfileId: userAgencyProfile.id, signatureUri },
      }).then(afterCreateSignature);
    } else {
      createAgencyWorkerSignature({ variables: { signatureUri } }).then(
        afterCreateSignature
      );
    }
  };

  return (
    <div className={styles.signatureInput}>
      {isLocked && isSignaturePresent && (
        <div>
          <ReadOnlySignature {...{ signature, ...DIMENSIONS }} />
          <Button
            onClick={() => {
              setIsLocked(false);
            }}
          >
            <T t="bintisign.signature_input.signature_input.change_your_signature" />
          </Button>
        </div>
      )}
      {!isLocked && (
        <SignatureInputTypeSwitcher
          cancel={() => {
            setIsLocked(true);
          }}
          canCancel={isSignaturePresent}
          {...{ name, updateSignatureUri }}
          {...DIMENSIONS}
        />
      )}
    </div>
  );
};

SignatureInput.propTypes = {
  activeSigner: PropTypes.object.isRequired,
  signingEvent: PropTypes.object.isRequired,
};

export default SignatureInput;
