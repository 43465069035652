import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Button,
  Flex,
  LoadingOverlay,
  PageContainer,
  Text,
} from "@heart/components";
import flattenErrorMessages from "@utils/flattenErrorMessages";
import { omit } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";

import CharacteristicsOpenToTable from "@components/placement_preferences/CharacteristicsOpenToTable";
import ChildYouthPreferences from "@components/placement_preferences/ChildYouthPreferences";

import CopyPlacementProviderPreferencesToAgencyPlacement from "@graphql/mutations/CopyPlacementProviderPreferencesToAgencyPlacement.graphql";
import UpdateAgencyPlacement from "@graphql/mutations/UpdateAgencyPlacement.graphql";

import useBanner from "@lib/useBanner";

import { BEHAVIOR_MEDICAL_OPTIONS } from "@root/constants";

import { Availability } from "./Availability";
import FamilyInformation from "./FamilyInformation";
import OldAvailability from "./OldAvailability";
import OtherInformation from "./OtherInformation";
import PlacementInformation from "./PlacementInformation";
import PreferencesConflictNotice from "./PreferencesConflictNotice";

export const AgencyPlacementQuery = gql`
  query AgencyPlacementQuery($agencyPlacementId: ID!) {
    agencyPlacement(agencyPlacementId: $agencyPlacementId) {
      id
      displayName
      agencyFamily
      groupHome
      additionalDirectionsToHome
      contactEmail
      contactInformation
      hasPetInHome
      therapeuticCare
      emergencyPlacement
      canCallAfterHours
      interestedInAdoption
      childDemographicsOpenTo {
        openToAllGenders
        gendersOpenTo
        ethnicitiesOpenTo
        numSiblingsOpenTo
        sexAssignedAtBirthOpenTo
        ageLowerOpenTo
        ageHigherOpenTo
      }
      childMedicalBehavioralCharacteristicsOpenTo
      availability
      bedsTemporarilyUnavailable
      comments
      capacity
      approvedAgeLower
      approvedAgeHigher
      placementInterests {
        slug
      }
      agency {
        stateAbbr
      }
    }
  }
`;

/** Modernized flow for editing placement information.
 *   Placements Dashboard > Row Action > Edit Placement Information
 */
// TODO: ENG-14163 Remove revised availability feature flag
export const EditPlacementInformation = ({
  agencyPlacementId,
  revisedAvailability,
}) => {
  const { errorBanner } = useBanner();
  // query for grabbing Agency Placement data
  const {
    loading: isLoadingAgencyPlacement,
    error: agencyPlacementQueryError,
    data: agencyPlacementQueryData,
    refetch: refetchAgencyPlacement,
  } = useQuery(AgencyPlacementQuery, {
    variables: { agencyPlacementId },
    onError: err => {
      errorBanner(flattenErrorMessages(err));
    },
  });

  // mutation for updating Agency Placement data
  const [updateAgencyPlacement, { loading: updatingAgencyPlacement }] =
    useMutation(UpdateAgencyPlacement, {
      onCompleted: () => {
        setRedirecting(true);
        window.location = `/admin/placements/${agencyPlacementId}`;
      },
      onError: err => {
        setRedirecting(false);
        errorBanner(flattenErrorMessages(err));
      },
    });

  const [
    copyPlacementProviderPreferencesToAgencyPlacement,
    { loading: copyingData },
  ] = useMutation(CopyPlacementProviderPreferencesToAgencyPlacement);

  const [redirecting, setRedirecting] = useState(false);
  const [comments, setComments] = useState();
  const [availability, setAvailability] = useState();
  const [bedsTemporarilyUnavailable, setBedsTemporarilyUnavailable] =
    useState(0);
  const [directions, setDirections] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [hasPetInHome, setHasPetInHome] = useState();
  const [therapeuticCare, setTherapeuticCare] = useState();
  const [emergencyFamily, setEmergencyFamily] = useState();
  const [canCallAfterHours, setCanCallAfterHours] = useState();
  const [characteristicsOpenTo, setCharacteristicsOpenTo] = useState();
  const [demographicsOpenTo, setDemographicsOpenTo] = useState();
  const [placementInterests, setPlacementInterests] = useState([]);
  const [contactInformation, setContactInformation] = useState();

  useEffect(() => {
    if (agencyPlacementQueryData) {
      const { agencyPlacement } = agencyPlacementQueryData;

      setComments(agencyPlacement.comments);
      setAvailability(agencyPlacement.availability);
      setBedsTemporarilyUnavailable(agencyPlacement.bedsTemporarilyUnavailable);
      setContactEmail(agencyPlacement.contactEmail);
      setDirections(agencyPlacement.additionalDirectionsToHome);
      setHasPetInHome(agencyPlacement.hasPetInHome);
      setTherapeuticCare(agencyPlacement.therapeuticCare);
      setEmergencyFamily(agencyPlacement.emergencyPlacement);
      setCanCallAfterHours(agencyPlacement.canCallAfterHours);
      setCharacteristicsOpenTo(
        agencyPlacement.childMedicalBehavioralCharacteristicsOpenTo
      );
      setDemographicsOpenTo(
        omit(agencyPlacement.childDemographicsOpenTo, "__typename")
      );
      setContactInformation(agencyPlacement.contactInformation);
      if (agencyPlacement.placementInterests) {
        setPlacementInterests(
          agencyPlacement.placementInterests.map(interest => interest.slug)
        );
      }
    }
  }, [agencyPlacementQueryData]);

  const onCommentsChanged = newComments => {
    setComments(newComments);
  };

  const onCharacteristicChanged = (slug, value) =>
    setCharacteristicsOpenTo({
      ...characteristicsOpenTo,
      [slug]: value,
    });

  const onDemographicsOpenToChanged = newObject =>
    setDemographicsOpenTo({
      ...demographicsOpenTo,
      ...newObject,
    });

  const onPlacementInterestsChanged = interests =>
    setPlacementInterests(interests);

  const updatePlacement = () => {
    const updateVariables = {
      agencyPlacementId: agencyPlacementId,
      additionalDirectionsToHome: directions,
      emergencyPlacement: emergencyFamily,
      agencyPlacementInput: {
        comments: comments,
        availability: availability,
        bedsTemporarilyUnavailable: bedsTemporarilyUnavailable,
        therapeuticCare: therapeuticCare,
        canCallAfterHours: canCallAfterHours,
        hasPetInHome: hasPetInHome,
        contactEmail: contactEmail,
        contactInformation: contactInformation,
      },
      childMedicalBehavioralCharacteristicsOpenTo: characteristicsOpenTo,
      childDemographicsOpenTo: demographicsOpenTo,
      placementInterests: placementInterests,
    };

    updateAgencyPlacement({ variables: updateVariables });
  };

  const copyDataOver = () =>
    copyPlacementProviderPreferencesToAgencyPlacement({
      variables: {
        agencyPlacementId: agencyPlacementId,
      },
    }).then(() => {
      refetchAgencyPlacement();
    });

  if (agencyPlacementQueryError) return <Fragment></Fragment>;

  const agencyPlacement = agencyPlacementQueryData
    ? agencyPlacementQueryData.agencyPlacement
    : {};

  return (
    <Fragment>
      <LoadingOverlay
        active={isLoadingAgencyPlacement || updatingAgencyPlacement}
      >
        <PageContainer fullWidth>
          <Flex justify="space-between" row>
            <Text textStyle="emphasis-300">
              Edit Placement Information for {agencyPlacement?.displayName}
            </Text>
            <Flex>
              <Button
                onClick={updatePlacement}
                type="submit"
                variant="primary"
                disabled={copyingData || redirecting}
                submitting={updatingAgencyPlacement}
              >
                Update Placement Information
              </Button>
            </Flex>
          </Flex>
          <If condition={agencyPlacement?.agencyFamily}>
            <PreferencesConflictNotice
              agencyPlacementId={agencyPlacementId}
              onApprove={copyDataOver}
              submitting={copyingData || redirecting}
            />
            <FamilyInformation
              directions={directions}
              onDirectionsChanged={setDirections}
              hasPetInHome={hasPetInHome}
              onHasPetChanged={setHasPetInHome}
              therapeuticCare={therapeuticCare}
              onTherapeuticCareChanged={setTherapeuticCare}
              emergencyFamily={emergencyFamily}
              onEmergencyFamChanged={setEmergencyFamily}
              canCallAfterHours={canCallAfterHours}
              onCanCallChanged={setCanCallAfterHours}
              state={agencyPlacement?.agency.stateAbbr}
            />
          </If>
          <If
            condition={
              !agencyPlacement?.agencyFamily &&
              agencyPlacement?.agency?.stateAbbr
            }
          >
            <PlacementInformation
              directions={directions}
              onDirectionsChanged={setDirections}
              contactEmail={contactEmail}
              onContactEmailChanged={setContactEmail}
              therapeuticCare={therapeuticCare}
              onTherapeuticCareChanged={setTherapeuticCare}
              contactInformation={contactInformation}
              onContactInformationChanged={setContactInformation}
              state={agencyPlacement?.agency.stateAbbr}
              groupHome={agencyPlacement?.groupHome}
            />
          </If>
          <ChildYouthPreferences
            title="Child/Youth Preferences"
            demographicsOpenTo={demographicsOpenTo || {}}
            onDemographicsOpenToChanged={onDemographicsOpenToChanged}
            approvedAgeLower={agencyPlacement?.approvedAgeLower || 0}
            approvedAgeHigher={agencyPlacement?.approvedAgeHigher || 21}
            placementInterests={placementInterests}
            onPlacementInterestsChanged={onPlacementInterestsChanged}
          />
          <CharacteristicsOpenToTable
            title="Characteristics, Medical Conditions, and Behaviors Open to"
            characteristicsOptions={BEHAVIOR_MEDICAL_OPTIONS}
            characteristicsOpenTo={characteristicsOpenTo || {}}
            onCharacteristicChanged={onCharacteristicChanged}
          />
          {revisedAvailability ? (
            <Availability
              agencyPlacementId={agencyPlacementId}
              bedsTemporarilyUnavailable={bedsTemporarilyUnavailable}
              capacity={agencyPlacement?.capacity}
              onBedsUpdated={value =>
                setBedsTemporarilyUnavailable(parseInt(value, 10))
              }
              writeMode
            />
          ) : (
            <OldAvailability
              agencyPlacementId={agencyPlacementId}
              capacity={agencyPlacement?.capacity}
              availability={availability}
              onAvailabilityChanged={a => setAvailability(parseInt(a, 10))}
            />
          )}
          <OtherInformation
            comments={comments}
            onCommentsChanged={onCommentsChanged}
          />
        </PageContainer>
      </LoadingOverlay>
    </Fragment>
  );
};

EditPlacementInformation.propTypes = {
  agencyPlacementId: PropTypes.number.isRequired,
  revisedAvailability: PropTypes.bool,
};

export default EditPlacementInformation;
