import { useApolloClient } from "@apollo/client";
import { Actions, InputTextarea, SurfaceForm } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import AgencyWorkersByEmail from "@graphql/queries/AgencyWorkersByEmail.graphql";

import preventDefault from "@lib/preventDefault";

const EnteringEmails = ({ onFinalized }) => {
  const apollo = useApolloClient();

  const [emails, setEmails] = useState("");
  const [emailsMissing, setEmailsMissing] = useState(null);

  const emailsProvided = emails.match(/\S+/g) || [];

  const onSubmit = preventDefault(() =>
    apollo
      .query({
        query: AgencyWorkersByEmail,
        variables: { emails: emailsProvided },
      })
      .then(({ data }) => {
        const emailsFound = data.agencyWorkers.map(aw => aw.email);
        const missing = emailsProvided.filter(
          email => !emailsFound.includes(email)
        );
        if (missing.length) {
          setEmailsMissing(missing);
        } else {
          onFinalized(emailsFound);
        }
      })
  );

  const error = isEmpty(emailsMissing) ? null : (
    <MissingEmails emails={emailsMissing} />
  );

  return (
    <SurfaceForm
      onSubmit={onSubmit}
      actions={<Actions primaryText="Preview Changes" />}
      title="Caseworkers To Modify"
    >
      <InputTextarea
        label="Caseworker email addresses, separated by new lines"
        onChange={setEmails}
        value={emails}
        error={error}
        required
      />
    </SurfaceForm>
  );
};

const MissingEmails = ({ emails }) => (
  <div>
    These email addresses couldn't be found:
    <ol data-testid="bulk-email-error">
      {emails.map((e, key) => (
        <li key={key}>{e}</li>
      ))}
    </ol>
  </div>
);

EnteringEmails.propTypes = {
  onFinalized: PropTypes.func.isRequired,
};

MissingEmails.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EnteringEmails;
