import { difference, extend } from "lodash";

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//######################
// Keep caseworker lists fresh
//######################

$(function () {
  const $approvals_worker_select = $(".approvals-worker-select");
  const $agency_select = $("#active_admin_content div.agency-select");

  const resetApprovalsWorkers = function (e) {
    // the data-testid attribute is used to signal to Cypress this worker input
    // is ready for interaction. Let's remove it in case it was previously
    // set (see below for when/where we set it)
    $("#application_approvals_worker_ids_input").removeAttr("data-testid");

    let agency_id = $agency_select.select2("val");
    if (!agency_id || agency_id.length === 0) {
      agency_id = $approvals_worker_select.data("agency-id");
    }
    const max_n_approvals_workers = parseInt(
      $approvals_worker_select.data("max-n-approvals-workers")
    );

    return $.get(
      "/admin/agencies/caseworkers_to_assign.json?agency_ids=" + agency_id
    ).done(function (response) {
      const allow_clear = $approvals_worker_select.hasClass("allow-clear");
      let select2opts = {
        data: response.data,
        allowClear: allow_clear,
        maximumSelectionLength: max_n_approvals_workers,
      };

      if (allow_clear) {
        // there's a quirk with single selects only where I found that I
        // needed to pass the placeholder in again to get the clearing to
        // work
        select2opts = extend(select2opts, { placeholder: "" });
      }

      $approvals_worker_select.select2(select2opts);

      // there's seems to be a layout bug in select2 4.0.3 when passing in
      // data like this
      $(".approvals-worker-select")
        .next(".select2")
        .attr("style", "min-width: 300px;");

      // Indicate to test suite that this element has been initialized
      $("#application_approvals_worker_ids_input").attr(
        "data-testid",
        "approvals-workers-reset"
      );
      if (e) {
        return $approvals_worker_select.select2("val", "");
      }
    });
  };

  if ($approvals_worker_select[0]) {
    $("#active_admin_content").on(
      "change",
      ".agency-select",
      resetApprovalsWorkers
    );
    resetApprovalsWorkers();
  }

  const $support_worker_select = $(".support-worker-select");

  const resetSupportWorkers = function (e) {
    // the data-testid attribute is used to signal to Cypress this worker input
    // is ready for interaction. Let's remove it in case it was previously
    // set (see below for when/where we set it)
    $("#application_support_worker_ids_input").removeAttr("data-testid");

    let agency_id = $agency_select.select2("val");
    if (!agency_id || agency_id.length === 0) {
      agency_id = $support_worker_select.data("agency-id");
    }
    const max_n_support_workers = parseInt(
      $support_worker_select.data("max-n-support-workers")
    );

    return $.get(
      "/admin/agencies/caseworkers_to_assign.json?agency_ids=" + agency_id
    ).done(function (response) {
      const allow_clear = $support_worker_select.hasClass("allow-clear");
      let select2opts = {
        data: response.data,
        allowClear: allow_clear,
        maximumSelectionLength: max_n_support_workers,
      };

      if (allow_clear) {
        // there's a quirk with single selects only where I found that I
        // needed to pass the placeholder in again to get the clearing to
        // work
        select2opts = extend(select2opts, { placeholder: "" });
      }

      $support_worker_select.select2(select2opts);

      // there's seems to be a layout bug in select2 4.0.3 when passing in
      // data like this
      $(".support-worker-select")
        .next(".select2")
        .attr("style", "min-width: 300px;");

      // Indicate to test suite that this element has been initialized
      $("#application_support_worker_ids_input").attr(
        "data-testid",
        "support-workers-reset"
      );

      if (e) {
        return $support_worker_select.select2("val", "");
      }
    });
  };

  if ($support_worker_select[0]) {
    $("#active_admin_content").on(
      "change",
      ".agency-select",
      resetSupportWorkers
    );
    return resetSupportWorkers();
  }
});

//######################
// Keep CBO worker list fresh
//######################

$(function () {
  const $cbo_select = $(".js-cbo-select");
  const $cbo_worker_select = $(".js-cbo-worker-select");
  const $cbo_worker_select_wrapper = $(".js-cbo-worker-select-wrapper");
  let cbo_ids_was = $cbo_select.select2("val");

  const resetCBOWorkers = function (e) {
    // the data-testid attribute is used to signal to Cypress this worker input
    // is ready for interaction. Let's remove it in case it was previously
    // set (see below for when/where we set it)
    $("#application_cbo_worker_ids_input").removeAttr("data-testid");

    const is_initial_page_load = !e;

    const cbo_ids_are = $cbo_select.select2("val");
    if (!cbo_ids_are || cbo_ids_are.length === 0) {
      return $cbo_worker_select_wrapper.hide();
    } else {
      $cbo_worker_select_wrapper.show();
      const ids = cbo_ids_are.join(",");
      return $.get(
        "/admin/agencies/caseworkers_to_assign.json?agency_ids=" + ids
      ).done(function (response) {
        if (response.error) {
          return;
        }

        const new_cbo_ids = difference(cbo_ids_are, cbo_ids_was);
        const allow_clear = $cbo_worker_select.hasClass("allow-clear");

        const current_cbo_workers = is_initial_page_load
          ? $cbo_worker_select.data("cbo-workers")
          : response.data.filter(function (worker) {
              let needle;
              return (
                (needle = worker.id.toString()),
                Array.from($cbo_worker_select.val()).includes(needle)
              );
            });

        const max_n_cbo_workers =
          $cbo_worker_select.data().select2opts.maximumSelectionLength;

        // tricky logic. first let's ensure the current workers are selected,
        // then we'll also select default recruiters if there's room and we're
        // not on the initial page load
        let n_selected_workers = 0;
        let data = response.data.map(function (worker) {
          if (
            current_cbo_workers.find(
              worker_worker => worker.id === worker_worker.id
            ) &&
            n_selected_workers < max_n_cbo_workers
          ) {
            worker.selected = true;
            n_selected_workers++;
          }

          return worker;
        });

        // now select default recruiters if not initial page load and slots are
        // available
        if (!is_initial_page_load) {
          data = data.map(function (worker) {
            let needle;
            if (
              worker.is_default_recruiter &&
              ((needle = worker.agency_id.toString()),
              Array.from(new_cbo_ids).includes(needle)) &&
              n_selected_workers < max_n_cbo_workers
            ) {
              worker.selected = true;
              n_selected_workers++;
            }

            return worker;
          });
        }

        const select2opts = extend($cbo_worker_select.data().select2opts, {
          data,
          allowClear: allow_clear,
        });

        $cbo_worker_select.html("");

        $cbo_worker_select.select2(select2opts);

        $(".cbo-worker-select")
          .next(".select2")
          .attr("style", "min-width: 300px;");

        // Indicate to test suite that this element has been initialized
        $("#application_cbo_worker_ids_input").attr(
          "data-testid",
          "cbo-workers-reset"
        );

        // save selected CBOs to see when they change
        return (cbo_ids_was = cbo_ids_are);
      });
    }
  };

  if ($cbo_select[0]) {
    $("#active_admin_content").on("change", ".js-cbo-select", resetCBOWorkers);
  }

  return resetCBOWorkers();
});
