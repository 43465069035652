import { gql, useMutation } from "@apollo/client";
import {
  Modal,
  InputDate,
  InputCheckbox,
  InputDropdown,
} from "@heart/components";
import I18n from "i18n-js";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState, Fragment } from "react";

import determineUserAgencyProfile from "../determineUserAgencyProfile";
import StaffMemberCard from "./StaffMemberCard";
import removeStaffMember from "./removeStaffMember";
import {
  buildDeleteIconAction,
  buildEditIconAction,
  StaffMemberFragment,
} from "./staffMemberUtils";

const StaffMember = ({
  // Model data
  agencyId,
  staffMember,
  placementProviderId,
  // React state
  setEditingForStaffMember,
  messageDisplay,
}) => {
  const [removing, setRemoving] = useState(false);
  const [leftAtDate, setLeftAtDate] = useState(
    DateTime.local().toFormat("yyyy-MM-dd")
  );
  const [reasonForLeaving, setReasonForLeaving] = useState();
  const [isErroneousStaffMember, setErroneousStaffMember] = useState(false);

  const RemoveStaffMember = gql`
    ${StaffMemberFragment}
    mutation RemoveStaffMember(
      $placementProviderId: ID!
      $userAgencyProfileId: ID!
      $role: String!
      $id: ID
      $leftAt: ISO8601Date
      $reasonForLeaving: String
      $isErroneous: Boolean
    ) {
      removeHumanPlacementProviderRole(
        input: {
          placementProviderId: $placementProviderId
          userAgencyProfileId: $userAgencyProfileId
          role: $role
          leftAt: $leftAt
          reasonForLeaving: $reasonForLeaving
          isErroneous: $isErroneous
          id: $id
        }
      ) {
        placementProvider {
          id
          staffMembers {
            ...StaffMember
          }
        }
        errors {
          attribute
          message
          type
        }
      }
    }
  `;

  const [removeMember] = useMutation(RemoveStaffMember);

  const userAgencyProfile = determineUserAgencyProfile({
    agencyId,
    person: staffMember,
  });

  const removeStaff = ({ setRemovingStaffMember, isErroneous }) => {
    removeStaffMember({
      messageDisplay: messageDisplay,
      placementProviderId,
      userAgencyProfileId: userAgencyProfile.id,
      removeMember,
      leftAt: leftAtDate,
      reasonForLeaving,
      isErroneous,
      setRemoving: setRemovingStaffMember,
      staffMemberId: staffMember.id,
    });
  };

  const iconActions = [
    buildDeleteIconAction({ staffMember, removing, setRemoving }),
    buildEditIconAction({ staffMember, setEditingForStaffMember }),
  ];

  const { dateOfBirth, externalIdentifier } = userAgencyProfile;

  const reasonForLeavingOptions = ["disqualified", "resigned", "terminated"];

  return (
    <Fragment>
      <StaffMemberCard
        iconActions={iconActions}
        person={{
          ...staffMember,
          dateOfBirth: dateOfBirth,
          externalIdentifier: externalIdentifier,
        }}
      />
      <Modal
        hidden={!removing}
        title={I18n.t(
          "views.applications.application_staff_member.remove_confirmation"
        )}
        onCancel={() => setRemoving(false)}
        onSubmit={() =>
          removeStaff({
            setRemovingStaffMember: setRemoving,
            isErroneous: isErroneousStaffMember,
          })
        }
        submitDisabled={!isErroneousStaffMember && !reasonForLeaving}
        displayLargeTitle
      >
        <div className="contains-inputs">
          <InputDate
            label={I18n.t(
              "views.applications.application_staff_member.left_at_date"
            )}
            name="staffMember[left_at]"
            id="left-at-picker"
            value={leftAtDate}
            minDate={staffMember.joinedAt}
            onChange={setLeftAtDate}
            required
          />
          <InputCheckbox
            value={isErroneousStaffMember}
            onChange={() => setErroneousStaffMember(!isErroneousStaffMember)}
            label={I18n.t(
              "views.applications.application_staff_member.created_in_error"
            )}
          />
          {!isErroneousStaffMember && (
            <InputDropdown
              label={I18n.t(
                "views.applications.application_staff_member.reason_for_leaving"
              )}
              values={reasonForLeavingOptions.map(reason => ({
                label: I18n.t(
                  `views.applications.application_staff_member.reason_for_leaving_options.${reason}`
                ),
                value: reason,
              }))}
              name="reasonForLeaving"
              value={staffMember.reasonForLeaving}
              onChange={setReasonForLeaving}
              required
            />
          )}
        </div>
      </Modal>
    </Fragment>
  );
};

StaffMember.propTypes = {
  agencyId: PropTypes.number.isRequired,
  staffMember: PropTypes.shape({
    id: PropTypes.string,
    userId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    subrole: PropTypes.string,
    joinedAt: PropTypes.string,
    firstShiftDate: PropTypes.string,
    leftAt: PropTypes.string,
    reasonForLeaving: PropTypes.string,
    userAgencyProfiles: PropTypes.arrayOf(
      PropTypes.shape({
        dateOfBirth: PropTypes.string,
        externalIdentifier: PropTypes.string,
      })
    ),
  }),
  messageDisplay: PropTypes.object.isRequired,
  placementProviderId: PropTypes.number.isRequired,
  setEditingForStaffMember: PropTypes.func.isRequired,
};

export default StaffMember;
