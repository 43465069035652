import PropTypes from "prop-types";
import React from "react";

import SurfaceBase from "../surface/SurfaceBase";
import InputContainer from "./InputContainer";

/**
 * This is a Surface that contains inputs and is part of a larger
 * form with a global submit button, most analogous to a
 * `<fieldset>` in HTML.
 *
 * In general, you should prefer using `<SurfaceForm>` to create forms
 * which are self-contained and independently-submittable forms atop a
 * Surface.  This component exists primarily for legacy cases where Rails
 * `form_for` creates the `<form>` wrapper and submit buttons and React is
 * responsible for creating all of the form's inputs.
 */
const SurfaceFieldset = ({
  title,
  subtitle,
  children,
  secondary,
  collapsible,
  defaultCollapsed,
  "data-testid": testId,
}) => (
  <SurfaceBase
    rootElement="section"
    title={title}
    subtitle={subtitle}
    data-testid={testId}
    secondary={secondary}
    collapsible={collapsible}
    defaultCollapsed={defaultCollapsed}
  >
    <InputContainer>{children}</InputContainer>
  </SurfaceBase>
);

SurfaceFieldset.propTypes = {
  /** Title of the fieldset, displayed in the top left. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Subtitle of the fieldset, displayed in the top left */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Secondary content - usually a call-to-action or a filter input,
   * displayed in the top right */
  secondary: PropTypes.node,
  /** Put all the inputs as children without a container. */
  children: PropTypes.node.isRequired,
  /** Whether the surface fieldset should be collapsible using a toggle in the top right
   * corner of the surface fieldset
   */
  collapsible: PropTypes.bool,
  /** Whether the surface fieldset should default to being collapsed on first load. Defaults
   * to false, and only usable in conjunction with `collapsible`
   */
  defaultCollapsed: PropTypes.bool,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default SurfaceFieldset;
