import { Button, Flex, Link } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  collatedDocumentsApplicationPath,
  regenerateCollatedDocumentsAdminApplicationPath,
} from "routes";

import PdfOfAllDocsDebuggingModal from "./PdfOfAllDocsDebuggingModal";

const ApplicationPDFButton = ({ application, currentUserBintiAdmin }) => {
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  return (
    <Flex column>
      <PdfOfAllDocsDebuggingModal
        applicationId={application.id}
        isOpen={showDebugInfo}
        onClose={() => {
          setShowDebugInfo(false);
        }}
      />
      <Flex row>
        <Button
          variant="secondary"
          target="_blank"
          rel="noopener noreferrer"
          href={collatedDocumentsApplicationPath(application.id, {
            format: "pdf",
          })}
        >
          {I18n.t(
            "javascript.components.application_requirements.application_pdf_button.pdf_of_all_documents"
          )}
        </Button>
      </Flex>
      <If condition={currentUserBintiAdmin && application.collatedPdf}>
        <p>
          <i>
            {I18n.t(
              "javascript.components.application_requirements.application_pdf_button.generated"
            )}{" "}
            {I18n.l(
              "time.formats.datetime",
              new Date(application.collatedPdf.createdAt)
            )}
          </i>
        </p>
        <Link
          role="button"
          href={regenerateCollatedDocumentsAdminApplicationPath(application.id)}
          data-method="post"
        >
          {I18n.t(
            "javascript.components.application_requirements.application_pdf_button.force_regenerate"
          )}
        </Link>
        <If condition={application.collatedPdf.isProcessing}>
          <Flex row>
            <Link
              role="button"
              onClick={() => {
                setShowDebugInfo(true);
              }}
            >
              Regeneration in process. Click for more info.
            </Link>
          </Flex>
        </If>
      </If>
    </Flex>
  );
};

ApplicationPDFButton.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // GraphQL serializes as string, Rails views as int
    collatedPdf: PropTypes.shape({
      createdAt: PropTypes.string,
      isProcessing: PropTypes.bool,
    }),
  }),
  currentUserBintiAdmin: PropTypes.bool.isRequired,
};

export default ApplicationPDFButton;
