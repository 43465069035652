import I18n from "i18n-js";
import PropTypes from "prop-types";
import { useCallback, useRef, useState } from "react";

import Modal from "./Modal";

/**
 * React hook for managing a confirm modal that handles the async state
 * management of whether the user has confirmed or canceled the modal.
 * This hook is sort of the React equivalent of window.confirm.
 *
 * The ConfirmModal component that is returned may be used like a regular Modal
 * with the exception that the hidden prop is managed by the hook.
 *
 * The confirm function opens the model and returns an awaitable promise that
 * resolves to true if the user confirms (submits) the modal, and false if
 * the user cancels the modal.
 *
 * The modal is closed by clicking the submit or cancel button.
 *
 * Note that you can change the text of the submit and cancel buttons by passing
 * submitText and cancelText props to the ConfirmModal component.
 *
 * @returns {{
 *  ConfirmModal: React.ComponentType<ModalProps>,
 *  confirm: () => Promise<boolean>
 * }}
 */
const useConfirmModal = () => {
  const isConfirmedResolve = useRef();

  const confirm = useCallback(() => {
    setIsOpen(true);

    return new Promise(resolve => {
      isConfirmedResolve.current = resolve;
    });
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const ConfirmModal = ({
    onSubmit,
    onCancel,
    title = I18n.t("common.are_you_sure"),
    ...otherModalProps
  }) => (
    <Modal
      {...otherModalProps}
      title={title}
      hidden={!isOpen}
      onSubmit={() => {
        isConfirmedResolve.current(true);
        const onSubmitValue = onSubmit?.();
        setIsOpen(false);
        return onSubmitValue;
      }}
      onCancel={() => {
        isConfirmedResolve.current(false);
        const onCancelValue = onCancel?.();
        setIsOpen(false);
        return onCancelValue;
      }}
    />
  );
  ConfirmModal.propTypes = {
    ...Modal.propTypes,
    /** Title of modal, will default to "Are you sure?" if not provided */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Whether modal should be hidden or not, handled by this component
     * internally, so it should not need to be set. Redefining it here to
     * avoid prop type warnings as it's a required prop for Modal
     */
    hidden: PropTypes.bool,
  };

  return {
    ConfirmModal,
    confirm,
  };
};

export default useConfirmModal;
