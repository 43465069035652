import { REACT_FORM_LOADED } from "./events";

// #####################
// Are you sure you want to leave w/o saving?
//
// Make sure user doesn't leave with unsaved changes
// #####################
$(() => {
  // this is definitely the best way to focus on the first field
  // doing it with the autofocus attribute requires recursive tracking to
  // identify only the first of the first of the first of the first, etc
  const $form = $("form[id*='questionnaire_response']");
  if ($form.length > 0) {
    const $hasError = $form.find(".has-error").first();
    if ($hasError.length > 0) {
      $hasError
        .closest(".question")
        .find(":input:enabled:visible:first")
        .focus();
    } else {
      $form.find(":input:enabled:visible:first").focus();
    }
  }

  let successfulSubmit = false;
  $("form.js-check-dirty-and-warn").on("submit", () => {
    successfulSubmit = true;

    return true;
  });

  const formState = () =>
    $(
      ".js-check-dirty-and-warn textarea, \
       .js-check-dirty-and-warn select, \
       .js-check-dirty-and-warn input[type=text], \
       .js-check-dirty-and-warn input[type=date], \
       .js-check-dirty-and-warn input[type=checkbox], \
       .js-check-dirty-and-warn input[type=radio]"
    ).serialize();

  let originalFormContent = formState();

  // Our React-rendered forms may finish rendering after page load, so
  // listen to this event and update the "original" content in case
  // that happens.
  $(".js-check-dirty-and-warn").on(REACT_FORM_LOADED, () => {
    originalFormContent = formState();
  });

  let preventedDoubleSubmit = false;
  // prevent double submission of form
  // cf. https://stackoverflow.com/questions/926816/how-to-prevent-form-from-submitting-multiple-times-from-client-side
  $form.on("submit", function () {
    if (preventedDoubleSubmit) return true;

    // Do not make this a fat arrow - jQuery needs to bind `this` ^^^
    setTimeout(
      () =>
        // without wrapping this line in a setTimeout, Chrome 55 will not send the
        // special POST parameter coming from the <button>'s name and value
        // TODO test this behavior in browsers other than Chrome
        $(this).find("input[type='submit']").attr("disabled", true),
      0
    );

    preventedDoubleSubmit = true;

    return true;
  });

  window.Binti.onBeforeUnloadCallbacks.push(() => {
    if (successfulSubmit) {
      return undefined;
    }

    const content = formState();

    if (content !== originalFormContent) {
      // If we return any non-empty string, the browser shows "Are you sure you
      // want to leave this page?" The message is not customizable for security
      // reasons.
      //
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
      return " ";
    }

    return undefined;
  });
});
