import PropTypes from "prop-types";

const DocumentTableDataCell = ({ header, children }) => (
  <span>
    <span className="show-small table-header">{header}: </span>
    {children}
  </span>
);

DocumentTableDataCell.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default DocumentTableDataCell;
