import { Link, FlexItem, Text } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { policy } from "@lib/graphqlHelpers";

const childName = ({ fullName, dependentName, respite }) => {
  let name = fullName;
  if (dependentName) {
    name = `${fullName} and their child ${dependentName}`;
  }
  if (respite) {
    name = `${name} (respite)`;
  }
  return name;
};

const ChildrenInHome = ({ placedChildren, respiteChildren }) => {
  const numberOfChildren = placedChildren.length + respiteChildren.length;
  const childrenPresent = numberOfChildren > 0;

  const renderChild = (child, childText, index) => {
    let Component = Text;
    let componentProps = { textColor: "neutral-600" };
    if (policy(child).mayShow()) {
      Component = Link;
      componentProps = { href: `/admin/children/${child.id}` };
    }
    return (
      <Fragment key={child.id}>
        <Component {...componentProps}>{childText}</Component>
        <If condition={index < numberOfChildren - 1}> | </If>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Text textColor="neutral-500">Children in home</Text>
      <FlexItem>
        <If condition={childrenPresent}>
          {[
            ...placedChildren,
            ...respiteChildren.map(child => ({ ...child, respite: true })),
          ].map((child, index) => renderChild(child, childName(child), index))}
        </If>
        <If condition={!childrenPresent}>
          <Text textColor="neutral-600">None</Text>
        </If>
      </FlexItem>
    </Fragment>
  );
};

ChildrenInHome.propTypes = {
  placedChildren: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      dependentName: PropTypes.string,
    })
  ),
  respiteChildren: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      dependentName: PropTypes.string,
    })
  ),
};

export default ChildrenInHome;
