import { Flex } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./PageContainer.module.scss";

/** MVP implementation of a page container, the "base layer" of our
 * main application content between the header and the footer.
 *
 * It currently just provides padding between Surfaces and the page margins
 * (and each other) - it does not set the background color as it only satisfies
 * the use case of ActiveAdmin page content for now.
 */
const PageContainer = ({ children, fullWidth = false }) => (
  <Flex
    column
    gap="300"
    className={classNames(styles.container, {
      [styles.widthConstrained]: !fullWidth,
    })}
  >
    {children}
  </Flex>
);

PageContainer.propTypes = {
  children: PropTypes.node,
  /** Whether or not the content column should take up the full width available
   * (mostly for dashboard tables and non-form layouts) */
  fullWidth: PropTypes.bool,
};

export default PageContainer;
