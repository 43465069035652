import { gql, useMutation } from "@apollo/client";
import { Button, Text } from "@heart/components";
import PropTypes from "prop-types";

import PhoneNumberFragment from "@graphql/fragments/PhoneNumber.graphql";
import PhoneNumbersByUser from "@graphql/queries/PhoneNumbersByUser";

import BintiPropTypes from "@lib/BintiPropTypes";

const MarkPhoneNumberAsPrimary = gql`
  ${PhoneNumberFragment}
  mutation MarkPhoneNumberAsPrimary($input: MarkPhoneNumberAsPrimaryInput!) {
    markPhoneNumberAsPrimary(input: $input) {
      phoneNumber {
        ...PhoneNumber
      }
    }
  }
`;

/**
 * Component to display the primary status of a phone number.
 *
 * @param {Object} props.phoneNumber - The phone number object.
 */
const PrimaryStatus = ({ phoneNumber, personId, personType }) => {
  const [markPhoneNumberAsPrimary, { loading }] = useMutation(
    MarkPhoneNumberAsPrimary,
    {
      refetchQueries: [
        {
          query: PhoneNumbersByUser,
          variables: { personId, personType },
        },
      ],
    }
  );

  if (phoneNumber.active && phoneNumber.primary) {
    return <Text>primary</Text>;
  }

  if (phoneNumber.active) {
    return (
      <Button
        submitting={loading}
        variant="secondary"
        onClick={() =>
          markPhoneNumberAsPrimary({
            variables: { input: { id: phoneNumber.id } },
          })
        }
      >
        mark as primary
      </Button>
    );
  }

  return <Text>(inactive)</Text>;
};

PrimaryStatus.propTypes = {
  phoneNumber: PropTypes.shape({
    id: PropTypes.string.isRequired,
    category: PropTypes.string,
    formattedNumber: PropTypes.string,
    phoneNumber: PropTypes.string,
    notes: PropTypes.string,
    primary: PropTypes.bool,
    active: PropTypes.bool,
  }).isRequired,
  personId: BintiPropTypes.ID,
  personType: PropTypes.string,
};

export default PrimaryStatus;
