import classNames from "classnames";
import PropTypes from "prop-types";

import EnvironmentName from "./EnvironmentName";
import styles from "./Header.module.scss";
import LogoWhite from "./logo-white.svg";

/**
 * A compact version of Binti's header, used in the following places:
 *   * Unauthenticated routes like our login page
 *   * During the user's signup process before they arrive at the applicant portal
 *   * For admin flows like "View as Applicant" and "Assign Myself Template"
 */
export const MinimalHeader = ({ rootPath, authenticated, k8sNamespace }) => (
  <header>
    <nav className={classNames(styles.container, styles.unauthenticated)}>
      <a
        href={rootPath}
        className={classNames(styles.logoContainer, styles[k8sNamespace])}
        data-testid="header-logo"
      >
        {/** TODO ENG-14960 translate this */}
        <img src={LogoWhite} alt="Binti Logo" />
        <EnvironmentName k8sNamespace={k8sNamespace} />
      </a>
      <If condition={authenticated}>
        <a
          rel="nofollow"
          data-method="delete"
          href="/users/logout"
          className={styles.desktopHeaderFolder}
        >
          {/** TODO ENG-14960 translate this */}
          Logout
        </a>
      </If>
      <If condition={!authenticated}>
        <a href="/users/login" className={styles.desktopHeaderFolder}>
          {/** TODO ENG-14960 translate this */}
          Log In
        </a>
      </If>
    </nav>
  </header>
);

MinimalHeader.propTypes = {
  rootPath: PropTypes.string,
  authenticated: PropTypes.bool,
  k8sNamespace: PropTypes.string.isRequired,
};

export default MinimalHeader;
