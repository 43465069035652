import PropTypes from "prop-types";

import ExpandableContent from "@components/reusable_ui/ExpandableContent";

const MAX_NON_TRUNCATED_LENGTH = 140;

const Comments = ({ comments }) => {
  if (!comments) return null;

  if (comments.length <= MAX_NON_TRUNCATED_LENGTH) {
    return <pre>{comments}</pre>;
  }

  return (
    <ExpandableContent>
      <pre>{comments}</pre>
    </ExpandableContent>
  );
};

Comments.propTypes = {
  comments: PropTypes.string,
};

export default Comments;
