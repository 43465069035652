import { Flex } from "@heart/components";
import PropTypes from "prop-types";

import {
  useInputId,
  useInputLabel,
  useInputDescription,
  useInputError,
  useInputProps,
  useInputContainerClassName,
} from "./";

/**
 * This is your run-of-the-mill input layout - just a container
 * that has the label above the input and extra information below it.
 */
const BasicInputLayout = ({ inputComponent, ...props }) => {
  const id = useInputId(props);
  const label = useInputLabel({ ...props, id });
  const description = useInputDescription(props);
  const error = useInputError(props);
  const inputProps = useInputProps({ ...props, id });
  const containerClass = useInputContainerClassName(props);

  return (
    <Flex column className={containerClass}>
      {label}
      {description}
      {inputComponent(inputProps)}
      {error}
    </Flex>
  );
};

BasicInputLayout.propTypes = {
  /** A component that renders an input, given `useInputProps` as its props. */
  inputComponent: PropTypes.func.isRequired,
};

export default BasicInputLayout;
