/* eslint-disable no-new */
import { Chart, registerables } from "chart.js";

$(() => {
  const $lineCharts = $(".js-line-chart");
  const $pieCharts = $(".js-pie-chart");
  const $scatterCharts = $(".js-scatter-chart");
  const $stackedBarCharts = $(".js-stacked-bar-chart");
  const $barCharts = $(".js-bar-chart");
  Chart.register(...registerables);
  $lineCharts.toArray().forEach(ctx => {
    const chartOptions = $(ctx).data("chart-options");
    // we don't have a "deep merge" library available, so I'm very carefully
    // setting these options recursively only if they are unset
    if (chartOptions.legend === undefined) {
      chartOptions.legend = {};
    }
    if (chartOptions.legend.labels === undefined) {
      chartOptions.legend.labels = {
        // the reason for this is that chart.js doesn't support declarative
        // hiding of the legend on a per-dataset basis. we can do it though
        // by injecting the legend=false on the dataset and checking for it here
        // cf. https://stackoverflow.com/a/47654364/283398
        filter: (item, chart) =>
          !(chart.datasets[item.datasetIndex].legend === false),
      };
    }
    if (!chartOptions.plugins) {
      chartOptions.plugins = {};
    }
    chartOptions.plugins.legend = chartOptions.legend;
    chartOptions.legend = null;

    new Chart(ctx.getContext("2d"), {
      type: "line",
      data: $(ctx).data("chart-data"),
      options: chartOptions,
    });
  });

  $pieCharts.toArray().forEach(ctx => {
    new Chart(ctx.getContext("2d"), {
      type: "pie",
      data: $(ctx).data("chart-data"),
      options: {
        maintainAspectRatio: false,
        legend: { position: "right" },
      },
    });
  });

  $scatterCharts.toArray().forEach(ctx => {
    const scatterData = $(ctx).data("chart-data");
    const chartOptions = $(ctx).data("chart-options") || { plugins: {} };

    // NOTE pretty specific to Time to approval report
    const chart = new Chart(ctx.getContext("2d"), {
      type: "scatter",
      data: $(ctx).data("chart-data"),
      yAxisID: "Amount of Times",
      options: {
        maintainAspectRatio: false,
        showLines: false, // MUST be false and overridden by line dataset,
        // otherwise chart.js will show lines for the *scatter* dataset
        plugins: {
          tooltip: {
            callbacks: {
              label: context => {
                if (context.raw.label) {
                  return context.raw.label;
                }
                return `${context.dataset.label}: ${context.parsed.y}`;
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              stepSize: 1,
              // min and max are offset by 1, to hack around points on the
              // Y-axis being hidden
              min: 0,
              max: scatterData.labels.length - 1,
              // "category" option has weird behavior with scatter plot (it
              // causes chart.js to plot only the first point in each category)
              // hacking the x axis tick labels this way instead
              callback: value => scatterData.labels[value],
            },
            grid: {
              display: false,
            },
          },
          ...(chartOptions.yAxes ? { y: chartOptions.yAxes } : {}),
        },
        elements: {
          point: {
            radius: 6,
          },
          // disable line and fill
          // cf. https://github.com/chartjs/Chart.js/issues/1178#issuecomment-111888642
          line: {
            fill: false,
            tension: 0, // straight lines, not curves
          },
        },
      },
    });
    ctx.onclick = e => {
      const selectedDot = chart.getElementsAtEventForMode(
        e,
        "nearest",
        { intersect: true },
        true
      )[0];
      if (selectedDot) {
        const { link } =
          chart.data.datasets[selectedDot.datasetIndex].data[selectedDot.index];
        if (link) {
          window.open(link, "_blank");
        }
      }
    };
  });

  /*
    eslint no-underscore-dangle:
      ["error", { "allow": ["_xScale", "_yScale", "_meta", "_model"] }]
  */
  $stackedBarCharts.toArray().forEach(ctx => {
    const chartMax = $(ctx).data("chart-max");
    const chart = new Chart(ctx.getContext("2d"), {
      type: "bar",
      data: $(ctx).data("chart-data"),
      options: {
        plugins: {
          legend: {
            labels: {
              filter: legendItem => legendItem.text !== "Total",
            },
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItem, data) => {
              const index = tooltipItem.datasetIndex;
              let currentLabel = data.datasets[index].label;
              // eslint-disable-next-line prefer-destructuring
              const _meta = data.datasets[index]._meta;
              const xScaleMax =
                _meta[Object.keys(_meta)[0]].data[index]._xScale.maxWidth;
              const labelSplit = currentLabel.split("/");
              if (xScaleMax < 750) {
                // tooltip is shorter for mobile devices
                currentLabel =
                  labelSplit[0] + (labelSplit.length > 1 ? "..." : "");
              }
              return `${currentLabel}: ${tooltipItem.yLabel}`;
            },
            title: () => {}, // no title
          },
        },
        hover: {
          animationDuration: 1,
          intersect: false,
        },
        animation: {
          duration: 1,
          onComplete: function (e) {
            // Once we've drawn the chart, this callback labels the stacked
            // segments and the bar as a whole with text for their values as
            // a convenience.
            //
            // There's some heuristic math here for the y position of the
            // text that has proven to be dependent on the version of chart.js,
            // so it might need tweaking if we ever upgrade that library.
            // This tweaking suggests we should move to another method of
            // rendering these charts at some point.
            //
            // disable no-mixed-operators for our math. Not sure if we even want
            // that rule on arithmetic operators anyway.
            /* eslint-disable no-mixed-operators */
            const labelFontHeight = 18;
            const chartInstance = e.chart;
            const context = chartInstance.ctx;

            const totalDataset =
              this.data.datasets[this.data.datasets.length - 1].data.slice(0);
            context.textAlign = "center";
            context.textBaseline = "middle";

            // The "Total" count labels are positioned at the top of the stacked
            // bars and their position is calculated by finding the y position
            // closest to the top of the graph among all the other, non-Total
            // bar segments. These values are stored in this barTops array.
            const barTops = Array(totalDataset.length).fill(Infinity);
            this.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.getDatasetMeta(i);

              const isHidden = meta.hidden;
              const yScaleMax = meta.yScale.maxHeight;
              meta.data.forEach((bar, index) => {
                const data = dataset.data[index];
                const yScaleHeight = (data * yScaleMax) / chartMax;

                // the "Total" values come after all the others, so the
                // barTops[index] value should have been computed correctly
                // at this point.
                if (dataset.label === "Total") {
                  context.fillStyle = "rgba(0, 0, 0, 0.9)";
                  context.fillText(
                    totalDataset[index],
                    bar.x,
                    barTops[index] - labelFontHeight / 2
                  );
                } else if (isHidden) {
                  totalDataset[index] -= data;
                } else {
                  // take the minimum "y", which is computed from the top of
                  // the chart.
                  barTops[index] = Math.min(barTops[index], bar.y);
                  context.fillStyle = "rgba(255, 255, 255, 0.9)";

                  // only draw the count for this segment if it's tall enough
                  // to fit the text. There is still a hover tooltip to show
                  // the value either way.
                  if (yScaleHeight > labelFontHeight) {
                    context.fillText(data, bar.x, bar.y + yScaleHeight * 0.4);
                  }
                }
              });
            });
            /* eslint-enable no-mixed-operators */
          },
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            barPercentage: 0.5,
            scaleLabel: {
              display: true,
              labelString: "Month of initial inquiry",
            },
            ticks: {
              autoSkip: false,
            },
          },

          y: {
            stacked: true,
            ticks: {
              min: 0,
              max: chartMax,
              callback: label => {
                if (label === Math.floor(label)) {
                  return Math.floor(label);
                }
                return null;
              },
            },
            scaleLabel: {
              display: true,
              labelString: "# of applicants",
            },
          },
        },
      },
    });
    ctx.onclick = e => {
      const selectedBar = chart.getElementsAtEventForMode(e)[0];
      if (selectedBar) {
        /* eslint-disable no-underscore-dangle */
        // eslint-disable-next-line prefer-destructuring
        const links = chart.data.datasets[selectedBar._datasetIndex].links;
        const link = links && links.length ? links[selectedBar._index] : null;
        /* eslint-enable no-underscore-dangle */
        window.open(link, "_blank");
      }
    };
  });

  $barCharts.toArray().forEach(ctx => {
    new Chart(ctx.getContext("2d"), {
      type: "bar",
      data: $(ctx).data("chart-data"),
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              autoSkip: false,
            },
            stacked: false,
          },

          y: {
            stacked: false,
            ticks: {
              min: 0,
              max: $(ctx).data("chart-max"),
              callback: label => {
                if (label === Math.floor(label)) {
                  return Math.floor(label);
                }
                return null;
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Amount of Times",
            },
          },
        },
      },
    });
  });
});
