import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import StripedTable from "../../shared/StripedTable.js";
import SupportCell from "../cells/SupportCell.js";

const { t, T } = translationWithRoot("views.contacts.support", {
  escapeJavascriptRoot: true,
});

const SupportContacts = () => {
  const columns = [
    {
      Header: t("contact_method"),
      accessor: "method",
      Cell: ({ value }) => (
        <SupportCell header={t("contact_method")} value={value} />
      ),
    },
    {
      Header: t("info"),
      accessor: "info",
      Cell: ({ value }) => <SupportCell header={t("info")} value={value} />,
    },
  ];

  const data = [
    {
      method: <T t="help_center_method" />,
      info: <T t="help_center_info_html" />,
    },
    {
      method: <T t="live_customer_method" />,
      info: <T t="live_customer_info_html" />,
    },
  ];
  return (
    <StripedTable
      title={I18n.t("views.contacts.support.title")}
      data={data}
      columns={columns}
    />
  );
};

SupportContacts.propTypes = {
  value: PropTypes.string,
};

SupportContacts.displayName = "SupportContacts";
export default SupportContacts;
