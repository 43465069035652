import PropTypes from "prop-types";

import T from "@components/T";

import styles from "../../BintiSign.module.scss";
import AcceptButton from "./AcceptButton";
import SignatureLocations from "./SignatureLocations";
import SignatureFontLoader from "./signature_input/SignatureFontLoader";
import SignatureInput from "./signature_input/SignatureInput";

/**
 * Side pane of the BintiSign modal containing an input for your signature,
 * an table of the pages on which you need to sign, and the accept button
 * when you've completed signing all the necessary locations.
 */
const SignaturePane = ({
  signatureLocationsForRoleByPage,
  allLocationsSigned,
  pageRefs,
  scrollTo,
  activeSigner,
  signingEvent,
  onClose,
}) => (
  <div className={styles.signaturePane}>
    {allLocationsSigned && (
      <AcceptButton {...{ activeSigner, signingEvent, onClose }} />
    )}
    <p>
      <T t="bintisign.signature_pane.your_signature_will_appear" />:
      <SignatureFontLoader />
    </p>
    <SignatureInput {...{ activeSigner, signingEvent }} />
    <hr />
    <SignatureLocations
      {...{ signatureLocationsForRoleByPage, pageRefs, scrollTo }}
    />
  </div>
);

SignaturePane.propTypes = {
  signingEvent: PropTypes.object.isRequired,
  activeSigner: PropTypes.object.isRequired,
  scrollTo: PropTypes.func.isRequired,
  signatureLocationsForRoleByPage: PropTypes.object.isRequired,
  allLocationsSigned: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pageRefs: PropTypes.array,
  userName: PropTypes.string,
};

export default SignaturePane;
