import { Flex, LiveRegion, ScrollWhenTooWide } from "@heart/components";
import TableCaption from "@heart/components/data_table/common/TableCaption";
import TableCell from "@heart/components/data_table/common/TableCell";
import TableColumnHeader from "@heart/components/data_table/common/TableColumnHeader";
import onUploadForCell from "@heart/components/data_table/common/onUploadForCell";
import styles from "@heart/components/data_table/common/tableCommon.module.scss";
import * as HeartTable from "@heart/components/table/Table";
import {
  flatten,
  get,
  intersection,
  isArray,
  isEmpty,
  isNil,
  sortBy,
} from "lodash";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useMemo, useState } from "react";

import { dateWithinRange, determinePrefixedRangeDates } from "@lib/dates";
import useLocation from "@lib/react-use/useLocation";
import useSearchParam from "@lib/react-use/useSearchParam";
import { getSearchParamForAttribute, setSearchParams } from "@lib/searchParams";

import DeprecatedTableFilter from "./DeprecatedTableFilter";

const { Table, Thead, Tr, Tbody } = HeartTable;

const directionOptions = ["NONE", "ASC", "DESC"];

/** Returns the next sort direction in the list, looping around
 * if the end of the list has been reached
 */
export const nextDirection = (direction = "NONE") =>
  directionOptions[(directionOptions.indexOf(direction) + 1) % 3];

export const searchValueWithinRow = ({
  searchTerm,
  searchFilter,
  data,
  exactMatch,
}) => {
  if (isNil(searchTerm) || isEmpty(searchTerm)) return true;
  if (typeof searchFilter === "string") {
    let dataAtAccessor = get(data, searchFilter);
    if (isArray(searchTerm)) {
      if (isNil(dataAtAccessor)) return false;
      dataAtAccessor = isArray(dataAtAccessor)
        ? dataAtAccessor
        : [dataAtAccessor];
      return intersection(dataAtAccessor, searchTerm).length > 0;
    }

    dataAtAccessor = (dataAtAccessor || "").toLowerCase();
    if (exactMatch) return dataAtAccessor === searchTerm.toLowerCase();
    return dataAtAccessor.includes(searchTerm.toLowerCase());
  }
  return searchFilter({ data, searchTerm });
};

/** A table for displaying data that can optionally be sorted
 * and filtered
 *
 * **Note:** This story has the date locked to August 24, 2022 so that
 * the dates can be hardcoded for tests and the dropdown filters
 * are still relevant
 *
 * **Troubleshooting:** If you're running into infinite rendering issues,
 * you may need to wrap arguments like `columns` in a `useMemo` hook, or
 * wrap the `filteredDataCallback` argument in a `useCallback` hook. Both
 * these steps will help the table know whether the values have actually
 * changed (which triggers a rerender) by keeping the reference to the
 * argument more consistent.
 *
 * ### Cypress
 *
 * See the stories for `HeartTable` (the base table component) for Cypress selectors
 * to use on tables.
 */
const DeprecatedArrayDataTable = ({
  title,
  actions,
  columns,
  data,
  onUpload,
  disableUploadForRow,
  disableUploadColumnIdCellIndexes,
  filteredDataCallback,
  defaultSortAttributes = {},
}) => {
  const tableIsSortable = columns.some(({ columnSort }) => !isNil(columnSort));

  // We scoop up all the filters and evaluate them one-by-one against
  // query params that we might find in the URL.
  const filterAttributes = useMemo(
    () =>
      columns
        .filter(column => column.filter)
        .reduce(
          (acc, column) => ({
            ...acc,
            [column.id]: {
              type: column.filter.type,
              attribute: column.filter.filterBy,
            },
          }),
          {}
        ),
    [columns]
  );

  const sortColumnIndex = useSearchParam("sortColumnIndex");
  const sortDirection = useSearchParam("sortDirection");

  const [sortedData, setSortedData] = useState(data);
  const [filteredData, setFilteredData] = useState(sortedData);

  const location = useLocation();

  useEffect(() => {
    // Only take default sort attributes into account if there are
    // not existing search attributes in the URL
    if (isNil(sortColumnIndex) && !isEmpty(defaultSortAttributes)) {
      setSearchParams([
        {
          attribute: "sortColumnIndex",
          value: defaultSortAttributes.columnIndex,
        },
        { attribute: "sortDirection", value: defaultSortAttributes.direction },
      ]);
    }
  }, [defaultSortAttributes, sortColumnIndex]);

  useEffect(() => {
    setSortedData(data);
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    if (!isNil(sortColumnIndex)) {
      const columnSort = get(columns[sortColumnIndex], "columnSort");

      if (sortDirection === "NONE") setSortedData(data);
      else if (columnSort) {
        let sorted = [...data];
        if (typeof columnSort === "string") {
          sorted = sortBy(data, [a => get(a, columnSort)]);
        } else {
          sorted.sort(columnSort);
        }
        if (sortDirection === "DESC") sorted.reverse();
        setSortedData(sorted);
      }
    } else {
      setSortedData(data);
    }
  }, [columns, data, sortColumnIndex, sortDirection]);

  useEffect(() => {
    const newFilteredData = sortedData.filter(rowData =>
      Object.entries(filterAttributes).every(([id, { attribute, type }]) => {
        if (type === "date" || type === "custom_dates") {
          /** pull dates if the filter is a custom date filter */
          let startDate = appliedFilterValueFor(`${id}Gteq`);
          let endDate = appliedFilterValueFor(`${id}Lteq`);

          /** pull date range if the filter is a preset date range filter & convert to actual dates */
          const dateRange = appliedFilterValueFor(id);
          if (dateRange) {
            [startDate, endDate] = determinePrefixedRangeDates({ dateRange });
          }
          return dateWithinRange({
            startDate,
            endDate,
            dateToCompare: get(rowData, attribute),
          });
        } else if (type === "search") {
          return searchValueWithinRow({
            searchTerm: appliedFilterValueFor(id),
            searchFilter: attribute,
            data: rowData,
          });
        } else if (type === "select") {
          return searchValueWithinRow({
            searchTerm: appliedFilterValueFor(id),
            searchFilter: attribute,
            data: rowData,
            exactMatch: true,
          });
        }

        throw new Error(`Filter with unsupported type ${type} registered`);
      })
    );
    setFilteredData(newFilteredData);

    if (filteredDataCallback) {
      filteredDataCallback(newFilteredData);
    }
  }, [sortedData, filterAttributes, filteredDataCallback, location.search]);

  const appliedFilterValueFor = field => {
    const isMultiSelect =
      (columns.find(({ id }) => id === field) || {}).filter?.isMulti || false;
    const value = getSearchParamForAttribute({
      attribute: field,
    });
    if (isMultiSelect) {
      return isEmpty(value) ? undefined : value.split(",");
    }
    return value;
  };
  const applyFilters = filters =>
    Object.entries(filters).forEach(([attribute, value]) => {
      setSearchParams([{ attribute, value }]);
    });
  const isActiveSortColumn = index => parseInt(sortColumnIndex, 10) === index;

  const onSortToggle = index => {
    if (isActiveSortColumn(index)) {
      setSearchParams([
        {
          attribute: "sortDirection",
          value: nextDirection(sortDirection),
        },
      ]);
    } else {
      setSearchParams([
        { attribute: "sortColumnIndex", value: index },
        { attribute: "sortDirection", value: "ASC" },
      ]);
    }
  };

  const getOnUploadForCell = onUploadForCell({
    disableUploadForRow,
    disableUploadColumnIdCellIndexes,
    onUpload,
  });

  return (
    <Fragment>
      <Flex justify="space-between">
        {columns
          .filter(column => column.filter)
          .map(({ filter, id }) => (
            <DeprecatedTableFilter
              key={`filter-${id}`}
              filter={{ ...filter, field: id }}
              appliedFilterValueFor={appliedFilterValueFor}
              applyFilters={applyFilters}
            />
          ))}
        {actions}
      </Flex>
      <LiveRegion>
        <ScrollWhenTooWide>
          <Table>
            <TableCaption caption={title} tableIsSortable={tableIsSortable} />
            <Thead>
              <Tr>
                {columns.map((column, columnIndex) => (
                  <TableColumnHeader
                    key={`th-${column.id}`}
                    id={column.id}
                    name={column.columnName.name}
                    justify={column.columnName.justify}
                    isSortable={Boolean(column.columnSort)}
                    isActiveSortColumn={isActiveSortColumn(columnIndex)}
                    sortDirection={sortDirection}
                    onSort={() => onSortToggle(columnIndex)}
                    colSpan={
                      Array.isArray(column.cell)
                        ? column.cell.length
                        : undefined
                    }
                  />
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {filteredData.map(rowData => (
                <Tr key={rowData.id} className={styles.tableRow}>
                  {columns.map(column => {
                    const cells = flatten([column.cell]);
                    return cells.map((cell, index) => (
                      <TableCell
                        key={`td-${rowData.id}-${column.id}-${index}`}
                        rowData={rowData}
                        cell={cell}
                        onUpload={getOnUploadForCell({
                          rowData,
                          column,
                          cellIndex: index,
                        })}
                        iconOnlyUpload={column.iconOnlyUpload}
                        /** remove right side table cell padding from all but the last
                         * cell when a column header spans more than one column
                         */
                        removeRightPadding={index < cells.length - 1}
                      />
                    ));
                  })}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ScrollWhenTooWide>
      </LiveRegion>
    </Fragment>
  );
};

DeprecatedArrayDataTable.propTypes = {
  /** A caption for the table. Required when the DetailsTable is not
   * on top of a `Surface` (or any `SurfaceBase` like `SurfaceForm` / `Notice` etc)
   * or when the `Surface`'s title doesn't adequately convey to non-sighted users
   * what the data in this table refers to.
   * When inferred from the `Surface` title, it's hidden. */
  title: PropTypes.string,
  /** Actions to render above the top right corner of the table */
  actions: PropTypes.node,
  /** A list of the columns for the table, indicating name, display logic,
   * filterability, sortability, and whether the dropzone for the column
   * should be icon only (when used in conjunction with onUpload)
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cell: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.func, PropTypes.string])
        ),
      ]).isRequired,
      columnSort: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      filter: PropTypes.shape({
        label: PropTypes.string.isRequired,
        filterBy: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        type: PropTypes.oneOf(["custom_dates", "date", "search", "select"])
          .isRequired,
        /** Allow users to select multiple values from dropdown to filter by.
         * Operates as an "or" filter, meaning rows that contain at least one
         * of the selected values will be shown. isMulti only has an effect
         * when type is "select" */
        isMulti: PropTypes.bool,
        /** A list of the values that should populate the dropdown. Only valid
         * when type is "select"
         */
        values: PropTypes.arrayOf(PropTypes.object),
      }),
      id: PropTypes.string.isRequired,
      columnName: PropTypes.shape({
        name: PropTypes.string.isRequired,
        justify: PropTypes.oneOf(["start", "center", "end"]),
      }).isRequired,
      iconOnlyUpload: PropTypes.bool,
    })
  ).isRequired,
  /** Default sorting attributes that will be used if the URL
   * does not already contain sort attributes
   */
  defaultSortAttributes: PropTypes.shape({
    columnIndex: PropTypes.number.isRequired,
    direction: PropTypes.oneOf(["ASC", "DESC", "NONE"]).isRequired,
  }),
  /** Function called with row data to determine whether upload
   * should be disabled for a given row
   */
  disableUploadForRow: PropTypes.func,
  /** column id and cell indexes that should not have an upload dropzone
   *
   * This should be used for Actions columns containing Menus, where
   * the Upload dropzone breaks the behavior of our Menu
   */
  disableUploadColumnIdCellIndexes: PropTypes.shape({
    columnId: PropTypes.string,
    indexes: PropTypes.arrayOf(PropTypes.number),
  }),
  /** Function called with row data which should return a function
   * that can take in files dropped over each row
   */
  onUpload: PropTypes.func,
  /** Function that will be called with the filtered data when provided.
   * Note that it may be necessary to wrap the callback in a `useCallback`
   * hook to avoid having the table infinitely rerender
   */
  filteredDataCallback: PropTypes.func,
  /** Data to be displayed in the table */
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default DeprecatedArrayDataTable;
