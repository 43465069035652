import { isNil } from "lodash";

import { B64PARAMS } from "@root/constants";

import { stringifyAndEncode } from "./base64Helpers";
import { getBase64SearchParamForAttribute } from "./base64SearchParams";

/** Determine existing search params in URL */
export const getSearchParams = () =>
  new URLSearchParams(window.location.search);

/** Determine existing search params in URL for a specific attribute */
export const getSearchParamForAttribute = ({ attribute }) => {
  const params = getSearchParams();
  return params.get(attribute);
};

/** Maintain existing search params & update provided attribute to value */
export const setSearchParams = parameters => {
  const params = getSearchParams();
  parameters.forEach(({ attribute, value }) => {
    if (isNil(value) || value === "") {
      params.delete(attribute);
    } else {
      params.set(attribute, value);
    }
  });
  window.history.pushState({}, "", `${window.location.pathname}?${params}`);
};

/** Clear all but provided search params */
export const clearAllOtherSearchParams = (
  { preserveAttributes = [], preserveB64Attributes = [] } = {
    preserveAttributes: [],
    preserveB64Attributes: [],
  }
) => {
  const preservedAttrs = new URLSearchParams();
  preserveAttributes.map(attribute => {
    const val = getSearchParamForAttribute({ attribute });
    return val ? preservedAttrs.set(attribute, val) : undefined;
  });

  preservedAttrs.set(B64PARAMS, {});
  const preservedB64Attrs = {};
  preserveB64Attributes.forEach(attribute => {
    const val = getBase64SearchParamForAttribute({ attribute });
    preservedB64Attrs[attribute] = val;
  });

  preservedAttrs.set(B64PARAMS, stringifyAndEncode(preservedB64Attrs));

  window.history.pushState(
    {},
    "",
    `${window.location.pathname}?${preservedAttrs}`
  );
};
