import PropTypes from "prop-types";

/** These are the props that can be found on every input. */
const inputCommonPropTypes = {
  /** Optional text to help the user understand how to complete this input */
  description: PropTypes.node,
  /** Callback to be invoked when the value changes */
  onChange: PropTypes.func,
  /** The input's `id` HTML attribute.  Use sparingly; if omitted one will be generated. */
  id: PropTypes.string,
  /** Label for this input */
  label: props => {
    if (!props.label && !props.labeledExternally) {
      return new Error(
        "The `label` prop is required.  To hide the label, use the `hideLabel` prop. " +
          "If you have provided a label for this component elsewhere on the page, use the " +
          "`labeledExternally` prop to indicate so."
      );
    }
    return undefined;
  },
  labeledExternally: props => {
    if (props.labeledExternally && !props.id) {
      return new Error(
        "The `id` prop is required when using an external label."
      );
    }
    return undefined;
  },
  /** HTML input name attribute */
  name: PropTypes.string,
  /** To indicate if this input is required */
  required: PropTypes.bool,
  /** Disable the input, preventing user interaction.
   *
   * **Note**: When you disable an input, its value will not be sent along with form POSTs!
   */
  disabled: PropTypes.bool,
  /** To indicate if this input should be hidden entirely but still present in the DOM */
  hidden: PropTypes.bool,
  /** Optional error text that will be displayed to the user */
  error: PropTypes.string,
  /** For inputs in a column, fullWidth indicates they should visually span the
   * entire column */
  fullWidth: PropTypes.bool,
};
export default inputCommonPropTypes;

export const inputGroupCommonPropTypes = {
  /** To indicate orientation of the options */
  orientation: PropTypes.oneOf(["column", "row"]),
};

export const textualInputPropTypes = {
  /** The initial value (uncontrolled) of this input, or the current value (controlled). */
  value: PropTypes.string,
  /** `onChange` is invoked with the text value as the only argument */
  onChange: PropTypes.func,
  /** `onBlur` is invoked with the event as the only argument */
  onBlur: PropTypes.func,
  /** A sample value placeholder for users to see what kind of input we expect */
  placeholder: PropTypes.string,
  /** The maximum number of characters a user can enter before this field is invalid */
  maxCharacters: PropTypes.number,
};

/* This is InputText's prop types. The name is perhaps confusingly similar to the above
 * textualInputPropTypes, but this is specifically for InputText. It is exposed so classes
 * may wrap InputText and passthrough props
 */
export const inputTextPropTypes = {
  ...inputCommonPropTypes,
  ...textualInputPropTypes,
  /** Regex for HTML validation of this field.  Not applicable for `type="number"`. */
  pattern: PropTypes.string,
  /**
   * Which `<input type="" />` to use.  See [this table](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types)
   * for more information about this field.
   *
   * Defaults to `text`.
   * * Use `email` for email inputs - it comes with built-in HTML validation!
   * * Use `number` for numbers that can be incremented/decremented, not for things like ZIP codes.
   * * Use `search` for search fields - it comes with its own "clear" button
   * * `tel` is for telephone numbers, but `<InputPhone />` does a much better job at it.
   */
  type: PropTypes.oneOf([
    "text",
    "email",
    "number",
    "password",
    "search",
    "tel",
  ]),
  /** The initial (or current) field value as a string */
  value: PropTypes.string,
  /** Invoked with the current field value as an argument */
  onChange: PropTypes.func,
  /** TODO How much to increase number inputs by */
  step: PropTypes.string,
};
