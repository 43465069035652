/* eslint-disable no-underscore-dangle */
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import AppRequirementReconciliation from "@graphql/queries/ApplicationRequirementReconciliation.graphql";

import { typeEq } from "@lib/graphqlHelpers";

import SupportingDocumentsV2Table from "./SupportingDocumentsV2Table";

/**
 * Temporary shim for exposing UploadType requirements and uploads on the
 * legacy documents page.
 */
const LegacyExpiringDocumentShim = ({ applicationId }) => {
  const { loading, error, data } = useQuery(AppRequirementReconciliation, {
    variables: { applicationId: applicationId },
  });
  if (loading) {
    return false;
  }
  if (error) return <p>Unable to load supporting documents!</p>;

  const {
    applicationRequirementReconciliation: { requirementFulfillments },
    application,
  } = data;
  const supportingDocumentsFulfillments = requirementFulfillments.filter(
    ({ requirement }) => typeEq("UploadTypeRequirement", requirement)
  );

  return (
    <div id="#SupportingDocuments">
      <SupportingDocumentsV2Table
        requirementFulfillments={supportingDocumentsFulfillments}
        title="Supporting Documents"
        isEditable={false}
        {...{ application }}
      />
    </div>
  );
};

LegacyExpiringDocumentShim.propTypes = {
  applicationId: PropTypes.number.isRequired,
};

export default LegacyExpiringDocumentShim;
