import { gql, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  InputFilterable,
  LoadingOverlay,
  Surface,
  Text,
} from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

const { t } = translationWithRoot("placement_search.workers_assigned");

const AddWorkersForm = ({ workersAssigned, childId, onSubmit, onCancel }) => {
  const { data, loading: dataLoading } = useQuery(
    gql`
      query AddWorkersForm($childId: ID!) {
        placementSearchFormData(childId: $childId) {
          workersAvailable {
            id
            name
          }
        }
      }
    `,
    {
      variables: {
        childId,
      },
    }
  );

  const [workersToAdd, setWorkersToAdd] = useState(null);

  const { workersAvailable } = data?.placementSearchFormData || {
    workersAvailable: [],
  };

  const assignedIds = workersAssigned?.map(({ id }) => id) || [];
  const availableWorkers = workersAvailable.filter(
    ({ id }) => !assignedIds.includes(id)
  );

  return (
    <LoadingOverlay active={dataLoading}>
      <Surface title={t("title")} hideTitle data-testid="add-workers-form">
        <Choose>
          <When condition={!!availableWorkers.length}>
            <InputFilterable
              isMulti
              label={t("available_workers")}
              onChange={setWorkersToAdd}
              options={availableWorkers.map(({ id, name }) => ({
                label: name,
                value: id.toString(),
              }))}
              value={workersToAdd}
            />
            <Flex justify="end">
              <Button variant="tertiary" onClick={onCancel}>
                {t("cancel")}
              </Button>
              <Button
                onClick={() =>
                  onSubmit(
                    workersToAdd.map(a => ({ id: a.value, name: a.label }))
                  )
                }
              >
                {t("assign")}
              </Button>
            </Flex>
          </When>
          <Otherwise>
            <Text>{t("no_workers_available")}</Text>
          </Otherwise>
        </Choose>
      </Surface>
    </LoadingOverlay>
  );
};

AddWorkersForm.propTypes = {
  workersAssigned: PropTypes.array,
  placementSearchId: BintiPropTypes.ID,
  childId: BintiPropTypes.ID,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default AddWorkersForm;
