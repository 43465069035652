import { Icons } from "@heart/components";
import PropTypes from "prop-types";

import { policy } from "@lib/graphqlHelpers";

/**
 *
 * PossiblyLinkedTitle shows either the requirement title linked to the fill
 * out a form if it's fillable or just the plain requirement title otherwise.
 *
 */
const PossiblyLinkedTitle = ({ formInstance, title }) => {
  if (formInstance?.editUrl && policy(formInstance).mayUpdate()) {
    return (
      <span>
        <a href={formInstance.editUrl}>{title}</a>
        <span>&nbsp;</span>
        <a href={formInstance.editUrl}>
          <Icons.Edit />
        </a>
      </span>
    );
  }

  return <span>{title}</span>;
};

PossiblyLinkedTitle.propTypes = {
  title: PropTypes.string.isRequired,
  formInstance: PropTypes.object,
};

export default PossiblyLinkedTitle;
