import { useMutation } from "@apollo/client";
import {
  Surface,
  SurfaceForm,
  Icons,
  DetailsTable,
  InputDate,
  InputDropdown,
  Actions,
  Modal,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState, useEffect, Fragment } from "react";

import DeleteAppeal from "@graphql/mutations/DeleteAppeal.graphql";
import UpdateAppeal from "@graphql/mutations/UpdateAppeal.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const AppealDetails = ({
  appeal,
  refetch,
  application,
  representatives,
  applicationType,
  showDeleteIcon,
  appealLocations,
}) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updateAppeal] = useMutation(UpdateAppeal);
  const [deleteAppeal] = useMutation(DeleteAppeal);
  const handleAppeal = preventDefault(() => {
    updateAppeal({
      variables: {
        appealLocation,
        appealId: appeal.id,
        representative,
        appealStartedDate,
        approvingAgencyNotifiedDate,
        appealsBodyNotifiedDate,
        docsSentToAppealsBodyDate,
      },
      onCompleted: () => {
        refetch();
        setEditing(false);
      },
    });
  });

  const setStateToAppeal = () => {
    const {
      appealLocation,
      representative,
      appealStartedDate,
      approvingAgencyNotifiedDate,
      appealsBodyNotifiedDate,
      docsSentToAppealsBodyDate,
    } = appeal;
    setAppealLocation(appealLocation);
    setRepresentative(representative);
    setAppealStartedDate(appealStartedDate);
    setApprovingAgencyNotifiedDate(approvingAgencyNotifiedDate);
    setAppealsBodyNotifiedDate(appealsBodyNotifiedDate);
    setDocsSentToAppealsBodyDate(docsSentToAppealsBodyDate);
    setEditing(false);
  };

  useEffect(setStateToAppeal, [appeal]);

  const [appealLocation, setAppealLocation] = useState(appeal.appealLocation);
  const [representative, setRepresentative] = useState(
    appeal.representative || ""
  );
  const [appealStartedDate, setAppealStartedDate] = useState(
    appeal.appealStartedDate
  );
  const [approvingAgencyNotifiedDate, setApprovingAgencyNotifiedDate] =
    useState(appeal.approvingAgencyNotifiedDate);
  const [appealsBodyNotifiedDate, setAppealsBodyNotifiedDate] = useState(
    appeal.appealsBodyNotifiedDate
  );
  const [docsSentToAppealsBodyDate, setDocsSentToAppealsBodyDate] = useState(
    appeal.docsSentToAppealsBodyDate
  );

  if (editing)
    return (
      <SurfaceForm
        title={I18n.t("javascript.components.appeals.appeal_details")}
        actions={
          <Actions
            cancelAction={() => {
              setStateToAppeal();
            }}
          />
        }
        onSubmit={handleAppeal}
      >
        {appealLocations.length > 1 && (
          <InputDropdown
            label={I18n.t(
              "javascript.components.change_application_status.appeals.appeal_location"
            )}
            onChange={setAppealLocation}
            value={appealLocation}
            values={appealLocations}
          />
        )}
        <InputDropdown
          label={I18n.t("javascript.components.appeals.agency_representative")}
          name="representative"
          values={representatives.map(([label, value]) => [label, value])}
          value={representative}
          onChange={setRepresentative}
          required={false}
        />
        <InputDate
          label={I18n.t("javascript.components.appeals.date_appeal_started")}
          name="appealStartedDate"
          value={appealStartedDate}
          onChange={setAppealStartedDate}
          required={false}
        />
        <InputDate
          label={I18n.t(
            "javascript.components.appeals.date_approving_agency_notified"
          )}
          name="approvingAgencyNotifiedDate"
          value={approvingAgencyNotifiedDate}
          onChange={setApprovingAgencyNotifiedDate}
          required={false}
        />
        <InputDate
          label={I18n.t(
            "javascript.components.appeals.date_appeals_body_notified"
          )}
          name="appealsBodyNotifiedDate"
          value={appealsBodyNotifiedDate}
          onChange={setAppealsBodyNotifiedDate}
          required={false}
        />
        <InputDate
          label={I18n.t(
            "javascript.components.appeals.date_docs_sent_to_appeals_body"
          )}
          name="docsSentToAppealsBodyDate"
          value={docsSentToAppealsBodyDate}
          onChange={setDocsSentToAppealsBodyDate}
          required={false}
        />
      </SurfaceForm>
    );

  return (
    <Surface
      title={I18n.t("javascript.components.appeals.appeal_details")}
      secondary={
        <Fragment>
          <Icons.Pencil
            description={I18n.t(
              "javascript.components.appeals.appeal_details_edit"
            )}
            onClick={() => setEditing(true)}
          />
          <If condition={showDeleteIcon}>
            <Icons.Trash
              description={I18n.t("javascript.components.common.delete")}
              onClick={() => {
                setDeleting(true);
              }}
            />
          </If>
        </Fragment>
      }
    >
      <Modal
        title={I18n.t("javascript.components.appeals.delete_appeal_title")}
        onCancel={() => setDeleting(false)}
        hidden={!deleting}
        onSubmit={() => {
          deleteAppeal({
            variables: { appealId: appeal.id },
            onCompleted: () => {
              window.location = `/admin/applications/${application.id}/change_status`;
            },
          });
        }}
      >
        {I18n.t("javascript.components.appeals.delete_appeal_text")}
      </Modal>
      <DetailsTable
        details={[
          {
            label: I18n.t("javascript.components.appeals.application_type"),
            value: applicationType,
          },
          {
            label: I18n.t(
              "javascript.components.appeals.application_denial_date"
            ),
            value: application.statusDate,
          },
          {
            label: I18n.t("javascript.components.appeals.appeal_location"),
            value: appealLocation
              ? I18n.t(
                  `activerecord.enums.application_status_change.appeal_locations.${appealLocation}`
                )
              : "",
          },
          {
            label: I18n.t(
              "javascript.components.appeals.agency_representative"
            ),
            value: representative
              ? I18n.t(
                  `javascript.components.appeal_representative.${representative}`
                )
              : "",
          },
          {
            label: I18n.t("javascript.components.appeals.date_appeal_started"),
            value: appealStartedDate,
          },
          {
            label: I18n.t(
              "javascript.components.appeals.date_approving_agency_notified"
            ),
            value: approvingAgencyNotifiedDate,
          },
          {
            label: I18n.t(
              "javascript.components.appeals.date_appeals_body_notified"
            ),
            value: appealsBodyNotifiedDate,
          },
          {
            label: I18n.t(
              "javascript.components.appeals.date_docs_sent_to_appeals_body"
            ),
            value: docsSentToAppealsBodyDate,
          },
        ]}
      />
    </Surface>
  );
};

AppealDetails.propTypes = {
  appeal: PropTypes.shape({
    id: BintiPropTypes.ID,
    appealLocation: PropTypes.string,
    representative: PropTypes.string,
    appealStartedDate: PropTypes.string,
    approvingAgencyNotifiedDate: PropTypes.string,
    docsSentToAppealsBodyDate: PropTypes.string,
    appealsBodyNotifiedDate: PropTypes.string,
  }),
  application: PropTypes.shape({
    id: BintiPropTypes.ID,
    type: PropTypes.string,
    statusDate: PropTypes.string,
  }),
  representatives: PropTypes.array,
  appealLocations: PropTypes.array,
  applicationType: PropTypes.string,
  showDeleteIcon: PropTypes.bool,
  refetch: PropTypes.func,
};

export default AppealDetails;
