import { Icons } from "@heart/components";
import { ToastContainer as ToastifyContainer, Slide } from "react-toastify";
import "react-toastify/scss/main.scss";

import { translationWithRoot } from "@components/T";

import styles from "./Toast.module.scss";

const { t } = translationWithRoot("toast");

/**
 * To create notifications in the corner of the screen called a "Toast", call one of the
 * `toast.*` functions exported from `@heart/components` anywhere in your code.  The
 * following functions are available:
 *  * `toast.informative()`,
 *  * `toast.success()`,
 *  * `toast.notable()`
 *  * `toast.negative()`
 *
 * Refer to Design's
 * [Guru card: Notifications](https://app.getguru.com/card/c84M8n8i/Binti-Notifications-Guidance-and-Examples)
 * for guidance about
 *  * when you should use a `Toast` and when you should use a `Notice`
 *  * which kind of `Toast` is appropriate for which situation
 *
 * The arguments to `toast.*({...})` are:
 *   * `title`: The title of the `toast`, as a string
 *   * `message`: The toast's message content, as string or JSX (in case you need to include a link)
 *    * *a11y note!*: Only put links in `negative` and `notable` toasts, as the other toasts
 *      auto-dismiss and keyboard users may not be able to reach the link in time.
 *
 * This will only work on pages where there is a `<ToastContainer>` somewhere in the render tree.
 * At the time of writing, this is rendered in all scenarios of `application.html.erb`, so it
 * *should* Just Work™!
 *
 * ### Cypress
 *
 * To target this component in Cypress, use `cy.findNotification()`.
 */
const ToastContainer = () => (
  <div className={styles.bintiToast}>
    <ToastifyContainer
      position="bottom-right"
      transition={Slide}
      autoClose={8000}
      theme="colored"
      closeButton={({ closeToast }) => (
        <div className={styles.closeIconContainer}>
          <Icons.Times onClick={closeToast} description={t("close")} />
        </div>
      )}
    />
  </div>
);

export default ToastContainer;
