import { Button, Flex, InputTextarea, LiveRegion } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import styles from "./AvailabilityRequest.module.scss";

const prefixT = (key, args) =>
  I18n.t(
    `javascript.components.availability_request.preview_container.${key}`,
    args
  );

const PreviewContainer = ({
  onCancel,
  emailText,
  SMSText,
  channels,
  agencyPlacementIds,
  childrenIds,
  agency,
  refererURL,
  canSendSms,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // eslint-disable-next-line compat/compat
  const previewParamsObj = new URLSearchParams({
    email_body: encodeURIComponent(emailText),
    agency_id: encodeURIComponent(agency.id),
  });
  const previewUrl = `/availability_request_preview_email?${previewParamsObj.toString()}`;

  const sendMessage = () => {
    const dataObject = {
      agency_placement_ids: agencyPlacementIds,
      child_ids: childrenIds,
      agency_id: agency.id,
    };
    if (channels.includes("email")) {
      dataObject.email_body = emailText;
    }

    if (channels.includes("sms")) {
      dataObject.sms_message = SMSText;
    }

    $.ajax({
      url: "/availability_request",
      method: "post",
      contentType: "application/json",
      data: JSON.stringify(dataObject),
      success: () => {
        window.location.replace(refererURL);
      },
      error: () => {
        setError(prefixT("error_message"));
        setLoading(false);
      },
    });

    setLoading(true);
  };

  const sendButtonMessage = () => {
    if (loading) {
      return prefixT("sending");
    }

    return channels.length > 1
      ? prefixT("send_messages")
      : prefixT("send_message");
  };

  const editButtonMessage = () =>
    channels.length > 1 ? prefixT("edit_messages") : prefixT("edit_message");

  return (
    <div className={styles.flexDiv}>
      <If condition={channels.includes("sms")}>
        <InputTextarea
          label={prefixT("sms_preview")}
          value={SMSText}
          rows={5}
          maxCharacters={320}
          disabled
          className={styles.nonResizableTextareaInput}
        />
      </If>
      <section>
        <If condition={channels.includes("email")}>
          <h4 className={styles.previewHeader}>{prefixT("email_preview")}</h4>
          <iframe
            className={styles.iFrame}
            src={previewUrl}
            title={prefixT("email_preview")}
          />
        </If>
      </section>
      <If condition={error}>
        <LiveRegion as="div" className="errors space-above-4" id="error-info">
          {error}
        </LiveRegion>
      </If>
      <If condition={!canSendSms}>
        <div className="page-notification">
          You are in a non-production environment. We will not send any text
          messages to families, but the flow will work as if we had sent them.
        </div>
      </If>
      <Flex row>
        <Button onClick={sendMessage} disabled={loading}>
          {sendButtonMessage()}
        </Button>
        <Button variant="secondary" onClick={onCancel} disabled={loading}>
          {editButtonMessage()}
        </Button>
      </Flex>
    </div>
  );
};
PreviewContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  emailText: PropTypes.string.isRequired,
  SMSText: PropTypes.string.isRequired,
  channels: PropTypes.arrayOf(PropTypes.oneOf(["email", "sms"])).isRequired,
  agencyPlacementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  childrenIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  agency: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
    .isRequired,
  refererURL: PropTypes.string.isRequired,
  canSendSms: PropTypes.bool.isRequired,
};

export default PreviewContainer;
