import PropTypes from "prop-types";

import CopyToClipboardTextWithIcon from "@components/shared/copy_to_clipboard/CopyToClipboardTextWithIcon";

import CloseDeleteButton from "../shared/CloseDeleteButton";
import styles from "./ApplicationTemplateDesigner.module.scss";
import NeededFor from "./NeededFor";
import RequirementDisplayContext from "./RequirementDisplayContext";

const UploadType = ({ uploadType, dragHandleProps = {}, onRemove }) => (
  <RequirementDisplayContext.Consumer>
    {({ isCompact }) => (
      <div
        className={styles.uploadType}
        data-testid="template-designer-upload-type-tile"
      >
        <div className={styles.uploadTypeHeader} {...dragHandleProps}>
          <div>
            <div className={styles.uploadTypeName}>
              {uploadType.nameTranslations.en}{" "}
              {!isCompact && (
                <a
                  href={`/admin/upload_types/${uploadType.id}/edit`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  [<i className="fa fa-pencil-alt"></i>]
                </a>
              )}
            </div>
            <div className={styles.uploadTypeSlug}>
              <CopyToClipboardTextWithIcon text={uploadType.slug} />
            </div>
          </div>
          {onRemove && <CloseDeleteButton onClick={onRemove} />}
        </div>
        {!isCompact && (
          <div className={styles.uploadTypeRolesSpec}>
            <NeededFor recordType={uploadType} />
          </div>
        )}
      </div>
    )}
  </RequirementDisplayContext.Consumer>
);

UploadType.propTypes = {
  uploadType: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  dragHandleProps: PropTypes.object,
};

export default UploadType;
