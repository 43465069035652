import PropTypes from "prop-types";

const linkPropType = PropTypes.shape({
  key: PropTypes.string,
  url: PropTypes.string,
  call_to_action: PropTypes.bool,
});

const folderPropType = PropTypes.shape({
  key: PropTypes.string,
  submenu_items: PropTypes.arrayOf(linkPropType),
});

const allLinksPropType = PropTypes.arrayOf(
  PropTypes.oneOfType([linkPropType, folderPropType])
);

const userPropType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
});

export default {
  linkPropType,
  allLinksPropType,
  userPropType,
};
