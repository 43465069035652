import { Layout, LiveRegion } from "@heart/components";
import useCurrentPageSearchParam from "@heart/components/layout/page_layout/useCurrentPageSearchParam";
import PropTypes from "prop-types";
import { createContext } from "react";

import { translationWithRoot } from "@components/T";

import styles from "./Wizard.module.scss";
import WizardSidebar from "./WizardSidebar";

const { t } = translationWithRoot("heart.components.wizard");

export const calculateProgress = ({ requiredFields }) =>
  (requiredFields.filter(Boolean).length / requiredFields.length) * 100;

export const WizardPageContext = createContext(null);

/** Our multi-page form scaffolding. The goal of this component is to bake in the
 * layout and some functionality so that individual use cases don't need to worry
 * about every detail!
 *
 * #### Usage
 *
 * The Wizard should be rendered as a fully React driven page, leveraging
 * `layout("application_full_width")` at the top of the controller defining the route
 * so that the Wizard can take up the entirety of the page. It should not be rendered
 * within an ActiveAdmin context
 *
 * This component should be used in conjunction with `WizardPage` and `WizardFieldset`.
 *
 * `WizardFieldset` will accept any props that `SurfaceFieldset` accepts
 *
 * #### Baked in functionality
 * - Any asynchronous primaryAction or cancelAction will trigger a submitting state
 * when the associated button is clicked
 * - If `WizardPage` is provided with `confirmBeforeCancel` prop, a modal will appear
 * when the user clicks the cancel button for the page warning them that all unsaved
 * changes will be lost
 *   - Note: this functionality has not been built to account for the page being
 * refreshed, TODO: https://binti.atlassian.net/browse/TRIAG-11337
 *   - Note: using the back button of the browser will not spawn a modal either,
 * TODO: https://binti.atlassian.net/browse/TRIAG-11340
 *
 * #### calculateProgress
 *
 * For the `progress` field, you can use the `calculateProgress` function exported
 * from the Wizard file - it takes in an array of `requiredFields` and returns the
 * percentage of those fields that are truthy
 *
 * #### Template for creating a Wizard
 *
 * The story for this component has been set up to be usable as a template for building
 * out Wizards. It includes examples of setting up global form state, and subsections
 * extracted into separate components which read from and write to that global form state.
 *
 * This story is also set up to display the global form state in the Actions tab as a way
 * to see how values are stored when using this global form state pattern
 *
 * ### Cypress
 *
 * To target things within this component in Cypress:
 * - the Sidebar has attribute `data-heart-component="Sidebar"`
 * - each WizardPage has attribute `data-heart-component="WizardPage"`
 * - the Action buttons are encompassed in an element with attribute `data-heart-component="Actions"`
 *   - The primary action button can be clicked using `cy.clickSubmitButton();`
 */
const Wizard = ({ breadcrumbs, title, notice, pages }) => {
  const { currentPage } = useCurrentPageSearchParam({ defaultPage: 0 });
  const parsedCurrentPage = parseInt(currentPage, 10);

  return (
    <WizardPageContext.Provider value={{ pages }}>
      <Layout
        breadcrumbs={breadcrumbs}
        pageTitle={title}
        pageNotice={notice}
        sidebar={{
          content: <WizardSidebar label={title} />,
          title: t("step_of_total_steps", {
            step: parsedCurrentPage + 1,
            total: pages.length,
          }),
        }}
        main={{
          content: (
            <LiveRegion className={styles.liveRegion}>
              {pages.map((PageComponent, index) => (
                <If
                  condition={index === parsedCurrentPage}
                  key={PageComponent.props.pageTitle}
                >
                  {PageComponent}
                </If>
              ))}
            </LiveRegion>
          ),
        }}
      />
    </WizardPageContext.Provider>
  );
};
Wizard.propTypes = {
  /** An instance of the <Breadcrumbs /> component */
  breadcrumbs: PropTypes.node,
  /** A title for the wizard */
  title: PropTypes.string.isRequired,
  /** An optional notice for the wizard that takes an object with a title and body */
  notice: PropTypes.shape({
    /** A title for the Notice */
    title: PropTypes.string,
    /** Content shown in the body of the Notice */
    body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  /** A list of pages to display within the wizard */
  pages: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
};

export default Wizard;
