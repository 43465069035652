import { Flex } from "@heart/components";

import AfcarsTable from "./AfcarsTable";

const ChildrenInFosterCare = () => (
  <Flex column>
    <h1 style={{ marginLeft: "20px" }}>Children in Foster Care</h1>
    <h2 style={{ marginLeft: "20px", padding: "0" }}>
      September 30, 2023 (N=23,507)
    </h2>
    <Flex row>
      <Flex column style={{ flex: 1 }}>
        <AfcarsTable
          stats
          key="age-stats"
          headers={["Age", "Year"]}
          data={[
            ["Mean", "7.3"],
            ["Median", "6.0"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="age-stats-2"
          headers={["Age", "%", "Number"]}
          data={[
            ["Less than 1 Year", "8%", "1,933"],
            ["1 Year", "10%", "2,277"],
            ["2 Years", "9%", "2,023"],
            ["3 Years", "7%", "1,721"],
            ["4 Years", "7%", "1,548"],
            ["5 Years", "6%", "1,320"],
            ["6 Years", "6%", "1,312"],
            ["7 Years", "5%", "1,195"],
            ["8 Years", "5%", "1,116"],
            ["9 Years", "4%", "1,015"],
            ["10 Years", "4%", "885"],
            ["11 Years", "4%", "903"],
            ["12 Years", "4%", "874"],
            ["13 Years", "4%", "918"],
            ["14 Years", "4%", "912"],
            ["15 Years", "4%", "865"],
            ["16 Years", "4%", "914"],
            ["17 Years", "4%", "907"],
            ["18 Years", "2%", "405"],
            ["19 Years", "1%", "259"],
            ["20 Years", "1%", "178"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="race-eth"
          headers={["Race/Ethnicity", "%", "Number"]}
          data={[
            ["American Indian/Alaska Native", "0%", "18"],
            ["Asian", "0%", "32"],
            ["Black or African American", "29%", "6823"],
            ["Native Hawaiian/Other Pacific Islander", "0%", "9"],
            ["Hispanic (of any race)", "17%", "4067"],
            ["White", "45%", "10682"],
            ["Unknown/Unable to Determine", "1%", "281"],
            ["Two or More Races", "7%", "1590"],
          ]}
          note="All races exclude children of Hispanic origin. Children of Hispanic ethnicity may be any race."
        />
      </Flex>
      <Flex column style={{ flex: 1 }}>
        <AfcarsTable
          stats
          normalizeNumbers
          key="sex-stats"
          headers={["Sex", "%", "Number"]}
          data={[
            ["Male", "51%", "11,899"],
            ["Female", "49%", "11,603"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="most-recent-placement-setting"
          headers={["Most recent placement setting", "%", "Number"]}
          data={[
            ["Pre-Adoptive Home", "2%", "354"],
            ["Foster Family Home (Relative)", "42%", "9813"],
            ["Foster Family Home (Non-Relative)", "44%", "10339"],
            ["Group Home", "7%", "1658"],
            ["Institution", "1%", "342"],
            ["Supervised Independent Living", "3%", "821"],
            ["Runaway", "1%", "180"],
            ["Trial Home Visit", "0%", "0"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="case-plan-goal"
          headers={["Case plan goal", "%", "Number"]}
          data={[
            ["Reunify with Parent(s) or Primary Caretaker(s)", "49%", "11,073"],
            ["Live with Other Relative(s)", "0%", "41"],
            ["Adoption", "39%", "8,743"],
            ["Long Term Foster Care", "0%", "0"],
            ["Emancipation", "4%", "963"],
            ["Guardianship", "5%", "1,022"],
            ["Case Plan Goal Not Yet Established", "4%", "816"],
          ]}
        />
        <AfcarsTable
          stats
          key="time-in-care-1"
          headers={["Time in care", "Months"]}
          data={[
            ["Mean", "17.6"],
            ["Median", "12.5"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="time-in-care-2"
          headers={["Time in care", "%", "Number"]}
          data={[
            ["Less than 1 Month", "5%", "1,208"],
            ["1 - 5 Months", "22%", "5,115"],
            ["6-11 Months", "22%", "5,100"],
            ["12-17 Months", "15%", "3,444"],
            ["18-23 Months", "11%", "2,566"],
            ["24-29 Months", "8%", "1,986"],
            ["30-35 Months", "5%", "1,290"],
            ["3-4 Years", "9%", "2,114"],
            ["5 Years or More", "3%", "684"],
          ]}
        />
      </Flex>
    </Flex>
  </Flex>
);

export default ChildrenInFosterCare;
