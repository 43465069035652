import { useMutation } from "@apollo/client";
import {
  Button,
  Flex,
  FlexItem,
  HeartTable,
  Link,
  Text,
  LabeledContent,
  TableCellList,
} from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { familyFindingClearPersonSearchPath } from "routes";

import { translationWithRoot } from "@components/T";

import CreateClearPersonSearch from "@graphql/mutations/CreateClearPersonSearch.graphql";

import { dateTimeFromAnyFormat } from "@lib/dates";

import ConfirmationModal from "./ConfirmationModal";
import LinkPotentialConnectionModal from "./LinkPotentialConnectionModal";
import ResultModalNotice from "./ResultModalNotice";

const { Table, Caption, Td, Th, Thead, Tr, Tbody } = HeartTable;

const { t, T } = translationWithRoot("family_finding.potential_kin_search");

const { T: CommonT } = translationWithRoot("views.common", {
  escapeJavascriptRoot: true,
});

const formatCityLine = ({ city, state, zip }) => {
  if (!city || !state) {
    return [city, state, zip].filter(Boolean).join(" ");
  }

  return [[city, state].join(", "), zip].filter(Boolean).join(" ");
};

const PotentialKinTable = ({ dataLoaded, potentialKin, caption }) => {
  const [resultForConfirmationModal, setResultForConfirmationModal] =
    useState();
  const [linkModalResult, setLinkModalResult] = useState();
  const [modalTypeVisibility, setModalTypeVisibility] = useState();
  const [
    childAgencyHumansForSuccessModal,
    setChildAgencyHumansForSuccessModal,
  ] = useState([]);
  const [linkConnectionResults, setLinkConnectionResults] = useState([]);

  const [createSearch, { loading: mutationLoading }] = useMutation(
    CreateClearPersonSearch
  );

  return (
    <Fragment>
      <ConfirmationModal
        onSubmit={async () => {
          createSearch({
            variables: {
              criteria: {
                entityId: resultForConfirmationModal.entityId,
              },
            },
          }).then(({ data }) => {
            setResultForConfirmationModal();
            window
              .open(
                familyFindingClearPersonSearchPath(
                  data.createClearPersonSearch.search.id
                ),
                "_blank"
              )
              .focus();
          });
        }}
        onCancel={() => setResultForConfirmationModal()}
        hidden={!resultForConfirmationModal}
      />
      <Table>
        <Caption>{caption}</Caption>
        <Thead>
          <Tr>
            <Th>
              <T t="results.name" />
            </Th>
            <Th>
              <T t="results.personal_information" />
            </Th>
            <Th>
              <T t="results.contact_information" />
            </Th>
            <Th>
              <T t="results.actions" />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <If condition={dataLoaded && isEmpty(potentialKin)}>
            <Tr>
              <Td colSpan={4}>
                <Flex grow justify="center">
                  {t("no_data")}
                </Flex>
              </Td>
            </Tr>
          </If>
          {potentialKin.map(result => (
            <Tr key={result.entityId}>
              <Td>
                <Flex column>
                  <FlexItem>
                    <Text textStyle="emphasis-200">{result.fullName}</Text>
                  </FlexItem>
                  <If condition={result.relativeDegree}>
                    <FlexItem>
                      <Text textStyle="supporting-150" as="em">
                        {t("search_result_details.degrees_of_relation", {
                          count: result.relativeDegree,
                        })}
                      </Text>
                    </FlexItem>
                  </If>
                </Flex>
              </Td>
              <Td>
                <TableCellList
                  items={[
                    <LabeledContent
                      key={result.dateOfBirth}
                      label={<CommonT t="date_of_birth" />}
                      content={
                        result.dateOfBirth
                          ? dateTimeFromAnyFormat(result.dateOfBirth, {
                              zone: "UTC",
                              setZone: true,
                            }).toLocaleString()
                          : t("personal_information.no_data")
                      }
                    />,
                    result.dateOfDeath && (
                      <LabeledContent
                        label={<CommonT t="date_of_death" />}
                        content={dateTimeFromAnyFormat(result.dateOfDeath, {
                          zone: "UTC",
                          setZone: true,
                        }).toLocaleString()}
                        key={result.dateOfDeath}
                      />
                    ),
                    <LabeledContent
                      label={<CommonT t="address" />}
                      content={
                        result.primaryAddress?.addressLine1 ||
                        t("personal_information.no_data")
                      }
                      /** Using entityId as well in case addressLine1 is undefined */
                      key={`${result.primaryAddress?.addressLine1}-${result.entityId}`}
                    />,
                    formatCityLine(result.primaryAddress || {}),
                    t("results.additional_addresses", {
                      count: result.additionalAddressesCount,
                    }),
                  ].filter(value => !isEmpty(value))}
                />
              </Td>
              <Td>
                <TableCellList
                  items={[
                    <LabeledContent
                      key={result.phoneNumber}
                      label={<CommonT t="phone_number" />}
                      content={
                        result.phoneNumber ? (
                          result.phoneNumber.phoneNumber
                        ) : (
                          <Text textColor="neutral-600">
                            {t("personal_information.no_data")}
                          </Text>
                        )
                      }
                    />,
                    t("results.additional_phone_numbers", {
                      count: result.additionalPhoneNumbersCount,
                    }),
                    <LabeledContent
                      key={result.emailAddress}
                      label={<CommonT t="email_address" />}
                      content={
                        result.emailAddress ? (
                          <Link href={`mailto:${result.emailAddress}`}>
                            {result.emailAddress}
                          </Link>
                        ) : (
                          <Text textColor="neutral-600">
                            {t("personal_information.no_data")}
                          </Text>
                        )
                      }
                    />,
                    t("results.additional_email_addresses", {
                      count: result.additionalEmailAddressesCount,
                    }),
                  ]}
                />
              </Td>
              <Td>
                <Flex column>
                  <FlexItem>
                    <Button
                      variant="primary"
                      submitting={mutationLoading}
                      onClick={() => setResultForConfirmationModal(result)}
                    >
                      <T t="results.more_details" />
                    </Button>
                  </FlexItem>
                  <FlexItem>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setLinkModalResult(result);
                        setModalTypeVisibility("linkPotential");
                      }}
                    >
                      <T t="results.link_as_potential_connection" />
                    </Button>
                  </FlexItem>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <LinkPotentialConnectionModal
        hidden={modalTypeVisibility !== "linkPotential"}
        setModalTypeVisibility={setModalTypeVisibility}
        onCancel={() => setModalTypeVisibility(undefined)}
        childAgencyHumans={[]}
        setChildAgencyHumansForSuccessModal={
          setChildAgencyHumansForSuccessModal
        }
        searchData={linkModalResult}
        setLinkConnectionResults={setLinkConnectionResults}
      />
      <ResultModalNotice
        hidden={modalTypeVisibility !== "success"}
        onClose={() => setModalTypeVisibility(undefined)}
        childAgencyHumans={childAgencyHumansForSuccessModal}
        linkConnectionResults={linkConnectionResults}
      />
    </Fragment>
  );
};

PotentialKinTable.propTypes = {
  dataLoaded: PropTypes.bool,
  potentialKin: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    })
  ),
  caption: PropTypes.string.isRequired,
};

export default PotentialKinTable;
