import { Flex, UploadButton } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import uploadRequirementFiles from "../uploadRequirementFiles";

const CaregiverActionsButtons = ({
  holderToken,
  fulfillment,
  createUploadedRecord,
}) => {
  const { isOverridden, requirement } = fulfillment;

  let cellContents = null;

  const uploadFiles = uploadRequirementFiles({
    holderToken,
    createUploadedRecord,
  })({
    requirement,
  });

  if (isOverridden) {
    cellContents = null;
  } else {
    cellContents = (
      <Fragment>
        <UploadButton onUpload={uploadFiles} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Flex justify="end">{cellContents}</Flex>
    </Fragment>
  );
};
CaregiverActionsButtons.propTypes = {
  holderToken: PropTypes.string.isRequired,
  fulfillment: PropTypes.shape({
    isOverridden: PropTypes.bool.isRequired,
    formInstance: PropTypes.shape({
      id: PropTypes.string.isRequired,
      editUrl: PropTypes.string,
    }),
    requirement: PropTypes.shape({
      id: PropTypes.number.isRequired,
      form: PropTypes.shape({ slug: PropTypes.string }),
      title: PropTypes.string.isRequired,
      uploadType: PropTypes.shape({ slug: PropTypes.string }),
    }).isRequired,
  }),
  /** GraphQL Mutations */
  createUploadedRecord: PropTypes.func.isRequired,
};

export default CaregiverActionsButtons;
