import { useQuery, useMutation } from "@apollo/client";
import { Actions, InputText, SurfaceForm } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import Spinner from "@components/Spinner";
import ErrorBanner from "@components/shared/banner/ErrorBanner";

import EditRoleInfo from "@graphql/mutations/EditRoleInfo";
import Role from "@graphql/queries/Role.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const RoleInfoEditor = ({ toggleToReadOnly, roleId }) => {
  const { data, loading, error } = useQuery(Role, {
    variables: { roleId },
  });

  const [editRoleInfo, { mutationLoading, mutationError }] = useMutation(
    EditRoleInfo,
    {
      refetchQueries: [{ query: Role, variables: { id: roleId } }],
      onCompleted: toggleToReadOnly,
    }
  );

  const { formState, setFormAttribute } = useBintiForm(data.role);

  if (error) return <ErrorBanner message={error.message} />;
  if (mutationError) return <ErrorBanner message={mutationError.message} />;

  if (loading || mutationLoading) return <Spinner />;

  const onSubmit = () => {
    preventDefault(
      editRoleInfo({
        variables: {
          slug: formState.slug,
          roleName: formState.name,
          roleId: roleId,
        },
        onCompleted: toggleToReadOnly,
      })
    );
  };

  return (
    <SurfaceForm
      title="Edit Role Information"
      actions={<Actions cancelAction={toggleToReadOnly} />}
      onSubmit={onSubmit}
    >
      <InputText
        label={I18n.t("common.slug")}
        value={formState.slug}
        onChange={setFormAttribute("slug")}
      />
      <InputText
        label={I18n.t("javascript.components.permissions.role_name")}
        value={formState.name}
        onChange={setFormAttribute("name")}
      />
    </SurfaceForm>
  );
};

RoleInfoEditor.propTypes = {
  roleId: BintiPropTypes.ID,
  toggleToReadOnly: PropTypes.func,
};

export default RoleInfoEditor;
