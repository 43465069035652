import { InputCheckbox, Flex } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import {
  OTHER_PERMITTED_KEY,
  GIVEN_OPTIONS_KEY,
} from "@lib/overridableHelpers";

import OverridableDetailsOptionsSpecInput from "./OverridableDetailsOptionsSpecInput";
import styles from "./OverridableInput.module.scss";

/** Configure override reasons for a particular overridable requirement.
 *    Upload Types or Forms > New > Override Options
 */
const OverridableReasonSpecInput = ({
  reason,
  spec = {},
  updateReasonSpec,
}) => {
  const [showDetails, setShowDetails] = useState(!isEmpty(spec));

  const toggleShowDetails = () => {
    if (showDetails) {
      updateReasonSpec(undefined);
    }

    setShowDetails(!showDetails);
  };

  const toggleOtherPermitted = () => {
    updateReasonSpec(
      Object.assign({}, spec, {
        [OTHER_PERMITTED_KEY]: !spec[OTHER_PERMITTED_KEY],
      })
    );
  };

  const updateOptions = options => {
    updateReasonSpec(Object.assign({}, spec, { [GIVEN_OPTIONS_KEY]: options }));
  };

  // note that we don't give a name to the checkbox below because we don't
  // want to submit it.
  return (
    <div className={styles.reasonBlock}>
      <InputCheckbox
        value={showDetails}
        onChange={toggleShowDetails}
        label={reason}
      />
      {showDetails && (
        <Flex row align="center" justify="space-between" wrap>
          <InputCheckbox
            value={spec[OTHER_PERMITTED_KEY]}
            onChange={toggleOtherPermitted}
            label='Allow user to input "other" details'
          />
          <OverridableDetailsOptionsSpecInput
            options={spec[GIVEN_OPTIONS_KEY]}
            updateOptions={updateOptions}
          />
        </Flex>
      )}
    </div>
  );
};

OverridableReasonSpecInput.propTypes = {
  reason: PropTypes.string.isRequired,
  updateReasonSpec: PropTypes.func.isRequired,
  spec: PropTypes.object,
};

export default OverridableReasonSpecInput;
