import I18n from "i18n-js";
import { camelCase, isEmpty, snakeCase } from "lodash";

export const GIVEN_OPTIONS_KEY = "givenOptions";
export const OTHER_KEY = "other";
export const EN_LOCALE = "en";
export const OPTION_KEY_KEY = "optionKey";
export const OTHER_PERMITTED_KEY = "otherPermittedKey";

export const NOT_APPLICABLE = "notApplicable";
export const TRACKED_OUTSIDE_OF_BINTI = "trackedOutsideOfBinti";
export const FULFILLED_BY_OTHER_MEANS = "fulfilledByOtherMeans";
export const EXCEPTION_GRANTED = "exceptionGranted";

export const OVERRIDE_REASON_KEYS = Object.freeze([
  NOT_APPLICABLE,
  TRACKED_OUTSIDE_OF_BINTI,
  FULFILLED_BY_OTHER_MEANS,
  EXCEPTION_GRANTED,
]);

export const LEGACY_OVERRIDABLE_SPEC = Object.freeze({
  caseworker: {
    notApplicable: {
      otherPermittedKey: true,
    },
    trackedOutsideOfBinti: {
      otherPermittedKey: true,
    },
    fulfilledByOtherMeans: {
      otherPermittedKey: true,
    },
    exceptionGranted: {
      otherPermittedKey: true,
    },
  },
  supervisor: {
    notApplicable: {
      otherPermittedKey: true,
    },
    trackedOutsideOfBinti: {
      otherPermittedKey: true,
    },
    fulfilledByOtherMeans: {
      otherPermittedKey: true,
    },
    exceptionGranted: {
      otherPermittedKey: true,
    },
  },
});

export const overridableSpecForRequirement = requirement =>
  requirement.uploadType?.overridableSpec || requirement.form?.overridableSpec;

export const isOverridableForReason = ({
  requirement,
  reason,
  providerRole = requirement.providerRole,
} = {}) => {
  const overridableSpec = overridableSpecForRequirement(requirement);

  return !!(
    overridableSpec &&
    overridableSpec[providerRole] &&
    overridableSpec[providerRole][reason] &&
    (overridableSpec[providerRole][reason][OTHER_PERMITTED_KEY] ||
      !isEmpty(overridableSpec[providerRole][reason][GIVEN_OPTIONS_KEY]))
  );
};

export const isOverridable = ({
  requirement,
  providerRole = requirement.providerRole,
} = {}) =>
  OVERRIDE_REASON_KEYS.some(reason =>
    isOverridableForReason({ requirement, reason, providerRole })
  );

/**
 * Constructs an array of Select options given a requirement, a providerRole,
 * and a reason.
 */
export const overridableOptions = ({ requirement, providerRole, reason }) => {
  if (!isOverridableForReason({ requirement, reason, providerRole })) {
    return [];
  }

  const overridableSpec = overridableSpecForRequirement(requirement);
  const options =
    overridableSpec[providerRole][reason][GIVEN_OPTIONS_KEY]?.map(option => {
      const value = option[OPTION_KEY_KEY];
      const label = option[I18n.locale];

      return { value, label };
    }) || [];

  if (overridableSpec[providerRole][reason][OTHER_PERMITTED_KEY]) {
    return options.concat([
      {
        value: OTHER_KEY,
        label: I18n.t("javascript.lib.overridable_helpers.other"),
      },
    ]);
  }

  return options;
};

export const selectedDetails = ({
  overrideRecord,
  requirement,
  providerRole,
  reason,
}) => {
  if (!isOverridableForReason({ requirement, reason, providerRole })) {
    return undefined;
  }

  const overridableSpec = overridableSpecForRequirement(requirement);
  const option = overridableSpec[providerRole][reason][GIVEN_OPTIONS_KEY]?.find(
    opt => opt[OPTION_KEY_KEY] === overrideRecord[OPTION_KEY_KEY]
  );

  if (option) {
    return { value: option[OPTION_KEY_KEY], label: option[I18n.locale] };
  }

  return { value: OTHER_KEY, label: overrideRecord.details };
};

export const reasonLabel = reason =>
  I18n.t(`activerecord.enums.override_record.reasons.${snakeCase(reason)}`);

export const isOtherTheOnlyOption = ({ requirement, providerRole, reason }) => {
  const options = overridableOptions({ requirement, providerRole, reason });

  return options.length === 1 && options[0].value === OTHER_KEY;
};

export const overrideOptionTranslation = ({
  requirement,
  overrideRecord: { reason, optionKey },
  providerRole,
}) =>
  overridableSpecForRequirement(requirement)?.[providerRole]?.[
    camelCase(reason)
  ]?.[GIVEN_OPTIONS_KEY]?.find(opt => opt[OPTION_KEY_KEY] === optionKey)?.[
    I18n.locale
  ];
