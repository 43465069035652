import PropTypes from "prop-types";

import {
  Table,
  Th,
  Tr,
  Thead,
  Tbody,
  Caption,
} from "@components/reusable_ui/Table";

const HouseholdMembers = ({ placementProviderRoles }) => (
  <div className="plain-panel">
    <Table>
      <Caption>Prior members of the home</Caption>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Role</Th>
          <Th>Date Joined</Th>
          <Th>Date Left</Th>
        </Tr>
      </Thead>
      <Tbody>
        {placementProviderRoles.map(priorPerson => (
          <tr key={priorPerson.id}>
            <td>
              <If condition={!priorPerson.is_deleted && !priorPerson.left_at}>
                <a href={priorPerson.user_url}>{priorPerson.name}</a>
              </If>
              <If condition={priorPerson.is_deleted || priorPerson.left_at}>
                {priorPerson.name}
              </If>
            </td>
            <td>{priorPerson.role}</td>
            <td>{priorPerson.joined_at}</td>
            <td>{priorPerson.left_at}</td>
          </tr>
        ))}
      </Tbody>
    </Table>
  </div>
);

HouseholdMembers.propTypes = {
  placementProviderRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      role: PropTypes.string,
      name: PropTypes.string,
      joined_at: PropTypes.string,
      left_at: PropTypes.string,
      user_url: PropTypes.string,
      is_deleted: PropTypes.bool,
    })
  ),
};
export default HouseholdMembers;
