import { union } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";

import DocumentsClient, {
  DocumentsClientContext,
  OtherDocument,
} from "../../clients/DocumentsClient";
import DocumentRow from "./DocumentRow";
import DocumentUploadOther from "./DocumentUploadOther";

class DocumentsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      docs: props && props.docs,
      updating: [],
    };

    this.client = new DocumentsClient({
      updatingDocument: doc => {
        const updating = union(this.state.updating, [doc]);
        this.setState({ updating });
      },
      // XXX ids may morph for references. Throw away the original and replace.
      updatedDocument: (updated, originalId = updated.id) => {
        const updating = this.state.updating.filter(
          doc => doc.id !== originalId
        );

        if (updated.id === OtherDocument.id) {
          this.setState({ updating });
          return;
        }

        const docs = this.state.docs.some(doc => doc.id === originalId)
          ? this.state.docs.map(doc => (doc.id === originalId ? updated : doc))
          : this.state.docs.concat([updated]);

        this.setState({ updating, docs });
      },
      deletedDocument: toDelete => {
        const updating = this.state.updating.filter(
          doc => doc.id !== toDelete.id
        );

        const docs = this.state.docs.filter(doc => doc.id !== toDelete.id);

        this.setState({ updating, docs });
      },
    });
  }

  render() {
    const { docs = [], updating } = this.state;
    const {
      applicationId,
      caption = "",
      withLinksToDelete,
      withLinksToUpload,
      canManuallyMarkDocumentsComplete,
      uploadButton,
      otherCategory,
    } = this.props;

    return (
      <DocumentsClientContext.Provider value={this.client}>
        <table
          border="0"
          cellSpacing="0"
          cellPadding="0"
          className="breakout-table documents-table"
          data-testid="documents-table"
        >
          <thead>
            <tr>
              <th className="large-row">Title</th>
              <th className="min-width numeric-column status-column js-status">
                Status
              </th>
              <th>Document</th>
              {withLinksToUpload && <th>Mark N/A</th>}
              {canManuallyMarkDocumentsComplete && <th>Received paper copy</th>}
              {withLinksToUpload && (
                <th>
                  Upload or
                  <br /> Drag & Drop
                </th>
              )}
            </tr>
          </thead>
          <caption>
            {caption}
            {uploadButton && (
              <DocumentUploadOther
                applicationId={applicationId}
                category={otherCategory}
                backgroundLoading={updating.some(
                  u => u.id === OtherDocument.id
                )}
              />
            )}
          </caption>
          <tbody>
            {docs.map((doc, i) => (
              <DocumentRow
                {...{
                  doc,
                  withLinksToDelete,
                  withLinksToUpload,
                  canManuallyMarkDocumentsComplete,
                  backgroundLoading: updating.some(u => u.id === doc.id),
                }}
                key={`${caption}-${i}`}
              />
            ))}
          </tbody>
        </table>
      </DocumentsClientContext.Provider>
    );
  }
}

DocumentsTable.propTypes = {
  caption: PropTypes.string,
  applicationId: PropTypes.number.isRequired,
  docs: PropTypes.array,
  withLinksToUpload: PropTypes.bool,
  withLinksToDelete: PropTypes.bool,
  canManuallyMarkDocumentsComplete: PropTypes.bool,
  uploadButton: PropTypes.bool,
  otherCategory: PropTypes.string,
};

export default DocumentsTable;
