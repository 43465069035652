import { Link } from "@heart/components";
import PropTypes from "prop-types";
import { Component } from "react";

import T from "@components/T";

import preventDefault from "@lib/preventDefault";

class ResetSignatureData extends Component {
  onClick = preventDefault(() => {
    if (
      // TODO ENG-13258 replace with styled confirm dialog
      // eslint-disable-next-line no-alert
      window.confirm(I18n.t("javascript.components.common.confirm_reset"))
    ) {
      const { doc, client } = this.props;

      client.resetSignatureData(doc);
    }
  });

  render() {
    const { backgroundLoading } = this.props;

    return (
      <Link onClick={this.onClick} disabled={backgroundLoading}>
        <T t="common.reset_signatures" />
      </Link>
    );
  }
}

ResetSignatureData.propTypes = {
  doc: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  backgroundLoading: PropTypes.bool,
};

export default ResetSignatureData;
