import { PageContainer } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./CcwisDemo.module.scss";
import aiIcon from "./ai-icon.svg";

const AISurface = ({ children }) => (
  <PageContainer fullWidth>
    <div className={styles.aiSurface}>
      <h2 className={styles.surfaceTitle}>
        <img
          className={styles.icon}
          src={aiIcon}
          aria-hidden="true"
          alt="AI Icon"
        />{" "}
        Binti Assist
      </h2>
      <hr />
      {children}
    </div>
  </PageContainer>
);

AISurface.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AISurface;
