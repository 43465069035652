import { Flex, Link } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { policy, typeEq } from "@lib/graphqlHelpers";

import CompleteFormInstanceLink from "./CompleteFormInstanceLink";
import DeleteRequirementLink from "./DeleteRequirementLink";
import PossiblyLinkedTitle from "./PossiblyLinkedTitle";
import styles from "./Requirement.module.scss";
import SigningButtons from "./SigningButtons";

const FillableFormRequirementTitle = ({
  requirement,
  fulfillment,
  holderToken,
}) => {
  const { form } = requirement;
  const formInstance = fulfillment.records.find(typeEq("FormInstance"));

  return (
    <Fragment>
      <Flex justify="space-between">
        <PossiblyLinkedTitle {...{ formInstance, title: fulfillment.title }} />

        <If condition={policy(form).mayShow()}>
          <Link href={form.viewLink}> ({form.slug})</Link>
        </If>
      </Flex>

      <div className={styles.signingButtons}>
        <SigningButtons {...{ formInstance, fulfillment }} />
      </div>

      <Flex justify="start">
        <If condition={policy(requirement).mayDestroy()}>
          <DeleteRequirementLink {...{ requirement }} />
        </If>

        <If condition={formInstance && policy(formInstance).mayComplete()}>
          <CompleteFormInstanceLink {...{ formInstance, holderToken }} />
        </If>
      </Flex>
    </Fragment>
  );
};

FillableFormRequirementTitle.propTypes = {
  // reference form requirements don't always have a form instance yet, thus
  // this prop is optional for those cases
  holderToken: PropTypes.string.isRequired,
  requirement: PropTypes.object.isRequired,
  fulfillment: PropTypes.object.isRequired,
};

export default FillableFormRequirementTitle;
