import { useQuery } from "@apollo/client";
import { DetailsTable } from "@heart/components";
import I18n from "i18n-js";
import React from "react";

import Spinner from "@components/Spinner";

import AppealQuery from "@graphql/queries/Appeal.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const ViewFinalAppealOutcome = ({ appealId }) => {
  const { data, loading } = useQuery(AppealQuery, {
    variables: { appealId },
  });

  if (loading) {
    return <Spinner />;
  }

  const {
    appeal: {
      appealOutcome,
      appealResolvedDate,
      conditions,
      appealOutcomeNotes,
    },
  } = data || { appeal: {} };

  return (
    <DetailsTable
      details={[
        {
          label: I18n.t("javascript.components.appeals.appeal_outcome"),
          value: appealOutcome
            ? I18n.t(`javascript.components.appeal_outcomes.${appealOutcome}`)
            : "",
        },
        {
          label: I18n.t("javascript.components.appeals.appeal_outcome_date"),
          value: appealResolvedDate,
        },
        {
          label: I18n.t("javascript.components.appeals.conditions"),
          value: conditions,
        },
        {
          label: I18n.t("javascript.components.appeals.appeal_outcome_notes"),
          value: appealOutcomeNotes,
        },
      ]}
    />
  );
};

ViewFinalAppealOutcome.propTypes = {
  appealId: BintiPropTypes.ID,
};

export default ViewFinalAppealOutcome;
