import { useMutation } from "@apollo/client";
import { Button } from "@heart/components";
import camelize from "camelize";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import CreateUploadTypeOverride from "@graphql/mutations/CreateUploadTypeOverride.graphql";
import DeleteOverrideRecord from "@graphql/mutations/DeleteOverrideRecord.graphql";

import { policy } from "@lib/graphqlHelpers";
import {
  isOverridable,
  NOT_APPLICABLE,
  OTHER_KEY,
  TRACKED_OUTSIDE_OF_BINTI,
} from "@lib/overridableHelpers";

const LEGACY_DETAILS = "legacy";

// There might be a better place to put/get this constant but I can't think
// of one at time of writing.
const CASEWORKER = "caseworker";

const UploadTypeOverride = ({
  createOverrideLabel,
  deleteOverrideLabel,
  aroundLoader,
  fulfillment,
  reason,
  grantDate = DateTime.local().toISO(),
  application,
}) => {
  const [createUploadTypeOverride] = useMutation(CreateUploadTypeOverride);
  const [deleteOverrideRecord] = useMutation(DeleteOverrideRecord);

  if (!policy(fulfillment).mayCreateOverrideRecord()) return false;

  if (
    !isOverridable({
      requirement: fulfillment.requirement,
      reason,
      providerRole: CASEWORKER,
    })
  ) {
    return false;
  }

  const { requirement, records, isFulfilled } = fulfillment;
  const overrideRecord = records.find(
    record =>
      camelize(record.overrideType) === "uploadType" &&
      camelize(record.reason) === reason
  );

  if (overrideRecord) {
    const deleteOverride = aroundLoader(() =>
      deleteOverrideRecord({
        variables: {
          holderToken: application.holderToken,
          overrideRecordId: overrideRecord.id,
        },
      })
    );

    return (
      <Button variant="secondary" onClick={deleteOverride}>
        {deleteOverrideLabel}
      </Button>
    );
  }

  if (isFulfilled && !overrideRecord) return false;

  const createOverride = aroundLoader(() =>
    createUploadTypeOverride({
      variables: {
        holderToken: application.holderToken,
        uploadTypeSlug: requirement.uploadType.slug,
        reason,
        optionKey: OTHER_KEY,
        details: LEGACY_DETAILS,
        grantDate,
        // requirement.userAgencyProfile may be undefined if the
        // requirement isn't bound to a particular user, e.g. applicant
        userAgencyProfileId: requirement.userAgencyProfile?.id,
        replaceOverrideRecordId: overrideRecord && overrideRecord.id,
      },
    })
  );

  return (
    <Button variant="secondary" onClick={createOverride}>
      {createOverrideLabel}
    </Button>
  );
};

UploadTypeOverride.propTypes = {
  createOverrideLabel: PropTypes.string.isRequired,
  deleteOverrideLabel: PropTypes.string.isRequired,
  aroundLoader: PropTypes.func.isRequired,
  fulfillment: PropTypes.object.isRequired,
  reason: PropTypes.oneOf([NOT_APPLICABLE, TRACKED_OUTSIDE_OF_BINTI]),
  grantDate: PropTypes.string,
  application: PropTypes.object.isRequired,
};

export default UploadTypeOverride;
