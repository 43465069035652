import { gql } from "@apollo/client";
import {
  InputFilterable,
  MultiInputTemplate,
  InputDate,
  InputRadioGroup,
  InputsRow,
  DetailsTable,
  Surface,
  Link,
} from "@heart/components";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import CreateCriminalCodeModal from "./CreateCriminalCodeModal";

const { t } = translationWithRoot(
  "background_check_exemptions.arrest_conviction_inputs"
);

const valuesFromAutocompleteResponse = response =>
  (response?.criminalCodeMatches || []).map(
    ({ id, label, severity, codeType, state }) => ({
      id,
      label, // label is code + description
      severity,
      codeType,
      state,
    })
  );

const CriminalCodeSearch = gql`
  query CriminalCodeSearch($inputQuery: String!) {
    criminalCodeMatches(inputQuery: $inputQuery) {
      id
      label
      severity
      codeType
      state
    }
  }
`;

const ArrestAndConvictionsLog = ({
  formState,
  setFormAttribute,
  arrestConvictionRecords,
  agencyId,
  createModalHidden,
  setCreateModalHidden,
  usStateOptions,
  agencyBackgroundChecks,
  codeSeverityList,
  codeTypes,
}) => (
  <Fragment>
    <MultiInputTemplate
      title={t("arrest_conviction")}
      onChange={setFormAttribute("arrestConvictionRecords")}
      onDelete={setFormAttribute("arrestConvictionRecordIdsToDelete")}
      startingValues={arrestConvictionRecords || []}
      defaultValues={{
        source: "",
      }}
      sectionInputs={({ getMultiInputProps }) => {
        // collect the isOnProbation props here so we can use it
        // for the conditional below
        const isOnProbationProps = getMultiInputProps({
          id: "isOnProbation",
        });
        // collect criminalCodeProps so the values can be displayed in the DetailsTable
        const criminalCodeProps = getMultiInputProps({
          id: "criminalCode",
        });

        return (
          <Fragment>
            <InputFilterable
              {...getMultiInputProps({ id: "source" })}
              options={agencyBackgroundChecks}
              label={t("source")}
              description={t("how_do_you_know_source")}
            />
            <InputDate
              {...getMultiInputProps({ id: "incidentDate" })}
              label={t("date_of_conviction")}
            />
            <InputsRow>
              <InputRadioGroup
                {...isOnProbationProps}
                label={t("is_on_probation")}
                orientation="row"
                values={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
              <If condition={isOnProbationProps.value}>
                <InputDate
                  {...getMultiInputProps({ id: "probationEndDate" })}
                  label={t("probation_end_date")}
                />
              </If>
            </InputsRow>
            <InputAutocompleteGraphQL
              {...criminalCodeProps}
              label={t("search_list_of_codes")}
              query={CriminalCodeSearch}
              getOptionValue={option => option.id}
              valuesFromResponse={valuesFromAutocompleteResponse}
              placeholder={t("search_placeholder")}
            />
            <p>
              {t("start_typing_code_text")}{" "}
              <Link onClick={() => setCreateModalHidden(false)}>
                {t("click_here")}
              </Link>{" "}
              {t("enter_it_manually")}
            </p>
            <If condition={criminalCodeProps.value}>
              <Surface title={t("code_details")}>
                <DetailsTable
                  details={[
                    {
                      label: t("code_and_description"),
                      value: criminalCodeProps.value.label,
                    },
                    {
                      label: t("severity"),
                      value: I18n.t(
                        `activerecord.enums.background_check_exemption.severity.${criminalCodeProps.value.severity}`
                      ),
                    },
                    {
                      label: t("code_type"),
                      value: I18n.t(
                        `activerecord.enums.background_check_exemption.code_type.${criminalCodeProps.value.codeType}`
                      ),
                    },
                    {
                      label: t("state"),
                      value: criminalCodeProps.value.state,
                    },
                  ]}
                />
              </Surface>
            </If>
          </Fragment>
        );
      }}
      addSectionText={t("add_another")}
      removeSectionText={t("remove")}
    />
    <CreateCriminalCodeModal
      formState={formState}
      agencyId={agencyId}
      usStateOptions={usStateOptions}
      codeSeverityList={codeSeverityList}
      codeTypes={codeTypes}
      hidden={createModalHidden}
      onClose={() => setCreateModalHidden(true)}
    />
  </Fragment>
);

ArrestAndConvictionsLog.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
  arrestConvictionRecords: PropTypes.array.isRequired,
  agencyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  usStateOptions: PropTypes.object.isRequired,
  agencyBackgroundChecks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  codeSeverityList: PropTypes.array.isRequired,
  codeTypes: PropTypes.array.isRequired,
  createModalHidden: PropTypes.bool.isRequired,
  setCreateModalHidden: PropTypes.func.isRequired,
};

export default ArrestAndConvictionsLog;
