import { entries, find, isEmpty, isEqual, some } from "lodash";

// SigningEvents have role-oriented RequiredSignatures which list the
// pages on which those roles need signatures.

// The view however needs a page-oriented list of roles requiring signatures.
// These methods invert that matrix.

/**
 * For a given signingEvent, returns a map from page numbers with
 * signature locations to an array of objects with:
 *
 * - a location
 * - the role of the location
 * - a boolean `isSigned` indicating if it's signed
 * - a signature if it is signed
 */
const signatureLocationsByPage = signingEvent => {
  const signatureLocations = {};

  signingEvent.requiredSignatures.forEach(
    ({ locations, signedLocations, signature }) => {
      locations.forEach((location, index) => {
        const isSigned = some(
          signedLocations,
          ({ locationIndex }) => index === locationIndex
        );

        if (!signatureLocations[location.pageNumber]) {
          signatureLocations[location.pageNumber] = [];
        }

        signatureLocations[location.pageNumber].push({
          location,
          isSigned,
          signature: isSigned && signature,
        });
      });
    }
  );

  return signatureLocations;
};

// takes the output of signatureLocationsByPage and a role and produces
// a subset of just the keys of page numbers with this role's locations and just
// the locations of that role on each such page.
const locationsForSignerRoleByPage = ({ signatureLocations, signerRole }) =>
  entries(signatureLocations).reduce((acc, [pageNumber, pageLocations]) => {
    const pageRoleLocations = pageLocations.filter(
      ({ location }) => location.signerRole === signerRole
    );

    if (!isEmpty(pageRoleLocations)) {
      return Object.assign({}, acc, { [pageNumber]: pageRoleLocations });
    }

    return acc;
  }, {});

const findPageNumberWithNextAction = ({ signingEvent, signerRole }) => {
  const myRequiredSignature = find(
    signingEvent.requiredSignatures,
    ({ role }) => signerRole === role
  );

  if (!myRequiredSignature) {
    return null;
  }

  const nextUnsignedLocation = find(
    myRequiredSignature.locations,
    location =>
      !find(myRequiredSignature.signedLocations, signedLocation =>
        isEqual(signedLocation, location)
      )
  );

  if (!nextUnsignedLocation) {
    return null;
  }

  return nextUnsignedLocation.pageNumber;
};

const extractUserName = ({ signingEvent, signerRole }) =>
  signingEvent.requiredSignatures.find(({ role }) => role === signerRole)
    .userName;

export {
  extractUserName,
  signatureLocationsByPage,
  locationsForSignerRoleByPage,
  findPageNumberWithNextAction,
};
