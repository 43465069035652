import { Button } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import { copyRelationshipDetails } from "@components/family_finding/relationships/transitionRelationshipState";

const { t } = translationWithRoot("agency_human.form.relationship_to_child");

/** A button that will copy the existing relationship information between a
 * keystone Agency Human and the `copyFromAgencyHuman` to the relationship
 * between the keystone Agency Human and the `copyToAgencyHuman`
 *
 * If copyFromAgencyHumanId === copyToAgencyHumanId, no button will be rendered
 */
const CopyRelationshipDetailsButton = ({
  copyToAgencyHumanId,
  copyFromAgencyHumanId,
  copyFromName,
  formState = {},
  defaultRelationshipState,
  setFormAttribute,
  copyRelationshipDetailsFunction = copyRelationshipDetails,
}) => {
  if (copyToAgencyHumanId === copyFromAgencyHumanId) return null;
  const onClick = () => {
    setFormAttribute(
      copyRelationshipDetailsFunction({
        relationshipToCopy: formState[copyFromAgencyHumanId] || {},
        existingRelationshipDetails:
          formState[copyToAgencyHumanId] || defaultRelationshipState,
      })
    );
  };

  return (
    <Button variant="secondary" onClick={onClick}>
      {t("copy_relationship_from_child", { name: copyFromName })}
    </Button>
  );
};
CopyRelationshipDetailsButton.propTypes = {
  copyToAgencyHumanId: PropTypes.string.isRequired,
  copyFromAgencyHumanId: PropTypes.string.isRequired,
  copyFromName: PropTypes.string.isRequired,
  formState: PropTypes.object,
  /** Information that should be used to create a new relationship for the
   * copyToAgencyHuman if one does not exist yet
   */
  defaultRelationshipState: PropTypes.shape({
    keystoneAgencyHumanId: PropTypes.string,
    nonKeystoneAgencyHumanId: PropTypes.string,
  }).isRequired,
  setFormAttribute: PropTypes.func.isRequired,
  /** A function to compute the copied relationship details */
  copyRelationshipDetailsFunction: PropTypes.func,
};

export default CopyRelationshipDetailsButton;
