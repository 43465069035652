import { Flex } from "@heart/components";

import AfcarsTable from "./AfcarsTable";

const ChildrenAdoptedWithPublicAgencyInvolvement = () => (
  <Flex column>
    <h1 style={{ marginLeft: "20px" }}>Children Entering Foster Care</h1>
    <h2 style={{ marginLeft: "20px", padding: "0" }}>FY 2023 (N=3,938)</h2>
    <Flex row>
      <Flex column style={{ flex: 1 }}>
        <AfcarsTable
          stats
          key="age-stats"
          headers={["Age at Adoption", "Year"]}
          data={[
            ["Mean", "6.1"],
            ["Median", "5.0"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="age-stats-2"
          headers={["Age at Adoption", "%", "Number"]}
          data={[
            ["Less than 1 Year", "3%", "100"],
            ["1 Year", "11%", "451"],
            ["2 Years", "14%", "544"],
            ["3 Years", "11%", "430"],
            ["4 Years", "8%", "326"],
            ["5 Years", "7%", "288"],
            ["6 Years", "7%", "264"],
            ["7 Years", "6%", "235"],
            ["8 Years", "5%", "203"],
            ["9 Years", "5%", "193"],
            ["10 Years", "4%", "146"],
            ["11 Years", "4%", "141"],
            ["12 Years", "4%", "155"],
            ["13 Years", "2%", "93"],
            ["14 Years", "3%", "111"],
            ["15 Years", "2%", "89"],
            ["16 Years", "2%", "82"],
            ["17 Years", "2%", "81"],
            ["18 Years", "0%", "6"],
            ["19 Years", "0%", "0"],
            ["20 Years", "0%", "0"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="adoptive-family-structure"
          headers={["Adoptive Family Structure", "%", "Number"]}
          data={[
            ["Married Couple", "61%", "2391"],
            ["Unmarried Couple", "4%", "154"],
            ["Single Female", "31%", "1237"],
            ["Single Male", "4%", "155"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="relationship-of-adoptive-parents"
          headers={[
            "Relationship of Adoptive Parents Prior to Adoption",
            "%",
            "Number",
          ]}
          data={[
            ["Non-Relative", "23%", "910"],
            ["Foster Parent", "29%", "1,116"],
            ["Stepparent", "0%", "2"],
            ["Other Relative", "48%", "1,864"],
          ]}
        />
      </Flex>
      <Flex column style={{ flex: 1 }}>
        <AfcarsTable
          stats
          key="age-entry-stats"
          headers={[
            "Time Elapsed from Termination of Parental Rights to Adoption",
            "Months",
          ]}
          data={[
            ["Mean", "12.9"],
            ["Median", "9.6"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="age-entry-stats-2"
          headers={[
            "Time Elapsed from Termination of Parental Rights to Adoption",
            "%",
            "Number",
          ]}
          data={[
            ["Less than 1 Month", "0%", "7"],
            ["1 - 5 Months", "31%", "1,199"],
            ["6-11 Months", "29%", "1,125"],
            ["12-17 Months", "18%", "699"],
            ["18-23 Months", "9%", "364"],
            ["24-29 Months", "5%", "197"],
            ["30-35 Months", "3%", "118"],
            ["3-4 Years", "3%", "126"],
            ["5 Years or More", "1%", "33"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="race-eth"
          headers={["Race/Ethnicity", "%", "Number"]}
          data={[
            ["American Indian/Alaska Native", "0%", "0"],
            ["Asian", "0%", "4"],
            ["Black or African American", "24%", "938"],
            ["Native Hawaiian/Other Pacific Islander", "0%", "2"],
            ["Hispanic (of any race)", "15%", "587"],
            ["White", "52%", "2,035"],
            ["Unknown/Unable to Determine", "1%", "26"],
            ["Two or More Races", "9%", "346"],
          ]}
          note="All races exclude children of Hispanic origin. Children of Hispanic ethnicity may be any race."
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="sex-stats"
          headers={["Sex", "%", "Number"]}
          data={[
            ["Male", "50%", "1985"],
            ["Female", "50%", "1953"],
          ]}
        />
        <AfcarsTable
          stats
          normalizeNumbers
          key="subsidy"
          headers={["Receive Adoption Subsidy", "%", "Number"]}
          data={[
            ["Yes", "95%", "3759"],
            ["No", "5%", "179"],
          ]}
        />
      </Flex>
    </Flex>
  </Flex>
);

export default ChildrenAdoptedWithPublicAgencyInvolvement;
