$(() => {
  // Toggle disabled-ness of email and explicit no email fields

  function on_explicitly_has_no_email_change(event) {
    const { target } = event;
    // NOTE assumption is that applicant email field is always previous field
    // before explicitly no email checkbox
    const $email_field = $(target)
      .closest("li")
      .prev("li[id*='email']")
      .find("input");

    // NOTE *only* toggling disabled-ness, not doing any further validation, to
    // keep client simple; handle edge cases in the backend
    if (target.checked) {
      $email_field.attr("disabled", "disabled");
    } else {
      $email_field.attr("disabled", null);
    }
  }

  function on_email_change(event) {
    const { target } = event;
    // NOTE assumption is that explicitly no email checkbox is always next field
    // after applicant email
    const $explictly_has_no_email_field = $(target)
      .closest("li")
      .next("li[id*='explicitly_has_no_email']")
      .find("input[type='checkbox']");

    if (!$explictly_has_no_email_field) {
      return;
    }

    // NOTE *only* toggling disabled-ness, not doing any further validation, to
    // keep client simple; handle edge cases in the backend
    if (target.value.length > 0) {
      $explictly_has_no_email_field.attr("disabled", "disabled");
    } else {
      $explictly_has_no_email_field.attr("disabled", null);
    }
  }

  const toggle_visibility_of_mailing_address = () =>
    $(".js-mailing-address-field").toggle();

  const update_county_details_visibility = function (
    $county_wrapper,
    $state_select
  ) {
    const state_value = $state_select.val();
    if (state_value) {
      $county_wrapper.hide();
    } else {
      $county_wrapper.show();
    }
  };

  function update_referral_details_visibility($target) {
    const $fieldset = $target.closest("fieldset");
    const $detail_wrapper = $fieldset.find(".js-out-of-county-referral-detail");
    const $county_wrapper = $fieldset.find(".js-out-of-county-referral-county");
    const $state_select = $fieldset.find("select[name*='referral_state']");
    const select_value = $target.val();

    $("label[for*='referral_county']").html(
      Binti.referral_county_labels[select_value]
    );

    $("label[for*='referral_state']").html(
      Binti.referral_state_labels[select_value]
    );

    if (select_value.indexOf("out_of_county") >= 0) {
      update_county_details_visibility($county_wrapper, $state_select);
      $detail_wrapper.show();
    } else {
      $detail_wrapper.hide();
    }

    const $worker_wrapper = $fieldset.find(".js-out-of-county-referral-worker");
    if (select_value.indexOf("out_of_county_sending") >= 0) {
      $worker_wrapper.show();
    } else {
      $worker_wrapper.hide();
    }
  }

  const on_referral_type_change = event =>
    update_referral_details_visibility($(event.target));

  function toggle_visibility_of_child_questions(target) {
    const child_fields = $(".has_many_container.child_agency_placements");
    if ($(target).val() === "true") {
      child_fields.show();
    } else {
      child_fields.hide();
    }
  }

  $("input[name*='explicitly_has_no_email']").change(
    on_explicitly_has_no_email_change
  );

  $("input[name*='email']").on("change keyup", on_email_change);

  $("input[name*='mailing_address_is_same_as_physical']").on(
    "change",
    toggle_visibility_of_mailing_address
  );

  $(".js-referral-type").on("change", on_referral_type_change);
  $(".js-referral-type").each(function () {
    return update_referral_details_visibility($(this));
  });
  toggle_visibility_of_child_questions(".js-child-associated:checked");
  $(".js-out-of-county-referral-state").on("change", on_referral_state_change);

  function on_referral_state_change(event) {
    const $state_select = $(event.target);
    const $fieldset = $state_select.closest("fieldset");
    const $county_wrapper = $fieldset.find(".js-out-of-county-referral-county");

    return update_county_details_visibility($county_wrapper, $state_select);
  }

  $(document).on("change", ".js-child-select", function () {
    const new_child_fields = $(this)
      .parents(".js-child-agency-placement")
      .find(".js-new-child-field");
    if ($(this).val() === "-1") {
      new_child_fields.show();
    } else {
      new_child_fields.hide();
    }
  });

  $(document).on("click", ".js-child-in-home", function () {
    const child_in_home_fields = $(this)
      .parents(".js-child-agency-placement")
      .find(".js-child-in-home-field");
    if ($(this).val() === "true") {
      child_in_home_fields.show();
    } else {
      child_in_home_fields.hide();
    }
  });

  $(document).on("click", ".js-child-associated", function () {
    return toggle_visibility_of_child_questions($(this));
  });
});
