import { Button, Icons } from "@heart/components";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import useCollapse from "react-collapsed";

import styles from "./InputPanel.module.scss";

const InputPanel = ({
  title,
  children,
  collapsible = false,
  initiallyOpen = false,
}) => {
  const [isExpanded, setExpanded] = useState(initiallyOpen);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const ariaLabelId = `input-panel-${title.toString().replace(/\s+/g, "-")}`;
  const inputsSection = (
    <ol className={styles.inputPanel__inputContainer}>
      {React.Children.map(children, (input, idx) =>
        input ? <li key={idx}>{input}</li> : null
      )}
    </ol>
  );

  return (
    <fieldset className={styles.inputPanel}>
      <legend
        className={classnames(
          styles.inputPanel__legend,
          styles.inputPanel__flexContainer
        )}
        id={`${ariaLabelId}-title`}
      >
        <div className={styles.inputPanel__flexTitle}>{title}</div>
        <If condition={collapsible}>
          <Button
            {...getToggleProps({
              onClick: () => setExpanded(prevExpanded => !prevExpanded),
            })}
            description={`${isExpanded ? "Hide" : "Show"} ${title} Panel`}
            icon={isExpanded ? Icons.Minus : Icons.Plus}
          />
        </If>
      </legend>
      <If condition={collapsible}>
        <section {...getCollapseProps()}>{inputsSection}</section>
      </If>
      <If condition={!collapsible}>{inputsSection}</If>
    </fieldset>
  );
};

InputPanel.propTypes = {
  children: PropTypes.node.isRequired,
  collapsible: PropTypes.bool,
  /** when used in conjunction with the collapsible prop,
  determines whether the section starts open or closed */
  initiallyOpen: PropTypes.bool,
  title: PropTypes.node.isRequired,
};

export default InputPanel;
