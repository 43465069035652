import { gql, useMutation } from "@apollo/client";
import { Icons } from "@heart/components";
import PropTypes from "prop-types";

import PhoneNumbersByUser from "@graphql/queries/PhoneNumbersByUser";

import BintiPropTypes from "@lib/BintiPropTypes";

/**
 * DeletePhoneNumber component to delete a phone number.
 *
 * @param {string} id - The ID of the phone number to delete.
 * @param {string} personId - The ID of the person.
 * @param {string} personType - The type of the person.
 */
const DeletePhoneNumber = ({ id, personId, personType }) => {
  const [deletePhoneNumber] = useMutation(
    gql`
      mutation DeletePhoneNumber($input: DeletePhoneNumberInput!) {
        deletePhoneNumber(input: $input) {
          success
        }
      }
    `,
    {
      refetchQueries: [
        { query: PhoneNumbersByUser, variables: { personId, personType } },
      ],
    }
  );

  return (
    <Icons.Trash
      description="Delete Phone Number"
      onClick={() =>
        deletePhoneNumber({
          variables: {
            input: { id },
          },
        })
      }
    />
  );
};

DeletePhoneNumber.propTypes = {
  id: BintiPropTypes.ID,
  personId: PropTypes.string.isRequired,
  personType: PropTypes.string,
};

export default DeletePhoneNumber;
