import { LoadingOverlay, SurfaceFieldset } from "@heart/components";
import {
  useIntersectionObserver,
  useSessionStorageValue,
} from "@react-hookz/web";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

const WizardFieldset = ({
  sectionTitle,
  children,
  loading = false,
  ...surfaceFieldsetProps
}) => {
  const { value: sectionIsVisible, set: setSectionIsVisible } =
    useSessionStorageValue(`${sectionTitle}IsVisible`, {
      defaultValue: false,
      initializeWithValue: false,
    });
  const intersectionRef = useRef(null);
  const intersection = useIntersectionObserver(intersectionRef, {
    threshold: [0, 0.1],
  });

  /** We need to determine whether this section is visible to the user
   * or not so that we can determine whether the name of the section should
   * be highlighted in blue in the sidebar of the Wizard
   *
   * the isIntersecting attribute refers to the intersection between
   * this section and the user's viewport
   */
  const isVisible = get(intersection, "isIntersecting") || false;

  useEffect(() => {
    if (sectionIsVisible !== isVisible) {
      setSectionIsVisible(isVisible);
    }
  }, [isVisible, sectionIsVisible, setSectionIsVisible]);

  /** Setup an id so the section can be jumped to via the sidebar, and setup a
   * ref to track whether the section is visible in the viewport */
  return (
    <span id={sectionTitle} ref={intersectionRef}>
      <LoadingOverlay active={loading}>
        <SurfaceFieldset title={sectionTitle} {...surfaceFieldsetProps}>
          {children}
        </SurfaceFieldset>
      </LoadingOverlay>
    </span>
  );
};
WizardFieldset.propTypes = {
  /** Title of the section */
  sectionTitle: PropTypes.string.isRequired,
  /** Whether the fieldset is loading, triggering our Loading Overlay to display */
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default WizardFieldset;
