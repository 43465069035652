import { Button, InputHidden } from "@heart/components";
import camelize from "camelize";
import { values } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import { LEGACY_OVERRIDABLE_SPEC } from "@lib/overridableHelpers";
import preventDefault from "@lib/preventDefault";

import OverridableRoleSpecInput from "./OverridableRoleSpecInput";

/**
 * This input appears in the new/edit form for Forms and Upload Types,
 * and it controls which types of user can waive the associated requirement
 */
const OverridableInput = ({
  name,
  initialOverrideSpec = {},
  onRoleSpecChanged,
  clearValues = false,
}) => {
  const [overrideSpec, setRoleSpec] = useState(initialOverrideSpec);

  const updateRoleSpec = role => roleSpec => {
    const updatedOverrideSpec = { ...overrideSpec, [role]: roleSpec };

    setRoleSpec(updatedOverrideSpec);

    if (onRoleSpecChanged) {
      onRoleSpecChanged(updatedOverrideSpec);
    }
  };

  const resetRoleSpec = preventDefault(() => {
    setRoleSpec(LEGACY_OVERRIDABLE_SPEC);

    if (onRoleSpecChanged) {
      onRoleSpecChanged(LEGACY_OVERRIDABLE_SPEC);
    }
  });

  return (
    <div>
      <InputHidden
        name={name}
        // when 'clearValues' is true, ignore inputs below and send {} to form
        value={JSON.stringify(clearValues ? {} : overrideSpec)}
      />
      {values(Binti.ProviderRoles)
        .map(camelize)
        .sort()
        .map(role => (
          <OverridableRoleSpecInput
            key={role}
            role={role}
            spec={overrideSpec[role]}
            updateRoleSpec={updateRoleSpec(role)}
          />
        ))}
      <div>
        <Button variant="secondary" onClick={resetRoleSpec}>
          Reset to defaults
        </Button>
      </div>
    </div>
  );
};

OverridableInput.propTypes = {
  name: PropTypes.string.isRequired,
  initialOverrideSpec: PropTypes.object,
  onRoleSpecChanged: PropTypes.func,
  clearValues: PropTypes.bool,
};

export default OverridableInput;
