import {
  ArrayDataTable,
  Button,
  Link,
  PageContainer,
  Surface,
} from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";
import Markdown from "react-markdown";

import AISurface from "../AISurface";
import styles from "../CcwisDemo.module.scss";

const CaseNotes = ({ caseNotes, childId, summary }) => (
  <Fragment>
    <AISurface>
      <Markdown className={styles.markdown}>{summary}</Markdown>
    </AISurface>
    <PageContainer fullWidth>
      <Surface title="Case Notes" hideTitle>
        <ArrayDataTable
          actions={
            <Button href={`/admin/children/${childId}/case_notes/new`}>
              New Case Note
            </Button>
          }
          columns={[
            {
              id: "author",
              columnName: { name: "Author", justify: "center" },
              cell: "author",
            },
            {
              id: "date",
              columnName: { name: "Case Note Date", justify: "center" },
              cell: "date",
            },
            {
              id: "family",
              columnName: { name: "Family", justify: "center" },
              cell: "family",
            },
            {
              id: "contactMethod",
              columnName: { name: "Contact Method", justify: "center" },
              cell: "contactMethod",
            },
            {
              id: "notes",
              columnName: { name: "Notes", justify: "center" },
              cell: "notes",
            },
            {
              id: "actions",
              columnName: { name: "Actions", justify: "center" },
              cell: ({ editLink, deleteLink }) => (
                <Fragment>
                  <Link href={editLink}>edit</Link>{" "}
                  <Link
                    href={deleteLink}
                    data-confirm="Are you sure?"
                    rel="nofollow"
                    data-method="delete"
                  >
                    delete
                  </Link>
                </Fragment>
              ),
            },
          ]}
          data={caseNotes}
        />
      </Surface>
    </PageContainer>
  </Fragment>
);

CaseNotes.propTypes = {
  caseNotes: PropTypes.arrayOf(PropTypes.object).isRequired,
  childId: PropTypes.number.isRequired,
  summary: PropTypes.string,
};

export default CaseNotes;
