import { escape } from "lodash";

// TODO remove all functions except toggleReferringAgency once Placement is
// separate from PlacementSearch
$(() => {
  const $trigger = $(".js-agency-placement-open-trigger");
  const $referringType = $(".js-referring-type");
  const $referringAgency = $(".js-referring-agency");
  const $toShowPlacement = $(".js-agency-placement");
  const $underlyingPlacementSelect = $toShowPlacement.find("select");
  const $privateAgencyInfo = $(".js-private-agency-info");
  const $toShowOtherExplanation = $(".js-agency-placement-other-explanation");
  const $submitLink = $(".js-toggle-title");
  const agencyPlacementOptions = {};

  const addSelectOptions = ($select, opts) => {
    const currentVal = $select.val();
    let str = "<option></option>";
    let i = 0;
    if (!$select[0]) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    $select[0].innerHTML = "";
    for (i; i < opts.length; i += 1) {
      str += `<option value='${opts[i].value}'>${escape(
        opts[i].label
      )}</option>`;
    }
    $select.append(str);
    $select.val(currentVal);
  };

  const toggleAgencyPlacement = () => {
    $.ajax({
      dataType: "json",
      url: "/admin/placements/placement_by_reason_closed",
      data: {
        reason_closed: $trigger.val(),
        agency_id: $toShowPlacement.data("agency"),
      },
      success: response => {
        let i = 0;
        for (i; i < response.results.length; i += 1) {
          agencyPlacementOptions[response.results[i].value] =
            response.results[i];
        }

        if (response.results.length > 0) {
          $toShowPlacement.show();
          addSelectOptions($underlyingPlacementSelect, response.results);
        } else {
          $toShowPlacement.hide();
          addSelectOptions($underlyingPlacementSelect, []);
        }
      },
    });

    togglePrivateAgencyFamilyInfo();
    toggleOtherExplanation();
  };

  const handlePlacementChange = () => {
    const val = $underlyingPlacementSelect.val();
    let message = "";
    if (!agencyPlacementOptions[val].approved) {
      message += "Warning: This family has not been approved yet ";
    }
    if (agencyPlacementOptions[val].availability === 0) {
      message += "Warning: This family has no availability";
    }
    $(".js-warning-notice").text(message);
  };

  const togglePrivateAgencyFamilyInfo = () => {
    if ($trigger.val() === "placed_with_ooc_family") {
      $privateAgencyInfo.show();
    } else if ($trigger.val() === "provider_not_in_binti") {
      $privateAgencyInfo.show();
    } else if (
      $trigger.val() === "placed_with_private_agency" &&
      $toShowPlacement.find("select").val() !== undefined &&
      $toShowPlacement.find("select").val() !== ""
    ) {
      $privateAgencyInfo.show();
    } else {
      $privateAgencyInfo.hide();
    }
  };

  const editCloseOtherExplanationTitle = title => {
    const element = $toShowOtherExplanation.find("label");
    const label = element.text().replace(/[\w|\s]+/, title); // to preserve the '*' if the field is required
    element.text(label);
  };

  const toggleOtherExplanation = () => {
    const toggleOnOptions = {
      closed_other: "Other Closure Reason Explanation",
      hospitalization: "Hospital Name",
      juvenile_detention_center: "Juvenile Detention Center Name",
      silp: "Independent Living Information",
    };
    if (toggleOnOptions[$trigger.val()]) {
      editCloseOtherExplanationTitle(toggleOnOptions[$trigger.val()]);
      $toShowOtherExplanation.show();
    } else {
      $toShowOtherExplanation.hide();
    }
  };

  const toggleSubmitTitle = () => {
    const submitBtn = $submitLink.find("input");
    if ($trigger.val() === "placement_found") {
      submitBtn.val("Save and Continue");
    } else {
      submitBtn.val("Submit");
    }
  };

  const toggleIsCloseDateRequiredText = () => {
    const labelText = "Search Completed Date";
    const label = $(`label:contains(${labelText})`);
    if ($trigger.val() === "placement_found") {
      label.text(`${labelText} *`);
    } else {
      label.text(labelText);
    }
  };

  const toggleReferringAgency = () => {
    const $select = $referringAgency.find("select");
    const val = $referringType.val();
    if (val === "public_agency") {
      $referringAgency.show();
      addSelectOptions($select, Binti.public_referrers);
    } else if (val === "private_agency") {
      $referringAgency.show();
      addSelectOptions($select, Binti.private_referrers);
    } else {
      $referringAgency.hide();
      addSelectOptions($select, []);
    }
  };

  if ($trigger.length > 0) {
    toggleAgencyPlacement();
  }
  toggleReferringAgency();
  toggleSubmitTitle();
  toggleIsCloseDateRequiredText();
  $trigger.on("change", toggleAgencyPlacement);
  $trigger.on("change", toggleSubmitTitle);
  $trigger.on("change", toggleIsCloseDateRequiredText);
  $underlyingPlacementSelect.on("change", handlePlacementChange);
  $toShowPlacement.find("select").on("change", togglePrivateAgencyFamilyInfo);
  $referringType.on("change", toggleReferringAgency);
});
