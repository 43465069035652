import { useEffect, useState } from "react";

import { isBrowser, on, off } from "./utils";

/** This hook was copied over from [react-use](https://github.com/streamich/react-use)
 * as the library is no longer being maintained. We're moving to `react-hookz` as the
 * supported alternative, but location related hooks will not be migrated over so we're
 * pulling the hooks we need directly into our codebase as an alternative
 *
 * Their [license](https://github.com/streamich/react-use/blob/325f5bd69904346788ea981ec18bfc7397c611df/LICENSE#L1-L24),
 * allows us to copy, modify, publish, use, compile, sell, or distribute this
 * software, either in source code form or as a compiled binary, for any purpose,
 * commercial or non-commercial, and by any means.
 */
const patchHistoryMethod = method => {
  const { history } = window;
  const original = history[method];

  history[method] = function (state) {
    // eslint-disable-next-line prefer-rest-params
    const result = original.apply(this, arguments);
    const event = new Event(method.toLowerCase());

    event.state = state;

    window.dispatchEvent(event);

    return result;
  };
};

if (isBrowser) {
  patchHistoryMethod("pushState");
  patchHistoryMethod("replaceState");
}

const useLocationServer = () => ({
  trigger: "load",
  length: 1,
});

const buildState = trigger => {
  const { state, length } = window.history;

  const {
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search,
  } = window.location;

  return {
    trigger,
    state,
    length,
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search,
  };
};

const useLocationBrowser = () => {
  const [state, setState] = useState(buildState("load"));

  useEffect(() => {
    const onPopstate = () => setState(buildState("popstate"));
    const onPushstate = () => setState(buildState("pushstate"));
    const onReplacestate = () => setState(buildState("replacestate"));

    on(window, "popstate", onPopstate);
    on(window, "pushstate", onPushstate);
    on(window, "replacestate", onReplacestate);

    return () => {
      off(window, "popstate", onPopstate);
      off(window, "pushstate", onPushstate);
      off(window, "replacestate", onReplacestate);
    };
  }, []);

  return state;
};

const hasEventConstructor = typeof Event === "function";

export default isBrowser && hasEventConstructor
  ? useLocationBrowser
  : useLocationServer;
