import { useQuery } from "@apollo/client";
import { Link, Surface } from "@heart/components";
import { Spinner } from "@heart/components/icon/Icon";

import ErrorBanner from "@components/shared/banner/ErrorBanner";

import Role from "@graphql/queries/Role.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const UsersInRoleTable = ({ roleId }) => {
  const { data, loading, error } = useQuery(Role, {
    variables: { roleId },
  });

  if (loading) return <Spinner />;
  if (error) return <ErrorBanner message={error.message} />;

  const {
    role: { usersInRole },
  } = data;

  return (
    <Surface title={I18n.t("javascript.components.permissions.users_in_role")}>
      {usersInRole.map(userInfo => (
        <div key={userInfo.id}>
          <Link href={`/admin/agency_workers/${userInfo.id}`}>
            {userInfo.name}
          </Link>
        </div>
      ))}
    </Surface>
  );
};

UsersInRoleTable.propTypes = {
  roleId: BintiPropTypes.ID,
};

export default UsersInRoleTable;
