import PropTypes from "prop-types";

import styles from "../MobileStyling.module.scss";

/* This is used in the SupportContacts.js component,
and is used for including inline HTML in our technical support
table on our Family Portal Agency Contacts page  */
const SupportCell = ({ header = "", value = "" }) => (
  <div className={styles.cell}>
    <div className={styles.smallHeader}>{header}</div>
    <div className={styles.value}>{value}</div>
  </div>
);

SupportCell.propTypes = {
  header: PropTypes.string,
  value: PropTypes.string,
};

export default SupportCell;
