import { InputFilterable } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

/** Allow an applicant to select their primary and additional languages.
 *   Masquerade as Applicant > "Personal Information" sidebar
 */
const Languages = ({
  primaryLanguage,
  additionalLanguages,
  languageOptions,
  primLangInputName,
  additionalLangsInputName,
}) => {
  const languagesT = t => I18n.t(`activerecord.enums.common.languages.${t}`);
  const [primLang, setPrimLanguage] = useState({
    label: languagesT(primaryLanguage),
    value: primaryLanguage,
  });

  const [addtlLangs, setAddtlLangs] = useState(
    additionalLanguages.map(addtlLang => ({
      label: languagesT(addtlLang),
      value: addtlLang,
    }))
  );

  return (
    <div className="contains-inputs">
      <InputFilterable
        required
        label={I18n.t("activerecord.enums.common.primary_language")}
        onChange={setPrimLanguage}
        disabled={false}
        values={languageOptions.map(lang => ({
          label: languagesT(lang),
          value: lang,
        }))}
        value={primLang}
        name={primLangInputName}
      />

      <InputFilterable
        label={I18n.t("activerecord.enums.common.additional_languages")}
        isMulti
        onChange={langs => setAddtlLangs(langs)}
        disabled={false}
        values={languageOptions.map(lang => ({
          label: languagesT(lang),
          value: lang,
        }))}
        value={addtlLangs}
        name={additionalLangsInputName}
        description={I18n.t("activerecord.enums.common.select_languages")}
      />
    </div>
  );
};

Languages.propTypes = {
  primaryLanguage: PropTypes.string,
  additionalLanguages: PropTypes.array,
  languageOptions: PropTypes.array,
  primLangInputName: PropTypes.string.isRequired,
  additionalLangsInputName: PropTypes.string.isRequired,
};

export default Languages;
