import { InputFilterable } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useState } from "react";

import AddForm from "./AddForm";
import styles from "./FormMigration.module.scss";
import RemoveForm from "./RemoveForm";
import ReplaceForm from "./ReplaceForm";

const OPERATIONS = Object.freeze([
  { value: "add_form", label: "Add form", OpComponent: AddForm },
  { value: "remove_form", label: "Remove form", OpComponent: RemoveForm },
  { value: "replace_form", label: "Replace form", OpComponent: ReplaceForm },
]);

const OPERATION_COMPONENTS = Object.freeze({
  add_form: AddForm,
  remove_form: RemoveForm,
  replace_form: ReplaceForm,
});

const FormMigration = ({ forms }) => {
  const [operation, setOperation] = useState("add_form");
  const changeOperation = ({ value }) => setOperation(value);
  const OpComponent = OPERATION_COMPONENTS[operation];

  return (
    <div className={classNames(styles.defeatSidebar, "contains-inputs")}>
      <InputFilterable
        label="Operation"
        name="operation"
        onChange={changeOperation}
        defaultValue={OPERATIONS[0]}
        options={OPERATIONS}
      />
      <OpComponent forms={forms} />
    </div>
  );
};

FormMigration.propTypes = {
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FormMigration;
