import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Flex, LoadingOverlay } from "@heart/components";
import { useList } from "@react-hookz/web";
import PropTypes from "prop-types";
import { useEffect } from "react";

import PhoneNumberFragment from "@graphql/fragments/PhoneNumber.graphql";
import PhoneNumbersByUser from "@graphql/queries/PhoneNumbersByUser";

import ItemListTable from "../reusable_ui/ItemListTable";
import DeletePhoneNumber from "./DeletePhoneNumber";
import PhoneNumberForm from "./PhoneNumberForm";

const UpdatePhoneNumbers = gql`
  ${PhoneNumberFragment}
  mutation UpdatePhoneNumbers($input: UpdatePhoneNumbersInput!) {
    updatePhoneNumbers(input: $input) {
      phoneNumbers {
        ...PhoneNumber
      }
    }
  }
`;

const emptyArray = [];

/**
 * EditPhoneNumbers component allows editing phone numbers for a specific person.
 *
 * @param {string} personType - The type of the person (e.g., user, admin).
 * @param {string} personId - The ID of the person.
 * @param {Function} handleSuccess - Callback function to handle success.
 */
const EditPhoneNumbers = ({ personType, personId, handleSuccess }) => {
  const { data, loading } = useQuery(PhoneNumbersByUser, {
    variables: { personType, personId },
  });

  const [phoneNumbers, { updateAt, set }] = useList(
    data?.phoneNumbers || emptyArray
  );

  useEffect(() => {
    set(data?.phoneNumbers || emptyArray);
  }, [data, set]);

  const [updatePhoneNumbers] = useMutation(UpdatePhoneNumbers, {
    onCompleted: handleSuccess,
  });

  const onSubmit = () => {
    if (phoneNumbers.length > 0) {
      updatePhoneNumbers({
        variables: { input: { phoneNumbers } },
      });
    } else {
      handleSuccess();
    }
  };

  return (
    <LoadingOverlay active={loading}>
      <Flex column align="end" data-testid="phone-inline-edit">
        <ItemListTable
          headers={[
            "Phone Category",
            "Phone Number",
            "Change Primary Status",
            "Inactive Status",
          ]}
        >
          {phoneNumbers.map((phoneNumber, index) => (
            <tr key={phoneNumber.id} data-testid="phone-row">
              <PhoneNumberForm
                key={phoneNumber.id}
                formState={phoneNumber}
                setFormAttribute={attr => value =>
                  updateAt(index, { ...phoneNumber, [attr]: value })}
              />
              <td>
                <div className="space-above-4">
                  <DeletePhoneNumber
                    id={phoneNumber.id}
                    personId={personId}
                    personType={personType}
                  />
                </div>
              </td>
            </tr>
          ))}
        </ItemListTable>
        <Button onClick={onSubmit}>Save Changes</Button>
      </Flex>
    </LoadingOverlay>
  );
};

EditPhoneNumbers.propTypes = {
  personType: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

export default EditPhoneNumbers;
