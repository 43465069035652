import { gql, useQuery } from "@apollo/client";
import { DetailsTable } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React from "react";

import Spinner from "@components/Spinner";

import AppealQuery from "@graphql/queries/Appeal.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const ApplicationQuery = gql`
  query Application($id: ID!) {
    application(id: $id) {
      id
      statusDate
    }
  }
`;

const AppealDetails = ({ appealId, applicationId, applicationType }) => {
  const { data: appealData, loading: appealLoading } = useQuery(AppealQuery, {
    variables: { appealId },
  });
  const { data: applicationData, loading: applicationLoading } = useQuery(
    ApplicationQuery,
    {
      variables: { id: applicationId },
    }
  );

  if (appealLoading || applicationLoading) {
    return <Spinner />;
  }

  const { appeal } = appealData || {};
  const { application } = applicationData || {};

  return (
    <DetailsTable
      details={[
        {
          label: I18n.t("javascript.components.appeals.application_type"),
          value: applicationType,
        },
        {
          label: I18n.t(
            "javascript.components.appeals.application_denial_date"
          ),
          value: application.statusDate,
        },
        {
          label: I18n.t("javascript.components.appeals.appeal_location"),
          value: appeal.appealLocation
            ? I18n.t(
                `activerecord.enums.application_status_change.appeal_locations.${appeal.appealLocation}`
              )
            : "",
        },
        {
          label: I18n.t("javascript.components.appeals.agency_representative"),
          value: appeal.representative
            ? I18n.t(
                `javascript.components.appeal_representative.${appeal.representative}`
              )
            : "",
        },
        {
          label: I18n.t("javascript.components.appeals.date_appeal_started"),
          value: appeal.appealStartedDate,
        },
        {
          label: I18n.t(
            "javascript.components.appeals.date_approving_agency_notified"
          ),
          value: appeal.approvingAgencyNotifiedDate,
        },
        {
          label: I18n.t(
            "javascript.components.appeals.date_appeals_body_notified"
          ),
          value: appeal.appealsBodyNotifiedDate,
        },
        {
          label: I18n.t(
            "javascript.components.appeals.date_docs_sent_to_appeals_body"
          ),
          value: appeal.docsSentToAppealsBodyDate,
        },
      ]}
    />
  );
};

AppealDetails.propTypes = {
  appealId: BintiPropTypes.ID,
  applicationId: BintiPropTypes.ID,
  representatives: PropTypes.array,
  appealLocations: PropTypes.array,
  applicationType: PropTypes.string,
  showDeleteIcon: PropTypes.bool,
};

export default AppealDetails;
