import { Link } from "@heart/components";
import I18n from "i18n-js";
import { isEmpty, remove } from "lodash";

import flattenGraphqlErrorMessages from "../flattenGraphqlErrorMessages";
import isPlaceholderEmail from "../isPlaceholderEmail";

const isBlankFormId = id => id.startsWith("blank-");

export default async ({
  applicationId,
  createApplicationReference,
  updateApplicationReference,
  messageDisplay: { displayError, displayInfo, displayNotice },
  referenceOrFormId,
  refetch,
  referenceParams,
  referredUserAgencyProfileId,
  requiredRemainingIds,
  setEditingForThisReference,
  setlocalErrorMessages = () => {},
}) => {
  const setAlertAfterUpdate = ({ email }) => {
    if (isEmpty(email) || isPlaceholderEmail({ email })) {
      displayInfo(I18n.t("views.applications.application_reference.no_email"));
    } else if (isBlankFormId(referenceOrFormId)) {
      // New reference
      displayNotice(
        I18n.t(
          "views.reference_requests.create.reference_or_other_adult_with_email",
          { email }
        )
      );
    } else {
      // Editing existing reference
      displayNotice(
        I18n.t("views.applications.application_reference.updated_successfully")
      );
    }
  };

  const commonMutationVariables = {
    firstName: referenceParams.firstName,
    middleName: referenceParams.middleName,
    lastName: referenceParams.lastName,
    suffix: referenceParams.suffix,
    ...(!isEmpty(referenceParams.email) && { email: referenceParams.email }),
    ...(!isEmpty(referenceParams.phoneNumber) && {
      phoneNumber: referenceParams.phoneNumber,
    }),
  };

  const onError = errors => {
    setlocalErrorMessages([
      <Link key={"edit_reference_error"} href="#ref_error_banner">
        {I18n.t("javascript.components.other_adults.scroll_up_for_errors")}
      </Link>,
    ]);
    displayError(flattenGraphqlErrorMessages(errors));
  };

  if (isBlankFormId(referenceOrFormId)) {
    // Adding new reference
    const variables = {
      ...commonMutationVariables,
      applicationId: applicationId,
      referredUserAgencyProfileId,
    };
    const {
      data: {
        createApplicationReference: { errors },
      },
    } = await createApplicationReference({ variables });
    if (!isEmpty(errors)) {
      onError(errors);
    } else {
      remove(requiredRemainingIds, id => id === referenceOrFormId);
      refetch();
      setAlertAfterUpdate({
        email: referenceParams.email,
      });
      setEditingForThisReference(false);
    }
  } else {
    // Editing existing reference
    const variables = {
      ...commonMutationVariables,
      applicationReferenceId: referenceOrFormId,
    };
    const {
      data: {
        updateApplicationReference: { errors },
      },
    } = await updateApplicationReference({ variables });
    if (!isEmpty(errors)) {
      onError(errors);
    } else {
      refetch();
      setAlertAfterUpdate({
        email: referenceParams.email,
      });
      setEditingForThisReference(false);
    }
  }
};
