import { Button, Flex } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";

import { setBase64SearchParams } from "@lib/base64SearchParams";
import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";

import styles from "./Toggle.module.scss";

/** A toggle component for switching between two different views
 *
 * This component will change the base64 encoded `view` param in the URL, which can
 * then be used by other components to determine what to render
 *
 * Note: this is **NOT** intended to be used as a form toggle element, but rather
 * as a way to toggle between two different views for a set of data. For example,
 * toggling between a table and a chart
 */
const Toggle = ({
  LeftIcon,
  RightIcon,
  iconOnly = true,
  leftDescription,
  rightDescription,
  queryParam = "view",
  leftView,
  rightView,
  "data-testid": testId,
}) => {
  const view = useBase64SearchParam(queryParam);

  return (
    <Flex data-heart-component="toggle" data-testid={testId} gap="0">
      <Button
        description={iconOnly ? leftDescription : undefined}
        icon={LeftIcon}
        onClick={() =>
          setBase64SearchParams([{ attribute: queryParam, value: leftView }])
        }
        /** We're overriding the button styles, but we still get all the
         * a11y considerations by using a Button as our base
         */
        className={classNames(
          styles.flattenRight,
          styles.toggleBase,
          view !== rightView ? styles.active : styles.inactive
        )}
      >
        {iconOnly ? null : leftDescription}
      </Button>
      <Button
        description={iconOnly ? rightDescription : undefined}
        icon={RightIcon}
        onClick={() =>
          setBase64SearchParams([{ attribute: queryParam, value: rightView }])
        }
        className={classNames(
          styles.flattenLeft,
          styles.toggleBase,
          view === rightView ? styles.active : styles.inactive
        )}
      >
        {iconOnly ? null : rightDescription}
      </Button>
    </Flex>
  );
};
Toggle.propTypes = {
  /** One of the components from `import { Icons } from "@heart/components"`.
   * The icon to display on the left side of the toggle
   */
  LeftIcon: PropTypes.object,
  /** One of the components from `import { Icons } from "@heart/components"`.
   * The icon to display on the right side of the toggle
   */
  RightIcon: PropTypes.object,
  /** When true, only displays the icons for the toggle buttons. The provided
   * descriptions will be used for a11y descriptions.
   */
  iconOnly: PropTypes.bool,
  /** For icon only toggles, used to indicate to screen readers what the purpose
   * of the left button is and puts useful hover text on the button. For non-icon
   * only buttons, this text will be displayed in the toggle
   *
   * A combination of HTML `title` and `aria-label` attributes - for further reading:
   *   * https://silktide.com/blog/i-thought-title-text-improved-accessibility-i-was-wrong/
   *   * https://dev.opera.com/articles/ux-accessibility-aria-label/#accessible-name-calculation
   */
  leftDescription: PropTypes.string.isRequired,
  /** For icon only toggles, used to indicate to screen readers what the purpose
   * of the right button is and puts useful hover text on the button. For non-icon
   * only buttons, this text will be displayed in the toggle
   */
  rightDescription: PropTypes.string.isRequired,
  /** What the base64 encoded `view` param should be set to when the left button
   * is clicked. This param can then trigger other behavior in the components it is
   * intended to control
   */
  leftView: PropTypes.string.isRequired,
  /** What the base64 encoded `view` param should be set to when the right button
   * is clicked. This param can then trigger other behavior in the components it is
   * intended to control
   */
  rightView: PropTypes.string.isRequired,
  /** The query param that will be controlled by the toggle. Defaults to "view", this
   * prop should only be changed if we're using two toggles within one page as they'll
   * need to controll different query parameters
   */
  queryParam: PropTypes.string,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};

export default Toggle;
