import { Button, Icons } from "@heart/components";
import bintiLogo from "logo-white.png";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import T from "@components/T";

import styles from "../BintiSign.module.scss";

/**
 * Header of the BintiSign modal with controls to close the window and go
 * to the next signature location, if applicable.
 */
const BintiSignHeader = forwardRef(
  ({ setIsModalOpen, scrollTo, nextLocationRef, allLocationsSigned }, ref) => (
    <div className={styles.header} ref={ref}>
      <div className={styles.headerCloseAndLogo}>
        <Icons.Times
          onClick={() => setIsModalOpen(false)}
          description={I18n.t("views.common.close")}
          className={styles.close}
        />
        <img
          className={styles.logo}
          src={bintiLogo}
          aria-hidden="true"
          alt=""
        />
      </div>
      {!allLocationsSigned && nextLocationRef && (
        <Button
          className={styles.headerAction}
          onClick={() => {
            scrollTo(nextLocationRef);
          }}
        >
          <T t="bintisign.bintisign_header.next" /> &gt;
        </Button>
      )}
    </div>
  )
);

forwardRef.displayName = "BintiSignHeader";

BintiSignHeader.propTypes = {
  nextLocationRef: PropTypes.object,
  scrollTo: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  allLocationsSigned: PropTypes.bool.isRequired,
};

export default BintiSignHeader;
