import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@heart/components";
import Flex, { FlexItem } from "@heart/components/layout/Flex";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import DeletePermissionCondition from "@graphql/mutations/DeletePermissionCondition";
import * as ResourceActionQuery from "@graphql/queries/ResourceAction.graphql";

import PermissionsPropTypes from "./PermissionsPropTypes";

const ConditionSummary = ({ queryVariables, viewOnly }) => {
  const { loading, error, data } = useQuery(ResourceActionQuery, {
    variables: queryVariables,
  });
  const [deletePermissionCondition] = useMutation(DeletePermissionCondition);

  if (loading || error) {
    return null;
  }

  const conditions = data.resourceAction.permissionRules.flatMap(
    rule => rule.conditions
  );

  const onDeletePermissionCondition = id => {
    deletePermissionCondition({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: ResourceActionQuery,
          variables: queryVariables,
        },
      ],
    });
  };

  return (
    <div>
      {conditions.map(condition => (
        <Flex key={condition.id} row>
          <FlexItem>{condition.summary}</FlexItem>
          <If condition={!viewOnly}>
            <Button onClick={() => onDeletePermissionCondition(condition.id)}>
              {I18n.t("javascript.components.permissions.delete_condition")}
            </Button>
          </If>
        </Flex>
      ))}
    </div>
  );
};

ConditionSummary.propTypes = {
  queryVariables: PermissionsPropTypes.queryVariables,
  viewOnly: PropTypes.bool,
};

export default ConditionSummary;
