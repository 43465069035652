// TODO ENG-12557 investigate why we get an error when we import this the conventional way,
// using the Heart index file:
import ContainsTrustedHTML from "@heart/components/contains_trusted_html/ContainsTrustedHTML";
import I18n from "i18n-js";
import PropTypes from "prop-types";

/**
 * In React, you should use the T component to inject user-visible text.
 * It automatically prefixes the key with `javascript.components`, which
 * is where all the React components should be anyway.
 *
 * E.g. if your key is `javascript.components.my_cool_component.some_message`,
 * you could inject a translation like so:
 * `<T t="my_cool_component.some_message" />`
 */
const T = ({ t, escapeJavascriptRoot = false, ...values }) => {
  // if we ever decide to remove the `javascript.components` prefix, the setup in
  // jest-setup.js will also need to be updated
  const translated = escapeJavascriptRoot
    ? I18n.t(t, values)
    : I18n.t(`javascript.components.${t}`, values);
  if (t.endsWith("_html")) {
    return (
      <ContainsTrustedHTML
        trustedSource="translation YML values"
        html={translated}
      />
    );
  }

  return translated;
};

T.propTypes = {
  /** Translation key from en|es|xx.yml */
  t: PropTypes.string.isRequired,
  /** Escape from the `javascript.components` namespace and provide a fully-qualified
   * translation key.
   *
   * **Discouraged:** Please use `javascript.components` keys wherever possible.
   * If there is a translation key that exists elsewhere you want to use, copy it
   * into `javascript.components`.  This option exists to rapidly bring legacy code
   * into `<T>` and will eventually be removed.
   */
  escapeJavascriptRoot: PropTypes.bool,
};

export default T;

/**
 * If you have many strings to translate in the same React component,
 * there's going to be lots of repetition when specifying your component
 * translation keys.  Use `translationWithRoot(prefix)` to get a version of the `<T />`
 * component which is pre-bound to your component's translation prefix:
 *
 * This function also returns `t()` - a similarly pre-bound version of `I18n.t`,
 * rather than a component for cases where a component will not work (usually
 * when passing into configuration data structures or plugging into external
 * packages - please prefer the `T` export where possible!)
 *
 * ```js
 * import { translationWithRoot } from "@components/T";
 * const { t, T } = translationWithRoot("some.component");;
 * ```
 */
export const translationWithRoot = (prefix, { escapeJavascriptRoot } = {}) => {
  // This is a higher-order component and the linter doesn't do very well with those:
  const PrefixedT = ({ t, ...props }) => (
    <T
      t={`${prefix}.${t}`}
      escapeJavascriptRoot={Boolean(escapeJavascriptRoot)}
      {...props}
    />
  );
  PrefixedT.propTypes = { t: PropTypes.string.isRequired };

  const prefixedI18n = (name, values) =>
    escapeJavascriptRoot
      ? I18n.t(`${prefix}.${name}`, values)
      : I18n.t(`javascript.components.${prefix}.${name}`, values);

  return { T: PrefixedT, t: prefixedI18n };
};
