import { useQuery } from "@apollo/client";
import { InputCheckbox, InputHidden } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import AgencyWorker from "@graphql/queries/AgencyWorker.graphql";

const name =
  "agency_worker[agency_admin_assignment_attributes][explicit_can_override_approval]";

/**
 * A GraphQL-powered "Explicitly allow this worker to approve applications" checkbox.
 *   New Agency Worker > Role "caseworker" > Set Agency > Set Role to "Agency Worker"
 */
const CanOverrideApprovalCheckbox = ({
  workerId,
  selectedRoleId,
  explicitCanOverrideApprovalExisting,
  explicitCanOverrideApprovalRoles,
  canGrantOverrideApproval,
}) => {
  const { data } = useQuery(AgencyWorker, {
    variables: { workerId },
    skip: !workerId,
  });

  const { id: roleId } = data?.agencyWorker?.roleForPermissions || {};
  const [explicitCanOverrideApproval, setExplicitCanOverrideApproval] =
    useState(explicitCanOverrideApprovalExisting);

  if (
    !explicitCanOverrideApprovalRoles.includes(
      Number(selectedRoleId || roleId)
    ) ||
    !canGrantOverrideApproval
  ) {
    return null;
  }

  return (
    <li>
      <InputHidden name={name} value="false" />
      <InputCheckbox
        value={explicitCanOverrideApproval}
        onChange={setExplicitCanOverrideApproval}
        name={name}
        htmlValue="true"
        label={I18n.t(
          "activerecord.attributes.agency_admin_assignment.explicit_can_override_approval"
        )}
      />
    </li>
  );
};

CanOverrideApprovalCheckbox.propTypes = {
  workerId: PropTypes.number,
  selectedRoleId: PropTypes.string,
  explicitCanOverrideApprovalExisting: PropTypes.bool.isRequired,
  explicitCanOverrideApprovalRoles: PropTypes.array.isRequired,
  canGrantOverrideApproval: PropTypes.bool.isRequired,
};

export default CanOverrideApprovalCheckbox;
