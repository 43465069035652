import { InputText } from "@heart/components";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { translationWithRoot } from "@components/T";

import {
  isValidPostalCode,
  postcodeValidatorExistsForCountry,
} from "@lib/postalCodes";

import { inputCommonPropTypes, textualInputPropTypes } from "./common";

const { t } = translationWithRoot("heart.components.inputs.input_postal_code");

/** An input for postal code built atop InputText. It validates the postal
 * code based on the countryCode prop passed in. It defaults to US validation.
 * The validation occurs on each keystroke and if the input text is invalid
 * an error message will appear. It will disappear when the text is considered valid.
 * Additionally, the invalid input will fail HTML validation and prevent submission
 * of forms when you use `<Button type="submit">` and `<form onSubmit={}>`.
 * A validation tooltip will appear on the input after attempting to submit the form.
 */

const InputPostalCode = props => {
  const { value, countryCode = "US", onChange, error } = props;

  const [postalCode, setPostalCode] = useState(value);
  useEffect(() => setPostalCode(value), [value]);

  const isInvalidPostalCode =
    postcodeValidatorExistsForCountry(countryCode) &&
    postalCode &&
    !isValidPostalCode(postalCode, countryCode);

  const changeHandler = val => {
    setPostalCode(val);
    if (onChange) onChange(val);
  };

  const validate = e => {
    if (isInvalidPostalCode) {
      // Prevents user from being able to submit form with invalid field
      e.target.setCustomValidity(t("enter_valid_postal_code"));
    } else {
      e.target.setCustomValidity("");
    }
  };

  return (
    <InputText
      {...props}
      type="text"
      value={postalCode}
      onChange={changeHandler}
      onBlur={validate}
      error={isInvalidPostalCode ? t("enter_valid_postal_code") : error}
    />
  );
};

InputPostalCode.propTypes = {
  /** Country code (e.g. "US", "MX") to use for validation. Defaults to "US" */
  countryCode: PropTypes.string,
  ...inputCommonPropTypes,
  ...textualInputPropTypes,
};

export default InputPostalCode;
