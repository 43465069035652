import PropTypes from "prop-types";

/** When a component's child has a fixed width (or has content that can't get any smaller),
 * and the parent is smaller than that width, by default CSS behavior is to let the child
 * content spill out of the container, overflowing into the surrounding area.
 *
 * This is a reasonable default (`overflow: visible`)- sometimes, like when we're using
 * absolute positioning to "float" something above the content, overflow is what we want!
 *
 * We should always be trying to use responsive design to keep children inside their
 * containers at various viewports.  But other times, despite our best efforts at responsive
 * design, some things are Just Too Wide™ and we need to introduce a scrollbar.
 *
 * This utility component is a wrapper for such occasions.  Use it carefully and don't put
 * anything inside that's absolutely-positioned such as `InputDate` or `InputFilterable`
 * as they will introduce a vertical scrollbar.
 */
const ScrollWhenTooWide = ({ children }) => (
  <div style={{ overflowX: "auto" }}>{children}</div>
);

ScrollWhenTooWide.propTypes = {
  /** Contents that will scroll if they're wider than their container */
  children: PropTypes.node,
};
export default ScrollWhenTooWide;
