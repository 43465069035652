import { gql, useQuery } from "@apollo/client";
import {
  Flex,
  InputCheckbox,
  InputDropdown,
  InputText,
} from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import BintiPropTypes from "@lib/BintiPropTypes";

import RRuleInput from "./rrule/RRuleInput";

/**
 * Allows you to configure the schedule for a requirement template.
 * Case Management Setup > Schedules > Edit > "Scheduled Requirement Templates"
 */
const ScheduleSpecificationForm = ({
  agencyId,
  templateType,
  formState,
  setFormAttribute,
}) => {
  const { data } = useQuery(
    gql`
      query ScheduleFormData($agencyId: ID!, $templateType: String!) {
        scheduleFormData(agencyId: $agencyId, templateType: $templateType) {
          startDateEventOptions {
            label
            value
          }
        }
      }
    `,
    {
      variables: { agencyId, templateType },
    }
  );

  const { startDateEventOptions } = data?.scheduleFormData || {
    startDateEventOptions: [],
  };

  const [showDelayStartDateFields, updateShowDelayStartDateFields] = useState(
    Boolean(formState?.startDateDelayNumber)
  );

  return (
    <Fragment>
      <InputDropdown
        label="Schedule Start Date Event"
        values={startDateEventOptions}
        value={formState?.start_date_event}
        onChange={setFormAttribute("start_date_event")}
      />
      <InputCheckbox
        value={Boolean(showDelayStartDateFields)}
        onChange={() =>
          updateShowDelayStartDateFields(!showDelayStartDateFields)
        }
        label="Delay Schedule Start Date"
      />
      <If condition={showDelayStartDateFields}>
        <Flex>
          <InputText
            type="number"
            label="Start date delay number"
            hideLabel
            value={formState?.start_date_delay_number}
            onChange={setFormAttribute("start_date_delay_number")}
          />
          <InputDropdown
            label="Start date delay unit (e.g. days, weeks, months)"
            hideLabel
            value={formState?.start_date_delay_unit}
            onChange={setFormAttribute("start_date_delay_unit")}
            values={[
              [
                I18n.t(
                  "admin.requirement_templates.start_date_delay_units.days"
                ),
                "days",
              ],
              [
                I18n.t(
                  "admin.requirement_templates.start_date_delay_units.weeks"
                ),
                "weeks",
              ],
              [
                I18n.t(
                  "admin.requirement_templates.start_date_delay_units.months"
                ),
                "months",
              ],
              [
                I18n.t(
                  "admin.requirement_templates.start_date_delay_units.years"
                ),
                "years",
              ],
            ]}
          />
        </Flex>
      </If>
      <RRuleInput
        rruleString={formState?.rrule}
        onChange={setFormAttribute("rrule")}
      />
    </Fragment>
  );
};

ScheduleSpecificationForm.propTypes = {
  id: BintiPropTypes.ID,
  agencyId: BintiPropTypes.ID,
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
  templateType: PropTypes.string,
};

export default ScheduleSpecificationForm;
