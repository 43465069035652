import { useQuery, useMutation } from "@apollo/client";
import { Modal, Flex, Text, Icons, Link } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { translationWithRoot } from "@components/T";
import RoleDropdown from "@components/shared/RoleDropdown";

import BulkReassignAgencyRoles from "@graphql/mutations/BulkReassignAgencyRoles";
import CustomRoles from "@graphql/queries/CustomRoles";
import UsersInRole from "@graphql/queries/UsersInRole.graphql";

import RoleAgencyWorkersLink from "../RoleAgencyWorkersLink";

const { T } = translationWithRoot("permissions.bulk_reassign_modal");

const StepOne = ({ fromRole, agencyId, onSubmit, onClose }) => {
  const [toRoleId, setToRoleId] = useState();

  const { data: usersData, loading: workersLoading } = useQuery(UsersInRole, {
    variables: { roleId: fromRole.id },
  });

  const [reassignWorkers, { loading: reassignInProgress }] = useMutation(
    BulkReassignAgencyRoles,
    {
      onCompleted: onSubmit,
      refetchQueries: [{ query: CustomRoles, variables: { agencyId } }],
    }
  );

  const { usersInRole } = usersData?.role || {};

  if (workersLoading) {
    return <Icons.Spinner />;
  }

  return (
    <Modal
      hidden={false}
      title={
        <Flex justify="space-between" gap="300">
          <div>
            <T t="title" /> {fromRole.name}
          </div>
          <Text color="neutral-200">
            <T t="step_one.sub_header" />
          </Text>
        </Flex>
      }
      cancelText={I18n.t("views.common.cancel")}
      submitText={<T t="step_one.submit" />}
      onSubmit={() =>
        reassignWorkers({
          variables: { fromRoleId: fromRole.id, toRoleId },
        })
      }
      onCancel={onClose}
      submitting={reassignInProgress}
      data-testid="bulk-reassign-modal-step-1"
    >
      <Flex column gap="300" justify="space-between">
        <p>
          <T t="step_one.body.part_1" />{" "}
          <RoleAgencyWorkersLink
            id={fromRole.id}
            numUsersInRole={usersInRole.length}
          />{" "}
          <T t="step_one.body.part_2" />
        </p>
        <div>
          {usersInRole.map(worker => (
            <div key={worker.id}>
              <Link href={`/admin/agency_workers/${worker.id}`}>
                {worker.name}
              </Link>
            </div>
          ))}
        </div>
        <Flex justify="space-between" gap="300" align="center">
          <span>{fromRole.name}</span>
          <Icons.ArrowRight />
          <RoleDropdown
            hideTitle
            label={<T t="step_one.to_role" />}
            value={toRoleId}
            agencyId={agencyId}
            onChange={setToRoleId}
            excludedRoleId={fromRole.id}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

StepOne.propTypes = {
  fromRole: PropTypes.object,
  onSubmit: PropTypes.func,
  agencyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
};

export default StepOne;
