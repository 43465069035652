import { Surface, Icons } from "@heart/components";
import PropTypes from "prop-types";
import React, { useState, Fragment } from "react";

import BintiPropTypes from "@lib/BintiPropTypes";

import DeleteHearingSessionModal from "./DeleteHearingSessionModal";
import EditHearingSession from "./EditHearingSession";
import ViewHearingSession from "./ViewHearingSession";

const HearingSession = ({ id, sessionNumber, appealId }) => {
  const [editing, setEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  if (editing) {
    return (
      <EditHearingSession
        id={id}
        sessionNumber={sessionNumber}
        onSubmit={() => setEditing(false)}
        onCancel={() => setEditing(false)}
      />
    );
  }

  return (
    <Surface
      title={I18n.t("javascript.components.appeals.hearing_date", {
        session: sessionNumber,
      })}
      secondary={
        <Fragment>
          <Icons.Pencil
            description={I18n.t(
              "javascript.components.appeals.hearing_session_details_edit",
              {
                session: sessionNumber,
              }
            )}
            onClick={() => setEditing(true)}
          />
          <Icons.Trash
            description={I18n.t("javascript.components.common.delete")}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          />
        </Fragment>
      }
    >
      <If condition={showDeleteModal}>
        <DeleteHearingSessionModal
          hearingSessionId={id}
          onCancel={() => setShowDeleteModal(false)}
          appealId={appealId}
        />
      </If>
      <ViewHearingSession id={id} />
    </Surface>
  );
};

HearingSession.propTypes = {
  id: BintiPropTypes.ID,
  sessionNumber: PropTypes.number,
  addingNewSession: PropTypes.bool,
  setAddingNewSession: PropTypes.func,
  appealId: BintiPropTypes.ID,
};

export default HearingSession;
