$(function initializeTrainingTrackToggle() {
  $($(".js-curriculum-agency")[0]).on("change", function () {
    maybeShowTrainingTrackInput($(this).val());
  });

  function maybeShowTrainingTrackInput(agencyId) {
    let $trainingTrack;
    let track;
    let i;
    let options;
    $.ajax({
      type: "GET",
      url: "/admin/agencies/" + agencyId + "/training_tracks",
      success: function (result) {
        $trainingTrack = $($(".js-training-track-wrapper")[0]);
        if (result.training_tracks.length > 1) {
          $trainingTrack.show();
          options = "<option value='' selected></option>";
          for (i = 0; i < result.training_tracks.length; i += 1) {
            track = result.training_tracks[i];
            options +=
              "<option value='" + track[0] + "'>" + track[1] + "</option>";
          }
          $trainingTrack.find("select").html(options);
        } else {
          $trainingTrack
            .find("select")
            .html("<option value='' selected></option>");
          $trainingTrack.hide();
        }
      },
    });
  }
});
