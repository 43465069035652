import { makeVar } from "@apollo/client";

export const bannerVar = makeVar({
  visible: false,
  type: null,
  message: null,
});

const useBanner = () => ({
  clearBanner: () => bannerVar({ visible: false }),
  errorBanner: message => bannerVar({ visible: true, type: "error", message }),
  noticeBanner: message =>
    bannerVar({ visible: true, type: "notice", message }),
  infoBanner: message => bannerVar({ visible: true, type: "default", message }),
});

export default useBanner;
