import { useMutation } from "@apollo/client";
import {
  Button,
  InputText,
  InputTextarea,
  LoadingOverlay,
  Modal,
  Notice,
} from "@heart/components";
import I18n from "i18n-js";
import { useState } from "react";

import CreateTranslationConfusionReport from "@graphql/mutations/CreateTranslationConfusionReport.graphql";

const TranslationHeader = () => {
  const [reporting, setReporting] = useState(false);
  const [confusingText, setConfusingText] = useState("");
  const [anythingElse, setAnythingElse] = useState("");
  const [report, { loading }] = useMutation(CreateTranslationConfusionReport, {
    onCompleted: () => {
      setReporting(false);
    },
  });
  return (
    <LoadingOverlay active={loading}>
      <Notice
        title={I18n.t(
          "javascript.components.translation_header.machine_translation_warning"
        )}
      >
        <div>
          <Button
            onClick={() => {
              setReporting(true);
            }}
          >
            {I18n.t("javascript.components.translation_header.submit_text")}
          </Button>
        </div>
        <Modal
          hidden={!reporting}
          onCancel={() => {
            setReporting(false);
          }}
          onSubmit={() => {
            report({
              variables: {
                confusingText: confusingText,
                anythingElse: anythingElse,
                url: window.location.href,
              },
            });
          }}
          submitText={I18n.t("views.common.submit")}
          cancelText={I18n.t("views.common.cancel")}
          title={I18n.t(
            "javascript.components.translation_header.report_modal_title"
          )}
        >
          <InputText
            value={confusingText}
            onChange={setConfusingText}
            label={I18n.t(
              "javascript.components.translation_header.confusing_text_label"
            )}
          />
          <InputTextarea
            value={anythingElse}
            onChange={setAnythingElse}
            label={I18n.t(
              "javascript.components.translation_header.anything_else_label"
            )}
          ></InputTextarea>
        </Modal>
      </Notice>
    </LoadingOverlay>
  );
};
export default TranslationHeader;
