import { Button } from "@heart/components";
import PropTypes from "prop-types";

import CopyToClipboard from "./CopyToClipboard";

/**
 * Given the prop `text`, this component creates a button
 * that, when clicked, copies `text` to the clipboard.
 *
 * It does not display the text, which protects the information
 * from prying eyes, screenshots, and print-outs.
 */
const CopyToClipboardButton = ({ text, label = "Copy To Clipboard" }) => (
  <CopyToClipboard
    text={text}
    button={({ onClick }) => (
      <Button variant="secondary" onClick={onClick}>
        {label}
      </Button>
    )}
  />
);

CopyToClipboardButton.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default CopyToClipboardButton;
