import PropTypes from "prop-types";

import styles from "./ProfileSection.module.scss";

const ListField = ({ items }) => {
  if (items && items.length > 0) {
    return (
      <ul className={styles.fieldText}>
        {items.map((item, idx) => item && <li key={idx}>{item}</li>)}
      </ul>
    );
  }
  // Blank spacer
  return <div className={styles.fieldText}></div>;
};

ListField.propTypes = {
  /** Array of strings to display as a bulleted list */
  items: PropTypes.arrayOf(PropTypes.string),
};
export default ListField;
