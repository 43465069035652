import { gql, useMutation, useQuery } from "@apollo/client";

import SetOtherAdultsInHomeKnown from "@graphql/mutations/SetOtherAdultsInHomeKnown.graphql";

const ApplicationQuery = gql`
  query Application($id: ID!) {
    application(id: $id) {
      id
      __typename
      type
      otherAdultsInHomeKnown
      otherAdultsInHomeCount
    }
  }
`;

export default applicationId => {
  const { loading, data, refetch } = useQuery(ApplicationQuery, {
    variables: { id: applicationId },
  });
  const [setOtherAdultsInHomeKnownMutation] = useMutation(
    SetOtherAdultsInHomeKnown,
    {
      refetchQueries: [
        { query: ApplicationQuery, variables: { id: applicationId } },
      ],
    }
  );

  const setOtherAdultsInHomeKnown = isKnown => {
    setOtherAdultsInHomeKnownMutation({
      variables: {
        applicationId,
        isKnown: isKnown,
      },
    });
  };

  return {
    loading,
    otherAdultsInHomeKnown: data?.application?.otherAdultsInHomeKnown,
    otherAdultsInHomeCount: data?.application?.otherAdultsInHomeCount,
    setOtherAdultsInHomeKnown,
    refetchOtherAdultsInHomeKnown: refetch,
  };
};
