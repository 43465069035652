import { useQuery } from "@apollo/client";
import { Modal, Flex, Icons, Text, Link } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";
import EditableDate from "@components/reusable_ui/EditableDate";

import FeatureFlag from "@graphql/queries/FeatureFlag.graphql";

import { typeEq } from "@lib/graphqlHelpers";

import styles from "./DocumentCell.module.scss";

const { t } = translationWithRoot(
  "requirements.requirement_table.document_cell"
);

export const deleteAttachmentOnSubmit = async ({
  attachment,
  holderToken,
  requirement,
  /** State setters for Modal */
  setHideDeleteModal,
  setSubmittingDeletion,
  /** GraphQL Mutations */
  deleteFormInstanceAttachment,
  deleteUploadedRecordAttachment,
}) => {
  const { id, recordId } = attachment;

  await setSubmittingDeletion(true);
  if (typeEq("ChildFormRequirement", requirement)) {
    await deleteFormInstanceAttachment({
      variables: {
        attachmentId: id,
        formInstanceId: recordId,
        holderToken,
      },
    });
  } else if (typeEq("ScheduledUploadTypeRequirement", requirement)) {
    await deleteUploadedRecordAttachment({
      variables: {
        holderToken,
        attachmentId: id,
        uploadedRecordId: recordId,
      },
    });
  }
  await setSubmittingDeletion(false);
  setHideDeleteModal(true);
};

const Attachment = ({
  lastItem = false,
  attachment,
  holderToken,
  requirement,
  /** GraphQL Mutations */
  deleteFormInstanceAttachment,
  deleteUploadedRecordAttachment,
  setUploadedRecordCompletedDate,
}) => {
  const {
    downloadUrl,
    name,
    id,
    recordCompletedDate,
    recordId,
    uploaderName,
    uploaderPageUrl,
  } = attachment;
  const [hideDeleteModal, setHideDeleteModal] = useState(true);
  const [submittingDeletion, setSubmittingDeletion] = useState(false);
  const [completedDate, setCompletedDate] = useState(recordCompletedDate);

  const { data: { featureFlag: ffResourceParentPortal052024 } = {} } = useQuery(
    FeatureFlag,
    {
      variables: { flag: "ff_resource_parent_portal_05_2024" },
    }
  );

  // Handle the date change and update the local state and GraphQL
  const handleDateChange = async newCompletedDate => {
    await setUploadedRecordCompletedDate({
      variables: {
        holderToken,
        uploadedRecordId: recordId,
        completedDate: newCompletedDate,
      },
    });
    setCompletedDate(newCompletedDate); // Update local state with the new date
  };

  return (
    <Flex column>
      <Flex justify="space-between">
        <div className={styles.attachmentName}>{name}</div>
        <Flex align="center">
          <If condition={downloadUrl}>
            <Icons.FileDownload
              description={t("attachment.download", { name })}
              href={downloadUrl}
              target={`attachment${id}`}
            />
          </If>
          <Icons.Trash
            description={t("attachment.delete", { name })}
            onClick={() => {
              setHideDeleteModal(false);
            }}
          />
        </Flex>
      </Flex>
      <If condition={uploaderName && !!ffResourceParentPortal052024}>
        <Text textStyle="supporting-100">
          {t("attachment.uploaded_by")}:{" "}
          {uploaderPageUrl ? (
            <Link href={uploaderPageUrl}>{uploaderName}</Link>
          ) : (
            uploaderName
          )}
        </Text>
      </If>
      <If condition={completedDate}>
        <Text textStyle="supporting-50">
          ({t("attachment.completed_date")}:{" "}
          <EditableDate
            required
            onChange={handleDateChange}
            value={completedDate} // Bind local state to EditableDate
            label={t("attachment.completed_date_label")}
            editDateButtonTitle={t("attachment.completed_date_edit_title")}
          />
          )
        </Text>
      </If>
      <If condition={!lastItem}>
        <hr className={styles.dividingLine} />
      </If>
      {/* Delete Attachment Confirmation Modal */}
      <Modal
        title={t("attachment.are_you_sure", { name })}
        hidden={hideDeleteModal}
        submitting={submittingDeletion}
        onSubmit={async () =>
          deleteAttachmentOnSubmit({
            attachment,
            holderToken,
            requirement,
            setHideDeleteModal,
            setSubmittingDeletion,
            deleteFormInstanceAttachment,
            deleteUploadedRecordAttachment,
          })
        }
        onCancel={() => setHideDeleteModal(true)}
        submitDangerButton
        submitText={t("delete_record")}
        submittingText={t("deleting")}
      />
    </Flex>
  );
};
Attachment.propTypes = {
  /** Boolean indicating whether this attachment is the
   * last one in a list of attachments. Functionally,
   * controls whether a dividing line shows up at the bottom
   * of the attachment information
   */
  lastItem: PropTypes.bool,
  /** The attachment being displayed */
  attachment: PropTypes.shape({
    downloadUrl: PropTypes.string,
    name: PropTypes.string.isRequired,
    recordCompletedDate: PropTypes.string,
    id: PropTypes.string.isRequired,
    recordId: PropTypes.number.isRequired,
    uploaderName: PropTypes.string,
    uploaderPageUrl: PropTypes.string,
  }),
  /** id of the entity that this attachment is
   * associated with
   */
  holderToken: PropTypes.string.isRequired,
  /** The requirement that this attachment is
   * associated with
   */
  requirement: PropTypes.shape({
    id: PropTypes.number.isRequired,
    form: PropTypes.shape({ slug: PropTypes.string }),
    title: PropTypes.string.isRequired,
    uploadType: PropTypes.shape({ slug: PropTypes.string }),
  }).isRequired,
  /** GraphQL mutation for deleting the attachment if it is
   * attached to a ChildFormInstance
   */
  deleteFormInstanceAttachment: PropTypes.func,
  /** GraphQL mutation for deleting the attachment if it is
   * attached to a ScheduledUploadTypeRequirement
   */
  deleteUploadedRecordAttachment: PropTypes.func.isRequired,
  /** GraphQL mutation for changing the completed date
   * of an uploaded record
   */
  setUploadedRecordCompletedDate: PropTypes.func.isRequired,
};

export default Attachment;
