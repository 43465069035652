import { LoadingOverlay } from "@heart/components";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";
import Dropzone from "react-dropzone";

import styles from "./ReplaceTemplate.module.scss";

const DOCX_MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
const DOC_MIME_TYPE = "application/msword";

class ReplaceTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templateFilename: props.templateFilename,
      templateDownloadUrl: props.templateDownloadUrl,
    };
  }

  onDrop = ([templateFile]) => {
    if (!templateFile) {
      return;
    }

    if (
      templateFile.name.endsWith(".doc") ||
      templateFile.type === DOC_MIME_TYPE
    ) {
      /* eslint-disable no-alert */
      alert(
        "That looks like doc file. Please save it as a docx file and reupload. Thank you! :)"
      );
      /* eslint-enable no-alert */
      return;
    }

    if (
      !templateFile.name.endsWith(".docx") ||
      // workaround for Kraven's computer which is setting type to the empty
      // string for some reason. Should continue to enforce extension tho.
      (templateFile.type !== DOCX_MIME_TYPE && templateFile.type !== "")
    ) {
      /* eslint-disable no-alert */
      alert(
        "Hmm... that doesn't look like a docx file. Please check again and reupload. Thank you! :)"
      );
      /* eslint-enable no-alert */
      return;
    }

    const { uploadPath, locale } = this.props;

    const data = new FormData();
    data.append("template_file", templateFile);

    this.setState({ isUploading: true });
    $.ajax({
      url: `${uploadPath}?locale=${locale}`,
      cache: false,
      contentType: false,
      processData: false,
      data,
      method: "post",
      dataType: "json",
      success: ({ templateDownloadUrl }) => {
        this.setState({
          templateFilename: templateFile.name,
          templateDownloadUrl,
        });
      },
      error: xhr => {
        /* eslint-disable no-alert */
        alert(`Upload failed: ${xhr.responseJSON.error}`);
      },
      complete: () => {
        this.setState({ isUploading: false });
      },
    });
  };

  render() {
    const { templateFilename, templateDownloadUrl, isUploading } = this.state;
    const { locale } = this.props;

    return (
      <div>
        {templateDownloadUrl && (
          <a href={templateDownloadUrl}>{templateFilename}</a>
        )}
        {!templateDownloadUrl && "No template uploaded for this locale"}
        <LoadingOverlay spinner active={isUploading} text="Uploading...">
          <Dropzone onDrop={this.onDrop} multiple={false}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                className={classnames(styles.dropzone, {
                  [styles.dropzoneActive]: isDragActive,
                })}
                {...getRootProps()}
              >
                <label>
                  Click or drag to upload template for &quot;{locale}&quot;
                  <input {...getInputProps()} />
                </label>
              </div>
            )}
          </Dropzone>
        </LoadingOverlay>
      </div>
    );
  }
}

ReplaceTemplate.propTypes = {
  templateFilename: PropTypes.string,
  templateDownloadUrl: PropTypes.string,
  locale: PropTypes.string.isRequired,
  uploadPath: PropTypes.string.isRequired,
};

export default ReplaceTemplate;
