import PropTypes from "prop-types";

const Alert = ({
  message = "Sorry, something went wrong.  Please try again.",
}) => (
  <div className="alert alert-danger" role="alert">
    {message}
  </div>
);

Alert.propTypes = {
  message: PropTypes.string,
};

export default Alert;
