import { Button } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./Cell.module.scss";

const ButtonCell = ({ header = "", value = "" }) => (
  <div className={styles.cell}>
    <div className={styles.smallHeader}>{header}</div>
    <Button variant="secondary" type="submit" id={value}>
      Edit
    </Button>
  </div>
);

ButtonCell.propTypes = {
  header: PropTypes.object,
  value: PropTypes.string,
};

export default ButtonCell;
