import { useMutation } from "@apollo/client";
import { Modal, InputText, InputDropdown, Flex } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import { startCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";

import CreateCriminalCode from "@graphql/mutations/CreateCriminalCode";

const { t } = translationWithRoot(
  "background_check_exemptions.criminal_code_input"
);

const CreateCriminalCodeModal = ({
  hidden,
  onClose,
  agencyId,
  usStateOptions,
  codeSeverityList,
  codeTypes,
}) => {
  const { formState, setFormAttribute } = useBintiForm();

  const [createCriminalCode, { loading }] = useMutation(CreateCriminalCode, {
    onCompleted: onClose,
  });

  const stateKeys = Object.keys(usStateOptions || {});

  return (
    <Modal
      asForm
      hidden={hidden}
      title={t("create_new_code")}
      submitting={loading}
      onSubmit={() =>
        createCriminalCode({ variables: { ...formState, agencyId: agencyId } })
      }
      submitText={t("save_new_code")}
      onCancel={onClose}
    >
      <Flex column>
        <InputText
          id="code"
          label={t("code")}
          required
          onChange={setFormAttribute("code")}
        />
        <InputText
          id="description"
          label={t("description")}
          required
          onChange={setFormAttribute("description")}
        />
        <InputDropdown
          id="severity"
          label={t("severity")}
          required
          values={codeSeverityList.map(codeSeverity => ({
            label: I18n.t(
              `activerecord.enums.background_check_exemption.severity.${codeSeverity}`
            ),
            value: codeSeverity,
          }))}
          onChange={setFormAttribute("severity")}
        />
        <InputDropdown
          id="codeType"
          label={t("code_type")}
          required
          values={codeTypes.map(type => ({
            label: I18n.t(
              `activerecord.enums.background_check_exemption.code_type.${type}`
            ),
            value: type,
          }))}
          onChange={setFormAttribute("codeType")}
        />
        <If condition={formState.codeType === "state"}>
          <InputDropdown
            id="state"
            label={t("state")}
            required
            values={stateKeys.map(label => ({
              label: startCase(label),
              value: usStateOptions[label],
            }))}
            onChange={setFormAttribute("state")}
          />
        </If>
      </Flex>
    </Modal>
  );
};

CreateCriminalCodeModal.propTypes = {
  hidden: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  agencyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  usStateOptions: PropTypes.object.isRequired,
  codeSeverityList: PropTypes.array.isRequired,
  codeTypes: PropTypes.array.isRequired,
};

export default CreateCriminalCodeModal;
