import { Sidebar, Text } from "@heart/components";
import Clickable from "@heart/components/clickable/Clickable";
import useCurrentPageSearchParam from "@heart/components/layout/page_layout/useCurrentPageSearchParam";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {
  personPath,
  editPersonPath,
  familyFindingDocumentsPersonPath,
} from "routes";

import { translationWithRoot } from "@components/T";

import useFeatureFlag from "@lib/useFeatureFlag";

import styles from "./AgencyHumanSidebar.module.scss";
import useAgencyHumanPermissions from "./useAgencyHumanPermissions";

const { t } = translationWithRoot("agency_human.sidebar");

const SidebarLink = ({ pageId, pageTitle, href }) => {
  const { currentPage, navigateToPage } = useCurrentPageSearchParam({});
  const isCurrentPage = pageId === currentPage;

  return (
    <Clickable
      className={styles.removeClickableStyles}
      href={href}
      onClick={isNil(href) ? () => navigateToPage({ page: pageId }) : undefined}
    >
      <Text
        textColor={isCurrentPage ? "emphasis-500" : "neutral-600"}
        textStyle={isCurrentPage ? "emphasis-100" : "body-100"}
      >
        {pageTitle}
      </Text>
    </Clickable>
  );
};
SidebarLink.propTypes = {
  pageId: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  href: PropTypes.string,
};

const AgencyHumanSidebar = ({ avatar, label, agencyHumanId }) => {
  const { flag: ffIntegratedSearch } = useFeatureFlag(
    "ff_integrated_search_01_2024"
  );

  const permissions = useAgencyHumanPermissions(agencyHumanId);

  const items = [
    {
      id: "summary",
      content: (
        <SidebarLink
          pageId="summary"
          pageTitle={t("summary")}
          href={personPath(agencyHumanId)}
        />
      ),
    },
  ];
  if (permissions.mayEdit()) {
    items.push({
      id: "edit",
      content: (
        <SidebarLink
          pageId="edit"
          pageTitle={t("edit")}
          href={editPersonPath(agencyHumanId)}
        />
      ),
    });
  }
  if (permissions.mayViewFamilyFindingDocuments() && ffIntegratedSearch) {
    items.push({
      id: "family_finding_documents",
      content: (
        <SidebarLink
          pageId="family_finding_documents"
          pageTitle={t("family_finding_documents")}
          href={familyFindingDocumentsPersonPath(agencyHumanId)}
        />
      ),
    });
  }

  return <Sidebar avatar={avatar} label={label} items={items} />;
};
AgencyHumanSidebar.propTypes = {
  agencyHumanId: PropTypes.string.isRequired,
  avatar: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
};

export default AgencyHumanSidebar;
