/* eslint-disable no-underscore-dangle */
import { useQuery } from "@apollo/client";
import { Flex } from "@heart/components";
import LogoSpinner from "@heart/components/loading_overlay/LogoSpinner";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import AppRequirementReconciliation from "@graphql/queries/ApplicationRequirementReconciliation.graphql";

import { typeEq } from "@lib/graphqlHelpers";

import {
  isNeededForApplication,
  isNeededForCoApplicant,
  isNeededForApplicant,
  isNeededForOtherAdult,
  isNeededForReference,
} from "../../NeededForHelper";
import ApplicationRequirementTable from "./ApplicationRequirementTable";
import AuxiliaryActionBar from "./AuxiliaryActionBar";
import OtherDocumentsTable from "./OtherDocumentsTable";
import OtherPartnerDocumentsTable from "./OtherPartnerDocumentsTable";

const familyHomeDefinitions = application => [
  {
    title: I18n.t(
      "javascript.components.application_requirements.applicant_forms"
    ),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      (isNeededForApplicant(requirement.form.neededFor) ||
        isNeededForCoApplicant(requirement.form.neededFor)),
    id: "ApplicantForms",
  },
  {
    title: I18n.t(
      "javascript.components.application_requirements.agency_forms"
    ),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForApplication(requirement.form.neededFor) &&
      !requirement.form.psychoSocial,
    id: "AgencyForms",
  },
  {
    title: I18n.st(application.agency.stateAbbr, "psycho_social"),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForApplication(requirement.form.neededFor) &&
      requirement.form.psychoSocial,
  },
  {
    title: I18n.t(
      "javascript.components.application_requirements.reference_forms"
    ),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForReference(requirement.form.neededFor),
  },
  {
    title: I18n.t("javascript.components.application_requirements.oa_forms"),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForOtherAdult(requirement.form.neededFor),
  },
  {
    title: "Supporting Documents",
    filter: ({ requirement }) => typeEq("UploadTypeRequirement", requirement),
    id: "SupportingDocuments",
  },
];

const organizationDefinitions = [
  {
    title: I18n.t(
      "javascript.components.application_requirements.provider_forms"
    ),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForApplicant(requirement.form.neededFor),
    id: "ApplicantForms",
  },
  {
    title: I18n.t(
      "javascript.components.application_requirements.licensor_forms"
    ),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForApplication(requirement.form.neededFor) &&
      !requirement.form.psychoSocial,
    id: "AgencyForms",
  },
  {
    title: I18n.t(
      "javascript.components.application_requirements.supporting_documents"
    ),
    filter: ({ requirement }) => typeEq("UploadTypeRequirement", requirement),
    id: "SupportingDocuments",
  },
];

const groupDefinitions = application => {
  if (
    ["congregate_care", "child_placing_agency"].includes(
      application.license.licenseType
    )
  ) {
    return organizationDefinitions;
  }
  // familyHome or null
  return familyHomeDefinitions(application);
};

const ApplicationRequirementGroups = ({
  applicationId,
  currentUserBintiAdmin,
  showOtherDocumentsTable,
  showOtherPartnerDocumentsTable,
}) => {
  const { loading, error, data } = useQuery(AppRequirementReconciliation, {
    variables: { applicationId },
  });
  if (loading) {
    // TODO actually get some design for this spinner
    return (
      <Flex justify="center">
        <div style={{ height: "200px", width: "200px", margin: "30px" }}>
          <LogoSpinner />
        </div>
      </Flex>
    );
  }
  if (error) return <p>Error :/</p>;

  const { applicationRequirementReconciliation, application } = data;
  const { unmatchedRecords } = applicationRequirementReconciliation;
  const filteredFulfillments = filter =>
    applicationRequirementReconciliation.requirementFulfillments.filter(filter);

  return (
    <div>
      <AuxiliaryActionBar
        application={application}
        currentUserBintiAdmin={currentUserBintiAdmin}
      />
      {groupDefinitions(application).map(({ filter, id, title }) => (
        <ApplicationRequirementTable
          key={title}
          requirementFulfillments={filteredFulfillments(filter)}
          {...{ application, id, title }}
        />
      ))}
      <If condition={showOtherDocumentsTable}>
        <OtherDocumentsTable {...{ application, unmatchedRecords }} />
      </If>
      <If condition={showOtherPartnerDocumentsTable}>
        <OtherPartnerDocumentsTable {...{ application, unmatchedRecords }} />
      </If>
    </div>
  );
};

ApplicationRequirementGroups.propTypes = {
  applicationId: PropTypes.number.isRequired,
  currentUserBintiAdmin: PropTypes.bool,
  showOtherDocumentsTable: PropTypes.bool.isRequired,
  showOtherPartnerDocumentsTable: PropTypes.bool.isRequired,
};

export default ApplicationRequirementGroups;
