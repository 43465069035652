import { Button, Flex } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import Csrf from "@components/Csrf";

import { isTestEnvironment } from "@lib/environment";

import styles from "./SsoCard.module.scss";

const SsoCard = ({ name, uri }) => (
  <Flex
    justify="space-between"
    gap="300"
    align="center"
    className={styles.ssoCard}
  >
    <div>{name}</div>
    <form action={uri} method="post">
      <Csrf />
      <Button type="submit" variant="secondary">
        <If condition={isTestEnvironment()}>{name} </If>
        {I18n.t("views.devise.common.sign_in")}
      </Button>
    </form>
  </Flex>
);

SsoCard.propTypes = {
  name: PropTypes.string,
  uri: PropTypes.string,
};

export default SsoCard;
