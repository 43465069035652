import { Icons, Link, Pill, Text } from "@heart/components";
import { Table, Tbody, Td, Th, Thead, Tr } from "@heart/components/table/Table";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import { PropTypes } from "prop-types";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("placement_search.workers_assigned");

const emptyArray = [];

const WorkersAssignedForm = ({
  formState,
  onPrimaryWorkerChanged,
  onWorkerUnassigned,
}) => {
  const { agencyWorkers = emptyArray, primaryWorkerAssignedId } =
    formState || {};

  const tableStyles = {
    verticalAlign: "middle",
  };

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>{I18n.t("admin.common.name")}</Th>
          <Th>{I18n.t("common.status")}</Th>
          <Th>{I18n.t("admin.common.actions")}</Th>
        </Tr>
      </Thead>
      <Tbody aria-live="polite">
        <Choose>
          <When condition={!isEmpty(agencyWorkers)}>
            {agencyWorkers.map(({ id, name }) => (
              <Tr key={id}>
                <Td style={tableStyles}>
                  <Text>{name}</Text>
                </Td>
                <Td style={tableStyles}>
                  {agencyWorkers.length > 1 &&
                    (primaryWorkerAssignedId === id ? (
                      <Pill text={I18n.t("common.primary")} variant="info" />
                    ) : (
                      <Link onClick={() => onPrimaryWorkerChanged(id)}>
                        {t("primary_button")}
                      </Link>
                    ))}
                </Td>
                <Td style={tableStyles}>
                  <Icons.Trash
                    description={`${t("unassign")} ${name}`}
                    disabled={
                      agencyWorkers.length > 1 && primaryWorkerAssignedId === id
                    }
                    onClick={() => onWorkerUnassigned(id)}
                  >
                    {t("primary_button")}
                  </Icons.Trash>
                </Td>
              </Tr>
            ))}
          </When>
          <Otherwise>
            <Tr>
              <Td colSpan={3} style={{ textAlign: "left" }}>
                {t("no_workers_assigned")}
              </Td>
            </Tr>
          </Otherwise>
        </Choose>
      </Tbody>
    </Table>
  );
};

WorkersAssignedForm.propTypes = {
  formState: PropTypes.object,
  onPrimaryWorkerChanged: PropTypes.func,
  onWorkerUnassigned: PropTypes.func,
};

export default WorkersAssignedForm;
