import { useMutation, useReactiveVar } from "@apollo/client";
import { Modal, InputDropdown, toast } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import UpdateEvent from "@graphql/mutations/integrations/UpdateEvent.graphql";
import Events from "@graphql/queries/integrations/Events.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

/**
 * Modal to edit an integration event status. This will turn off
 * the event in it's entirety for all subscribers and is effectively
 * a kill switch for the event. It will not proactively deque any jobs that are waiting to
 * be processed.
 */
const EventEditModal = ({ eventIdToEdit, resetStateCallback }) => {
  const queryVariables = useReactiveVar(queryVariablesVar);

  const [eventStatus, setEventStatus] = useState(false);
  const [updateEvent, { loading }] = useMutation(UpdateEvent, {
    onCompleted: () => {
      toast.success({
        title: "Success!",
        message: "Updated Event",
      });
      resetStateCallback();
    },
    refectchQueries: [{ query: Events, variables: queryVariables }],
  });

  return (
    <Modal
      asForm
      title="Edit Event Status"
      submitting={loading}
      submitText="Save"
      submittingText="Saving..."
      onCancel={() => {
        resetStateCallback();
      }}
      onSubmit={async () => {
        await updateEvent({
          variables: { eventId: eventIdToEdit, status: eventStatus },
        });
      }}
      hidden={false}
    >
      <InputDropdown
        label="Status"
        values={["enabled", "disabled"]}
        value={eventStatus}
        onChange={setEventStatus}
      />
    </Modal>
  );
};

EventEditModal.propTypes = {
  eventIdToEdit: BintiPropTypes.ID,
  resetStateCallback: PropTypes.func,
};
export default EventEditModal;
