import {
  Actions,
  InputDropdown,
  InputHidden,
  InputPhone,
  InputPostalCode,
  InputRadioGroup,
  InputText,
  SurfaceForm,
} from "@heart/components";
import InputCsrfToken from "@heart/components/inputs/InputCsrfToken";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("recruiting_leads.contact_form");

const ContactForm = ({
  firstName,
  middleName,
  lastName,
  suffix,
  locale,
  email,
  applicationTemplateLineageSlug,
  statesAndTerritories,
  counties,
  csrfToken,
  submitPath,
}) => {
  const [usState, setUsState] = useState();

  return (
    <SurfaceForm method="post" action={submitPath} actions={<Actions />}>
      <InputText
        name="lead[address_line_1]"
        label={t("address_line_1")}
        type="text"
        required
        fullWidth
      />
      <InputText
        name="lead[address_line_2]"
        label={t("address_line_2")}
        type="text"
        fullWidth
      />
      <InputText
        name="lead[city]"
        label={I18n.t("admin.common.address.city")}
        type="text"
        required
        fullWidth
      />
      <InputPostalCode
        name="lead[zipcode]"
        label={I18n.t("admin.common.address.zip_code")}
        countryCode="us"
        required
      />
      <InputDropdown
        name="lead[state]"
        label={I18n.t("admin.common.address.state")}
        values={statesAndTerritories}
        value={usState}
        onChange={setUsState}
        required
        fullWidth
      ></InputDropdown>
      <InputDropdown
        name="lead[county]"
        label={I18n.t("admin.common.address.county")}
        values={usState ? counties[usState] : []}
        disabled={!usState || !counties[usState]}
        required
        fullWidth
      ></InputDropdown>
      <InputPhone
        name="lead[phone_number]"
        label={I18n.t("admin.common.phone_number")}
        required
        fullWidth
      ></InputPhone>
      <InputRadioGroup
        name="lead[preferred_contact_method]"
        label={I18n.t("views.contacts.common.preferred_contact_method")}
        values={[
          { label: I18n.t("views.contacts.common.phone"), value: "phone" },
          { label: I18n.t("views.contacts.common.email"), value: "email" },
        ]}
        required
      ></InputRadioGroup>
      <InputHidden name="lead[first_name]" value={firstName} />
      <InputHidden name="lead[middle_name]" value={middleName} />
      <InputHidden name="lead[last_name]" value={lastName} />
      <InputHidden name="lead[suffix]" value={suffix} />
      <InputHidden name="lead[locale]" value={locale} />
      <InputHidden name="lead[email]" value={email} />
      <InputHidden
        // this isn't assigned directly to the lead, so separate it
        name="application_template_lineage_slug"
        value={applicationTemplateLineageSlug}
      />
      <InputCsrfToken csrfToken={csrfToken} />
    </SurfaceForm>
  );
};

ContactForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  middleName: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  suffix: PropTypes.string.isRequired,
  locale: PropTypes.string,
  email: PropTypes.string.isRequired,
  applicationTemplateLineageSlug: PropTypes.string.isRequired,
  statesAndTerritories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  counties: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  submitPath: PropTypes.string.isRequired,
};

export default ContactForm;
