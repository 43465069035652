import { useQuery } from "@apollo/client";

import FeatureFlag from "@graphql/queries/FeatureFlag.graphql";

/**
 * Convenience hook for fetching a feature flag from the backend.
 */
const useFeatureFlag = (flag: string) => {
  const { data: { featureFlag } = {}, loading } = useQuery(FeatureFlag, {
    variables: { flag },
  });

  return { flag: featureFlag, loading };
};

export default useFeatureFlag;
