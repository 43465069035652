import { Surface, InputTextarea } from "@heart/components";
import PropTypes from "prop-types";

const OtherInformation = ({ comments, onCommentsChanged }) => (
  <Surface title="Other Information">
    <InputTextarea
      label="Comments"
      id="comments_id"
      onChange={onCommentsChanged}
      value={comments || ""}
    />
  </Surface>
);
OtherInformation.propTypes = {
  comments: PropTypes.string,
  onCommentsChanged: PropTypes.func.isRequired,
};

export default OtherInformation;
