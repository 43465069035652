/** This is duplicative to lib/postalCodes. It is currently being used by InputZipcode
 * which is used for the applicant sign-up flow. We can remove this once we feel confident
 * about moving over to the new InputPostalCode in Heart.
 */
import { postcodeValidator } from "postcode-validator";

/** Checks if a zipcode value is a "valid" zipcode. A zipcode is "valid"
 *  when it match the regular expressions for that country. If no country
 *  is specified, it will assume US. */
const isValidZipcode = (postcode, country = "US") =>
  postcodeValidator(postcode, country);

export { isValidZipcode };

/** Checks if the postcodeValidator recognizes zipcodes from this country. */
export { postcodeValidatorExistsForCountry } from "postcode-validator";
