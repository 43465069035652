import { gql } from "@apollo/client";
import I18n from "i18n-js";

import constructName from "../constructName";

// Very similar to other adult. Maybe de-duplicate
export const buildEditIconAction = ({
  staffMember,
  setEditingForStaffMember,
}) => ({
  ariaLabel: `${I18n.t("admin.common.edit")} ${constructName(staffMember)}`,
  onClick: () => setEditingForStaffMember(true),
  icon: "Pencil",
});

export const buildDeleteIconAction = ({
  staffMember,
  removing,
  setRemoving,
}) => ({
  ariaLabel: `${I18n.t("admin.common.delete")} ${constructName(staffMember)}`,
  onClick: () => {
    setRemoving(true); // This enables a Modal
  },
  icon: removing ? "Spinner" : "Trash",
});

export const StaffMemberFragment = gql`
  fragment StaffMember on HumanPlacementProviderRole {
    id
    role
    subrole
    joinedAt
    leftAt
    firstShiftDate
    userId
    firstName
    middleName
    lastName
    suffix
    userAgencyProfiles {
      id
      agencyId
      dateOfBirth
      email
      externalIdentifier
    }
  }
`;
