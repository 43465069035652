import { gql, useMutation, useQuery } from "@apollo/client";
import { InputDate, Flex } from "@heart/components";
import PropTypes from "prop-types";
import React, { refetch } from "react";

import CreateOrUpdateApplicationKeyDate from "@graphql/mutations/CreateOrUpdateApplicationKeyDate.graphql";

// get all the applicationKeyDates associated with the application
const applicationKeyDatesQuery = gql`
  query applicationKeyDates($applicationId: ID!) {
    applicationKeyDates(applicationId: $applicationId) {
      id
      date
      keyDateType {
        id
        name
      }
    }
  }
`;

// get all the agencyKeyDates associated with the applications agency
const agencyKeyDatesQuery = gql`
  query agencyKeyDates($applicationId: ID!) {
    agencyKeyDates(applicationId: $applicationId) {
      id
      isInitialOnly
      keyDateType {
        id
        name
      }
    }
  }
`;

const KeyDates = ({ application, isInitialApplication }) => {
  const { data: appKeyDatesData } = useQuery(applicationKeyDatesQuery, {
    variables: { applicationId: application.id },
  });
  const { data: agencyKeyDatesData } = useQuery(agencyKeyDatesQuery, {
    variables: { applicationId: application.id },
  });

  const appKeyDates = appKeyDatesData?.applicationKeyDates || [];
  const agencyKeyDates = agencyKeyDatesData?.agencyKeyDates || [];

  // get all the agencyKeyDates if app is initial, if not initial then exclude key dates
  // that are initialOnly
  const filteredKeyDates = agencyKeyDates.filter(agencyKeyDate =>
    isInitialApplication ? agencyKeyDate : !agencyKeyDate.isInitialOnly
  );

  // ===============================================================================
  // the two functions below search through the appKeyDates array to find an
  // applicationKeyDate with a matching keyDateTypeId.
  //
  // on the model, the key_date_type_id is unique within the scope of each application_id.
  // only one applicationKeyDate can match the provided agencyKeyDate's keyDateType.id.

  // get the "date" value associated with a specific applicationKeyDate.
  const getAppKeyDateValue = agencyKeyDate => {
    const matchingKeyDate = appKeyDates.find(
      appKeyDate => appKeyDate.keyDateType.id === agencyKeyDate.keyDateType.id
    );
    return matchingKeyDate ? matchingKeyDate.date : undefined;
  };

  // find the id associated with a specific applicationKeyDate
  const getAppKeyDateId = agencyKeyDate => {
    const matchingKeyDate = appKeyDates.find(
      appKeyDate => appKeyDate.keyDateType.id === agencyKeyDate.keyDateType.id
    );
    return matchingKeyDate ? matchingKeyDate.id : undefined;
  };
  // ===============================================================================

  const [updateKeyDate] = useMutation(CreateOrUpdateApplicationKeyDate, {
    refetchQueries: [
      {
        query: applicationKeyDatesQuery,
        variables: { applicationId: application.id },
      },
    ],
    onCompleted: refetch,
  });

  return (
    <div>
      <Flex column>
        {filteredKeyDates?.map(agencyKeyDate => (
          <InputDate
            key={agencyKeyDate.id}
            id={getAppKeyDateId(agencyKeyDate)}
            label={agencyKeyDate.keyDateType.name}
            value={getAppKeyDateValue(agencyKeyDate)}
            onChange={newDate =>
              updateKeyDate({
                variables: {
                  id: getAppKeyDateId(agencyKeyDate),
                  date: newDate,
                  applicationId: application.id,
                  keyDateTypeId: agencyKeyDate.keyDateType.id,
                },
              })
            }
          />
        ))}
      </Flex>
    </div>
  );
};

KeyDates.propTypes = {
  application: PropTypes.object.isRequired,
  isInitialApplication: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default KeyDates;
