import { Button, Text, Flex } from "@heart/components";
import { identity, startCase } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import Spinner from "@components/Spinner";
import T from "@components/T";

import SigningEvent from "@graphql/queries/SigningEvent.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import { usePollingQuery } from "@lib/graphqlHelpers";

const isEventReady = data =>
  data?.signingEvent?.state !== "generating_locations";

const PlaceholderSigningButton = ({
  contentWhenReady = identity,
  signingEventId,
}) => {
  const { loading, error, data } = usePollingQuery(SigningEvent, isEventReady, {
    variables: { id: signingEventId },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return I18n.t("assets.javascripts.common.trouble_with_document_prep");
  }

  if (!data) {
    return null;
  }

  const { signingEvent } = data;

  const requiredSignatures = signingEvent.requiredSignatures || [];

  const isAnyApplicantIncomplete = requiredSignatures
    .filter(
      signer => signer.role === "applicant" || signer.role === "coApplicant"
    )
    .some(signer => !signer.isComplete);

  const isAnyWorkerIncomplete = requiredSignatures
    .filter(
      signer => signer.role === "caseworker" || signer.role === "supervisor"
    )
    .some(signer => !signer.isComplete);

  const getMessage = isTrue => (isTrue ? "already signed" : "signature needed");

  return contentWhenReady(
    <Fragment>
      {requiredSignatures
        .filter(
          signer => signer.role === "applicant" || signer.role === "coApplicant"
        )
        .sort((a, b) => a.role.localeCompare(b.role))
        .map(signer => (
          <Flex column key={signer.id}>
            <Button
              disabled={true}
              data-testid="bintisign-signer-button"
              style={{ width: "auto", maxWidth: "250px" }}
            >{`${startCase(signer.role)} ${getMessage(
              signer.isComplete
            )}`}</Button>
          </Flex>
        ))}
      {""}
      {requiredSignatures.length > 0 && isAnyApplicantIncomplete && (
        <Text>
          <T t="admin.applicant_view_button_help_text" />
        </Text>
      )}
      {<br />}
      {<br />}
      {requiredSignatures.length > 1 && isAnyWorkerIncomplete && (
        <Text>
          <T t="admin.applicant_view_caseworker_help_text" />
        </Text>
      )}
    </Fragment>
  );
};

PlaceholderSigningButton.propTypes = {
  signingEventId: BintiPropTypes.ID,
  contentWhenReady: PropTypes.func,
};

export default PlaceholderSigningButton;
