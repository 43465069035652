import { gql, useQuery } from "@apollo/client";
import { InputFilterable, LoadingOverlay } from "@heart/components";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import PropTypes from "prop-types";
import { agencyServicesPath } from "routes";

import { translationWithRoot } from "@components/T";

import AgencyServicesAutocomplete from "@graphql/queries/prevention/AgencyServicesAutocomplete.graphql";

const { T } = translationWithRoot(
  "prevention.agency_service_referrals.form_page"
);

/** Visible for testing purposes only */
export const ratesQuery = gql`
  query AgencyServiceRates($agencyServiceId: ID!) {
    preventionAgencyServiceRate(agencyServiceId: $agencyServiceId) {
      nodes {
        id
        label
      }
    }
  }
`;

const convertToServiceOptions = response =>
  response?.preventionAgencyServicesAutocomplete?.map(service => ({
    label: `${service.name} - ${service.providerAgency.name}`,
    value: service.id,
  })) || [];

const convertToRatesOptions = response =>
  response?.preventionAgencyServiceRate?.nodes?.map(rate => ({
    value: rate.id,
    label: rate.label,
  })) || [];

/**
 * A component to manage the selection of a service and the rates associated to it.
 */
const ServiceAndRateInput = ({
  onServiceChange,
  onRateChange,
  selectedService,
  selectedRates,
}) => {
  const { data: serviceRates, loading: ratesLoading } = useQuery(ratesQuery, {
    variables: { agencyServiceId: selectedService?.value },
    skip: !selectedService?.value,
  });

  const ratesOptions = convertToRatesOptions(serviceRates);

  return (
    <LoadingOverlay active={ratesLoading}>
      <InputAutocompleteGraphQL
        query={AgencyServicesAutocomplete}
        valuesFromResponse={convertToServiceOptions}
        label={
          <span>
            <T t={"services"} />
            &nbsp;
            <a href={agencyServicesPath()} target={"_blank"} rel={"noreferrer"}>
              <T t={"view_services"} />
            </a>
          </span>
        }
        onChange={onServiceChange}
        required
        value={selectedService}
      />
      <InputFilterable
        disabled={!selectedService?.value}
        values={ratesOptions}
        label={<T t={"service_rates"} />}
        onChange={onRateChange}
        value={selectedRates}
        required
        isMulti
      />
    </LoadingOverlay>
  );
};

ServiceAndRateInput.propTypes = {
  onServiceChange: PropTypes.func.isRequired,
  onRateChange: PropTypes.func.isRequired,
  selectedService: PropTypes.object,
  selectedRates: PropTypes.arrayOf(PropTypes.object),
};

export default ServiceAndRateInput;
