import { gql, useQuery } from "@apollo/client";
import { InputDropdown, LiveRegion, LoadingOverlay } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

import CareProviderDropdown from "./CareProviderDropdown";

const { t } = translationWithRoot("placement_period");

// This dropdown also triggers the "placement form" js file upon change
// app/javascript/legacy/admin/placement_form.js
// TODO: turn all of the logic on this page into react
// https://binti.atlassian.net/browse/ENG-6337

/** Optionally let the user select a placement type.
 *    Children > Row Actions > Edit Placement
 */
const PlacementType = ({
  childId,
  placementPeriodId,
  agencyId,
  hideComponent,
}) => {
  const { data, loading } = useQuery(
    gql`
      query PlacementType(
        $childId: ID!
        $placementPeriodId: ID!
        $placementPeriodIdNonNull: Boolean!
        $nillablePlacementPeriodId: ID
      ) {
        placementPeriod(placementPeriodId: $placementPeriodId)
          @include(if: $placementPeriodIdNonNull) {
          id
          placementType
          careProvider
        }
        placementPeriodFormData(
          childId: $childId
          placementPeriodId: $nillablePlacementPeriodId
        ) {
          placementPeriodPlacementTypes {
            label
            value
          }
          permittedAttributes
        }
      }
    `,
    {
      variables: {
        childId,
        placementPeriodId: placementPeriodId || 0,
        nillablePlacementPeriodId: placementPeriodId,
        placementPeriodIdNonNull: placementPeriodId != null,
      },
    }
  );

  const { placementType } = data?.placementPeriod || {};

  const placementPeriodTypeOptions =
    data?.placementPeriodFormData?.placementPeriodPlacementTypes ||
    (placementType ? [{ label: placementType, value: placementType }] : []);

  const permittedAttributes =
    data?.placementPeriodFormData?.permittedAttributes || [];

  const { formState, setFormAttribute } = useBintiForm(data?.placementPeriod);

  return (
    <LiveRegion>
      <LoadingOverlay active={loading}>
        <If condition={hideComponent}>
          <InputDropdown
            required
            disabled={!permittedAttributes.includes("placement_type")}
            label={t("placement_type")}
            values={placementPeriodTypeOptions}
            value={formState.placementType}
            onChange={setFormAttribute("placementType")}
          />
        </If>
        <If condition={formState.placementType != null}>
          <CareProviderDropdown
            childId={childId}
            agencyId={agencyId}
            placementPeriodId={placementPeriodId}
            formState={formState}
            setFormAttribute={setFormAttribute}
          />
        </If>
      </LoadingOverlay>
    </LiveRegion>
  );
};

PlacementType.propTypes = {
  childId: BintiPropTypes.ID,
  placementPeriodId: BintiPropTypes.ID,
  placementType: PropTypes.string,
  agencyId: BintiPropTypes.ID,
  hideComponent: PropTypes.bool,
};

export default PlacementType;
