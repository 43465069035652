import { InputDropdown, LiveRegion } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./PlacementType.module.scss";

// This dropdown also triggers the "placement form" js file upon change
// app/javascript/legacy/admin/placement_form.js
// TODO: turn all of the logic on this page into react
// https://binti.atlassian.net/browse/ENG-6337

/** Optionally let the user select a placement type.
 *    Children > Row Actions > Edit Placement
 */
const PlacementType = ({
  placementType,
  placementTypeOptions,
  hideComponent,
}) => (
  <LiveRegion>
    {/* instead of setting the display to none use an input where we can set the type to hidden */}
    <div className={hideComponent ? styles.hideComponent : ""}>
      <InputDropdown
        required
        name="placement_period[placement_type]"
        label="Placement Type"
        className="js-placement-period-open-trigger"
        values={placementTypeOptions}
        value={placementType}
      />
    </div>
  </LiveRegion>
);

PlacementType.propTypes = {
  placementType: PropTypes.string,
  placementTypeOptions: PropTypes.array,
  hideComponent: PropTypes.bool,
};

export default PlacementType;
