import { useMutation } from "@apollo/client";
import {
  SurfaceForm,
  InputDate,
  InputTextarea,
  Actions,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import PropTypes from "prop-types";
import React from "react";

import CreateHearingSessionQuery from "@graphql/mutations/CreateHearingSession";
import AppealQuery from "@graphql/queries/Appeal.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const CreateHearingSession = ({
  appealId,
  sessionNumber,
  onSubmit,
  onCancel,
}) => {
  const [createHearingSession] = useMutation(CreateHearingSessionQuery, {
    refetchQueries: [{ query: AppealQuery, variables: { appealId } }],
    onCompleted: onSubmit,
  });

  const { formState, setFormAttribute } = useBintiForm(null);

  return (
    <SurfaceForm
      title={I18n.t("javascript.components.appeals.hearing_session_number", {
        session: sessionNumber,
      })}
      actions={<Actions cancelAction={onCancel} />}
      onSubmit={preventDefault(() => {
        createHearingSession({
          variables: {
            appealId,
            hearingSessionDate: formState.hearingSessionDate,
            hearingNotes: formState.hearingNotes,
          },
        });
      })}
    >
      <InputDate
        label={I18n.t("javascript.components.appeals.hearing_session_date")}
        name="hearingSessionDate"
        value={formState.hearingSessionDate}
        onChange={setFormAttribute("hearingSessionDate")}
        required
      />
      <InputTextarea
        label={I18n.t("javascript.components.appeals.hearing_session_notes")}
        name="hearingNotes"
        value={formState.hearingNotes}
        onChange={setFormAttribute("hearingNotes")}
      />
    </SurfaceForm>
  );
};

CreateHearingSession.propTypes = {
  sessionNumber: PropTypes.number,
  appealId: BintiPropTypes.ID,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CreateHearingSession;
