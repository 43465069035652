import PropTypes from "prop-types";

import styles from "./PlacementErrors.module.scss";

const parseErrors = ({ otherErrors, overlappingErrors, path }) =>
  otherErrors.map((error, index) => {
    // insert overlapping placement links under the overlapping placement error message
    if (
      error ===
      I18n.t(
        "views.admin.placements.placement_period.errors.overlapping_placement",
        { count: overlappingErrors.length }
      )
    ) {
      return (
        <li key={`other-errors-${index}`}>
          {`${I18n.t(
            "views.admin.placements.placement_period.errors.overlapping_placement",
            { count: overlappingErrors.length }
          )}`}
          <ul className={styles.errors}>
            {overlappingPlacementErrorsLinks(overlappingErrors, path)}
          </ul>
        </li>
      );
    }
    return <li key={`other-errors-${index}`}>{error}</li>;
  });

const overlappingPlacementErrorsLinks = (errors, path) =>
  errors.map((error, index) => {
    const attrList = error.split(":");
    // discard the error label
    attrList.shift();
    const [id, startDate, endDate] = attrList;
    return (
      <li key={`overlapping-errors-${index}`}>
        <a href={`${path}/${id}/edit`} target="_blank" rel="noreferrer">
          {I18n.t(
            "views.admin.placements.placement_period.errors.overlapping_placement_link_text",
            {
              start_date: startDate,
              end_date: endDate,
            }
          )}
        </a>
      </li>
    );
  });

const PlacementErrors = ({ errors, path }) => {
  const label = I18n.t(
    "views.admin.placements.placement_period.errors.overlapping_placement_label"
  );
  const overlappingErrorsPattern = new RegExp(`^${label}:`);
  const otherErrorsPattern = new RegExp(`^(?!${label}:).*$`);
  const overlappingErrors = errors.filter(error =>
    error.match(overlappingErrorsPattern)
  );
  const otherErrors = errors.filter(error => error.match(otherErrorsPattern));
  return (
    <div className={styles.container}>
      <ul className={styles.errors}>
        {parseErrors({ otherErrors, overlappingErrors, path })}
      </ul>
    </div>
  );
};

PlacementErrors.propTypes = {
  errors: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
};

export default PlacementErrors;
