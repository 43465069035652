import { Flex } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import Attachment from "./Attachment";
import styles from "./DocumentCell.module.scss";
import Override from "./Override";

/** Cell contents displaying the associated attachments and overrides for
 * an entity. Note: there are stories for Attachment and Override detailing
 * more configuration details for each
 */
const DocumentCell = ({
  holderToken,
  fulfillment,
  /** GraphQL Mutations */
  createFormOverride,
  createUploadTypeOverride,
  deleteFormInstanceAttachment,
  deleteOverrideRecord,
  deleteUploadedRecordAttachment,
  setUploadedRecordCompletedDate,
}) => {
  const { allAttachmentsForRecord, overrideRecords, requirement } = fulfillment;

  return (
    <Flex column className={styles.documentCell}>
      {/* Display all attachments */}
      {allAttachmentsForRecord.map((attachment, index) => (
        <Attachment
          key={index}
          lastItem={
            index >= allAttachmentsForRecord.length - 1 &&
            isEmpty(overrideRecords)
          }
          holderToken={holderToken}
          attachment={attachment}
          requirement={requirement}
          /** GraphQL Mutations */
          deleteFormInstanceAttachment={deleteFormInstanceAttachment}
          deleteUploadedRecordAttachment={deleteUploadedRecordAttachment}
          setUploadedRecordCompletedDate={setUploadedRecordCompletedDate}
        />
      ))}

      {/* Display all override records */}
      {overrideRecords.map((override, index) => (
        <Override
          key={index}
          holderToken={holderToken}
          fulfillment={fulfillment}
          lastItem={index >= overrideRecords.length - 1}
          overrideRecord={override}
          createFormOverride={createFormOverride}
          createUploadTypeOverride={createUploadTypeOverride}
          deleteOverrideRecord={deleteOverrideRecord}
        />
      ))}
    </Flex>
  );
};
DocumentCell.propTypes = {
  holderToken: PropTypes.string.isRequired,
  fulfillment: PropTypes.shape({
    allAttachmentsForRecord: PropTypes.arrayOf(
      PropTypes.shape({
        downloadUrl: PropTypes.string,
        name: PropTypes.string.isRequired,
        recordCompletedDate: PropTypes.string,
        id: PropTypes.string.isRequired,
        recordId: PropTypes.number.isRequired,
        uploaderName: PropTypes.string,
        uploaderPageUrl: PropTypes.string,
      })
    ),
    overrideRecords: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        creatorName: PropTypes.string.isRequired,
        details: PropTypes.string.isRequired,
        grantDate: PropTypes.string.isRequired,
        reason: PropTypes.string.isRequired,
      })
    ),
    requirement: PropTypes.shape({
      id: PropTypes.number.isRequired,
      form: PropTypes.shape({ slug: PropTypes.string }),
      title: PropTypes.string.isRequired,
      uploadType: PropTypes.shape({ slug: PropTypes.string }),
    }).isRequired,
  }),
  /** GraphQL Mutations */
  createFormOverride: PropTypes.func.isRequired,
  createUploadTypeOverride: PropTypes.func.isRequired,
  deleteFormInstanceAttachment: PropTypes.func.isRequired,
  deleteOverrideRecord: PropTypes.func.isRequired,
  deleteUploadedRecordAttachment: PropTypes.func.isRequired,
  setUploadedRecordCompletedDate: PropTypes.func.isRequired,
};

export default DocumentCell;
