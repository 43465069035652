import { useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  Link,
  InputFilterableAsync,
  InputText,
} from "@heart/components";
import { isEmpty, toString } from "lodash";
import PropTypes from "prop-types";
import { useState, useEffect, Fragment } from "react";

import T from "@components/T";

import PreApprovalPlacementHistory from "@graphql/queries/PreApprovalPlacementHistory.graphql";

import PreApprovalPlacementHistoryTable from "./PreApprovalPlacementHistoryTable";

/** This is a place to record placements of children with this applicant before
 *  their application is approved.
 *    Applicant Data > Pre-Approval Placements or
 *    Applications Dashboard > Column "Days since Child Placed" > Link
 */
const Relationship = ({
  child,
  applicationId,
  applicantName,
  mountChild,
  unmountChild,
  setRelationship,
  showEmptyChildError = false,
  loadChildOptions,
  applicationStatus,
}) => {
  const [disabledAddPlacement, setDisabledAddPlacement] = useState(false);
  useEffect(() => setDisabledAddPlacement(checkDisabledAddPlacement));
  const checkDisabledAddPlacement = () => !child.isReal();

  const onChildSelect = ({ value, label }) => {
    mountChild(child.id, toString(value), label);
  };
  const handleRemoveRelationship = () => {
    unmountChild(child.id);
  };
  const handleRelationshipChange = relationship => {
    setRelationship(child.id, { relationship });
  };

  const { data, loading, error } = useQuery(PreApprovalPlacementHistory, {
    variables: { childId: child.id, applicationId },
    skip: !child.id,
  });

  const preApprovalPlacementHistory =
    loading || error ? [] : data.preapprovalPlacementHistory;

  const latestPlacementHasEnded = () => {
    if (preApprovalPlacementHistory.length > 0) {
      return !!preApprovalPlacementHistory[0].endDate;
    }
    return false;
  };

  const applicationStatusApplying =
    applicationStatus === I18n.t("application.statuses.initial_applying");

  return (
    <div>
      <fieldset className="inputs">
        <ol>
          <li>
            {/* Create <ChildAutoCompleteSelect /> instead of reaching to <InputFilterableAsync /> */}
            <InputFilterableAsync
              required
              label="Child"
              name={`agency_placement[children][${child.id}][childId]`}
              // placeholder not working because value is not falsey, please fix
              placeholder="Please enter 3 or more characters"
              loadOptions={loadChildOptions}
              onChange={onChildSelect}
              value={child}
              error={showEmptyChildError && <T t="common.cant_be_blank" />}
              description={
                <Flex row>
                  {"Don't see the child you're looking for?"}
                  <Link type="submit" id="add-child">
                    Add a child here
                  </Link>
                </Flex>
              }
            />
          </li>
          <li>
            <InputText
              label={<T t="children_in_home.relationships.relationship" />}
              type="text"
              id={`${child.id}_relationship`}
              name={`agency_placement[children][${child.id}][relationship]`}
              onChange={handleRelationshipChange}
              value={child.relationship}
            />
          </li>
          <li>
            {(isEmpty(child.latestPlacementId) ||
              latestPlacementHasEnded()) && (
              <Fragment>
                <div className="space-below-1">
                  {applicationStatusApplying
                    ? ""
                    : I18n.t(
                        "javascript.components.children_in_home.relationships.application_status_warning",
                        { status: applicationStatus }
                      )}
                </div>
                <Button
                  variant="secondary"
                  type="submit"
                  id={`${child.id}-add-placement-periods`}
                  disabled={disabledAddPlacement || !applicationStatusApplying}
                >
                  {I18n.t(
                    "javascript.components.children_in_home.child_in_home_input.add_placement"
                  )}
                </Button>
              </Fragment>
            )}
          </li>
          <li>
            <PreApprovalPlacementHistoryTable
              rows={preApprovalPlacementHistory.map(record => ({
                ...record,
                editId: `${record.child.id}-${record.id}-edit-placement-periods`,
                endDate: `${
                  record.endDate
                    ? record.endDate
                    : I18n.t(
                        "javascript.components.pre_approval_placement_history_table.column.end_date.empty"
                      )
                }`,
              }))}
              applicantName={applicantName}
            />
          </li>
          <li>
            <Button
              variant="danger"
              disabled={
                !isEmpty(child.latestPlacementId) && !latestPlacementHasEnded()
              }
              onClick={handleRemoveRelationship}
            >
              {I18n.t(
                "javascript.components.children_in_home.relationships.remove_relationship"
              )}
            </Button>
          </li>
        </ol>
      </fieldset>
    </div>
  );
};

Relationship.propTypes = {
  child: PropTypes.shape({
    id: PropTypes.string.isRequired,
    relationship: PropTypes.string,
    label: PropTypes.string,
    latestPlacementId: PropTypes.string,
    isReal: PropTypes.func,
  }),
  mountChild: PropTypes.func.isRequired,
  unmountChild: PropTypes.func.isRequired,
  setRelationship: PropTypes.func.isRequired,
  loadChildOptions: PropTypes.func.isRequired,
  showEmptyChildError: PropTypes.bool,
  applicationId: PropTypes.number.isRequired,
  applicantName: PropTypes.string.isRequired,
  applicationStatus: PropTypes.string.isRequired,
};

export default Relationship;
