import { useMutation, useQuery } from "@apollo/client";
import {
  Surface,
  SurfaceForm,
  InputDate,
  InputTextarea,
  Actions,
  Button,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import I18n from "i18n-js";
import { PropTypes } from "prop-types";
import React, { useState } from "react";

import Spinner from "@components/Spinner";

import UpdateAppeal from "@graphql/mutations/UpdateAppeal";
import AppealQuery from "@graphql/queries/Appeal.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import CreateHearingSession from "../HearingSession/CreateHearingSession";
import HearingSession from "../HearingSession/HearingSession";

const HearingDetails = ({ appealId, onSubmit, onCancel }) => {
  const [addingNewSession, setAddingNewSession] = useState(false);

  const { data, loading } = useQuery(AppealQuery, {
    variables: { appealId },
  });
  const [updateAppeal] = useMutation(UpdateAppeal, {
    refetchQueries: [{ query: AppealQuery, variables: { appealId } }],
    onCompleted: onSubmit,
  });

  const { formState, setFormAttribute } = useBintiForm(data?.appeal);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Surface title={I18n.t("javascript.components.appeals.hearing_details")}>
      <SurfaceForm
        title="Edit Hearing Details"
        actions={<Actions cancelAction={onCancel} />}
        onSubmit={preventDefault(() =>
          updateAppeal({
            variables: {
              appealId,
              preHearingNotes: formState.preHearingNotes,
              preHearingDate: formState.preHearingDate,
            },
          })
        )}
      >
        <InputDate
          label={I18n.t("javascript.components.appeals.pre_hearing_date")}
          name="preHearingDate"
          value={formState.preHearingDate}
          onChange={setFormAttribute("preHearingDate")}
          required={false}
        />
        <InputTextarea
          label={I18n.t("javascript.components.appeals.pre_hearing_notes")}
          name="preHearingNotes"
          value={formState.preHearingNotes}
          onChange={setFormAttribute("preHearingNotes")}
          required={false}
        />
      </SurfaceForm>
      {formState.hearingSessions.map((session, index) => (
        <HearingSession
          key={index}
          id={session.id}
          appealId={appealId}
          sessionNumber={index + 1}
        />
      ))}
      {addingNewSession ? (
        <CreateHearingSession
          sessionNumber={formState.hearingSessions.length + 1}
          appealId={appealId}
          onSubmit={() => setAddingNewSession(false)}
          onCancel={() => setAddingNewSession(false)}
        />
      ) : (
        <Button variant="secondary" onClick={() => setAddingNewSession(true)}>
          {I18n.t("javascript.components.appeals.add_new_hearing_session")}
        </Button>
      )}
    </Surface>
  );
};

HearingDetails.propTypes = {
  appealId: BintiPropTypes.ID,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default HearingDetails;
