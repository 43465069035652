import { Icons, Link, InputDate } from "@heart/components";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useState } from "react";

import { LUXON_DATEPICKER_FORMAT } from "@lib/dates";

import styles from "./EditableDate.module.scss";

const EnteredDateControl = ({
  renderDate,
  date,
  editDateButtonTitle,
  disabled,
  enableEditMode,
}) => (
  <span className={styles.dateControl}>
    {renderDate(date)}
    <If condition={!disabled}>
      {" "}
      <Link onClick={enableEditMode} description={editDateButtonTitle}>
        <Icons.Pencil />
        <span className={styles.screenReader}>{editDateButtonTitle}</span>
      </Link>
    </If>
  </span>
);

EnteredDateControl.propTypes = {
  date: PropTypes.string,
  renderDate: PropTypes.func.isRequired,
  editDateButtonTitle: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  enableEditMode: PropTypes.func.isRequired,
};

// Generally speaking, dates are passed from the backend in ISO format
const defaultRenderDate = date =>
  DateTime.fromISO(date).toFormat(LUXON_DATEPICKER_FORMAT);

const EditableDate = ({
  required,
  value,
  onChange,
  disabled,
  label,
  description,
  renderDate = defaultRenderDate,
  editDateButtonTitle,
}) => {
  const dateNeeded = required && isEmpty(value);
  const [showEdit, setShowEdit] = useState(dateNeeded);

  if (!disabled && (showEdit || dateNeeded)) {
    return (
      <div className={styles.editDateContainer}>
        <InputDate
          label={label}
          description={description}
          onChange={newDate => {
            onChange(newDate);
            setShowEdit(false);
          }}
          {...{ value, disabled }}
        />
      </div>
    );
  }

  const enableEditMode = () => {
    setShowEdit(true);
  };

  return (
    <EnteredDateControl
      {...{
        date: value,
        disabled,
        editDateButtonTitle,
        enableEditMode,
        renderDate,
      }}
    />
  );
};

EditableDate.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  /** optional boolean to disable editing */
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  /** optional render prop to display the current date in non-edit mode */
  renderDate: PropTypes.func,
  editDateButtonTitle: PropTypes.string.isRequired,
};

export default EditableDate;
