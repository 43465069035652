const setupSelect2 = () => {
  const DEFAULT_SELECT2_OPTS = { width: "resolve" };
  const ALLOW_CLEAR_OPTS = { placeholder: "", allowClear: true };
  // <select>'s with empty names are active admin contains/equals/starts with,
  // do NOT select2-ify these ones!
  return (() => {
    const result = [];
    $("select.with-select2:not([name='']):not(.delayed-select2)")
      .toArray()
      .forEach(select => {
        const $select = $(select);
        const select2Opts = $.extend({}, DEFAULT_SELECT2_OPTS);
        if ($select.hasClass("allow-clear")) {
          $.extend(select2Opts, ALLOW_CLEAR_OPTS);
        }
        $.extend(select2Opts, $select.data().select2opts || {});

        select2Opts.dropdownParent = $select.parent();

        $select.select2(select2Opts);

        // https://github.com/select2/select2/issues/1456
        const $form = $select.parents("form");

        const select2EnterEvent = e => {
          if (e.keyCode === 13) {
            $form.submit();
          }
        };

        $select.on("select2:select", () =>
          $form.off("keyup", ".select2-selection", select2EnterEvent)
        );

        result.push(
          $select.on("select2:opening", () =>
            $form.on("keyup", ".select2-selection", select2EnterEvent)
          )
        );
      });

    return result;
  })();
};

$(() => {
  if ($("body.active_admin").length > 0) {
    setupSelect2();
  }

  // Make sure select2 is applied to dyamically created selects
  $(document).on("has_many_add:after", (event, $fields) => {
    setTimeout(setupSelect2, 0);
    setTimeout(() => $fields.addClass("loaded"), 0);
  });

  // focus the autocomplete input on open
  // from https://stackoverflow.com/a/73543335
  $(document).on("select2:open", e => {
    document
      .querySelector(`[aria-controls="select2-${e.target.id}-results"]`)
      .focus();
  });
});
