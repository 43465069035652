import { Button, LiveRegion } from "@heart/components";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useState } from "react";
import useCollapse from "react-collapsed";

import styles from "./ShowHide.module.scss";

/**
 * Accessibly renders a header and a disclosure arrow.  When the header is
 * clicked, the content unfolds into view.
 *
 * See this article for more information on the a11y of this component:
 * https://accessibility.huit.harvard.edu/%E2%9C%8E-practice-expandable-sections
 */
const ShowHide = ({ initiallyOpen = false, renderHeader, renderBody }) => {
  const [transitionClass, setTransitionClass] = useState();
  const { getCollapseProps, getToggleProps } = useCollapse({
    defaultExpanded: initiallyOpen,
    onCollapseStart: () => {
      setTransitionClass(styles.caretClosed);
    },
    onExpandStart: () => {
      // alert("hi");
      setTransitionClass(styles.caretOpen);
    },
  });

  const caretClasses = classnames(
    "fas fa-caret-right",
    styles.caret,
    transitionClass
  );

  return (
    <LiveRegion as="div" className={styles.container}>
      <div className={styles.headerContainer}>
        <Button {...getToggleProps()} className={styles.showHideButton}>
          {renderHeader()}
          <i className={caretClasses} />
        </Button>
      </div>
      <div {...getCollapseProps()}>{renderBody()}</div>
    </LiveRegion>
  );
};

ShowHide.propTypes = {
  /** Whether or not the component is in a "folded" state by default */
  initiallyOpen: PropTypes.bool,
  /** Render Props pattern; provide a function that takes props and renders DOM */
  renderHeader: PropTypes.func.isRequired,
  /** Render Props pattern; provide a function that takes props and renders DOM */
  renderBody: PropTypes.func.isRequired,
};

export default ShowHide;
