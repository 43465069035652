import { Breadcrumbs, Layout } from "@heart/components";
import PropTypes from "prop-types";
import { adminChildrenPath, agencyServicesPath } from "routes";

import { translationWithRoot } from "@components/T";
import AgencyServiceForm from "@components/prevention/agency_services/AgencyServiceForm";

const { T } = translationWithRoot("prevention.agency_services");

const AgencyServiceFormPage = ({
  providerAgencies,
  referringAgencies,
  ebps,
  areasOfCoverage,
  fundingSources,
  serviceTypes,
  billingStructures,
}) => (
  <Layout
    pageTitle={<T t="create_new" />}
    breadcrumbs={
      <Breadcrumbs
        pages={[
          {
            label: <T t="dashboard.breadcrumbs.home" />,
            href: adminChildrenPath(),
          },
          {
            label: <T t="dashboard.title" />,
            href: agencyServicesPath(),
          },
          {
            label: <T t="add_service" />,
            href: "#",
          },
        ]}
      />
    }
    main={{
      content: (
        <AgencyServiceForm
          providerAgencies={providerAgencies}
          referringAgencies={referringAgencies}
          ebps={ebps}
          areasOfCoverage={areasOfCoverage}
          fundingSources={fundingSources}
          serviceTypes={serviceTypes}
          billingStructures={billingStructures}
        />
      ),
    }}
  />
);

AgencyServiceFormPage.propTypes = {
  providerAgencies: PropTypes.array,
  referringAgencies: PropTypes.array,
  ebps: PropTypes.array,
  areasOfCoverage: PropTypes.array,
  fundingSources: PropTypes.array,
  serviceTypes: PropTypes.array,
  billingStructures: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default AgencyServiceFormPage;
