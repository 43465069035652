import { gql, useMutation, useQuery } from "@apollo/client";
import { Actions, Icons, LoadingOverlay, SurfaceForm } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import PropTypes from "prop-types";

import requirementTemplateFragment from "@graphql/fragments/RequirementTemplate";
import ScheduleQuery from "@graphql/queries/Schedule.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import RequirementTemplateForm from "./RequirementTemplateForm";

const requirementTemplateEditQuery = gql`
  ${requirementTemplateFragment}
  query RequirementTemplateEdit($id: ID!) {
    requirementTemplate(id: $id) {
      ...RequirementTemplate
    }
  }
`;

const emptyObject = {};

/**
 * Allows you to configure the schedule for a requirement template.
 * Case Management Setup > Schedules > Edit > "Scheduled Requirement Templates"
 */
const RequirementTemplateEdit = ({ id, scheduleId, agencyId }) => {
  const { data, loading: queryLoading } = useQuery(
    requirementTemplateEditQuery,
    {
      variables: {
        id,
      },
    }
  );

  const { formState, setFormAttribute } = useBintiForm(
    data?.requirementTemplate || emptyObject
  );

  const [updateScheduleRequirementTemplate, { loading: updateLoading }] =
    useMutation(
      gql`
        ${requirementTemplateFragment}
        mutation UpdateScheduleRequirementTemplate(
          $input: UpdateScheduleRequirementTemplateInput!
        ) {
          updateScheduleRequirementTemplate(input: $input) {
            requirementTemplate {
              ...RequirementTemplate
            }
          }
        }
      `
    );

  const [deleteRequirementTemplate, { loading: deleteLoading }] = useMutation(
    gql`
      mutation DeleteScheduleRequirementTemplate(
        $input: DeleteScheduleRequirementTemplateInput!
      ) {
        deleteScheduleRequirementTemplate(input: $input) {
          success
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: ScheduleQuery,
          variables: { id: scheduleId },
        },
      ],
    }
  );

  const loading = queryLoading || updateLoading || deleteLoading;

  return (
    <LoadingOverlay active={loading}>
      <SurfaceForm
        title="Scheduled Requirement Template"
        actions={<Actions isSubmitting={updateLoading} />}
        onSubmit={preventDefault(() =>
          updateScheduleRequirementTemplate({
            variables: { input: { ..._.omit(formState, "fulfillableBy") } },
          })
        )}
        secondary={
          <Icons.Trash
            description="Remove"
            isSubmitting={deleteLoading}
            onClick={() =>
              deleteRequirementTemplate({
                variables: { input: { id: formState.id } },
              })
            }
          />
        }
      >
        <RequirementTemplateForm
          agencyId={agencyId}
          templateType={formState.type}
          formState={formState}
          setFormAttribute={setFormAttribute}
        />
      </SurfaceForm>
    </LoadingOverlay>
  );
};

RequirementTemplateEdit.propTypes = {
  id: BintiPropTypes.ID,
  scheduleId: BintiPropTypes.ID,
  templateType: PropTypes.string,
  agencyId: PropTypes.ID,
};

export default RequirementTemplateEdit;
