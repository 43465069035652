import PropTypes from "prop-types";

import styles from "./TopScrollbar.module.scss";

/**
 * Wrapper component to allow showing a horizontal scrollbar at the top of a
 * left/right scrolling view. (By default most browsers display these at the
 * bottom.)
 */
const TopScrollbar = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.inner}>{children}</div>
  </div>
);

TopScrollbar.propTypes = {
  children: PropTypes.node,
};

export default TopScrollbar;
