import {
  Surface,
  Link,
  GraphQLDataTable,
  Pill,
  Flex,
  Icons,
  Button,
} from "@heart/components";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import CopyToClipboardButton from "@components/shared/copy_to_clipboard/CopyToClipboardButton";

import ApiTokensQuery from "@graphql/queries/ApiTokens.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import { formatAsLongDate } from "@lib/dates";

import ApiTokenCreateModal from "./ApiTokenCreateModal";
import ApiTokenDeleteModal from "./ApiTokenDeleteModal";
import ApiTokenEditModal from "./ApiTokenEditModal";

const ApiTokens = ({
  workerId = null,
  showAllFilters = true,
  showAddTokenButton = true,
  showEditDeleteColumn = true,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [apiTokenIdToDelete, setApiTokenIdToDelete] = useState(null);
  const [apiTokenIdToEdit, setApiTokenIdToEdit] = useState(null);

  // dynamically declaring which filters we show so that we can use this component
  // across multiple use cases, and only show filters that are relevant
  const filtersToShow = [
    {
      label: "Status",
      type: "dropdown",
      field: "status",
      values: ["Active", "Expired"],
    },
    {
      label: "Expiring In",
      type: "dropdown",
      field: "expiringIn",
      values: [
        { label: "14 Days or Fewer", value: "14" },
        { label: "7 Days or Fewer", value: "7" },
      ],
    },
    ...(showAllFilters
      ? [
          {
            label: "Agency Name",
            type: "search",
            field: "agencyNameContains",
          },
          {
            label: "Point of Contact Name",
            type: "search",
            field: "adminNameContains",
          },
        ]
      : []),
  ];

  // dynamically declaring which columns we show so that we can use this component
  // across multiple use cases, and only show columns that are relevant
  const columnsToShow = [
    ...(isNil(workerId)
      ? [
          {
            columnName: { name: "Agency Name" },
            id: "agency_name",
            sortBy: "agency_name",
            cell: "agency.name",
          },
          {
            columnName: { name: "Point of Contact" },
            id: "point_of_contact_name",
            cell: ({ user }) => {
              if (!user) return null;
              return <Link href={user.linkToView}>{user.name}</Link>;
            },
          },
        ]
      : []),
    {
      columnName: { name: "Token Name" },
      id: "token_name",
      sortBy: "token_name",
      cell: "name",
    },
    {
      columnName: { name: "Token Status" },
      id: "token_expired",
      cell: ({ expired }) => (
        <Pill
          variant={expired ? "warning" : "success"}
          text={expired ? "Expired" : "Active"}
        />
      ),
    },
    {
      columnName: { name: "Expiration Date" },
      id: "expires_at",
      sortBy: "expires_at",
      cell: ({ expiresAt }) => formatAsLongDate(expiresAt),
    },

    ...(showEditDeleteColumn
      ? [
          {
            columnName: { name: "Edit/Delete" },
            id: "edit_or_delete",
            cell: ({ id, expired }) => (
              <Flex row>
                <If condition={!expired}>
                  <Icons.Pencil
                    description="Edit"
                    onClick={() => {
                      setApiTokenIdToEdit(id);
                      setShowEditModal(true);
                    }}
                  />
                </If>

                <Icons.Trash
                  description="Delete"
                  onClick={() => {
                    setApiTokenIdToDelete(id);
                    setShowDeleteModal(true);
                  }}
                />
              </Flex>
            ),
          },
        ]
      : []),
    {
      columnName: { name: "Authentication Token" },
      id: "auth_token",
      cell: ({ authenticationToken }) => (
        <Flex row>
          <CopyToClipboardButton text={authenticationToken} />
        </Flex>
      ),
    },
  ];

  return (
    <Surface hideTitle title="API Tokens">
      <GraphQLDataTable
        query={ApiTokensQuery}
        defaultFilters={{ workerId }}
        queryTitle="apiTokens"
        actions={
          <If condition={showAddTokenButton}>
            <Flex justify="end" column>
              <Button onClick={() => setShowCreateModal(true)}>
                Add New API Token
              </Button>
            </Flex>
          </If>
        }
        defaultSort={{ sortBy: "agency_name", sortDirection: "ASC" }}
        filters={filtersToShow}
        columns={columnsToShow}
      />
      <If condition={showDeleteModal}>
        <ApiTokenDeleteModal
          apiTokenIdToDelete={apiTokenIdToDelete}
          resetStateCallback={() => {
            setApiTokenIdToDelete(null);
            setShowDeleteModal(false);
          }}
        />
      </If>
      <If condition={showEditModal}>
        <ApiTokenEditModal
          apiTokenIdToEdit={apiTokenIdToEdit}
          resetStateCallback={() => {
            setApiTokenIdToEdit(null);
            setShowEditModal(false);
          }}
        />
      </If>

      <If condition={showCreateModal}>
        <ApiTokenCreateModal
          workerId={workerId}
          resetStateCallback={() => {
            setShowCreateModal(false);
          }}
        />
      </If>
    </Surface>
  );
};

ApiTokens.propTypes = {
  workerId: BintiPropTypes.ID,
  showAllFilters: PropTypes.bool,
  showAddTokenButton: PropTypes.bool,
  showEditDeleteColumn: PropTypes.bool,
};

export default ApiTokens;
