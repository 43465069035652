import { Flex, Link } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import SsoCardGroup from "./SsoCardGroup";
import styles from "./SsoLogin.module.scss";
import SsoSearch from "./SsoSearch";

const SsoLogin = ({ returnUri }) => (
  <Flex column align="center" gap="300">
    <SsoSearch />
    <SsoCardGroup />
    <div className={styles.returnLink}>
      <Link href={returnUri}>
        {I18n.t("devise.sessions.new_via_sso.return_to_regular_sign_in")}
      </Link>
    </div>
  </Flex>
);

SsoLogin.propTypes = {
  returnUri: PropTypes.string,
};

export default SsoLogin;
