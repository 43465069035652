import { useQuery, useReactiveVar } from "@apollo/client";
import { Spinner } from "@heart/components/icon/Icon";
import PropTypes from "prop-types";
import React from "react";

import ErrorBanner from "@components/shared/banner/ErrorBanner";

import * as ResourceActionQuery from "@graphql/queries/ResourceAction.graphql";

import FullPermissions from "./FullPermissions";
import NoPermissions from "./NoPermissions";
import PermissionsPropTypes from "./PermissionsPropTypes";
import SomePermissions from "./SomePermissions";
import {
  hideNoRuleResourceActionsVar,
  hideUnsupportedResourceActionsVar,
} from "./permissionsReactiveVars";

const ResourceAction = ({ queryVariables, viewOnlyMode }) => {
  const hideNoRuleResourceActions = useReactiveVar(
    hideNoRuleResourceActionsVar
  );
  const hideUnsupportedResourceActions = useReactiveVar(
    hideUnsupportedResourceActionsVar
  );
  const { loading, error, data } = useQuery(ResourceActionQuery, {
    variables: queryVariables,
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorBanner message={error.message} />;
  }

  const { resourceAction } = data;
  const { permissionRules } = resourceAction;

  if (
    resourceAction.agencyRelationshipSupport === "unsupported" &&
    hideUnsupportedResourceActions
  ) {
    return null;
  }

  if (permissionRules.length === 0) {
    if (hideNoRuleResourceActions) {
      return null;
    }

    return (
      <NoPermissions
        queryVariables={queryVariables}
        viewOnlyMode={viewOnlyMode}
      />
    );
  }

  // There should only be one permissionRule ever. But just in case..
  return (
    <React.Fragment>
      {permissionRules.map(permissionRule => {
        if (permissionRule.conditions.length === 0) {
          return (
            <FullPermissions
              key={permissionRule.id}
              permissionRuleId={permissionRule.id}
              queryVariables={queryVariables}
              viewOnlyMode={viewOnlyMode}
            />
          );
        }
        return (
          <SomePermissions
            key={permissionRule.id}
            permissionRuleId={permissionRule.id}
            queryVariables={queryVariables}
            viewOnlyMode={viewOnlyMode}
          />
        );
      })}
    </React.Fragment>
  );
};

ResourceAction.propTypes = {
  viewOnlyMode: PropTypes.bool,
  queryVariables: PermissionsPropTypes.queryVariables,
};

export default ResourceAction;
