import { useMutation } from "@apollo/client";
import {
  Alert,
  InputDate,
  InputTextarea,
  Link,
  Modal,
} from "@heart/components";
import I18n from "i18n-js";
import { trim } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

import UpdateApplicationStatusDate from "@graphql/mutations/UpdateApplicationStatusDate.graphql";

import preventDefault from "@lib/preventDefault";

const { T, t } = translationWithRoot("update_application_status_date_link");

const UpdateApplicationStatusDateModal = ({
  allowedStatusMap,
  application,
  hidden,
  onCancel,
  onUpdate,
}) => {
  const [statusDate, setStatusDate] = useState(application.statusDate);
  const [statusDateChangeExplanation, setStatusDateChangeExplanation] =
    useState("");

  // Convenience function to look up display text for a given status from allowedStatusMap
  const statusText = status =>
    allowedStatusMap.find(([mapStatus]) => status === mapStatus)[1];

  const [updateApplicationStatusDate, { loading }] = useMutation(
    UpdateApplicationStatusDate
  );

  const submitForm = preventDefault(() => {
    updateApplicationStatusDate({
      variables: {
        applicationId: application.id,
        statusDate,
        statusDateChangeExplanation,
      },
    }).then(onUpdate);
  });

  return (
    <Modal
      hidden={hidden}
      title={t("modal_title")}
      onCancel={onCancel}
      onSubmit={submitForm}
      submitDisabled={
        statusDate === application.statusDate ||
        trim(statusDateChangeExplanation).length === 0
      }
      submitting={loading}
      submitText={t("submit_button_text")}
      submittingText={t("submitting_text")}
      data-testid="update-application-status-date-modal"
    >
      <T t="warning" />
      {/*
        TODO https://binti.atlassian.net/browse/ENG-13652
        This is styled manually for now to avoid looking horrible, but
        it needs to be replaced with our list component when it's available.
      */}
      <ul style={{ paddingLeft: "40px", margin: "16px 0" }}>
        <li>
          <T t="details_replace_status_change_history" />
        </li>
        <li>
          <T t="details_training_requirements" />
        </li>
        <li>
          <T t="details_renewal_due_dates" />
        </li>
      </ul>
      <InputDate
        label={
          <T t="status_date_label" status={statusText(application.status)} />
        }
        id="update-status-date-picker"
        value={statusDate}
        onChange={setStatusDate}
        required
      />
      <InputTextarea
        required
        label={<T t="explanation_label" />}
        value={statusDateChangeExplanation}
        rows={4}
        onChange={setStatusDateChangeExplanation}
      />
    </Modal>
  );
};

UpdateApplicationStatusDateModal.propTypes = {
  /** Mappings of [raw DB status, status text to display to the user]
   *  for allowed status transitions.
   *  Lookup would be easier as a (status -> display text) object, but `camelize_props`
   *  would de-snake the statuses so we have to do this as an array. */
  allowedStatusMap: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
  application: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    statusDate: PropTypes.string,
  }).isRequired,
  hidden: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const UpdateApplicationStatusDateLink = ({ allowedStatusMap, application }) => {
  const [statusDateChangeModalOpen, setStatusDateChangeModalOpen] =
    useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  return (
    <Fragment>
      <UpdateApplicationStatusDateModal
        allowedStatusMap={allowedStatusMap}
        application={application}
        hidden={!statusDateChangeModalOpen}
        onCancel={() => setStatusDateChangeModalOpen(false)}
        onUpdate={() => {
          setStatusDateChangeModalOpen(false);
          setAlertOpen(true);
        }}
      />
      <Alert
        title={t("modal_title")}
        hidden={!alertOpen}
        submitText={I18n.t("views.common.continue")}
        onSubmit={() => {
          // reload the whole page because there are non-react elements that will
          // update as a result of this mutation
          // TODO add a notification?
          window.location.reload();
        }}
        data-testid="update-application-status-date-alert"
      >
        <T t="status_date_updated" />
      </Alert>
      <Link onClick={() => setStatusDateChangeModalOpen(true)}>
        <T t="update_status_date" />
      </Link>
    </Fragment>
  );
};

UpdateApplicationStatusDateLink.propTypes = {
  /** Mappings of [raw DB status, status text to display to the user]
   *  for allowed status transitions.
   *  Lookup would be easier as a (status -> display text) object, but `camelize_props`
   *  would de-snake the statuses so we have to do this as an array. */
  allowedStatusMap: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
  application: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    statusDate: PropTypes.string,
  }).isRequired,
};

// export this as "ForTesting" to avoid the no-named-as-default eslint rule
export const UpdateApplicationStatusDateLinkForTesting =
  UpdateApplicationStatusDateLink;

export default UpdateApplicationStatusDateLink;
