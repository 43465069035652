import { Link, Text } from "@heart/components";
import PropTypes from "prop-types";
import { Component } from "react";

class UpdateAvailabilityLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }

  onClick = () => {
    $.ajax({
      url: this.props.url,
      method: "put",
      dataType: "json",
      contentType: "application/json",
      success: this.setState({
        clicked: true,
      }),
    });
  };

  render() {
    const { clicked } = this.state;
    if (clicked) {
      return (
        <Text textStyle="supporting-100" textColor="neutral-500">
          Availability has been updated.
        </Text>
      );
    }
    return (
      <Link onClick={this.onClick}>
        {this.props.label || "Mark as Current"}
      </Link>
    );
  }
}

UpdateAvailabilityLink.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default UpdateAvailabilityLink;
