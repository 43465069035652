import { useQuery } from "@apollo/client";
import { Surface, HeartTable } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import AgencyWorkersByEmail from "@graphql/queries/AgencyWorkersByEmail.graphql";

import RoleSetter from "./RoleSetter";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const PreviewingAgencyWorkers = ({ emails }) => {
  const { data } = useQuery(AgencyWorkersByEmail, {
    variables: { emails },
  });

  if (!data) return null;
  return (
    <Fragment>
      <RoleSetter agencyWorkerIds={data.agencyWorkers.map(aw => aw.id)} />
      <Surface title="Affected Users">
        <Table data-testid="bulk-assign-preview">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Current Role</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.agencyWorkers.map((aw, idx) => (
              <AgencyWorkerRow
                key={idx}
                name={aw.name}
                email={aw.email}
                agencyRole={aw.roleForPermissions.name}
              />
            ))}
          </Tbody>
        </Table>
      </Surface>
    </Fragment>
  );
};

PreviewingAgencyWorkers.propTypes = {
  emails: PropTypes.array,
};

const AgencyWorkerRow = ({ name, agencyRole, email }) => (
  <Tr>
    <Td>{name}</Td>
    <Td>
      <a href={`mailto:${email}`}>{email}</a>
    </Td>
    <Td>{agencyRole}</Td>
  </Tr>
);

AgencyWorkerRow.propTypes = {
  name: PropTypes.string.isRequired,
  agencyRole: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default PreviewingAgencyWorkers;
