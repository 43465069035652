import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Page } from "react-pdf";

import styles from "../../BintiSign.module.scss";
import SignatureBlock from "./SignatureBlock";

/**
 * Wrapper around a react-pdf Page that allows us to overlay signature blocks.
 */
const SignaturePage = forwardRef(
  // We're passing along Page prop-types - eslint can't figure this out.
  /* eslint-disable react/prop-types */
  (
    {
      signingEvent,
      activeSigner,
      pageNumber,
      pageWidth,
      signatureLocations,
      ...pageProps
    },
    ref
  ) => {
    const locationIndex = location => {
      const requiredSignature = signingEvent.requiredSignatures.find(
        ({ role }) => role === location.signerRole
      );
      return requiredSignature.locations.indexOf(location);
    };

    return (
      <div className={styles.signaturePageWrapper} ref={ref}>
        <div className={styles.signaturePage}>
          <Page
            className={styles.pdfjsPage}
            width={pageWidth}
            {...{ pageNumber, ...pageProps }}
          />
          {signatureLocations.map(({ location, isSigned }, i) => (
            <SignatureBlock
              key={`page-${pageNumber}-signature-${i}`}
              {...{
                signingEvent,
                activeSigner,
                location,
                locationIndex: locationIndex(location),
                isSigned,
                pageWidth,
              }}
            />
          ))}
        </div>
      </div>
    );
  }
);

SignaturePage.displayName = "SignaturePage";

SignaturePage.propTypes = Object.assign({}, Page.propTypes, {
  signingEvent: PropTypes.object.isRequired,
  activeSigner: PropTypes.object.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageWidth: PropTypes.number.isRequired,
  signatureLocations: PropTypes.array.isRequired,
});

export default SignaturePage;
