import { Alert, Flex, Icons, LoadingOverlay } from "@heart/components";
import classNames from "classnames";
import I18n from "i18n-js";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { translationWithRoot } from "@components/T";

import styles from "./Upload.module.scss";
import useDropzoneWrapper from "./useDropzoneWrapper";

const { t, T } = translationWithRoot("upload");

const SmallDropzone = ({ icon }) => (
  <Flex
    align="center"
    justify="center"
    key="small-dropzone"
    className={styles.dropzonePadding}
  >
    <div className={styles.icon}>{icon}</div>
  </Flex>
);
SmallDropzone.propTypes = {
  icon: PropTypes.node.isRequired,
};

const LargeDropzone = ({ fileText, icon }) => (
  <Flex
    key="large-dropzone"
    align="center"
    gap="300"
    className={styles.dropzonePadding}
  >
    <div className={styles.icon}>{icon}</div>
    <Flex column gap="0">
      <p className={styles.linkText}>
        <T t="browse_for" file={fileText} />
      </p>
      <p>
        <T t="or_drop_files" />
      </p>
    </Flex>
  </Flex>
);
LargeDropzone.propTypes = {
  fileText: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

const OverlayDropzone = ({ active, fileText, iconOnly, children }) => (
  <Fragment>
    <div className={styles.overlayContainer}>
      {children}
      <If condition={active}>
        <Flex
          key="overlay-dropzone"
          align="center"
          justify="center"
          className={classNames(
            styles.dropzonePadding,
            styles.overlayDropzoneContent
          )}
        >
          <Flex className={styles.helpTextContainer}>
            <div className={styles.icon}>
              <Icons.Parachute />
            </div>
            {/* Ideally we would eventually be able to use a CSS container query,
            but they're not widely supported yet
            https://developer.mozilla.org/en-US/docs/Web/CSS/@container */}
            <span className={classNames({ [styles.hidden]: iconOnly })}>
              <T t="drop_files" file={fileText} />
            </span>
          </Flex>
        </Flex>
      </If>
    </div>
  </Fragment>
);
OverlayDropzone.propTypes = {
  active: PropTypes.bool,
  fileText: PropTypes.string.isRequired,
  iconOnly: PropTypes.bool,
  children: PropTypes.any,
};

const uploadIcon = ({ photosOnly, multiple }) => {
  if (photosOnly) {
    if (multiple) {
      return <Icons.Images />;
    }
    return <Icons.Image />;
  }
  return <Icons.FileUpload />;
};

/**
 * A drag and drop upload region for files.
 */
const Upload = ({
  children,
  iconOnly,
  onUpload,
  disabled,
  role,
  "data-testid": testId,
  photosOnly = false,
  audioOnly = false,
  multiple = true,
}) => {
  const hideDropzoneBehindChildren = !isNil(children);
  const [error, setError] = useState(null);
  const onDrop = async files => {
    if (disabled) return;
    setIsUploading(true);
    await onUpload(files);
    setIsUploading(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzoneWrapper({
    onDrop,
    noClick: hideDropzoneBehindChildren,
    setError,
    multiple,
    photosOnly,
    audioOnly,
  });

  const [isUploading, setIsUploading] = useState(false);
  const showDropzone =
    !disabled && (isDragActive || isUploading || !hideDropzoneBehindChildren);

  const fileTextKey = (() => {
    if (photosOnly) return "file.photo";
    if (audioOnly) return "file.audio";
    return "file.any";
  })();
  const fileText = t(fileTextKey, { count: multiple ? 2 : 1 });

  const ariaLabelText = hideDropzoneBehindChildren
    ? `${t("drop_files", { file: fileText })}`
    : `${t("browse_for", { file: fileText })} ${t("or_drop_files")}`;

  const icon = uploadIcon({ photosOnly, multiple });

  return (
    <Fragment>
      <Alert
        hidden={!error}
        isAlert={true}
        title={I18n.t("javascript.components.common.alert")}
        onSubmit={() => setError(null)}
        submitText={<T t="try_again" />}
      >
        {/** These map to errors in react-dropzone [1] but might be `null`.
         * Since Alert always renders its children, we have a translation string
         * for `errors.null` so that its children are never empty [2]:
         *
         * [1] https://github.com/react-dropzone/react-dropzone/blob/master/typings/react-dropzone.d.ts#L13-L18
         * [2] https://github.com/binti-family/family/pull/11491/files/9064702838a79149e8e04233388e0be9859d368b#r1037446203
         */}
        {t(`errors.${error}`)}
      </Alert>
      <div
        {...getRootProps({
          className: classNames({
            [styles.dropzone]: showDropzone,
            [styles.largeDropzone]: !iconOnly && !children && showDropzone,
            [styles.smallDropzone]: iconOnly && !children && showDropzone,
            [styles.overlayDropzone]: children,
          }),
          disabled: isUploading || disabled,
          tabIndex: children ? -1 : 0,
          role: role ?? (children ? "presentation" : "button"),
        })}
      >
        <input
          {...getInputProps({
            "aria-label": ariaLabelText,
            "data-testid": testId,
          })}
        />
        <LoadingOverlay active={isUploading && !error}>
          <If condition={showDropzone}>
            <If condition={!hideDropzoneBehindChildren && iconOnly}>
              <SmallDropzone icon={icon} />
            </If>
            <If condition={!hideDropzoneBehindChildren && !iconOnly}>
              <LargeDropzone fileText={fileText} icon={icon} />
            </If>
            <If condition={hideDropzoneBehindChildren}>
              <OverlayDropzone
                active={showDropzone}
                fileText={fileText}
                iconOnly={iconOnly}
              >
                {children}
              </OverlayDropzone>
            </If>
          </If>
          <If condition={!showDropzone}>{children}</If>
        </LoadingOverlay>
      </div>
    </Fragment>
  );
};

Upload.propTypes = {
  /** **Note**: if the upload zone is wrapped around children,
   * it will automatically make this a hidden dropzone
   *
   * When hidden, the dropzone will not be tabbable. As a result,
   * keyboard users will not be able to use this instance of the
   * dropzone, and there **MUST** be another way for those users to
   * upload items.
   */
  children: PropTypes.any,
  /** Whether the dropzone should be disabled. Will hide the dropzone
   * when true
   */
  disabled: PropTypes.bool,
  /** Render small icon-only version of the upload dropzone */
  iconOnly: PropTypes.bool,
  /** Function called with array of files uploaded. **Note**: this function
   * will only be called with files that meet our accepted file types list
   */
  onUpload: PropTypes.func.isRequired,
  /** Only allow photos to be uploaded */
  photosOnly: PropTypes.bool,
  /** Only allow audio files to be uploaded */
  audioOnly: PropTypes.bool,
  /** Allow multiple files to be uploaded.  Defaults to true */
  multiple: PropTypes.bool,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
  /** The role of the dropzone root container. Defaults to "presentation"
   * when there are children, "button" where there are no children. */
  role: PropTypes.string,
};

export default Upload;
