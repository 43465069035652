$(() => {
  $(document).on("change", ".js-training-caregiver-profile", function () {
    const $fieldSet = $(this).parents(".js-training-attendance");
    const $caregiver = $fieldSet.find(".js-custom-caregiver-field");

    if ($(this).val() === Binti.placeholder_caregiver_profile_id) {
      $caregiver.show();
    } else {
      $caregiver.hide();
    }
  });

  $(document).on("change", ".js-curriculum-picker", function () {
    const curriculumId = $(this).val();
    const $wrapper = $(this).parent().parent();

    const $otherTrainingExplanation = $wrapper.find(
      ".js-other-training-explanation"
    );
    const $otherTrainingHours = $wrapper.find(".js-other-training-hours");
    if (curriculumId === "other") {
      $otherTrainingExplanation.show(250);
      $otherTrainingHours.show(250);
    } else {
      $otherTrainingExplanation.hide(250);
      $otherTrainingExplanation.find(":input").val(null);
      $otherTrainingHours.hide(250);
      $otherTrainingHours.find(":input").val(null);
    }

    const $expirationDate = $wrapper.find(".js-expiration-date");
    const trainingType =
      Binti.curriculum_types_by_id[parseInt(curriculumId, 10)];
    if (trainingType === "has_expiration_date") {
      $expirationDate.show(250);
    } else {
      $expirationDate.hide(250);
      $expirationDate.find(":input").val(null);
    }
  });
});
