import { isArray, isNil, times } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { RRule } from "rrule";

const START = "start";
const DAY_OF_MONTH = "day-of-month"; // bymonthday
const DAY_OF_WEEK = "day-of-week"; // uses bysetpos, byweekday

const RRuleMonthInput = ({
  rruleObject: { origOptions, options },
  onRRuleChange,
}) => {
  const [bymonthday, setByMonthDay] = useState(
    (isArray(origOptions.bymonthday)
      ? origOptions.bymonthday[0]
      : origOptions.bymonthday) || 1
  );
  const [bysetpos, setBySetPos] = useState(origOptions.bysetpos || 1);

  // byweekday is inconsistent with passed-in value in origOptions:
  // https://github.com/jakubroztocil/rrule#instance-properties
  const [byweekday, setByWeekDay] = useState(options.byweekday || [RRule.MO]);

  const determineAlignment = () => {
    if (!isNil(origOptions.bymonthday)) return DAY_OF_MONTH;
    if (!isNil(options.byweekday)) return DAY_OF_WEEK;
    return START;
  };
  const [alignment, setAlignment] = useState(determineAlignment());

  useEffect(() => {
    updateRRule();
  }, [alignment, bymonthday, bysetpos, byweekday]);

  const updateRRule = () => {
    switch (alignment) {
      case START:
        onRRuleChange({ bymonthday: null, bysetpos: null, byweekday: null });
        break;
      case DAY_OF_MONTH:
        onRRuleChange({ bymonthday, bysetpos: null, byweekday: null });
        break;
      case DAY_OF_WEEK:
        onRRuleChange({ bymonthday: null, bysetpos, byweekday });
        break;
      default:
        // this shouldn't happen...
        break;
    }
  };

  const onRadioChange = e => setAlignment(e.target.value);

  const onByMonthDayChange = e => setByMonthDay(e.target.value);

  const onBySetPosChange = e => setBySetPos(parseInt(e.target.value, 10));

  const onByWeekDayChange = e =>
    setByWeekDay(e.target.value.split(",").map(d => parseInt(d, 10)));

  return (
    <div>
      {/* FROM EVENT DATE */}
      <fieldset className="choices">
        <ol className="choices-group">
          <li className="choice">
            <label>
              <input
                type="radio"
                checked={alignment === START}
                onChange={onRadioChange}
                value="start"
              />
              From event date
            </label>
          </li>

          {/* ON DAY OF MONTH */}
          <li className="choice">
            <label>
              <input
                type="radio"
                checked={alignment === DAY_OF_MONTH}
                onChange={onRadioChange}
                value={DAY_OF_MONTH}
              />
              On day of the month
            </label>
            <If condition={alignment === DAY_OF_MONTH}>
              <select
                data-testid="day-of-month-dropdown"
                value={bymonthday}
                onChange={onByMonthDayChange}
                disabled={alignment !== DAY_OF_MONTH}
              >
                {times(31, i => (
                  <option key={`dotm-${i + 1}`} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
                <option value={-1}>Last</option>
              </select>
            </If>
          </li>

          {/* ON DAY OF WEEK */}
          <li className="choice">
            <label>
              <input
                type="radio"
                checked={alignment === DAY_OF_WEEK}
                onChange={onRadioChange}
                value={DAY_OF_WEEK}
              />
              On a specific weekday in the month (e.g. &quot;first monday&quot;)
            </label>
            <If condition={alignment === DAY_OF_WEEK}>
              <select
                data-testid="day-of-week-dropdown-nth-day"
                value={bysetpos}
                disabled={alignment !== DAY_OF_WEEK}
                onChange={onBySetPosChange}
              >
                <option value={1}>First</option>
                <option value={2}>Second</option>
                <option value={3}>Third</option>
                <option value={4}>Fourth</option>
                <option value={-1}>Last</option>
              </select>
              <select
                data-testid="day-of-week-dropdown-weekday"
                value={byweekday.join(",")}
                disabled={alignment !== DAY_OF_WEEK}
                onChange={onByWeekDayChange}
              >
                <option value={RRule.MO.weekday}>Monday</option>
                <option value={RRule.TU.weekday}>Tuesday</option>
                <option value={RRule.WE.weekday}>Wednesday</option>
                <option value={RRule.TH.weekday}>Thursday</option>
                <option value={RRule.FR.weekday}>Friday</option>
                <option value={RRule.SA.weekday}>Saturday</option>
                <option value={RRule.SU.weekday}>Sunday</option>
                <option
                  value={[RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]
                    .map(d => d.weekday.toString())
                    .join(",")}
                >
                  Weekday
                </option>
              </select>
            </If>
          </li>
        </ol>
      </fieldset>
    </div>
  );
};

RRuleMonthInput.propTypes = {
  rruleObject: PropTypes.object.isRequired,
  onRRuleChange: PropTypes.func.isRequired,
};

export default RRuleMonthInput;
