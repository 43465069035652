import PropTypes from "prop-types";

import { policy } from "@lib/graphqlHelpers";

import AttachmentControl from "./AttachmentControl";
import styles from "./Requirement.module.scss";

const RecordWithAttachments = ({
  record,
  deleteAttachment,
  attachmentProperties = ["attachments"],
}) => (
  <div className={styles.recordBlock}>
    <div>
      {attachmentProperties.flatMap(attachmentProperty =>
        record[attachmentProperty].map(attachment => {
          const mayDownloadAttachment = policy(record).mayDownloadAttachment();
          const mayDestroyAttachment =
            policy(record).mayDestroyAttachment() ||
            policy(attachment).mayDestroy();

          return (
            <AttachmentControl
              key={`${attachmentProperty}-${attachment.id}`}
              {...{
                attachment,
                deleteAttachment,
                mayDestroyAttachment,
                mayDownloadAttachment,
              }}
            />
          );
        })
      )}
    </div>
  </div>
);

RecordWithAttachments.propTypes = {
  record: PropTypes.object.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  attachmentProperties: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export default RecordWithAttachments;
