import PropTypes from "prop-types";

import CreateUploadTypeRequirementTemplate from "@graphql/mutations/CreateUploadTypeRequirementTemplate.graphql";
import RemoveUploadTypeRequirementTemplate from "@graphql/mutations/RemoveUploadTypeRequirementTemplate.graphql";

import AllRequirementsOfAType from "./AllRequirementsOfAType";
import UploadType from "./UploadType";
import UploadTypeAdder from "./UploadTypeAdder";

const AllUploadTypes = ({
  applicationTemplate,
  stageRole,
  defaultIsCollapsed,
}) => (
  <AllRequirementsOfAType
    type="uploadType"
    createMutation={CreateUploadTypeRequirementTemplate}
    removeMutation={RemoveUploadTypeRequirementTemplate}
    AdderComponent={UploadTypeAdder}
    DisplayComponent={UploadType}
    {...{ applicationTemplate, stageRole, defaultIsCollapsed }}
  />
);

AllUploadTypes.propTypes = {
  /** An ApplicationTemplate GraphQL object */
  applicationTemplate: PropTypes.object.isRequired,
  /**
   * Role of stages currently visible. Used to determine if the objects
   * of `type` are already assigned in the current view.
   */
  stageRole: PropTypes.string.isRequired,
  /**
   * Should the component start out collapsed? The user will have the ability
   * to show/hide.
   */
  defaultIsCollapsed: PropTypes.bool,
};

export default AllUploadTypes;
