// module for Quill to support uploading files and inserting a link to them
import styles from "@heart/components/icon/Icon.module.scss";
import classNames from "classnames";
import { isFunction, isString, isArray, first } from "lodash";

class QuillFileUploader {
  constructor(quill, options = {}) {
    this.quill = quill;
    this.options = options;
    this.testMode = !!options.testMode;

    if (isString(this.options.acceptedFileTypes)) {
      this.acceptedFileTypes = this.options.acceptedFileTypes;
    } else if (isArray(this.options.acceptedFileTypes)) {
      this.acceptedFileTypes = this.options.acceptedFileTypes.join(", ");
    }

    if (!isFunction(this.options.upload)) {
      // eslint-disable-next-line no-console
      console.warn("[QuillFileUploader] upload function must be specified");
    }

    this.upload = this.options.upload;

    const toolbar = this.quill.getModule("toolbar");

    if (toolbar) {
      toolbar.addHandler("uploadlink", this.selectLocalFile.bind(this));

      const uploadLinkButton =
        toolbar.container.getElementsByClassName("ql-uploadlink")[0];

      if (uploadLinkButton) {
        const icon = document.createElement("i");
        icon.setAttribute("class", classNames(styles.icon, "fa-file-upload"));
        uploadLinkButton.appendChild(icon);
      }
    }
  }

  selectLocalFile() {
    this.quill.focus();
    this.range = this.quill.getSelection();

    this.fileInput = document.createElement("input");
    this.fileInput.setAttribute("type", "file");

    // Give browser tests a way to find the file input to interact with
    if (this.testMode) {
      this.fileInput.setAttribute("data-testid", "quill-file-upload");
    }

    if (this.acceptedFileTypes) {
      this.fileInput.setAttribute("accept", this.acceptedFileTypes);
    }

    this.fileInput.setAttribute("style", "visibility:hidden");

    this.fileInput.onchange = this.fileSelected.bind(this);

    document.body.appendChild(this.fileInput);

    // Browser tests can't interact with the system file selection dialog,
    // so only open it in human mode
    if (!this.testMode) {
      this.fileInput.click();
    }
  }

  fileSelected() {
    document.body.removeChild(this.fileInput);

    this.quill.disable();

    const file = first(this.fileInput.files);
    this.upload(file).then(url => {
      let { name } = file;

      // If the user has selected some text, let's assume that's what
      // they want to link to the given file.
      if (this.range.length > 0) {
        name = this.quill.getText(this.range.index, this.range.length);
        this.quill.deleteText(this.range.index, this.range.length);
      }

      this.quill.insertText(this.range.index, name, "uploadlink", url);

      this.quill.enable();
    });
  }
}

export const register = Quill => {
  const Link = Quill.import("formats/link");

  class UploadLink extends Link {}
  UploadLink.blotName = "uploadlink";
  // having a className is an important differientator for Parchment to
  // know what links are normal links versus links generated by this
  // module
  UploadLink.className = "__uploadlink";

  Quill.register(UploadLink);
  Quill.register("modules/fileUploader", QuillFileUploader);
};

export default QuillFileUploader;
