import PropTypes from "prop-types";
import { useEffect, Fragment } from "react";

import useBanner from "@lib/useBanner";

/**
 * This component uses the `useBanner` hook to render an error banner elsewhere
 * on the page while it is part of the render tree.  If you stop rendering it,
 * it clears the banner.
 *
 * ### Cypress
 *
 * To target this component in Cypress, use `cy.findNotification()`.
 */
const ErrorBanner = ({ message }) => {
  const { errorBanner, clearBanner } = useBanner();
  useEffect(() => {
    errorBanner(message);
    return clearBanner;
  }, [message]);

  return <Fragment />;
};

ErrorBanner.propTypes = {
  /**
   * The content of the banner message.  This can be a simple string but also
   * it can be more complex JSX.
   */
  message: PropTypes.node,
};
export default ErrorBanner;
