import { isNumber } from "lodash";

/**
 * Given a signing event, extract the required signatures that may be signed
 * by the current user.
 *
 * Sometimes signingEvent.signers is missing... maybe an apollo sync issue? In
 * that case may return undefined.
 *
 * @param {*} signingEvent
 */
export const extractUserRequiredSignatures = signingEvent =>
  signingEvent.signers?.flatMap(({ role: signerRole }) =>
    signingEvent.requiredSignatures.find(({ role }) => role === signerRole)
  );

/**
 * Given a signing event, returns true if any of the roles the current user
 * may sign for are unsigned.
 *
 * @param {*} signingEvent
 */
export const anyUnsignedRoles = signingEvent =>
  extractUserRequiredSignatures(signingEvent)?.some(
    ({ isComplete }) => !isComplete
  );

export const SIGNING_STATE = Object.freeze({
  CAN_SIGN: "CAN_SIGN",
  AWAITING_EARLIER_SIGNERS: "AWAITING_EARLIER_SIGNERS",
  AWAITING_LATER_SIGNERS: "AWAITING_LATER_SIGNERS",
  AWAITING_INTERMEDIATE_SIGNERS: "AWAITING_INTERMEDIATE_SIGNERS",
});

/**
 * Given a signingEvent, return whether the user may sign or is waiting
 * on someone else to sign, or unknown.
 *
 * @param {*} signingEvent
 */
export const extractSigningState = signingEvent => {
  const userRequiredSignatures = extractUserRequiredSignatures(signingEvent);

  if (!userRequiredSignatures) return undefined;

  const { nextAllowedSignatureOrder } = signingEvent;

  if (!isNumber(nextAllowedSignatureOrder)) return undefined;

  if (
    userRequiredSignatures.some(
      ({ order }) => order === nextAllowedSignatureOrder
    )
  ) {
    return SIGNING_STATE.CAN_SIGN;
  }

  const hasAlreadySignedSome = userRequiredSignatures.some(
    ({ order }) => order < nextAllowedSignatureOrder
  );

  const hasLaterSignatures = userRequiredSignatures.some(
    ({ order }) => order > nextAllowedSignatureOrder
  );

  if (hasAlreadySignedSome && hasLaterSignatures) {
    return SIGNING_STATE.AWAITING_INTERMEDIATE_SIGNERS;
  }

  if (hasAlreadySignedSome && !hasLaterSignatures) {
    return SIGNING_STATE.AWAITING_LATER_SIGNERS;
  }

  if (!hasAlreadySignedSome && hasLaterSignatures) {
    return SIGNING_STATE.AWAITING_EARLIER_SIGNERS;
  }

  return undefined;
};
