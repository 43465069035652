import { Flex, Link } from "@heart/components";
import PropTypes from "prop-types";

const GenerateIncompletePdf = ({ onClick, linkText, disabled }) => (
  <Flex column align="start">
    <Link onClick={onClick} disabled={disabled}>
      {linkText}
    </Link>
  </Flex>
);

GenerateIncompletePdf.propTypes = {
  onClick: PropTypes.func.isRequired,
  linkText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default GenerateIncompletePdf;
