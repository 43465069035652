import { difference } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import HasMany from "../shared/HasMany";
import GoodCauseBarrier from "./GoodCauseBarrier";

/**
 * HasMany-based collection component for individual GoodCauseBarrier component.
 * This collection is rendered using HasMany because we need the barrier reasons
 * to be unique among the collection, so this component makes sure that
 * uniqueness is maintained.
 *   Application Details > Good Causes
 */
const GoodCauseBarriers = ({
  barrierTypes,
  barriers: initialBarriers = [],
}) => {
  const [barriers, setBarriers] = useState(initialBarriers);

  const addItem = barrier => {
    setBarriers(barriers.concat(barrier));
  };

  const updateItem = updatedBarrier => {
    setBarriers(
      barriers.map(barrier => {
        if (barrier.id === updatedBarrier.id) return updatedBarrier;

        return barrier;
      })
    );
  };

  const removeItem = toRemove => {
    setBarriers(barriers.filter(barrier => toRemove.id !== barrier.id));
  };

  const remainingBarrierTypes = item =>
    difference(
      barrierTypes,
      barriers.filter(b => b.id !== item.id).map(b => b.barrierType)
    );

  return (
    <HasMany
      renderItem={({ item, isPersisted }) => (
        <GoodCauseBarrier
          barrierTypes={remainingBarrierTypes(item)}
          {...{ isPersisted, item, updateItem }}
        />
      )}
      items={barriers}
      description="Reason"
      {...{ addItem, updateItem, removeItem }}
    />
  );
};

GoodCauseBarriers.propTypes = {
  barrierTypes: PropTypes.array.isRequired,
  barriers: PropTypes.array,
};

export default GoodCauseBarriers;
