import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Flex, InputDropdown, LoadingOverlay } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import PropTypes from "prop-types";

import BintiPropTypes from "@lib/BintiPropTypes";

const updatePlacementSearchResponseMutation = gql`
  mutation UpdatePlacementSearchResponse(
    $input: UpdatePlacementSearchResponseInput!
  ) {
    updatePlacementSearchResponse(input: $input) {
      placementSearch {
        id
        response
      }
    }
  }
`;

const responseEditQuery = gql`
  query responseEditQuery($placementSearchId: ID!) {
    placementSearchFormData {
      responseOptions {
        label
        value
      }
    }
    placementSearch(id: $placementSearchId) {
      id
      response
    }
  }
`;

const ResponseEdit = ({ placementSearchId, onCompleted, onCancel }) => {
  const { data, loading: queryLoading } = useQuery(responseEditQuery, {
    variables: { placementSearchId },
  });
  const [updatePlacementSearchResponse, { loading: mutationLoading }] =
    useMutation(updatePlacementSearchResponseMutation, { onCompleted });

  const { responseOptions } = data?.placementSearchFormData || {
    responseOptions: [],
  };
  const { formState, setFormAttribute } = useBintiForm(data?.placementSearch);

  const onSubmit = () =>
    updatePlacementSearchResponse({
      variables: {
        input: { placementSearchId, response: formState?.response },
      },
    });

  return (
    <LoadingOverlay active={queryLoading || mutationLoading}>
      <Flex column>
        <InputDropdown
          data-testid="placement-search-response-dropdown"
          value={formState?.response}
          values={responseOptions}
          hideBlank
          onChange={setFormAttribute("response")}
        />
        <Flex justify="end">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onSubmit}>Submit</Button>
        </Flex>
      </Flex>
    </LoadingOverlay>
  );
};

ResponseEdit.propTypes = {
  placementSearchId: BintiPropTypes.ID,
  onCompleted: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ResponseEdit;
