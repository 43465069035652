import { useMutation, useQuery } from "@apollo/client";
import {
  SurfaceForm,
  InputDate,
  InputDropdown,
  InputTextarea,
  Actions,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React from "react";

import Spinner from "@components/Spinner";

import UpdateAppeal from "@graphql/mutations/UpdateAppeal";
import AppealQuery from "@graphql/queries/Appeal.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

/**
 * Form for editing final appeal outcome
 */
const EditFinalAppealOutcome = ({
  appealId,
  appealOutcomeOptions,
  onSubmit,
  onCancel,
}) => {
  const { data, loading } = useQuery(AppealQuery, {
    variables: { appealId },
  });
  const [updateAppeal] = useMutation(UpdateAppeal, {
    refetchQueries: [{ query: AppealQuery, variables: { appealId } }],
    onCompleted: onSubmit,
  });

  const { formState, setFormAttribute } = useBintiForm(data?.appeal);

  if (loading) {
    return <Spinner />;
  }

  return (
    <SurfaceForm
      title={I18n.t("javascript.components.appeals.final_appeal_outcome")}
      actions={<Actions cancelAction={onCancel} />}
      onSubmit={preventDefault(() =>
        updateAppeal({
          variables: { appealId, ...formState },
        })
      )}
    >
      <InputDate
        label={I18n.t(
          "javascript.components.appeals.appeal_decision_received_date"
        )}
        name="appealResolvedDate"
        value={formState.appealResolvedDate}
        onChange={setFormAttribute("appealResolvedDate")}
        required={false}
      />
      <InputDropdown
        label={I18n.t("javascript.components.appeals.appeal_outcome")}
        values={appealOutcomeOptions.map(([label, value]) => [label, value])}
        name="appealOutcome"
        value={formState.appealOutcome}
        onChange={setFormAttribute("appealOutcome")}
        required={false}
      />
      <InputTextarea
        label={I18n.t(
          "javascript.components.appeals.appeal_withdrawal_conditions"
        )}
        name="conditions"
        value={formState.conditions}
        onChange={setFormAttribute("conditions")}
        required={false}
      />
      <InputTextarea
        label={I18n.t("javascript.components.appeals.appeal_outcome_notes")}
        name="appealOutcomeNotes"
        value={formState.appealOutcomeNotes}
        onChange={setFormAttribute("appealOutcomeNotes")}
        required={false}
      />
    </SurfaceForm>
  );
};

EditFinalAppealOutcome.propTypes = {
  appealId: BintiPropTypes.ID,
  appealOutcomeOptions: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditFinalAppealOutcome;
