import { isNil, times } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { RRule } from "rrule";

const START = "start";
const DAY_OF_MONTH = "day-of-month"; // uses bysetpos, byday/byweekday, bymonth
const DAY_OF_YEAR = "day-of-year"; // uses bymonth, bymonthday

/**
 * there's a bug in the rrule node module we're using that causes bymonthday
 * to be stored incorrectly. To account for this, we are using bysetpos=-1
 * with a bymonthday value of all the potential last dates of the month
 * (28, 29, 30, and 31) to grab the last member of the set of days in the
 * selected month
 *
 * Stack overflow post with bysetpos functionality explanation:
 * https://stackoverflow.com/questions/24943832/rrule-explanaiton-using-byday-and-bysetpos/24950973#24950973
 */
const LAST_DAY_OF_MONTH = Object.freeze([28, 29, 30, 31]); // used in conjunction with bysetpos=-1

const MONTHS = Object.freeze([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);

const RRuleYearInput = ({ rruleObject: { options }, onChange }) => {
  /** These values can come through as Arrays, but we need them to be strings
   * in order to match the values of our <options>. `bysetpos` needs to be
   * an integer because new RRules cannot process strings for this field. On
   * creation of a new RRule, `bymonth` and `bymonthday will be translated to
   * the appropriate data structure correctly.
   */
  const [bymonth, setByMonth] = useState((options.bymonth || 1).toString());
  const [bymonthday, setByMonthDay] = useState(
    (options.bymonthday || 1).toString()
  );
  const [bysetpos, setBySetPos] = useState(parseInt(options.bysetpos || 1, 10));
  const [byweekday, setByWeekDay] = useState(options.byweekday || [RRule.MO]);

  const determineAlignment = () => {
    // when "From event date" radio is selected, `bymonth` is set to null
    if (isNil(options.bymonth)) {
      return START;
    }
    // when "On a day of the year" radio is selected, `byweekday` is set to null
    if (isNil(options.byweekday)) {
      return DAY_OF_YEAR;
    }
    // when "On a specific weekday" radio is selected, `bymonth`, `byweekday`
    // and `bysetpos` are given values
    return DAY_OF_MONTH;
  };
  const [alignment, setAlignment] = useState(determineAlignment());

  useEffect(() => {
    updateRRule();
  }, [alignment, bymonth, bymonthday, bysetpos, byweekday]);

  const updateRRule = () => {
    switch (alignment) {
      case START:
        onChange({
          bymonth: null,
          bymonthday: null,
          bysetpos: null,
          byweekday: null,
        });
        break;
      case DAY_OF_MONTH:
        onChange({ bymonth, bymonthday: null, bysetpos, byweekday });
        break;
      case DAY_OF_YEAR:
        onChange({
          bymonth,
          bymonthday,
          bysetpos,
          byweekday: null,
        });
        break;
      default:
        // this shouldn't happen...
        break;
    }
  };

  const onRadioChange = e => setAlignment(e.target.value);

  const onByMonthChange = e => setByMonth(e.target.value);

  const onByMonthDayChange = e => {
    setByMonthDay(e.target.value);
    setBySetPos(e.target.value === LAST_DAY_OF_MONTH.toString() ? -1 : 1);
  };

  const onBySetPosChange = e => setBySetPos(parseInt(e.target.value, 10));

  const onByWeekDayChange = e =>
    setByWeekDay(e.target.value.split(",").map(d => parseInt(d, 10)));

  return (
    <div>
      <fieldset className="choices">
        <ol className="choices-group">
          <li className="choice">
            <label>
              <input
                type="radio"
                checked={alignment === "start"}
                onChange={onRadioChange}
                value={START}
              />
              From event date
            </label>
          </li>
          <li className="choice">
            <label>
              <input
                type="radio"
                checked={alignment === DAY_OF_YEAR}
                onChange={onRadioChange}
                value={DAY_OF_YEAR}
              />
              On a day of the year (e.g. &quot;March 1st&quot;)
            </label>

            <If condition={alignment === DAY_OF_YEAR}>
              <select
                data-testid="day-of-year-dropdown-month"
                value={bymonth}
                onChange={onByMonthChange}
                disabled={alignment !== DAY_OF_YEAR}
              >
                {MONTHS.map((month, i) => (
                  <option key={month} value={i + 1}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                data-testid="day-of-year-dropdown-monthday"
                value={bymonthday}
                onChange={onByMonthDayChange}
                disabled={alignment !== DAY_OF_YEAR}
              >
                {times(31, i => (
                  <option key={`dotm-${i + 1}`} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
                <option value={LAST_DAY_OF_MONTH}>Last</option>
              </select>
            </If>
          </li>
          <li className="choice">
            <label>
              <input
                type="radio"
                checked={alignment === DAY_OF_MONTH}
                onChange={onRadioChange}
                value={DAY_OF_MONTH}
              />
              On a specific weekday a specific month (e.g. &quot;first Monday in
              April&quot;)
            </label>
            <If condition={alignment === DAY_OF_MONTH}>
              <select
                data-testid="day-of-week-dropdown-nth-day"
                value={bysetpos}
                disabled={alignment !== DAY_OF_MONTH}
                onChange={onBySetPosChange}
              >
                <option value={1}>First</option>
                <option value={2}>Second</option>
                <option value={3}>Third</option>
                <option value={4}>Fourth</option>
                <option value={-1}>Last</option>
              </select>{" "}
              <select
                data-testid="day-of-week-dropdown-weekday"
                value={byweekday.join(",")}
                disabled={alignment !== DAY_OF_MONTH}
                onChange={onByWeekDayChange}
              >
                <option value={RRule.MO.weekday}>Monday</option>
                <option value={RRule.TU.weekday}>Tuesday</option>
                <option value={RRule.WE.weekday}>Wednesday</option>
                <option value={RRule.TH.weekday}>Thursday</option>
                <option value={RRule.FR.weekday}>Friday</option>
                <option value={RRule.SA.weekday}>Saturday</option>
                <option value={RRule.SU.weekday}>Sunday</option>
                <option
                  value={[RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]
                    .map(d => d.weekday.toString())
                    .join(",")}
                >
                  Weekday
                </option>
              </select>
              in
              <select
                data-testid="day-of-week-dropdown-month"
                value={bymonth}
                onChange={onByMonthChange}
                disabled={alignment !== DAY_OF_MONTH}
              >
                {MONTHS.map((month, i) => (
                  <option key={month} value={i + 1}>
                    {month}
                  </option>
                ))}
              </select>
            </If>
          </li>
        </ol>
      </fieldset>
    </div>
  );
};

RRuleYearInput.propTypes = {
  rruleObject: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RRuleYearInput;
