import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { forwardRef, useState, useEffect } from "react";

import { MIN_ALLOWED_DATE } from "@root/constants";

import InputHidden from "./InputHidden";
import { inputCommonPropTypes, BasicInputLayout } from "./common";

// This is the date formatting for HTML attributes of the <input> element -
// Other code will zero out the seconds and milliseconds before passing dates to
// this function, but we need to strip them from the ISO string here as well.
//
// This value is devoid of timezone because of how the HTML element works - timezone info
// is conveyed in the ISO strings you find in `onChange` and the hidden input that
// gets `POST`ed.
const formatDateObject = date => {
  if (!date) return "";

  return date.toISO({
    suppressMilliseconds: true,
    suppressSeconds: true,
    includeOffset: false,
  });
};

// We strip the seconds and milliseconds from any incoming dates
// to simplify the component and to clean up the UI - it asks the user
// for seconds and milliseconds if the given value contains them
const dateISOPropToDateTime = dateProp =>
  dateProp ? DateTime.fromISO(dateProp).startOf("minute") : null;

/**
 * The `InputDateTime` component is used to collect a specific time on a given
 * day.  All props and callbacks accept ISO-formatted strings.
 *
 * Please be aware that this component will strip the seconds and milliseconds
 * off of the values you pass it, as this component isn't concerned with
 * sub-minute accuracy.
 *
 * You shouldn't need to do any timezone conversions when passing data to/from
 * this component; the user should be able to enter a date in their browser locale
 * and the timezone information will be attached as part of the ISO format.
 *
 * ### Note
 * If you're using this in a server `POST` request, congratulations, you're the first!
 * Send a message to #guild-frontend and we can make sure that workflow gets QAed and we
 * can remove this message; consider this to be 'beta' functionality until such time.
 *
 * ### Cypress
 * _these commands accept JS dates from faker or Luxon DateTime instances_
 *  * Entering dates: `cy.enterDateTime({label, date: faker.date.soon()})`
 *  * Asserting dates are set: `cy.checkDateTime({label, expected: DateTime.fromObject({})})`
 */
const InputDateTime = forwardRef(
  ({ value, onChange, minDate, maxDate, ...props }, ref) => {
    const [dateTime, setDateTime] = useState(dateISOPropToDateTime(value));
    useEffect(() => setDateTime(dateISOPropToDateTime(value)), [value]);

    const handleChange = newDateString => {
      const newDate = DateTime.fromISO(newDateString);
      setDateTime(newDate);
      if (onChange) onChange(newDate.toISO());
    };

    return (
      <BasicInputLayout
        {...props}
        inputComponent={({
          name,
          disabled,
          "data-testid": dataTestId,
          ...commonInputProps
        }) => (
          <div data-heart-component="InputDateTime" data-testid={dataTestId}>
            <input
              type="datetime-local"
              {...commonInputProps}
              disabled={disabled}
              onChange={e => handleChange(e.target.value)}
              value={formatDateObject(dateTime)}
              min={formatDateObject(
                dateISOPropToDateTime(minDate || MIN_ALLOWED_DATE)
              )}
              max={formatDateObject(dateISOPropToDateTime(maxDate))}
              ref={ref}
            />
            <InputHidden
              // When we use this input to POST data via `<form method="POST">`,
              // we submit this hidden field instead, so that the whole ISO date
              // including timezone is sent.
              data-testid="input-date-time-iso-value-with-offset"
              value={dateTime ? dateTime.toISO() : ""}
              name={name}
              disabled={disabled}
            />
          </div>
        )}
      />
    );
  }
);

InputDateTime.displayName = "InputDateTime";
InputDateTime.propTypes = {
  /** The initial (or current) field value as an ISO-formatted string.
   * Call `.toISOString()` for JS dates or `.toISO()` on Luxon `DateTime`s
   * to get one. */
  value: PropTypes.string,
  /** Invoked with the current ISO string value as an argument */
  onChange: PropTypes.func,
  /** The earliest date and time users are allowed to select (as ISO string) */
  min: PropTypes.string,
  /** The latest date and time users are allowed to select (as ISO string) */
  max: PropTypes.string,
  /** *not an actual prop, just a check that all dates are ISO* */
  isoCheck: (props, _, componentName) => {
    ["min", "max", "value"].forEach(propName => {
      if (props[propName] && !DateTime.fromISO(props[propName]).isValid) {
        throw new Error(
          `Invalid prop '${propName}' passed to ${componentName}: '${props[propName]}' ` +
            "is not a valid ISO string"
        );
      }
    });
  },
  ...inputCommonPropTypes,
};

export default InputDateTime;
