/* eslint-disable class-methods-use-this */
import { debounce, throttle } from "lodash";
import { Component } from "react";

class FixedHeader extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onWindowResize = () => {
    this.updateCellWidthHeight();
    this.updateHeaderWidth();
  };

  onWindowScroll = () => {
    const { headerElement, tableElement } = this.state;
    this.setState({ headerRect: headerElement.getBoundingClientRect() });
    this.setState({ tableRect: tableElement.getBoundingClientRect() });
  };

  updateHeaderWidth() {
    // we need to keep the width of the header row consistent with the original
    // header
    const { tableElement, fixedHeaderElement } = this.state;
    $(fixedHeaderElement.querySelector("table")).width(
      $(tableElement).width() + 1
    );
  }

  updateCellWidthHeight() {
    const { fixedHeaderElement, headerElement } = this.state;
    const copiedTh = fixedHeaderElement.querySelectorAll("tr th");
    headerElement.querySelectorAll("tr th").forEach((element, index) => {
      if (element.getBoundingClientRect().width !== 0) {
        copiedTh[index].style.display = "table-cell";
        // Setting the widths fixes the height as well. Probably handled by
        // active admin since we use the same classes as on the original table.
        $(copiedTh[index]).width($(element).width());
      } else {
        copiedTh[index].style.display = "none";
      }
    });
  }

  componentDidUpdate() {
    const { fixedHeaderElement, headerRect, tableRect } = this.state;
    if (!fixedHeaderElement || !headerRect || !tableRect) {
      return;
    }

    if (headerRect.top <= 0 && tableRect.bottom - headerRect.height > 0) {
      if (fixedHeaderElement.style.display !== "block") {
        fixedHeaderElement.style.display = "block";
        fixedHeaderElement.style.position = "fixed";
        fixedHeaderElement.style.top = "0px";
      }
    } else if (fixedHeaderElement.style.display !== "none") {
      fixedHeaderElement.style.display = "none";
    }
  }

  onDocumentClick = () => {
    // setTimeout is used so that the actual hide col click event is processed
    // and when we update the header this information is reflected when we
    // look at what is displayed and what is hidden.
    setTimeout(() => {
      const { fixedHeaderElement } = this.state;
      if (!fixedHeaderElement) {
        return;
      }
      if ($("#sidebar").find(".js-dropdown-menu").length !== 0) {
        this.updateCellWidthHeight();
        this.updateHeaderWidth();
      }
    });
  };

  addScrollListenerOnTable = () => {
    const { tableElement, fixedHeaderElement } = this.state;
    const onScroll = () => {
      fixedHeaderElement.querySelector(
        ".js-responsive-table-wrapper"
      ).scrollLeft = tableElement.parentNode.scrollLeft;
    };
    tableElement.parentNode.addEventListener("scroll", throttle(onScroll, 100));
  };

  addScrollListenerOnFixedHeader = () => {
    const { tableElement, fixedHeaderElement } = this.state;
    const onScroll = () => {
      tableElement.parentNode.scrollLeft = fixedHeaderElement.querySelector(
        ".js-responsive-table-wrapper"
      ).scrollLeft;
    };
    fixedHeaderElement
      .querySelector(".js-responsive-table-wrapper")
      .addEventListener("scroll", throttle(onScroll, 100));
  };

  cloneHeader() {
    // use parentNode to keep css alignment as expected
    const clone = document
      .querySelector(".js-fixed-header")
      .parentNode.cloneNode(true);
    $(clone.querySelector("tbody")).remove();
    // remove all siblings (title, etc) that are in between js-fixed-header and
    // js-responsive-table-wrapper
    const elementsToRemove = [];
    clone.querySelectorAll(".js-fixed-header > div").forEach(child => {
      if (
        clone.querySelector(".js-responsive-table-wrapper") !== child &&
        clone.querySelector("table") !== child
      ) {
        elementsToRemove.push(child);
      }
    });
    for (let i = elementsToRemove.length - 1; i >= 0; i -= 1) {
      $(elementsToRemove[i]).remove();
    }
    clone.classList.add("fixed-header-wrapper");
    return clone;
  }

  componentDidMount() {
    const hook = document.querySelector(".js-fixed-header");
    if (!hook) {
      return;
    }
    const fixedHeader = this.cloneHeader();
    fixedHeader.style.display = "none";
    document.body.appendChild(fixedHeader);
    this.setState({ fixedHeaderElement: fixedHeader });
    this.setState({
      headerElement: document.querySelector(".js-fixed-header thead"),
    });
    this.setState({
      tableElement: document.querySelector(".js-fixed-header table"),
    });
    setTimeout(() => {
      // setTimeout used to put these events in queue behind the react setState events
      // since we use the new state in these methods.
      this.updateCellWidthHeight();
      this.updateHeaderWidth();
      this.addScrollListenerOnTable();
      this.addScrollListenerOnFixedHeader();
    });
    window.addEventListener("resize", debounce(this.onWindowResize, 250));
    window.addEventListener("scroll", throttle(this.onWindowScroll, 50));
    document.addEventListener("click", this.onDocumentClick);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("scroll", this.onWindowScroll);
  }

  render() {
    return <div></div>;
  }
}

export default FixedHeader;
