import { Icons } from "@heart/components";
import { flatten } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import CloseDeleteButton from "../shared/CloseDeleteButton";
import styles from "./ApplicationTemplateDesigner.module.scss";
import NeededFor from "./NeededFor";
import RequirementDisplayContext from "./RequirementDisplayContext";

const SIGNERS = {
  applicant: "A",
  co_applicant: "CO",
  caseworker: "CW",
  supervisor: "SV",
  other_adult_in_home: "OA",
  reference_user: "REF",
};

const FormSigners = ({ form }) => {
  if (!form.signingOrder) return false;
  const signers = flatten(form.signingOrder);

  return (
    <div className={styles.formSignerBadges}>
      <p>Signed by:</p>
      {signers.map(signer => (
        <div
          key={signer}
          className={styles.formSignerBadge}
          alt={`Requires a signature by ${signer}`}
          title={`Requires a signature by ${signer}`}
        >
          {SIGNERS[signer]}
        </div>
      ))}
    </div>
  );
};

FormSigners.propTypes = {
  form: PropTypes.object.isRequired,
};

const Form = ({
  form,
  dragHandleProps = {},
  isMissingFromTemplate,
  onAddToTemplate,
  onRemove,
}) => (
  <RequirementDisplayContext.Consumer>
    {({ isCompact }) => (
      <div className={styles.form}>
        <div className={styles.formHeader} {...dragHandleProps}>
          <div>
            <div className={styles.formName}>
              {form.nameTranslations.en}
              {isMissingFromTemplate && (
                <Fragment>
                  {" "}
                  <Icons.ExclamationTriangle
                    onClick={onAddToTemplate}
                    description="Form is not in template! Click to add"
                  />
                </Fragment>
              )}{" "}
              {!isCompact && (
                <a
                  href={`/admin/forms/${form.slug}/edit`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  [<Icons.Pencil />]
                </a>
              )}
            </div>
            <div className={styles.formSlug}>{form.slug}</div>
          </div>
          {onRemove && <CloseDeleteButton onClick={onRemove} />}
        </div>
        {!isCompact && (
          <div className={styles.formRolesSpec}>
            <NeededFor recordType={form} />
            <FormSigners form={form} />
          </div>
        )}
      </div>
    )}
  </RequirementDisplayContext.Consumer>
);

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  isMissingFromTemplate: PropTypes.bool,
  onAddToTemplate: PropTypes.func,
  dragHandleProps: PropTypes.object,
};

export default Form;
