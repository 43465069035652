import { gql, useMutation } from "@apollo/client";
import {
  Actions,
  Button,
  Flex,
  LoadingOverlay,
  Surface,
  SurfaceForm,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import requirementTemplateFragment from "@graphql/fragments/RequirementTemplate";
import ScheduleQuery from "@graphql/queries/Schedule.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import RequirementTemplateForm from "./RequirementTemplateForm";

const templates = {
  uploadType: {
    uploadTypeId: null,
    type: "ScheduledUploadTypeRequirementTemplate",
    requirementType: "ScheduledUploadTypeRequirement",
    specification: {},
    fulfillableBy: [],
  },
  form: {
    formId: null,
    type: "ScheduledFormRequirementTemplate",
    requirementType: "ScheduledFormRequirement",
    specification: {},
  },
};

const emptyObject = {};

/**
 * Allows you to configure the schedule for a requirement template.
 * Case Management Setup > Schedules > Edit > "Scheduled Requirement Templates"
 */
const RequirementTemplateCreate = ({ scheduleId, agencyId, onCancel }) => {
  const { formState, setFormAttribute, setFormState } =
    useBintiForm(emptyObject);
  const [templateType, setTemplateType] = useState("uploadType");

  useEffect(
    () => setFormState(templates[templateType]),
    [templateType, setFormState]
  );

  const [createScheduleRequirementTemplate, { loading }] = useMutation(
    gql`
      ${requirementTemplateFragment}
      mutation CreateScheduleRequirementTemplate(
        $input: CreateScheduleRequirementTemplateInput!
      ) {
        createScheduleRequirementTemplate(input: $input) {
          requirementTemplate {
            ...RequirementTemplate
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: ScheduleQuery, variables: { id: scheduleId } }],
    }
  );

  return (
    <LoadingOverlay active={loading}>
      <Surface title="Create Scheduled Requirement Template">
        <Flex gap="100">
          <Button
            variant={templateType === "uploadType" ? "primary" : "secondary"}
            onClick={() => setTemplateType("uploadType")}
          >
            Upload Type
          </Button>
          <Button
            variant={templateType === "form" ? "primary" : "secondary"}
            onClick={() => setTemplateType("form")}
          >
            Form
          </Button>
        </Flex>
        <SurfaceForm
          title="Scheduled Requirement Template"
          actions={<Actions isSubmitting={loading} cancelAction={onCancel} />}
          onSubmit={preventDefault(() =>
            createScheduleRequirementTemplate({
              variables: {
                input: {
                  ..._.omit(formState, "fulfillableBy"),
                  requirementSourceId: scheduleId,
                  requirementSourceType: "Schedule",
                },
              },
            })
          )}
        >
          <RequirementTemplateForm
            agencyId={agencyId}
            templateType={templateType}
            formState={formState}
            setFormAttribute={setFormAttribute}
            defaults={templates[templateType]}
          />
        </SurfaceForm>
      </Surface>
    </LoadingOverlay>
  );
};

RequirementTemplateCreate.propTypes = {
  id: BintiPropTypes.ID,
  scheduleId: BintiPropTypes.ID,
  agencyId: BintiPropTypes.ID,
  templateType: PropTypes.string,
  defaults: PropTypes.object,
  onCancel: PropTypes.func,
};

export default RequirementTemplateCreate;
