import { InputHidden, InputCheckbox, Notice } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import { LEGACY_OVERRIDABLE_SPEC } from "@lib/overridableHelpers";

import OverridableInput from "./OverridableInput";

/**
 * This input contains a checkbox to control whether a form is optional
 * If (and only if) the form is optional, it also shows options for who
 * can override (waive) the associated requirements
 */
const OptionalOverridableInput = ({
  name,
  initialOverrideSpec = {},
  initialOptional = false,
  onRoleSpecChanged,
}) => {
  const [optional, setOptional] = useState(initialOptional);

  return (
    <div>
      <InputHidden name="form[optional]" value={optional} />
      <InputCheckbox
        value={optional}
        onChange={() => {
          setOptional(!optional);
        }}
        label="Optional Form"
        description="Make this form optional. If the form is optional, please fill out the override options below to control who can waive it."
      />
      <If condition={!optional}>
        <Notice title="Required forms cannot be overridden">
          <p>
            Please make the form optional if you need to set override options.
          </p>
        </Notice>
      </If>
      <div hidden={!optional}>
        <OverridableInput
          name={name}
          // if the form starts out required, prepare the hidden form with defaults,
          // so that if it is set to optional the defaults will be there
          initialOverrideSpec={
            initialOptional ? initialOverrideSpec : LEGACY_OVERRIDABLE_SPEC
          }
          onRoleSpecChanged={onRoleSpecChanged}
          // when the form is not optional, send a blank json value on submit
          // (but keep the normal values in the DOM)
          clearValues={!optional}
        />
      </div>
    </div>
  );
};

OptionalOverridableInput.propTypes = {
  name: PropTypes.string.isRequired,
  initialOverrideSpec: PropTypes.object,
  initialOptional: PropTypes.bool,
  onRoleSpecChanged: PropTypes.func,
};

export default OptionalOverridableInput;
