import { InputCheckbox, InputDropdown } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./ApplicationTemplateDesigner.module.scss";

/** Options at the top of the Application Template designer.
 *   Application Templates > Choose a row with "Designer" > Click
 */
const ApplicationTemplateDesignerViewOptions = ({
  stageRole,
  setStageRole,
  isCompact,
  setIsCompact,
  showOnlyNormalStages,
  setShowOnlyNormalStages,
  showUploadTypesInStages,
  setShowUploadTypesInStages,
  showFormsInStages,
  setShowFormsInStages,
}) => (
  <div className={styles.topControls}>
    <div className={styles.roleSelector}>
      <InputDropdown
        label="Stage Role"
        value={stageRole}
        onChange={setStageRole}
        values={[
          { value: "applicant", label: "Applicant Stages" },
          { value: "other_adult_in_home", label: "Other Adult Stages" },
          { value: "caseworker", label: "Caseworker Stages" },
          { value: "co_applicant", label: "Co-Applicant Stages" },
        ]}
      />
    </div>
    <div className={styles.displayOptions}>
      <InputCheckbox
        value={isCompact}
        onChange={setIsCompact}
        label="Compact View"
      />
      <InputCheckbox
        value={showOnlyNormalStages}
        onChange={setShowOnlyNormalStages}
        label="Show only normal stages"
      />
      <InputCheckbox
        checked={showUploadTypesInStages}
        onChange={setShowUploadTypesInStages}
        label="Show upload types in stages"
      />
      <InputCheckbox
        checked={showFormsInStages}
        onChange={() => setShowFormsInStages(!showFormsInStages)}
        label="Show forms in stages"
      />
    </div>
  </div>
);

ApplicationTemplateDesignerViewOptions.propTypes = {
  stageRole: PropTypes.string.isRequired,
  setStageRole: PropTypes.func.isRequired,
  isCompact: PropTypes.bool.isRequired,
  setIsCompact: PropTypes.func.isRequired,
  showOnlyNormalStages: PropTypes.bool.isRequired,
  setShowOnlyNormalStages: PropTypes.func.isRequired,
  showUploadTypesInStages: PropTypes.bool.isRequired,
  setShowUploadTypesInStages: PropTypes.func.isRequired,
  showFormsInStages: PropTypes.bool.isRequired,
  setShowFormsInStages: PropTypes.func.isRequired,
};

export default ApplicationTemplateDesignerViewOptions;
