$(function () {
  function toggleReporterNameAndType() {
    if (this.value === "false") {
      $(".js-reported-anonymously-dependent").show();
    } else {
      $(".js-reported-anonymously-dependent").hide();
    }
  }

  function togglePriority() {
    if (this.value === "true") {
      $(".js-referred-to-investigations-dependent").show();
    } else {
      $(".js-referred-to-investigations-dependent").hide();
    }
  }

  function toggleSupportServicesNotes() {
    if (this.value === "true") {
      $(".js-referred-to-services-dependent").show();
    } else {
      $(".js-referred-to-services-dependent").hide();
    }
  }

  $(".js-reported-anonymously input").change(toggleReporterNameAndType);
  $(".js-referred-to-investigations input").change(togglePriority);
  $(".js-referred-to-services input").change(toggleSupportServicesNotes);
});
