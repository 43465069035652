import PropTypes from "prop-types";

import styles from "./LogoSpinner.module.scss";

const Logo = ({ className }) => (
  <svg
    className={styles.logo}
    preserveAspectRatio="none"
    viewBox="0 0 50.883499 50.725186"
  >
    <path
      className={className}
      d="M 0.02905164,31.999108 C 0.11173304,25.836404 -0.17155116,19.653116 0.31979724,13.506057 1.4668885,6.3398165 7.9062945,0.52660853 15.14609,0.08444823 c 5.026558,-0.5325426 10.16564,1.47967427 13.484284,5.29540227 2.881644,2.890902 4.359552,6.8883195 4.526684,10.9232075 1.009612,0.446499 2.605869,0.267941 3.774842,0.696326 7.699613,1.707332 13.561673,9.459566 12.72337,17.375912 -0.484244,7.826835 -7.306193,14.668851 -15.150623,15.036501 -11.4371,0.261861 -22.880617,0.0923 -34.32063016,0.139779 -0.3743762,-0.472326 -0.042504,-1.6509 -0.1549652,-2.385142 0,-5.055775 0,-10.111551 0,-15.167326 z"
    />
  </svg>
);

Logo.propTypes = { className: PropTypes.string };

/** Growing Binti "heart", used for our LoadingOverlay
 *
 * We also use this logo spinner in some base level Heart components, but
 * generally we should be reaching for LoadingOverlay instead outside of Heart!
 *
 * ### Jest
 * We mock this component globally in our Jest setup so that it just says "logoSpinnerMock"
 * If needed, you can override this global mock in a specific test
 */
const LogoSpinner = () => (
  <div className={styles.container}>
    <Logo className={styles.fixed} />
    <Logo className={styles.spinner} />
  </div>
);

export default LogoSpinner;
